import * as React from "react";
import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue, red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import css from "../styles/Dashboard/Dashboard.module.css";
import { useState, useEffect, useContext } from "react";
import { urls as url } from "../services/urlConstant";
import appendStringToUrls from "../utils/appendString";
import SettingContext from "../context/SettingsContext";

import { get } from "../services/apis";
import dayjs from "dayjs";
import { Box, Divider } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function StudentNews() {
  const [expanded, setExpanded] = React.useState([]);
  const [expanded1, setExpanded1] = React.useState(false);
  const [announceNotice, setAnounceNotice] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    getNews();
  }, [selectedSetting]);

  const getNews = async () => {
    try {
      const res = await get(`${urls.studentDashboard.getNews}`, {
        params: {
          search: {
            webView: "yes",
          },
        },
      });
      setAnounceNotice(res.data?.data);
      console.log(res, "newsffffffffffffffff");
    } catch (error) {
      console.log(error);
    }
  };

  const handleExpandClick = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };
  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  return (
    <div className={css.scrollableCard}>
      <div>
        {/* <Box className={css.cardScroll}> */}
        {announceNotice && announceNotice.length > 0 ? (
          announceNotice.map((news, i) => (
            <React.Fragment key={i}>
              <Box className={css.cardScroll}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      N
                    </Avatar>
                  }
                  action={
                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded[i]}
                        onClick={() => handleExpandClick(i)}
                        aria-expanded={expanded}
                        aria-label="show more">
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                  }
                  title={
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      {news.title}
                    </Typography>
                  }
                  subheader={
                    <Typography
                      style={{
                        display: "flex",
                        textAlign: "left",
                        fontSize: 14,
                      }}>
                      {dayjs(news.date).format("DD/MM/YYYY")}
                    </Typography>
                  }
                />

                <Collapse
                  in={expanded[i]}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    padding: 2,
                    borderTop: 1,
                    borderColor: "divider",
                  }}>
                  <Typography
                    paragraph
                    sx={{ textAlign: "left", fontSize: 14 }}>
                    {news.news}
                  </Typography>
                  <CardMedia
                    component="img"
                    height="250"
                    image={news.image ? news.image.link : ""}
                    alt="no image"
                    sx={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      bgcolor: "lightgray",
                      padding: "10px",
                    }}
                  />
                </Collapse>
              </Box>
            </React.Fragment>
          ))
        ) : (
          <Typography sx={{ textAlign: "center" }}>No news found.</Typography>
        )}
        {/* </Box> */}
      </div>
    </div>
  );
}
