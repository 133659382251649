import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
  Grid,
  Card,
  TextField,
  styled,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect, useContext } from "react";
import DeleteModal from "../Academic/DeleteModal";
import { urls as url } from "../../../services/urlConstant";
import { del, post, put, get } from "../../../services/apis";
import AddExamTerm from "./AddExamTerm";
import TablePagination from "@mui/material/TablePagination";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../../components/Wrapper";
import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import ShowComponent from "../../ShowComponent";

const SearchBox = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down(900)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(685)]: {
    justifyContent: "flex-start",
  },
}));

const ExamTerm = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [schoolExamTerm, setSchoolExamTerm] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateExamTerm, setUpdateExamTerm] = useState(undefined);

  const [totalCount, setTotalCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let [loader, setLoader] = useState(false);

  const [sort, setSort] = useState({
    examTitle: "asc",
    examType: "asc",
  });

  const sortByExamTitle = () => {
    console.log("ExamTitle");
    if (search) {
      if (sort.examTitle === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.examTitle.localeCompare(a.examTitle)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examTitle: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.examTitle.localeCompare(b.examTitle)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examTitle: "asc" });
      }
    } else {
      if (sort.examTitle === "asc") {
        let sortedList = [
          ...schoolExamTerm.sort((a, b) =>
            b.examTitle.localeCompare(a.examTitle)
          ),
        ];
        setSchoolExamTerm(sortedList);
        setSort({ ...sort, examTitle: "des" });
      } else {
        let sortedList = [
          ...schoolExamTerm.sort((a, b) =>
            a.examTitle.localeCompare(b.examTitle)
          ),
        ];
        setSchoolExamTerm(sortedList);
        setSort({ ...sort, examTitle: "asc" });
      }
    }
  };

  const sortByExamType = () => {
    console.log("ExamType");
    if (search) {
      if (sort.examType === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.examType.localeCompare(a.examType)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examType: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.examType.localeCompare(b.examType)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examType: "asc" });
      }
    } else {
      if (sort.examType === "asc") {
        let sortedList = [
          ...schoolExamTerm.sort((a, b) =>
            b.examType.localeCompare(a.examType)
          ),
        ];
        setSchoolExamTerm(sortedList);
        setSort({ ...sort, examType: "des" });
      } else {
        let sortedList = [
          ...schoolExamTerm.sort((a, b) =>
            a.examType.localeCompare(b.examType)
          ),
        ];
        setSchoolExamTerm(sortedList);
        setSort({ ...sort, examType: "asc" });
      }
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateExamTerm(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        urls.examTerm.delExamTerm,
        id,
        {},
        { roleFunction: "examTerm" }
      );
      if (res.data.success) {
        setSchoolExamTerm(
          schoolExamTerm.filter((item) => item._id !== res.data.data._id)
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    const getSchoolLength = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "examTerm" },
        });
        setTotalCount(res.data.data.length);
      } catch (error) {}
    };
    getSchoolLength();
  }, [selectedSetting]);

  const handleSubmit = async (e, data) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await post(urls.examTerm.postExamTerm, data, {
        headers: { roleFunction: "examTerm" },
      });
      if (res.data.success) {
        setSchoolExamTerm([...schoolExamTerm, res.data.data]);
        handleClose();
      }
    } catch (error) {}
    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const res = await put(urls.examTerm.putExamTerm, id, data, {
        headers: { roleFunction: "examTerm" },
      });
      if (res.data.success) {
        setSchoolExamTerm(
          schoolExamTerm.map((e) => {
            if (e._id === res.data.data._id) {
              return res.data.data;
            } else {
              return e;
            }
          })
        );
        handleClose();
      }
    } catch (error) {}
    setLoader(false);
  };

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getExamTermById = schoolExamTerm.find((item) => item._id === id);
      setUpdateExamTerm(getExamTermById);
    } catch (error) {}
  };

  useEffect(() => {
    const getSchoolExam = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "examTerm" },
        });
        if (res && res.data && res.data.data) {
          setSchoolExamTerm([...res.data.data]);
        }
      } catch (error) {}
    };
    getSchoolExam();
  }, [selectedSetting]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolExamTerm.filter((ele) =>
          ele.examTitle.toLowerCase().includes(value)
        )
      );
    } else {
      setSearchFilter([]);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Exam Term" />

      <div style={{ margin: "15px 0" }}>
        <div className={styles.searchInputContainer}>
          <SearchBox container spacing={2}>
            <Grid item xs="auto">
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search Exam Term"
                variant="outlined"
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </SearchBox>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Exam Title</span>
                  <span
                    onClick={sortByExamTitle}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.examTitle === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Type</span>
                  <span
                    onClick={sortByExamType}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.examType === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>

                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {" "}
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examTitle}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <ShowComponent
                            roleFunction="examTerm"
                            action="update"
                          >
                            <Tooltip title="Update" disableInteractive>
                              <IconButton
                                size="small"
                                sx={{ color: "#1b3779" }}
                                onClick={() => handleEdit(row._id)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <ShowComponent
                            roleFunction="examTerm"
                            action="delete"
                          >
                            <Tooltip title="Delete" disableInteractive>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => setDeleteModal(row._id)}
                              >
                                <Delete fontSize="small" color="error" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>

                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                : schoolExamTerm
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {" "}
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examTitle}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <ShowComponent
                              roleFunction="examTerm"
                              action="update"
                            >
                              <Tooltip title="Update" disableInteractive>
                                <IconButton
                                  size="small"
                                  sx={{ color: "#1b3779" }}
                                  onClick={() => handleEdit(row._id)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="examTerm"
                              action="delete"
                            >
                              <Tooltip title="Delete" disableInteractive>
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!schoolExamTerm.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : schoolExamTerm.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />

        <div className="add-icon">
          <AddExamTerm
            loader={loader}
            setLoader={setLoader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateExamTerm={updateExamTerm}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ExamTerm;
