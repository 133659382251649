import React, { useContext, useEffect } from "react";
// style
import css from "../../../styles/fees/QuickFeeCollection.module.css";
import { useState } from "react";
import { get, post } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import { instance } from "../../../services/axios.Config";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { LoadingButton } from "@mui/lab";
import QuickFeeConcessionModal from "../Fees/components/QuickFeeConcessionModal";
import QuickFeeCollectModal from "../Fees/components/QuickFeeCollectModal";
import moment from "moment";

const showInfo = (data) => {
  let result = [];

  for (let dep of data.dependencies) {
    if (dep === "academicYear") {
      result.push(
        `[${data.academicYearId.academicYearFrom}-${data.academicYearId.academicYearTo}]-Academic Year`
      );
    } else if (dep === "class") {
      let newItem = `[${data.classes
        ?.map((c) => c.className)
        ?.join("-and-")}]-Class`;
      result.push(newItem);
    } else if (dep === "classOld") {
      let newItem = `[${data.classes
        ?.map((c) => c.className)
        ?.join("-and-")}]-Class-Old`;
      result.push(newItem);
    } else if (dep === "classNew") {
      let newItem = `[${data.classes
        ?.map((c) => c.className)
        ?.join("-and-")}]-Class-New`;
      result.push(newItem);
    } else if (dep === "transportCategory") {
      let newItem = `[${data.transportCategory}]-Transport Category`;
      result.push(newItem);
    }
  }

  return result.join(" | ");
};

const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

function CollectFeeDetails() {
  const { selectedSetting } = useContext(SettingContext);
  const [feeParticulars, setFeeParticulars] = useState([]);
  const [dueDetails, setDueDetails] = useState(null);
  const [penalty, setPenalty] = useState(0);
  const [payingAmount, setPayingAmount] = useState(0);
  const [note, setNote] = useState("");

  const [formData, setFormData] = useState({});
  const [students, setStudents] = useState([]);
  const [selectStudent, setSelectStudent] = useState(null);

  const [concessionDialog, setConcessionDialog] = useState(false);
  const [concession, setConcession] = useState(null);
  const [collectFeeModal, setCollectFeeModal] = useState(false);

  const [collectingFee, setCollectingFee] = useState(false);
  const [downloadingPreview, setDownloadingPreview] = useState(false);
  const [findLoader, setFindLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [receiptTitles, setReceiptTitles] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [feeDetails, setFeeDetails] = useState(null);
  const [downloadingReceipt, setDownloadingReceipt] = useState(false);

  const [previousReceipts, setPreviousReceipts] = useState(null);

  const getAllReceiptTitles = async () => {
    try {
      const { data } = await get(urls.receiptTitle.list, {
        headers: { roleFunction: "collectFees" },
        params: {
          search: { active: true },
        },
      });
      setReceiptTitles(data.data);
      setFormData((prev) => ({
        ...prev,
        receiptTitleId: data.data[0] ? data.data[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllReceiptTitles();
  }, [selectedSetting._id]);

  const getClasses = async () => {
    try {
      const res = await get(urls.class.getAllClass, {
        headers: { roleFunction: "collectFees" },
      });
      setClasses(res.data.data);
      setFormData((prev) => ({
        ...prev,
        class: res.data.data[0] ? res.data.data[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // get all classes
  useEffect(() => {
    getClasses();
  }, [selectedSetting._id, formData.feeMapId]);

  // get all students
  const getAllStudents = async () => {
    try {
      let filter = {};
      if (formData.class) {
        filter["academicInfo.class"] = formData.class;
      }

      if (formData.section) {
        filter["academicInfo.section"] = formData.section;
      }

      const { data: students, status } = await get(
        `${urls.students.getAllStudent}`,
        {
          headers: { roleFunction: "collectFees" },
          params: {
            search: filter,
          },
        }
      );
      if (status > 199 && status < 299) {
        setSelectStudent(null);
        setStudents(
          students.data?.sort((a, b) =>
            a.basicInfo.name.localeCompare(b.basicInfo.name)
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.class && formData.section) {
      getAllStudents();
    } else {
      setStudents([]);
      setSelectStudent(null);
    }
  }, [formData.section, formData.feeMapId]);

  const getSections = async () => {
    try {
      const { data } = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "collectFees" },
        params: { search: { class: formData.class } },
      });
      setSections(data.data);
      setFormData((prev) => ({
        ...prev,
        section: data.data[0] ? data.data[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.class) {
      getSections();
    }
  }, [formData.class]);

  // form data to fetch the fee details
  const handleFormDataInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //
  const handleStudentSelect = (e, val) => {
    setSelectStudent(val);
    setDueDetails(null);
    setFeeParticulars([]);
  };

  // get student fee Details
  const handleSubmitFind = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setFindLoader(true);

    try {
      const { data: feeReceipt, status } = await get(
        `${urls.receipt.getFeeDetails}`,
        {
          headers: { roleFunction: "collectFees" },
          params: {
            studentId: selectStudent._id,
            receiptTitleId: formData.receiptTitleId,
          },
        }
      );
      if (status > 199 && status < 299) {
        console.log(feeReceipt.data, "datata");
        setFeeParticulars(feeReceipt.data?.feeParticulars);
        setDueDetails(feeReceipt.data?.dues);
        setPreviousReceipts(feeReceipt.data?.previousReceipts);
      }
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  useEffect(() => {
    if (formData.installmentId) {
      handleSubmitFind();
    }
  }, [formData.installmentId]);

  // concessions manipulation

  const handleConcessionDialogClose = () => {
    setConcessionDialog(false);
  };
  const handleConcessionDialogOpen = () => {
    if (concession) {
      setConcession(null);
    } else {
      setConcessionDialog(true);
    }
  };
  const handleCollectFeeDialogClose = () => {
    setCollectFeeModal(false);
  };
  const handleCollectFeeDialogOpen = () => {
    setCollectFeeModal(true);
  };
  const handleConcessionSubmit = (data) => {
    setConcession(data);
    handleConcessionDialogClose();
  };

  const handleFeeCollect = async (paymentMode, paymentDetails) => {
    setCollectingFee(true);
    const data = {
      payingAmount,
      penalty,
      note,
      receiptTitleId: formData.receiptTitleId,
      studentId: selectStudent._id,
      paymentMode: paymentMode.payment,
      concessionDetails: concession
        ? {
            amount: concession.concession,
            referredBy: concession.refer,
            givenAs: concession.format,
          }
        : {},
    };

    if (paymentMode.payment === "Cash") {
      data.cashDetails = {
        date: paymentMode.date,
      };
    }
    if (paymentMode.payment === "Cheque") {
      data.chequeDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
        chequeNumber: paymentDetails.chequeNumber,
        chequeDate: paymentDetails.chequeDate,
      };
    }
    if (paymentMode.payment === "DD") {
      data.ddDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
      };
    }
    if (paymentMode.payment === "Upi") {
      data.upiDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }
    if (paymentMode.payment === "Card") {
      data.cardDetails = {
        bankName: paymentDetails.bankName,
        cardType: paymentDetails.cardType,
      };
    }
    if (paymentMode.payment === "Netbanking") {
      data.netBankingDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }

    try {
      const response = await instance({
        method: "post",
        url: `${urls.receipt.create}`,
        data,
        responseType: "blob",
        headers: { roleFunction: "collectFees" },
      });
      if (response) {
        if (response.status > 199 && response.status < 299) {
          const uri = URL.createObjectURL(response.data);
          window.open(uri, "__blank");
        }
        setConcession(null);
        setPenalty(0);
        setNote("");
        setPayingAmount(0);
        setDueDetails(null);

        setFormData((prev) => ({ ...prev, payment: null }));
        setFeeDetails(null);
        setCollectingFee(false);
        setCollectFeeModal(false);
      } else {
        const response = await instance({
          method: "post",
          url: `${urls.receipt.create}`,
          data,
          headers: { roleFunction: "collectFees" },
        });
        setCollectingFee(false);
      }
    } catch (error) {
      console.log(error);
      setCollectingFee(false);

      if (error.response) {
        let jsonBlob = new Blob([error.response.data], {
          type: "application/json",
        });
        const jsonErr = JSON.parse(await jsonBlob.text());
        if (jsonErr.message === "jwt expired") {
          setCollectingFee(false);

          toast.error("Session expired! Please login again!");
          window.location.href = "/login";

          return;
        }
        toast.error(jsonErr.message);
      } else {
        toast.error(error);
      }
    }
    setCollectingFee(false);
  };

  useEffect(() => {
    setConcession(null);
    setFormData((prev) => ({ ...prev, payment: null }));
    setFeeDetails(null);
    setCollectingFee(false);
    setCollectFeeModal(false);
    setNote("");
  }, [selectStudent]);

  const handlePreviewButtonClick = async (paymentMode, paymentDetails) => {
    setDownloadingPreview(true);
    const data = {
      payingAmount,
      penalty,
      note,
      receiptTitleId: formData.receiptTitleId,
      studentId: selectStudent._id,
      paymentMode: paymentMode.payment,
      concessionDetails: concession
        ? {
            amount: concession.concession,
            referredBy: concession.refer,
            givenAs: concession.format,
          }
        : {},
    };

    if (paymentMode.payment === "Cash") {
      data.cashDetails = {
        date: paymentMode.date,
      };
    }
    if (paymentMode.payment === "Cheque") {
      data.chequeDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
        chequeNumber: paymentDetails.chequeNumber,
        chequeDate: paymentDetails.chequeDate,
      };
    }
    if (paymentMode.payment === "DD") {
      data.ddDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
      };
    }
    if (paymentMode.payment === "Upi") {
      data.upiDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }
    if (paymentMode.payment === "Card") {
      data.cardDetails = {
        bankName: paymentDetails.bankName,
        cardType: paymentDetails.cardType,
      };
    }
    if (paymentMode.payment === "Netbanking") {
      data.netBankingDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }

    try {
      const response = await instance({
        method: "post",
        url: `${urls.receipt.previewReceipt}`,
        data,
        responseType: "blob",
        headers: { roleFunction: "collectFees" },
      });
      if (response) {
        if (response.status > 199 && response.status < 299) {
          const uri = URL.createObjectURL(response.data);
          window.open(uri, "__blank");
        }
        setDownloadingPreview(false);
      } else {
        const response = await instance({
          method: "post",
          url: `${urls.receipt.previewReceipt}`,
          data,
          headers: { roleFunction: "collectFees" },
        });
        setDownloadingPreview(false);
      }
    } catch (error) {
      console.log(error);
      setDownloadingPreview(false);

      if (error.response) {
        let jsonBlob = new Blob([error.response.data], {
          type: "application/json",
        });
        const jsonErr = JSON.parse(await jsonBlob.text());
        if (jsonErr.message === "jwt expired") {
          setDownloadingPreview(false);

          toast.error("Session expired! Please login again!");
          window.location.href = "/login";

          return;
        }
        toast.error(jsonErr.message);
      } else {
        toast.error(error);
      }
    }
    setDownloadingPreview(false);
  };

  const downloadReceipt = async (e, id) => {
    e.preventDefault();
    try {
      setDownloadingReceipt(id);
      const getPrint = await get(`${urls.receipt.downloadFeeReceipt}${id}`, {
        headers: { roleFunction: "collectFees" },
        responseType: "blob",
      });
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setDownloadingReceipt("");
  };

  return (
    <Box p={"1rem"}>
      <SectionSubHeader title="Collect Fees" />
      <Card sx={{ mt: 3, padding: 1 }}>
        <Box sx={{ padding: "10px 0" }}>
          <form onSubmit={handleSubmitFind}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Receipt Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="receiptTitleId"
                    label="Receipt Name"
                    required
                    value={formData.receiptTitleId || ""}
                    onChange={(event) => handleFormDataInputChange(event)}
                  >
                    {receiptTitles.map((receiptTitle, index) => (
                      <MenuItem key={index} value={receiptTitle._id}>
                        {receiptTitle.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="class"
                    label="Class"
                    required
                    value={formData.class || ""}
                    onChange={(event) => handleFormDataInputChange(event)}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="section"
                    label="Section"
                    value={formData.section || ""}
                    required
                    onChange={(event) => handleFormDataInputChange(event)}
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  size="small"
                  value={selectStudent}
                  sx={styles.textfield}
                  onChange={handleStudentSelect}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  options={students}
                  getOptionLabel={(option) =>
                    `${option.basicInfo.name} | ${option.academicInfo.admissionNo} | ${option.academicInfo.rollNo} | ${option.fatherInfo?.fatherName}`
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      size="small"
                      {...params}
                      label="Students"
                      placeholder="Search student with name"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                alignSelf="center"
                display="flex"
                justifyContent="flex-start"
              >
                <LoadingButton
                  loading={findLoader}
                  style={styles.Button}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  find
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      {selectStudent && dueDetails && (
        <>
          <Card sx={{ padding: "10px", margin: "10px 0" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography fontSize="15px">
                  <b>Student Name: </b> {selectStudent?.basicInfo?.name}
                </Typography>
              </Box>

              <Box>
                <Typography fontSize="15px">
                  <b>Past Due :</b> ₹{" "}
                  <span>{dueDetails?.past?.toFixed(2)}</span>
                </Typography>

                <Typography fontSize="15px">
                  <b>Total Due :</b> ₹{" "}
                  <span>{dueDetails?.total?.toFixed(2)}</span>
                </Typography>
                <Typography fontSize="15px">
                  <b>Total Paid :</b> ₹{" "}
                  <span>{dueDetails?.totalPaid?.toFixed(2)}</span>
                </Typography>
              </Box>
            </div>
          </Card>

          {!previousReceipts?.length ? null : (
            <Card
              sx={{ padding: "10px", margin: "10px 0" }}
              className={css.dueInfoContainer}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  columnGap: "5px",
                }}
              >
                {previousReceipts.map((p) => (
                  <LoadingButton
                    loading={downloadingReceipt === p._id}
                    size="small"
                    variant="outlined"
                    disabled={downloadingReceipt ? true : false}
                    key={p._id}
                    onClick={(e) => downloadReceipt(e, p._id)}
                    endIcon={<DownloadForOfflineSharpIcon />}
                  >
                    {dayjs(p.paidAt).toDate().toLocaleDateString()}
                  </LoadingButton>
                ))}
              </Box>
            </Card>
          )}

          <div className={css.tableContainer}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#1b3779" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Fee Particular
                    </TableCell>
                    {/* <TableCell sx={{ color: "white" }} align="center">
                      Description
                    </TableCell> */}
                    <TableCell sx={{ color: "white" }} align="center">
                      Actual Amount
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Due Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feeParticulars.map((itemDetail, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <Typography>{itemDetail.title}</Typography>
                      </TableCell>
                      {/* <TableCell align="center">
                        <Typography>{itemDetail.description}</Typography>
                      </TableCell> */}
                      <TableCell align="center">
                        <Typography>₹ {itemDetail.fee}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>₹ {itemDetail.dueAmount}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow sx={{ backgroundColor: "whitesmoke" }}>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontWeight: "bold" }}>Total</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography sx={{ fontWeight: "bold" }}>
                        ₹ {dueDetails?.totalAmountToBePaid}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontWeight: "bold" }}>
                        ₹ {dueDetails?.current}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  padding: "10px",
                  columnGap: "10px",
                  backgroundColor: "whitesmoke",
                  borderRadius: "5px",
                  marginTop: "80px",
                }}
              >
                <Stack direction="row" columnGap={2}>
                  <TextField
                    type="number"
                    size="small"
                    name="penalty"
                    label="Penalty"
                    value={penalty || ""}
                    onChange={(e) => setPenalty(e.target.value)}
                  />
                  <TextField
                    type="number"
                    size="small"
                    name="payingAmount"
                    label="Paying Amount"
                    value={payingAmount || ""}
                    onChange={(e) => setPayingAmount(e.target.value)}
                  />
                </Stack>
                <div className={css.addRowBtn_childFlex}>
                  <div>
                    <Button
                      onClick={handleConcessionDialogOpen}
                      variant="contained"
                      size="small"
                    >
                      {concession ? "remove concession" : "add concession"}
                    </Button>
                    <div className={css.concessionText}>
                      {concession
                        ? concession?.format === "Value"
                          ? `Concession:- ₹${concession?.concession}`
                          : `Concession:- ${concession?.concession}%`
                        : ""}
                    </div>
                  </div>
                  <Button
                    onClick={handleCollectFeeDialogOpen}
                    variant="contained"
                    size="small"
                    sx={{
                      background: "#1B3779",
                      "&:hover": {
                        background: "#1B3779",
                      },
                    }}
                  >
                    collect fee
                  </Button>
                </div>
              </div>
            </TableContainer>
          </div>
        </>
      )}
      <QuickFeeConcessionModal
        onSubmit={handleConcessionSubmit}
        open={concessionDialog}
        onClose={handleConcessionDialogClose}
        opener={handleConcessionDialogOpen}
      />
      <QuickFeeCollectModal
        collectingFee={collectingFee}
        downloadingPreview={downloadingPreview}
        onPreviewButtonClick={handlePreviewButtonClick}
        feeReceipt={feeDetails}
        onSubmit={handleFeeCollect}
        open={collectFeeModal}
        onClose={handleCollectFeeDialogClose}
        onUpdateNote={setNote}
        payingAmount={payingAmount}
        note={note}
      />
    </Box>
  );
}

export default CollectFeeDetails;
