import React, { useContext, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  TablePagination,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import Navigation from "../../Navigation/components/Navigation";
import SearchbarAndFilter from "../Administrator/SearchbarAndFilter";
import { useEffect } from "react";
import AddEvent from "./AddEvent";
import DeleteModal from "../Academic/DeleteModal";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";

import dayjs from "dayjs";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import ShowComponent from "../../ShowComponent";

const Event = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [event, setEvent] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [updateEvent, setUpdateEvent] = React.useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  let [loader, setLoader] = useState(false);
  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  // filter pagination==========
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = async (event, newPage) => {
    try {
      const res = await get(`${url.events.getEventList}`, {
        headers: { roleFunction: "event" },
        params: {
          limit: 10,
          page: newPage + 1,
        },
      });
      setEvent([...res.data.result.data]);
      setPage(newPage);
    } catch (error) {
      console.log(error);
    }
  };
  // ==============

  const [sort, setSort] = useState({
    eventTitle: "asc",
    location: "asc",
    status: "asc",
    hostedBy: "asc",
    toDate: "asc",
    fromDate: "asc",
  });

  const sortByEventTitle = () => {
    console.log("EventTitle");
    if (search) {
      if (sort.eventTitle === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.eventTitle.localeCompare(a.eventTitle)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, eventTitle: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.eventTitle.localeCompare(b.eventTitle)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, eventTitle: "asc" });
      }
    } else {
      if (sort.eventTitle === "asc") {
        let sortedList = [
          ...event.sort((a, b) => b.eventTitle.localeCompare(a.eventTitle)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, eventTitle: "des" });
      } else {
        let sortedList = [
          ...event.sort((a, b) => a.eventTitle.localeCompare(b.eventTitle)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, eventTitle: "asc" });
      }
    }
  };

  const sortByLocation = () => {
    console.log("Location");
    if (search) {
      if (sort.location === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.location.localeCompare(a.location)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, location: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.location.localeCompare(b.location)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, location: "asc" });
      }
    } else {
      if (sort.location === "asc") {
        let sortedList = [
          ...event.sort((a, b) => b.locataion.localeCompare(a.locataion)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, location: "des" });
      } else {
        let sortedList = [
          ...event.sort((a, b) => a.locataion.localeCompare(b.locataion)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, location: "asc" });
      }
    }
  };

  const sortByHosted = () => {
    console.log("hostedBy");
    if (search) {
      if (sort.hostedBy === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.hostedBy.localeCompare(a.hostedBy)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, hostedBy: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.hostedBy.localeCompare(b.hostedBy)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, hostedBy: "asc" });
      }
    } else {
      if (sort.hostedBy === "asc") {
        let sortedList = [
          ...event.sort((a, b) => b.hostedBy.localeCompare(a.hostedBy)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, hostedBy: "des" });
      } else {
        let sortedList = [
          ...event.sort((a, b) => a.hostedBy.localeCompare(b.hostedBy)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, hostedBy: "asc" });
      }
    }
  };

  const sortByStatus = () => {
    console.log("Status");
    if (search) {
      if (sort.status === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.status.localeCompare(a.status)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, status: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.status.localeCompare(b.status)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, status: "asc" });
      }
    } else {
      if (sort.status === "asc") {
        let sortedList = [
          ...event.sort((a, b) => b.status.localeCompare(a.status)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, status: "des" });
      } else {
        let sortedList = [
          ...event.sort((a, b) => a.status.localeCompare(b.status)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, status: "asc" });
      }
    }
  };

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.fromDate);
    let dateB = new Date(b.fromDate);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.fromDate);
    let dateB = new Date(b.fromDate);

    return dateB - dateA;
  };

  const compareDatesAscTo = (a, b) => {
    let dateA = new Date(a.toDate);
    let dateB = new Date(b.toDate);

    return dateA - dateB;
  };

  const compareDatesDecTo = (a, b) => {
    let dateA = new Date(a.toDate);
    let dateB = new Date(b.toDate);

    return dateB - dateA;
  };

  const sortByFromDate = () => {
    // console.log("Date");
    if (search) {
      if (sort.fromDate === "asc") {
        let sortedList = [...searchFilter.sort(compareDatesDec)];
        setSearchFilter(sortedList);
        setSort({ ...sort, fromDate: "des" });
      } else {
        let sortedList = [...searchFilter.sort(compareDatesAsc)];
        setSearchFilter(sortedList);
        setSort({ ...sort, fromDate: "asc" });
      }
    } else {
      if (sort.fromDate === "asc") {
        let sortedList = [...event.sort(compareDatesDec)];
        setEvent(sortedList);
        setSort({ ...sort, fromDate: "des" });
      } else {
        let sortedList = [...event.sort(compareDatesAsc)];
        setEvent(sortedList);
        setSort({ ...sort, fromDate: "asc" });
      }
    }
  };

  const sortByToDate = () => {
    console.log("ToDate");
    if (search) {
      if (sort.toDate === "asc") {
        let sortedList = [...searchFilter.sort(compareDatesDecTo)];
        setSearchFilter(sortedList);
        setSort({ ...sort, toDate: "des" });
      } else {
        let sortedList = [...searchFilter.sort(compareDatesAscTo)];
        setSearchFilter(sortedList);
        setSort({ ...sort, toDate: "asc" });
      }
    } else {
      if (sort.toDate === "asc") {
        let sortedList = [...event.sort(compareDatesDecTo)];
        setEvent(sortedList);
        setSort({ ...sort, toDate: "des" });
      } else {
        let sortedList = [...event.sort(compareDatesAscTo)];
        setEvent(sortedList);
        setSort({ ...sort, toDate: "asc" });
      }
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateEvent(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        `${url.events.deleteEvent}/`,
        id,
        {},
        { roleFunction: "event" }
      );
      console.log(res);
      if (res.data.success) {
        const filteredEvent = event.filter((item) => item._id !== id);
        setEvent(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoader(true);
    try {
      // e.preventDefault();
      const res = await post(`${url.events.createEvent}`, data, {
        headers: { roleFunction: "event" },
      });
      if (res.data.success) {
        setEvent([...event, res.data.result]);

        handleClose();
        getEvent();
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);

    try {
      const response = await put(`${url.events.updateEvent}/`, id, data, {
        headers: { roleFunction: "event" },
      });
      console.log("reach1", data);
      if (response.data.success) {
        setEvent(
          event.map((e) => {
            if (e._id === response.data.result.data._id) {
              console.log("res", response);
              return response.data.result.data;
            } else {
              return e;
            }
          })
        );
        handleClose();
        getEvent();
      }

      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getEventById = event.find((item) => item._id === id);
      console.log(getEventById);
      setUpdateEvent(getEventById);
    } catch (error) {
      console.log(error);
    }
  };
  const getEvent = async () => {
    try {
      const res = await get(`${url.events.getEventList}`, {
        headers: { roleFunction: "event" },
      });
      console.log("reach ", res);
      setEvent([...res.data.result.data]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getEvent = async () => {
      try {
        const res = await get(`${url.events.getEventList}`, {
          headers: { roleFunction: "event" },
          params: {
            limit: 10,
            page: 1,
          },
        });
        setEvent([...res.data.result.data]);
        setTotalCount(res.data.result.count);
      } catch (error) {
        console.log(error);
      }
    };
    getEvent();
  }, [selectedSetting]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      event.length > 0 &&
        setSearchFilter(
          event.filter((ele) =>
            ele.eventTitle.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
      setShowSearch(false);
    } else {
      setSearchFilter([]);
    }
  };
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const noticeSearch = await get(`${url.events.getEventList}`, {
        headers: { roleFunction: "event" },
        params: {
          search: {
            $or: [
              {
                eventTitle: { $regex: search.trim(), $options: "i" },
              },
              {
                fromDate: dayjs(search, "DD-MM-YYYY").isValid()
                  ? dayjs(search, "DD-MM-YYYY").toDate()
                  : "",
              },
              {
                toDate: dayjs(search, "DD-MM-YYYY").isValid()
                  ? dayjs(search, "DD-MM-YYYY").toDate()
                  : "",
              },
              {
                location: search,
              },
            ],
          },
        },
      });
      setSearchFilter(noticeSearch.data.result.data);
      setShowSearch(true);
    } catch (error) {
      console.log(error.stack);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Events" />
      <Box style={{ margin: "15px 0" }}>
        <Grid container spacing={2} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <form onSubmit={handleSearchSubmit}>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search Event"
                variant="outlined"
                placeholder="Search Event"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Event Title</span>
                  <span
                    onClick={sortByEventTitle}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.eventTitle === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Location</span>
                  <span
                    onClick={sortByLocation}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.location === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">From Date</span>
                  <span
                    onClick={sortByFromDate}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.fromDate === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">To Date</span>
                  <span
                    onClick={sortByToDate}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.toDate === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>

                <TableCell align="center">
                  <span className="class-table-header">HostedBy</span>
                  <span
                    onClick={sortByHosted}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.hostedBy === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.eventTitle}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.location}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dayjs(row.fromDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dayjs(row.toDate).format("DD-MM-YYYY")}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.hostedBy}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="edit-delete">
                          <ShowComponent roleFunction="event" action="update">
                            <Tooltip title="Update">
                              <IconButton
                                size="small"
                                style={{ color: "#1b3779" }}
                                onClick={() => handleEdit(row._id)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <ShowComponent roleFunction="event" action="delete">
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                size="small"
                                aria-label="delete"
                                onClick={() => setDeleteModal(row._id)}
                              >
                                <Delete color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                          title={row.eventTitle}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : event.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.eventTitle}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.location}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dayjs(row.fromDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dayjs(row.toDate).format("DD-MM-YYYY")}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.hostedBy}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="edit-delete">
                          <ShowComponent roleFunction="event" action="update">
                            <Tooltip title="Update">
                              <IconButton
                                size="small"
                                style={{ color: "#1b3779" }}
                                onClick={() => handleEdit(row._id)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <ShowComponent roleFunction="event" action="delete">
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                size="small"
                                aria-label="delete"
                                onClick={() => setDeleteModal(row._id)}
                              >
                                <Delete color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                          title="event"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!event.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
        <div className="add-icon">
          <AddEvent
            url={url}
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateEvent={updateEvent}
            getEvents={getEvent}
          />
        </div>
      </Box>
    </Wrapper>
  );
};

export default Event;
