import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import Container from "@mui/material/Container";
import Wrapper from "../../Wrapper";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";

const EmployeeAttendence = () => {
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const [month, setMonth] = useState(dayjs(Date.now()));
  const [classes, setClasses] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMonthChange = (newValue) => {
    // Extract the month name from the selected date
    const monthName = newValue.format("MMMM");
    setMonth(newValue);
  };
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "reports" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "reports" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "reports" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const styles = {
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
      marginLeft: "10px",
      marginTop: "5px",
    },
    Cards: {
      marginTop: "20px",
      padding: "10px",
      marginBottom: "10px",
    },
  };
  const numbers = [];
  for (let i = 1; i <= 31; i++) {
    numbers.push(
      <TableCell sx={{ color: "white", display: "inline" }} key={i}>
        {i}
      </TableCell>
    );
  }
  console.log(month, "month");
  const handleFind = async () => {
    setLoader(true);

    try {
      let data = {
        academicYearId: formData.academicYear,
        month: month,
      };
      const response = await post(
        `${urls.employeeReport.employeeMonthlyReport}`,
        data,
        { headers: { roleFunction: "reports" } }
      );
      console.log(response.data.data, "response");
      setData(response.data.data);
      // setLoader(false);
    } catch (error) {
      console.log(error);
      // setLoader(false);
    }
    setLoader(false);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Employee Attendance" />
      <Box sx={{ mt: "15px" }}>
        <Card style={styles.Cards}>
          <Box sx={{ flexGrow: 1 }}>
            <form>
              <Grid container spacing={2} marginBottom={"5px"}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      variant="outlined"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Select Month"
                        views={["month"]}
                        inputFormat="MMMM"
                        value={month}
                        onChange={handleMonthChange}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  display="flex"
                  alignSelf="center"
                >
                  <LoadingButton
                    type="submit"
                    size="small"
                    loading={loader}
                    variant="contained"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                    onClick={handleFind}
                  >
                    find
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
        <TableContainer component={Paper} sx={{ overflowX: "scroll" }}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  Student Name
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Working Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Present Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Absent Days
                </TableCell>
                {numbers.map((num) => (
                  <TableCell
                    key={num.key}
                    sx={{ color: "white" }}
                    align="center"
                  >
                    {num.key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((dat, index) => (
                  <TableRow>
                    <TableCell align="center">
                      {dat.employee.basicInfo.empName}
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      {dat.attendanceList.filter(
                        (ele) => ele.status === "present"
                      ).length +
                        dat.attendanceList.filter(
                          (ele) => ele.status === "absent"
                        ).length}
                    </TableCell>
                    <TableCell align="center">
                      {
                        dat.attendanceList.filter(
                          (ele) => ele.status === "absent"
                        ).length
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        dat.attendanceList.filter(
                          (ele) => ele.status === "present"
                        ).length
                      }
                    </TableCell>
                    {numbers.map((num) => {
                      const matchingDocument = dat.attendanceList.filter(
                        (ele) => ele.date == num.key
                      );
                      return (
                        <TableCell key={num.key} align="center">
                          {matchingDocument.length > 0
                            ? matchingDocument.some(
                                (sta) => sta.status === "present"
                              )
                              ? "P"
                              : matchingDocument.some(
                                  (sta) => sta.status === "absent"
                                )
                              ? "A"
                              : "-"
                            : "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </Box>
    </Wrapper>
  );
};

export default EmployeeAttendence;
