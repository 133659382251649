import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  Card,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TablePagination,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { styled } from "@mui/material";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "650px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",

  [theme.breakpoints.down(600)]: {
    width: "100%",
    height: "100%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function EmployeeLeave() {
  const { selectedSetting } = useContext(SettingContext);
  const [state, setState] = useState({
    leaveType: "",
    startDate: "",
    endDate: "",
    information: "",
    subject: "",
    description: "",
  });
  const [totalDays, settotalDays] = useState(0);
  const [value, setValue] = React.useState(0);
  const [range, setRange] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editEmployeeleave, setEditEployeeLeave] = useState();
  const [file, setFile] = useState(null);
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [allMyLeaves, setAllMyLeaves] = useState([]);
  const [allLeaves, setAllLeaves] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [searchAll, setSearchAll] = useState("");
  const [filterAll, setFilterAll] = useState("");
  const [totalLeave, setTotalLeave] = useState(0);
  const [typeLeave, setTypeLeave] = useState([]);
  const [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const classes = useStyles();

  let handleNumberOfDay = (e, v) => {
    const { name, value } = e.target;

    const indexOfV = range.indexOf(v);
    let updatedList = range.map((item, index) =>
      index == indexOfV ? { ...item, value: value } : item
    );
    setRange(updatedList);
  };

  useEffect(() => {
    const totalleave = async () => {
      try {
        const total = await get(`${urls.employeeLeaveApplication.totalcount}`, {
          headers: { roleFunction: "employeeLeave" },
        });
        const totalLeaveData = total.data.data;
        const sumTotal = totalLeaveData.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.total;
        }, 0);
        setTotalLeave(sumTotal);
        setTypeLeave(totalLeaveData);
        console.log(totalLeaveData, "totaldata");
      } catch (error) {
        console.log(error);
      }
    };
    totalleave();
  }, [selectedSetting._id]);
  useEffect(() => {
    let number = 0;
    for (let item of range) {
      if (item.value) {
        if (item.value === "fullday") {
          number += 1;
        } else {
          number += 0.5;
        }
      }
    }
    settotalDays(number);
  }, [range]);

  useEffect(() => {
    if (state.endDate && state.startDate) {
      const start = new Date(state.startDate);
      const end = new Date(state.endDate);
      if (start <= end) {
        const currentDate = new Date(start);
        let list = [];
        while (currentDate <= end) {
          let newItem = {
            value: "",
            date: new Date(currentDate).toLocaleDateString(),
          };
          list.push(newItem);
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setRange(list);
      }
    }
  }, [state.endDate, state.startDate]);

  let handleCancel = () => {
    setOpen(false);
    setState({
      leaveType: "",
      startDate: "",
      endDate: "",
      information: "",
      subject: "",
      description: "",
    });
    settotalDays(0);
  };

  useEffect(() => {
    const getLeaveType = async () => {
      try {
        let { data } = await get(`${urls.leaveType.getList}/all`, {
          headers: { roleFunction: "employeeLeave" },
        });
        setLeaveTypeData(data.data);
        // console.log(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getLeaveType();
  }, [selectedSetting._id]);
  const getAllMyLeaves = async () => {
    try {
      let { data } = await get(`${urls.employeeLeaveApplication.getMyLeaves}`, {
        headers: { roleFunction: "employeeLeave" },
      });
      setAllMyLeaves(data.data);
      setAllLeaves(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllMyLeaves();
  }, [selectedSetting._id]);

  const getAllLeaves = async () => {
    try {
      let { data } = await get(
        `${urls.employeeLeaveApplication.getAllLeaves}`,
        {
          headers: { roleFunction: "employeeLeave" },
        }
      );
      setAllLeaves(data.data);
      // console.log(data.data, "kkkkkkkkkkkkk");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllLeaves();
  }, [selectedSetting._id]);

  const { leaveType, startDate, endDate, information, subject, description } =
    state;

  let handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let payload = {
        leaveType,
        startDate,
        endDate,
        subject,

        description,
        totalDays,
        file,
      };

      const res = await post(
        `${urls.employeeLeaveApplication.createLeave}`,
        payload,
        {
          headers: { roleFunction: "employeeLeave" },
        }
      );
      if (res.data.success) {
        getAllMyLeaves();
        getAllLeaves();
        handleCancel();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleApproveLeave = async (id, index) => {
    console.log(index, "index");

    try {
      const response = await put(
        `${urls.employeeLeaveApplication.approveLeave}/${id}`,
        "",
        {},
        {
          headers: { roleFunction: "employeeLeave" },
        }
      );
      getAllLeaves();
      getAllMyLeaves();
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectLeave = async (id, index) => {
    try {
      const response = await put(
        `${urls.employeeLeaveApplication.rejectLeave}/${id}`,
        "",
        {},
        {
          headers: { roleFunction: "employeeLeave" },
        }
      );

      getAllLeaves();
      let allItemsId = allLeaves.map((l) => l._id);
      let newList = allLeaves.map((l) =>
        allItemsId.indexOf(response.data.data._id) == index
          ? response.data.data
          : l
      );
      setAllLeaves(newList);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleRejectLeave = async (id, index) => {
  //   try {
  //     const response = await put(
  //       `${urls.employeeLeaveApplication.rejectLeave}/${id}`
  //     );
  //     console.log(response, "qqqqqqqqqqq");

  //     let allItemsId = allLeaves.map((l) => l._id);

  //     let newList = allLeaves.map((l) =>
  //       allItemsId.indexOf(response.data.result._id) == index
  //         ? response.data.result
  //         : l
  //     );
  //     setAllLeaves(newList);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      allMyLeaves.length > 0 &&
        setFilter(
          allMyLeaves.filter((ele) =>
            ele.applierRoleName
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setFilter([]);
    }
  };

  const handleSearchAll = (e) => {
    const { value } = e.target;
    setSearchAll(value.trim());
    if (value.trim() !== "") {
      allLeaves.length > 0 &&
        setFilterAll(
          allLeaves.filter((ele) =>
            ele.applierRoleName
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setFilter([]);
    }
  };

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SectionSubHeader title="Employee Leave" />
        </Grid>
      </Grid>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", margin: 2 }}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Leave List" {...a11yProps(0)} />
            <Tab label="Leave Manage" {...a11yProps(1)} />
          </Tabs>
          <Divider />
        </Box>
        <TabPanel value={value} index={0}>
          <Box style={{ marginBottom: "20px" }}>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Grid
                item
                display="flex"
                alignSelf="center"
                justifyContent="center"
              >
                <Paper
                  style={{
                    height: 80,
                    width: 150,
                    padding: 20,
                    color: "green",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", mt: 1 }}>
                    Total taken :{totalLeave}
                  </Typography>
                </Paper>
              </Grid>
              {typeLeave.map((data) => (
                <Grid item>
                  <Paper
                    style={{
                      height: 80,
                      width: 150,
                      padding: 20,
                      textAlign: "center",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Typography style={{ fontSize: "15px" }}>
                      {data.name}:{data.total}
                    </Typography>
                    <Typography style={{ fontSize: "15px" }}>
                      Total taken :{data.totalTaken ? data.totalTaken : 0}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box style={{ margin: 15 }}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid xs="auto">
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search Name"
                  variant="outlined"
                  placeholder="Search..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ margin: 2 }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell>
                      <span style={{ color: "white" }}>#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span style={{ color: "white" }}>Name</span>
                    </TableCell>
                    <TableCell align="center">
                      <span style={{ color: "white" }}>Leave Type</span>
                    </TableCell>
                    <TableCell align="center">
                      <span style={{ color: "white" }}>Number of days</span>
                    </TableCell>
                    <TableCell align="center">
                      <span style={{ color: "white" }}>Start date</span>
                    </TableCell>
                    <TableCell align="center">
                      <span style={{ color: "white" }}>End date</span>
                    </TableCell>
                    <TableCell align="center">
                      <span style={{ color: "white" }}>Status</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search
                    ? filter.map((row, index) => (
                        <TableRow key={index} style={{ border: 0 }}>
                          <TableCell
                            key={row.applierRoleName}
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {row.applierRoleName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {/* {row.leaveType.leaveType} */}
                            {row.leaveType.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.totalDays}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.startDate.slice(0, 10)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.endDate.slice(0, 10)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.leaveStatus}
                          </TableCell>
                        </TableRow>
                      ))
                    : allMyLeaves.map((row, index) => (
                        <TableRow key={index} style={{ border: 0 }}>
                          <TableCell
                            key={row.applierRoleName}
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {row.applierRoleName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {/* {row.leaveType.leaveType} */}
                            {row.leaveType.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.totalDays}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.startDate.slice(0, 10)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.endDate.slice(0, 10)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.leaveStatus}
                          </TableCell>
                          {/* <Box className="edit-delete">
                              <TableCell>
                                <Tooltip title="Edit">
                                  <Button>
                                    <Edit
                                      onClick={() => handleEdit(row._id, row)}
                                    />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <Button>
                                    <Delete />
                                  </Button>
                                </Tooltip>
                              </TableCell>
                            </Box> */}
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
              {!allMyLeaves.length && (
                <Typography
                  // variant="h6"
                  style={{
                    textAlign: "center",
                    margin: "5px",
                    padding: "5px",
                  }}
                >
                  No data found
                </Typography>
              )}

              {search.trim() && !filter.length && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={search ? filter.length : allMyLeaves.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                my: 1,
              }}
            />
          </Box>
        </TabPanel>
      </Box>

      <TabPanel value={value} index={1}>
        <Box style={{ margin: 15 }}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid xs="auto">
              <TextField
                size="small"
                value={searchAll}
                onChange={handleSearchAll}
                label="Search Name"
                variant="outlined"
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ margin: 2 }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell>
                    <span style={{ color: "white" }}>#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Leave Type</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Number of days</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Start date</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>End date</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Status</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Action</span>
                    {/* <Typography sx={{color:"white"}}>Action</Typography> */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchAll
                  ? filterAll
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index} style={{ border: 0 }}>
                          <TableCell
                            key={row.name}
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {row.applierRoleName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {/* {row.leaveType.leaveType} */}
                            {row.leaveType.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.totalDays}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.startDate.slice(0, 10)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.endDate.slice(0, 10)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.leaveStatus}
                          </TableCell>

                          <TableCell>
                            <ShowComponent
                              roleFunction="employeeLeave"
                              action="update"
                            >
                              {row.leaveStatus === "pending" ||
                              row.leaveStatus === "rejected" ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "green",
                                    color: "white",
                                    "&:hover": { backgroundColor: "green" },
                                    marginRight: "3px",
                                  }}
                                  onClick={() =>
                                    handleApproveLeave(row._id, index)
                                  }
                                >
                                  Approve
                                </Button>
                              ) : null}
                            </ShowComponent>

                            <ShowComponent
                              roleFunction="employeeLeave"
                              action="update"
                            >
                              {row.leaveStatus === "pending" || "approved" ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "red",
                                    color: "white",
                                    "&:hover": { backgroundColor: "red" },
                                  }}
                                  onClick={() =>
                                    handleRejectLeave(row._id, index)
                                  }
                                >
                                  Reject
                                </Button>
                              ) : null}
                            </ShowComponent>
                          </TableCell>
                        </TableRow>
                      ))
                  : allLeaves
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index} style={{ border: 0 }}>
                          <TableCell
                            key={row.name}
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {row.applierRoleName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.leaveType.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.totalDays}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.startDate.slice(0, 10)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.endDate.slice(0, 10)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.leaveStatus}
                          </TableCell>

                          <TableCell align="center">
                            <ShowComponent
                              roleFunction="employeeLeave"
                              action="update"
                            >
                              {row.leaveStatus === "pending" ||
                              row.leaveStatus === "rejected" ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "green",
                                    color: "white",
                                    "&:hover": { backgroundColor: "green" },
                                    marginRight: "3px",
                                  }}
                                  onClick={() =>
                                    handleApproveLeave(row._id, index)
                                  }
                                >
                                  Approve
                                </Button>
                              ) : null}
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="employeeLeave"
                              action="update"
                            >
                              {row.leaveStatus === "pending" ||
                              row.leaveStatus === "approved" ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "red",
                                    color: "white",
                                    "&:hover": { backgroundColor: "red" },
                                  }}
                                  onClick={() =>
                                    handleRejectLeave(row._id, index)
                                  }
                                >
                                  Reject
                                </Button>
                              ) : null}
                            </ShowComponent>
                          </TableCell>
                        </TableRow>
                      ))}
              </TableBody>
            </Table>
            {!allLeaves.length && (
              <Typography
                style={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {searchAll.trim() && !filterAll.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={searchAll ? filterAll.length : allLeaves.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              my: 1,
            }}
          />
        </Box>
      </TabPanel>

      <ShowComponent roleFunction="employeeLeave" action="add">
        <Box className="add-icon">
          <Tooltip title="Add Employee Leave">
            <Fab
              size="medium"
              style={{ background: "#1b3779", color: "#fff" }}
              aria-label="add"
              onClick={handleOpen}
            >
              <Add />
            </Fab>
          </Tooltip>
        </Box>
      </ShowComponent>

      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" onSubmit={handleSubmit}>
          {/* <Card> */}
          <ContainerBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ marginBottom: "15px" }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                fontWeight="bold"
              >
                {editEmployeeleave ? "Update Leave" : "Add Leave"}
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ marginBottom: "15px" }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    style={{ label: { fontSize: 12 } }}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Leave Type
                    </InputLabel>
                    <Select
                      label="Leave Type"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="leaveType"
                      value={leaveType}
                      onChange={handleChange}
                      // style={{ minWidth: 180, label: { fontSize: 12 } }}
                    >
                      {leaveTypeData.map((data, i) => (
                        <MenuItem key={i} value={data._id}>
                          {data.name}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={"select"}>--select--</MenuItem>
                          <MenuItem value={"sickleave"}>Sickleave</MenuItem>
                          <MenuItem value={"earnedleave"}>Earnedleave</MenuItem>
                          <MenuItem value={"compoff"}>compoff</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ marginBottom: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      required
                      id="filled-required"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ label: { fontSize: 12 } }}
                      label="Start Date"
                      name="startDate"
                      value={startDate}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ marginBottom: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      required
                      id="filled-required"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ label: { fontSize: 12 } }}
                      label="End Date"
                      name="endDate"
                      value={endDate}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ marginBottom: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      required
                      id="basic-standard"
                      size="small"
                      label="Subject"
                      name="subject"
                      value={subject}
                      onChange={handleChange}
                      style={{ label: { fontSize: 12 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  style={{
                    marginBottom: "15px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <FormControl>
                    <Typography>Number of days : {totalDays}</Typography>
                  </FormControl>
                </Grid>
                {!endDate ? (
                  <></>
                ) : (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      marginBottom: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography required>Information :</Typography>
                      {!endDate ? (
                        <></>
                      ) : (
                        <Box
                          style={{
                            height: "100px",
                            overflow: "scroll",
                            marginLeft: "20px",
                          }}
                        >
                          {range.map((date, index) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              key={index}
                            >
                              <Typography>{date.date}</Typography>
                              <Select
                                value={date.value}
                                labelId="demo-simpless-select-filled-label"
                                id="mySelect"
                                name="totalDays"
                                style={{
                                  minWidth: 150,
                                  margin: "5px",
                                  marginLeft: "30px",
                                  fontSize: "15px",
                                }}
                                onChange={(e) => handleNumberOfDay(e, date)}
                              >
                                <MenuItem value={"fullday"}>FullDay</MenuItem>
                                <MenuItem value={"firsthalf"}>
                                  First half
                                </MenuItem>
                                <MenuItem value={"secondHalf"}>
                                  Second half
                                </MenuItem>
                              </Select>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ marginBottom: "15px" }}
                >
                  <TextareaAutosize
                    InputLabelProps={{
                      shrink: true,
                    }}
                    maxRows={4}
                    minRows={3}
                    name="description"
                    value={description}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      height: 100,
                      padding: "10px",
                    }}
                    aria-label="maximum height"
                    placeholder="Description"
                  />
                </Grid>
                <Grid>
                  <FormControl sx={{ marginLeft: "1rem" }}>
                    <label htmlFor="upload">Upload</label>
                    <input
                      type="file"
                      name="file"
                      id="upload"
                      style={{ width: "100%" }}
                      accept="image/*"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "1rem",
                justifyContent: { lg: "flex-end", xs: "center" },
              }}
            >
              <Button
                onClick={handleCancel}
                variant="contained"
                size="small"
                type="button"
                color="error"
                style={{ m: 1 }}
              >
                Cancel
              </Button>

              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                loading={loader}
                style={{
                  marginLeft: "10px",
                  color: "white",
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
              >
                {editEmployeeleave ? "Update Leave" : "Add Leave"}
              </LoadingButton>
            </Box>
          </ContainerBox>
          {/* </Card> */}
        </form>
      </Modal>
    </Wrapper>
  );
}
export default EmployeeLeave;
