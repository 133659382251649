import React, { useContext } from "react";

import Grid from "@mui/material/Grid";

import { Box, Typography, Container } from "@mui/material";

import { BsPhoneFill } from "react-icons/bs";

import { MdMail } from "react-icons/md";

import { ImLocation } from "react-icons/im";
import Footer from "./Footer";
import styled from "@emotion/styled";
import NavTopnav from "../components/NavTopnav/NavTopnav";
import SettingContext from "../context/SettingsContext";

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(https://i.pinimg.com/originals/5c/02/ef/5c02efe86d5487fdfeca9f8d6a194574.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  //  height:"600px",
  backgroundSize: "100% 100%",
  // paddingTop:"200px",
}));

const ContactUs = () => {
  const { selectedSetting } = useContext(SettingContext);
  let data = [
    {
      id: 1,
      icon: <BsPhoneFill color="#F86F03" size="2rem" />,
      title1: "CALL US",
      title2: `Phone: ${selectedSetting?.phone}`,
      title3: "",
      title4: "",
    },

    {
      id: 2,

      icon: <MdMail color="#F86F03" size="2rem" />,

      title1: "EMAIL",

      title2: `${selectedSetting?.email}`,

      title3: "",

      title4: "",
    },

    {
      id: 3,

      icon: <ImLocation color="#F86F03" size="2rem" />,

      title1: "ADDRESS",

      title2: selectedSetting?.address,

      title3: "",

      title4: "",
    },
  ];
  return (
    <>
      {/* <NavBar/> */}
      <NavTopnav />
      <OuterBox
        sx={{ padding: { md: "110px", sm: "118px", xs: "118px" } }}></OuterBox>
      <Container sx={{ marginTop: "10vh" }}>
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "serif",
              fontSize: "1.5rem",
              margin: "0px 0px 50px",
              textAlign: "center",
            }}>
            FIND YOUR ADDRESS
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {data.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <Grid item xs={12} md={6} sm={4} lg={4}>
                  <Box sx={{ textAlign: "center" }}>
                    {item.icon}

                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: "#F86F03",
                        margin: "22px 0px 8px",
                        fontFamily: "serif",
                      }}>
                      {item.title1}
                    </Typography>

                    <Typography
                      sx={{
                        color: "black",
                        fontFamily: "sans-serif",
                        fontSize: "1rem",
                        margin: "0px 0px 10px",
                      }}>
                      {item.title2}
                    </Typography>

                    <Typography
                      sx={{
                        color: "black",
                        fontFamily: "sans-serif",
                        fontSize: "1rem",
                        margin: "0px 0px 10px",
                      }}>
                      {item.title3}
                    </Typography>

                    <Typography
                      sx={{
                        color: "black",
                        fontFamily: "sans-serif",
                        fontSize: "1rem",
                        margin: "0px 0px 30px",
                      }}>
                      {item.title4}
                    </Typography>
                  </Box>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "serif",
              fontSize: "1.5rem",
              margin: "0px 0px 50px",
              textAlign: "center",
            }}>
            FIND YOUR LOCATION
          </Typography>
        </Box>

        <Box
          style={{
            border: "1px solid #ffff",
            borderShadow: "10px 20px #fff",
            marginBottom: "3rem",
            textAlign: "center",
          }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.43319155182!2d77.55235837528166!3d13.008063114077418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17bbc051f1ab%3A0x5b567d40d77191c6!2sWebspruce!5e0!3m2!1sen!2sin!4v1694703840631!5m2!1sen!2sin%22"
            width="80%"
            height="350"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;
