import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
  Stack,
  TextField,
  TablePagination,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import dayjs from "dayjs";
import { PickerViewRoot } from "@mui/x-date-pickers/internals/components/PickerViewRoot";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";
const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const FeeOverview = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [collected, setCollected] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "day"));
  const [toDate, setToDate] = useState(null);
  const [totalCount, setTotalCount] = useState();
  const [masterFeeOverview, setMasterFeeOverview] = useState([]);
  const [filterFeeOverview, setFilterFeeOverview] = useState([]);
  const [differentFeeOverView, setDifferentFeeOverView] = useState({
    modes: {},
    total: 0,
  });
  const [staffCopy, setStaffCopy] = useState([]);
  const [category, setCategory] = useState("admissionNumber");
  const [searchValue, setSearchValue] = useState("");
  const [finder, setFinder] = useState(false);
  const [download, setDownload] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "feeOverview" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getAllReceipts = async () => {
      try {
        const res = await get(urls.recepitBook.getAll, {
          headers: { roleFunction: "feeOverview" },
        });
        setFormData((prev) => ({
          ...prev,
          name: res.data.data[0]?._id,
        }));
        setReceipts(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllReceipts();
  }, []);

  useEffect(() => {
    const getCollected = async () => {
      try {
        const roleResponse = await get(urls.role.getAllRole, {
          headers: { roleFunction: "feeOverview" },
          params: {
            search: {
              $or: [{ roleName: "ACCOUNTANT" }, { roleName: "SUPER ADMIN" }],
            },
          },
        });
        console.log(roleResponse, "roleResponse");
        if (!roleResponse.data.data.length) return;
        const roleId = roleResponse.data.data[0]._id;

        const roleIds = roleResponse.data.data.map((r) => r._id);

        const employeeResponse = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "feeOverview" },
          params: {
            search: {
              role: { $in: roleIds },
            },
          },
        });
        setFormData((prev) => ({
          ...prev,
          name: employeeResponse.data.data.length
            ? employeeResponse.data.data[0]._id
            : "",
        }));
        setCollected(employeeResponse.data.data);
        setTotalCount(employeeResponse.data.count);
      } catch (error) {
        console.log("error", error);
      }
    };
    getCollected();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    console.log(name, "NAME");
    console.log(value, "values");
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "feeOverview" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "feeOverview" },
        });
        if (!res.data.data.length) {
          setClasses([]);
          setSections([]);
          //setSchoolStudents([]);
          setFormData((prev) => ({
            ...prev,
            className: "",
            section: "",
          }));

          return;
        }
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "feeOverview" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        if (!resSections.data.data.length) {
          setSections([]);
          //setSchoolStudents([]);
          setFormData((prev) => ({
            ...prev,
            className: res.data.data[0]._id,
            section: "all",
          }));
          return;
        }
        let searchParms;
        if (formData.section == "all") {
          searchParms = {
            academicYear: formData.academicYear,
            "academicInfo.class": res.data.data[0]._id,
          };
        } else {
          searchParms = {
            academicYear: formData.academicYear,
            "academicInfo.class": res.data.data[0]._id,
            "academicInfo.section": resSections.data.data[0]._id,
          };
        }
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "feeOverview" },
          params: {
            search: searchParms,
          },
        });
        // setSchoolStudents(searchStudents.data.data);
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: "all",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting._id]);

  const handleGetPrintPdf = async (id) => {
    try {
      {
        const getPrint = await get(
          `/${selectedSetting._id}/fee-receipt/download/report/staff/fee-receipt/${id}`,
          {
            headers: { roleFunction: "feeOverview" },
            responseType: "blob",
          }
        );

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleMarksExcelDownload = async () => {
    setDownload(true);
    try {
      {
        const getMarksListSheet = await get(
          `/${selectedSetting._id}/fee-receipt/download/report/fee-overview`,
          {
            headers: { roleFunction: "feeOverview" },
            params: {
              academicYearId: formData.academicYear,
              cashierId: formData.collectedby,
              receiptType: formData.feereceipt,
              classId: formData.class,
              sectionId: formData.section,
              fromDate: fromDate,
              toDate: toDate,
            },
            responseType: "blob",
          }
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const academicYearGet = formData.academicYear;
          const getExcelByYearId = academicYears.find(
            (excel) => excel._id === academicYearGet
          );
          const classename = formData.class;
          const getExcelByClassId = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = formData.section;
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const receiptss = formData.feereceipt;
          const getExcelreceiptById = receipts.find(
            (excel) => excel._id === receiptss
          );
          const cashier = formData.collectedby;
          const getExcelcashierById = receipts.find(
            (excel) => excel._id === receiptss
          );

          link.setAttribute(
            "download",
            `${getExcelByYearId.academicYearFrom}-${getExcelByYearId.academicYearTo} ${getExcelByClassId.className}th std ,${getExcelsectionById.sectionName}, ${getExcelreceiptById.name}.xlsx`
          );
          document.body.appendChild(link);
          link.click();

          link.parentNode.removeChild(link);
        }
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(error.message);
    }
    setDownload(false);
  };
  const handleFeeOverviewSearchSubmit = async (e) => {
    e.preventDefault();
    setFinder(true);
    try {
      const searchParams = {
        headers: { roleFunction: "feeOverview" },
        params: {
          academicYearId: formData.academicYear,
          cashierId: formData.collectedby,
          receiptType: formData.feereceipt,
          classId: formData.class,
          sectionId: formData.section,
          fromDate: fromDate,
          toDate: toDate,
        },
      };
      const differentMode = await get(
        urls.studentsFeeOverview.getAmountCollectedWithDifferentModes,
        { ...searchParams, sectionId: "" }
      );
      setDifferentFeeOverView({
        modes: differentMode.data.data.finalResult,
        total: differentMode.data.data.results[0].totalCollectedAmount,
      });

      const searchStudent = await get(
        urls.studentsFeeOverview.getAll,
        searchParams
      );
      setMasterFeeOverview(searchStudent.data.data);
      setFilterFeeOverview(searchStudent.data.data);
      setTotalCount(searchStudent.data.count);
    } catch (error) {
      console.log(error);
      setFinder(false);
    }
    setFinder(false);
  };

  console.log(differentFeeOverView, "difffff");
  console.log(masterFeeOverview, "studeee");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    // filterData(event.target.value, searchValue);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    filterData(category, event.target.value);
  };

  const filterData = (category, search) => {
    const filteredResults = masterFeeOverview.filter((data) => {
      if (category == "receiptNumber") {
        let lengthofgivensearch = search.length;
        let receiptNumber = data.receiptNumber.substring(
          0,
          lengthofgivensearch
        );
        return search == receiptNumber;
      } else if (category === "totalAmountPaid") {
        return data.totalAmountPaid.toString().includes(search);
      } else if (category === "paymentMode") {
        let paymentMode = data.paymentMode
          .toLowerCase()
          .includes(search.toLowerCase());
        return paymentMode;
      } else if (category === "name") {
        return data.studentDetails.name
          .toLowerCase()
          .includes(search.toLowerCase());
      } else if (category === "admissionNumber") {
        let admissionNumber = data.studentDetails.admissionNumber
          .toLowerCase()
          .includes(search.toLowerCase());
        return admissionNumber;
      }
      return true;
    });
    setFilterFeeOverview(filteredResults);
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <div>
        <SectionSubHeader title="Fee Overview" />
        <Card style={{ margin: "20px 0", padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleFeeOverviewSearchSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Collected By</InputLabel>
                    <Select
                      label="Collected By"
                      name="collectedby"
                      value={formData.collectedby || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {collected &&
                        collected.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.basicInfo.empName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Fee Receipt</InputLabel>
                    <Select
                      label="Fee Receipt"
                      id="demo-simple-select-filled-feereceipt"
                      name="feereceipt"
                      value={formData.feereceipt || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {receipts &&
                        receipts.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel id="demo-simple-select-helper-label">
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      id="demo-simple-select-filled"
                      name="class"
                      value={formData.class || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ alignSelf: "center" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ alignSelf: "center" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD-MM-YYYY"
                      value={toDate}
                      onChange={(newValue) => setToDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={1}
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "left",
                      lg: "left",
                    },
                  }}
                >
                  <LoadingButton
                    loading={finder}
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                      color: "#fff",
                    }}
                  >
                    find
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">Payment</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Total</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Cash</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Cheque</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">DD</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Chalan</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">NetBanking</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Online</span>
              </TableCell>
            </TableRow>
          </TableHead>
          {!Object.keys(differentFeeOverView.modes).length ? (
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="center" colSpan={8}>
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    No data found
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="center">
                  Amount
                  <CurrencyRupeeIcon size="small" fontSize="10px" />
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.total}
                </TableCell>

                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Cash}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Cheque}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.DD}{" "}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Card}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Netbanking}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Upi}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={Object.keys(differentFeeOverView.modes).length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      <Box
        sx={{
          alignSelf: "flex-end",
          display: "flex",
          justifyContent: "flex-end",
          columnGap: "10px",
          my: 2,
        }}
      >
        <Select
          value={category}
          onChange={handleCategoryChange}
          size="small"
          style={{ marginRight: "10px" }}
          sx={{ width: "160px" }}
        >
          <MenuItem value="receiptNumber">Receipt No</MenuItem>
          <MenuItem value="totalAmountPaid">Amount</MenuItem>
          <MenuItem value="paymentMode">Payment Mode</MenuItem>
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="admissionNumber">Admission No</MenuItem>
        </Select>
        <TextField
          label="Search"
          value={searchValue}
          onChange={handleSearchChange}
          size="small"
        />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">#SL</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Receipt No</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Paid Date</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Amount</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Payment Mode</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Name</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Class</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Admission No</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">View</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterFeeOverview
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.receiptNumber}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {/* {row.createdAt} */}
                    {dayjs(row.createdAt).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.totalAmountPaid}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.paymentMode}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.studentDetails.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.studentDetails.classNumber}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.studentDetails.admissionNumber}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => handleGetPrintPdf(row._id)}
                    >
                      STAFF COPY
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!masterFeeOverview.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={masterFeeOverview.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          loading={download}
          onClick={handleMarksExcelDownload}
          size="small"
          variant="contained"
          color="primary"
          sx={{
            display: masterFeeOverview.length ? "block" : "none",
          }}
        >
          DOWNLOAD
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default FeeOverview;
