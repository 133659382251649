import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import image from "../../assets/Images/akfounder.webp";
import { Link } from "react-router-dom";
import NavBar from "../navbar/Navbar";
import Footer from "../Footer/Footer";
import TopNav from "../navbar/TopNav";

const ImageContainer = styled(Grid)(({ theme }) => ({
  borderShadow: "10px 20px #0000",
  padding: "15px",
  width: "65%",
  marginLeft: "20%",
  [theme.breakpoints.down("md")]: {
    marginBottom: "15px",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "15px",
    width: "60%",
  },
}));

const AboutTitle = styled(Box)(({ theme }) => ({
  // color: "#F86F03",
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const FounderTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const FounderName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const AboutContainer = styled(Box)(({ theme }) => ({
  padding: "20px",
  [theme.breakpoints.down("md")]: {
    padding: "15px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const AboutFounder = () => {
  return (
    <React.Fragment>
      <TopNav />
      <NavBar />
      <AboutTitle>
        <FounderTitle>About Founder</FounderTitle>
        <Typography sx={{ color: "white" }}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "white",
            }}>
            <Typography
              variant="span"
              sx={{
                color: "white",
                textTransform: "uppercase",
                "&:hover": {
                  color: "#F86F03",
                },
              }}>
              HOME
            </Typography>
          </Link>
          &nbsp; / About
        </Typography>
      </AboutTitle>

      <AboutContainer>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box textAlign="center" padding="20px">
              <Typography
                sx={{
                  textShadow: "1px 1px 1px #F86F03",
                  color: "#1b61ff",
                  fontSize: "1.2rem",
                }}>
                <b>
                  {" "}
                  {"\u201C"}
                  The end-product of education should be a free creative man,
                  who can battle against historical circumstances and
                  adversities of nature.{"\u201D"}
                </b>
              </Typography>
              <Typography variant="body2" color="#F86F03">
                - Dr. Sarvepalli Radhakrishnan
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ImageContainer
              boxShadow={"15px 10px 10px #969c96"}
              textAlign="center">
              <img
                src={image}
                alt=""
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
              />
              <FounderName
                align="center"
                sx={{ marginLeft: "2%", fontWeight: 700 }}>
                FOUNDER
              </FounderName>
            </ImageContainer>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Typography
              style={{
                fontFamily: "arial",
                textAlign: "start",
              }}
              className="font-sans">
              <b>
                <b style={{ color: "#30A2FF" }}>"</b>Founder Name,{" "}
              </b>
              the founder of the Akshara Public School, is a "Lorem ipsum dolor
              sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat. Duis aute irure dolor in reprehenderit
              deserunt mollit anim id est laborum."
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit deserunt mollit anim id est laborum."
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit deserunt mollit anim id est laborum."
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit deserunt mollit anim id est laborum.""Lorem sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit deserunt mollit anim id est laborum.""
              <b style={{ color: "#30A2FF" }}>"</b>
            </Typography>
          </Grid>
        </Grid>
      </AboutContainer>

      <Footer />
    </React.Fragment>
  );
};

export default AboutFounder;
