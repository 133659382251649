import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  TablePagination,
  InputAdornment,
  ButtonGroup,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
  TableBody,
  tableCellClasses,
} from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { get, put, del } from "../../services/apis";
import dayjs from "dayjs";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { TableContainer } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../ShowComponent";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { Delete } from "@mui/icons-material";
import { Search } from "@material-ui/icons";
import moment from "moment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    width: "100%",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const styles = {
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const Enquiries = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [classes, setClasses] = useState([]);
  const [formData, setFormData] = useState({});
  const { selectedSetting } = useContext(SettingContext);
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showEnquirie, setShowEnquirie] = useState([]);
  const [loader, setLoader] = useState(false);
  const [appRejLoader, setAppRejLoader] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeButton, setActiveButton] = useState("Pending");

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ====================================

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndStartDate = (e) => {
    setEndDate(e.target.value);
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "preaddmissionEnquiry" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "preaddmissionEnquiry" },
        });

        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await get(`${urls.preAdmission.getList}`, {
        headers: { roleFunction: "preaddmissionEnquiry" },
        params: {
          search: {
            "studentDetails.academicDetails.academicYear":
              formData.academicYear,
            "studentDetails.academicDetails.class": formData.schoolClass,
            fromDate: startDate,
            toDate: endDate,
          },
        },
      });
      setList(res.data.data);
      setFilteredList(res.data.data.filter((e) => e.status === activeButton));
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const updateList = async () => {
    try {
      const res = await get(`${urls.preAdmission.getList}`, {
        headers: { roleFunction: "preaddmissionEnquiry" },
        params: {
          search: {
            "studentDetails.academicDetails.academicYear":
              formData.academicYear,
            "studentDetails.academicDetails.class": formData.schoolClass,
            fromDate: startDate,
            toDate: endDate,
          },
        },
      });
      setList(res.data.data);
      setFilteredList(res.data.data.filter((e) => e.status === activeButton));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        `${urls.preAdmission.delete}`,
        id,
        {},
        { roleFunction: "preaddmissionEnquiry" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleupdate = async (enquiryId, status) => {
    setAppRejLoader(status);
    const res = await put(
      `${urls.preAdmission.approveddata}${enquiryId}`,
      "",
      { status },
      { headers: { roleFunction: "preaddmissionEnquiry" } }
    );

    setList((prevList) =>
      prevList.map((item) => (item._id === enquiryId ? res.data.data : item))
    );
    setFilteredList((prevList) =>
      prevList
        .map((item) => (item._id === enquiryId ? res.data.data : item))
        .filter((e) => e.status === activeButton)
    );
    setAppRejLoader("");
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      filteredList.length > 0 &&
        setSearchFilter(
          filteredList.filter((ele) =>
            ele.studentDetails.basicDetails.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter(filteredList);
    }
  };

  const handleButtonClick = (status) => {
    setActiveButton(status);
  };

  useEffect(() => {
    if (!activeButton) {
      return setFilteredList(list);
    }
    setFilteredList(list.filter((e) => e.status === activeButton));
  }, [activeButton]);

  const handleClickToViewEnquirie = (enquirie) => {
    setShowEnquirie(enquirie);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Enquiries" />
      <Card style={{ marginBottom: "15px", padding: 10 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl style={styles.textfield} required>
                <InputLabel id="demo-simple-select-filled-label">
                  Academic Year
                </InputLabel>
                <Select
                  variant="outlined"
                  label="academic year"
                  size="small"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="academicYear"
                  value={formData.academicYear || ""}
                  onChange={handleOnChange}
                >
                  {academicYears &&
                    academicYears.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.academicYearFrom} - {row.academicYearTo}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel>Class</InputLabel>
                <Select
                  size="small"
                  variant="outlined"
                  label="class"
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  name="schoolClass"
                  value={formData.schoolClass || ""}
                  onChange={handleOnChange}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="From Date"
                    inputFormat="DD-MM-YYYY"
                    value={startDate || ""}
                    onChange={setStartDate}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="To Date"
                    inputFormat="DD-MM-YYYY"
                    value={endDate || ""}
                    onChange={setEndDate}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              style={{
                alignSelf: "flex-end",
              }}
            >
              <LoadingButton
                loading={loader}
                sx={{
                  color: "#fff",
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                }}
                type="submit"
                size="small"
                variant="contained"
              >
                Find
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
        }}
      >
        <Grid item xs={12} md={6} lg={3} alignItems="center">
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {["Pending", "Approved", "Rejected"].map((v, i) => (
              <Button
                key={i}
                variant="outlined"
                onClick={() => {
                  handleButtonClick(v);
                }}
                style={{
                  backgroundColor: activeButton === v ? "#1b3779" : "",
                  color: activeButton === v ? "white" : "black",
                  textTransform: "capitalize",
                }}
              >
                {v}:{list.filter((item) => item.status === v).length}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            style={styles.textfield}
            label="Search Student"
            placeholder="Enter Student Name"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow>
              <TableCell align="center" sx={{ color: "white" }}>
                #SL
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Student Name
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Class
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Submitted On
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Approval Status
              </TableCell>

              <TableCell align="center" sx={{ color: "white" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {search
            ? searchFilter
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, i) => (
                  <TableRow key={data._id}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">
                      {data.studentDetails.basicDetails.name}
                    </TableCell>
                    <TableCell align="center">
                      {data.studentDetails?.academicDetails?.class?.className}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(data.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <ShowComponent
                        roleFunction="preaddmissionEnquiry"
                        action="update"
                      >
                        <Stack
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                        >
                          {["Approved", "Pending"].includes(data.status) ? (
                            <LoadingButton
                              variant="contained"
                              size="small"
                              loading={appRejLoader == "Rejected"}
                              onClick={() => handleupdate(data._id, "Rejected")}
                              color="error"
                            >
                              Reject
                            </LoadingButton>
                          ) : null}

                          {["Rejected", "Pending"].includes(data.status) ? (
                            <LoadingButton
                              size="small"
                              variant="contained"
                              loading={appRejLoader == "Approved"}
                              onClick={() => handleupdate(data._id, "Approved")}
                              color="success"
                            >
                              Approve
                            </LoadingButton>
                          ) : null}
                        </Stack>
                      </ShowComponent>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="View">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() =>
                              handleClickToViewEnquirie(data?.studentDetails)
                            }
                          >
                            <VisibilityIcon fontSize="small" color="primary" />
                          </IconButton>
                        </Tooltip>

                        <ShowComponent
                          roleFunction="preaddmissionEnquiry"
                          action="delete"
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => setDeleteModal(data._id)}
                            >
                              <Delete fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>

                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
            : filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, i) => (
                  <TableRow key={data._id}>
                    <TableCell align="center">
                      {page * rowsPerPage + i + 1}
                    </TableCell>
                    <TableCell align="center">
                      {data.studentDetails.basicDetails.name}
                    </TableCell>
                    <TableCell align="center">
                      {data.studentDetails?.academicDetails?.class?.className}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(data.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <ShowComponent
                        roleFunction="preaddmissionEnquiry"
                        action="update"
                      >
                        <Stack
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                        >
                          {["Approved", "Pending"].includes(data.status) ? (
                            <LoadingButton
                              variant="contained"
                              size="small"
                              loading={appRejLoader == "Rejected"}
                              onClick={() => handleupdate(data._id, "Rejected")}
                              color="error"
                            >
                              Reject
                            </LoadingButton>
                          ) : null}

                          {["Rejected", "Pending"].includes(data.status) ? (
                            <LoadingButton
                              size="small"
                              variant="contained"
                              loading={appRejLoader == "Approved"}
                              onClick={() => handleupdate(data._id, "Approved")}
                              color="success"
                              // sx={{ marginLeft: 2 }}
                            >
                              Approve
                            </LoadingButton>
                          ) : null}
                        </Stack>
                      </ShowComponent>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="View">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() =>
                              handleClickToViewEnquirie(data?.studentDetails)
                            }
                          >
                            <VisibilityIcon fontSize="small" color="primary" />
                          </IconButton>
                        </Tooltip>
                        <ShowComponent
                          roleFunction="preaddmissionEnquiry"
                          action="delete"
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => setDeleteModal(data._id)}
                            >
                              <Delete fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>

                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
        </Table>
        {!filteredList.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={search ? searchFilter.length : filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start,",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* =========Book information modal =============== */}
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{
            backgroundColor: "#1b3779",
            color: "white",
            textAlign: "center",
          }}
        >
          Enquiry Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* ======== Desktop view table ============ */}
        <DialogContent
          dividers
          sx={{
            display: {
              xs: "none",
              sm: "block",
              md: "block",
              lg: "block",
            },
          }}
        >
          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Academic Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Class
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.academicDetails &&
                  showEnquirie.academicDetails.class.className
                    ? showEnquirie.academicDetails.class.className
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Academic Year
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.academicDetails &&
                  showEnquirie.academicDetails.academicYear
                    ? `${showEnquirie.academicDetails.academicYear.academicYearFrom}-${showEnquirie.academicDetails.academicYear.academicYearTo}`
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Basic Information
          </Typography>

          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Student Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails && showEnquirie.basicDetails?.name
                    ? showEnquirie.basicDetails?.name
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Date Of Birth
                </StyledTableCell>
                <StyledTableCell align="left">
                  {dayjs(
                    showEnquirie.basicDetails && showEnquirie.basicDetails.dob
                      ? showEnquirie.basicDetails.dob
                      : "NA"
                  ).format("DD-MM-YYYY")}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Gender
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails?.gender
                    ? showEnquirie.basicDetails?.gender
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Blood Group
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails?.bloodGroup
                    ? showEnquirie.basicDetails?.bloodGroup
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Religion
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails && showEnquirie.basicDetails.caste
                    ? showEnquirie.basicDetails.caste
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Caste Income Certificate Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails.casteIncomeCertificateNumber
                    ? showEnquirie.basicDetails.casteIncomeCertificateNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Mother Tongue
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails.motherTongue
                    ? showEnquirie.basicDetails.motherTongue
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Birth Place
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails.birthPlace
                    ? showEnquirie.basicDetails.birthPlace
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Aadhar Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails.aadharNumber
                    ? showEnquirie.basicDetails.aadharNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Contact Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Contact Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails?.contactNumber
                    ? showEnquirie.contactDetails?.contactNumber
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Guardian Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails?.guardianName
                    ? showEnquirie.contactDetails?.guardianName
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Guardian Contact Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.guardianContactNumber
                    ? showEnquirie.contactDetails.guardianContactNumber
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Alternate Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.alternateNumber
                    ? showEnquirie.contactDetails.alternateNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Relation With Guardian
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.relationWithGuardian
                    ? showEnquirie.contactDetails.relationWithGuardian
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  National Id
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails?.nationalId
                    ? showEnquirie.contactDetails?.nationalId
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Present Address
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.presentAddress
                    ? showEnquirie.contactDetails.presentAddress
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Permanent Address
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.permanentAddress
                    ? showEnquirie.contactDetails.permanentAddress
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Previous School Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow width={200}>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Previous School Name
                </StyledTableCell>
                <StyledTableCell>
                  {showEnquirie.previousSchoolDetails &&
                  showEnquirie.previousSchoolDetails.schoolName
                    ? showEnquirie.previousSchoolDetails.schoolName
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Previous Class
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.previousSchoolDetails &&
                  showEnquirie.previousSchoolDetails.class
                    ? showEnquirie.previousSchoolDetails.class
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  TC Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.previousSchoolDetails &&
                  showEnquirie.previousSchoolDetails.tcNumber
                    ? showEnquirie.previousSchoolDetails.tcNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Father Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.name
                    ? showEnquirie.fatherDetails?.name
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Contact Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.contactNumber
                    ? showEnquirie.fatherDetails?.contactNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Education
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.education
                    ? showEnquirie.fatherDetails?.education
                    : "NA"}
                </StyledTableCell>

                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Profession
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.profession
                    ? showEnquirie.fatherDetails?.profession
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Designation
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.designation
                    ? showEnquirie.fatherDetails?.designation
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Mother Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.name
                    ? showEnquirie.motherDetails?.name
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Contact Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.contactNumber
                    ? showEnquirie.motherDetails?.contactNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Education
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.education
                    ? showEnquirie.motherDetails?.education
                    : "NA"}
                </StyledTableCell>

                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Profession
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.profession
                    ? showEnquirie.motherDetails?.profession
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Designation
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.designation
                    ? showEnquirie.motherDetails?.designation
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Other Information:
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Health Condition
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.otherDetails &&
                  showEnquirie.otherDetails.healthCondition
                    ? showEnquirie.otherDetails.healthCondition
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Email
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.otherDetails && showEnquirie.otherDetails.email
                    ? showEnquirie.otherDetails.email
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Hostel Required
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.otherDetails &&
                  showEnquirie.otherDetails.hostelRequired
                    ? showEnquirie.otherDetails.hostelRequired
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Transport Required
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.otherDetails &&
                  showEnquirie.otherDetails.transportRequired
                    ? showEnquirie.otherDetails.transportRequired
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </DialogContent>

        {/* ======== Mobile view table ============ */}
        <DialogContent
          dividers
          sx={{
            display: {
              xs: "block",
              sm: "none",
              md: "none",
              lg: "none",
            },
          }}
        >
          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Academic Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Class
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.academicDetails &&
                  showEnquirie.academicDetails.class.className
                    ? showEnquirie.academicDetails.class.className
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Academic Year
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.academicDetails &&
                  showEnquirie.academicDetails.academicYear
                    ? `${showEnquirie.academicDetails.academicYear.academicYearFrom}-${showEnquirie.academicDetails.academicYear.academicYearTo}`
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Basic Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Student Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails && showEnquirie.basicDetails?.name
                    ? showEnquirie.basicDetails?.name
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Date Of Birth
                </StyledTableCell>
                <StyledTableCell align="left">
                  {dayjs(
                    showEnquirie.basicDetails && showEnquirie.basicDetails.dob
                      ? showEnquirie.basicDetails.dob
                      : "NA"
                  ).format("DD-MM-YYYY")}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Gender
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails?.gender
                    ? showEnquirie.basicDetails?.gender
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Blood Group
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails?.bloodGroup
                    ? showEnquirie.basicDetails?.bloodGroup
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Religion
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails && showEnquirie.basicDetails.caste
                    ? showEnquirie.basicDetails.caste
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Caste Income Certificate Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails.casteIncomeCertificateNumber
                    ? showEnquirie.basicDetails.casteIncomeCertificateNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Mother Tongue
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails.motherTongue
                    ? showEnquirie.basicDetails.motherTongue
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Birth Place
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails.birthPlace
                    ? showEnquirie.basicDetails.birthPlace
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Aadhar Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.basicDetails &&
                  showEnquirie.basicDetails.aadharNumber
                    ? showEnquirie.basicDetails.aadharNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Contact Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Contact Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails?.contactNumber
                    ? showEnquirie.contactDetails?.contactNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Guardian Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails?.guardianName
                    ? showEnquirie.contactDetails?.guardianName
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Guardian Contact Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.guardianContactNumber
                    ? showEnquirie.contactDetails.guardianContactNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Alternate Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.alternateNumber
                    ? showEnquirie.contactDetails.alternateNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Relation With Guardian
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.relationWithGuardian
                    ? showEnquirie.contactDetails.relationWithGuardian
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  National Id
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails?.nationalId
                    ? showEnquirie.contactDetails?.nationalId
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Present Address
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.presentAddress
                    ? showEnquirie.contactDetails.presentAddress
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Permanent Address
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.contactDetails &&
                  showEnquirie.contactDetails.permanentAddress
                    ? showEnquirie.contactDetails.permanentAddress
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Previous School Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow width={200}>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Previous School Name
                </StyledTableCell>
                <StyledTableCell>
                  {showEnquirie.previousSchoolDetails &&
                  showEnquirie.previousSchoolDetails.schoolName
                    ? showEnquirie.previousSchoolDetails.schoolName
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Previous Class
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.previousSchoolDetails &&
                  showEnquirie.previousSchoolDetails.class
                    ? showEnquirie.previousSchoolDetails.class
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  TC Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.previousSchoolDetails &&
                  showEnquirie.previousSchoolDetails.tcNumber
                    ? showEnquirie.previousSchoolDetails.tcNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Father Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.name
                    ? showEnquirie.fatherDetails?.name
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Contact Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.contactNumber
                    ? showEnquirie.fatherDetails?.contactNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Education
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.education
                    ? showEnquirie.fatherDetails?.education
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Profession
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.profession
                    ? showEnquirie.fatherDetails?.profession
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Designation
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.fatherDetails &&
                  showEnquirie.fatherDetails?.designation
                    ? showEnquirie.fatherDetails?.designation
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Mother Information
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.name
                    ? showEnquirie.motherDetails?.name
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Contact Number
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.contactNumber
                    ? showEnquirie.motherDetails?.contactNumber
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Education
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.education
                    ? showEnquirie.motherDetails?.education
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Profession
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.profession
                    ? showEnquirie.motherDetails?.profession
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Designation
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.motherDetails &&
                  showEnquirie.motherDetails?.designation
                    ? showEnquirie.motherDetails?.designation
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Typography variant="body" fontSize="16px" fontWeight={600}>
            Other Information:
          </Typography>
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              mb: 2,
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Health Condition
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.otherDetails &&
                  showEnquirie.otherDetails.healthCondition
                    ? showEnquirie.otherDetails.healthCondition
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Email
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.otherDetails && showEnquirie.otherDetails.email
                    ? showEnquirie.otherDetails.email
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Hostel Required
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.otherDetails &&
                  showEnquirie.otherDetails.hostelRequired
                    ? showEnquirie.otherDetails.hostelRequired
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Transport Required
                </StyledTableCell>
                <StyledTableCell align="left">
                  {showEnquirie.otherDetails &&
                  showEnquirie.otherDetails.transportRequired
                    ? showEnquirie.otherDetails.transportRequired
                    : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Wrapper>
  );
};

export default Enquiries;
