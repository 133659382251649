import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Delete, Edit } from "@mui/icons-material";
import DateModal from "./DateModal";
import "../../../styles/components/AcademicYear/AcademicYearTable.scss";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  Card,
  Grid,
  Tooltip,
  Typography,
  Box,
  TablePagination,
  IconButton,
} from "@mui/material";
import DeleteModal from "../Academic/DeleteModal";
import { useContext } from "react";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SettingSelector from "../../SettingSelector";
import ShowComponent from "../../ShowComponent";
import { toast } from "react-toastify";

const AcademicYear = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateAcademicYear, setUpdateAcademicYear] = useState(undefined);
  const { setSettingsContext, selectedSetting } = useContext(SettingContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [id, setId] = useState("");
  const [popup, setPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [showYear, setShowYear] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [loader, setLoader] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateAcademicYear(undefined);
    setId("");
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        urls.administrator.academicYearDel,
        id,
        {},
        { roleFunction: "academicYear" }
      );
      if (res.data.success) {
        setAcademicYears(
          academicYears.filter((item) => item._id !== res.data.data._id)
        );
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.administrator.academicYearPut, id, data, {
        headers: { roleFunction: "academicYear" },
      });
      if (response.data.success) {
        setAcademicYears(
          academicYears.map((e) => {
            if (e._id === response.data.data._id) {
              return response.data.data;
            } else {
              return e;
            }
          })
        );
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await post(urls.administrator.academicYearPost, data, {
        headers: { roleFunction: "academicYear" },
      });
      if (res.data.success) {
        setAcademicYears([...academicYears, res.data.data]);
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      setId(id);
      const getUserRoleById = academicYears.find((item) => item._id === id);
      setUpdateAcademicYear(getUserRoleById);
    } catch (error) {
      console.log(error);
    }
  };

  const openActivePopup = (id, row) => {
    setPopup(true);
    setActiveId(id);
    setShowYear(row);
    // setShowYear(`${row.academicYearFrom}-${row.academicYearTo}`);
  };

  const handleClosePopup = () => {
    setPopup(false);
    setActiveId("");
    setShowYear("");
  };

  const handleYearActive = async (e) => {
    e.preventDefault();
    try {
      const res = await put(
        urls.administrator.academicYearActive,
        activeId,
        {},
        { headers: { roleFunction: "academicYear" } }
      );
      const activeYear = `${res.data.data.academicYearFrom}-${res.data.data.academicYearTo}`;
      setSettingsContext((prev) => ({
        ...prev,
        activeAcademicYear: activeYear,
      }));
      if (res.data.success) {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears([...res.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
    handleClosePopup();
  };

  useEffect(() => {
    const getAllAcademicYear = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getAllAcademicYear();
  }, [selectedSetting]);

  return (
    <>
      <Card style={{ margin: 15, padding: 10 }}>
        <Box
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          spacing={2}
        >
          <Typography
            style={{
              color: "red",
              fontFamily: "cursive",
              display: "inline-block",
            }}
          >
            Academic Year
          </Typography>
          {/* <SettingSelector /> */}
        </Box>
      </Card>
      <div style={{ margin: "15px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="simple table">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="academicYear-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="academicYear-table-header">
                    Academic Year
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="academicYear-table-header">Status</span>
                </TableCell>
                <TableCell align="center">
                  <span className="academicYear-table-header">Note</span>
                </TableCell>
                <TableCell align="center">
                  <span className="academicYear-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {academicYears
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.academicYearFrom}-{row.academicYearTo}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.note}
                      </TableCell>
                      <TableCell align="center">
                        <ShowComponent
                          roleFunction="academicYear"
                          action="update"
                        >
                          <Tooltip title="Update">
                            <IconButton
                              size="small"
                              sx={{ color: "#1b3779", mr: "5px" }}
                              onClick={() => handleEdit(row._id)}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        {/* <Tooltip title="Delete" disableInteractive>
                          <Button onClick={() => setDeleteModal(row._id)}>
                            <Delete />
                          </Button>
                        </Tooltip> */}
                        <ShowComponent
                          roleFunction="academicYear"
                          action="update"
                        >
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              background:
                                row.status === "active"
                                  ? "green"
                                  : "rgb(27 55 121)",

                              ":hover": {
                                background:
                                  row.status === "active"
                                    ? "green"
                                    : "rgb(27 55 121)",
                              },
                              width: "90px",
                              fontSize: "12px",
                            }}
                            onClick={
                              row.status === "active"
                                ? () => toast.info("Already active!")
                                : () => openActivePopup(row._id, row)
                            }
                          >
                            {row.status === "active" ? "activated" : "Activate"}
                          </Button>
                        </ShowComponent>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!academicYears.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={academicYears.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: " flex",
            justifyContent: "flex-start",
            alignItem: "center",
            my: 1,
          }}
        />

        {/* Active inactive modal */}
        <Dialog
          onClose={handleClosePopup}
          open={popup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" fontWeight={600}>
              Are you sure you want to{" "}
              {showYear.status === "active" ? "Inactive" : "Activate"}{" "}
              {`${showYear.academicYearFrom}-${showYear.academicYearTo}`}{" "}
              academic year?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePopup}>No</Button>
            <Button onClick={(e) => handleYearActive(e)} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <ShowComponent
          roleFunction="academicYear"
          action={id ? "update" : "add"}
        >
          <div className="add-icon">
            <DateModal
              loader={loader}
              handleSubmit={handleSubmit}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleUpdate={handleUpdate}
              updateAcademicYear={updateAcademicYear}
            />
          </div>
        </ShowComponent>
      </div>
    </>
  );
};

export default AcademicYear;
