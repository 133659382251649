import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  Fab,
  Grid,
  styled,
  TextareaAutosize,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";

import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const AddDesignation = (props) => {
  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [designation, setDesignation] = useState("");
  const [orderSequence, setOrderSequence] = useState(0);
  const [note, setNote] = useState("");
  const [editId, setEditId] = useState("");
  const [schoolDesignation, setSchoolDesignation] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (props.open || props.updateDesignation) {
      setDesignation("");
      setNote("");
      setOrderSequence(0);
      if (props.updateDesignation) {
        setDesignation(props.updateDesignation.designation);
        setOrderSequence(
          props.updateDesignation.orderSequence
            ? props.updateDesignation.orderSequence
            : ""
        );
        setNote(props.updateDesignation.note);
        setEditId(props.updateDesignation._id);
      }
    }
  }, [props.open, props.updateDesignation]);

  useEffect(() => {
    const schoolDesignationGet = async () => {
      try {
        const response = await get(`${urls.class.getClass}`, {
          headers: { roleFunction: "designation" },
        });
        setSchoolDesignation(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    schoolDesignationGet();
  }, []);

  const handleDesignation = (e) => {
    setDesignation(e.target.value);
  };

  const handleDesignationNote = (e) => {
    setNote(e.target.value);
  };
  const handleOrderSequence = (e) => {
    setOrderSequence(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.updateDesignation) {
      await props.handleUpdate(
        {
          designation,
          orderSequence,
          note,
        },
        props.updateDesignation._id
      );
    } else {
      await props.handleSubmit(e, {
        designation,
        orderSequence,
        note,
      });
    }
  };

  const handleCloseModal = () => {
    setDesignation("");
    setOrderSequence();
    setNote("");
    setEditId("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add Designation" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}>
          <Add />
        </Fab>
      </Tooltip>

      {/*=============== Add Designation =================== */}
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form
          className="form-tag"
          onSubmit={handleSubmit}
          style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                fontSize="20px"
                fontWeight="bold">
                {props.updateDesignation
                  ? "Update Designation"
                  : "Add Designation"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                required
                size="small"
                id="filled-required"
                variant="outlined"
                label="Designation"
                value={designation}
                onChange={handleDesignation}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                required
                size="small"
                type="number"
                id="filled-required"
                variant="outlined"
                label="Order Sequence"
                value={orderSequence}
                onChange={handleOrderSequence}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextareaAutosize
                maxRows={4}
                aria-label="maximum height"
                placeholder="Drop a note"
                value={note}
                onChange={handleDesignationNote}
                style={{
                  width: "100%",
                  maxHeight: 110,
                  borderRadius: "5px",
                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  },
                }}>
                <Button
                  onClick={() => props.handleClose()}
                  variant="contained"
                  size="small"
                  type="button"
                  color="error"
                  sx={{
                    color: "#fff",
                  }}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={props.loader}
                  disabled={!designation}
                  size="small"
                  type="submit"
                  variant="contained"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}>
                  {props.updateDesignation ? "Updtae" : "Submit"}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>
      {/* ============================== */}
    </div>
  );
};

export default AddDesignation;
