import React, { useContext, useState } from "react";
import css from "../NavbarComponents/styles/Topnav.module.css";
import Divider from "@mui/material/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Typography, Box, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import NavItem from "./NavItem";
import AutoGraphRoundedIcon from "@mui/icons-material/AutoGraphRounded";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import KayakaLogo from "../assets/Images/kaykalogo.png";
import SettingContext from "../context/SettingsContext";

const NavContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));
const SideContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: 10,
  position: "absolute",
  bgcolor: "transparent",
  borderBottom: "0.1px solid #828998",
  width: "100%",

  // [theme.breakpoints.down("md")]: {
  //   marginTop: "60px",
  // },
}));

const Mainnav = () => {
  const [showMenuItem, setShowMenuItem] = useState(false);
  const { selectedSetting } = useContext(SettingContext);

  const handleMenuItem = () => {
    setShowMenuItem(true);
  };

  const styles = {
    background: {
      backgroundImage: "url(/bannback.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      width: "100%",
      opacity: "1",
    },
    logo: {
      padding: "0 10px",
      height: "90%",
      objectFit: "contain",
      width: "auto",
      maxWidth: "150px",
    },
  };

  const [home, setHome] = useState(null);
  const open = Boolean(home);
  const handleClick = (e) => {
    setHome(e.currentTarget);
    navigate("/");
  };

  const [about, setAbout] = useState(null);
  const open1 = Boolean(about);
  const handleClick1 = (e) => {
    setAbout(e.currentTarget);
  };
  const handleClose1 = () => {
    // if (path){navigate(path)}
    setAbout(null);
  };

  const [facilities, setFacilities] = useState(null);
  const open2 = Boolean(facilities);
  const handleClick2 = (e) => {
    setFacilities(e.currentTarget);
  };
  const handleClose2 = () => {
    setFacilities(null);
  };

  const [achievment, setAchievment] = useState(null);
  const open4 = Boolean(achievment);
  const handleClick4 = (e) => {
    setAchievment(e.currentTarget);
  };
  const handleClose4 = () => {
    setAchievment(null);
  };
  const [result, setResult] = useState(null);
  const open5 = Boolean(result);
  const handleClick5 = (e) => {
    setResult(e.currentTarget);
    navigate("/results");
  };
  const handleClose5 = () => {
    setResult(null);
  };
  const [contact, setContact] = useState(null);
  const open6 = Boolean(contact);
  const handleClick6 = (e) => {
    setContact(e.currentTarget);
    navigate("/contact-us");
  };
  const handleClose6 = () => {
    setContact(null);
  };

  const [admission, setAdmission] = useState(null);
  const open7 = Boolean(admission);
  const handleClick7 = (e) => {
    setAdmission(e.currentTarget);
    navigate("/pre-admission");
  };

  const [assignment, setAssignment] = useState(null);
  const open8 = Boolean(assignment);
  const handleClick8 = (e) => {
    setAssignment(e.currentTarget);
    navigate("/assignment");
  };

  const handleClose8 = () => {
    setAssignment(null);
  };

  // const [admission, setAdmission] = useState(null);
  // const open7 = Boolean(admission);
  // const handleClick7 = () => {
  //   setAdmission(e.currentTarget);
  //   navigate("/pre-admission");
  // };

  const navdata = [
    {
      href: "#",
      id: "button",
      "aria-haspopup": "true",
      "aria-expanded": open ? "true" : undefined,
      onClick: handleClick,
      variant: "contained",
      title: "Home hssgsfssd",
      path: "/",
    },
    {
      href: "#",
      id: "button",
      "aria-haspopup": "true",
      "aria-expanded": open1 ? "true" : undefined,
      onClick: handleClick1,
      variant: "contained",
      title: "About +",

      items: [
        {
          title: "About us",
          pathName: "/about/overview",
        },
        {
          title: "About Founder",
          pathName: "/about/founder",
        },
        {
          title: " Vision & Mission",
          pathName: "/about/visionandmission",
        },
      ],
    },

    {
      href: "#",
      id: "button",
      "aria-haspopup": "true",
      variant: "contained",
      title: "Pre-Admission ",
      onClick: handleClick7,
      path: "/pre-admission",
    },
    {
      href: "#",
      id: "button",
      "aria-haspopup": "true",
      variant: "contained",
      title: "Gallery ",
      path: "/home-gallery",
      onClick: () => navigate("/home-gallery"),
    },

    {
      href: "#",
      id: "button",
      "aria-haspopup": "true",
      "aria-expanded": open2 ? "true" : undefined,
      onClick: handleClick2,
      variant: "contained",
      title: "Facilities + ",
      items: [
        {
          title: "Food",
          pathName: "/facilities/canteen",
        },
        {
          title: "Library",
          pathName: "/library",
        },
        {
          title: "Transport",
          pathName: "/transport",
        },
        {
          title: "Dance And Singing",
          pathName: "/facilities/dance-and-singing",
        },
        {
          title: "Lab Facilities",
          pathName: "/facilities/labs",
        },
      ],
    },

    {
      href: "#",
      id: "button",
      "aria-haspopup": "true",
      "aria-expanded": open8 ? "true" : undefined,
      onClick: handleClick8,
      variant: "contained",
      title: "Assignment ",
      path: "/assignment",
    },

    {
      href: "#",
      id: "button",
      "aria-haspopup": "true",
      "aria-expanded": open5 ? "true" : undefined,
      onClick: handleClick5,
      variant: "contained",
      title: "Results",
      path: "/results",
    },

    {
      href: "#",
      id: "button",
      "aria-haspopup": "true",
      "aria-expanded": open6 ? "true" : undefined,
      onClick: handleClick6,
      variant: "contained",
      title: "Contact us ",
      path: "/contact-us",
    },
  ];

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  let navigate = useNavigate();
  const { pathname } = useLocation();
  // console.log(pathname);

  let handlePath = (data) => {
    navigate(data);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(true)}
      onKeyDown={toggleDrawer(true)}
    >
      <List>
        {navdata &&
          navdata.map((data, index) => {
            return (
              <React.Fragment key={index}>
                {/* <ListItem
                disablePadding
               
              ></ListItem> */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={data.items && <ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography onClick={handleClick}>{data.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data.items &&
                      data.items.map((item, itemIndex) => {
                        return (
                          <React.Fragment key={itemIndex}>
                            <MenuItem>
                              <Typography
                                component="div"
                                onClick={() => handlePath(item.pathName)}
                                sx={{
                                  color:
                                    pathname === item.pathName ? "red" : "",
                                }}
                              >
                                {item.title}
                              </Typography>
                            </MenuItem>
                          </React.Fragment>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            );
          })}
      </List>
      <Divider />
    </Box>
  );

  return (
    <>
      <MainBox
        sx={{
          padding: "0 60px",
        }}
      >
        <Link to="/">
          <img
            height={100}
            width={150}
            src={selectedSetting?.logo?.link || KayakaLogo}
          />
        </Link>

        <Box>
          <NavContainer className={css.navContent}>
            <ul className={css.content}>
              {navdata &&
                navdata.map((data, i) => {
                  return (
                    <React.Fragment key={i}>
                      <li>
                        <NavItem
                          title={data.title}
                          onClick={data.onClick}
                          open={data.open}
                          isSelected={pathname === data.path}
                          // path={handleClick}
                          // path1={handleClick1}
                        />
                      </li>
                    </React.Fragment>
                  );
                })}

              <Menu
                id="about"
                anchorEl={about}
                open={open1}
                onClick={handleClose1}
                style={{
                  marginTop: "4%",
                  Width: "15%",
                  textAlign: "center",
                }}
              >
                <Link to="/about/overview" style={{ textDecoration: "none" }}>
                  <MenuItem
                    style={{
                      color:
                        pathname == "/about/overview" ? "orangered" : "black",
                    }}
                  >
                    Overview
                  </MenuItem>
                </Link>
                <Link to="/about/founder" style={{ textDecoration: "none" }}>
                  <MenuItem
                    style={{
                      color:
                        pathname == "/about/founder" ? "orangered" : "black",
                    }}
                  >
                    About Founder
                  </MenuItem>
                </Link>
                <Link
                  to="/about/visionandmission"
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem
                    style={{
                      color:
                        pathname == "/about/visionandmission"
                          ? "orangered"
                          : "black",
                    }}
                  >
                    Vision & Mission
                  </MenuItem>
                </Link>
              </Menu>

              <Menu
                id="facilities"
                anchorEl={facilities}
                open={open2}
                onClick={handleClose2}
                style={{
                  marginTop: "4%",
                  Width: "15%",
                  textAlign: "center",
                }}
              >
                <NavLink
                  to="/facilities/canteen"
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem
                    style={{
                      color:
                        pathname == "/facilities/canteen"
                          ? "orangered"
                          : "black",
                    }}
                  >
                    Food
                  </MenuItem>
                </NavLink>
                <Link
                  to="/facilities/library"
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem
                    style={{
                      color:
                        pathname == "/facilities/library"
                          ? "orangered"
                          : "black",
                    }}
                  >
                    Library
                  </MenuItem>
                </Link>
                <Link
                  to="/facilities/transport"
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem
                    style={{
                      color:
                        pathname == "/facilities/transport"
                          ? "orangered"
                          : "black",
                    }}
                  >
                    Transport
                  </MenuItem>
                </Link>
                <Link
                  to="/facilities/dance-and-singing"
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem
                    style={{
                      color:
                        pathname == "/facilities/dance-and-singing"
                          ? "orangered"
                          : "black",
                    }}
                  >
                    Dance And Singing
                  </MenuItem>
                </Link>
                <Link to="/facilities/labs" style={{ textDecoration: "none" }}>
                  <MenuItem
                    style={{
                      color:
                        pathname == "/facilities/labs" ? "orangered" : "black",
                    }}
                  >
                    Lab Facilities
                  </MenuItem>
                </Link>
              </Menu>

              <Menu
                id="achievment"
                anchorEl={achievment}
                open={open4}
                onClick={handleClose4}
                style={{
                  marginTop: "4%",
                  Width: "15%",
                  textAlign: "center",
                }}
              ></Menu>

              {/* {showMenuItem && ( */}
              <Menu
                // id="assignment"
                // onClick={handleMenuItem}
                // anchorEl={assignment}
                // open={open8}
                // onClose={handleClose8}
                style={{
                  marginTop: "4%",
                  Width: "15%",
                  textAlign: "center",
                  background: "transparent",
                }}
              >
                Assignmnet
              </Menu>
              {/* )} */}

              {showMenuItem && (
                <Menu
                  id="result"
                  onClick={handleMenuItem}
                  anchorEl={result}
                  open={open5}
                  onClose={handleClose5}
                  style={{
                    marginTop: "4%",
                    Width: "15%",
                    textAlign: "center",
                  }}
                ></Menu>
              )}

              {showMenuItem && (
                <Menu
                  id="contact"
                  onClick={handleMenuItem}
                  anchorEl={contact}
                  open={open6}
                  onClose={handleClose6}
                  style={{
                    marginTop: "4%",
                    Width: "15%",
                    textAlign: "center",
                    background: "transparent",
                  }}
                ></Menu>
              )}
              {/* <Box component='div'
                open={open6}
                onClose={handleClose6}
              style={{
                marginTop: "4%",
                Width: "15%",
                textAlign: "center",
              }}
              ></Box> */}
            </ul>
          </NavContainer>
        </Box>

        {/* <Box>
          <AutoGraphRoundedIcon sx={{ color: "white" }} />
        </Box> */}

        <SideContainer>
          <div>
            <IconButton
              size="large"
              edge="start"
              color="info"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor="right"
              open={state.right}
              onClose={toggleDrawer(false)}
            >
              {list("right")}
            </Drawer>
          </div>
        </SideContainer>
      </MainBox>
    </>
  );
};

export default Mainnav;
