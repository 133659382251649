import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { ResponsiveContainer } from "recharts";

import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useContext } from "react";
import SectionsSubHeader from "../../../components/SectionSubHeader";
import { LoadingButton } from "@mui/lab";
import Wrapper from "../../Wrapper";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },

  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const DivisionWiseReportPrintable = React.forwardRef((props, ref) => {
  const { graphData, selectedClassName } = props;

  const exams = graphData?.labels || [];
  const subjects = graphData?.dataSets?.map((dataset) => dataset.label) || [];

  const data = subjects.map((subject) => {
    const entry = { subject };

    exams.forEach((exam) => {
      const dataset = graphData.dataSets.find((ds) => ds.label === subject);
      entry[exam] = dataset ? dataset.data[exams.indexOf(exam)] || 0 : 0;
    });

    return entry;
  });

  const colors = subjects.map(() => getRandomColor());

  const CustomLabel = ({ x, y, width, value }) => {
    const centerX = x + width / 2;
    if (value !== 0) {
      return (
        <text
          x={centerX}
          y={y}
          dy={-5}
          fontSize={18}
          fontWeight={"bold"}
          fill="#000"
          textAnchor="middle"
        >
          {value}
        </text>
      );
    }
    return null;
  };
  return (
    <div ref={ref}>
      <Box sx={{ border: "1px solid gray", mt: 1, mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textAlign: "center", mb: 2, mt: 1 }}
        >
          {selectedClassName} Division Wise Analysis Report
        </Typography>

        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            barCategoryGap={8}
            barGap={0}
            margin={{ top: 20, right: 20, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              label={{
                value: "Division Name",
                position: "insideBottom",
                offset: -8,
              }}
              dataKey="subject"
            />
            <YAxis
              label={{
                value: "Percentage",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip />

            {exams.map((exam, index) => (
              <Bar key={index} dataKey={exam} fill={colors[index]}>
                <LabelList dataKey={exam} content={<CustomLabel />} />
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>

        <TableContainer sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead sx={{ alignItems: "center" }}>
              <TableRow>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                {graphData?.dataSets?.map((subject, index) => (
                  <TableCell
                    sx={{ border: "1px solid #ddd" }}
                    key={index}
                    align="center"
                  >
                    {subject.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {graphData?.labels?.map((exam, examIndex) => (
                <TableRow key={examIndex}>
                  <TableCell sx={{ border: "1px solid #ddd" }} align="center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          backgroundColor: colors[examIndex],
                          width: "10px",
                          height: "10px",
                          marginRight: "4px",
                          display: "inline-block",
                        }}
                      ></span>
                      {exam}
                    </div>
                  </TableCell>
                  {graphData?.dataSets?.map((dataset, datasetIndex) => (
                    <TableCell
                      sx={{ border: "1px solid #ddd" }}
                      align="center"
                      key={datasetIndex}
                    >
                      <Typography> {dataset.data[examIndex]}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
});

export default function DivisionwiseReport() {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [graphData, setGraphData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [loader, setLoader] = useState(false);

  const [examTerms, setExamterms] = useState([]);
  const [selectedClassName, setSelectedClassName] = useState("");
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [divisionwiseReport, setDivisonwiseReport] = useState({});
  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "divissionwisereport" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "divissionwisereport" },
        });
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "divissionwisereport" },
        });
        setExamterms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, []);

  const divisionwisereport = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await post(
        `${urls.examResult.divisionwise}`,
        {
          classId: divisionwiseReport.className,
          sectionIds: divisionwiseReport.sectionIds,
          examTermIds: divisionwiseReport.examTitle,
        },
        {
          headers: { roleFunction: "divissionwisereport" },
        }
      );
      setGraphData(res.data);
      console.log(res);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleSections = (e) => {
    const { name, value } = e.target;
    setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Division Wise Report" />

      <form onSubmit={divisionwisereport}>
        <Card style={styles.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl variant="outlined" fullWidth size="small" required>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Class
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="className"
                  label="Class"
                  value={divisionwiseReport.className || ""}
                  onChange={(event) => handleClasses(event)}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl variant="outlined" fullWidth size="small" required>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Section
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="sectionIds"
                  label="Section"
                  multiple
                  value={divisionwiseReport.sectionIds || []}
                  onChange={(event) => handleSections(event)}
                >
                  {sections &&
                    sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <FormControl variant="outlined" fullWidth size="small" required>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Exam
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="examTitle"
                  label="Exam"
                  multiple
                  value={divisionwiseReport.examTitle || []}
                  onChange={(event) => handleExamTerms(event)}
                >
                  {examTerms &&
                    examTerms.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.examTitle}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3} sx={{ mt: 0.5 }}>
              <LoadingButton
                variant="contained"
                size="small"
                loading={loader}
                style={styles.Button}
                type="submit"
              >
                Find
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <DivisionWiseReportPrintable
          ref={componentRef}
          graphData={graphData}
          selectedClassName={selectedClassName}
        />
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                marginBottom: "3px",
                paddingTop: "3px",
                background: "#1b3779",
                ":hover": { background: "#1b3779" },
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Paper>
    </Wrapper>
  );
}
