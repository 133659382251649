import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext, useRef } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { Delete } from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SectionSubHeader from "../../SectionSubHeader";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { LoadingButton } from "@mui/lab";

import ReactToPrint, { useReactToPrint } from "react-to-print";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const data = [
  {
    name: "6th std",
    male: 6000,
    female: 2600,
    amt: 2400,
  },
  {
    name: "7th std",
    male: 5500,
    female: 3000,
    amt: 2400,
  },
  {
    name: "8th std",
    male: 4000,
    female: 2400,
    amt: 2400,
  },
];

const combineData = (data) => {
  const combinedData = data.map((item) => {
    return {
      name: item?.class,
      male: item?.maleStudent,
      female: item?.femaleStudent,
      total: item?.maleStudent + item?.femaleStudent,
    };
  });

  return combinedData;
};
const genderCombineData = (data) => {
  const combinedData = data.map((item) => {
    return {
      name: "gender",
      male: item?.maleCount,
      female: item?.femaleCount,
      total: item?.maleCount + item?.femaleCount,
    };
  });

  return combinedData;
};
const libraryCombineData = (data) => {
  const combinedData = data.map((item) => {
    return {
      name: "library",
      male: item?.maleMember,
      female: item?.femaleMember,
      total: item?.maleMember + item?.femaleMember,
    };
  });

  return combinedData;
};
const vehcileCombineData = (data) => {
  const combinedData = data.map((item) => {
    return {
      name: item?.vehicleNumber,
      male: item?.maleStudents,
      female: item?.femaleStudents,
      total: item?.maleStudents + item?.femaleStudents,
    };
  });

  return combinedData;
};
const hostelCombineData = (data) => {
  const combinedData = data.map((item) => {
    return {
      name: item?.hostelName,
      male: item?.maleMembers,
      female: item?.femaleMembers,
      total: item?.maleMembers + item?.femaleMembers,
    };
  });

  return combinedData;
};
const StudentReport = () => {
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [academicYears, setAcademicYears] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [classBar, setClassBar] = useState([]);
  const combinedData = combineData(classBar);
  const [genderBar, setGenderBar] = useState([]);
  const genderCombinedData = genderCombineData(genderBar);
  const [libraryBar, setLibraryBar] = useState([]);
  const libraryCombinedData = libraryCombineData(libraryBar);
  const [vehicleBar, setVehicleBar] = useState([]);
  const vehicleCombinedData = vehcileCombineData(vehicleBar);
  const [hostelBar, setHostelBar] = useState([]);
  const hostelCombinedData = hostelCombineData(hostelBar);
  const [findLoad, setFindLoad] = useState(false);
  const [download, setDownload] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const [groupData, setGroupdata] = useState("");

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "reports" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const styles = {
    cardsMargin: {
      marginTop: "15px",
    },
    cardStyle: {
      margin: "15px",
      padding: "10px",
    },
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
    },
    card: {
      padding: 15,
      marginBottom: 20,
      width: "100%",
      marginTop: "15px",
    },
    textfield: {
      width: "100%",
      label: { fontSize: 12 },
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
      marginLeft: "10px",
      marginTop: "5px",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };

  const handleFind = async (e) => {
    e.preventDefault();
    setFindLoad(true);
    try {
      let data = {
        academicYearId: formData.academicYear,
        groupBy: formData.groupBy,
      };
      console.log(data, "hjhjhjhj");
      const response = await post(`${urls.studentReport.getReport}`, data, {
        headers: { roleFunction: "reports" },
      });

      setGroupdata(formData.groupBy);
      setTableData(response.data.data);
      if (formData.groupBy === "class") {
        setClassBar(response.data.data);
      }
      if (formData.groupBy === "gender") {
        setGenderBar([response.data.data]);
      }
      if (formData.groupBy === "library") {
        setLibraryBar([response.data.data]);
      }
      if (formData.groupBy === "vehicle") {
        setVehicleBar(response.data.data);
      }
      if (formData.groupBy === "hostel") {
        setHostelBar(response.data.data);
      }
    } catch (error) {
      console.error(error);
      setFindLoad(false);
    }
    setFindLoad(false);
  };

  const handlePdfDownload = async (e) => {
    e.preventDefault();
    setDownload(true);
    try {
      const response = await get(
        `${urls.studentReport.downloadPdf}?academicYearId=${formData.academicYear}&groupBy=${formData.groupBy}`,
        {
          headers: { roleFunction: "reports" },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const uri = URL.createObjectURL(blob);
      window.open(uri, "_blank");
    } catch (error) {
      console.log(error);
    }
    setDownload(false);
  };

  let totalMaleStudents = 0;
  let totalFemaleStudents = 0;

  return (
    <div>
      <div style={{ margin: "15px" }}>
        <SectionSubHeader title="Student Report" />
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Tabular Report" {...a11yProps(0)} />
            <Tab label="Grapical Report" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Card style={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl size="small" fullWidth>
                  <InputLabel required sx={{ fontSize: 12 }}>
                    Group By Data
                  </InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Group By Data"
                    name="groupBy"
                    value={formData.groupBy || ""}
                    onChange={handleOnChange}
                  >
                    <MenuItem value={"gender"}>Gender</MenuItem>
                    <MenuItem value={"vehicle"}>Vehicle</MenuItem>
                    <MenuItem value={"library"}>Library</MenuItem>
                    <MenuItem value={"hostel"}>Hostel</MenuItem>
                    <MenuItem value={"class"}>Class</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
                display="flex"
                alignSelf="center"
                gap={1}
              >
                <LoadingButton
                  loading={findLoad}
                  variant="contained"
                  onClick={handleFind}
                  size="small"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                >
                  Find
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  loading={download}
                  onClick={handlePdfDownload}
                  size="small"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                >
                  Print
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>

          <TableContainer component={Paper}>
            {groupData === "class" && (
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Academic Year
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Class Name
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Male
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Female
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((data, index) => {
                    // Accumulate counts
                    totalMaleStudents += data.maleStudent;
                    totalFemaleStudents += data.femaleStudent;

                    return (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.academicYear.academicYearFrom}-
                          {data.academicYear.academicYearTo}
                        </TableCell>
                        <TableCell align="center">{data.class}</TableCell>
                        <TableCell align="center">{data.maleStudent}</TableCell>
                        <TableCell align="center">
                          {data.femaleStudent}
                        </TableCell>
                        <TableCell align="center">
                          {data.maleStudent + data.femaleStudent}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell align="start" colSpan={3}>
                      Total:
                    </TableCell>
                    <TableCell align="center">{totalMaleStudents}</TableCell>
                    <TableCell align="center">{totalFemaleStudents}</TableCell>
                    <TableCell align="center">
                      {totalMaleStudents + totalFemaleStudents}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            {groupData === "gender" && (
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Academic Year
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Male
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Female
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center"> 1</TableCell>
                    <TableCell align="center">
                      {tableData.academicYear.academicYearFrom}-
                      {tableData.academicYear.academicYearTo}
                    </TableCell>
                    <TableCell align="center">{tableData.maleCount}</TableCell>
                    <TableCell align="center">
                      {tableData.femaleCount}
                    </TableCell>
                    <TableCell align="center">
                      {tableData.maleCount + tableData.femaleCount}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            {groupData === "vehicle" && (
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Academic Year
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Vehicle Number
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Male
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Female
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((data, index) => {
                    // Accumulate counts
                    totalMaleStudents += data.maleStudents;
                    totalFemaleStudents += data.femaleStudents;

                    return (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.academicYear.academicYearFrom}-
                          {data.academicYear.academicYearTo}
                        </TableCell>
                        <TableCell align="center">
                          {data.vehicleNumber}
                        </TableCell>
                        <TableCell align="center">
                          {data.maleStudents}
                        </TableCell>
                        <TableCell align="center">
                          {data.femaleStudents}
                        </TableCell>
                        <TableCell align="center">
                          {data.maleStudents + data.femaleStudents}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell align="start" colSpan={3}>
                      Total:
                    </TableCell>
                    <TableCell align="center">{totalMaleStudents}</TableCell>
                    <TableCell align="center">{totalFemaleStudents}</TableCell>
                    <TableCell align="center">
                      {totalMaleStudents + totalFemaleStudents}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            {groupData === "library" && (
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Academic Year
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Male
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Female
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center"> 1</TableCell>
                    <TableCell align="center">
                      {tableData.academicYear.academicYearFrom}-
                      {tableData.academicYear.academicYearTo}
                    </TableCell>
                    <TableCell align="center">{tableData.maleMember}</TableCell>
                    <TableCell align="center">
                      {tableData.femaleMember}
                    </TableCell>
                    <TableCell align="center">
                      {tableData.totalLibraryMembers}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            {groupData === "hostel" && (
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Academic Year
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Hostel Name
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Male
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Female
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((data, index) => {
                    // Accumulate counts
                    totalMaleStudents += data.maleMembers;
                    totalFemaleStudents += data.femaleMembers;

                    return (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.academicYear.academicYearFrom}-
                          {data.academicYear.academicYearTo}
                        </TableCell>
                        <TableCell align="center">{data.hostelName}</TableCell>
                        <TableCell align="center">{data.maleMembers}</TableCell>
                        <TableCell align="center">
                          {data.femaleMembers}
                        </TableCell>
                        <TableCell align="center">
                          {data.maleMembers + data.femaleMembers}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell align="start" colSpan={3}>
                      Total:
                    </TableCell>
                    <TableCell align="center">{totalMaleStudents}</TableCell>
                    <TableCell align="center">{totalFemaleStudents}</TableCell>
                    <TableCell align="center">
                      {totalMaleStudents + totalFemaleStudents}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl size="small" fullWidth>
                      <InputLabel required sx={{ fontSize: 12 }}>
                        Group By Data
                      </InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Group By Data"
                        name="groupBy"
                        value={formData.groupBy || ""}
                        onChange={handleOnChange}
                      >
                        <MenuItem value={"gender"}>Gender</MenuItem>
                        <MenuItem value={"vehicle"}>Vehicle</MenuItem>
                        <MenuItem value={"library"}>Library</MenuItem>
                        <MenuItem value={"hostel"}>Hostel</MenuItem>
                        <MenuItem value={"class"}>Class</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                    display="flex"
                    alignSelf="center"
                    gap={1}
                  >
                    <LoadingButton
                      loading={findLoad}
                      variant="contained"
                      onClick={handleFind}
                      size="small"
                      sx={{
                        background: "#1b3779",
                        ":hover": { background: "#1b3779" },
                      }}
                    >
                      Find
                    </LoadingButton>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            background: "#1b3779",
                            ":hover": { background: "#1b3779" },
                          }}
                          onClick={handlePrint}
                        >
                          Print
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>
          <div ref={componentRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card sx={{ maxWidth: 420, mt: 3 }}>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* <img
                      src={selectedSetting.logo.link}
                      height={30}
                      width={30}
                    /> */}
                    <Typography
                      gutterBottom
                      sx={{ color: "red", fontSize: "7px", mt: 1.2, ml: 0.5 }}
                    >
                      {selectedSetting.schoolName}
                    </Typography>
                  </div>
                  <Typography
                    gutterBottom
                    variant="h7"
                    sx={{ textAlign: "center", mt: 2 }}
                    component="div"
                  >
                    {selectedSetting.schoolName}
                  </Typography>
                  <Typography
                    variant="h11"
                    sx={{ textAlign: "center" }}
                    component="div"
                  >
                    {selectedSetting.address}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "3px",
                    }}
                  >
                    <AssessmentIcon style={{ color: "#1b3779" }} />
                    <Typography
                      style={{ color: "red" }}
                      variant="h8"
                      component="div"
                    >
                      Student Report
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            <Typography variant="h5" sx={{ textAlign: "center", mt: 3 }}>
              Student Report
            </Typography>

            {groupData === "class" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BarChart
                  width={450}
                  height={300}
                  data={combinedData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="male" fill="#8884d8" />
                  <Bar dataKey="female" fill="#82ca9d" />
                  <Bar dataKey="total" fill="#ff7f50" />
                </BarChart>
              </div>
            )}
            {groupData === "gender" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BarChart
                  width={450}
                  height={300}
                  data={genderCombinedData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="male" fill="#8884d8" />
                  <Bar dataKey="female" fill="#82ca9d" />
                  <Bar dataKey="total" fill="#ff7f50" />
                </BarChart>
              </div>
            )}
            {groupData === "vehicle" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BarChart
                  width={450}
                  height={300}
                  data={vehicleCombinedData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="male" fill="#8884d8" />
                  <Bar dataKey="female" fill="#82ca9d" />
                  <Bar dataKey="total" fill="#ff7f50" />
                </BarChart>
              </div>
            )}
            {groupData === "hostel" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BarChart
                  width={450}
                  height={300}
                  data={hostelCombinedData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="male" fill="#8884d8" />
                  <Bar dataKey="female" fill="#82ca9d" />
                  <Bar dataKey="total" fill="#ff7f50" />
                </BarChart>
              </div>
            )}
            {groupData === "library" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BarChart
                  width={450}
                  height={300}
                  data={libraryCombinedData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="male" fill="#8884d8" />
                  <Bar dataKey="female" fill="#82ca9d" />
                  <Bar dataKey="total" fill="#ff7f50" />
                </BarChart>
              </div>
            )}
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

export default StudentReport;
