import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  selectClasses,
  styled,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import { Delete, Search } from "@mui/icons-material";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
// icons
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs, { Dayjs } from "dayjs";
import copy from "clipboard-copy";
import moment from "moment";
import Wrapper from "../Wrapper";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../ShowComponent";

const getDateWithTime = (dateTimeString = "27/09/2024 14:30") => {
  const dateTimeComponents = dateTimeString.split(" ");
  // Extract date components
  const dateComponents = dateTimeComponents[0].split("/");
  const yearExtracted = parseInt(dateComponents[2], 10);
  const monthExtracted = parseInt(dateComponents[1], 10) - 1; // Months are zero-based
  const dayExtracted = parseInt(dateComponents[0], 10);
  // Extract time components
  const timeComponents = dateTimeComponents[1].split(":");
  const hoursExtracted = parseInt(timeComponents[0], 10);
  const minutesExtracted = parseInt(timeComponents[1], 10);

  // Create a new Date object using the extracted components
  return new Date(
    yearExtracted,
    monthExtracted,
    dayExtracted,
    hoursExtracted,
    minutesExtracted
  );
};

const getStatus = (startDate, startTime, expiryDate, expiryTime) => {
  let currentDate = Date.now();
  let startDataTimestring = `${moment(startDate).format(
    "DD/MM/YYYY"
  )} ${startTime}}`;
  let expiryDateTimeString = `${moment(expiryDate).format(
    "DD/MM/YYYY"
  )} ${expiryTime}`;
  let currentDateTimeString = `${moment(currentDate).format(
    "DD/MM/YYYY"
  )} ${moment(currentDate).format("LT")}`;

  let meetingDate = getDateWithTime(startDataTimestring);
  let meetingExpiryDate = getDateWithTime(expiryDateTimeString);
  let currentDateAndTime = getDateWithTime(currentDateTimeString);

  if (meetingDate > currentDateAndTime) {
    return "Upcoming";
  } else if (
    meetingDate < currentDateAndTime &&
    meetingExpiryDate > currentDateAndTime
  ) {
    return "Available";
  } else {
    return "Expired";
  }
};

const TableData = styled(TableCell)(() => ({
  textAlign: "center",
  color: "#ffff",
  backgroundColor: "#1b3779",
}));

const Td = styled(TableCell)(() => ({
  textAlign: "center",
}));

const Label = styled("label")(() => ({
  fontWeight: 650,
  fontSize: "15px",
  color: "#424242",
  paddingLeft: "10px",
}));

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const defaultLiveData = {
  courseDate: "",
  startTime: "",
  expiryDate: "",
  expiryTime: "",
  meetingType: "LiveStreaming",
  participantType: "Class Students",
  employeeParticipants: [],
  studentParticipants: [],
  classId: [],
  userTypes: [],
  roles: [],
  section: [],
};

const Live = () => {
  const [open, setOpen] = React.useState(false);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editId, setEditId] = useState("");
  const [list, setList] = useState([]);
  const [liveData, setLiveData] = useState(defaultLiveData);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const navigate = useNavigate();
  const [filterMeeting, setFilterMeeting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [selectClass, setSelectClass] = useState([]);
  const [students, setStudents] = useState([]);
  const [sections, setSections] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [openJoin, setOpenJoin] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedPartcipatType, setSelectedParticipatType] = useState("All");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e) => {
    setSelectedParticipatType(e.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "name======value");

    if (
      name === "participantType" &&
      value !== "Class Students" &&
      liveData.userTypes.includes("student")
    ) {
      return setLiveData((prev) => ({
        ...prev,
        [name]: value,
        classId: [],
        section: [],
      }));
    }

    setLiveData((prev) => ({ ...prev, [name]: value }));
  };

  // Fileter meeting
  useEffect(() => {
    if (selectedPartcipatType) {
      if (selectedPartcipatType === "All") {
        setFilterMeeting(list);
      } else {
        setFilterMeeting(
          list.filter((c) => c.meetingType === selectedPartcipatType)
        );
      }
    }
  }, [selectedPartcipatType]);

  // Get meeting list
  const getList = async () => {
    try {
      const { data, status } = await get(`${urls.meeting.getList}`, {
        headers: { roleFunction: "live" },
      });
      setList(data.data);
      setFilterMeeting(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole, {
          headers: { roleFunction: "live" },
        });
        setRoles(
          rolesRes.data.data.filter(
            (r) => r.roleName.toLowerCase() !== "student"
          )
        );
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, [selectedSetting]);

  useEffect(() => {
    getList();
  }, [selectedSetting]);

  const getAllRoles = async () => {
    try {
      const rolesRes = await get(urls.role.getAllRole, {
        headers: { roleFunction: "live" },
      });
      setRoles(
        rolesRes.data.data.filter(
          (r) => !["student"].includes(r.roleName.toLowerCase())
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const getEmployees = async () => {
    try {
      const filter = {};
      if (liveData.roles) {
        filter["role"] = { $in: liveData.roles };
      }

      const getEmployees = await get(urls.employee.getAllEmployee, {
        headers: { roleFunction: "live" },
        params: {
          search: filter,
        },
      });
      if (getEmployees.data.success) setEmployee(getEmployees.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployees();
  }, [liveData.roles]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const body = {
        classId: liveData.classId,
        startTime: liveData.startTime,
        startDate: liveData.courseDate,
        expiryDate: liveData.expiryDate,
        expiryTime: liveData.expiryTime,
        meetingType: liveData.meetingType,
        userTypes: liveData.userTypes,
        participantType: liveData.participantType,
        employeeParticipants: employee.filter(
          (e) =>
            liveData.roles.includes(e.role) &&
            liveData.employeeParticipants.includes(e._id)
        ),
        studentParticipants: students.filter(
          (s) =>
            liveData.classId.includes(s?.academicInfo?.class?._id) &&
            liveData.section.includes(s?.academicInfo?.section?._id) &&
            liveData.studentParticipants.includes(s?._id)
        ),
      };

      console.log(body, "==========jhgjvwkebkwebkwbvbvkebvkwvbwk");

      if (!editId) {
        const response = await post(`${urls.meeting.create}`, body, {
          headers: { roleFunction: "live" },
        });

        if (response.data.success) {
          handleClose();
          getList();
        }
      } else {
        const res = await put(`${urls.meeting.update}${editId}`, "", body, {
          headers: { roleFunction: "live" },
        });
        if (res.data.success) {
          handleClose();
          getList();
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(false);
    setEditId("");
  };

  const handleCloseJoin = () => {
    setOpenJoin(false);
  };

  // update meeting
  const handleupdateModelOpen = (id) => {
    setEditId(id);
    let data = list.filter((m) => m._id == id)[0];

    // console.log(data, "data=====datat");

    if (data.userTypes.includes("employee")) {
      let roles = [];
      for (let emp of data.employeeParticipants) {
        if (roles.filter((r) => r == emp.role).length < 1) {
          roles.push(emp.role);
        }
      }
      setLiveData((prev) => ({
        ...prev,
        roles: roles,
        employeeParticipants: data.employeeParticipants.map((p) => p._id),
      }));
    }
    if (data.userTypes.includes("student")) {
      let classes = [];
      let sections = [];
      for (let stupar of data.studentParticipants) {
        if (sections.filter((s) => s == stupar.academicInfo.class).length < 1) {
          sections.push(stupar.academicInfo.section);
        }
        if (classes.filter((c) => c == stupar.academicInfo.class).length < 1) {
          classes.push(stupar.academicInfo.class);
        }
      }
      setLiveData((prev) => ({
        ...prev,
        classId: classes,
        section: sections,
        studentParticipants: data.studentParticipants.map((p) => p._id),
      }));
    }

    if (data.participantType === "Class Students") {
      setLiveData((prev) => ({
        ...prev,
        classId: data.classId.map((c) => c._id),
      }));
    }
    setLiveData((prev) => ({
      ...prev,
      courseDate: dayjs(data.startDate).format("YYYY-MM-DD"),
      expiryDate: dayjs(data.expiryDate).format("YYYY-MM-DD"),
      startTime: data.startTime,
      expiryTime: data.expiryTime,
      meetingType: data.meetingType,
      participantType: data.participantType,
      userTypes: data.userTypes,
    }));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLiveData(defaultLiveData);
  };

  const handleJoinClick = (id) => {
    const getMeetingId = list.find((item) => item._id === id);
    console.log(getMeetingId);
    if (getMeetingId) {
      const meetingType = getMeetingId.meetingType;
      navigate(`/room/${getMeetingId.roomId}`, {
        state: { meetingType: meetingType },
      });
    }
  };
  useEffect(() => {
    getList();
    getDateWithTime();
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await del(
        `${urls.meeting.delete}`,
        `${id}`,
        {},
        { roleFunction: "live" }
      );
      if (res.data.success) {
        getList();
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getClasses = async () => {
    try {
      const res = await get(urls.class.getAllClass, {
        headers: { roleFunction: "live" },
      });

      setClasses(res.data.data);
      setSelectClass(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClasses();
  }, [selectedSetting]);

  const getSections = async () => {
    try {
      let filter = {};
      if (liveData.userTypes.includes("student")) {
        filter["class"] = { $in: liveData.classId };
      }
      const { data } = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "live" },
        params: { search: { ...filter } },
      });
      setSections(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(sections, "sections========");

  useEffect(() => {
    getSections();
  }, [liveData.classId]);

  const getStudents = async () => {
    try {
      let filter = {};
      if (liveData.userTypes.includes("student")) {
        if (liveData.classId.length) {
          filter["academicInfo.class"] = { $in: liveData.classId };
        }
        if (liveData.section.length) {
          filter["academicInfo.section"] = { $in: liveData.section };
        }
      }

      const students = await get(urls.students.getAllStudent, {
        params: {
          search: filter,
        },
        headers: { roleFunction: "live" },
      });
      setStudents(students.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudents();
  }, [liveData.userTypes, liveData.classId, liveData.section]);

  // useEffect(() => {
  //   if (editId && liveData.participantType) {
  //     setLiveData((prev) => ({
  //       ...prev,
  //       classId: [],
  //       section:
  //         liveData.classId.length > 0
  //           ? sections
  //               .filter((s) => liveData.classId.includes(s.class._id))
  //               .map((s) => s._id)
  //           : liveData.section,
  //     }));
  //   }
  // }, [liveData.participantType]);

  // console.log(liveData, "liveDatatta");

  return (
    <Wrapper>
      <SectionsSubHeader title="Live" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
          mb: 1,
        }}
      >
        <FormControl size="small" sx={{ m: 1, minWidth: 250 }}>
          <Label id="demo-simple-select-label">
            <FilterListIcon fontSize="small" /> Filter Meeting
          </Label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Select Course"
            value={selectedPartcipatType}
            onChange={handleFilter}
          >
            <MenuItem value="All"> All </MenuItem>
            <MenuItem value="GroupCall">Group Call </MenuItem>
            <MenuItem value="LiveStreaming">Live Stream </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableData sx={{ fontWeight: 600 }}>S.No</TableData>
              <TableData sx={{ fontWeight: 600 }}>Meeting Date</TableData>
              <TableData sx={{ fontWeight: 600 }}>Meeting Type</TableData>
              <TableData sx={{ fontWeight: 600 }}>Status</TableData>
              <TableData sx={{ fontWeight: 600 }}>Start Meeting</TableData>
              <TableData sx={{ fontWeight: 600 }}>Action</TableData>
            </TableRow>
          </TableHead>{" "}
          <TableBody>
            {filterMeeting
              ? filterMeeting
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((listData, i) => (
                    <TableRow>
                      <Td>{page * rowsPerPage + i + 1}</Td>

                      <Td>
                        {dayjs(listData.startDate).format("DD-MM-YYYY")}, Time:{" "}
                        {listData.startTime}
                      </Td>
                      <Td>{listData.meetingType}</Td>
                      <Td>
                        <Box
                          sx={{
                            py: 1,
                            objectFit: "contain",
                            borderRadius: "5px",
                            background:
                              getStatus(
                                listData.startDate,
                                listData.startTime,
                                listData.expiryDate,
                                listData.expiryTime
                              ) === "Expired"
                                ? "#85848430"
                                : "#8281e74d",
                          }}
                        >
                          {getStatus(
                            listData.startDate,
                            listData.startTime,
                            listData.expiryDate,
                            listData.expiryTime
                          )}
                        </Box>
                      </Td>
                      <Td>
                        <Button
                          disabled={
                            getStatus(
                              listData.startDate,
                              listData.startTime,
                              listData.expiryDate,
                              listData.expiryTime
                            ) === "Expired"
                          }
                          onClick={() => handleJoinClick(listData._id)}
                          variant="contained"
                          size="small"
                        >
                          Join
                        </Button>
                      </Td>

                      <Td sx={{ textAlign: "center" }}>
                        <ShowComponent roleFunction="live" action="update">
                          <Tooltip title="Update">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleupdateModelOpen(listData._id)
                              }
                            >
                              <EditIcon
                                style={{ color: "#1b3779" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <ShowComponent roleFunction="live" action="delete">
                          <Tooltip title="Delete">
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => setDeleteModal(listData._id)}
                            >
                              <Delete fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={listData._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </Td>
                    </TableRow>
                  ))
              : list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((listData, i) => (
                    <TableRow>
                      <Td>{page * rowsPerPage + i + 1}</Td>

                      <Td>
                        {dayjs(listData.startDate).format("DD-MM-YYYY")}, Time:{" "}
                        {listData.startTime}
                      </Td>
                      <Td>{listData.meetingType}</Td>
                      <Td>
                        <Box
                          sx={{
                            py: 1,
                            objectFit: "contain",
                            borderRadius: "5px",
                            background:
                              getStatus(
                                listData.startDate,
                                listData.startTime,
                                listData.expiryDate,
                                listData.expiryTime
                              ) === "Expired"
                                ? "#85848430"
                                : "#8281e74d",
                          }}
                        >
                          {getStatus(
                            listData.startDate,
                            listData.startTime,
                            listData.expiryDate,
                            listData.expiryTime
                          )}
                        </Box>
                      </Td>
                      <Td>
                        <Button
                          disabled={
                            getStatus(
                              listData.startDate,
                              listData.startTime,
                              listData.expiryDate,
                              listData.expiryTime
                            ) === "Expired"
                          }
                          onClick={() => handleJoinClick(listData._id)}
                          variant="contained"
                          size="small"
                        >
                          Join
                        </Button>
                      </Td>

                      <Td sx={{ textAlign: "center" }}>
                        <ShowComponent roleFunction="live" action="update">
                          <Tooltip title="Update">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleupdateModelOpen(listData._id)
                              }
                            >
                              <EditIcon
                                style={{ color: "#1b3779" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <ShowComponent roleFunction="live" action="delete">
                          <Tooltip title="Delete">
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => setDeleteModal(listData._id)}
                            >
                              <Delete fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={listData._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </Td>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
        {!list.length === 0 && (
          <Box align="center" colSpan={12} py={2}>
            <Typography variant="h6" align="center">
              Live sessions Not found!
            </Typography>
          </Box>
        )}
        {!filterMeeting.length && (
          <Box align="center" colSpan={12} py={2}>
            <Typography variant="h6" align="center">
              No Data Found
            </Typography>
          </Box>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      <Box className="add-icon">
        <Tooltip title="Create Meeting">
          <Fab
            variant="contained"
            sx={{
              color: "#ffff",

              background: "rgb(27, 55, 121)",
              ":hover": { background: "rgb(27, 55, 121)" },
            }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>

      {/* ============= Add/Update model =============== */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                component="h2"
              >
                {editId ? "Update Meeting" : "Create Meeting"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Meeting Type</Label>
              <Select
                required
                size="small"
                fullWidth
                name="meetingType"
                type="text"
                value={liveData.meetingType || "LiveStreaming"}
                onChange={handleChange}
              >
                {/* <MenuItem value="OneONoneCall">One-On-One Call </MenuItem> */}
                <MenuItem value="GroupCall">Group Call </MenuItem>
                <MenuItem value="LiveStreaming">Live Stream </MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Participant Type</Label>

              <Select
                required
                size="small"
                fullWidth
                name="participantType"
                type="text"
                value={liveData.participantType || "Class Students"}
                onChange={handleChange}
                disabled={
                  liveData.meetingType === "OneONoneCall" ? true : false
                }
              >
                {/* <MenuItem value="Single">Single User</MenuItem> */}
                <MenuItem value="Class Students">Class Students</MenuItem>
                <MenuItem value="Selected">Selected Users</MenuItem>
              </Select>
            </Grid>

            {(liveData.participantType === "Single" ||
              liveData.participantType === "Selected") && (
              <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Label id="demo-simple-select-label">User Type</Label>
                  <Select
                    multiple
                    required
                    size="small"
                    fullWidth
                    name="userTypes"
                    type="text"
                    value={liveData.userTypes}
                    onChange={handleChange}
                  >
                    <MenuItem value="student">Student</MenuItem>
                    <MenuItem value="employee">Employee</MenuItem>
                  </Select>
                </Grid>

                {liveData.userTypes.includes("student") && (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Label id="demo-simple-select-label">
                        Select Classes
                      </Label>
                      <Select
                        multiple
                        required
                        size="small"
                        fullWidth
                        name="classId"
                        type="text"
                        value={liveData.classId || ""}
                        onChange={handleChange}
                      >
                        {selectClass &&
                          selectClass.map((data, i) => (
                            <MenuItem key={data._id} value={data._id}>
                              {data.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Label id="demo-simple-select-label">
                        Select Sections
                      </Label>
                      <Select
                        required
                        multiple
                        size="small"
                        fullWidth
                        name="section"
                        type="text"
                        value={liveData.section}
                        onChange={handleChange}
                      >
                        {sections &&
                          sections.map((data, i) => (
                            <MenuItem key={data._id} value={data._id}>
                              {data.class.className} {data.sectionName}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Label id="demo-simple-select-label">
                        Select Students ({liveData.studentParticipants.length})
                      </Label>
                      <Select
                        required
                        size="small"
                        multiple
                        fullWidth
                        name="studentParticipants"
                        type="text"
                        value={liveData.studentParticipants}
                        onChange={handleChange}
                      >
                        {students.map((studentName, i) => (
                          <MenuItem
                            key={studentName._id}
                            value={studentName._id}
                          >
                            {studentName.basicInfo?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
                {liveData.userTypes.includes("employee") && (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Label id="demo-simple-select-label">Select Role</Label>
                      <Select
                        required
                        multiple
                        size="small"
                        fullWidth
                        name="roles"
                        type="text"
                        value={liveData.roles}
                        onChange={handleChange}
                      >
                        {roles.map((role, i) => (
                          <MenuItem key={role._id} value={role._id}>
                            {role.roleName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Label id="demo-simple-select-label">
                        Select Employees ({liveData.employeeParticipants.length}
                        )
                      </Label>
                      <Select
                        required
                        size="small"
                        fullWidth
                        multiple
                        name="employeeParticipants"
                        type="text"
                        value={liveData.employeeParticipants}
                        onChange={handleChange}
                      >
                        {employee.map((row, i) => (
                          <MenuItem value={row._id} key={row._id}>
                            {" "}
                            {row.basicInfo.empName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
              </>
            )}

            {liveData.participantType === "Class Students" && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Label id="demo-simple-select-label">
                  Select Class/Classes
                </Label>

                <Select
                  size="small"
                  multiple
                  fullWidth
                  variant="outlined"
                  label="classId"
                  name="classId"
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  value={liveData.classId}
                  onChange={handleChange}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Start Date</Label>
              <TextField
                required
                type="date"
                size="small"
                fullWidth
                name="courseDate"
                value={liveData.courseDate}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Start Time</Label>
              <TextField
                required
                type="time"
                size="small"
                fullWidth
                name="startTime"
                value={liveData.startTime}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Expiry Date</Label>
              <TextField
                required
                type="date"
                size="small"
                fullWidth
                name="expiryDate"
                value={liveData.expiryDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Expiry Time</Label>
              <TextField
                required
                type="time"
                size="small"
                fullWidth
                name="expiryTime"
                value={liveData.expiryTime}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              gap="10px"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "flex-end",
                lg: "flex-end",
              }}
            >
              <Button
                onClick={handleClose}
                color="error"
                size="small"
                variant="contained"
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                size="small"
                sx={{
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                type="submit"
                variant="contained"
              >
                {editId ? "Update" : "Create"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Dialog
        open={openJoin}
        onClose={handleCloseJoin}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontWeight={600}>
            To enable this feature please contact admin!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJoin}>Ok</Button>
          {/* <Button onClick={(e) => handleOpenJoin(e)} autoFocus>
            Ok
          </Button> */}
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

export default Live;
