import React, { useContext, useEffect, useState } from "react";

import css from "../../../styles/fees/ClassMap.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  DialogTitle,
  FormControl,
  Toolbar,
  Tooltip,
  InputLabel,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Stack,
  TablePagination,
} from "@mui/material";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Add } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteModal from "../Academic/DeleteModal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import dayjs from "dayjs";
import SectionSubHeader from "../../SectionSubHeader";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";
import Wrapper from "../../Wrapper";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const FeeCategory = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [selectCategory, setSelectCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [feeCategory, setFeeCategory] = useState([]);
  const [addDialog, setAddDialog] = useState(false);
  const [addForm, setAddForm] = useState({});
  const [editFeeCategory, setEditFeeCategory] = useState();
  const [selected, setSelected] = useState([]);
  const [allselected, setAllSelected] = useState([]);
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [classMaps, setClassMaps] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isRowSelected, setISRowSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const getClassMaps = async () => {
      try {
        const { data: classMapList, status } = await get(
          `${urls.classMap.getAll}`,
          { headers: { roleFunction: "feeCategory" } }
        );

        if (status > 199 && status < 299) {
          setClassMaps(classMapList.data);
          setAddForm((prev) => ({
            ...prev,
            classMapId: classMapList.data.length
              ? classMapList.data[0]._id
              : "",
          }));
        }
      } catch (error) {
        return error;
      }
    };
    getClassMaps();
  }, []);

  useEffect(() => {
    const getSchoolClasses = async () => {
      try {
        const { data: schoolClassesList, status } = await get(
          `${urls.class.getAllClass}`,
          { headers: { roleFunction: "feeCategory" } }
        );
        if (status > 199 && status < 299) {
          setSchoolClasses(schoolClassesList.data);
          if (schoolClassesList.data.length) {
            setAddForm((prev) => ({
              ...prev,
              classId: schoolClassesList.data[0]._id,
            }));
          }
        }
      } catch (error) {}
    };

    getSchoolClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    if (addForm.classMapId) {
      getFeeCategory();
    }
  }, [addForm.classMapId]);

  const getFeeCategory = async () => {
    try {
      const { data: feeCategoryList, status } = await get(
        `${urls.feeCategory.getAllFeeCategory}`,
        {
          headers: { roleFunction: "feeCategory" },
          params: {
            search: {
              classMap: addForm.classMapId,
            },
          },
        }
      );
      const compare = (a, b) => a.priority - b.priority;
      let feeCategoryss = feeCategoryList.data;
      feeCategoryss.sort(compare);

      if (status > 199 && status < 299) {
        setFeeCategory(feeCategoryss);
        return {
          data: feeCategoryList,
          status,
        };
      }
      console.log("jkjk", feeCategory);
    } catch (error) {
      return error;
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.feeCategory.deleteFeeCategory}`,
        id,
        {},
        { roleFunction: "feeCategory" }
      );
      console.log(res);
      if (res.data.success) {
        const filteredEvent = feeCategory.filter((item) => item._id !== id);
        setFeeCategory(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMultiple = async () => {
    try {
      const res = await del(
        `${urls.feeCategory.deleteMultipleFeeCategory}`,
        "",
        { feeCategoryIds: selected },
        { roleFunction: "feeCategory" }
      );
      console.log(res);
      if (res.data.success) {
        const filteredEvents = feeCategory.filter(
          (item) => !selected.includes(item._id)
        );
        setFeeCategory(filteredEvents);
        setSelected([]);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log("kjhgfd", error);
    }
  };

  const handleSelectAllClick = (event) => {
    console.log("oooooooooo", selected);
    if (event.target.checked) {
      const ids = feeCategory.map((fee) => fee._id);
      setSelected([...ids]);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    if (event.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((_id) => _id !== id));
    }
  };

  const handleFavorite = async (id) => {
    if (!addForm.classMapId) return;
    try {
      const res = await put(
        `${urls.feeCategory.toggleFeeCategoryFavorite}${id}`,
        "",
        { studentCategory: selectCategory },
        { headers: { roleFunction: "feeCategory" } }
      );
      if (res.data.success) {
        const res = await get(`${urls.feeCategory.getAllFeeCategory}`, {
          headers: { roleFunction: "feeCategory" },
          params: {
            search: {
              classMap: addForm.classMapId,
            },
          },
        });
        setFeeCategory([...res.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePriorityUp = async (index, id) => {
    if (!addForm.classMapId) return;
    try {
      const res = await put(
        `${urls.feeCategory.changePriority}${id}`,
        "",
        {
          type: "up",
        },
        { headers: { roleFunction: "feeCategory" } }
      );
      console.log("res", res);
      if (res.data.success) {
        const res = await get(`${urls.feeCategory.getAllFeeCategory}`, {
          headers: { roleFunction: "feeCategory" },
          params: {
            search: {
              classMap: addForm.classMapId,
            },
          },
        });
        setFeeCategory(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePriorityDown = async (index, id) => {
    if (!addForm.classMapId) return;

    try {
      const res = await put(
        `${urls.feeCategory.changePriority}${id}`,
        "",
        {
          type: "down",
        },
        { headers: { roleFunction: "feeCategory" } }
      );

      if (res.data.success) {
        const res = await get(`${urls.feeCategory.getAllFeeCategory}`, {
          headers: { roleFunction: "feeCategory" },
          params: {
            search: {
              classMap: addForm.classMapId,
            },
          },
        });

        setFeeCategory(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseAddDialog = () => {
    setAddForm((prev) => ({ classMapId: prev.classMapId }));
    setEditFeeCategory("");
    setAddDialog(false);
  };
  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };
  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    try {
      if (!editFeeCategory) {
        if (!addForm.classMapId) return alert("Please select a class map!");
        const { status } = await post(
          `${urls.feeCategory.postFeeCategory}/${addForm.classMapId}`,
          {
            name: addForm.name,
            description: addForm.description,
            amount: addForm.amount,
          },
          { headers: { roleFunction: "feeCategory" } }
        );
        if (status > 199 && status < 299) {
          getFeeCategory();
          handleCloseAddDialog();
        }
      } else {
        const { status } = await put(
          `${urls.feeCategory.editFeeCategory}${editFeeCategory}`,
          "",
          {
            name: addForm.name,
            description: addForm.description,
            amount: addForm.amount,
          },
          { headers: { roleFunction: "feeCategory" } }
        );
        if (status > 199 && status < 299) {
          getFeeCategory();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleFeeCategoryEdit = (id, data) => {
    setEditFeeCategory(id);
    setAddForm({
      classMapId: data.classMap._id,
      name: data.name,
      description: data.description,
      amount: data.amount,
    });

    handleOpenAddDialog();
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Fee Category" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            my: 2,
          }}
        >
          {/* <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              my: 2,
            }}
          > */}
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormControl fullWidth size="small" required>
              <InputLabel>Class Maps</InputLabel>
              <Select
                size="small"
                name="classMapId"
                value={addForm.classMapId || ""}
                onChange={handleAddForm}
                label="Class Maps"
              >
                {classMaps.map((classMap) => (
                  <MenuItem key={classMap._id} value={classMap._id}>
                    {classMap.class?.className} -{" "}
                    {classMap.receiptBookItem.name} - ( ₹{classMap.fee})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Button
            onClick={handleOpenAddDialog}
            size="small"
            variant="contained"
          >
            <Add />
            add fee category
          </Button>
          {/* </Grid> */}
        </Grid>
      </Box>

      {/* <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormControl fullWidth>
            <InputLabel>Class Maps</InputLabel>
            <Select
              size="small"
              name="classMapId"
              value={addForm.classMapId || ""}
              onChange={handleAddForm}
              label="Class Maps"
            >
              {classMaps.map((classMap) => (
                <MenuItem key={classMap._id} value={classMap._id}>
                  {classMap.class?.className} - {classMap.receiptBookItem.name}{" "}
                  - ( ₹{classMap.fee})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
        <ShowComponent roleFunction="feeCategory" action="add">
          <Button
            onClick={handleOpenAddDialog}
            size="small"
            variant="contained"
          >
            <Add />
            add fee category
          </Button>
          </ShowComponent>
        </Grid>
      </Grid> */}

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }} align="center">
                <Checkbox
                  checked={selected.length === feeCategory.length}
                  indeterminate={
                    selected.length > 0 && feeCategory.length > selected.length
                  }
                  onChange={handleSelectAllClick}
                  color="primary"
                />
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Fee Category
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Description
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Amount
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feeCategory
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fees, index) => (
                <TableRow
                  key={fees._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    <Checkbox
                      checked={selected.includes(fees._id)}
                      onChange={(e) => handleClick(e, fees._id)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{fees.name}</TableCell>
                  <TableCell align="center">{fees.description}</TableCell>
                  <TableCell align="center">{fees.amount}</TableCell>

                  <TableCell align="right">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <ShowComponent roleFunction="feeCategory" action="update">
                        <div
                          onClick={() => handleFeeCategoryEdit(fees._id, fees)}
                          size="small"
                          variant="contained"
                        >
                          <ModeEditIcon />
                        </div>
                      </ShowComponent>
                      <div size="small" variant="contained">
                        |
                      </div>
                      <ShowComponent roleFunction="feeCategory" action="delete">
                        <div
                          onClick={() => setDeleteModal(fees._id, fees)}
                          size="small"
                          variant="contained"
                        >
                          <DeleteIcon />
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={fees._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </ShowComponent>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <ShowComponent
                          roleFunction="feeCategory"
                          action="update"
                        >
                          <div
                            variant="contained"
                            size="small"
                            onClick={() => handlePriorityUp(index, fees._id)}
                          >
                            <ArrowUpwardIcon />
                          </div>
                        </ShowComponent>
                        <ShowComponent
                          roleFunction="feeCategory"
                          action="update"
                        >
                          <div
                            variant="contained"
                            size="small"
                            onClick={() => handlePriorityDown(index, fees._id)}
                          >
                            <ArrowDownwardIcon />
                          </div>
                        </ShowComponent>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        {!feeCategory.length && (
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              margin: "5px",
              padding: "5px",
            }}
          >
            No data found
          </Typography>
        )}

        {selected.length > 1 && (
          <ShowComponent roleFunction="feeCategory" action="delete">
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteMultiple}
            >
              Delete
            </Button>
          </ShowComponent>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={feeCategory.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      <Dialog
        fullScreen={fullScreen}
        open={addDialog}
        onClose={handleCloseAddDialog}
      >
        <Box sx={style}>
          <form onSubmit={handleAddSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {editFeeCategory
                    ? "Update Fee Category Name"
                    : "Create New Fee Category Name"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  label="Name"
                  size="small"
                  name="name"
                  value={addForm.name || ""}
                  onChange={handleAddForm}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  label="Description"
                  size="small"
                  name="description"
                  value={addForm.description || ""}
                  onChange={handleAddForm}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  label="Amount"
                  size="small"
                  required
                  type="number"
                  name="amount"
                  value={addForm.amount || ""}
                  onChange={handleAddForm}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                gap={2}
              >
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  onClick={handleCloseAddDialog}
                >
                  Cancel
                </Button>
                <ShowComponent
                  roleFunction="feeCategory"
                  action={editFeeCategory ? "update" : "add"}
                >
                  <LoadingButton
                    loading={loader}
                    type="submit"
                    size="small"
                    variant="contained"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                      color: "#fff",
                    }}
                  >
                    {editFeeCategory
                      ? "Update Fee Category"
                      : "Add Fee Category"}
                  </LoadingButton>
                </ShowComponent>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
    </Wrapper>
  );
};

export default FeeCategory;
