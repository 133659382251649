import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useContext } from "react";
import Wrapper from "../../Wrapper";
import SectionsSubHeader from "../../../components/SectionSubHeader";
import { ResponsiveContainer } from "recharts";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },

  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const SubjectWiseReportPrintable = React.forwardRef((props, ref) => {
  const { graphData, selectedClassName, selectedSectionName } = props;

  const exams = graphData?.labels || [];
  const subjects = graphData?.dataSets?.map((dataset) => dataset.label) || [];

  // const data = subjects.map((subject) => {
  //   const entry = { subject };

  //   exams.forEach((exam) => {
  //     const dataset = graphData.dataSets.find((ds) => ds.label === subject);
  //     entry[exam] = dataset ? dataset.data[exams.indexOf(exam)] || 0 : 0;
  //   });

  //   return entry;
  // });

  const data = subjects.map((subject) => {
    const truncatedSubject = subject
      .split(" ")
      .map((word) => word.slice(0, 3).toUpperCase())
      .join(" ");
    const entry = { subject: truncatedSubject };

    exams.forEach((exam) => {
      const dataset = graphData.dataSets.find((ds) => ds.label === subject);
      entry[exam] = dataset ? dataset.data[exams.indexOf(exam)] || 0 : 0;
    });

    return entry;
  });

  const colors = subjects.map(() => getRandomColor());

  const CustomLabel = ({ x, y, width, value }) => {
    const centerX = x + width / 2;
    if (value !== 0) {
      return (
        <text
          x={centerX}
          y={y}
          dy={-5}
          fontSize={18}
          fontWeight={"bold"}
          fill="#000"
          textAnchor="middle"
        >
          {value}
        </text>
      );
    }
    return null;
  };

  return (
    <div ref={ref}>
      <Box sx={{ border: "1px solid gray", mt: 1, mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textAlign: "center", mb: 2, mt: 1 }}
        >
          {selectedClassName} {selectedSectionName} Subject Wise Report
        </Typography>

        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            barCategoryGap={8}
            barGap={0}
            margin={{ top: 20, right: 20, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="subject"
              label={{
                value: "Subject Name",
                position: "insideBottom",
                offset: -15,
              }}
              // tickFormatter={(value) => value}
            />

            <YAxis
              label={{
                value: "Percentage",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip />
            {exams.map((exam, index) => (
              <Bar key={index} dataKey={exam} fill={colors[index]}>
                <LabelList dataKey={exam} content={<CustomLabel />} />
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
        <TableContainer sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                {graphData?.dataSets?.map((subject, index) => (
                  <TableCell
                    key={index}
                    sx={{ border: "1px solid #ddd" }}
                    align="center"
                  >
                    {subject.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {graphData?.labels?.map((exam, examIndex) => (
                <TableRow key={examIndex}>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          backgroundColor: colors[examIndex],
                          width: "10px",
                          height: "10px",
                          marginRight: "4px",
                          display: "inline-block",
                        }}
                      ></span>
                      {exam}
                    </div>
                  </TableCell>
                  {graphData?.dataSets?.map((dataset, datasetIndex) => (
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid #ddd" }}
                      key={datasetIndex}
                    >
                      <Typography> {dataset.data[examIndex]}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
});

export default function SubjectWiseReport() {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [graphData, setGraphData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [subjets, setSubjects] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [subjectWiseReport, setSubjectWiseReport] = useState({});
  const [selectedClassName, setSelectedClassName] = useState("");
  const [selectedSectionName, setSelectedSectionName] = useState("");
  const [loader, setLoader] = useState(false);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "subjectwisereport" },
        });
        setClasses(res.data.data);
        console.log(res.data.data, "subjeeeeeeeeeeeee");
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "subjectwisereport" },
        });
        setExamterms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, []);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setSubjectWiseReport({ ...subjectWiseReport, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "subjectwisereport" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      setSelectedClassName(
        classes.find((c) => c._id === value)?.className || ""
      );

      const subject = await get(urls.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: value,
          },
        },

        headers: { roleFunction: "subjectwisereport" },
      });
      setSubjects(subject.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSections = (e) => {
    const { name, value } = e.target;
    setSubjectWiseReport({ ...subjectWiseReport, [name]: value });
    setSelectedSectionName(
      sections.find((s) => s._id === value)?.sectionName || ""
    );
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setSubjectWiseReport({ ...subjectWiseReport, [name]: value });
  };

  const subjectwisereport = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await post(
        `${urls.examResult.subjectwise}`,
        {
          classId: subjectWiseReport.className,
          sectionId: subjectWiseReport.sectionName,
          examTermIds: subjectWiseReport.examTitle,
          subjectIds: subjectWiseReport.subjectIds,
        },
        {
          headers: { roleFunction: "subjectwisereport" },
        }
      );

      setGraphData(res.data);
      console.log(res.data, "ooooooooooooooooo");
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleSubjects = (e) => {
    const { name, value } = e.target;
    setSubjectWiseReport({ ...subjectWiseReport, [name]: value });
  };
  return (
    <Wrapper>
      <SectionsSubHeader title="Subject Wise Report" />

      <form onSubmit={subjectwisereport}>
        <Card style={styles.card}>
          <Box>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small" required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    label="Class"
                    name="className"
                    size="small"
                    value={subjectWiseReport.className || ""}
                    onChange={(event) => handleClasses(event)}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small" required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="sectionName"
                    label="Section"
                    size="small"
                    value={subjectWiseReport.sectionName || ""}
                    onChange={(event) => handleSections(event)}
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small" required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="subjectIds"
                    multiple
                    label="Subject"
                    size="small"
                    value={subjectWiseReport.subjectIds || []}
                    onChange={(event) => handleSubjects(event)}
                  >
                    {subjets &&
                      subjets.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.subjectName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small" required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Exam
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="examTitle"
                    multiple
                    label="Exam"
                    size="small"
                    value={subjectWiseReport.examTitle || []}
                    onChange={(event) => handleExamTerms(event)}
                  >
                    {examTerms &&
                      examTerms.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.examTitle}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                alignSelf="center"
                display="flex"
                justifyContent={{
                  xs: "flex-end",
                  sm: "flex-end",
                  md: "flex-start",
                  lg: "flex-start",
                }}
              >
                <LoadingButton
                  variant="contained"
                  size="small"
                  sx={{
                    background: "#1b3779",
                    "&:hover": { background: "#1b3779" },
                  }}
                  type="submit"
                >
                  Find
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </form>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <SubjectWiseReportPrintable
          ref={componentRef}
          graphData={graphData}
          selectedClassName={selectedClassName}
          selectedSectionName={selectedSectionName}
        />
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                paddingTop: "3px",
                marginBottom: "3px",
                background: "#1b3779",
                ":hover": { background: "#1b3779" },
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Paper>
    </Wrapper>
  );
}
