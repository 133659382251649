import React, { useContext } from "react";
import {
  Card,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { urls as url } from "../../../services/urlConstant";
import { post, get } from "../../../services/apis";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import DownloadIcon from "@mui/icons-material/Download";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    marginTop: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    color: "#fff",
  },
};

const BulkAdmission = () => {
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState({});
  const [btnSampleLoader, setBtnSampleLoader] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [examResultDetails, setExamResultDetails] = useState({});

  const [loader, setLoader] = useState(false);

  const { selectedSetting, setSelectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const getAcademicYears = await get(
          urls.administrator.academicYearGetAll,
          { headers: { roleFunction: "studentBulkAdmission" } }
        );
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "studentBulkAdmission" },
        });
        if (res.data.success && res.data.data.length) {
          const resSections = await get(urls.schoolSection.getAllSection, {
            headers: { roleFunction: "studentBulkAdmission" },
            params: {
              search: {
                class: res.data.data.length ? res.data.data[0]._id : "",
              },
            },
          });
          setSections(resSections.data.data);
          setClasses(res.data.data);
          setAcademicYears(getAcademicYears.data.data);
          setFormData((prev) => ({
            ...prev,
            schoolClass: res.data.data[0]._id,
            section: resSections.data.data[0]
              ? resSections.data.data[0]._id
              : "",
            academicYear: getAcademicYears.data.data[0]
              ? getAcademicYears.data.data[0]._id
              : "",
          }));
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleGetAdmitSheet = async (e) => {
    e.preventDefault();
    setBtnSampleLoader(true);
    try {
      const getSheet = await get(`${urls.students.getBulkAdmitSheet}`, {
        // const getSheet = await get(`/bulk-admit/student/${formData.schoolClass}/${formData.section}/${formData.academicYear}`, {
        headers: { roleFunction: "studentBulkAdmission" },
        responseType: "blob",
      });
      const uri = URL.createObjectURL(getSheet.data);

      const link = document.createElement("a");
      link.href = uri;
      console.log("from", formData);
      const academicsYear = formData.academicYear;
      const getExcelyearById = academicYears.find(
        (excel) => excel._id === academicsYear
      );
      const classename = formData.schoolClass;
      const getExcelById = classes.find((excel) => excel._id === classename);
      const sectionsname = formData.section;
      const getExcelsectionById = sections.find(
        (excel) => excel._id === sectionsname
      );
      // link.setAttribute("download", `studentadmit.xlsx`);
      link.setAttribute(
        "download",
        `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className} ,${getExcelsectionById.sectionName}.xlsx`
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error(error.message);
    }
    setBtnSampleLoader(false);
  };
  const handleSubmitBulkAdmission = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const fileForm = new FormData();
      fileForm.append("sheet", e.target.bulkAdmitFile.files[0]);
      await post(
        `${urls.students.postBulkAdmit}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
        fileForm,
        { headers: { roleFunction: "studentBulkAdmission" } }
      );
    } catch (error) {
      toast.error(error.message);
    }

    setLoader(false);
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    const getSections = async () => {
      const sections = await get(`${urls.schoolSection.getSection}`, {
        headers: { roleFunction: "studentBulkAdmission" },
        params: {
          search: {
            class: formData.schoolClass,
          },
        },
      });
      setSections(sections.data.data);
    };
    getSections();
  }, [formData.schoolClass, selectedSetting]);
  return (
    <div style={{ margin: "15px" }}>
      <SectionSubHeader title="Bulk Admission   " />

      <Card style={styles.card}>
        <Box sx={styles}>
          <form onSubmit={handleSubmitBulkAdmission}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleFormChange}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom}-{row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    label="Class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={formData.schoolClass || ""}
                    onChange={handleFormChange}
                    // onChange={[]}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    section
                  </InputLabel>
                  <Select
                    label="Section"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleFormChange}
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <label
                  htmlFor="myfile"
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Select file:
                </label>
                <input
                  type="file"
                  id="myfile"
                  name="bulkAdmitFile"
                  accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                />
                <br />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <LoadingButton
                  onClick={handleGetAdmitSheet}
                  variant="contained"
                  loading={btnSampleLoader}
                  sx={{
                    backgroundColor: "#1b3779",
                    ":hover": { backgroundColor: "#1b3779" },
                  }}
                  endIcon={<DownloadIcon />}
                >
                  Get Sample
                </LoadingButton>
              </Grid>
              <div style={{ margin: "10px 0px 0px 10px" }}>
                <ul>
                  <li>
                    School role type configured with{" "}
                    <span style={{ color: "red" }}> manual</span>,please mention
                    role number in column.
                  </li>
                  <li>
                    School admission_no configured with auto{" "}
                    <span style={{ color: "red" }}>auto _ascending_no,</span>
                    admission_no column will be ignored.
                  </li>
                </ul>
              </div>

              <hr></hr>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={1}
              >
                {/* <Tooltip title="Get Sample">
                  <Button
                    variant="contained"
                    color="error"
                    style={{ color: "#fff" }}
                  >
                    Cancel
                  </Button>
                </Tooltip> */}

                <LoadingButton
                  loading={loader}
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "#1b3779",
                    ":hover": { backgroundColor: "#1b3779" },
                  }}
                >
                  Submit
                </LoadingButton>
              </Grid>
              {/* <Grid
                item
                xs={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
              >
                <Tooltip title="Get Sample">
                  <Button
                    variant="contained"
                    color="error"
                    style={{ color: "#fff" }}
                  >
                    Cancel
                  </Button>
                </Tooltip>
              </Grid> */}
            </Grid>
          </form>
        </Box>
      </Card>
      <div
        style={{
          backgroundColor: "#FFF9C4",
          margin: "10px 10px",
          padding: "10px",
        }}
      >
        <h3>Instruction:</h3>
        <ol>
          <li>At first select the (School), Class and Section</li>
          <li>Generate CSV file.</li>
          <li>
            Open the downloaded CSV file and enter student information with
            unique username.
          </li>
          <li>Save the edited CSV file.</li>
          <li>Upload again CSV file you just edited and submit.</li>
        </ol>
      </div>
    </div>
  );
};

export default BulkAdmission;
