import { Box, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import AddEmployee from "./AddEmployee";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { theme } from "../../../theme";

const EditEmployee = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [employee, setEmployee] = useState(undefined);
  const { employeeId } = useParams();

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const res = await get(urls.employee.getEmployee + employeeId, {
          headers: { roleFunction: "employee" },
        });
        if (res.data.success) {
          setEmployee(res.data.data);
          console.log("ededed", res.data.data);
        } else {
          console.log(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getEmployee();
  }, [employeeId]);
  return (
    <>
      {employee ? (
        <AddEmployee employee={employee} />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress variant="indeterminate" size={50} />
        </Box>
      )}
    </>
  );
};
export default EditEmployee;
