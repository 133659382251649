import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  styled,
  Grid,
  useMediaQuery,
  Dialog,
  Tooltip,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState, useEffect, useContext } from "react";
import { get } from "../../../services/apis";
import set from "date-fns/set/index.js";
import { objHas } from "../../../utils/utils";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { urls as url } from "../../../services/urlConstant";
import appendStringToUrls from "../../../utils/appendString";
import SettingContext from "../../../context/SettingsContext";

import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const AddClassRoutine = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [routineClass, setRoutineClass] = useState("");
  const [section, setSection] = useState("");
  const [subject, setSubject] = useState("");
  const [teacher, setTeacher] = useState("");
  const [day, setDay] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [roomNo, setRoomNo] = useState("");
  const [classes, setClasses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [classRoutineDetails, setClassRoutineDetailes] = useState({});
  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setClassRoutineDetailes({ ...classRoutineDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "periodRoutine" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      const response = await get(urls.schoolSubject.getAllSubject, {
        headers: { roleFunction: "periodRoutine" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSubjects(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "periodRoutine" },
        });
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const res = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "periodRoutine" },
        });
        setEmployees(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
  }, []);
  useEffect(() => {
    if (props.open || props.updateClassRoutine) {
      setRoutineClass("");
      setSection("");
      setSubject("");
      setTeacher("");
      setDay("");
      setStartTime("");
      setEndTime("");
      setRoomNo("");
      setClassRoutineDetailes({});

      if (props.updateClassRoutine) {
        const data = {
          className: props.updateClassRoutine.class._id,
          sectionName: props.updateClassRoutine.section._id,
          subjectName: props.updateClassRoutine.subject._id,
          teacher: props.updateClassRoutine.teacher,
          day: props.updateClassRoutine.day,
          startTime: dayjs(props.updateClassRoutine.startTime, "HH:mm").format(
            "HH:mm"
          ),
          endTime: dayjs(props.updateClassRoutine.endTime, "HH:mm").format(
            "HH:mm"
          ),
          roomNo: props.updateClassRoutine.roomNo,
        };
        setClassRoutineDetailes(data);
        const getSectionsAndSubject = async () => {
          try {
            const sections = await get(urls.schoolSection.getAllSection, {
              headers: { roleFunction: "periodRoutine" },
              params: {
                search: {
                  class: props.updateClassRoutine.class._id,
                },
              },
            });
            setSections(sections.data.data);
            const subject = await get(urls.schoolSubject.getAllSubject, {
              headers: { roleFunction: "periodRoutine" },
              params: {
                search: {
                  class: props.updateClassRoutine.class._id,
                },
              },
            });
            setSubjects(subject.data.data);
          } catch (error) {
            console.log(error);
          }
        };
        getSectionsAndSubject();
      }
    }
  }, [props.open, props.updateClassRoutine]);

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setClassRoutineDetailes({ ...classRoutineDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.updateClassRoutine) {
      console.log("test ", classRoutineDetails.startTime);
      await props.handleUpdate(
        {
          class: classRoutineDetails.className,
          section: classRoutineDetails.sectionName,
          subject: classRoutineDetails.subjectName,
          teacher: classRoutineDetails.teacher,
          day: classRoutineDetails.day,
          startTime: classRoutineDetails.startTime,
          endTime: classRoutineDetails.endTime,
          roomNo: classRoutineDetails.roomNo,
        },
        props.updateClassRoutine._id,
        props.updateClassRoutine.dayIndex
      );
    } else {
      await props.handleSubmit(e, {
        class: classRoutineDetails.className,
        section: classRoutineDetails.sectionName,
        subject: classRoutineDetails.subjectName,
        teacher: classRoutineDetails.teacher,
        day: classRoutineDetails.day,
        startTime: classRoutineDetails.startTime,
        endTime: classRoutineDetails.endTime,
        roomNo: classRoutineDetails.roomNo,
      });
    }
  };

  const handleCloseModal = () => {
    setRoutineClass("");
    setSection("");
    setSubject("");
    setTeacher("");
    setDay("");
    setStartTime("");
    setEndTime("");
    setRoomNo("");
    setClassRoutineDetailes({});

    props.handleClose();
  };

  const handleClose = () => {
    setRoutineClass("");
    setSection("");
    setSubject("");
    setTeacher("");
    setDay("");
    setStartTime("");
    setEndTime("");
    setRoomNo("");
    setClassRoutineDetailes({});
    props.handleClose();
  };
  return (
    <>
      <Tooltip title="Add Class Routine">
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="Add Class Routine"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
          className="form-tag"
          onSubmit={handleSubmit}
          style={{ padding: "20px" }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                fontSize="20px"
                fontWeight="bold"
              >
                {props.updateClassRoutine
                  ? "Update Class Routine"
                  : "Add Class Routine"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Class</InputLabel>
                <Select
                  label="Class"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="className"
                  value={classRoutineDetails.className || ""}
                  onChange={(event) => handleClasses(event)}
                  defaultValue={
                    props.updateClassRoutine &&
                    props.updateClassRoutine.class &&
                    props.updateClassRoutine.class._id
                  }
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Section</InputLabel>
                <Select
                  label="Section"
                  id="demo-simple-select-filled"
                  name="sectionName"
                  value={classRoutineDetails.sectionName || ""}
                  onChange={(event) => handleOnchange(event)}
                  defaultValue={
                    props.updateClassRoutine &&
                    props.updateClassRoutine.section &&
                    props.updateClassRoutine.section._id
                  }
                >
                  {sections.length > 0 &&
                    sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Subject</InputLabel>
                <Select
                  label="Subject"
                  id="demo-simple-select-filled"
                  name="subjectName"
                  value={classRoutineDetails.subjectName || null}
                  onChange={(event) => handleOnchange(event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={objHas(
                    props,
                    "updateClassRoutine.subject._id",
                    ""
                  )}
                >
                  {subjects &&
                    subjects.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.subjectName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Day</InputLabel>
                <Select
                  label="Day"
                  id="demo-simple-select-filled"
                  name="day"
                  value={classRoutineDetails.day}
                  onChange={(event) => handleOnchange(event)}
                  defaultValue={objHas(props, "updateClassRoutine.day", "")}
                >
                  <MenuItem
                    value={"monday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Monday
                  </MenuItem>
                  <MenuItem
                    value={"tuesday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Tuesday
                  </MenuItem>
                  <MenuItem
                    value={"wednesday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Wednesday
                  </MenuItem>
                  <MenuItem
                    value={"thursday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Thursday
                  </MenuItem>
                  <MenuItem
                    value={"friday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Friday
                  </MenuItem>
                  <MenuItem
                    value={"saturday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Saturday
                  </MenuItem>
                  <MenuItem
                    value={"sunday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Sunday
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Teacher</InputLabel>
                <Select
                  label="Teacher"
                  id="demo-simple-select-filled"
                  name="teacher"
                  value={classRoutineDetails.teacher}
                  onChange={(event) => handleOnchange(event)}
                  defaultValue={objHas(props, "updateClassRoutine.teacher", "")}
                >
                  {employees &&
                    employees.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.basicInfo.empName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                size="small"
                label="Room No"
                name="roomNo"
                value={classRoutineDetails.roomNo || ""}
                onChange={(event) => handleOnchange(event)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="time"
                size="small"
                name="startTime"
                label="Start Time"
                value={classRoutineDetails.startTime || ""}
                onChange={(event) => handleOnchange(event)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                label="End Time"
                type="time"
                name="endTime"
                value={classRoutineDetails.endTime || ""}
                onChange={(event) => handleOnchange(event)}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { md: "right", xs: "center" },
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={props.loader}
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    ml: 2,
                    color: "#fff",
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                >
                  {props.updateClassRoutine ? "Update" : "Submit"}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  );
};

export default AddClassRoutine;
