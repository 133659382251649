import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import Wrapper from "../../Wrapper";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
    marginTop: "15px",
  },

  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const StudentActivityReport = () => {
  const [formData, setFormData] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const [findLoad, setFindLoad] = useState(false);
  const [student, setStudent] = useState([]);
  const [selectStudent, setSelectStudent] = useState("");
  const [download, setDownload] = useState(false);
  const [findData, setFindData] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "reports" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "reports" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });

        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              "academicInfo.class": formData.className,
            },
          },
        });
        console.log(res, "rezsss");
        setStudent(res.data.data);
        setStudentOptions(
          res.data.data.map((student) => ({
            value: student._id,
            label: student.basicInfo.name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [formData.className]);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "reports" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    // setFormData({
    //   ...formData,
    //   [name]: value,
    // });
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFind = async (e) => {
    e.preventDefault();
    setFindLoad(true);
    try {
      let data = {
        academicYearId: formData.academicYear,
        studentId: selectStudent,
      };
      const response = await post(
        `${urls.studentActivityReport.getList}`,
        data,
        { headers: { roleFunction: "reports" } }
      );
      console.log(response.data.data, "respobse");
      setFindData(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setFindLoad(false);
  };
  const handlePdfDownload = async (e) => {
    e.preventDefault();
    setDownload(true);

    try {
      let data = {
        academicYearId: formData.academicYear,
        studentId: selectStudent,
      };

      const response = await get(
        `${urls.studentActivityReport.pdfDownload}?academicYearId=${formData.academicYear}&studentId=${selectStudent}`,
        {
          headers: { roleFunction: "reports" },
          params: data,
          responseType: "blob",
        }
      );
      console.log(response.data, "donwloadUi");
      const downloadUri = URL.createObjectURL(response.data);

      window.open(downloadUri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setDownload(false);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Student Activity Report" />

      {/* <Card style={styles.card}> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth size="small" required>
            <InputLabel>Select Academic Year</InputLabel>
            <Select
              label="Select Academic Year"
              id="demo-simple-select-filled"
              name="academicYear"
              variant="outlined"
              value={formData.academicYear || ""}
              onChange={handleOnChange}
              size="small"
            >
              {academicYears &&
                academicYears.map((row, index) => (
                  <MenuItem key={row._id} value={row._id}>
                    {row.academicYearFrom} - {row.academicYearTo}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl size="small" fullWidth>
            <InputLabel required sx={{ fontSize: 12 }}>
              Select Class
            </InputLabel>
            <Select
              fullWidth
              label="Select Class"
              variant="outlined"
              id="demo-simple-select-filled"
              name="className"
              value={formData.className || ""}
              onChange={handleClassChange}
              size="small"
            >
              {classes &&
                classes.map((row, index) => (
                  <MenuItem key={row._id} value={row._id}>
                    {row.className}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          {/* <FormControl fullWidth size="small">
              <InputLabel required sx={{ fontSize: 12 }}>
                Student
              </InputLabel>
              <Select
                label="Student"
                variant="outlined"
                id="demo-simple-select-filled"
                name="selectStudent"
                value={selectStudent || ""}
                onChange={(e) => setSelectStudent(e.target.value)}
                size="small"
              >
                {student.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data.basicInfo.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              options={studentOptions}
              getOptionLabel={(option) => option.label}
              value={studentOptions.find(
                (option) => option.value === selectStudent
              )}
              onChange={(event, newValue) => {
                setSelectStudent(newValue ? newValue.value : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Search student with name"
                  label="Student"
                />
              )}
              sx={{ width: 300 }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            mb: 2,
          }}
        >
          <LoadingButton
            loading={findLoad}
            size="small"
            variant="contained"
            onClick={handleFind}
            sx={{
              background: "#1b3779",
              ":hover": { background: "#1b3779" },
            }}
          >
            Find
          </LoadingButton>
          <LoadingButton
            loading={download}
            variant="contained"
            size="small"
            onClick={handlePdfDownload}
            sx={{
              background: "#1b3779",
              ":hover": { background: "#1b3779" },
            }}
          >
            Print
          </LoadingButton>
        </Grid>
      </Grid>
      {/* </Card> */}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                Academic Year
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Student
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Section
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Activity
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Activity Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {findData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <TableRow>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>

                  <TableCell align="center">
                    {data.academicYear.academicYearFrom}-
                    {data.academicYear.academicYearTo}
                  </TableCell>

                  <TableCell align="center">
                    {data.student.basicInfo.name}
                  </TableCell>
                  <TableCell align="center">
                    {data.section.sectionName}
                  </TableCell>
                  <TableCell align="center">{data.name}</TableCell>
                  <TableCell align="center">
                    {dayjs(data.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {findData.length == 0 ? (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        ) : (
          ""
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={findData.length || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
          "& .MuiTablePagination-spacer": {
            overflow: "none",
          },
        }}
      />
    </Wrapper>
  );
};

export default StudentActivityReport;
