import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const showInfo = (data) => {
  let result = [];

  for (let dep of data.dependencies) {
    if (dep === "academicYear") {
      result.push(
        `[${data.academicYearId.academicYearFrom}-${data.academicYearId.academicYearTo}]-Academic Year`
      );
    } else if (dep === "class") {
      let newItem = `[${data.class?.className}]-Class`;
      result.push(newItem);
    } else if (dep === "classOld") {
      let newItem = `[${data.class?.className}]-Class-Old`;
      result.push(newItem);
    } else if (dep === "classNew") {
      let newItem = `[${data.class?.className}]-Class-New`;
      result.push(newItem);
    } else if (dep === "hostel") {
      let newItem = `[${data.hostel?.name}]-Hostel`;
      result.push(newItem);
    } else if (dep === "roomType") {
      result.push(`[${data.roomType.name}]-Room_Type`);
    } else if (dep === "room") {
      let newItem = `[${data.room?.hostel.name}]+[${data.room?.totalSeats} Beds]+[${data.room?.type?.name}]-Room`;
      result.push(newItem);
    } else if (dep === "transportCategory") {
      let newItem = `[${data.transportCategory}]-Transport Category`;
      result.push(newItem);
    } else if (dep == "route") {
      let newItem = `[${data.route.vehicleNumber.vehicleNumber}]+[${data.route.transportRouteTitle}]-Route`;
      result.push(newItem);
    } else if (dep == "pickType") {
      let newItem = `[${data.pickType}]-Pick_Type`;
      result.push(newItem);
    } else if (dep === "stop") {
      let newItem = `[${data.stop.stopName}]-Stop`;
      result.push(newItem);
    } else if (dep === "addedBefore") {
      let newItem = `[${moment(data.addedBefore).format(
        "DD/MM/YYYY"
      )}]-Added Before`;
      result.push(newItem);
    } else if (dep === "addedAfter") {
      let newItem = `[${moment(data.addedAfter).format(
        "DD/MM/YYYY"
      )}]-Added After`;
      result.push(newItem);
    }
  }

  return result.join(" | ");
};
function BalanceFeeReportNew() {
  const { selectedSetting } = useContext(SettingContext);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [finder, setFinder] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [donwload, setDownload] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const calculateTotalValue = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += item.value; // Adjust the property name according to your data structure
    });
    return total;
  };

  useEffect(() => {
    const newTotalValue = calculateTotalValue(schoolStudents);
    setTotalValue(newTotalValue);
  }, [schoolStudents]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "balanceFeeReport" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getAllReceipts = async () => {
      try {
        const res = await get(urls.receiptTitle.list, {
          headers: { roleFunction: "balanceFeeReport" },
          params: {
            search: {
              active: true,
            },
          },
        });
        setFormData((prev) => ({
          ...prev,
          receiptTitleId: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setReceipts(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllReceipts();
  }, [selectedSetting._id]);

  const getClasses = async () => {
    try {
      const res = await get(urls.class.getAllClass, {
        headers: { roleFunction: "balanceFeeReport" },
      });
      setClasses(res.data.data);

      setFormData((prev) => ({
        ...prev,
        schoolClass: res.data.data[0] ? res.data.data[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // get all classes
  useEffect(() => {
    getClasses();
  }, [selectedSetting._id, formData.feeMapId]);

  const getSections = async () => {
    try {
      let search = {};
      if (formData.schoolClass !== "all") {
        search["class"] = formData.schoolClass;
      }
      const { data } = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "balanceFeeReport" },
        params: { search: search },
      });
      setSections(data.data);
      setFormData((prev) => ({
        ...prev,
        section: data.data[0] ? data.data[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.schoolClass) {
      getSections();
    }
  }, [formData.schoolClass]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setSchoolStudents([]);
  }, [formData]);

  const handleFeeSearchSubmit = async (e) => {
    e.preventDefault();
    setFinder(true);
    try {
      setShowPagination(true);

      let searchParams = {
        headers: { roleFunction: "balanceFeeReport" },
        params: {
          academicYearId: formData.academicYear,
          receiptTitleId: formData.receiptTitleId,
          search: {
            classId: formData.schoolClass,
          },
        },
      };

      if (formData.section !== "all") {
        searchParams["params"]["search"]["sectionId"] = formData.section;
      }

      const searchStudent = await get(
        urls.receipt.getBalanceFeeReport,
        searchParams
      );
      setPage(0);
      console.log(searchStudent.data.data, "datat");
      setSchoolStudents(searchStudent.data.data);
    } catch (error) {
      console.log(error);
      setFinder(false);
    }
    setFinder(false);
  };

  const handleMarksExcelDownload = async () => {
    setDownload(true);
    try {
      if (
        formData.academicYear &&
        formData.schoolClass &&
        formData.section &&
        formData.receiptTitleId
      ) {
        let searchParams = {
          headers: { roleFunction: "balanceFeeReport" },
          params: {
            academicYearId: formData.academicYear,
            receiptTitleId: formData.receiptTitleId,
            search: {
              classId: formData.schoolClass,
            },
          },
          responseType: "blob",
        };

        if (formData.section !== "all") {
          searchParams["params"]["search"]["sectionId"] = formData.section;
        }

        const getMarksListSheet = await get(
          urls.receipt.downloadBalanceFeeReport,
          searchParams
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const academicYearGet = formData.academicYear;
          const getExcelByYearId = academicYears.find(
            (excel) => excel._id === academicYearGet
          );
          const classename = formData.schoolClass;
          const getExcelByClassId = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = formData.section;
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );

          link.setAttribute(
            "download",
            `${getExcelByYearId.academicYearFrom}-${
              getExcelByYearId.academicYearTo
            } ${getExcelByClassId?.className || "All"} std ${
              getExcelsectionById
                ? getExcelsectionById.sectionName
                : "All sections"
            }.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          console.log(getExcelByYearId, "getyear");
          console.log(getExcelsectionById, "getSec");

          link.parentNode.removeChild(link);
        }
      } else if (
        formData.academicYear &&
        formData.schoolClass &&
        formData.section == "all" &&
        formData.receipt
      ) {
        const getMarksListSheet = await get(
          `/${selectedSetting._id}/fee-receipt/download/report/all-sections/balance-fee`,
          {
            headers: { roleFunction: "balanceFeeReport" },
            params: {
              academicYearId: formData.academicYear,
              receiptType: formData.receipt,
              classId: formData.schoolClass,
            },
            responseType: "blob",
          }
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const academicYearGet = formData.academicYear;
          const getExcelByYearId = academicYears.find(
            (excel) => excel._id === academicYearGet
          );
          const classename = formData.schoolClass;
          const getExcelByClassId = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = formData.section;
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const receiptss = formData.receipt;
          const getExcelreceiptById = receipts.find(
            (excel) => excel._id === receiptss
          );

          link.setAttribute(
            "download",
            `${getExcelByYearId.academicYearFrom}-${
              getExcelByYearId.academicYearTo
            } ${getExcelByClassId?.className || "All"} std ,${
              getExcelsectionById
                ? getExcelsectionById.sectionName
                : "All Sections"
            } ${
              getExcelreceiptById
                ? getExcelreceiptById.name
                : "All Fee Receipts"
            }.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(error.message);
    }
    setDownload(false);
  };
  return (
    <>
      <Box sx={{ p: 2 }}>
        <SectionSubHeader title="Balance Fee Report" />
        <Card style={{ margin: "15px 0", padding: "15px 10px" }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleFeeSearchSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">
                      Academic Year
                    </InputLabel>
                    <Select
                      label="Academic Year"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Receipt Name
                    </InputLabel>
                    <Select
                      label="Receipt"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="receiptTitleId"
                      value={formData.receiptTitleId || ""}
                      onChange={handleOnChange}
                    >
                      {receipts &&
                        [{ name: "All", _id: "all" }, ...receipts].map(
                          (row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.name}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="schoolClass"
                      value={formData.schoolClass || ""}
                      onChange={handleOnChange}
                    >
                      {classes &&
                        [{ className: "All", _id: "all" }, ...classes].map(
                          (row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.className}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Section
                    </InputLabel>
                    <Select
                      label="Section"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                    >
                      {sections &&
                        [{ sectionName: "All", _id: "all" }, ...sections].map(
                          (row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.sectionName}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  display="flex"
                  justifyContent="flex-start"
                >
                  <LoadingButton
                    loading={finder}
                    type="submit"
                    size="small"
                    variant="contained"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                  >
                    find
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Receipt</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Roll No</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Class</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Section</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Name</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Father</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Phone</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Amount</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Concession</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Fine</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Paid</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Balance</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolStudents
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.studentId}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.receiptTitle}</TableCell>

                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.rollNo}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.class}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.section}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.fatherName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      <CurrencyRupeeIcon size="small" fontSize="10px" />{" "}
                      {row.amount.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      <CurrencyRupeeIcon size="small" fontSize="10px" />{" "}
                      {row.concession.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      <CurrencyRupeeIcon size="small" fontSize="10px" />{" "}
                      {row.fine.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      <CurrencyRupeeIcon size="small" fontSize="10px" />{" "}
                      {row.paid.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      <CurrencyRupeeIcon size="small" fontSize="10px" />{" "}
                      {row.balance.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!schoolStudents.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={schoolStudents.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />

        {schoolStudents.length > 0 &&
          formData.academicYear &&
          formData.receiptTitleId &&
          formData.schoolClass &&
          formData.section && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <LoadingButton
                  loading={donwload}
                  onClick={handleMarksExcelDownload}
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                >
                  DOWNLOAD
                </LoadingButton>
              </div>
            </>
          )}
      </Box>
    </>
  );
}

export default BalanceFeeReportNew;
