import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Fab,
  InputAdornment,
  IconButton,
  TablePagination,
  TableContainer,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import { Search } from "@material-ui/icons";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ShowComponent from "../ShowComponent";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "relative",
  width: "100%",
  height: 650,
  bgcolor: "background.paper",
  p: 4,
};

const styles = {
  cardStyle: {
    margin: "15px",
    padding: "10px",
    overflowY: "hidden",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
  cardStyles: {
    padding: "10px",
  },
  card: {
    padding: "10px 10px",
    margin: "0px 0px 20px 0px",
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
    marginLeft: "5px",
  },
  textfield: {
    width: "100%",
    marginBottom: "18px",
  },
};

const VehicleLog = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [departureTime, setDepartureTime] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [transportRouteTitle, setTransportRouteTitle] = React.useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editVehicleLog, setEditVehicleLog] = useState();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [findForm, setFindForm] = useState({
    transportRouteTitle: "",
    vehicleNumber: "",
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [vehicleLoader, setVehicleLoader] = useState(false);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const [finder, setFinder] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileArrival, setFileArrival] = useState(null);
  const [fileNameArrival, setFileNameArrival] = useState("");
  const [editFormData, setEditFormData] = useState({});
  const [sort, setSort] = useState({
    date: "asc",
    ArrivaltoKMRun: "asc",
    SpareuseKMrun: "asc",
    ArrivalFromSchoolKM: "asc",
    totalKMRun: "asc",
  });

  const sortByDate = () => {
    if (sort.date === "asc") {
      const sortedList = [...list.sort((a, b) => b.date.localeCompare(a.date))];
      setList(sortedList);
      setSort({ ...sort, date: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.date.localeCompare(b.date))];
      setList(sortedList);
      setSort({ ...sort, date: "asc" });
    }
  };

  const sortByArrivaltoKMRun = () => {
    if (sort.ArrivaltoKMRun === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.ArrivaltoKMRun - a.ArrivaltoKMRun),
      ];
      setList(sortedList);
      setSort({ ...sort, ArrivaltoKMRun: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.ArrivaltoKMRun - b.ArrivaltoKMRun),
      ];
      setList(sortedList);
      setSort({ ...sort, ArrivaltoKMRun: "asc" });
    }
  };

  const sortBySpareuseKMrun = () => {
    if (sort.SpareuseKMrun === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.SpareuseKMrun - a.SpareuseKMrun),
      ];
      setList(sortedList);
      setSort({ ...sort, SpareuseKMrun: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.SpareuseKMrun - b.SpareuseKMrun),
      ];
      setList(sortedList);
      setSort({ ...sort, SpareuseKMrun: "asc" });
    }
  };

  const sortByArrivalFromSchoolKM = () => {
    if (sort.ArrivalFromSchoolKM === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.ArrivalFromSchoolKM - a.ArrivalFromSchoolKM),
      ];
      setList(sortedList);
      setSort({ ...sort, ArrivalFromSchoolKM: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.ArrivalFromSchoolKM - b.ArrivalFromSchoolKM),
      ];
      setList(sortedList);
      setSort({ ...sort, ArrivalFromSchoolKM: "asc" });
    }
  };

  const sortByTotalKMRun = () => {
    if (sort.totalKMRun === "asc") {
      const sortedList = [...list.sort((a, b) => b.totalKMRun - a.totalKMRun)];
      setList(sortedList);
      setSort({ ...sort, totalKMRun: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.totalKMRun - b.totalKMRun)];
      setList(sortedList);
      setSort({ ...sort, totalKMRun: "asc" });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };

  const getVehicleLog = async () => {
    setFinder(true);
    try {
      const { data, status } = await get(urls.vehicleLog.getList, {
        headers: { roleFunction: "vehicleLog" },
        params: {
          route: findForm.transportRouteTitle,
          vehicle: findForm.vehicleNumber,
          fromDate: new Date(fromDate),
          toDate: new Date(toDate),
        },
      });
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
    setFinder(false);
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.vehicleLog.delete}`,
        id,
        {},
        { roleFunction: "vehicleLog" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`, {
          headers: { roleFunction: "vehicleLog" },
        });
        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, [selectedSetting]);

  const handleClose = () => {
    setAddForm({});
    setFileName("");

    setDepartureTime("");
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setAddForm({});
    setArrivalTime("");
    setFileNameArrival("");
    setDepartureTime("");

    setOpenEditModal(false);
  };

  const handleVehicle = async (e) => {
    try {
      const { name, value } = e.target;
      setAddForm({
        ...addForm,
        [name]: value,
      });
      const res = await get(`${urls.route.getList}`, {
        headers: { roleFunction: "vehicleLog" },
        params: {
          search: {
            vehicleNumber: value,
          },
        },
      });
      if (res.data.success) {
        setTransportRouteTitle(res.data.data);
        setFindForm(res.data.data);
        console.log(res.data.data, "vvvv");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVehicleFindForm = async (e) => {
    try {
      const { name, value } = e.target;
      setFindForm({
        ...findForm,
        [name]: value,
      });
      const res = await get(`${urls.route.getList}`, {
        headers: { roleFunction: "vehicleLog" },
        params: {
          search: {
            vehicleNumber: value,
          },
        },
      });
      if (res.data.success) {
        setTransportRouteTitle(res.data.data);

        console.log(res.data.data, "vvvv");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFindForm = (e) => {
    const { name, value } = e.target;
    setFindForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleFileChangeArrival = (e) => {
    const selectedFile = e.target.files[0];
    setFileArrival(selectedFile);
    setFileNameArrival(selectedFile.name);
  };

  const handleDepartureSclTime = (e) => {
    setDepartureTime(e.target.value);
  };
  const handleArrivalTime = (e) => {
    setArrivalTime(e.target.value);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setVehicleLoader(true);
    try {
      const fileForm = new FormData();
      if (fileName) {
        fileForm.append("departureImage", file);
      }

      fileForm.append("route", addForm.route);
      fileForm.append("vehicle", addForm.vehicle);
      fileForm.append("date", date);
      fileForm.append("departureTime", departureTime);
      fileForm.append("readingAtDeparture", addForm.readingAtDeparture);

      if (!editVehicleLog) {
        const res = await post(`${urls.vehicleLog.create}`, fileForm, {
          "Content-Type": "multipart/form-data",
          headers: { roleFunction: "vehicleLog" },
        });
        if (res.data.success) {
          getVehicleLog();
          handleClose();
          setVehicleLoader(false);
        } else {
          console.log("Couldn't complete your request, Try again later");
        }
      }
    } catch (error) {
      console.log(error);
      setVehicleLoader(false);
    }
    setVehicleLoader(false);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const fileForm = new FormData();

      if (fileNameArrival) {
        fileForm.append("arrivalImage", fileArrival);
      }

      fileForm.append("arrivalTime", arrivalTime);
      fileForm.append("readingAtArrival", editFormData.readingAtArrival);
      fileForm.append("distance", editFormData.distance);
      fileForm.append(" reason", editFormData.reason);

      if (editVehicleLog) {
        const res = await put(
          `${urls.vehicleLog.update}${editVehicleLog}`,
          "",
          fileForm,

          {
            "Content-Type": "multipart/form-data",
            headers: { roleFunction: "vehicleLog" },
          }
        );
        if (res.data.success) {
          getVehicleLog();
          handleCloseEditModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVehicleLogEdit = (id, data) => {
    setEditVehicleLog(id);
    console.log(data, "handleDat");
    setAddForm({
      route: data.route._id,
      vehicle: data.vehicle._id,
      readingAtDeparture: data.readingAtDeparture?.reading,
      readingAtArrival: data.readingAtArrival?.reading,
      reason: data.reason,
      distance: data.distance,
      image: data.image,
    });
    setDepartureTime(data.departureTime);
    setDate(data.date);
    setArrivalTime(data.arrivalTime);
    handleClickEditModal();
  };

  const handleGetPrintPdf = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/vehicleLog/downloadpdf/${findForm.transportRouteTitle}/${findForm.vehicleNumber}/${fromDate}/${toDate}`,
        {
          headers: { roleFunction: "vehicleLog" },
          responseType: "blob",
        }
      );

      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.vehicleNumber.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleDownload = () => {
    const downloadUrl = list.find((l) => l._id == editVehicleLog)
      ?.readingAtDeparture?.image;
    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    }
  };

  const handleDownloadArrival = async () => {
    const downloadUrl = list.find((l) => l._id == editVehicleLog)
      ?.readingAtArrival?.image;
    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    }
  };

  console.log(
    list,
    "lisyttttttttttttttttttttttttttttttttttttttttttttttt======================================================================================="
  );

  return (
    <>
      <Box style={styles.cardStyle}>
        <SectionsSubHeader title="Vehicle Log" />
        <Card style={styles.card}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel>Vehicle Number</InputLabel>
                <Select
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  label="vehicle number"
                  name="vehicleNumber"
                  onChange={(e) => handleVehicleFindForm(e)}
                  value={findForm.vehicleNumber || ""}>
                  {vehicleNumber.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      {name.vehicleNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel>Route Title</InputLabel>
                <Select
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  label="Route Title"
                  name="transportRouteTitle"
                  onChange={handleFindForm}
                  value={findForm.transportRouteTitle || ""}>
                  {transportRouteTitle.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      {name.transportRouteTitle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="From Date"
                    inputFormat="DD-MM-YYYY"
                    value={fromDate || ""}
                    onChange={setFromDate}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="To Date"
                    inputFormat="DD-MM-YYYY"
                    value={toDate || ""}
                    onChange={setToDate}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "flex-end",
                lg: "flex-end",
              }}
              gap={1}>
              <LoadingButton
                loading={finder}
                variant="contained"
                size="small"
                sx={{
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                onClick={getVehicleLog}>
                Find
              </LoadingButton>
              <LoadingButton
                variant="contained"
                sx={{
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                size="small"
                onClick={handleGetPrintPdf}
                loading={loader}>
                Print
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <TextField
                size="small"
                fullWidth
                style={styles.textfield}
                value={search}
                onChange={handleSearch}
                placeholder="Enter vehicle No."
                label="Search vehicle No."
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByDate} className="class-table-header">
                    Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.date === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByArrivaltoKMRun}
                    className="class-table-header">
                    Departure Time
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortBySpareuseKMrun}
                    className="class-table-header">
                    Reading At Departure
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByArrivalFromSchoolKM}
                    className="class-table-header">
                    Arrival Time
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByArrivalFromSchoolKM}
                    className="class-table-header">
                    Reading At Arrival
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByTotalKMRun}
                    className="class-table-header">
                    Total KM Run
                    <span style={{ cursor: "pointer" }}>
                      {sort.totalKMRun === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {dayjs(data.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            {data.departureTime}
                          </TableCell>
                          <TableCell align="center">
                            {data.readingAtDeparture
                              ? data.readingAtDeparture.reading
                              : "0"}
                          </TableCell>
                          <TableCell align="center">
                            {data.arrivalTime ? data.arrivalTime : "NA"}
                          </TableCell>
                          <TableCell align="center">
                            {data.readingAtArrival
                              ? data.readingAtArrival.reading
                              : "0"}
                          </TableCell>
                          <TableCell align="center">
                            {data.totalDistanceTravelled}
                          </TableCell>
                          <TableCell align="center">
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="vehicleLog"
                                action="update">
                                <Tooltip title="Update">
                                  <IconButton
                                    style={{ color: "#1b3779" }}
                                    size="small"
                                    onClick={() =>
                                      handleVehicleLogEdit(data._id, data)
                                    }>
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="vehicleLog"
                                action="delete">
                                <Tooltip title="Delete">
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      setDeleteModal(data._id, data)
                                    }>
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={data._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {dayjs(data.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            {data.departureTime}
                          </TableCell>
                          <TableCell align="center">
                            {data.readingAtDeparture
                              ? data.readingAtDeparture.reading
                              : "0"}
                          </TableCell>
                          <TableCell align="center">
                            {data.arrivalTime ? data.arrivalTime : "NA"}
                          </TableCell>
                          <TableCell align="center">
                            {data.readingAtArrival
                              ? data.readingAtArrival.reading
                              : "0"}
                          </TableCell>
                          <TableCell align="center">
                            {data.totalDistanceTravelled}
                          </TableCell>
                          <TableCell align="center">
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="vehicleLog"
                                action="update">
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleVehicleLogEdit(data._id, data)
                                    }>
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="vehicleLog"
                                action="delete">
                                <Tooltip title="Delete">
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      setDeleteModal(data._id, data)
                                    }>
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={data._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!list.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        <div className="add-icon">
          <ShowComponent roleFunction="vehicleLog" action="add">
            <Tooltip title="Add Vehicle Log">
              <Fab
                variant="contained"
                style={{ color: "#ffff", backgroundColor: "#1b3779" }}
                onClick={handleClickOpen}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </ShowComponent>
        </div>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <form onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold">
                  Add Vehicle Log
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    variant="standard"
                    value={date}
                    onChange={(newDate) => setDate(newDate)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        sx={{
                          label: { fontSize: 12 },
                        }}
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Vehicle Number</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Vehicle Number"
                    name="vehicle"
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={(e) => handleVehicle(e)}
                    value={addForm.vehicle || ""}>
                    {vehicleNumber.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.vehicleNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Route Title</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Route Title"
                    variant="outlined"
                    name="route"
                    size="small"
                    fullWidth
                    onChange={handleEditFormChange}
                    value={addForm.route || ""}>
                    {transportRouteTitle.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.transportRouteTitle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth required size="small">
                  <TextField
                    id="filled-required"
                    variant="outlined"
                    label="Departure Time"
                    name="departureTime"
                    type="time"
                    size="small"
                    fullWidth
                    value={departureTime || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleDepartureSclTime}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography textAlign="center">Reading at Departure</Typography>
                <Box
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "5px",
                    padding: "15px 10px",
                    background: "whitesmoke",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}>
                    {" "}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <FormControl fullWidth required size="small">
                        <TextField
                          id="outlined-basic"
                          label="Depature From School Km"
                          variant="outlined"
                          type="number"
                          size="small"
                          fullWidth
                          onChange={handleEditFormChange}
                          value={addForm.readingAtDeparture}
                          name="readingAtDeparture"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Tooltip title="Upload Material">
                        <Button
                          component="label"
                          variant="outlined"
                          fullWidth
                          sx={{
                            padding: "7px",
                          }}
                          size="small">
                          upload file
                          <input
                            hidden
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: "block" }}
                          />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ textAlign: "center", paddingTop: "1px" }}>
                    {fileName ? <Typography>{fileName}</Typography> : ""}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    type="button"
                    onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={vehicleLoader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}>
                    Add
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      {editVehicleLog ? (
        <Dialog
          fullScreen={fullScreen}
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="responsive-dialog-title">
          <form onSubmit={handleUpdateSubmit}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold">
                    Update Vehicle Log
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      variant="standard"
                      value={date}
                      onChange={(newDate) => setDate(newDate)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          sx={{
                            label: { fontSize: 12 },
                          }}
                          {...params}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Vehicle Number</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Vehicle Number"
                      name="vehicle"
                      size="small"
                      fullWidth
                      variant="outlined"
                      onChange={(e) => handleVehicle(e)}
                      value={addForm.vehicle || ""}>
                      {vehicleNumber.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.vehicleNumber}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Route Title</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Route Title"
                      variant="outlined"
                      name="route"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.route}>
                      {transportRouteTitle.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.transportRouteTitle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="filled-required"
                      variant="outlined"
                      label="Departure Time"
                      name="departureTime"
                      type="time"
                      size="small"
                      fullWidth
                      value={departureTime || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleDepartureSclTime}
                      sx={{
                        label: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography textAlign="center">
                    Reading at Departure
                  </Typography>
                  <Box
                    sx={{
                      border: "1px solid gray",
                      borderRadius: "5px",
                      padding: "15px 10px",
                      background: "whitesmoke",
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}>
                      {" "}
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <FormControl fullWidth required size="small">
                          <TextField
                            id="outlined-basic"
                            label="Depature From School Km"
                            variant="outlined"
                            type="number"
                            size="small"
                            fullWidth
                            onChange={handleEditFormChange}
                            value={addForm.readingAtDeparture}
                            name="readingAtDeparture"
                            disabled={editVehicleLog}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Tooltip title="Upload Material">
                          <Button
                            component="label"
                            variant="outlined"
                            fullWidth
                            sx={{
                              padding: "7px",
                            }}
                            size="small">
                            upload file
                            <input
                              hidden
                              type="file"
                              onChange={handleFileChange}
                              style={{ display: "block" }}
                              disabled={editVehicleLog}
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Box>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ textAlign: "center", paddingTop: "1px" }}>
                      {fileName ? <Typography>{fileName}</Typography> : ""}
                    </Grid>
                    {list.find((l) => l._id == editVehicleLog)
                      ?.readingAtDeparture &&
                    list.find((l) => l._id == editVehicleLog)
                      ?.readingAtDeparture?.image ? (
                      <Button
                        sx={{ marginTop: "10px" }}
                        variant="outlined"
                        onClick={handleDownload}>
                        DownLoad File
                      </Button>
                    ) : null}
                  </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography textAlign="center">Reading at Arrival</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="filled-required"
                      variant="outlined"
                      label="Arrival Time"
                      name="arrivalTime"
                      type="time"
                      size="small"
                      fullWidth
                      value={arrivalTime || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleArrivalTime}
                      sx={{
                        label: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      border: "1px solid gray",
                      borderRadius: "5px",
                      padding: "15px 10px",
                      background: "whitesmoke",
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}>
                      {" "}
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <FormControl fullWidth required size="small">
                          <TextField
                            id="outlined-basic"
                            label="Arrival From School Km"
                            variant="outlined"
                            type="number"
                            size="small"
                            fullWidth
                            onChange={handleUpdateFormChange}
                            value={editFormData.readingAtArrival}
                            name="readingAtArrival"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Tooltip title="Upload Material">
                          <Button
                            component="label"
                            variant="outlined"
                            fullWidth
                            sx={{
                              padding: "7px",
                            }}
                            size="small">
                            upload file
                            <input
                              hidden
                              type="file"
                              onChange={handleFileChangeArrival}
                              style={{ display: "block" }}
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Box>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ textAlign: "center", paddingTop: "1px" }}>
                      {fileNameArrival ? (
                        <Typography>{fileNameArrival}</Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    {list.find((l) => l._id == editVehicleLog)
                      ?.readingAtArrival &&
                    list.find((l) => l._id == editVehicleLog)?.readingAtArrival
                      ?.image ? (
                      <Button
                        variant="outlined"
                        sx={{ marginTop: "10px" }}
                        onClick={handleDownloadArrival}>
                        DownLoad File
                      </Button>
                    ) : null}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Distance"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleUpdateFormChange}
                      value={editFormData.distance}
                      name="distance"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Arrival From School Km"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleUpdateFormChange}
                      value={editFormData.reason}
                      name="reason"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "10px",
                      paddingTop: "10px",
                    }}>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      type="button"
                      onClick={handleCloseEditModal}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={vehicleLoader}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}>
                      {editVehicleLog ? "Update" : "Add"}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
};

export default VehicleLog;
