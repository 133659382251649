import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  TextareaAutosize,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SettingContext from "../../../context/SettingsContext";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { get, post, put } from "../../../services/apis";
import TimezoneSelect from "react-timezone-select";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { urls as url } from "../../../services/urlConstant";
import appendStringToUrls from "../../../utils/appendString";
import currencyCodes from "currency-codes";
import currencyToSymbolMap from "currency-symbol-map/map";
import avatar from "../../../assets/Images/avatar.jpg";
import StickyBar from "../../StickyBar";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import banner from "../../../assets/Images/admissionbg.jpg";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    overflow: "visible",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    color: "#fff",
  },
};

const AddSetting = () => {
  const { settings, setSettings, selectedSetting, setSettingsUpdateCount } =
    useContext(SettingContext);
  const navigate = useNavigate();
  const [addDialog, setAddDialog] = useState(false);
  const currencies = currencyCodes.data;
  const currenciess = Object.keys(currencyToSymbolMap);
  const [schoolCreateLogoLink, setSchoolCreateLogoLink] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [previewCreateUrl, setPreviewCreateUrl] = useState(null);
  const [schoolCreateLogo, setSchoolCreatelLogo] = useState("");
  const { setSettingsContext } = useContext(SettingContext);
  const [logoLoader, setLogoLoader] = useState(false);

  const [createDetails, setCreateDetails] = useState({
    regDate: dayjs(Date.now()),
    bannerImages: [],
  });

  const [loading, setLoading] = useState(false);
  const [bannerLoader, setBannerLoader] = useState(false);
  // const handleCreateOnChange = (e) => {
  //   const { name, value } = e.target;
  //   setCreateDetails({ ...createDetails, [name]: value });
  // };

  const handleCreateOnChange = (event) => {
    const { name, value } = event.target;
    let symbol = "";
    if (name === "currency") {
      symbol = currencyToSymbolMap[value] || "";
    }

    setCreateDetails((prev) => ({
      ...prev,
      [name]: value,
      currencySymbol: name === "currency" ? symbol : prev.currencySymbol,
    }));
  };

  const handleBannerImg = async (e) => {
    setBannerLoader(true);
    const formData = new FormData();

    if (e.target.files.length + createDetails.bannerImages?.length > 6) {
      toast.error("You can only select up to 6 files.");
      e.target.value = null;
      setBannerLoader(false);
      return;
    }

    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("files", e.target.files[i]);
    }

    console.log(formData, "====formData");

    try {
      const res = await post(urls.fileUpload.postFileUpload, formData, {
        headers: { roleFunction: "setting" },
      });

      const fileLinks = res.data.result.map((file) => file.link);

      setCreateDetails({
        ...createDetails,
        bannerImages: [...createDetails.bannerImages, ...fileLinks],
      });

      setBannerLoader(false);
    } catch (error) {
      console.error(error);
      setBannerLoader(false);
    }
  };

  const handleRemoveImg = (img) => {
    let newBannerImg = createDetails.bannerImages?.filter((c) => c != img);
    setCreateDetails((pre) => ({ ...pre, bannerImages: newBannerImg }));
  };

  const handleCreateSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      // if (createDetails.bannerImages.length < 3) {
      //   toast.error("You have to select at least 3 banner images.");
      //   setLoading(false);
      //   return;
      // }
      const data = {
        ...createDetails,
        schoolName: createDetails.schoolName,
        twitterUrl: createDetails.twitterUrl,
        linkedinUrl: createDetails.linkedinUrl,
        gplusUrl: createDetails.gplusUrl,
        youtubeUrl: createDetails.youtubeUrl,
        instagramUrl: createDetails.instagramUrl,
        pinterestUrl: createDetails.pinterestUrl,
        facebookUrl: createDetails.facebookUrl,
        address: createDetails.address,
        phone: createDetails.phone,
        regDate: dayjs(createDetails.regDate).format("YYYY-MM-DD"),
        email: createDetails.email,
        schFax: createDetails.schFax,
        websiteFooter: createDetails.websiteFooter,
        description: createDetails.description,
        logo: createDetails.logo,
        currency: createDetails.currency,
        currencySymbol: createDetails.currencySymbol,
        bannerImages: createDetails.bannerImages || [],
        sessionStartMonth: createDetails.sessionStartMonth,
        sessionEndMonth: createDetails.sessionEndMonth,
        rollNumberType: createDetails.rollNumberType,
        admissionNo: createDetails.admissionNo,
        studentAttendenceType: createDetails.studentAttendenceType,
        longitude: createDetails.longitude,
        latitude: createDetails.latitude,
        googleAnalyticsId: createDetails.googleAnalyticsId,
        defaultTimeZone: createDetails.defaultTimeZone,
        teacherActivityFeedbackEnabled:
          createDetails.teacherActivityFeedbackEnabled,
      };

      const res = await post(urls.settings.postSettings, data, {
        headers: { roleFunction: "setting" },
      });

      if (res.data.success) {
        // setSettings([...settings, res.data.data]);
        setSettingsUpdateCount((prev) => prev + 1);

        setAddDialog(false);
        resetForm();

        // setSettingsContext((prev) => ({
        //   ...prev,
        //   schoolName: data.schoolName,
        //   schoolLogo: schoolCreateLogoLink,
        // }));
        navigate(`/manageSetting`);
      }
      // setTimeout(() => {
      //   setLoading(false);
      // }, 2000);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const resetForm = () => {
    setCreateDetails({});
  };

  const handleCreateOnChangeTime = (d) => {
    setCreateDetails({ ...createDetails, defaultTimeZone: d });
  };

  const handleCreatePhoto = async (e) => {
    setLogoLoader(true);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      setPreviewCreateUrl(URL.createObjectURL(e.target.files[0]));
      const res = await post(urls.fileUpload.postFileUpload, formData, {
        headers: { roleFunction: "setting" },
      });
      setCreateDetails({
        ...createDetails,
        logo: res.data?.result[0]?._id,
      });
      setSchoolCreateLogoLink(res.data?.result[0]?.link);
    } catch (error) {
      console.log(error);
    }
    setLogoLoader(false);
  };

  return (
    <>
      <form onSubmit={handleCreateSubmit}>
        <div style={{ margin: 15 }}>
          <Card style={{ margin: "20px 0px", padding: "22px 0px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} mg={12} lg={12}>
                <Typography
                  style={{
                    color: "red",

                    display: "inline-block",
                  }}
                >
                  Add Settings :
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              py: "15px",
              mb: "15px",
            }}
          >
            {/* <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            > */}
            {/* <Grid item xs={12} md={12} lg={12}> */}
            {/* <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                Logo: ss
              </Typography> */}
            {/* </Grid> */}
            {/* <Grid item lg={12} sx={{ marginLeft: "20px" }} mt={1}> */}
            <Box
              sx={{
                background: "#ececec",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundPosition: "center",
              }}
            >
              <img
                src={previewCreateUrl ? previewCreateUrl : avatar}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
                alt="Preview"
              />
            </Box>

            {/* {previewCreateUrl && (
              <img
                src={previewCreateUrl}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
                alt="Preview"
              />
            )} */}

            <LoadingButton
              loading={logoLoader}
              variant="outlined"
              component="label"
              sx={{ mt: "12px" }}
              size="small"
            >
              Upload Logo
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                value={schoolCreateLogo}
                onChange={handleCreatePhoto}
              />
            </LoadingButton>

            {/* </Grid> */}
            {/* </Grid> */}
          </Card>
          <Card style={styles.card}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  Basic Information:
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="schoolName"
                  value={createDetails.schoolName}
                  onChange={(event) => handleCreateOnChange(event)}
                  label="School Name"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  label="Address"
                  name="address"
                  value={createDetails.address}
                  onChange={(event) => handleCreateOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="number"
                  label="Phone"
                  name="phone"
                  value={createDetails.phone}
                  onChange={(event) => handleCreateOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="date"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  label="Registration Date"
                  name="regDate"
                  value={dayjs(createDetails.regDate).format("YYYY-MM-DD")}
                  onChange={(event) => handleCreateOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  label="Email "
                  name="email"
                  value={createDetails.email}
                  onChange={(event) => handleCreateOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  label="Fax"
                  name="schFax"
                  value={createDetails.schFax}
                  onChange={(event) => handleCreateOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  label="Website Footer"
                  name="websiteFooter"
                  value={createDetails.websiteFooter}
                  onChange={(event) => handleCreateOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  InputLabelProps={{
                    shrink: true,
                  }}
                  maxRows={4}
                  name="description"
                  value={createDetails.description}
                  onChange={(event) => handleCreateOnChange(event)}
                  style={{
                    width: "100%",
                    // marginLeft: 10,
                    height: 100,
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                />
              </Grid>
            </Grid>
          </Card>
          <Card style={styles.card}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  Setting Information:
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl size="small" required fullWidth>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    label="Currency"
                    id="demo-simple-select-filled"
                    name="currency"
                    fontSize="small"
                    onChange={(event) => handleCreateOnChange(event)}
                    value={createDetails.currency}
                  >
                    {currencies?.map((currency) => (
                      <MenuItem
                        key={currency.code}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={currency.code || ""}
                      >
                        {currency.currency} ({currency.code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl size="small" required fullWidth>
                  <InputLabel>Currency Symbol</InputLabel>
                  <Select
                    key={createDetails.currency}
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Currency Symbol"
                    onChange={(event) => handleCreateOnChange(event)}
                    name="currencySymbol"
                    value={createDetails.currencySymbol}
                  >
                    <MenuItem
                      key={createDetails.currency}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={createDetails.currencySymbol}
                    >
                      {createDetails.currencySymbol || ""}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="Session Start Month"
                      views={["month"]}
                      sx={{ fontSize: 14, fontWeight: 500 }}
                      inputFormat="MMMM"
                      value={createDetails.sessionStartMonth}
                      name="sessionStartMonth"
                      onChange={(newValue) => {
                        setCreateDetails({
                          ...createDetails,
                          sessionStartMonth: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          required
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="Session End Month"
                      views={["month"]}
                      inputFormat="MMMM"
                      name="sessionEndMonth"
                      sx={{ fontSize: 14, fontWeight: 500 }}
                      value={createDetails.sessionEndMonth}
                      onChange={(newValue) => {
                        setCreateDetails({
                          ...createDetails,
                          sessionEndMonth: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          // sx={{ m: 1, fontSize: 14, fontWeight: 500 }}
                          size="small"
                          required
                          id="outlined-basic"
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Roll Number</InputLabel>
                  <Select
                    label="roll number"
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="rollNumberType"
                    onChange={(event) => handleCreateOnChange(event)}
                    value={createDetails.rollNumberType}
                  >
                    <MenuItem
                      value={"manual"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Manual
                    </MenuItem>
                    <MenuItem
                      value={"ascendingName"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Auto ascending name
                    </MenuItem>
                    <MenuItem
                      value={"ascendingNameFemale"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Auto ascending name female
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel sx={{ fontSize: 12 }}>
                    Student Attendance Type
                  </InputLabel>
                  <Select
                    label=" Student Attendance Type"
                    name="studentAttendenceType"
                    value={createDetails.studentAttendenceType}
                    onChange={(event) => handleCreateOnChange(event)}
                  >
                    <MenuItem
                      value={"classWise"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Class-Wise
                    </MenuItem>
                    <MenuItem
                      value={"sectionWise"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Section-Wise
                    </MenuItem>
                    <MenuItem
                      value={"sessionWise"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Session-Wise
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Admission Number</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Admission Number"
                    variant="outlined"
                    onChange={(event) => handleCreateOnChange(event)}
                    name="admissionNo"
                    value={createDetails.admissionNo}
                  >
                    <MenuItem
                      value={"manual"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Manual
                    </MenuItem>
                    <MenuItem
                      value={"autoAscendingNo"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Auto ascending no
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  label="Latitude"
                  size="small"
                  onChange={(event) => handleCreateOnChange(event)}
                  value={createDetails.latitude}
                  // sx={{ mt: 1, label: { fontSize: 12 } }}
                  name="latitude"
                />
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  fullWidth
                  size="small"
                  label="Longitude"
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(event) => handleCreateOnChange(event)}
                  value={createDetails.longitude}
                  sx={{ mt: 1, label: { fontSize: 12 } }}
                  name="longitude"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TimezoneSelect
                  styles={{
                    control: (baseStyle, state) => ({
                      ...baseStyle,
                    }),
                  }}
                  placeholder="Select default timezone"
                  key={createDetails.defaultTimeZone}
                  value={createDetails.defaultTimeZone || {}}
                  onChange={handleCreateOnChangeTime}
                  name="defaultTimeZone"
                />
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  size="small"
                  fullWidth
                  onChange={(event) => handleCreateOnChange(event)}
                  id="outlined-basic"
                  variant="outlined"
                  label="Google Analytics"
                  sx={{ label: { fontSize: 12 } }}
                  name="googleAnalyticsId"
                  value={createDetails.googleAnalyticsId}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <FormControl size="small" required fullWidth>
                  <InputLabel>Teacher Activity Feedback Enabled</InputLabel>
                  <Select
                    fullWidth
                    key={createDetails.teacherActivityFeedbackEnabled}
                    size="small"
                    label="teacher activity feedback enabled"
                    id="demo-simple-select-filled"
                    onChange={(event) => handleCreateOnChange(event)}
                    value={createDetails.teacherActivityFeedbackEnabled}
                    name="teacherActivityFeedbackEnabled"
                    fontSize="small"
                  >
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"yes"}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"no"}
                    >
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Social Information:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    name="facebookUrl"
                    value={createDetails.facebookUrl}
                    onChange={(event) => handleCreateOnChange(event)}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label="Facebook URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    name="twitterUrl"
                    value={createDetails.twitterUrl || ""}
                    onChange={handleCreateOnChange}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label="Twitter URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    name="linkedinUrl"
                    value={createDetails.linkedinUrl || ""}
                    onChange={handleCreateOnChange}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label="Linkedin URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    name="gplusUrl"
                    value={createDetails.gplusUrl || ""}
                    onChange={handleCreateOnChange}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label="Google Plus URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    name="youtubeUrl"
                    value={createDetails.youtubeUrl || ""}
                    onChange={handleCreateOnChange}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label="Youtube URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    name="instagramUrl"
                    value={createDetails.instagramUrl || ""}
                    onChange={handleCreateOnChange}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label="Instagram URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    name="pinterestUrl"
                    value={createDetails.pinterestUrl || ""}
                    onChange={handleCreateOnChange}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label="Pinterest URL "
                    sx={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent="space-between"
              >
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  Banner Images:
                </Typography>
                <LoadingButton
                  loading={bannerLoader}
                  variant="outlined"
                  component="label"
                  size="small"
                  sx={{
                    objectFit: "contain",
                  }}
                >
                  Upload Banner
                  <input
                    hidden
                    id="p_file"
                    type="file"
                    accept="image/*"
                    multiple
                    // value={createDetails?.bannerImages && ""}
                    onChange={handleBannerImg}
                  />
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                className="banner-scroll"
                gap={2}
                sx={{
                  display: "flex",
                  overflowX: "auto",
                }}
              >
                {createDetails?.bannerImages?.map((img, i) => (
                  <Box
                    key={i}
                    sx={{
                      justifyContent: "center",
                      borderRadius: "5px",
                      backgroundSize: "cover",
                      "&:hover": {
                        position: "relative",
                        display: "inline-block",
                      },
                    }}
                  >
                    <img
                      src={img}
                      style={{
                        width: "150px",
                        height: "100px",
                        marginLeft: "5px",
                        borderRadius: "5px",
                        boxShadow: "0px 0px 2px 0px gray",
                      }}
                      alt="Preview"
                    />
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => handleRemoveImg(img)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        borderRadius: "50px",
                        padding: "2px",
                        cursor: "pointer",
                        background: "#DEE0E2",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Card>
          <StickyBar
            content={
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "5px",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => navigate(-1)}
                  sx={{ color: "#fff" }}
                >
                  Back
                </Button>
                <LoadingButton
                  type="submit"
                  size="small"
                  sx={styles.Button}
                  loading={loading}
                  variant="contained"
                >
                  Create
                </LoadingButton>
              </Grid>
            }
          />
        </div>
        <div style={{ marginBottom: "70px" }}></div>
      </form>
    </>
  );
};

export default AddSetting;
