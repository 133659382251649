import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Fab,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  Typography,
  Stack,
  TextareaAutosize,
  Dialog,
  IconButton,
  Tooltip,
  TablePagination,
  TableContainer,
  Switch,
} from "@mui/material";
import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { get, post, put } from "../../services/apis";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { urls as url } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShowComponent from "../ShowComponent";
import ModalDialog from "@mui/joy/ModalDialog";
import deleteIcon from "../../assets/Images/deleteIcon.png";
import copy from "clipboard-copy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";

const PreExamShedules = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [exam, setExam] = useState([]);
  const [addExam, setAddExam] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [dateOfExam, setDateOfExam] = useState(Date.now());
  const [editId, setEditId] = useState("");
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [toDate, setToDate] = useState(Date.now());
  const [classes, setClasses] = useState([]);
  const [addForm, setAddForm] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [deletes, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [findLoad, setFindLoad] = useState(false);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loader, setLoader] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnChangeForm = (e) => {
    const { name, value } = e.target;
    setAddForm({
      ...addForm,
      [name]: value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddForm({});
    setEditId("");
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "preaddmissionExamSchedule" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "preaddmissionExamSchedule" },
        });

        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getExam = async () => {
      try {
        const res = await get(urls.preExam.get, {
          headers: { roleFunction: "preaddmissionExamSchedule" },
          params: {
            search: {
              class: formData.schoolClass,
              academicYear: formData.academicYear,
            },
          },
        });
        const res2 = await get(urls.preExam.get, {
          headers: { roleFunction: "preaddmissionExamSchedule" },
          params: {
            search: {
              class: addForm.schoolClass,
              academicYear: addForm.academicYear,
            },
          },
        });
        console.log(res, "ertwrtrt");
        setExam(res.data.data);
        setAddExam(res2.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExam();
  }, [selectedSetting, formData, addForm]);
  const handleDelete = (id) => {
    setDelete(true);
    setDeleteId(id);
  };
  const handleDeleteQuiz = async () => {
    try {
      const response = await put(
        `${urls.preExamSchedules.delete}/${deleteId}`,
        "",
        {},
        { headers: { roleFunction: "preaddmissionExamSchedule" } }
      );
      handleDeleteClose();
      handleSearch();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteClose = () => {
    setDelete(false);
    setDeleteId("");
  };

  // useEffect(() => {
  //   handleSearch();
  // }, []);
  const handleSearch = async () => {
    try {
      const response = await get(urls.preExamSchedules.get, {
        headers: { roleFunction: "preaddmissionExamSchedule" },
        params: {
          search: {
            class: formData.schoolClass,
            academicYear: formData.academicYear,
            exam: formData.exam,
            createdAt: {
              $gte: fromDate,
              $lte: dayjs(toDate).add(1, "day").toDate(),
            },
          },
        },
      });
      console.log(response.data.data, "response");
      setList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateLink = async (id) => {
    try {
      const res = await put(
        `${urls.preExamSchedules.enableExamLink}${id}`,
        "",
        {},
        { headers: { roleFunction: "preaddmissionExamSchedule" } }
      );
      handleSearch();
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!editId) {
        let data = {
          classId: addForm.schoolClass,
          academicYearId: addForm.academicYear,
          examId: addForm.exam,
          dateOfExam: dateOfExam,
          startTime: addForm.startTime,
          endTime: addForm.endTime,
          address: addForm.address,
          roomNumber: addForm.roomNo,
        };
        const response = await post(urls.preExamSchedules.post, data, {
          headers: { roleFunction: "preaddmissionExamSchedule" },
        });

        if (response.status === 200) {
          setList([...list, response.data.data]);
          handleClose();
        }
      } else {
        let data = {
          classId: addForm.schoolClass,
          academicYearId: addForm.academicYear,
          examId: addForm.exam,
          dateOfExam: dateOfExam,
          startTime: addForm.startTime,
          endTime: addForm.endTime,
          address: addForm.address,
          roomNumber: addForm.roomNo,
        };
        const response = await put(
          `${urls.preExamSchedules.update}/${editId}`,
          "",
          data,
          { headers: { roleFunction: "preaddmissionExamSchedule" } }
        );

        if (response.status === 200) {
          console.log(response.data, "data======");
          setList(
            list.map((l) =>
              l._id == response.data.data._id ? response.data.data : l
            )
          );
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleEdit = (id, data) => {
    console.log(data, "khkjkjk");
    setEditId(id);
    setAddForm({
      schoolClass: data.class._id,
      academicYear: data.academicYear._id,
      exam: data.exam._id,
      startTime: data.startTime,
      endTime: data.endTime,
      address: data.venue.address,
      roomNo: data.venue.roomNumber,
    });
    setDateOfExam(data.dateOfExam);
    setOpen(true);
  };

  const handleSubmitNotify = async (e, id) => {
    e.preventDefault();
    try {
      const res = await post(
        `${urls.preExamSchedules.sendExamDetailsToStudents}/${id}`,
        "",
        { headers: { roleFunction: "preaddmissionExamSchedule" } }
      );
      handleSearch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyLink = (copylink) => {
    copy(`${copylink}`);
    toast.success("Exam Link Copied successfully");
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Exam Schedules" />
      <Card style={{ marginBottom: "15px", padding: 10 }}>
        <Box>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="academic year"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <FormControl size="small" fullWidth required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    label="class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={formData.schoolClass || ""}
                    onChange={handleOnChange}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <FormControl size="small" fullWidth required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Exam
                  </InputLabel>
                  <Select
                    label="Exam"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="examName"
                    value={formData.examName || ""}
                    onChange={handleOnChange}
                  >
                    {exam.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.examName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate || ""}
                      onChange={setFromDate}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD-MM-YYYY"
                      value={toDate || ""}
                      onChange={setToDate}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4} lg={3} sx={{ mt: 0.5 }}>
                <Button
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  size="small"
                  variant="contained"
                  onClick={handleSearch}
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow>
              <TableCell align="center" sx={{ color: "white" }}>
                #SL
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Academic Year
              </TableCell>

              <TableCell align="center" sx={{ color: "white" }}>
                Class
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Exam
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Exam Link
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {list
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, index) => (
              <TableRow>
                <TableCell align="center">
                  {page * rowsPerPage + index + 1}
                </TableCell>
                <TableCell align="center">
                  {data.academicYear.academicYearFrom}-
                  {data.academicYear.academicYearTo}
                </TableCell>

                <TableCell align="center">{data.class.className}</TableCell>
                <TableCell align="center">{data.exam.examName}</TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={`${
                      data.examLinkEnabled === true ? "Enable" : "Disable"
                    }`}
                  >
                    <Switch
                      key={data._id}
                      onClick={() => handleUpdateLink(data._id)}
                      defaultChecked={data.examLinkEnabled ? true : false}
                    />
                  </Tooltip>

                  <Tooltip title="Copy Link">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleCopyLink(data?.examLink)}
                    >
                      <ContentCopyIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <ShowComponent
                      roleFunction="preaddmissionExamSchedule"
                      action="update"
                    >
                      <Tooltip title="Update">
                        <IconButton
                          style={{
                            color: "#1b3779",
                          }}
                          size="small"
                          onClick={() => handleEdit(data._id, data)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ShowComponent>
                    <ShowComponent
                      roleFunction="preaddmissionExamSchedule"
                      action="delete"
                    >
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => handleDelete(data._id)}
                        >
                          <Delete color="error" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ShowComponent>

                    {/* {data.notified === false && ( */}
                    <Button
                      size="small"
                      onClick={(e) => handleSubmitNotify(e, data._id)}
                      disabled={data.notified === true}
                      variant="contained"
                    >
                      {data.notified === true ? "Notified" : "Notify"}
                    </Button>
                    {/* )} */}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </Table>
        {!list.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />
      {/* Add exam Schedule*/}
      <ShowComponent roleFunction="preaddmissionExamSchedule" action="add">
        <Box className="add-icon">
          <Tooltip title="Add Exam Schedules">
            <Fab
              variant="contained"
              sx={{
                color: "#ffff",

                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleClickOpen}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box>
      </ShowComponent>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={{ padding: 2 }}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {editId ? "Update Exam Schedule" : "Add Exam Schedule"}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl size="small" fullWidth required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="academic year"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={addForm.academicYear || ""}
                    onChange={handleOnChangeForm}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl size="small" fullWidth required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    label="class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={addForm.schoolClass || ""}
                    onChange={handleOnChangeForm}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl size="small" fullWidth required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Exam
                  </InputLabel>
                  <Select
                    label="exam"
                    fullWidth
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="exam"
                    value={addForm.exam || ""}
                    onChange={handleOnChangeForm}
                  >
                    {addExam &&
                      addExam.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.examName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4} sx={{ alignSelf: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="Date Of Exam"
                      inputFormat="DD-MM-YYYY"
                      value={dateOfExam || ""}
                      onChange={setDateOfExam}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  variant="outlined"
                  type="time"
                  size="small"
                  name="timeOfExam"
                  value={addForm.timeOfExam}
                  onChange={handleOnChangeForm}
                  label="Time Of Exam"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid> */}

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  name="startTime"
                  id="filled-required"
                  size="small"
                  type="time"
                  value={addForm.startTime || ""}
                  onChange={handleOnChangeForm}
                  label="Start Time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  name="endTime"
                  id="filled-required"
                  size="small"
                  type="time"
                  value={addForm.endTime || ""}
                  onChange={handleOnChangeForm}
                  label="End Time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="roomNo"
                  label="Room No"
                  type="number"
                  value={addForm.roomNo || ""}
                  onChange={handleOnChangeForm}
                  sx={{ label: { fontSize: 12 } }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  fullWidth
                  required
                  placeholder="Address"
                  name="address"
                  value={addForm.address || ""}
                  onChange={handleOnChangeForm}
                  style={{
                    width: "100%",
                    maxHeight: 110,

                    padding: 10,
                    height: 70,
                    overflow: "auto",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "3%",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    type="submit"
                    size="small"
                    sx={{
                      background: "#1b3779",
                      "&:hover": {
                        background: "#1b3779",
                      },
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={deletes}
        onClose={handleDeleteClose}
      >
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{
            background: "#fff",
            border: "none",
          }}
        >
          <Box sx={{ textAlign: "center", margin: "10px auto" }}>
            <img src={deleteIcon} width={50} height={50} />
          </Box>

          <Box p={2}>
            <Typography
              id="alert-dialog-modal-description"
              textAlign="center"
              my={1}
            >
              Are you sure you want to remove this?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                mt: 2,
              }}
            >
              <Button
                variant="plain"
                color="neutral"
                size="small"
                onClick={handleDeleteClose}
                sx={{
                  background: "#ddd",
                  ":hover": { background: "#ddd", opacity: 0.8 },
                  objectFit: "contain",
                }}
              >
                No,Cancel
              </Button>

              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDeleteQuiz}
              >
                Yes, I'm Sure
              </Button>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
    </Wrapper>
  );
};

export default PreExamShedules;
