import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import DeleteModal from "../Academic/DeleteModal";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { objHas } from "../../../utils/utils";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { LoadingButton } from "@mui/lab";
import {
  PrintSharp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import { Search } from "@material-ui/icons";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import ShowComponent from "../../ShowComponent";
const styles = {
  icon: {
    // padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1B3779",
    color: "#fff",
    ":hover": { background: "#1B3779" },
  },
};
const modal = {
  bgcolor: "background.paper",
  p: 2,
};

const StudentTable = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const pageRef = useRef(null);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [updateStudent, setUpdateStudent] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(false);
  const [item, setItem] = useState(false);
  const [print, setPrint] = useState(false);
  const [formData, setFormData] = useState({
    status: "active",
  });
  const [bulkAdmitModal, setBulkAdmitModal] = useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [totalCount, setTotalCount] = useState(100);
  const [search, setSearch] = useState("");
  const [showPagination, setShowPagination] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [rowsData, setRowsData] = useState(null);
  const [searchFilter, setSearchFilter] = useState([]);
  const [disable, setDisable] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };
  const [activeSortColumn, setActiveSortColumn] = useState("");
  // const [sortOrder, setSortOrder] = useState("asc");
  // const [sortedData, setSortedData] = useState(schoolStudents);

  const [sortField, setSortField] = useState("sectionOrder");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedUser, setSelectedUser] = useState(null);
  const [downloadingAll, setDownloadingAll] = useState(false);

  const location = useLocation();
  const { state } = location;

  //key value pair
  const [sort, setSort] = useState({
    order: "asc",
    rollNo: "asc",
    admissionNo: "asc",
    name: "asc",
    status: "asc",
    section: "asc",
  });

  const sortByRoll = (e) => {
    if (sort.rollNo == "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const sortByAdmissionNo = (e) => {
    console.log("jjjj");
    if (sort.admissionNo == "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => b.academicInfo.admissionNo - a.academicInfo.admissionNo
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, admissionNo: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => a.academicInfo.admissionNo - b.academicInfo.admissionNo
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, admissionNo: "asc" });
    }
  };

  // sorting.....
  const sortByName = (e) => {
    console.log("name");
    if (sort.name == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  // status
  const sortByStatus = (e) => {
    console.log("status");

    if (sort.status == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) => b.status.localeCompare(a.status)),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, status: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) => a.status.localeCompare(b.status)),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, status: "asc" });
    }
  };

  const sortBySection = (e) => {
    if (sort.section == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.academicInfo.section.sectionName.localeCompare(
            a.academicInfo.section.sectionName
          )
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, section: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.academicInfo.section.sectionName.localeCompare(
            b.academicInfo.section.sectionName
          )
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, section: "asc" });
    }
  };

  const sortedUsers = schoolStudents.slice().sort((a, b) => {
    const fieldA = a[sortField];
    const fieldB = b[sortField];
    if (fieldA < fieldB) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (fieldA > fieldB) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleIconChange = async (e) => {
    e.preventDefault();
    try {
      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "admitStudent" },
          params: {
            search: {
              "basicInfo.name": { $regex: search, $options: "i" },
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            },
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "admitStudent" },
          params: {
            search: {
              "basicInfo.name": { $regex: search, $options: "i" },
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSort({
      order: "asc",
      rollNo: "asc",
      admissionNo: "asc",
      name: "asc",
      status: "asc",
      section: "asc",
    });
  }, [schoolStudents.length]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);

  useEffect(() => {
    const getAcademicYears = async () => {
      setPage(0);
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "admitStudent" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  const handleOnChange = (e) => {
    setPage(0);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    setPage(0);

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "admitStudent" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const res = await del(
        urls.students.delStudent,
        id,
        {},
        { roleFunction: "admitStudent" }
      );
      if (res.data.success) {
        const filteredStudent = schoolStudents.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolStudents(filteredStudent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  function handlepaginationSearch() {
    if (!schoolStudents.length) {
      setShowPagination(false);
    }
  }

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "admitStudent" },
        });
        let search = res.data.data.length
          ? { class: res.data.data[0]._id }
          : {};
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "admitStudent" },
          params: {
            search,
          },
        });

        setClasses(res.data.data);

        setSections(resSections.data.data);

        if (state) {
          const resSections = await get(urls.schoolSection.getAllSection, {
            headers: { roleFunction: "admitStudent" },
            params: {
              search: { class: state.class },
            },
          });
          setSections(resSections.data.data);
          return setFormData((prev) => ({
            ...prev,
            schoolClass: state.class,
            section: state.section,
            status: state.status,
          }));
        }

        setFormData((prev) => ({
          ...prev,
          schoolClass: res.data.data.length ? res.data.data[0]._id : "",
          section: "all",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting, state]);

  useEffect(() => {
    setPage(0);
  }, [formData]);

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getStudentById = schoolStudents.find((item) => item._id === id);
      setUpdateStudent(getStudentById);
      navigate(`/edit-student/${getStudentById._id}`, {
        state: {
          class: formData.schoolClass,
          section: formData.section,
          status: formData.status,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAdmitSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const getSheet = await get(`${urls.students.getBulkAdmitSheet}`, {
        headers: { roleFunction: "admitStudent" },
        // const getSheet = await get(`/bulk-admit/student/${formData.schoolClass}/${formData.section}/${formData.academicYear}`, {

        responseType: "blob",
      });
      const uri = URL.createObjectURL(getSheet.data);

      const link = document.createElement("a");
      link.href = uri;
      console.log("from", formData);
      const academicsYear = formData.academicYear;
      const getExcelyearById = academicYears.find(
        (excel) => excel._id === academicsYear
      );
      const classename = formData.schoolClass;
      const getExcelById = classes.find((excel) => excel._id === classename);
      const sectionsname = formData.section;
      const getExcelsectionById = sections.find(
        (excel) => excel._id === sectionsname
      );
      // link.setAttribute("download", `studentadmit.xlsx`);
      link.setAttribute(
        "download",
        `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName}.xlsx`
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error(error.message);
    }
    setLoadingBtn(false);
  };

  const handleGetPrintPdf = async (e) => {
    setPrint(true);
    try {
      if (formData.section === "all") {
        const getPrint = await get(
          `${urls.students.getStudentsListPdf}/${formData.schoolClass}/${formData.academicYear}`,
          {
            headers: { roleFunction: "admitStudent" },
            responseType: "blob",
          }
        );
        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      } else {
        const getPrint = await get(
          `${urls.students.getStudentsListBySectionPdf}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          {
            headers: { roleFunction: "admitStudent" },
            responseType: "blob",
          }
        );

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
      setPrint(false);
    } catch (error) {
      console.log(error);
      setPrint(false);
    }
  };

  // useEffect(() => {
  //   handleStudentSearch();
  // }, [page, rowsPerPage, formData]);

  const handleStudentSearch = async () => {
    if (
      !formData.academicYear ||
      !formData.schoolClass ||
      !formData.status ||
      !formData.section
    )
      return;
    try {
      if (formData.section === "all") {
        const searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            };
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "admitStudent" },
          params: {
            search: searchParams,
            // limit: rowsPerPage,
            // page: page + 1,
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        let searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            };
        const searchStudent = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "admitStudent" },
          params: {
            search: searchParams,
            // limit: rowsPerPage,
            // page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();

    setLoadingBtn(true);
    try {
      const getDownload = await get(
        `${urls.students.getStudentsListExcel}/${formData.schoolClass}/${formData.academicYear}`,
        {
          headers: { roleFunction: "admitStudent" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getDownload.data);
      const link = document.createElement("a");
      console.log(uri);
      link.href = uri;

      const academicsYear = formData.academicYear;
      const getExcelyearById = academicYears.find(
        (excel) => excel._id === academicsYear
      );
      const classename = formData.schoolClass;
      const getExcelById = classes.find((excel) => excel._id === classename);
      link.setAttribute(
        "download",
        `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  const handleDownloadAllStudents = async (e) => {
    e.preventDefault();

    setDownloadingAll(true);
    try {
      const getDownload = await get(
        `${urls.students.getAllStudentsListExcel}/${formData.academicYear}`,
        {
          headers: { roleFunction: "admitStudent" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getDownload.data);
      const link = document.createElement("a");
      console.log(uri);
      link.href = uri;

      const academicsYear = formData.academicYear;
      const getExcelyearById = academicYears.find(
        (excel) => excel._id === academicsYear
      );
      const classename = formData.schoolClass;
      link.setAttribute(
        "download",
        `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setDownloadingAll(false);
  };

  const handleStudentSearchSubmit = async (e) => {
    setLoader(true);
    if (
      !formData.academicYear ||
      !formData.schoolClass ||
      !formData.status ||
      !formData.section
    )
      return;

    e.preventDefault();
    try {
      if (formData.section === "all") {
        const searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            };
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "admitStudent" },
          params: {
            search: searchParams,
            // limit: rowsPerPage,
            // page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        let searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            };
        const searchStudent = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "admitStudent" },
          params: {
            search: searchParams,
            // limit: rowsPerPage,
            // page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleSubmitBulkAdmit = async (e) => {
    setValue(true);
    e.preventDefault();
    try {
      const formDataFile = new FormData();
      formDataFile.append("sheet", e.target.bulkAdmit.files[0]);
      if (formData.section !== "all")
        await post(
          `${urls.students.postBulkAdmit}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          formDataFile,
          { headers: { roleFunction: "admitStudent" } }
        );
    } catch (error) {
      console.log(error);
    }
    setValue(false);
  };

  const handleGetUpdateSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    try {
      if (formData.section !== "all") {
        const getSheet = await get(
          `${urls.students.getBulkUpdate}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          {
            headers: { roleFunction: "admitStudent" },
            responseType: "blob",
          }
        );
        const uri = URL.createObjectURL(getSheet.data);

        const link = document.createElement("a");
        link.href = uri;

        const academicsYear = formData.academicYear;
        const getExcelyearById = academicYears.find(
          (excel) => excel._id === academicsYear
        );
        const classename = formData.schoolClass;
        const getExcelById = classes.find((excel) => excel._id === classename);
        const sectionsname = formData.section;
        const getExcelsectionById = sections.find(
          (excel) => excel._id === sectionsname
        );

        link.setAttribute(
          "download",
          `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitBulkUpdate = async (e) => {
    setItem(true);
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("sheet", e.target.bulkUpdate.files[0]);
      await put(`${urls.students.putBulkUpdateStudent}`, "", formData, {
        headers: { roleFunction: "admitStudent" },
      });
    } catch (error) {
      console.log(error);
    }
    setItem(false);
  };
  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0].name);
  };

  const getSerialNumber = (index) => {
    return (
      schoolStudents[index]?.serialNumber || page * rowsPerPage + index + 1
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    if (page !== 0) {
      setPage(0);
    }
    setSearch(value.trim());
    if (value.trim() !== "") {
      schoolStudents.length > 0 &&
        setSearchFilter(
          schoolStudents.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <>
      <SectionSubHeader title="Students" style={{ margin: "15px" }} />
      <div style={{ margin: "15px" }}>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleStudentSearchSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Academic Year
                    </InputLabel>
                    <Select
                      label="Academic Year"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="schoolClass"
                      value={formData.schoolClass || ""}
                      onChange={handleClassChange}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Section
                    </InputLabel>
                    <Select
                      label="Section"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                    >
                      {formData.schoolClass ? (
                        <MenuItem value="all">All</MenuItem>
                      ) : null}
                      {sections.length
                        ? sections
                            .sort((a, b) =>
                              a.sectionName.localeCompare(b.sectionName)
                            )
                            .map((row, index) => (
                              <MenuItem
                                key={row._id}
                                value={row._id}
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {row.sectionName}
                              </MenuItem>
                            ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Status
                    </InputLabel>
                    <Select
                      label="Status"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="status"
                      value={formData.status || ""}
                      onChange={handleOnChange}
                    >
                      <MenuItem
                        value={"active"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        value={"inactive"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Inactive
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  // style={{ alignSelf: "center" }}
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                      md: "row",
                      lg: "row",
                    },
                    gap: 1,
                  }}
                >
                  {/* <Box> */}
                  {formData.schoolClass &&
                    formData.academicYear &&
                    formData.section !== "all" && (
                      <Stack
                        gap={1}
                        direction={{ xs: "column", sm: "row", md: "row" }}
                      >
                        <Tooltip title="Bulk Admit">
                          <Button
                            size="small"
                            variant="contained"
                            style={styles.Button}
                            onClick={() => setBulkAdmitModal(true)}
                          >
                            BULK ADMIT
                          </Button>
                        </Tooltip>
                        <Tooltip title="Bulk Update">
                          <Button
                            size="small"
                            variant="contained"
                            style={styles.Button}
                            onClick={() => setBulkUpdateModal(true)}
                          >
                            BULK UPDATE
                          </Button>
                        </Tooltip>
                      </Stack>
                    )}
                  {/* </Box> */}
                  <Stack columnGap={1} direction={"row"}>
                    <LoadingButton
                      loading={downloadingAll}
                      type="submit"
                      size="small"
                      variant="contained"
                      sx={{
                        background: "#1b3779",
                        ":hover": { background: "#1b3779" },
                      }}
                      onClick={handleDownloadAllStudents}
                    >
                      Download All Students
                    </LoadingButton>
                    <LoadingButton
                      loading={loader}
                      type="submit"
                      size="small"
                      variant="contained"
                      sx={{
                        background: "#1b3779",
                        ":hover": { background: "#1b3779" },
                      }}
                      onClick={handlepaginationSearch}
                    >
                      SEARCH
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              {/* <Box>
                {formData.schoolClass &&
                  formData.academicYear &&
                  formData.section !== "all" && (
                    <Stack gap={1} direction={{ sm: "column", md: "row" }}>
                      <Tooltip title="Bulk Admit">
                        <Button
                          size="small"
                          variant="contained"
                          style={styles.Button}
                          onClick={() => setBulkAdmitModal(true)}
                        >
                          BULK ADMIT
                        </Button>
                      </Tooltip>
                      <Tooltip title="Bulk Update">
                        <LoadingButton
                          loading={loader}
                          size="small"
                          variant="contained"
                          style={styles.Button}
                          onClick={() => setBulkUpdateModal(true)}
                        >
                          BULK UPDATE
                        </LoadingButton>
                      </Tooltip>
                    </Stack>
                  )}
              </Box> */}
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Stack direction="row">
                  <Tooltip title="Download">
                    <LoadingButton
                      onClick={handleGetDownloadSheet}
                      style={styles.icon}
                      loading={loadingBtn}
                    >
                      <DownloadForOfflineSharpIcon />
                    </LoadingButton>
                  </Tooltip>
                  <Tooltip title="Print">
                    <LoadingButton
                      loading={print}
                      style={styles.icon}
                      onClick={handleGetPrintPdf}
                    >
                      <PrintSharp />
                    </LoadingButton>
                  </Tooltip>
                </Stack>

                <Link to={"/student-bulk-photo"}>
                  <Button size="small" sx={{ p: 1, ml: 1 }} variant="contained">
                    Bulk Photo
                  </Button>
                </Link>

                {/* <TextField
                  sx={{ ml: 3 }}
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  placeholder="Search By Student Name"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
              </Box>
            </Box>
          </Box>
        </Card>

        <Grid
          container
          spacing={1}
          justifyContent={{
            xs: "center",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
          }}
          mb={2}
        >
          <Grid xs={12} sm={6} md={4} lg={3}>
            <TextField
              // sx={{ ml: 3 }}
              size="small"
              fullWidth
              value={search}
              onChange={handleSearch}
              label="Search Student Name"
              placeholder="Search..."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              style={{
                background: "rgb(27 55 121)",
                position: "relative",
                botton: "30px",
              }}
            >
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">Section Order</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Photo</span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortByName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortByStatus} className="class-table-header">
                    Status
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortBySection} className="class-table-header">
                    Section
                    <span style={{ cursor: "pointer" }}>
                      {sort.section === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortByRoll} className="class-table-header">
                    Roll No.
                    <span style={{ cursor: "pointer" }}>
                      {sort.rollNo === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span
                    onClick={sortByAdmissionNo}
                    className="class-table-header"
                  >
                    Admission No.
                    <span style={{ cursor: "pointer" }}>
                      {sort.admissionNo === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {getSerialNumber(index)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Avatar src={objHas(row, "studentPhoto.link", "")} />
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                          key={row.id}
                        >
                          {row.basicInfo.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.section
                            ? row.academicInfo.section.sectionName
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.rollNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.admissionNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <ShowComponent
                              roleFunction="admitStudent"
                              action="update"
                            >
                              <Tooltip title="Update" disableInteractive>
                                <IconButton
                                  size="small"
                                  onClick={() => handleEdit(row._id)}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="admitStudent"
                              action="delete"
                            >
                              <Tooltip title="Delete" disableInteractive>
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                : schoolStudents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {getSerialNumber(index)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          <Avatar src={objHas(row, "studentPhoto.link", "")} />
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                          key={row.id}
                        >
                          {row.basicInfo.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.section
                            ? row.academicInfo.section.sectionName
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.rollNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.admissionNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <ShowComponent
                              roleFunction="admitStudent"
                              action="update"
                            >
                              <Tooltip title="Update" disableInteractive>
                                <IconButton
                                  size="small"
                                  onClick={() => handleEdit(row._id)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="admitStudent"
                              action="delete"
                            >
                              <Tooltip title="Delete" disableInteractive>
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!schoolStudents.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        {/* {!schoolStudents.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )} */}
        {/* {showPagination ? ( */}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : schoolStudents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
        {/* ) : null} */}

        <Dialog
          fullScreen={fullScreen}
          open={bulkAdmitModal}
          onClose={() => setBulkAdmitModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: { xs: "100%", sm: 350, md: 350, lg: 350 },
              },
            },
          }}
        >
          <Box sx={modal}>
            <form onSubmit={handleSubmitBulkAdmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    Bulk Admit
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    // loading={loadingBtn}
                    // style={styles.Button}
                    onClick={handleGetAdmitSheet}
                    sx={{
                      backgroundColor: "#1b3779",
                      ":hover": { backgroundColor: "#1b3779" },
                      width: "100%",
                    }}
                    endIcon={<DownloadIcon />}
                  >
                    Sample
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <Tooltip title="Upload">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                        width: "100%",
                      }}
                      component="label"
                    >
                      upload
                      <input
                        name="bulkAdmit"
                        onChange={handleFileChange}
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                  </Tooltip>

                  <div style={{ fontSize: "15px", marginTop: 1 }}>
                    {fileChoosen && fileChoosen}
                  </div> */}
                  <Typography> Select file:</Typography>
                  <input
                    type="file"
                    name="bulkAdmit"
                    accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                    onChange={handleFileChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <LoadingButton
                    loading={value}
                    variant="contained"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                    type="submit"
                  >
                    submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={bulkUpdateModal}
          onClose={() => setBulkUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: { xs: "100%", sm: 350, md: 350, lg: 350 },
              },
            },
          }}
        >
          <Box sx={modal}>
            <form onSubmit={handleSubmitBulkUpdate}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    Bulk Update
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Tooltip title="Get Student Data">
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      style={{ padding: "6px" }}
                      // loading={loadingBtn}
                      onClick={handleGetUpdateSheet}
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                        width: "100%",
                      }}
                      endIcon={<DownloadIcon />}
                    >
                      Download
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <Tooltip title="Upload">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      component="label"
                    >
                      upload
                      <input
                        name="bulkUpdate"
                        onChange={handleFileChange}
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                  </Tooltip>

                  {fileChoosen && (
                    <div
                      style={{
                        fontSize: "15px",
                        marginTop: 1,
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {fileChoosen}
                    </div>
                  )} */}
                  <Typography> Select file:</Typography>
                  <input
                    name="bulkUpdate"
                    onChange={handleFileChange}
                    accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                    type="file"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={item}
                      variant="contained"
                      sx={{
                        background: "#1b3779",
                        ":hover": { background: "#1b3779" },
                      }}
                      type="submit"
                    >
                      submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>

        <ShowComponent roleFunction="admitStudent" action="add">
          <div className="add-btn">
            <Tooltip title="Admit Student" disableInteractive>
              <Link to="/addstudent">
                <Fab
                  size="medium"
                  style={{ background: "#1b3779", color: "#fff" }}
                  aria-label="add"
                >
                  <Add />
                </Fab>
              </Link>
            </Tooltip>
          </div>
        </ShowComponent>
      </div>
    </>
  );
};

export default StudentTable;
