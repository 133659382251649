import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  TablePagination,
  Autocomplete,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Search, Settings } from "@mui/icons-material";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useLocation } from "react-router-dom";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Sell = () => {
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = useState(0);
  const { selectedSetting } = useContext(SettingContext);
  const [fromType, setFromType] = useState("School");
  const [toType, setToType] = useState("EMPLOYEE");

  const [from, setSettingOrVendor] = useState("");
  const [to, setAdditionalDropdownValue] = useState("");
  const [roles, setRoles] = useState([]);
  const [schoolEmployee, setSchoolEmployee] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [departments, setDepartment] = useState([]);

  const [student, setStudent] = useState([]);
  const [formData, setFormData] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const [inventoryItem, setInventoryItem] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const { state: localState } = useLocation();
  const [addForm, setAddForm] = useState({});
  const [dataSet, setDataSet] = useState({});
  const [editIssue, setEditIssue] = useState();
  const [settings, setSettings] = useState([]);
  const [issue, setIssue] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);

  const [sort, setSort] = useState({
    itemId: "asc",
    itemName: "asc",
    total: "asc",
    quantity: "asc",
    issuedBy: "asc",
  });

  const sortByItemId = () => {
    if (sort.itemId == "asc") {
      const sortedList = [
        ...issue.sort((a, b) => b.item.itemId.localeCompare(a.item.itemId)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, itemId: "des" });
    } else {
      const sortedList = [
        ...issue.sort((a, b) => a.item.itemId.localeCompare(b.item.itemId)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, itemId: "asc" });
    }
  };

  const sortByItemName = () => {
    if (sort.itemName == "asc") {
      const sortedList = [
        ...issue.sort((a, b) => b.item.name.localeCompare(a.item.name)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, itemName: "des" });
    } else {
      const sortedList = [
        ...issue.sort((a, b) => a.item.name.localeCompare(b.item.name)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, itemName: "asc" });
    }
  };

  const sortByTotal = () => {
    if (sort.total == "asc") {
      const sortedList = [...issue.sort((a, b) => b.total - a.total)];
      setIssue(sortedList);
      setSort({ ...sort, total: "des" });
    } else {
      const sortedList = [...issue.sort((a, b) => a.total - b.total)];
      setIssue(sortedList);
      setSort({ ...sort, total: "asc" });
    }
  };

  const sortByIssuedBy = () => {
    if (sort.issuedBy == "asc") {
      const sortedList = [
        ...issue.sort((a, b) =>
          b.issuedTo.basicInfo.empName.localeCompare(
            a.issuedTo.basicInfo.empName
          )
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, issuedBy: "des" });
    } else {
      const sortedList = [
        ...issue.sort((a, b) =>
          a.issuedTo.basicInfo.empName.localeCompare(
            b.issuedTo.basicInfo.empName
          )
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, issuedBy: "asc" });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  useEffect(() => {
    if (localState) {
      if (localState == "sold") {
        setTabValue(1);
      }
    }
  }, [localState]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [issueFrom, setIssueFrom] = useState({
    fromSchool: "",
    vendorId: "",
  });

  const [issueTo, setIssueTo] = useState({
    toSchoolId: "",
    vendorId: "",
    studentId: "",
    employeeId: "",
  });

  const [classes, setClasses] = useState([]);

  const handleToTypeChange = (event) => {
    const selectedToType = event.target.value;
    setToType(selectedToType);
    setAdditionalDropdownValue("");
  };

  const handleFromTypeChange = (event) => {
    const selectedFromType = event.target.value;
    setFromType(selectedFromType);
    setSettingOrVendor("");
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole, {
          headers: { roleFunction: "sold" },
        });
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, []);

  useEffect(() => {
    const schoolEmployeeGet = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "sold" },
          params: {
            limit: 10,
            page: 1,
          },
        });

        setSchoolEmployee(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const schoolEmployeeGetAll = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "sold" },
        });
        setAllEmployees(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllDepartment = async () => {
      try {
        const departments = await get(urls.department.getDepartment, {
          headers: { roleFunction: "sold" },
        });
        setDepartment(departments.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDepartment();
    schoolEmployeeGet();
    schoolEmployeeGetAll();
  }, [selectedSetting]);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "sold" },
        });
        setFormData((prev) => ({
          ...prev,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "sold" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "sold" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getsearchStudents = async () => {
      try {
        if (!formData.className || !formData.section) return;
        const response = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "sold" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.className,
              "academicInfo.section": formData.section,
            },
          },
        });
        setStudent(response.data.data);
      } catch (error) {}
    };
    getsearchStudents();
  }, [formData.className, formData.section]);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "sold" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.inventory.getAllItem}`, {
          headers: { roleFunction: "sold" },
        });
        setInventoryItem(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.vendor.getAll}`, {
          headers: { roleFunction: "sold" },
        });
        setVendorData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  let [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.employee.getAllEmployee}`, {
          headers: { roleFunction: "sold" },
        });
        setEmployeeData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.settings.getAllSettings}`, {
          headers: { roleFunction: "sold" },
        });
        setSettings(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.employee.getAllEmployee}`, {
          headers: { roleFunction: "sold" },
        });
        setStudentData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  useEffect(() => {
    fetchData();
  }, [selectedSetting._id]);

  const fetchData = async () => {
    try {
      const response = await get(`${urls.inventorySell.getAll}`, {
        headers: { roleFunction: "sold" },
      });
      const responseData = response.data.data;
      setIssue(responseData);
      console.log(responseData, "data");
    } catch (error) {
      console.log(error);
    }
  };

  const handleIssueFrom = async (e) => {
    const { name, value } = e.target;
    setIssueFrom({
      ...issueFrom,
      [name]: value,
    });
  };

  const handleIssueTo = async (e) => {
    const { name, value } = e.target;
    setIssueTo({
      ...issueTo,
      [name]: value,
    });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setDataSet({
      ...dataSet,
      [name]: value,
    });
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };

  const handleClose = () => {
    setOpen(false);
    setShowEdit(false);
    setEditIssue(null);
    setDataSet({});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setShowEdit(false);
      setEditIssue(null);
      setDataSet({});
    }
    setTabValue(newValue);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      issue.length > 0 &&
        setSearchFilter(
          issue.filter((ele) =>
            ele.item.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleEdit = (id, value) => {
    console.log(value, "valssue");
    setOpen(true);
    setEditIssue(id);
    setTabValue(2);
    setShowEdit(true);

    setDataSet({
      item: value.item.name,
      quantity: value.quantity,
      pricePerItem: value.pricePerItem,
      fromSchool: value.fromSchool.schoolName,
      issuedTo: value.toMeetUser,
      employeeId: value.issuedTo.basicInfo.empName,
      studentId: value.issuedTo.basicInfo.name,
      status: value.status,
      notes: value.notes,
    });
    setToType(value.issuedToRoleName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (!editIssue) {
      try {
        const data = await post(
          `${urls.inventorySell.create}`,
          {
            item: dataSet.item,
            quantity: dataSet.quantity,
            pricePerItem: dataSet.pricePerItem,
            fromSchool: dataSet.fromSchool,
            issuedTo: dataSet.toMeetUser,
            employeeId: dataSet.toMeetUser,
            studentId: dataSet.toMeetUser,
            status: dataSet.status,
            notes: dataSet.notes,
            issuedToRoleName: toType,
          },
          { headers: { roleFunction: "sold" } }
        );

        if (data.success) {
          fetchData();
          setTabValue(0);
          handleClose();
        }
      } catch (error) {
        console.log("Error creating data:", error);
      }
    } else {
      try {
        const data = await put(
          `${urls.inventorySell.update}${editIssue}`,
          "",
          {
            item: dataSet.item,
            quantity: dataSet.quantity,
            pricePerItem: dataSet.pricePerItem,
            fromSchool: dataSet.fromSchool,
            issuedTo: dataSet.toMeetUser,
            employeeId: dataSet.toMeetUser,
            studentId: dataSet.toMeetUser,
            status: dataSet.status,
            notes: dataSet.notes,
            issuedToRoleName: toType,
          },
          { headers: { roleFunction: "sold" } }
        );
        if (data.success) {
          fetchData();
          setShowEdit(false);
          setTabValue(0);
          handleClose();
        }
      } catch (error) {
        console.log("Error updating data:", error);
      }
    }
    setLoader(false);
  };

  return (
    <div>
      <SectionSubHeader title="Sell Details" style={{ margin: "15px" }} />
      <div style={{ margin: "15px" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "15px 0",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Sold List" {...a11yProps(0)} />
            <Tab label="Sell" {...a11yProps(1)} />
            {showEdit && <Tab label="Edit Sold" {...a11yProps(2)} />}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {/* <Card style={{ padding: "10px", margin: "10px 0px" }}>
            <div style={styles.cardButton}>
              <Button size="small" variant="contained">
                Excel
              </Button>

              <Button size="small" variant="contained">
                Pdf
              </Button>
            </div>
          </Card> */}

          <Grid container justifyContent="space-between">
            <Box style={styles.cardButton}>
              <Button size="small" variant="contained">
                Excel
              </Button>

              <Button size="small" variant="contained">
                Pdf
              </Button>
            </Box>
            <Grid xs="auto">
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search Item Name"
                placeholder="Search Item Name"
                variant="outlined"
                sx={{ mb: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span onClick={sortByItemId} className="class-table-header">
                      Item Id
                      <span style={{ cursor: "pointer" }}>
                        {sort.itemId === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByItemName}
                      className="class-table-header"
                    >
                      Item Name
                      <span style={{ cursor: "pointer" }}>
                        {sort.itemName === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByIssuedBy}
                      className="class-table-header"
                    >
                      Sellar Name
                      <span style={{ cursor: "pointer" }}>
                        {sort.issuedBy === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>

                  <TableCell sx={{ color: "white" }} align="center">
                    <span onClick={sortByTotal} className="class-table-header">
                      Total Quantity
                      <span style={{ cursor: "pointer" }}>
                        {sort.total === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter
                      .slice(
                        search ? searchPage * searchRowsPerPage : 0,
                        search
                          ? (searchPage + 1) * searchRowsPerPage
                          : rowsPerPage
                      )
                      .map((data, index) => {
                        return (
                          <TableRow>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {data.item.itemId}
                            </TableCell>

                            <TableCell align="center">
                              {data.item.name}
                            </TableCell>
                            <TableCell align="center">
                              {data.issuedBy.basicInfo.empName}
                            </TableCell>
                            <TableCell align="center">{data.total}</TableCell>
                            <TableCell align="center">
                              <div className="edit-delete">
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() => handleEdit(data._id, data)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : issue
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => {
                        return (
                          <TableRow>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {data.item.itemId}
                            </TableCell>

                            <TableCell align="center">
                              {data.item.name}
                            </TableCell>
                            <TableCell align="center">
                              {data.issuedBy.basicInfo.empName}
                            </TableCell>
                            <TableCell align="center">{data.total}</TableCell>
                            <TableCell align="center">
                              <div className="edit-delete">
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() => handleEdit(data._id, data)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
              </TableBody>
            </Table>
            {!issue.length && !search.trim() && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {search.trim() && !searchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={search ? searchFilter.length : issue.length}
            rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
            page={search ? searchPage : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start,",
              alignItems: "center",
              my: 1,
            }}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <form onSubmit={handleSubmit}>
            <Paper sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                  >
                    <InputLabel>Item </InputLabel>
                    <Select
                      name="item"
                      label="item"
                      fullWidth
                      size="small"
                      value={dataSet?.item}
                      onChange={handleChange}
                    >
                      {inventoryItem.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    label="Quantity"
                    name="quantity"
                    value={dataSet?.quantity}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                  >
                    <InputLabel>Sell Status</InputLabel>
                    <Select
                      name="status"
                      size="small"
                      label="Sell Satus"
                      fullWidth
                      onChange={handleChange}
                      value={dataSet.status}
                    >
                      <MenuItem value={"Pending"}>Pending</MenuItem>
                      <MenuItem value={"Sold"}>Sold</MenuItem>
                      <MenuItem value={"Returned"}>Returned</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl variant="outlined" required fullWidth>
                    <InputLabel>From Type</InputLabel>
                    <Select
                      size="small"
                      label="From Type"
                      value={fromType}
                      name="fromType"
                      onChange={handleFromTypeChange}
                    >
                      <MenuItem value={"School"}>School</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                  >
                    <InputLabel>School</InputLabel>
                    <Select
                      size="small"
                      label="School"
                      name="fromSchool"
                      value={dataSet.fromSchool}
                      onChange={handleChange}
                    >
                      {settings.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.schoolName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl variant="outlined" required fullWidth>
                    <InputLabel>To Type</InputLabel>
                    <Select
                      name="to"
                      size="small"
                      label="To Type"
                      value={toType}
                      onChange={handleToTypeChange}
                    >
                      <MenuItem value={"EMPLOYEE"}>Employee</MenuItem>
                      <MenuItem value={"STUDENT"}>Student</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {toType === "EMPLOYEE" ? (
                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                    >
                      <InputLabel>Employee Name</InputLabel>
                      <Select
                        size="small"
                        fullWidth
                        label="Employee name"
                        value={dataSet.toMeetUser}
                        onChange={handleChange}
                        name="toMeetUser"
                      >
                        {schoolEmployee.map((emp) => (
                          <MenuItem key={emp._id} value={emp._id}>
                            {emp.basicInfo.empName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                      >
                        <InputLabel>Class</InputLabel>
                        <Select
                          size="small"
                          name="className"
                          label="Class"
                          value={formData.className}
                          onChange={handleClassChange}
                        >
                          {classes &&
                            classes.map((row, index) => (
                              <MenuItem key={row._id} value={row._id}>
                                {row.className}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {formData.className && (
                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                        >
                          <InputLabel> Section</InputLabel>
                          <Select
                            name="section"
                            label="Section"
                            value={formData.section || ""}
                            onChange={handleOnChange}
                          >
                            {sections.map((row, index) => (
                              <MenuItem key={row._id} value={row._id}>
                                {row.sectionName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {formData.className && (
                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          variant="outlined"
                          required
                          size="small"
                          fullWidth
                        >
                          {/* <InputLabel>Student Name</InputLabel>
                          <Select
                            fullWidth
                            size="small"
                            name="toMeetUser"
                            label="Student Name"
                            value={dataSet.toMeetUser || ""}
                            onChange={handleChange}
                          >
                            {student.map((row, index) => (
                              <MenuItem key={row._id} value={row._id}>
                                {row.basicInfo.name}
                              </MenuItem>
                            ))}
                          </Select> */}
                          <Autocomplete
                            options={student}
                            getOptionLabel={(option) => option.basicInfo?.name}
                            value={
                              student.find(
                                (std) => std._id === dataSet.toMeetUser
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              handleChange({
                                target: {
                                  name: "toMeetUser",
                                  value: newValue ? newValue._id : "",
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Student Name"
                                variant="outlined"
                                size="small"
                                placeholder="Search student with name"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </>
                )}

                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataSet.notes}
                    onChange={handleChange}
                    maxRows={4}
                    name="description"
                    style={{
                      width: "100%",

                      height: 100,
                      padding: "10px",
                    }}
                    aria-label="maximum height"
                    placeholder="Drop a note"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                    mb: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    type="button"
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Sell
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </TabPanel>

        {showEdit && (
          <TabPanel value={tabValue} index={2}>
            <form onSubmit={handleSubmit}>
              <Paper sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                    >
                      <InputLabel>Item</InputLabel>
                      <Select
                        label="item"
                        name="item"
                        size="small"
                        fullWidth
                        value={dataSet.item}
                        onChange={handleChange}
                      >
                        {inventoryItem.map((data) => (
                          <MenuItem key={data.name} value={data.name}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      size="small"
                      required
                      variant="outlined"
                      type="number"
                      label="Quantity"
                      name="quantity"
                      value={dataSet.quantity}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                    >
                      <InputLabel>Sellar Status</InputLabel>
                      <Select
                        name="status"
                        size="small"
                        label="Seller Status"
                        onChange={handleChange}
                        value={dataSet.status}
                      >
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"Sold"}>Sold</MenuItem>
                        <MenuItem value={"Returned"}>Returned</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                    >
                      <InputLabel>From Type</InputLabel>
                      <Select
                        value={fromType}
                        size="small"
                        label="From Type"
                        fullWidth
                        name="fromType"
                        onChange={handleFromTypeChange}
                      >
                        <MenuItem value={"School"}>School</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                    >
                      <InputLabel>School</InputLabel>
                      <Select
                        name="fromSchool"
                        size="small"
                        fullWidth
                        label="School"
                        value={dataSet.fromSchool || ""}
                        onChange={handleChange}
                      >
                        {settings.map((data) => (
                          <MenuItem
                            key={data.schoolName}
                            value={data.schoolName}
                          >
                            {data.schoolName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                    >
                      <InputLabel>To Type</InputLabel>
                      <Select
                        name="to"
                        size="small"
                        label="To Type"
                        fullWidth
                        value={toType}
                        onChange={handleToTypeChange}
                      >
                        <MenuItem value={"EMPLOYEE"}>Employee</MenuItem>
                        <MenuItem value={"STUDENT"}>Student</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {toType === "EMPLOYEE" ? (
                    <Grid item xs={12} md={4} lg={3}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                      >
                        <InputLabel>Employee Name</InputLabel>
                        <Select
                          value={dataSet.employeeId}
                          size="small"
                          label="Employee Name"
                          onChange={handleChange}
                          name="employeeId"
                        >
                          {schoolEmployee.map((emp) => (
                            <MenuItem
                              key={emp.basicInfo.empName}
                              value={emp.basicInfo.empName}
                            >
                              {emp.basicInfo.empName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                        >
                          <InputLabel>Class</InputLabel>
                          <Select
                            name="className"
                            label="Class"
                            size="small"
                            fullWidth
                            value={formData.className}
                            onChange={handleClassChange}
                          >
                            {classes &&
                              classes.map((row, index) => (
                                <MenuItem key={row._id} value={row._id}>
                                  {row.className}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {formData.className && (
                        <Grid item xs={12} md={4} lg={4}>
                          <FormControl
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                          >
                            <InputLabel> Section</InputLabel>
                            <Select
                              name="section"
                              label="Section"
                              value={formData.section || ""}
                              onChange={handleOnChange}
                            >
                              {sections.map((row, index) => (
                                <MenuItem key={row._id} value={row._id}>
                                  {row.sectionName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {formData.className && (
                        <Grid item xs={12} md={4} lg={4}>
                          <FormControl
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                          >
                            {/* <InputLabel>Student Name</InputLabel>
                            <Select
                              name="studentId"
                              label="Student Name"
                              value={dataSet.studentId || ""}
                              onChange={handleChange}
                            >
                              {student.map((row, index) => (
                                <MenuItem
                                  key={row.basicInfo.name}
                                  value={row.basicInfo.name}
                                >
                                  {row.basicInfo.name}
                                </MenuItem>
                              ))}
                            </Select> */}
                            <Autocomplete
                              fullWidth
                              size="small"
                              options={student}
                              value={
                                student.find(
                                  (s) => s.basicInfo.name === dataSet.studentId
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  handleChange({
                                    target: {
                                      name: "studentId",
                                      value: newValue.basicInfo.name,
                                    },
                                  });
                                } else {
                                  handleChange({
                                    target: { name: "studentId", value: "" },
                                  });
                                }
                              }}
                              getOptionLabel={(option) => option.basicInfo.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Student Name"
                                  variant="outlined"
                                  size="small"
                                  placeholder="Search student with name"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </>
                  )}

                  <Grid item xs={12} md={12} lg={12}>
                    <TextareaAutosize
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxRows={4}
                      name="description"
                      style={{
                        width: "100%",

                        height: 100,
                        padding: "10px",
                      }}
                      aria-label="maximum height"
                      placeholder="Drop a note"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      type="button"
                      onClick={() => {
                        setTabValue(0);
                        setShowEdit(false);
                      }}
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Update
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </TabPanel>
        )}
      </div>
    </div>
  );
};

export default Sell;
