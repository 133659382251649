import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  listItemSecondaryActionClasses,
  styled,
} from "@mui/material";
import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";

import moment from "moment";
const TableData = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  textAlign: "center",
}));

const HeadWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  margin: "15px 0 25px 0",
}));

const Heading = styled(Typography)(({}) => ({
  fontWeight: "bold",
}));

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  marginBottom: "10px",
}));

const getDateWithTime = (dateTimeString = "27/09/2024 14:30") => {
  const dateTimeComponents = dateTimeString.split(" ");

  // Extract date components
  const dateComponents = dateTimeComponents[0].split("/");
  const yearExtracted = parseInt(dateComponents[2], 10);
  const monthExtracted = parseInt(dateComponents[1], 10) - 1; // Months are zero-based
  const dayExtracted = parseInt(dateComponents[0], 10);

  // Extract time components
  const timeComponents = dateTimeComponents[1].split(":");
  const hoursExtracted = parseInt(timeComponents[0], 10);
  const minutesExtracted = parseInt(timeComponents[1], 10);

  // Create a new Date object using the extracted components
  return new Date(
    yearExtracted,
    monthExtracted,
    dayExtracted,
    hoursExtracted,
    minutesExtracted
  );
};

const getStatus = (startDate, startTime, expiryDate, expiryTime) => {
  let currentDate = Date.now();
  let startDataTimestring = `${moment(startDate).format(
    "DD/MM/YYYY"
  )} ${startTime}}`;
  let expiryDateTimeString = `${moment(expiryDate).format(
    "DD/MM/YYYY"
  )} ${expiryTime}`;
  let currentDateTimeString = `${moment(currentDate).format(
    "DD/MM/YYYY"
  )} ${moment(currentDate).format("LT")}`;

  let meetingDate = getDateWithTime(startDataTimestring);
  let meetingExpiryDate = getDateWithTime(expiryDateTimeString);
  let currentDateAndTime = getDateWithTime(currentDateTimeString);

  if (meetingDate > currentDateAndTime) {
    return "Upcoming";
  } else if (
    meetingDate < currentDateAndTime &&
    meetingExpiryDate > currentDateAndTime
  ) {
    return "Available";
  } else {
    return "Expired";
  }
};

const StudentLive = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [list, setList] = useState([]);
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState("All");
  const [page, setPage] = React.useState(0);
  const [openJoin, setOpenJoin] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleCloseJoin = () => {
    setOpenJoin(false);
  };

  const getList = async () => {
    try {
      const { data, status } = await get(`${urls.meeting.getStudentList}`, {
        headers: { roleFunction: "live" },
      });
      setList(data?.data);
      setFilteredMeetings(data?.data);
      console.log(data, "studentlist");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, [selectedSetting]);

  const handleSelectedMeetingType = (e) => {
    setSelectedMeeting(e.target.value);
  };

  useEffect(() => {
    console.log(selectedMeeting, "===fun");
    if (selectedMeeting) {
      if (selectedMeeting === "All") {
        setFilteredMeetings(list);
      } else {
        setFilteredMeetings(
          list.filter((c) => c.meetingType === selectedMeeting)
        );
      }
    }
  }, [selectedMeeting]);

  const handleJoinClick = (meeting) => {
    // navigate(`/room/${meeting.roomId}`, {
    //   state: { meetingType: meeting.meetingType },
    // });
    setOpenJoin(true);
  };
  return (
    <Box>
      <HeadWrapper>
        <Heading variant="h5" sx={{ fontSize: "18px" }}>
          All Upcoming Live sessions
        </Heading>

        <FormControl fullWidth sx={{ maxWidth: "300px" }}>
          <Box sx={{ display: "flex" }}>
            <FilterListIcon fontSize="small" />
            <Label id="demo-simple-select-label">Filter by meeting type</Label>
          </Box>
          <Select
            labelId="filter-live-sessions-by-course"
            id="filter-live-sessions-by-course"
            label="Courses"
            value={selectedMeeting}
            onChange={handleSelectedMeetingType}
            placeholder="Filter by meeting"
            input={
              <OutlinedInput
                // label="Category"
                sx={{
                  textAlign: "center",
                  height: "35px",
                }}
              />
            }
          >
            <MenuItem value="All"> All </MenuItem>
            <MenuItem value="OneONoneCall">One-On-One Call </MenuItem>
            <MenuItem value="GroupCall">Group Call </MenuItem>
            <MenuItem value="LiveStreaming">Live Stream </MenuItem>
          </Select>
        </FormControl>
      </HeadWrapper>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableData>S.No</TableData>
              <TableData>Meeting Type</TableData>
              <TableData>Meeting Date</TableData>
              <TableData>Status</TableData>
              <TableData>Action</TableData>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMeetings
              ? filteredMeetings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {index + 1}.
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row.meetingType}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {`${moment(row.startDate).format("DD/MM/YYYY")} - ${
                          row.startTime
                        }`}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Box
                          sx={{
                            py: 1,
                            objectFit: "contain",
                            borderRadius: "5px",
                            background:
                              getStatus(
                                row.startDate,
                                row.startTime,
                                row.expiryDate,
                                row.expiryTime
                              ) === "Expired"
                                ? "#85848430"
                                : "#8281e74d",
                          }}
                        >
                          {getStatus(
                            row.startDate,
                            row.startTime,
                            row.expiryDate,
                            row.expiryTime
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          size="small"
                          onClick={() => handleJoinClick(row)}
                          variant="contained"
                        >
                          Join
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              : list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {index + 1}.
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row.meetingType}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {`${moment(row.startDate).format("DD/MM/YYYY")} - ${
                          row.startTime
                        }`}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Box
                          sx={{
                            py: 1,
                            objectFit: "contain",
                            borderRadius: "5px",
                            background:
                              getStatus(
                                row.startDate,
                                row.startTime,
                                row.expiryDate,
                                row.expiryTime
                              ) === "Expired"
                                ? "#85848430"
                                : "#8281e74d",
                          }}
                        >
                          {getStatus(
                            row.startDate,
                            row.startTime,
                            row.expiryDate,
                            row.expiryTime
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          size="small"
                          onClick={() => handleJoinClick(row)}
                          variant="contained"
                        >
                          Join
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
        {!list.length === 0 && (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "center",
              margin: "10px 0",
              py: 2,
            }}
          >
            Live sessions Not found!
          </Typography>
        )}
        {!filteredMeetings.length && (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "center",
              margin: "10px 0",
              py: 2,
            }}
          >
            Live sessions Not found!
          </Typography>
        )}
      </TableContainer>

      <Dialog
        open={openJoin}
        onClose={handleCloseJoin}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontWeight={600}>
            To enable this feature please contact admin!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJoin}>Ok</Button>
          {/* <Button onClick={(e) => handleOpenJoin(e)} autoFocus>
            Ok
          </Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StudentLive;
