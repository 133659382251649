import React, { useContext, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  TextField,
  Typography,
  styled,
  InputAdornment,
  IconButton,
  TablePagination,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import AddDepartment from "../HumanResource/AddDepartment";
import DeleteModal from "../Academic/DeleteModal";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import ShowComponent from "../../ShowComponent";
import Wrapper from "../../Wrapper";

const SearchField = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down(900)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(685)]: {
    justifyContent: "flex-start",
  },
}));

const Department = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [schoolDepartment, setSchoolDepartment] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateDepartment, setUpdateDepartment] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSeaarch] = useState("");
  const [sort, setSort] = useState({ department: "asc" });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };

  let [loader, setLoader] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateDepartment(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        urls.department.delDepartment,
        id,
        {},
        { roleFunction: "department" }
      );
      if (res.data.success) {
        const filteredSection = schoolDepartment.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolDepartment(filteredSection);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await post(urls.department.postDepartment, data, {
        headers: { roleFunction: "department" },
      });
      if (res.data.success) {
        setSchoolDepartment([...schoolDepartment, res.data.data]);
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.department.putDepartment, id, data, {
        headers: { roleFunction: "department" },
      });
      if (response.data.success) {
        setSchoolDepartment(
          schoolDepartment.map((e) => {
            if (e._id === response.data.data._id) {
              return response.data.data;
            } else {
              return e;
            }
          })
        );
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getDepartmentById = schoolDepartment.find(
        (item) => item._id === id
      );
      setUpdateDepartment(getDepartmentById);
    } catch (error) {
      console.log(error);
    }
  };
  const getSchoolDepartment = async () => {
    try {
      const res = await get(urls.department.getDepartment, {
        headers: { roleFunction: "department" },
      });
      setSchoolDepartment([...res.data.data]);
      setSearchFilter([...res.data.data]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSchoolDepartment();
  }, []);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSeaarch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolDepartment.filter((ele) =>
          ele.department.toLowerCase().includes(value.toLowerCase().trim())
        )
      );
    } else {
      setSearchFilter([...schoolDepartment]);
    }
  };

  const departmentSorted = () => {
    if (search) {
      if (sort.department === "des") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.department.localeCompare(b.department)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, department: "asc" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.department.localeCompare(a.department)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, department: "des" });
      }
    } else {
      if (sort.department === "des") {
        let sortedList = [
          ...schoolDepartment.sort((a, b) =>
            a.department.localeCompare(b.department)
          ),
        ];
        setSchoolDepartment(sortedList);
        setSort({ ...sort, department: "asc" });
      } else {
        let sortedList = [
          ...schoolDepartment.sort((a, b) =>
            b.department.localeCompare(a.department)
          ),
        ];
        setSchoolDepartment(sortedList);
        setSort({ ...sort, department: "des" });
      }
    }
  };

  return (
    <Wrapper>
      <Card style={{ padding: "22px 0px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <Typography
              style={{
                color: "red",

                display: "inline-block",
              }}
            >
              Department :
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <SearchField container spacing={1} my={2}>
        <Grid item xs="auto">
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            label="Search"
            variant="outlined"
            placeholder="Seacrh By Department"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </SearchField>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">#SL</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">
                  Department
                  <span
                    onClick={departmentSorted}
                    style={{ cursor: "pointer" }}
                  >
                    {sort.department === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Note</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter
                  .slice(
                    searchPage * searchRowsPerPage,
                    searchPage * searchRowsPerPage + searchRowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.department}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.note}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <ShowComponent
                              roleFunction="department"
                              action="update"
                            >
                              <Tooltip title="Update" disableInteractive>
                                <IconButton
                                  size="small"
                                  style={{ color: "#1b3779" }}
                                  onClick={() => handleEdit(row._id)}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="department"
                              action="delete"
                            >
                              <Tooltip title="Delete" disableInteractive>
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              : schoolDepartment
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.department}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.note}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <ShowComponent
                              roleFunction="department"
                              action="update"
                            >
                              <Tooltip title="Update" disableInteractive>
                                <IconButton
                                  size="small"
                                  style={{ color: "#1b3779" }}
                                  onClick={() => handleEdit(row._id)}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="department"
                              action="delete"
                            >
                              <Tooltip title="Delete" disableInteractive>
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
          </TableBody>
        </Table>
        {!schoolDepartment.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={search ? searchFilter.length : schoolDepartment.length}
        rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
        page={search ? searchPage : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ShowComponent roleFunction="department" action="add">
        <div className="add-icon">
          <AddDepartment
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateDepartment={updateDepartment}
          />
        </div>
      </ShowComponent>
    </Wrapper>
  );
};

export default Department;
