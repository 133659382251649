import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  InputLabel,
  Typography,
  Tooltip,
  FormLabel,
  TableContainer,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Dialog,
} from "@mui/material";
import dayjs from "dayjs";
import SectionsSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import { get, post, put, del } from "../../../services/apis";
import { Search } from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import * as Yup from "yup";

// icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import { useFormik } from "formik";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const FormikError = styled(Typography)(() => ({
  color: "#d32f2f",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  fontSize: "0.75rem",
  marginTop: "50px",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExperienceLetter = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [loadingBtn, setLoadingBtn] = useState("");
  const [loadingEdit, setLoadingEdit] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [dates, setDates] = useState();
  const [list, setList] = useState([]);
  const [editId, setEditId] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [modalData, setModalData] = useState({
    open: false,
    containt: "",
    action: () => {},
  });

  const [notes, setNotes] = useState(`
                  <p>To: John</p>
                  <p>Rajajingar, Banglore, Karnataka</p>
                  <p>From: Excellent English Medium Primary & High School Ittangihal road, Vijaypur, Karnataka 586103</p>
                  <p>Dear Sir/Madam,</p>
                  <p>Subject: APPOINTMENT FOR THE POSITION OF A PHYSICS TEACHER</p>
                  <p>Following your application for a position of a physics teacher on Jan 17, 2020, to Excellent English Medium Primary & High School and subsequent interviews on Jan 20, 2020, I am glad to inform you that you passed the interview.</p>
                  <p>Effective June 01, 2020, you have been appointed as a Physics teacher with an annual starting salary of Rs.25,000/-. Other allowances and benefits admissible in your pay scale are outlined in the attached letter. You are kindly requested to respond to this letter within 10 days on whether you accept this offer.</p>
                  <p>This appointment is permanent and all terminal benefits, including gratuity, are attached.</p>
                  <p>Should you accept this offer, your probation will start immediately and will take 3 months. Your services can be terminated during your probation without any notice, based on your conduct. Also, the probation period may be extended.</p>
                  <p>Find attached other terms of your employment, your duties and responsibility, and our rules of engagement.</p>
                  <p>Welcome to Excellent School. Congratulations on your appointment!</p>`);

  //   =========ADD Experience Letter HANDLER ==========
  const onHandleFormik = useFormik({
    initialValues: {
      name: "",
      joiningDate: "",
      experienceLetter: `
      <p>AARON JEFFREY</p>
      <p>Rajajingar, Banglore, Karnataka</p>
      <p>From: Excellent English Medium Primary & High School Ittangihal road, Vijaypur, Karnataka 586103</p>
      <p>Contact: +91 876xxxxxxxxx</p>
      <p>E Mail: ajxxxxx@gmail.com</p>
      <p>Date: DD, MM, YY</p>
      <br/>
      <p>Dear Concerned ,</p>
      <br/>
      <p>I hereby certify that Aaron was employed as Senior Executive - CS with our company zzzzzz, during the period starting from MM, YY to MM, YY.</p>
      <br/>
      <p>Aaron started working with zzzzzz company as a trainee and with sincere efforts & excellent performance was moved to the position of Executive – CS and was officially employed as a full time employee. For 4 years of working for us, he demonstrated as a diligent and truthful person. His interpersonal skills are outstanding and he has been very helpful and has been highly appraised by his Managers.</p>
      <br/>     
     <p> We wish him success in his future efforts. </p>
     <br/>
     <p>Regards</p>     
     <p>Managment Team</p>`,
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Employee Name"),
      joiningDate: Yup.string().required().label("Joining Date"),
      experienceLetter: Yup.string().required().label("experience Letter"),
    }),
    onSubmit: async (values) => {
      setAddLoading(true);
      try {
        const body = {
          name: values.name,
          joiningDate: values.joiningDate,
          experienceLetter: values.experienceLetter,
        };

        const res = await post(`${urls.Experienceletter.create}`, body, {
          headers: { roleFunction: "experienceletter" },
        });
        onHandleFormik.resetForm();

        setTabValue(0);
        getList();
      } catch (error) {
        setAddLoading(false);
        console.error(error);
      }
      setAddLoading(false);
    },
    enableReinitialize: true,
    validateOnBlur: false,
  });

  // Table pagination ====================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==========================

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClickOpen = (id, element) => {
    setModalData({
      ...modalData,
      open: true,
      containt: element.experienceLetter,
      action: () => handleGetDownloadSheet(id),
    });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setEditId(null);
    setName("");
    setDates();
    setNotes("");
  };

  // get experience data
  const getList = async () => {
    try {
      const res = await get(`${urls.Experienceletter.list}`, {
        headers: { roleFunction: "experienceletter" },
      });
      setList(res.data.data);
      setSearchFilter(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  // delete
  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.Experienceletter.delete}`,
        id,
        {},
        { roleFunction: "experienceletter" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
        getList();
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // update
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
    try {
      if (editId) {
        const res = await put(
          `${urls.Experienceletter.update}/${editId}`,
          "",
          {
            name: name,
            joiningDate: dates,
            experienceLetter: notes,
          },
          { headers: { roleFunction: "experienceletter" } }
        );
        getList();
        setTabValue(0);
        setEditId(null);
        setName("");
        setDates();
        setNotes("");
      }
      // else {
      //   const res = await post(
      //     `${urls.Experienceletter.create}`,
      //     {
      //       name: name,
      //       joiningDate: dates,
      //       experienceLetter: notes,
      //     },
      //     { headers: { roleFunction: "experienceletter" } }
      //   );
      //   getList();
      //   setTabValue(0);
      //   setEditId(null);
      //   setName("");
      //   setDates();
      //   setNotes("");
      // }
    } catch (error) {
      console.log(error);
      setLoadingEdit(false);
      setTabValue(0);
    }
    setLoadingEdit(false);
  };

  // download
  const handleGetDownloadSheet = async (id) => {
    setLoadingBtn(id);
    try {
      const getDownload = await get(
        `${selectedSetting._id}/experienceletter/downloadpdf/${id}`,
        { headers: { roleFunction: "experienceletter" }, responseType: "blob" }
      );
      const uri = URL.createObjectURL(getDownload.data);

      const link = document.createElement("a");
      link.href = uri;
      link.target = "_blank"; // Open in a new tab
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link); // Remove the link element
      setLoadingBtn("");
    } catch (error) {
      console.log(error);
    }
  };

  // react quill
  const handleQuillChange = (value) => {
    setNotes(value);
  };

  const handleEdit = (id, data) => {
    setEditId(id);
    setName(data.name);
    setDates(data.joiningDate);
    setNotes(data.experienceLetter);
    setTabValue(2);
    console.log(data, "data");
  };

  // search employee
  const handleSearchName = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() != "") {
      setSearchFilter(
        list.filter((item) =>
          item.name.toLowerCase().includes(value.trim().toLowerCase())
        )
      );
    } else {
      setSearchFilter([...list]);
    }
  };
  return (
    <Wrapper>
      <SectionsSubHeader title="Experience Letter" />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "15px 0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Experience Letter" {...a11yProps(0)} />
          <Tab label="Add Experience Letter" {...a11yProps(1)} />
          {editId && <Tab label="Edit Experience Letter" {...a11yProps(2)} />}
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <TextField
            size="small"
            value={search}
            label="Search Employee"
            placeholder="Search..."
            variant="outlined"
            onChange={handleSearchName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }} align="center">
                  #sl
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  School
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Created
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {data.setting.schoolName}
                          </TableCell>
                          <TableCell align="center">{data.name}</TableCell>
                          <TableCell align="center">
                            {dayjs(data.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="View">
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleClickOpen(data._id, data)}
                              >
                                <VisibilityIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </IconButton>
                            </Tooltip>
                            <ShowComponent
                              roleFunction="experienceletter"
                              action="update"
                            >
                              <Tooltip title="Update">
                                <IconButton
                                  style={{ color: "#1b3779" }}
                                  size="small"
                                  onClick={(e) => handleEdit(data._id, data)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="experienceletter"
                              action="delete"
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(data._id)}
                                >
                                  <DeleteIcon fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDeleteone}
                              id={data._id}
                              setDeleteModal={setDeleteModal}
                            />
                            {/* <Tooltip>
                              <LoadingButton
                                size="small"
                                loading={loadingBtn === data._id}
                                onClick={() => handleGetDownloadSheet(data._id)}
                                style={{ color: "rgb(27 55 121)" }}
                              >
                                <FileDownloadIcon fontSize="small" />
                              </LoadingButton>
                            </Tooltip> */}
                          </TableCell>
                        </TableRow>
                      );
                    })
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {data.setting.schoolName}
                          </TableCell>
                          <TableCell align="center">{data.name}</TableCell>
                          <TableCell align="center">
                            {dayjs(data.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="View">
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleClickOpen(data._id, data)}
                              >
                                <VisibilityIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </IconButton>
                            </Tooltip>
                            <ShowComponent
                              roleFunction="experienceletter"
                              action="update"
                            >
                              <Tooltip title="Update">
                                <IconButton
                                  style={{ color: "#1b3779" }}
                                  size="small"
                                  onClick={(e) => handleEdit(data._id, data)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="experienceletter"
                              action="delete"
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(data._id)}
                                >
                                  <DeleteIcon fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDeleteone}
                              id={data._id}
                              setDeleteModal={setDeleteModal}
                            />
                            {/* <Tooltip>
                              <LoadingButton
                                size="small"
                                loading={loadingBtn === data._id}
                                onClick={() => handleGetDownloadSheet(data._id)}
                                style={{ color: "rgb(27 55 121)" }}
                              >
                                <FileDownloadIcon fontSize="small" />
                              </LoadingButton>
                            </Tooltip> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {searchFilter.length === 0 || list.length === 0 ? (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          ) : (
            ""
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <form onSubmit={onHandleFormik.handleSubmit}>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} lg={4}>
              <FormLabel
                sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
              >
                Employee Name*:
              </FormLabel>

              <FormControl fullWidth required>
                <TextField
                  size="small"
                  variant="outlined"
                  name="name"
                  // value={name}
                  // onChange={(e) => setName(e.target.value)}
                  value={onHandleFormik.values.name}
                  onChange={onHandleFormik.handleChange}
                  onBlur={onHandleFormik.handleBlur}
                  error={
                    onHandleFormik.touched.name && onHandleFormik.errors.name
                      ? true
                      : false
                  }
                  helperText={
                    onHandleFormik.touched.name
                      ? onHandleFormik.errors.name
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <FormLabel
                sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
              >
                Joining Date*:
              </FormLabel>

              <FormControl fullWidth required>
                <TextField
                  type="date"
                  // label="date"
                  size="small"
                  name="joiningDate"
                  // value={dayjs(dates).format("YYYY-MM-DD")}
                  // onChange={(e) => setDates(e.target.value)}
                  variant="outlined"
                  value={onHandleFormik.values.joiningDate}
                  onChange={onHandleFormik.handleChange}
                  onBlur={onHandleFormik.handleBlur}
                  error={
                    onHandleFormik.touched.joiningDate &&
                    onHandleFormik.errors.joiningDate
                      ? true
                      : false
                  }
                  helperText={
                    onHandleFormik.touched.joiningDate
                      ? onHandleFormik.errors.joiningDate
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <FormLabel
                sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
              >
                Experience Letter*:
              </FormLabel>
              <ReactQuill
                theme="snow"
                contentType="html"
                style={{
                  height: "210px",
                }}
                // value={notes}
                // onChange={handleQuillChange}

                value={onHandleFormik.values.experienceLetter}
                onChange={(v) =>
                  onHandleFormik.setFieldValue("experienceLetter", v)
                }
                onBlur={onHandleFormik.handleBlur}
                error={
                  onHandleFormik.touched.experienceLetter &&
                  onHandleFormik.errors.experienceLetter
                    ? true
                    : false
                }
              />
              <FormikError variant="body">
                {onHandleFormik.touched.experienceLetter
                  ? onHandleFormik.errors.experienceLetter
                  : ""}
              </FormikError>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
              mt: 2,
            }}
          >
            <Button variant="contained" size="small" color="error">
              Cancel
            </Button>
            <ShowComponent roleFunction="experienceletter" action="add">
              <LoadingButton
                loading={addLoading}
                size="small"
                variant="contained"
                type="submit"
                sx={{
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
              >
                ADD
              </LoadingButton>
            </ShowComponent>
          </Box>
        </form>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <form>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} lg={4}>
              <FormLabel
                sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
              >
                Employee Name:{" "}
              </FormLabel>

              <FormControl fullWidth required>
                <TextField
                  size="small"
                  variant="outlined"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <FormLabel
                sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
              >
                Joining Date:
              </FormLabel>

              <FormControl fullWidth required>
                <TextField
                  type="date"
                  // label="date"
                  size="small"
                  name="dates"
                  value={dayjs(dates).format("YYYY-MM-DD")}
                  onChange={(e) => setDates(e.target.value)}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <FormLabel
                sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
              >
                Experience Letter:
              </FormLabel>

              <ReactQuill
                theme="snow"
                contentType="html"
                style={{
                  height: "210px",
                }}
                value={notes}
                onChange={handleQuillChange}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
              mt: 7,
            }}
          >
            <Button size="small" variant="contained" color="error">
              Cancel
            </Button>
            <LoadingButton
              loading={loadingEdit}
              size="small"
              variant="contained"
              type="submit"
              sx={{
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleSubmit}
            >
              Update
            </LoadingButton>
          </Box>
        </form>
      </TabPanel>

      {/* =============view offer-letter========== */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalData.open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Experience Letter
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent ref={componentRef}>
          <Typography gutterBottom>
            <div dangerouslySetInnerHTML={{ __html: modalData.containt }}></div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <ReactToPrint
            trigger={() => (
              <LoadingButton
                variant="contained"
                size="small"
                loading={loadingBtn}
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                }}
                onClick={handlePrint}
                startIcon={<PrintIcon />}
              >
                Print
              </LoadingButton>
            )}
            content={() => componentRef.current}
          />
        </DialogActions>
      </BootstrapDialog>
    </Wrapper>
  );
};

export default ExperienceLetter;
