import React, { useContext } from "react";
import {
  Grid,
  Card,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Button,
  Autocomplete,
} from "@mui/material";
import { useState, useEffect } from "react";
import { post, get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";

const styles = {
  card1: {
    padding: 10,

    // margin: "70px 200px",
    // margin: "10% 20%",
    margin: "15px 0px",
  },
  card: {
    margin: { md: 15, sm: 0 },
    padding: 10,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
};

const ResetPasswordUser = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [formData, setFormData] = useState({});
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("");

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  let [loader, setLoader] = useState(false);
  let [existloader, setExistloader] = useState(false);

  useEffect(() => {
    setEmployees([]);
    setSelectEmployee("");
    setFormData({});
  }, [selectedSetting]);

  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole, {
          headers: { roleFunction: "resetUserPassword" },
        });
        setRoles(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, [selectedSetting]);

  const handleRoleChange = async (e) => {
    try {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        password: "",
      }));
      setEmployees([]);
      setSelectEmployee("");
      const getEmployees = await get(urls.employee.getAllEmployee, {
        headers: { roleFunction: "resetUserPassword" },
        params: {
          search: {
            role: value,
          },
        },
      });
      if (getEmployees.data.success) setEmployees(getEmployees.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleEmployeeAuto = (e, val) => {
    setSelectEmployee(val);
  };
  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleUserChangePassword = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      await post(
        `${urls.password.resetUserPassword}/${selectEmployee._id}`,
        {
          password: formData.password,
        },
        { headers: { roleFunction: "resetUserPassword" } }
      );
    } catch (error) {
      toast.error(error.message);
    }

    setFormData({});
    setSelectEmployee("");
    setLoader(false);
  };
  const handleSendPassword = async () => {
    setExistloader(true);
    try {
      await post(
        `${urls.sms.sendEmployeesCredentials}`,
        {
          employees: [selectEmployee._id],
        },
        { headers: { roleFunction: "resetUserPassword" } }
      );
    } catch (error) {
      toast.error(error.message);
    }

    setFormData({});
    setEmployees([]);
    setSelectEmployee("");
    setExistloader(false);
  };
  return (
    <Wrapper>
      <SectionSubHeader title="User Reset Password" />
      <Card style={styles.card1}>
        {/* <Box sx={{ flexGrow: 1 }}> */}
        <form onSubmit={handleUserChangePassword}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} lg={4}>
              <FormControl size="small" sx={styles.textfield} required>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  User Type
                </InputLabel>
                {/* <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="role"
                  label="User Type"
                  onChange={handleRoleChange}
                  value={formData.role || ""}>
                  {roles &&
                    roles.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}>
                        {row.roleName}
                      </MenuItem>
                    ))}
                </Select> */}
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="role"
                  label="User Type"
                  onChange={handleRoleChange}
                  value={formData.role || ""}
                >
                  {roles &&
                    roles.map((row, index) => {
                      if (row.roleName.toLowerCase() !== "student") {
                        return (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.roleName}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Autocomplete
                fullWidth
                onChange={handleEmployeeAuto}
                options={employees}
                value={selectEmployee}
                getOptionLabel={(option) =>
                  option
                    ? `${option.basicInfo.empName} || ${option.username}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Employees"
                    size="small"
                    placeholder="Search employee"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label=" Password"
                size="small"
                fullWidth
                required
                name="password"
                value={formData.password || ""}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "flex-end",
                lg: "flex-end",
              }}
            >
              <LoadingButton
                loading={loader}
                variant="contained"
                type="submit"
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                }}
              >
                Change password
              </LoadingButton>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "flex-end",
                lg: "flex-end",
              }}
            >
              {selectEmployee && (
                <LoadingButton
                  loading={existloader}
                  variant="contained"
                  onClick={handleSendPassword}
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                >
                  Send exsiting password to user
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </form>
        {/* </Box> */}
      </Card>
    </Wrapper>
  );
};

export default ResetPasswordUser;
