import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../Wrapper";
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import SectionsSubHeader from "../../../components/SectionSubHeader";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import appendStringToUrls from "../../../utils/appendString";
import SettingContext from "../../../context/SettingsContext";

const StorageStye = styled(Typography)(() => ({
  border: "1px solid lightGrey",
  fontWeight: "600",
  padding: 10,
  borderRadius: 5,
  textAlign: "center",
}));

const Storage = () => {
  const [storageDetails, setStorageDetails] = useState({
    totalStorage: 0,
    usedStorage: 0,
  });
  const { selectedSetting } = useContext(SettingContext);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const getStorageDetails = async () => {
    try {
      const { data } = await get(urls.fileStorage.getDetails, {
        headers: { roleFunction: "fileStorage" },
      });
      setStorageDetails(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStorageDetails();
  }, [selectedSetting._id]);
  return (
    <Wrapper>
      <SectionsSubHeader title="Storage" />
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper
          sx={{
            padding: 4,
            width: { xs: "100%", sm: "70%", md: "50%", lg: "40%" },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StorageStye>
                Total Storage:{" "}
                <span style={{ color: "#607d8b", fontWeight: "bold" }}>
                  {storageDetails.totalStorage}
                </span>{" "}
              </StorageStye>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StorageStye>
                Used Storage:{" "}
                <span style={{ color: "#607d8b", fontWeight: "bold" }}>
                  {storageDetails.usedStorage}
                </span>{" "}
              </StorageStye>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Wrapper>
  );
};

export default Storage;
