import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";

import {
  Box,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TableContainer,
  TablePagination,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Search } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { add } from "date-fns";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import { useTheme } from "@mui/material/styles";
import ShowComponent from "../ShowComponent";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const styles = {
  textfield: {
    marginTop: "25px",
    // width: "70%",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TableRows({ rows, tableRowRemove, onValUpdate, key }) {
  const theme = useTheme();
  return rows
    ? rows.map((rowData, index) => {
        const { name, position, allocated } = rowData;
        console.log("rowData:", rowData);

        const handleValueUpdate = (event) => {
          onValUpdate(index, event);
        };

        return (
          <Grid
            container
            spacing={1}
            // key={index}
            key={key}
            sx={{
              marginBottom: "1px",
              backgroundColor: theme.palette.grey[100],
              borderRadius: "5px",
              padding: "10px 5px 15px 5px",
              border: "1px solid lightgrey",
              marginTop: "5px",
            }}
          >
            <Grid item xs={5} md={5} lg={5}>
              <TextField
                size="small"
                fullWidth
                required
                type="text"
                value={name}
                onChange={handleValueUpdate}
                name="name"
                placeholder="Bed Name"
                style={{ fontSize: "12px", padding: "4px", marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={5} md={5} lg={5}>
              <TextField
                type="text"
                fullWidth
                required
                value={position}
                size="small"
                onChange={handleValueUpdate}
                name="position"
                placeholder="Bed Position"
                style={{ fontSize: "12px", padding: "4px", marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} sx={{ mt: 1, textAlign: "center" }}>
              <Typography color={allocated ? "green" : "red"}>
                {allocated ? "Allotted" : "Not Allotted"}
              </Typography>
            </Grid>
            {/* <Grid item xs={2} md={2} lg={2}>
              {allocated ? (
                <Typography sx={{ mt: 2 }} color={allocated ? "green" : "red"}>
                  Alloted
                </Typography>
              ) : (
                <Button
                  onClick={(e) => tableRowRemove(index, e)}
                  sx={{ mt: 2 }}>
                  <RemoveIcon color="error" fontSize="medium" />
                </Button>
              )}
            </Grid> */}
          </Grid>
        );
      })
    : "";
}

const ManageRoom = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [rows, setRows] = useState([]);
  const [addForm, setAddForm] = useState({
    number: 0,
    totalSeats: 0,
    beds: [],
    type: "",
    note: "",
    hostel: "",
  });
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editRoom, setEditRoom] = useState();
  const [list, setList] = React.useState([]);
  const [hostelName, setHostelName] = React.useState([]);
  const [roomType, setRoomType] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [roomLoader, setRoomLoader] = useState(false);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);

  console.log(addForm, "--addForm");

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const [sort, setSort] = useState({
    name: "asc",
    type: "asc",
    seat: "asc",
    room: "asc",
  });

  const sortByTitle = () => {
    if (sort.name === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.hostel.name.localeCompare(a.hostel.name)),
      ];
      setList(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.hostel.name.localeCompare(b.hostel.name)),
      ];
      setList(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByType = () => {
    if (sort.type === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.type.name.localeCompare(a.type.name)),
      ];
      setList(sortedList);
      setSort({ ...sort, type: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.type.name.localeCompare(b.type.name)),
      ];
      setList(sortedList);
      setSort({ ...sort, type: "asc" });
    }
  };

  const sortBySeat = () => {
    if (sort.seat === "asc") {
      const sortedList = [...list.sort((a, b) => b.totalSeats - a.totalSeats)];
      setList(sortedList);
      setSort({ ...sort, seat: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.totalSeats - b.totalSeats)];
      setList(sortedList);
      setSort({ ...sort, seat: "asc" });
    }
  };

  const sortByRoom = () => {
    if (sort.room === "asc") {
      const sortedList = [...list.sort((a, b) => b.number - a.number)];
      setList(sortedList);
      setSort({ ...sort, room: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.number - b.number)];
      setList(sortedList);
      setSort({ ...sort, room: "asc" });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };

  useEffect(() => {
    const initialBed = {
      name: "",
      position: "",
      allocated: false,
    };

    setAddForm((prevAddForm) => ({
      ...prevAddForm,
      beds: [initialBed],
    }));
  }, [selectedSetting]);

  const addRowTable = (e) => {
    e.preventDefault();
    const data = {
      name: "",
      position: "",
      allocated: false,
    };
    let initialBedList = [...addForm.beds, data];
    setAddForm({ ...addForm, beds: initialBedList });
    // console.log(initialBedList, "ininininininin");

    // setAddForm({ totalSeats: value });
    // const buttons = Array.from(
    //   { length: parseInt(value) },
    //   (_, index) => index + 1
    // );
    // setButtonArray(buttons);
  };

  const tableRowRemove = (index, e) => {
    e.preventDefault();
    // const dataRow = [...rows];
    // dataRow.splice(index, 1);
    let newbedList = addForm.beds.filter((item, i) => i !== index);
    setAddForm({ ...addForm, beds: newbedList });
    // setRows(dataRow);
  };

  const onValUpdate = (i, event) => {
    event.preventDefault();
    const { name, value } = event.target;

    let initialBed = [...addForm.beds];
    let newBeds = [];
    for (let b of initialBed) {
      if (initialBed.indexOf(b) == i) {
        let newItem = { ...b, [name]: value };
        newBeds.push(newItem);
      } else {
        newBeds.push(b);
      }
    }
    setAddForm({ ...addForm, beds: newBeds });

    // const { name, value } = event.target;
    // const data = [...rows];
    // data[i][name] = value;
    // setRows(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setAddForm({
      number: 0,
      totalSeats: 0,
      beds: [{ name: "", position: "", allocated: false }],
      type: "",
      note: "",
      hostel: "",
    });
    setRows([]);
    setOpen(false);
    setEditRoom(null);
  };

  const handleClickOpenDialog = (room) => {
    setShowDetails(room);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    // console.log(ele.number, "99999");
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter(
            (ele) =>
              ele.hostel.name
                .toLowerCase()
                .includes(value.toLowerCase().trim()) ||
              ele.type.name
                .toLowerCase()
                .includes(value.toLowerCase().trim()) ||
              ele.number.toString().includes(value.trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setRoomLoader(true);
    try {
      if (!editRoom) {
        const res = await post(
          `${urls.room.create}`,
          {
            number: addForm.number,
            type: addForm.type,
            hostel: addForm.hostel,
            beds: addForm.beds,
            note: addForm.note,
            totalSeats: addForm.totalSeats,
          },
          { headers: { roleFunction: "hostelroom" } }
        );
        if (res.data.success) {
          setAddForm({
            number: 0,
            totalSeats: 0,
            beds: [{ name: "", position: "", allocated: false }],
            type: "",
            note: "",
            hostel: "",
          });

          getRoom();
          handleClose();
          setRoomLoader(false);
        }
      } else {
        const res = await put(
          `${urls.room.editRoom}${editRoom}`,
          "",
          {
            number: addForm.number,
            type: addForm.type,
            hostel: addForm.hostel,
            beds: addForm.beds,
            note: addForm.note,
            totalSeats: addForm.totalSeats,
          },
          { headers: { roleFunction: "hostelroom" } }
        );
        if (res.data.success) {
          setAddForm({
            number: 0,
            totalSeats: 0,
            beds: [{ name: "", position: "", allocated: false }],
            type: "",
            note: "",
            hostel: "",
          });

          getRoom();
          handleClose();
          setRoomLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setRoomLoader(false);
    }
  };

  useEffect(() => {
    getRoom();
  }, [selectedSetting]);

  const getRoom = async () => {
    try {
      const rooms = await get(`${urls.room.getList}`, {
        headers: { roleFunction: "hostelroom" },
      });

      setList(rooms.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getHostelNames = async () => {
      try {
        const { data, status } = await get(`${urls.hostel.getList}`, {
          headers: { roleFunction: "hostelroom" },
        });
        if (status > 199 && status < 299) {
          setHostelName(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getHostelNames();
  }, [selectedSetting]);

  useEffect(() => {
    const getRoomTypes = async () => {
      try {
        const { data, status } = await get(`${urls.roomType.getList}`, {
          headers: { roleFunction: "hostelroom" },
          params: {
            search: {},
            page: 1,
            limit: 1000,
          },
        });

        if (status > 199 && status < 299) {
          setRoomType(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoomTypes();
  }, [selectedSetting]);

  const [buttonArray, setButtonArray] = useState([]);
  const handleButtonClick = (buttonValue) => {
    // Handle button click logic here
    console.log(`Button ${buttonValue} clicked`);
  };
  const handleAddForm = (event) => {
    const { name, value } = event.target;
    console.log(value, "---event");
    // addRowTable();
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    // const buttons = Array.from(
    //   { length: parseInt(value) },
    //   (_, index) => index + 1
    // );

    // const buttons = Array.from(
    //   { length: parseInt(value) },
    //   (_, index) => index + 1
    // );
    // setButtonArray(buttons);
  };

  const handleAddFormBeds = (event) => {
    const { name, value } = event.target;

    // setAddForm({ totalSeats: value });
    setAddForm((prev) => ({ ...prev, [name]: Math.ceil(value) }));

    const buttons = Array.from(
      { length: parseInt(value) },
      (_, index) => index + 1
    );
    setButtonArray(buttons);
  };

  const handleClassMapEdit = (id, data) => {
    console.log(data, "ooooooooooooooooo");
    setEditRoom(id);
    // console.log(data.beds, "bedddhvg88888");
    // let newRows = [];
    // for(let bed of data.beds) {
    //   let newBed = {
    //     bedname:bed.name,
    //     bedposition: bed.position,
    //     allocated :bed.allocated
    //   }
    //   newRows.push(newBed)
    // }
    // setRows(newRows)
    setAddForm({
      number: data.number,
      type: data.type._id,
      hostel: data.hostel._id,
      beds: data.beds || [],
      note: data.note,
      totalSeats: data.totalSeats,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.room.delRoom}`,
        id,
        {},
        { roleFunction: "hostelroom" }
      );

      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Hostel Room & Beds" />
      {/* <div style={{ margin: "20px 0" }}> */}
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { md: "right", xs: "center" },
        }}
      >
        <Grid item xs="auto" mb={2}>
          <TextField
            size="small"
            // style={styles.textfield}
            value={search}
            onChange={handleSearch}
            label="Search"
            variant="outlined"
            placeholder="Enter Name,Type,Seat,Room no."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByRoom} className="class-table-header">
                  Room No
                  <span style={{ cursor: "pointer" }}>
                    {sort.room === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByType} className="class-table-header">
                  Room Type
                  <span style={{ cursor: "pointer" }}>
                    {sort.type === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortBySeat} className="class-table-header">
                  Seat Total
                  <span style={{ cursor: "pointer" }}>
                    {sort.seat === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByTitle} className="class-table-header">
                  Hostel Name
                  <span style={{ cursor: "pointer" }}>
                    {sort.name === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter
                  .slice(
                    search ? searchPage * searchRowsPerPage : 0,
                    search ? (searchPage + 1) * searchRowsPerPage : rowsPerPage
                  )
                  .map((room, index) => {
                    return (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{room.number}</TableCell>
                        <TableCell align="center">{room.type.name}</TableCell>
                        <TableCell align="center">{room.totalSeats}</TableCell>
                        <TableCell align="center">{room.hostel.name}</TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="view">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => handleClickOpenDialog(room._id)}
                              >
                                <VisibilityIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </IconButton>
                            </Tooltip>

                            <ShowComponent
                              roleFunction="hostelroom"
                              action="update"
                            >
                              <Tooltip title="Update">
                                <IconButton
                                  size="small"
                                  style={{
                                    color: "#1b3779",
                                  }}
                                  onClick={() =>
                                    handleClassMapEdit(room._id, room)
                                  }
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="hostelroom"
                              action="delete"
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(room._id, room)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDeleteone}
                              id={room._id}
                              setDeleteModal={setDeleteModal}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
              : list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((room, index) => {
                    return (
                      <TableRow>
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">{room.number}</TableCell>
                        <TableCell align="center">{room.type.name}</TableCell>
                        <TableCell align="center">{room.totalSeats}</TableCell>
                        <TableCell align="center">{room.hostel.name}</TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="View">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => handleClickOpenDialog(room)}
                              >
                                <VisibilityIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </IconButton>
                            </Tooltip>
                            <ShowComponent
                              roleFunction="hostelroom"
                              action="update"
                            >
                              <Tooltip title="Update">
                                <IconButton
                                  size="small"
                                  style={{
                                    color: "#1b3779",
                                  }}
                                  onClick={() =>
                                    handleClassMapEdit(room._id, room)
                                  }
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="hostelroom"
                              action="delete"
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(room._id, room)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDeleteone}
                              id={room._id}
                              setDeleteModal={setDeleteModal}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={search ? searchFilter.length : list.length}
        rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
        page={search ? searchPage : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start,",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* ==Add Room And Bed======== */}
      <Box className="add-icon">
        <ShowComponent roleFunction="hostelroom" action="add">
          <Tooltip title="Add Hostel Room & Beds">
            <Fab
              variant="contained"
              sx={{
                color: "#ffff",

                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleClickOpen}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </ShowComponent>
      </Box>
      {/* ================== */}

      {/* == View Room Information ======== */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={dialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: "#1b3779", color: "white" }}
        >
          Room Information
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <table className="hostel" style={styles.tab}>
            <tbody>
              {showDetails ? (
                <div>
                  <tr>
                    <td>School Name</td>
                    <td>{selectedSetting.schoolName}</td>
                  </tr>
                  <tr>
                    <td>Room No</td>
                    <td>{showDetails.number}</td>
                  </tr>
                  <tr>
                    <td>Seat Total</td>
                    <td>{showDetails.totalSeats}</td>
                  </tr>
                  <tr>
                    <td>Hostel Name</td>
                    <td>{showDetails.hostel.name}</td>
                  </tr>
                  <tr>
                    <td>Bed</td>
                    <td>
                      <tr>
                        <th className="borderName">Bed Name</th>
                        <th className="borderPosition">Bed Position</th>
                        <th className="borderPosition">Bed Allocated</th>
                      </tr>
                      {showDetails.beds.map((bed, index) => (
                        <tr key={index}>
                          <td>{bed.name}</td>
                          <td>{bed.position}</td>
                          <td>{bed.allocated ? "Yes" : "No"}</td>
                        </tr>
                      ))}
                    </td>
                  </tr>

                  <tr>
                    <td>Note</td>
                    <td>{showDetails.note}</td>
                  </tr>
                  <tr>
                    <td>Created</td>
                    <td>
                      {" "}
                      {dayjs(showDetails.type.createdAt).format("DD-MM-YYYY")}
                    </td>
                  </tr>
                </div>
              ) : null}
            </tbody>
          </table>
        </DialogContent>
      </BootstrapDialog>
      {/* ======================= */}

      {/* == Add/Update Room & Bed ======== */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag">
          <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  {editRoom ? "Update Room & Bed" : "Add Room & Bed"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  required
                  label="Room No"
                  type="number"
                  name="number"
                  value={addForm.number || ""}
                  onChange={handleAddForm}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 0.5, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel required sx={{ fontSize: 12 }}>
                    Room Type
                  </InputLabel>
                  <Select
                    label="Room Type"
                    required
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="type"
                    value={addForm.type || ""}
                    onChange={handleAddForm}
                  >
                    {roomType.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* ===================================================== */}
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  name="totalSeats"
                  id="filled-required"
                  size="small"
                  type="number"
                  label="Total Beds"
                  value={addForm.totalSeats || ""}
                  onChange={handleAddFormBeds}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              {/* ============================================================ */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth size="small">
                  <InputLabel required sx={{ fontSize: 12 }}>
                    Hostel Name
                  </InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Hostel Name"
                    required
                    name="hostel"
                    onChange={handleAddForm}
                    value={addForm.hostel || ""}
                  >
                    {hostelName.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",

                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  <p>Bed Name</p>
                  <p>Bed Position</p>
                  <p>Is Alloted</p>
                </Box>

                {buttonArray.map(
                  (buttonValue) => (
                    console.log(buttonValue, "===buttonValue"),
                    (
                      <TableRows
                        key={buttonValue}
                        rows={addForm.beds}
                        // rows={buttonValue}
                        tableRowRemove={tableRowRemove}
                        onValUpdate={onValUpdate}
                        // onClick={() => handleButtonClick(buttonValue)}
                      />
                    )
                  )
                )}

                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: {
                      md: "left",
                      sm: "left",
                      xs: "left",
                    },
                  }}
                >
                  <Button
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                      color: "white",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onClick={addRowTable}
                  >
                    Add More
                  </Button>
                </Box> */}
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  maxRows={4}
                  label="Note"
                  name="note"
                  value={addForm.note || ""}
                  onChange={handleAddForm}
                  placeholder="Drop a note"
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    padding: 10,
                    height: 70,
                    borderRadius: "5px",
                    overflow: "auto",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: {
                      md: "right",
                      sm: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{ color: "#ffff" }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={roomLoader}
                    variant="contained"
                    size="small"
                    onClick={handleAddSubmit}
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    {editRoom ? "update" : "Add"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </Wrapper>
  );
};
export default ManageRoom;
