// import React, { useState, useEffect, useContext } from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import DownloadIcon from "@mui/icons-material/Download";
// import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
// import StudentRoutine from "../components/StudentRoutine/StudentRoutine";
// import Holidays from "../components/Holidays/Holidays";
// import StudentAttendence from "../components/StudentAttendence/StudentAttendence";
// import NoContent from "../components/NoContent";
// import css from "../styles/Dashboard/Dashboard.module.css";
// import Notice from "../components/Notice/Notice";
// import StudentNews from "../components/StudentNews";
// import { get } from "../services/apis";
// import dayjs from "dayjs";

// import { ListItemIcon, ListItemText, Tabs } from "@mui/material";
// import Tab from "@mui/material/Tab";
// import Avatar from "@mui/material/Avatar";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import MenuItem from "@mui/material/MenuItem";
// import Menu from "@mui/material/Menu";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import MoreIcon from "@mui/icons-material/MoreVert";
// import { Public } from "@mui/icons-material";
// import { Link, useNavigate } from "react-router-dom";
// import LogoutIcon from "@mui/icons-material/Logout";
// import { toast } from "react-toastify";

// import { urls as url } from "../services/urlConstant";
// import appendStringToUrls from "../utils/appendString";

// import SettingContext from "../context/SettingsContext";
// import Feedbacks from "../components/Layout/Teacher/Feedbacks";
// import MyCourses from "../components/MyCourses/MyCourses";
// import VideoPlayer from "../components/MyCourses/VideoPlayer";

// const Heading = styled(Typography)(() => ({
//   padding: 10,

//   textAlign: "center",

//   fontFamily: "cursive",

//   color: "#212121",
//   fontSize: "22px",
//   background: "-webkit-linear-gradient(#eee, #eeeeee)",
//   webKitBackgroundClip: " text",
//   webkitTextFillColor: " transparent",
//   fontWeight: 600,
// }));

// const Student = styled(Typography)(() => ({
//   fontSize: "15px",
//   // fontFamily: "cursive",
//   fontWeight: "bold",
//   // color: "#607d8b",
//   color: "#000",
//   textAlign: "center",
// }));

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));
// const MenuItems = styled(MenuItem)(() => ({
//   "&:hover": {
//     background: "#607d8b",
//     color: "#fff",
//     // margin: 1,
//   },
// }));
// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }
// const Container = styled(Box)(() => ({}));
// const TabHeading = styled(Tab)(() => ({
//   fontSize: "15px",
//   color: "#000",
//   fontWeight: 600,
//   fontFamily: "cursive",
// }));
// const StudentDetails = styled(Typography)(() => ({
//   fontSize: "15px",
//   fontFamily: "cursive",
//   fontWeight: "bold",
//   color: "#607d8b",
// }));
// export default function StudentDashbord() {
//   const [auth, setAuth] = React.useState(true);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [currentStudent, setCurrentStudent] = useState(null);

//   useEffect(() => {
//     let student = window.localStorage.getItem(
//       process.env.REACT_APP_CURRENT_USER
//     );
//     if (student) {
//       setCurrentStudent(JSON.parse(student));
//     }
//   }, [process.env.REACT_APP_CURRENT_USER]);

//   const handleChange = (event) => {
//     setAuth(event.target.checked);
//   };

//   const handleMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleLogout = () => {
//     setAnchorEl(null);
//     window.localStorage.removeItem(process.env.REACT_APP_TOKEN);
//     window.localStorage.removeItem(process.env.REACT_APP_USER_TYPE);
//     window.localStorage.removeItem(process.env.REACT_APP_ROLE);
//     window.localStorage.removeItem(process.env.REACT_APP_CURRENT_USER);
//     window.localStorage.removeItem(process.env.REACT_APP_PROFILE_ID);
//     navigate("/login");
//   };

//   const styles = {
//     dash: {
//       fontSize: "18px",
//       color: "forestgreen",
//     },
//   };
//   const [tabValue, setTabValue] = useState(0);
//   const navigate = useNavigate();
//   const [assignment, setAssignment] = useState();
//   const [invoice, setInvoice] = useState();
//   const [downloaded, setDownloaded] = useState();
//   const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
//   const isMenuOpen = Boolean(anchorEl);
//   const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

//   const handleProfileMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMobileMenuClose = () => {
//     setMobileMoreAnchorEl(null);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     handleMobileMenuClose();
//   };

//   const handleLogOut = () => {
//     navigate("/login");
//     toast.success("Logout Successful");
//     // setAnchorEl(null);
//     // handleMobileMenuClose();
//   };

//   const handleMobileMenuOpen = (event) => {
//     setMobileMoreAnchorEl(event.currentTarget);
//   };

//   const mobileMenuId = "primary-search-account-menu-mobile";
//   const renderMobileMenu = (
//     <Menu
//       anchorEl={mobileMoreAnchorEl}
//       anchorOrigin={{
//         vertical: "top",
//         horizontal: "right",
//       }}
//       id={mobileMenuId}
//       keepMounted
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "right",
//       }}
//       open={isMobileMenuOpen}
//       onClose={handleMobileMenuClose}
//       sx={{
//         top: "60px",
//       }}
//     >
//       <MenuItem onClick={handleMenuClose}>
//         <Link to="/profile">
//           <p style={{ color: "black" }}>Profile</p>
//         </Link>
//       </MenuItem>
//       {/* <MenuItem onClick={handleMenuClose}>
//         <Link to="/resetPassword">
//           <p style={{ color: "black" }}>Dashboard</p>
//         </Link>
//       </MenuItem> */}
//       <MenuItems onClick={handleMenuClose}>
//         <Link to="/resetPassword">
//           <p style={{ color: "black" }}>Reset Password</p>
//         </Link>
//       </MenuItems>
//       <MenuItems onClick={handleMenuClose}>
//         <ListItemText> Log Out</ListItemText>
//         <ListItemIcon>
//           <LogoutIcon fontSize="small" />
//         </ListItemIcon>
//       </MenuItems>
//     </Menu>
//   );
//   const { selectedSetting } = useContext(SettingContext);
//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
//   let photo = window.localStorage.getItem("empPhoto");
//   console.log(photo, "=====");
//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   useEffect(() => {
//     getAssignment();
//   }, [selectedSetting]);

//   const getAssignment = async () => {
//     try {
//       const response = await get(`${urls.studentDashboard.getAssignment}`);
//       if (response.data.data) {
//         setAssignment([...response.data.data]);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     const getInvoice = async () => {
//       try {
//         const response = await get(`${urls.studentDashboard.getInvoice}`);
//         if (response.data.data) {
//           setInvoice([...response.data.data]);
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getInvoice();
//   }, [selectedSetting]);

//   const handleGetPrintPdf = async (id) => {
//     console.log(id, "iiiiii");
//     try {
//       {
//         const getPrint = await get(
//           `${urls.studentDashboard.getInvoicepdf}/${id}`,
//           {
//             responseType: "blob",
//           }
//         );

//         const uri = URL.createObjectURL(getPrint.data);
//         window.open(uri, "__blank");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   let addNavigate = () => {
//     navigate("/videoContent");
//   };

//   if (!currentStudent) return null;

//   return (
//     <Container>
//       <Box sx={{ flexGrow: 1 }}>
//         <AppBar position="sticky">
//           <Toolbar>
//             <Typography
//               sx={{ mr: 2, display: { xs: "none", sm: "flex", md: "flex" } }}
//             >
//               Welcome to your dashboard
//             </Typography>

//             <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//               Kayaka Foundation School
//             </Typography>
//             {auth && (
//               <div>
//                 <IconButton
//                   size="large"
//                   aria-label="account of current user"
//                   aria-controls={mobileMenuId}
//                   aria-haspopup="true"
//                   onClick={handleProfileMenuOpen}
//                   color="inherit"
//                   sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
//                 >
//                   <AccountCircle />
//                 </IconButton>
//                 <Menu
//                   id="menu-appbar"
//                   anchorEl={anchorEl}
//                   anchorOrigin={{
//                     vertical: "top",
//                     horizontal: "right",
//                   }}
//                   keepMounted
//                   transformOrigin={{
//                     vertical: "top",
//                     horizontal: "right",
//                   }}
//                   open={Boolean(anchorEl)}
//                   onClose={handleClose}
//                   sx={{
//                     top: "55px",
//                   }}
//                 >
//                   <MenuItems onClick={handleClose}>Profile</MenuItems>
//                   <MenuItems onClick={handleClose}>Reset Password</MenuItems>
//                   <MenuItems onClick={handleLogout}>
//                     <ListItemText> Log Out</ListItemText>
//                     <ListItemIcon>
//                       <LogoutIcon fontSize="small" sx={{ color: "#fff" }} />
//                     </ListItemIcon>
//                   </MenuItems>
//                 </Menu>
//               </div>
//             )}
//             {/* <IconButton
//               size="large"
//               edge="end"
//               color="inherit"
//               aria-label="menu"
//               sx={{ mr: 2, display: { xs: "none", sm: "flex", md: "flex" } }}>
//               <Public />
//             </IconButton> */}
//             <Link style={{ color: "inherit", alignSelf: "center" }} to="/">
//               <IconButton
//                 size="large"
//                 edge="end"
//                 color="inherit"
//                 aria-label="menu"
//                 sx={{ mr: 2, display: { xs: "none", sm: "flex", md: "flex" } }}
//               >
//                 <Public />
//               </IconButton>
//             </Link>
//             <Box sx={{ display: { xs: "flex", sm: "none", md: "none" } }}>
//               <IconButton
//                 size="large"
//                 aria-label="show more"
//                 aria-controls={mobileMenuId}
//                 aria-haspopup="true"
//                 onClick={handleMobileMenuOpen}
//                 color="inherit"
//               >
//                 <MoreIcon />
//               </IconButton>
//             </Box>
//           </Toolbar>
//         </AppBar>
//         {renderMobileMenu}
//       </Box>
//       <Box m="0px 20px">
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} sm={12} md={4} lg={3}>
//             <Box
//               width="100%"
//               height="100%"
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               flexDirection="column"
//             >
//               <Box
//                 sx={{
//                   borderRadius: "50%",
//                   border: 1,
//                   height: 170,
//                   width: 170,
//                   textAlign: "center",
//                   borderColor: "divider",
//                 }}
//               >
//                 <img
//                   src={photo}
//                   height={170}
//                   width={170}
//                   style={{
//                     borderRadius: "50%",
//                   }}
//                   alt="Image...!"
//                 />
//               </Box>
//               <Box>
//                 <Student>{currentStudent.basicInfo.name}</Student>
//                 <Student>
//                   {currentStudent.academicInfo.class?.className} -{" "}
//                   {currentStudent.academicInfo.section?.sectionName}
//                 </Student>
//               </Box>
//             </Box>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             sm={12}
//             md={8}
//             lg={9}
//             display="flex"
//             flex={1}
//             // flexDirection="row"
//           >
//             <Paper sx={{ width: "100%" }}>
//               <Grid container>
//                 <Grid
//                   item
//                   xs={12}
//                   sm={6}
//                   md={6}
//                   lg={6}
//                   sx={{ borderRight: 1, borderColor: "divider" }}
//                 >
//                   <Heading>News</Heading>
//                   <Divider />
//                   <StudentNews />
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={6} lg={6}>
//                   <Heading>Notice</Heading>
//                   <Divider />
//                   <Notice />
//                 </Grid>
//               </Grid>
//             </Paper>
//           </Grid>
//         </Grid>
//       </Box>

//       <Paper sx={{ margin: "20px 20px" }}>
//         <Tabs
//           value={tabValue}
//           onChange={handleTabChange}
//           aria-label="basic tabs example"
//           variant="scrollable"
//           scrollButtons
//           allowScrollButtonsMobile
//         >
//           <Tab label="Routine" {...a11yProps(0)} />
//           {/* <Tab label="Notice" {...a11yProps(1)} /> */}
//           <Tab label="Invoice" {...a11yProps(1)} />
//           <Tab label="Attendence" {...a11yProps(2)} />
//           <Tab label="Assignment" {...a11yProps(3)} />
//           <Tab label="Feedback" {...a11yProps(4)} />
//           <Tab label="My Courses" {...a11yProps(5)} />
//           <Tab label="Video Player" {...a11yProps(6)} />
//         </Tabs>
//       </Paper>
//       <Box sx={{ margin: "0px 20px" }}>
//         <TabPanel value={tabValue} index={0}>
//           <Paper>
//             <Heading>Routine</Heading>
//             <Divider />
//           </Paper>
//           <StudentRoutine />
//         </TabPanel>

//         {/* <TabPanel value={tabValue} index={1}>
//           <Paper>
//             <Heading>Notice</Heading>
//             <Divider />
//             <Notice />
//           </Paper>
//         </TabPanel> */}

//         <TabPanel value={tabValue} index={1}>
//           <Paper>
//             <Heading>Invoice</Heading>
//             <Divider />
//             <Box sx={{ textAlign: "center", mt: 2 }}>
//               <CardContent sx={{ display: "flex" }}>
//                 {invoice && invoice.length > 0 ? (
//                   invoice.map((row) => (
//                     <div
//                       style={{
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div>
//                         <Typography
//                           sx={{ fontSize: 15, textAlign: "start" }}
//                           color="text.secondary"
//                         >
//                           <b style={{ color: "#E1AD01" }}>
//                             {" "}
//                             {row.receiptType.name}:
//                           </b>
//                           {row.totalAmountPaid}
//                         </Typography>
//                         <Typography
//                           sx={{ fontSize: 15, textAlign: "start" }}
//                           color="text.secondary"
//                         >
//                           <b style={{ color: "#E1AD01" }}> Date:</b>
//                           {dayjs(row.dueDate).format("DD-MM-YYYY")}
//                         </Typography>
//                       </div>
//                       <div
//                         style={{ margin: "auto" }}
//                         onClick={() => handleGetPrintPdf(row._id)}
//                       >
//                         <DownloadIcon
//                           sx={{ fontSize: 35, ml: 12, cursor: "pointer" }}
//                           color="success"
//                         />
//                       </div>
//                     </div>
//                   ))
//                 ) : (
//                   <p>no Invoices</p>
//                 )}
//               </CardContent>
//             </Box>
//           </Paper>
//         </TabPanel>

//         <TabPanel value={tabValue} index={2}>
//           <Paper>
//             <Heading>Attendence</Heading>
//             <Divider />
//             <StudentAttendence />
//           </Paper>
//         </TabPanel>

//         <TabPanel value={tabValue} index={3}>
//           <Paper>
//             <Heading>Assignment</Heading>
//             <Divider />
//             <div className={css.scrollable}>
//               {assignment && assignment.length > 0 ? (
//                 assignment.map((row) => (
//                   <div key={row.id}>
//                     <Card
//                       sx={{ textAlign: "center", mt: 2, width: "100%" }}
//                       className={css.card}
//                     >
//                       <CardContent>
//                         <Typography
//                           sx={{ fontSize: 15, textAlign: "start" }}
//                           color="text.secondary"
//                           gutterBottom
//                         >
//                           <b style={{ color: "#E1AD01" }}>Subject:</b>
//                           {row.subject.subjectName}
//                         </Typography>

//                         <Typography
//                           sx={{ fontSize: 15, textAlign: "start" }}
//                           color="text.secondary"
//                           gutterBottom
//                         >
//                           <b style={{ color: "#E1AD01" }}>Assignment:</b>
//                           {row.assignmentTitle}
//                         </Typography>
//                         <Typography
//                           sx={{ fontSize: 15, textAlign: "start" }}
//                           color="text.secondary"
//                           gutterBottom
//                         >
//                           <b style={{ color: "#E1AD01" }}> Due Date:</b>
//                           {dayjs(row.deadline).format("DD-MM-YYYY")}
//                         </Typography>
//                       </CardContent>
//                     </Card>
//                   </div>
//                 ))
//               ) : (
//                 <p>No assignments found.</p>
//               )}
//             </div>
//           </Paper>
//         </TabPanel>

//         <TabPanel value={tabValue} index={4}>
//           <Heading>Feedback</Heading>
//           <Divider />

//           <Feedbacks />
//         </TabPanel>
//         <TabPanel value={tabValue} index={5}>
//           <Heading>My Courses</Heading>
//           <Divider />
//           <MyCourses />
//         </TabPanel>
//         <TabPanel value={tabValue} index={6}>
//           <Heading>Video Content</Heading>
//           <Divider />
//           <Button
//             onClick={() => addNavigate()}
//             size="small"
//             variant="contained"
//             sx={{
//               display: "block",
//               margin: "auto",
//               textAlign: "center",
//               justifyContent: "center",
//               alignItems: "center",
//               padding: 1,
//               borderRadius: "20px",
//               mt: "15px",
//               color: "#ffff",
//               background: "rgb(27, 55, 121)",
//               ":hover": { background: "rgb(27, 55, 121)" },
//             }}
//           >
//             Watch Video
//           </Button>
//         </TabPanel>
//       </Box>
//       <Box sx={{ mb: 3 }} />
//     </Container>
//   );
// }

//==========previous code=====//

import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import StudentRoutine from "../components/StudentRoutine/StudentRoutine";
import Holidays from "../components/Holidays/Holidays";
import StudentAttendence from "../components/StudentAttendence/StudentAttendence";
import NoContent from "../components/NoContent";
import css from "../styles/Dashboard/Dashboard.module.css";
import Notice from "../components/Notice/Notice";
import StudentNews from "../components/StudentNews";
import { get } from "../services/apis";
import dayjs from "dayjs";

import { ListItemIcon, ListItemText, Stack, Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Public } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";

import { urls as url } from "../services/urlConstant";
import appendStringToUrls from "../utils/appendString";

import SettingContext from "../context/SettingsContext";
import Feedbacks from "../components/Layout/Teacher/Feedbacks";
import MyCourses from "../components/MyCourses/MyCourses";
import VideoPlayer from "../components/MyCourses/VideoPlayer";
import StudentResetPassword from "./StudentResetPassword";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PasswordIcon from "@mui/icons-material/Password";
import StudentLive from "../components/StudentAttendence/StudentLive";
import MyReceipts from "../components/Layout/MyReceipts";

const Heading = styled(Typography)(() => ({
  padding: 10,

  textAlign: "center",

  fontFamily: "cursive",

  color: "#212121",
  fontSize: "22px",
  background: "-webkit-linear-gradient(#eee, #eeeeee)",
  webKitBackgroundClip: " text",
  webkitTextFillColor: " transparent",
  fontWeight: 600,
}));

const Student = styled(Typography)(() => ({
  fontSize: "15px",
  // fontFamily: "cursive",
  fontWeight: "bold",
  // color: "#607d8b",
  color: "#000",
  textAlign: "center",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const MenuItems = styled(MenuItem)(() => ({
  "&:hover": {
    background: "#607d8b",
    color: "#fff",
    // margin: 1,
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Container = styled(Box)(() => ({}));
const TabHeading = styled(Tab)(() => ({
  fontSize: "15px",
  color: "#000",
  fontWeight: 600,
  fontFamily: "cursive",
}));
const StudentDetails = styled(Typography)(() => ({
  fontSize: "15px",
  fontFamily: "cursive",
  fontWeight: "bold",
  color: "#607d8b",
}));

export default function StudentDashbord() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentStudent, setCurrentStudent] = useState(null);

  useEffect(() => {
    let student = window.localStorage.getItem(
      process.env.REACT_APP_CURRENT_USER
    );
    if (student) {
      setCurrentStudent(JSON.parse(student));
    }
  }, [process.env.REACT_APP_CURRENT_USER]);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlekOpenModal = () => {
    setOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    window.localStorage.removeItem(process.env.REACT_APP_TOKEN);
    window.localStorage.removeItem(process.env.REACT_APP_USER_TYPE);
    window.localStorage.removeItem(process.env.REACT_APP_ROLE);
    window.localStorage.removeItem(process.env.REACT_APP_CURRENT_USER);
    window.localStorage.removeItem(process.env.REACT_APP_PROFILE_ID);
    navigate("/login");
  };

  const styles = {
    dash: {
      fontSize: "18px",
      color: "forestgreen",
    },
  };
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [assignment, setAssignment] = useState();

  const [invoice, setInvoice] = useState();
  const [downloaded, setDownloaded] = useState();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogOut = () => {
    navigate("/login");
    toast.success("Logout Successful");
    // setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  console.log(assignment, "assssssssssssssignmenttttttttttttttttttttttt");

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{
        top: "60px",
      }}>
      {/* <MenuItem onClick={handleMenuClose}>
        <Link to="/profile">
          <p style={{ color: "black" }}>Profile</p>
        </Link>
      </MenuItem> */}
      {/* <MenuItem onClick={handleMenuClose}>
        <Link to="/resetPassword">
          <p style={{ color: "black" }}>Dashboard</p>
        </Link>
      </MenuItem> */}
      <MenuItems onClick={handlekOpenModal}>
        <ListItemIcon>
          <PasswordIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Reset Password</ListItemText>
      </MenuItems>
      <MenuItems onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText> Log Out</ListItemText>
      </MenuItems>
    </Menu>
  );
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  let photo = window.localStorage.getItem("empPhoto");
  console.log(photo, "=====");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    getAssignment();
  }, [selectedSetting]);

  const getAssignment = async () => {
    try {
      const response = await get(`${urls.studentDashboard.getAssignment}`);
      if (response.data.data) {
        setAssignment([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getInvoice = async () => {
      try {
        const response = await get(`${urls.studentDashboard.getInvoice}`);
        console.log(response, "tftftfftftftftftftftftft");
        if (response.data.data) {
          setInvoice([...response.data.data]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getInvoice();
  }, [selectedSetting]);

  console.log(invoice, "invoicerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");

  const handleGetPrintPdf = async (id) => {
    console.log(id, "iiiiii");
    try {
      {
        const getPrint = await get(
          `${urls.studentDashboard.getInvoicepdf}/${id}`,
          {
            responseType: "blob",
          }
        );

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  let addNavigate = () => {
    navigate("/videoContent");
  };

  if (!currentStudent) return null;

  const handleDownloadAssignment = () => {
    try {
      if (assignment && assignment.length > 0) {
        const fileLink = assignment[0].file.link;
        window.open(fileLink, "_blank");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography
              sx={{ mr: 2, display: { xs: "none", sm: "flex", md: "flex" } }}>
              Welcome to your dashboard
            </Typography>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Kayaka Foundation School
            </Typography>
            {auth && (
              <div>
                <Button
                  size="large"
                  aria-label="account of current user"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}>
                  <Avatar />
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  sx={{ width: 227 }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem onClick={handlekOpenModal}>
                    <ListItemIcon>
                      <PasswordIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Reset Password</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText> Log Out</ListItemText>
                  </MenuItem>
                </Menu>
              </div>
            )}
            {/* <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, display: { xs: "none", sm: "flex", md: "flex" } }}>
              <Public />
            </IconButton> */}
            <Link style={{ color: "inherit", alignSelf: "center" }} to="/">
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, display: { xs: "none", sm: "flex", md: "flex" } }}>
                <Public />
              </IconButton>
            </Link>
            <Box sx={{ display: { xs: "flex", sm: "none", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </Box>
      <Box m="0px 20px">
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column">
              <Box
                sx={{
                  borderRadius: "50%",
                  border: 1,
                  height: 170,
                  width: 170,
                  textAlign: "center",
                  borderColor: "divider",
                }}>
                {currentStudent.studentPhoto ? (
                  <img
                    src={currentStudent.studentPhoto.link}
                    height={170}
                    width={170}
                    style={{
                      borderRadius: "50%",
                    }}
                    alt="Image...!"
                  />
                ) : (
                  "NA"
                )}
              </Box>
              <Box>
                <Student>{currentStudent.basicInfo.name}</Student>
                <Student>
                  {currentStudent.academicInfo.class?.className} -{" "}
                  {currentStudent.academicInfo.section?.sectionName}
                </Student>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} display="flex" flex={1}>
            <Paper sx={{ width: "100%" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ borderRight: 1, borderColor: "divider" }}>
                  <Heading>News</Heading>
                  <Divider />
                  <StudentNews />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Heading>Notice</Heading>
                  <Divider />
                  <Notice />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Paper sx={{ margin: "20px 20px" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Routine" {...a11yProps(0)} />
          {/* <Tab label="Notice" {...a11yProps(1)} /> */}
          <Tab label="Invoice" {...a11yProps(1)} />
          <Tab label="My Receipts" {...a11yProps(2)}>
            {" "}
          </Tab>
          <Tab label="Attendence" {...a11yProps(3)} />
          <Tab label="Assignment" {...a11yProps(4)} />
          <Tab label="Feedback" {...a11yProps(5)} />
          <Tab label="My Courses" {...a11yProps(6)} />
          <Tab label="Live" {...a11yProps(7)} />
          {/* <Tab label="Video Player" {...a11yProps(6)} /> */}
        </Tabs>
      </Paper>
      <Box sx={{ margin: "0px 20px" }}>
        <TabPanel value={tabValue} index={0}>
          <Paper>
            <Heading>Routine</Heading>
            <Divider />
          </Paper>
          <StudentRoutine />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Paper>
            <Heading>Invoice</Heading>
            <Divider />
            <Box
              sx={{
                height: 300,
                overflowY: "scroll",
                padding: 2,
              }}>
              {invoice && invoice.length > 0 ? (
                <Grid container rowGap={1}>
                  {invoice.map((row) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                      key={row.id}
                      sx={{
                        display: "flex",
                        padding: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: 2,
                          bgcolor: "whitesmoke",
                          borderRadius: "10px",
                          width: "100%",
                        }}>
                        <Box>
                          <Typography
                            sx={{ fontSize: 15, textAlign: "start" }}
                            color="text.secondary">
                            <b style={{ color: "#E1AD01" }}>
                              {" "}
                              {row.receiptType.name}:
                            </b>
                            {row.totalAmountPaid}
                          </Typography>

                          <Typography
                            sx={{ fontSize: 15, textAlign: "start" }}
                            color="text.secondary">
                            <b style={{ color: "#E1AD01" }}> Date:</b>
                            {dayjs(row.dueDate).format("DD-MM-YYYY")}
                          </Typography>
                        </Box>
                        <div onClick={() => handleGetPrintPdf(row._id)}>
                          <DownloadIcon
                            sx={{ fontSize: 35, cursor: "pointer" }}
                            color="success"
                          />
                        </div>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
                  No Invoices
                </Typography>
              )}
            </Box>
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Paper>
            <Heading>My Receipts</Heading>
            <Divider />
            <MyReceipts />
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <Paper>
            <Heading>Attendence</Heading>
            <Divider />
            <StudentAttendence />
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          <Paper>
            <Heading>Assignment</Heading>
            <Divider />
            <Box
              sx={{
                overflowY: "scroll",
                padding: 2,
              }}>
              {assignment && assignment.length > 0 ? (
                <Grid container spacing={2}>
                  {assignment.map((row) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <Card>
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "10px",
                            }}>
                            <Box>
                              {" "}
                              <Typography
                                sx={{ fontSize: 15, textAlign: "start" }}
                                color="text.secondary"
                                gutterBottom>
                                <b style={{ color: "#E1AD01" }}>Subject:</b>
                                {row.subject.subjectName}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 15, textAlign: "start" }}
                                color="text.secondary"
                                gutterBottom>
                                <b style={{ color: "#E1AD01" }}>Assignment:</b>
                                {row.assignmentTitle}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 15, textAlign: "start" }}
                                color="text.secondary"
                                gutterBottom>
                                <b style={{ color: "#E1AD01" }}> Due Date:</b>
                                {dayjs(row.deadline).format("DD-MM-YYYY")}
                              </Typography>
                            </Box>
                            <Box>
                              {" "}
                              <Button onClick={handleDownloadAssignment}>
                                <DownloadIcon
                                  sx={{ fontSize: 35, cursor: "pointer" }}
                                  color="success"
                                />
                              </Button>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
                  No assignments found
                </Typography>
              )}
            </Box>
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={5}>
          <Heading>Feedback</Heading>
          <Divider />

          <Feedbacks />
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Heading>My Courses</Heading>
          <Divider />
          <MyCourses />
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
          <StudentLive />
        </TabPanel>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title">
        <StudentResetPassword handleCloseModal={handleCloseModal} />
      </Dialog>
      <Box sx={{ mb: 3 }} />
    </Container>
  );
}
