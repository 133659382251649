import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Grid,
  TextareaAutosize,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Dialog,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useContext } from "react";
import SettingContext from "../../../context/SettingsContext";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import appendStringToUrls from "../../../utils/appendString";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TimezoneSelect from "react-timezone-select";
import { Params, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import currencyCodes from "currency-codes";
import currencyToSymbolMap from "currency-symbol-map/map";
import avatar from "../../../assets/Images/avatar.jpg";
import banner from "../../../assets/Images/admissionbg.jpg";
import DownloadIcon from "@mui/icons-material/Download";
import StickyBar from "../../StickyBar";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    overflow: "visible",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    marginRight: "10px",
    ":hover": { background: "#1b3779", opacity: 0.9 },
    color: "#fff",
  },
};

const Settings = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    selectedSetting,
    setSelectedSetting,
    settings,
    setSettings,
    setSettingsUpdateCount,
  } = useContext(SettingContext);
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState("");
  const [selectBannerImg, setSelectBannerImg] = useState(null);
  const [settingDetails, setSettingDetails] = useState(null);
  const [createDetails, setCreateDetails] = useState({
    regDate: dayjs(Date.now()),
    sessionStartMonth: dayjs(Date.now()),
    sessionEndMonth: dayjs(Date.now()),
  });
  const [sessionStartMonth, setSessionStartMonth] = useState(dayjs(Date.now()));
  const [sessionEndMonth, setSessionEndMonth] = useState(dayjs(Date.now()));
  const [schoolLogoLink, setSchoolLogoLink] = useState("");
  const { setSettingsContext } = useContext(SettingContext);
  const [school, setSchool] = useState([]);
  const [addDialog, setAddDialog] = useState(false);
  const [setting, setSetting] = useState(null);
  const [logoLoader, setLogoLoader] = useState(false);
  const [bannerLoader, setBannerLoader] = useState(false);
  // const currencies = currencyCodes.data;
  // const currenciess = Object.keys(currencyToSymbolMap);
  const currencies = currencyCodes.data;
  const currencyCodesList = currencies.map((currency) => currency.code);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleChangeTimeZone = (t) => {
    setSettingDetails({ ...settingDetails, defaultTimeZone: t });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    let symbol = "";
    if (name === "currency") {
      symbol = currencyToSymbolMap[value] || "";
    }

    setSettingDetails((prev) => ({
      ...prev,
      [name]: value,
      currencySymbol: name === "currency" ? symbol : prev.currencySymbol,
    }));
  };

  const handlePhoto = async (e) => {
    setLogoLoader(true);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);

    try {
      setPreviewUrl(URL.createObjectURL(e.target.files[0]));
      const res = await post(urls.fileUpload.postFileUpload, formData, {
        headers: { roleFunction: "setting" },
      });

      setSettingDetails({ ...settingDetails, logo: res.data.result[0]._id });
      setSchoolLogoLink(res.data.result[0].link);
    } catch (error) {
      setLogoLoader(false);
      console.error(error);
    }
    setLogoLoader(false);
  };

  const handleBannerImg = async (e) => {
    setBannerLoader(true);
    const formData = new FormData();

    if (e.target.files.length + settingDetails.bannerImages?.length > 6) {
      toast.error("You can only select up to 6 files.");
      e.target.value = null;
      setBannerLoader(false);
      return;
    }

    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("files", e.target.files[i]);
    }

    try {
      const res = await post(urls.fileUpload.postFileUpload, formData, {
        headers: { roleFunction: "setting" },
      });

      const fileLinks = res.data.result.map((file) => file.link);

      setSettingDetails({
        ...settingDetails,
        bannerImages: [...settingDetails.bannerImages, ...fileLinks],
      });

      setBannerLoader(false);
    } catch (error) {
      console.error(error);
      setBannerLoader(false);
    }
  };

  const handleRemoveImg = (img) => {
    let newBannerImg = settingDetails.bannerImages.filter((c) => c != img);
    setSettingDetails((pre) => ({ ...pre, bannerImages: newBannerImg }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      // if (settingDetails.bannerImages.length < 3) {
      //   toast.error("You have to select at least 3 banner images.");
      //   setLoading(false);
      //   return;
      // }
      const data = {
        ...settingDetails,
        schoolName: settingDetails.schoolName,
        address: settingDetails.address,
        phone: settingDetails.phone,
        regDate: dayjs(settingDetails.regDate).format("YYYY-MM-DD"),
        email: settingDetails.email,
        schFax: settingDetails.schFax,
        websiteFooter: settingDetails.websiteFooter,
        description: settingDetails.description,
        logo: settingDetails.logo,
        bannerImages: settingDetails.bannerImages || [],
        currency: settingDetails.currency,
        currencySymbol: settingDetails.currencySymbol,
        sessionStartMonth: settingDetails.sessionStartMonth,
        sessionEndMonth: settingDetails.sessionEndMonth,
        longitude: settingDetails.longitude,
        rollNumberType: settingDetails.rollNumberType,
        admissionNo: settingDetails.admissionNo,
        latitude: settingDetails.latitude,
        studentAttendenceType: settingDetails.studentAttendenceType,
        googleAnalyticsId: settingDetails.googleAnalyticsId,
        defaultTimeZone: settingDetails.defaultTimeZone,
        teacherActivityFeedbackEnabled:
          settingDetails.teacherActivityFeedbackEnabled,
      };
      if (setting) {
        const res = await put(
          urls.settings.putSettings,
          settingDetails._id,
          data,
          { headers: { roleFunction: "setting" } }
        );
        if (res.data.success) {
          setSettingsUpdateCount((prev) => prev + 1);
          setLoading(false);
          navigate(`/manageSetting`);
        }
      } else {
        const res = await post(urls.settings.postSettings, data, {
          headers: { roleFunction: "setting" },
        });
        if (res.data.success) {
          setSettingsUpdateCount((prev) => prev + 1);
          navigate(`/manageSetting`);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };

  const resetForm = () => {
    setCreateDetails({});
  };

  const handleCloseAddDialog = () => {
    setAddDialog(false);
    resetForm();
  };

  useEffect(() => {
    const setSettingById = async () => {
      try {
        const res = await get(`${urls.settings.getSettings}${id}`, {
          headers: { roleFunction: "setting" },
        });
        setSetting(res.data.data);
        setSettingDetails(res.data.data);
        if (res.data.data.logo) {
          setPreviewUrl(res.data.data.logo.link);
        }
      } catch (error) {
        console.error(error);
      }
    };
    setSettingById();
  }, [id]);

  useEffect(() => {
    if (setting) {
      setSettingDetails(setting);
    }
  }, [id]);

  if (!setting || !settingDetails) return null;

  const handleDownload = () => {
    try {
      window.open(previewUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  console.log(settingDetails.studentAttendenceType, "lllsettingDetails");

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: 15 }}>
          <Card style={{ margin: 15, padding: "22px 0px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} mg={12} lg={12}>
                <Typography
                  style={{
                    color: "red",

                    display: "inline-block",
                  }}
                >
                  Update Settings :
                </Typography>
              </Grid>
            </Grid>
          </Card>

          <div style={{ margin: 15 }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mb: "15px",
                py: "25px",
              }}
            >
              <Box
                sx={{
                  background: "#ececec",
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  backgroundPosition: "center",
                }}
              >
                <img
                  src={previewUrl ? previewUrl : avatar}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                  alt="Preview"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px",
                  mt: "15px",
                }}
              >
                <LoadingButton
                  loading={logoLoader}
                  variant="outlined"
                  component="label"
                  size="small"
                  // sx={styles.Button}
                >
                  {previewUrl ? "Update Logo" : "Upload Logo"}
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    // value={schoolLogo}
                    onChange={handlePhoto}
                  />
                </LoadingButton>
                {previewUrl && (
                  <Tooltip title="Download Logo">
                    <IconButton
                      size="small"
                      sx={styles.Button}
                      onClick={handleDownload}
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Card>
            <Card style={styles.card}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Basic Information:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    name="schoolName"
                    value={settingDetails.schoolName}
                    onChange={(event) => handleOnChange(event)}
                    label="School Name"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    label="Address"
                    name="address"
                    value={settingDetails.address}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    label="Phone"
                    name="phone"
                    value={settingDetails.phone}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Registration Date"
                    name="regDate"
                    value={dayjs(settingDetails.regDate).format("YYYY-MM-DD")}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    label="Email "
                    name="email"
                    value={settingDetails.email}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="filled-required"
                    size="small"
                    label="Fax"
                    name="schFax"
                    value={settingDetails.schFax}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    label="Website Footer"
                    name="websiteFooter"
                    value={settingDetails.websiteFooter}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    InputLabelProps={{
                      shrink: true,
                    }}
                    maxRows={4}
                    name="description"
                    value={settingDetails.description}
                    onChange={(event) => handleOnChange(event)}
                    style={{
                      width: "100%",
                      height: 100,
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    aria-label="maximum height"
                    placeholder="Drop a note"
                  />
                </Grid>
              </Grid>
            </Card>
            <Card style={styles.card}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Setting Information:
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Currency</InputLabel>
                    <Select
                      label="Currency"
                      id="demo-simple-select-filled"
                      value={settingDetails.currency}
                      onChange={(event) => handleOnChange(event)}
                      name="currency"
                      fontSize="small"
                    >
                      {currencies.map((currency) => (
                        <MenuItem
                          key={currency.code}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                          value={currency.code}
                        >
                          {currency.currency} ({currency.code})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Currency Symbol</InputLabel>
                    <Select
                      key={settingDetails.currency}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Currency Symbol"
                      value={settingDetails.currencySymbol}
                      onChange={handleOnChange}
                      name="currencySymbol"
                    >
                      <MenuItem
                        key={settingDetails.currency}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={settingDetails.currencySymbol}
                      >
                        {settingDetails.currencySymbol}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Session Start Month"
                        views={["month"]}
                        sx={{ fontSize: 14, fontWeight: 500 }}
                        inputFormat="MMMM"
                        name="sessionStartMonth"
                        value={settingDetails.sessionStartMonth}
                        onChange={(newValue) => {
                          setSettingDetails({
                            ...settingDetails,
                            sessionStartMonth: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" required {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Session End Month"
                        views={["month"]}
                        inputFormat="MMMM"
                        sx={{ fontSize: 14, fontWeight: 500 }}
                        name="sessionEndMonth"
                        value={settingDetails.sessionEndMonth}
                        onChange={(newValue) => {
                          setSettingDetails({
                            ...settingDetails,
                            sessionEndMonth: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" required {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <FormControl size="small" fullWidth>
                    <InputLabel required>Roll Number Type</InputLabel>
                    <Select
                      key={settingDetails.rollNumberType}
                      name="rollNumberType"
                      value={settingDetails.rollNumberType}
                      onChange={(event) => handleOnChange(event)}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Currency Symbol"
                    >
                      <MenuItem
                        value={"manual"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Manual
                      </MenuItem>
                      <MenuItem
                        value={"ascendingName"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Auto ascending name
                      </MenuItem>
                      <MenuItem
                        value={"ascendingNameFemale"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Auto ascending name Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel sx={{ fontSize: 12 }}>
                      Student Attendance Type
                    </InputLabel>
                    <Select
                      key={settingDetails.studentAttendenceType}
                      id="demo-simple-select-filled-l"
                      label="Student Attendance Type"
                      name="studentAttendenceType"
                      value={settingDetails.studentAttendenceType}
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"classWise"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Class-Wise
                      </MenuItem>
                      <MenuItem
                        value={"sectionWise"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Section-Wise
                      </MenuItem>
                      <MenuItem
                        value={"sessionWise"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Session-Wise
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>Admission Number</InputLabel>
                    <Select
                      key={settingDetails.admissionNo}
                      name="admissionNo"
                      value={settingDetails.admissionNo}
                      onChange={(event) => handleOnChange(event)}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Currency Symbol"
                    >
                      <MenuItem
                        value={"manual"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Manual
                      </MenuItem>
                      <MenuItem
                        value={"autoAscendingNo"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Auto ascending No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="filled-required"
                    fullWidth
                    label="Latitude"
                    size="small"
                    name="latitude"
                    value={settingDetails.latitude}
                    onChange={(event) => handleOnChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    size="small"
                    label="Longitude"
                    sx={{ label: { fontSize: 12 } }}
                    name="longitude"
                    value={settingDetails.longitude}
                    onChange={(event) => handleOnChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TimezoneSelect
                    styles={{
                      control: (baseStyle, state) => ({
                        ...baseStyle,
                        backgroundColor: "#fff",
                      }),
                    }}
                    style={{ backgroundColor: "#fff" }}
                    size="small"
                    placeholder="Select default timezone"
                    key={settingDetails.defaultTimeZone}
                    value={settingDetails.defaultTimeZone || {}}
                    onChange={handleChangeTimeZone}
                    name="defaultTimeZone"
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                    label="Google Analytics"
                    sx={{ minWidth: 229, label: { fontSize: 12 } }}
                    value={settingDetails.googleAnalyticsId}
                    onChange={(event) => handleOnChange(event)}
                    name="googleAnalyticsId"
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Teacher Activity Feedback Enabled</InputLabel>
                    <Select
                      key={settingDetails.teacherActivityFeedbackEnabled}
                      name="teacherActivityFeedbackEnabled"
                      onChange={(event) => handleOnChange(event)}
                      value={settingDetails.teacherActivityFeedbackEnabled}
                      label="Teacher Activity Feedback Enabled"
                      id="demo-simple-select-filled"
                      fontSize="small"
                    >
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"true"}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"false"}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>

            <Card style={styles.card}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Social Information:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="facebookUrl"
                    value={settingDetails.facebookUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    size="small"
                    label="Facebook URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="twitterUrl"
                    value={settingDetails.twitterUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    size="small"
                    label="Twitter URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="linkedinUrl"
                    value={settingDetails.linkedinUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    size="small"
                    label="Linkedin URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="gplusUrl"
                    value={settingDetails.gplusUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    size="small"
                    label="Google Plus URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="youtubeUrl"
                    value={settingDetails.youtubeUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    size="small"
                    label="Youtube URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="instagramUrl"
                    value={settingDetails.instagramUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    size="small"
                    label="Instagram URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="pinterestUrl"
                    value={settingDetails.pinterestUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    size="small"
                    label="Pinterest URL "
                    sx={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card style={styles.card}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Banner Images:
                  </Typography>
                  <LoadingButton
                    loading={bannerLoader}
                    variant="outlined"
                    component="label"
                    size="small"
                    sx={{
                      objectFit: "contain",
                    }}
                  >
                    Upload Banner
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      value={settingDetails.bannerImg}
                      onChange={handleBannerImg}
                    />
                  </LoadingButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={12}
                  className="banner-scroll"
                  gap={2}
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                  }}
                >
                  {settingDetails?.bannerImages?.map((img, i) => (
                    <Box
                      key={i}
                      sx={{
                        justifyContent: "center",
                        borderRadius: "5px",
                        backgroundSize: "cover",
                        "&:hover": {
                          position: "relative",
                          display: "inline-block",
                        },
                      }}
                    >
                      <img
                        src={img}
                        style={{
                          width: "150px",
                          height: "100px",
                          marginLeft: "5px",
                          borderRadius: "5px",
                          boxShadow: "0px 0px 2px 0px gray",
                        }}
                        alt="Preview"
                      />
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() => handleRemoveImg(img)}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          borderRadius: "50px",
                          padding: "2px",
                          cursor: "pointer",
                          background: "#DEE0E2",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Card>

            <StickyBar
              content={
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "5px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ color: "#fff" }}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                  <LoadingButton
                    type="submit"
                    size="small"
                    sx={styles.Button}
                    loading={loading}
                    variant="contained"
                  >
                    Update
                  </LoadingButton>
                </Grid>
              }
            />
          </div>
        </div>
        <div style={{ marginBottom: "70px" }}></div>
      </form>
    </>
  );
};

export default Settings;
