import {
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { objHas } from "../../../utils/utils";
import { useRef } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import { LoadingButton } from "@mui/lab";

const StudentBulkPhoto = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const photos = useRef([]);

  const styles = {
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
    },
    card: {
      padding: 15,
      marginBottom: 20,
    },
    textfield: {
      width: "100%",
      label: { fontSize: 12 },
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
    },
  };
  useEffect(() => {
    const init = async () => {
      try {
        const { data: academicYears, status: academicYearsStatus } = await get(
          `${urls.administrator.academicYearGetAll}`,
          {
            headers: { roleFunction: "admitStudent" },
          }
        );
        if (academicYearsStatus > 199 && academicYearsStatus < 299) {
          setAcademicYears(academicYears.data);
        }
        const { data: schoolClass, status: schoolClassStatus } = await get(
          `${urls.class.getAllClass}`,
          {
            headers: { roleFunction: "admitStudent" },
          }
        );
        if (schoolClassStatus > 199 && schoolClassStatus < 299) {
          setClasses(schoolClass.data);
        }
      } catch (error) {}
    };
    init();
  }, [selectedSetting]);
  useEffect(() => {
    const getSections = async () => {
      try {
        const { data: sections, status: sectionsStatus } = await get(
          `${urls.schoolSection.getAllSection}`,
          {
            headers: { roleFunction: "admitStudent" },
            params: {
              search: {
                class: formData.schoolClass,
              },
            },
          }
        );
        if (sectionsStatus > 199 && sectionsStatus < 299) {
          setSections(sections.data);
        }
      } catch (error) {}
    };
    if (formData.schoolClass) {
      getSections();
    }
  }, [formData.schoolClass, selectedSetting]);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleStudentSearchSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const { data: studentsData, status: studentsStatus } = await get(
        `${urls.students.getAllStudent}`,
        {
          headers: { roleFunction: "admitStudent" },
          params: {
            search: {
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.schoolSection,
              academicYear: formData.academicYear,
            },
          },
        }
      );
      if (studentsStatus > 199 && studentsStatus < 299) {
        setStudents(studentsData.data);
      }
    } catch (error) {}
    setLoader(false);
  };
  const handleStudentUpload = async (e, id) => {
    toast.dismiss();
    const studentPhoto = new FormData();
    studentPhoto.append("files", e.target.files[0]);
    try {
      const { data: fileUpload, status: fileUploadStatus } = await post(
        `${urls.fileUpload.postFileUpload}`,
        studentPhoto,
        {
          headers: { roleFunction: "admitStudent" },
        }
      );
      if (fileUploadStatus > 199 && fileUploadStatus < 299) {
        const photoId = fileUpload.result[0]._id;
        const { data: studentUpdate, status: photoUpdateStatus } = await put(
          `${urls.students.putStudent}${id}`,
          "",
          {
            studentPhoto: photoId,
          },
          {
            headers: { roleFunction: "admitStudent" },
          }
        );
        if (photoUpdateStatus > 199 && photoUpdateStatus < 299) {
          const updatedPhoto = fileUpload.result[0];
          setStudents((students) =>
            students.map((student) =>
              student._id === id
                ? { ...student, studentPhoto: updatedPhoto }
                : student
            )
          );
        }
      }
    } catch (error) {}
  };
  // const handlePhotoSubmit = async () => {
  //     try {

  //     } catch (error) {

  //     }
  // }
  return (
    <div style={{ margin: "15px" }}>
      <SectionSubHeader title=" Students Bulk Photo " />

      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleStudentSearchSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  size="small"
                  fullWidth
                  // style={styles.textfield}
                  required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}>
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  size="small"
                  fullWidth
                  // style={styles.textfield}
                  required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={formData.schoolClass || ""}
                    onChange={handleOnChange}>
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  size="small"
                  fullWidth
                  // style={styles.textfield}
                  required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Sections
                  </InputLabel>
                  <Select
                    label="Sections"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolSection"
                    value={formData.schoolSection || ""}
                    onChange={handleOnChange}>
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}>
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={1} style={{ alignSelf: "center" }}>
                <LoadingButton
                  loading={loader}
                  type="submit"
                  size="small"
                  variant="contained"
                  style={styles.Button}>
                  SEARCH
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Roll No</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Name</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Photo</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">File</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student, index) => (
                <TableRow
                  key={student._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}>
                    {student.academicInfo.rollNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}>
                    {student.basicInfo.name}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ textTransform: "capitalize" }}>
                    <Avatar src={objHas(student, "studentPhoto.link", "")} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleStudentUpload(e, student._id)}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}>
                    <Link to={`/edit-student/${student._id}`}>
                      <Button>edit</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div
        style={{
          textAlign: "center",
          marginTop: "1rem",
        }}></div>
    </div>
  );
};

export default StudentBulkPhoto;
