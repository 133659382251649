import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from "./Addmission.module.css";
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const Sports = () => {
  const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];

  return (
    <>
      <TopNav />
      <Navbar />
      <div className={css.headerContainer}>
        <div>
          <h1>SPORTS</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/">
              Home
            </Link>{" "}
            / SPORTS
          </h5>
        </div>
      </div>
      <Grid container spacing={12} marginLeft={"-76px"}>
        <Grid
          items
          xs={12}
          mg={12}
          lg={8}
          style={{
            margin: "40px 0px 20px 0px",
            "border-right": "1px solid #BDBDBD",
          }}>
          <p style={{ margin: "0px 20px 20px 24px", fontSize: "1.11rem" }}>
            {" "}
            Akshara School gives sufficient time to the students for recreation
            in the form of Yoga classes, Aerobics, Physical Education and both
            indoor and outdoor games.. A beautiful, well-equipped children park
            has been created for the tiny tots to enjoy their school with fun
            and play
          </p>
          <h5 style={{ margin: "5px 10px 10px 24px", fontWeight: "700" }}>
            Outdoor games :
          </h5>
          <p style={{ margin: "-5px 10px 20px 24px", fontSize: "1.11rem" }}>
            The school has a spacious ground for outdoor activities and it
            comprises courts for valley ball, Badminton and Some of the outdoor
            games taught in school Football, Cricket, Kho-Kho, Athletics etc.
          </p>
          <h5 style={{ margin: "5px 10px 10px 24px", fontWeight: "700" }}>
            Indoor games :
          </h5>
          <p style={{ margin: "-5px 10px 20px 24px", fontSize: "1.11rem" }}>
            Indoor games like Chess, Carrom and Table Tennis are also played.
          </p>
          <h5 style={{ margin: "5px 10px 10px 24px", fontWeight: "700" }}>
            Aerobics and P.T. :
          </h5>
          <p style={{ margin: "-5px 10px 20px 24px", fontSize: "1.11rem" }}>
            Regular classes of Aerobics and P.T (Physical Education) are
            conducted during the daily schedule for the students to keep
            themselves physically fit.
          </p>
        </Grid>
        <Grid
          items
          xs={12}
          mg={12}
          lg={4}
          style={{ height: "80vh", paddingTop: "40px" }}>
          <Demo />
        </Grid>
      </Grid>
      {/* <div className={css.contentContainer}>
          <div className={css.content}>
            <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    Excellent School gives sufficient time to the students for recreation in the form of Yoga classes, Aerobics, Physical Education and both indoor and outdoor games.. A beautiful, well-equipped children park has been created for the tiny tots to enjoy their school with fun and play.                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                        <span style={{fontWeight:'bold'}}>Outdoor games</span>: The school has a spacious ground for outdoor activities and it comprises courts for valley ball, Badminton and Some of the outdoor games taught in school Football, Cricket, Kho-Kho, Athletics etc.
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    <span style={{fontWeight:'bold'}}>Indoor games</span>: Indoor games like Chess, Carrom and Table Tennis are also played.
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    <span style={{fontWeight:'bold'}}>Aerobics and P.T.</span>: Regular classes of Aerobics and P.T (Physical Education) are conducted during the daily schedule for the students to keep themselves physically fit.
                    </div>
                </div>
          </div>
          <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
};

export default Sports;
