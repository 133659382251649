import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@material-ui/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import dayjs from "dayjs";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../../components/Wrapper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { TableContainer } from "@material-ui/core";
import ShowComponent from "../ShowComponent";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        rowGap: 2,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const Vehicle = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [list, setList] = React.useState([]);
  const [listRoute, setListRoute] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [dialog, setOpenDialog] = React.useState(false);
  const [dialogRoute, setOpenDialogRoute] = React.useState(false);
  const [editVehicle, setEditVehicle] = useState();
  const [addForm, setAddForm] = useState({});
  const [renewalDate, setRenewelDate] = useState();
  const [employees, setEmployees] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [showDetailsRoute, setShowDetailsRoute] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const [sort, setSort] = useState({
    vehicleNumber: "asc",
    driver: "asc",
    totalPassenger: "asc",
    trackingId: "asc",
    renewalDate: "asc",
  });
  const sortByVehicleNumber = () => {
    if (sort.vehicleNumber === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.vehicleNumber.localeCompare(a.vehicleNumber)),
      ];
      setList(sortedList);
      setSort({ ...sort, vehicleNumber: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.vehicleNumber.localeCompare(b.vehicleNumber)),
      ];
      setList(sortedList);
      setSort({ ...sort, vehicleNumber: "asc" });
    }
  };
  const sortByDriver = () => {
    if (sort.driver === "asc") {
      const sortedList = [
        ...list.sort((a, b) =>
          b.driver.basicInfo.empName.localeCompare(a.driver.basicInfo.empName)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, driver: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) =>
          a.driver.basicInfo.empName.localeCompare(b.driver.basicInfo.empName)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, driver: "asc" });
    }
  };
  const sortByTotalPassenger = () => {
    if (sort.totalPassenger === "asc") {
      const sortedList = [
        ...list.sort((a, b) =>
          b.totalPassenger.localeCompare(a.totalPassenger)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, totalPassenger: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) =>
          a.totalPassenger.localeCompare(b.totalPassenger)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, totalPassenger: "asc" });
    }
  };
  const sortByTrackingId = () => {
    if (sort.trackingId === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.trackingId.localeCompare(a.trackingId)),
      ];
      setList(sortedList);
      setSort({ ...sort, trackingId: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.trackingId.localeCompare(b.trackingId)),
      ];
      setList(sortedList);
      setSort({ ...sort, trackingId: "asc" });
    }
  };
  const sortByRenewalDate = () => {
    if (sort.renewalDate === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.renewalDate.localeCompare(a.renewalDate)),
      ];
      setList(sortedList);
      setSort({ ...sort, renewalDate: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.renewalDate.localeCompare(b.renewalDate)),
      ];
      setList(sortedList);
      setSort({ ...sort, renewalDate: "asc" });
    }
  };
  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getVehicle();
  }, [selectedSetting._id]);

  useEffect(() => {
    const getDriver = async () => {
      try {
        const { data: role, status } = await get(`${urls.role.getAllRole}`, {
          // const { data: role, status } = await get(`${urls.role.getAllRole}`, {
          headers: { roleFunction: "vehicle" },
          params: {
            search: {
              roleName: "DRIVER",
            },
          },
        });
        console.log(role, "udata");

        if (status > 199 && status < 299) {
          const driverRole = role.data[0];
          const { data: employees, status } = await get(
            `${urls.employee.getAllEmployee}`,
            {
              headers: { roleFunction: "vehicle" },
              params: {
                search: {
                  roleName: "DRIVER",
                  // role: role.data[0]?._id,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            const driver = employees.data.filter((emp) =>
              emp.role.includes(driverRole._id)
            );
            setEmployees(driver);
            console.log(driver, "driverr");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDriver();
  }, [selectedSetting._id]);

  console.log(employees, "oooo");

  useEffect(() => {
    getVehicle();
  }, [selectedSetting._id]);

  const getVehicle = async () => {
    try {
      const { data, status } = await get(urls.transport.getList, {
        headers: { roleFunction: "vehicle" },
      });
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.transport.deletVehicle}`,
        id,
        {},
        { roleFunction: "vehicle" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setEditVehicle(null);
    setAddForm({});
    setOpen(false);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editVehicle) {
        const res = await post(
          `${urls.transport.create}`,
          {
            vehicleNumber: addForm.vehicleNumber,
            vehicleModel: addForm.vehicleModel,
            driver: addForm.driver,
            vehicleLicense: addForm.vehicleLicense,
            vehicleContact: addForm.vehicleContact,
            trackingId: addForm.trackingId,
            insuranceName: addForm.insuranceName,
            insuranceId: addForm.insuranceId,
            renewalDate: renewalDate,
            totalPassenger: addForm.totalPassenger,
            note: addForm.note,
          },
          { headers: { roleFunction: "vehicle" } }
        );
        if (res.data.success) {
          getVehicle();
          handleClose();
          setLoader(false);
        } else {
          console.log("Couldn't complete your request, Try again later");
        }
      } else {
        const res = await put(
          `${urls.transport.update}/${editVehicle}`,
          "",
          {
            vehicleNumber: addForm.vehicleNumber,
            vehicleModel: addForm.vehicleModel,
            driver: addForm.driver,
            vehicleLicense: addForm.vehicleLicense,
            vehicleContact: addForm.vehicleContact,
            trackingId: addForm.trackingId,
            insuranceName: addForm.insuranceName,
            insuranceId: addForm.insuranceId,
            renewalDate: renewalDate,
            totalPassenger: addForm.totalPassenger,
            note: addForm.note,
          },
          { headers: { roleFunction: "vehicle" } }
        );

        if (res.data.success) {
          getVehicle();
          handleClose();
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.vehicleNumber.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDialog = (vehicle) => {
    setShowDetails(vehicle);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };
  useEffect(() => {
    getRoute();
  }, []);

  const getRoute = async () => {
    try {
      const { data, status } = await get(urls.route.getList, {
        headers: { roleFunction: "vehicle" },
      });
      setListRoute(data.data);
      console.log(data.data, "getRouteeeeeepppppp");
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickOpenDialogRoute = (routeVehicle) => {
    setShowDetailsRoute(
      listRoute.filter((l) => l.vehicleNumber?._id == routeVehicle)
    );
    setOpenDialogRoute(true);
  };
  const handleCloseDialogRoute = () => {
    setShowDetailsRoute([]);
    setOpenDialogRoute(false);
  };

  const handleClassMapEdit = (id, data) => {
    setEditVehicle(id);
    setAddForm({
      vehicleNumber: data.vehicleNumber,
      driver: data.driver._id,
      totalPassenger: data.totalPassenger,
      trackingId: data.trackingId,
      vehicleModel: data.vehicleModel,
      insuranceName: data.insuranceName,
      renewalDate: data.renewalDate,
      insuranceId: data.insuranceId,
      vehicleContact: data.vehicleContact,
      note: data.note,
      vehicleLicense: data.vehicleLicense,
    });
    handleClickOpen();
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Vehicle" />

      <div style={{ margin: "15px 0", overflow: "hidden" }}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginTop: "15px", marginBottom: "10px" }}
        >
          <Grid xs="auto">
            <TextField
              size="small"
              value={search}
              placeholder="Enter Vehicle No."
              onChange={handleSearch}
              label="Search Vehicle No."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* </div> */}

        <TableContainer component={Paper}>
          <Table sx={{ marginTop: "1rem" }}>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByVehicleNumber}
                    className="class-table-header"
                  >
                    Vehicle Number
                    <span style={{ cursor: "pointer" }}>
                      {sort.vehicleNumber === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByDriver} className="class-table-header">
                    Driver
                    <span style={{ cursor: "pointer" }}>
                      {sort.driver === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Contact
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByTotalPassenger}
                    className="class-table-header"
                  >
                    Total passengers
                    <span style={{ cursor: "pointer" }}>
                      {sort.totalPassenger === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByTrackingId}
                    className="class-table-header"
                  >
                    Tracking ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.trackingId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Vehicle Model
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Insurance Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByRenewalDate}
                    className="class-table-header"
                  >
                    Renewal Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.renewalDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((vehicle, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {vehicle.vehicleNumber}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.driver?.basicInfo.empName}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.vehicleContact}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.totalPassenger}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.trackingId}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.vehicleModel}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.insuranceName}
                          </TableCell>
                          <TableCell align="center">
                            {dayjs(vehicle.renewalDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip title="View">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => handleClickOpenDialog(vehicle)}
                                >
                                  <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                              <ShowComponent
                                roleFunction="vehicle"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    sx={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleClassMapEdit(vehicle._id, vehicle)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="vehicle"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      setDeleteModal(vehicle._id, vehicle)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={vehicle._id}
                                setDeleteModal={setDeleteModal}
                              />
                              <IconButton
                                sx={{
                                  color: "#1b3779",
                                }}
                                size="small"
                                onClick={() =>
                                  handleClickOpenDialogRoute(vehicle._id)
                                }
                              >
                                <DirectionsTransitFilledIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((vehicle, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.vehicleNumber}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.driver?.basicInfo.empName}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.vehicleContact}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.totalPassenger}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.trackingId}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.vehicleModel}
                          </TableCell>
                          <TableCell align="center">
                            {vehicle.insuranceName}
                          </TableCell>
                          <TableCell align="center">
                            {dayjs(vehicle.renewalDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              className="edit-delete"
                            >
                              <Tooltip title="View Vehicle Information">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => handleClickOpenDialog(vehicle)}
                                >
                                  <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                              <ShowComponent
                                roleFunction="vehicle"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    sx={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleClassMapEdit(vehicle._id, vehicle)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>

                              <ShowComponent
                                roleFunction="vehicle"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      setDeleteModal(vehicle._id, vehicle)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={vehicle._id}
                                setDeleteModal={setDeleteModal}
                              />
                              <Tooltip title="View  Route Information">
                                <IconButton
                                  sx={{
                                    color: "#1b3779",
                                  }}
                                  size="small"
                                  onClick={() =>
                                    handleClickOpenDialogRoute(vehicle._id)
                                  }
                                >
                                  <DirectionsTransitFilledIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!list.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        {/*======== Add vehicle pluse button ===== */}
        <Box className="add-icon">
          <Tooltip title="Add Vehicle">
            <Fab
              variant="contained"
              sx={{
                color: "#ffff",
                backgroundColor: "#1b3779",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleClickOpen}
            >
              <ShowComponent roleFunction="vehicle" action="add">
                <AddIcon />
              </ShowComponent>
            </Fab>
          </Tooltip>
        </Box>
        {/* ================ || ================= */}

        {/*======= Add/Update Dialog ============ */}
        <Dialog
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title"
          open={open}
          onClose={handleClose}
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="15px"
                fontSize="20px"
                fontWeight="bold"
              >
                {editVehicle ? "Update Vehicle" : "Add Vehicle"}
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    required
                    label="Vehicle Number"
                    name="vehicleNumber"
                    value={addForm.vehicleNumber || ""}
                    onChange={handleAddForm}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Vehicle Model"
                    name="vehicleModel"
                    value={addForm.vehicleModel || ""}
                    onChange={handleAddForm}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Driver</InputLabel>
                    <Select
                      label="Driver"
                      required
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="driver"
                      value={addForm.driver}
                      onChange={handleAddForm}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee._id} value={employee._id}>
                          {employee.basicInfo.empName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Vehicle License"
                    name="vehicleLicense"
                    onChange={handleAddForm}
                    value={addForm.vehicleLicense || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Vehicle Contact"
                    required
                    type="number"
                    name="vehicleContact"
                    value={addForm.vehicleContact || ""}
                    onChange={handleAddForm}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Tracking ID"
                    name="trackingId"
                    value={addForm.trackingId || ""}
                    onChange={handleAddForm}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Insurence Name"
                    required
                    name="insuranceName"
                    onChange={handleAddForm}
                    value={addForm.insuranceName || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    required
                    label="Insurence ID"
                    name="insuranceId"
                    value={addForm.insuranceId || ""}
                    onChange={handleAddForm}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Total Seats"
                    required
                    type="number"
                    name="totalPassenger"
                    onChange={handleAddForm}
                    value={addForm.totalPassenger || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextareaAutosize
                    maxRows={4}
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      padding: 10,
                      height: 70,
                      borderRadius: "5px",
                      marginLeft: "2px",
                      marginTop: "6px",
                      overflow: "auto",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "2px",
                      gap: "10px",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                      },
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="error"
                      size="small"
                      type="button"
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {editVehicle ? "update" : "Add"}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
        {/* ================ || ================= */}

        {/* ========= Vehicle Information ======= */}
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Vehicle Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <Box>
                    <tr>
                      <td>School Name</td>
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Number</td>
                      <td>{showDetails.vehicleNumber}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Modal</td>
                      <td>{showDetails.vehicleModel}</td>
                    </tr>
                    <tr>
                      <td>Driver</td>
                      <td>{showDetails.driver.basicInfo.empName}</td>
                    </tr>
                    <tr>
                      <td>Vehicle License</td>
                      <td>{showDetails.vehicleLicense}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Contact</td>
                      <td>{showDetails.vehicleContact}</td>
                    </tr>
                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>
                        {dayjs(showDetails.createdAt).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </Box>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
        {/* ==================||================= */}

        {/* ==== Vehicle Route Information ====== */}
        <BootstrapDialog
          onClose={handleCloseDialogRoute}
          aria-labelledby="customized-dialog-title"
          open={dialogRoute}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialogRoute}
            style={{
              backgroundColor: "#1b3779",
              color: "white",
              width: "100%",
            }}
          >
            Vehicle Route Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetailsRoute.length ? (
                  showDetailsRoute?.map((d, i) => (
                    <Box key={i} sx={{ mb: 2 }}>
                      <tr>
                        <td>School Name</td>
                        <td>{selectedSetting.schoolName}</td>
                      </tr>
                      <tr>
                        <td>Route Title</td>
                        <td>{d?.transportRouteTitle}</td>
                      </tr>
                      <tr>
                        <td>Vehicle Start</td>
                        <td>{d?.routeStart}</td>
                      </tr>
                      <tr>
                        <td>Vehicle End</td>
                        <td>{d?.routeEnd}</td>
                      </tr>
                      <tr>
                        <td>Route Stops</td>
                        <td>
                          <tr>
                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Stop Name
                            </th>
                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Stop KM
                            </th>
                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Pick Start
                            </th>

                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Pick End
                            </th>
                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Drop Start
                            </th>
                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Drop End
                            </th>
                          </tr>
                          {d?.routeDetails.map((route, index) => (
                            <tr key={index}>
                              <td>{route.stopName}</td>
                              <td>{route.stopKM}</td>
                              <td>{route.pickTime}</td>
                              <td>{route.pickEndTime}</td>
                              <td>{route.dropTime}</td>
                              <td>{route.dropEndTime}</td>
                            </tr>
                          ))}
                        </td>
                      </tr>
                    </Box>
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Not assigned to any vehicle!
                  </Typography>
                )}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
        {/* =============||====================== */}
      </div>
    </Wrapper>
  );
};

export default Vehicle;
