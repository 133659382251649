import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import { urls as url } from "../../../services/urlConstant";
import { del, get, post, put } from "../../../services/apis";
import { FormLabel } from "@mui/material";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import DeleteModal from "../Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ListContainer = styled(Box)(() => ({
  flexWrap: "nowrap",
  overflowY: "hidden",
  overflowX: "auto",
  flexDirection: "column",
  display: "flex",
  paddingLeft: "10px",
}));
const ListItem = styled(Typography)(() => ({
  fontSize: "14px",
  paddingRight: "5px",
  paddingLeft: "5px",
}));

const ListItemContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#f9f9f9",
  marginRight: "10px",
  alignItems: "center",
  borderRadius: "10@s",
  minWidth: "150px",
}));

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function LeaveType() {
  const { selectedSetting } = useContext(SettingContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [schoolDepartment, setSchoolDepartment] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [editLeaveType, setEditLeaveType] = useState();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [multipleDepartments, setMultipleDepartments] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [loader, setLoader] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [state, setState] = useState({
    name: "",
    leaveType: "",
    departments: "",
    autoEarned: false,
    total: 0,
    isSpecial: false,
    canResetCarryForward: false,
    carryForwardCount: 0,
    autoEarnCount: 0,
  });

  let {
    name,
    leaveType,
    departments,
    autoEarned,
    total,
    isSpecial,
    canResetCarryForward,
    carryForwardCount,
    autoEarnCount,
  } = state;
  // let handleChange = (e) => {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "departments") {
      setMultipleDepartments(value);
    } else {
      setState({ ...state, [name]: value });
    }
  };

  useEffect(() => {
    const getSchoolDepartment = async () => {
      try {
        const res = await get(urls.department.getDepartment, {
          headers: { roleFunction: "leaveType" },
        });
        // console.log(res.data)
        setSchoolDepartment([...res.data.data]);
        setSearchFilter([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolDepartment();
  }, [selectedSetting._id]);

  let handleCancel = () => {
    setOpen(false);
    setState({});
    setMultipleDepartments([]);
    setEditLeaveType(null);
  };
  let fetchData = async () => {
    try {
      let { data } = await get(`${urls.leaveType.getList}/all`, {
        headers: { roleFunction: "leaveType" },
      });
      setLeaveTypeData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedSetting._id]);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let payload = {
      name,
      leaveType,
      departments: multipleDepartments,
      autoEarned,
      total,
      isSpecial,
      canResetCarryForward,
      carryForwardCount,
      autoEarnCount,
    };
    // console.log(payload, "payload");

    if (!editLeaveType) {
      try {
        let res = await post(`${urls.leaveType.create}/new`, payload, {
          headers: { roleFunction: "leaveType" },
        });

        if (res.data.success) {
          fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const response = await put(
        `${urls.leaveType.update}/${editLeaveType}`,
        "",
        payload,
        {
          headers: { roleFunction: "leaveType" },
        }
      );
      fetchData();
    }
    setState({
      name: "",
      leaveType: "",
      departments: "",
      autoEarned: false,
      total: 0,
      isSpecial: "",
      canResetCarryForward: false,
      carryForwardCount: 0,
      autoEarnCount: 0,
    });
    setMultipleDepartments([]);

    setOpen(false);
    setLoader(false);
  };

  const handleEdit = (id, value) => {
    // console.log(value, "bbhbhbhh");
    setOpen(true);
    setEditLeaveType(id);
    setState({
      name: value.name,
      leaveType: value.leaveType,
      departments: value.departments,
      autoEarned: value.autoEarned,
      total: value.total,
      isSpecial: value.isSpecial ? true : false,
      canResetCarryForward: value.canResetCarryForward,
      carryForwardCount: value.carryForwardCount,
      autoEarnCount: value.autoEarnCount,
    });
    // if (Array.isArray(value.departments)) {
    //   setMultipleDepartments(value.departments);
    // } else {
    //   setMultipleDepartments([]);
    // }
  };
  // console.log(multipleDepartments, "multipleDepartments");

  const handleDelete = async (id) => {
    try {
      const res = await del(
        `${urls.leaveType.delete}/remove/`,
        id,
        {},
        { roleFunction: "leaveType" }
      );
      if (res.data.success) {
        const filteredEvent = leaveTypeData.filter((item) => item._id !== id);
        setLeaveTypeData(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      leaveTypeData.length > 0 &&
        setFilter(
          leaveTypeData.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setFilter([]);
    }
  };

  const handleRemoveDepartments = (data) => {
    let newList = multipleDepartments.filter((t) => t != data);
    setMultipleDepartments(newList);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Leave Type" />
      <Box style={{ margin: "15px 0px" }}>
        <Grid container justifyContent="flex-end">
          <Grid xs="auto">
            <TextField
              full
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search Name"
              variant="outlined"
              placeholder="Search..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box style={{ margin: "15px 0" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell>
                  <span style={{ color: "white" }}>#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Name</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Leave category</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Department</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Total</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? filter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((value, index) => (
                      <TableRow key={index} style={{ border: "0px" }}>
                        <TableCell
                          key={value.name}
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{value.name}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.leaveType}
                        </TableCell>

                        <TableCell>
                          {value.departments.map((d) => d.department).join(",")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.total}
                        </TableCell>
                        <TableCell
                          //  className="edit-delete"
                          align="center"
                        >
                          <ShowComponent
                            roleFunction="leaveType"
                            action="update"
                          >
                            <Tooltip title="Update">
                              <IconButton
                                size="small"
                                onClick={() => handleEdit(value._id, value)}
                              >
                                <Edit sx={{ color: "#1b3779" }} />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <ShowComponent
                            roleFunction="leaveType"
                            action="delete"
                          >
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => setDeleteModal(value._id, value)}
                              >
                                <Delete color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={value._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                : leaveTypeData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((value, index) => (
                      <TableRow key={index} style={{ border: "0px" }}>
                        <TableCell
                          key={value.name}
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">{value.name}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.leaveType}
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.departments}
                        </TableCell> */}

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* {value.departments} */}
                          {value.departments.map((d) => d.department).join(",")}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.total}
                        </TableCell>
                        <TableCell
                          //  className="edit-delete"
                          align="center"
                        >
                          <ShowComponent
                            roleFunction="leaveType"
                            action="update"
                          >
                            <Tooltip title="Update">
                              <IconButton
                                size="small"
                                sx={{ color: "#1b3779" }}
                                onClick={() => handleEdit(value._id, value)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <ShowComponent
                            roleFunction="leaveType"
                            action="delete"
                          >
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => setDeleteModal(value._id)}
                              >
                                <Delete color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>

                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={value._id}
                            setDeleteModal={setDeleteModal}
                            title={`${value.leaveType} leave type`}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!leaveTypeData.length && !search.trim() && (
            <Typography
              variant="h6"
              style={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !filter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? filter.length : leaveTypeData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />

        {/* Add Leave Type */}
        <ShowComponent roleFunction="leaveType" action="add">
          <Box className="add-icon">
            <Tooltip title="Add Leave">
              <Fab
                size="medium"
                style={{ background: "#1b3779", color: "#fff" }}
                aria-label="add"
                onClick={handleOpen}
              >
                <Add />
              </Fab>
            </Tooltip>
          </Box>
        </ShowComponent>
      </Box>
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" onSubmit={handleSubmit}>
          {/* <Card > */}
          <ContainerBox>
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                fontWeight={600}
                mb={2}
              >
                {editLeaveType ? "Update Leave" : "Add Leave"}
              </Typography>
            </Box>
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="basic-standard"
                      name="name"
                      size="small"
                      value={name}
                      onChange={handleChange}
                      label="Name"
                      sx={{ label: { fontSize: 12 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel required>Leave Type</InputLabel>
                    <Select
                      label="Leave Type"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="leaveType"
                      size="small"
                      value={leaveType}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Student"}>Student</MenuItem>
                      <MenuItem value={"Employee"}>Employee</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {leaveType === "Employee" ? (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel size="small" required>
                          Department
                        </InputLabel>
                        <Select
                          label="Department"
                          labelId="demo-simpless-select-filled-label"
                          id="demo-simple-select-filled-l"
                          name="departments"
                          value={multipleDepartments}
                          onChange={handleChange}
                          multiple
                        >
                          {schoolDepartment.map((dept) => (
                            <MenuItem key={dept._id} value={dept._id}>
                              {dept.department}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <ListContainer>
                        {multipleDepartments.map((data) => (
                          <ListItemContainer key={data}>
                            <ListItem component="span">
                              {
                                schoolDepartment.filter((d) => d._id == data)[0]
                                  .department
                              }
                            </ListItem>
                            <IconButton
                              onClick={() => handleRemoveDepartments(data)}
                            >
                              <CloseRoundedIcon fontSize="small" />
                            </IconButton>
                          </ListItemContainer>
                        ))}
                      </ListContainer>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl required fullWidth size="small">
                        <RadioGroup
                          size="small"
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="isSpecial"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          value={isSpecial}
                          onChange={handleChange}
                        >
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            sx={{ fontSize: "15px", fontWeight: 600 }}
                          >
                            Is Special Type:
                          </FormLabel>
                          <Stack direction="row">
                            <FormControlLabel
                              value={"true"}
                              control={<Radio size="small" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={<Radio size="small" />}
                              label="No"
                            />
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="basic-standard"
                          size="small"
                          name="total"
                          value={total}
                          onChange={handleChange}
                          label="Total"
                          style={{ label: { fontSize: 12 } }}
                          // sx={styles.textfield}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="autoEarned"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          value={autoEarned}
                          onChange={handleChange}
                        >
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            sx={{ fontSize: "15px", fontWeight: 600 }}
                          >
                            Is Leave Auto Earned:
                          </FormLabel>
                          <Stack direction="row" marginLeft={2}>
                            <FormControlLabel
                              value={"true"}
                              control={<Radio size="small" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              size="small"
                              value={"false"}
                              control={<Radio size="small" />}
                              label="No"
                            />
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="standard-basic"
                          size="small"
                          name="autoEarnCount"
                          value={autoEarnCount}
                          onChange={handleChange}
                          label="Auto Earned Count"
                          style={{ minWidth: 227, label: { fontSize: 12 } }}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="canResetCarryForward"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          value={canResetCarryForward}
                          onChange={handleChange}
                        >
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            sx={{ fontSize: "15px", fontWeight: 600 }}
                          >
                            Is Carryforward Reset:{" "}
                          </FormLabel>
                          <Stack direction="row" marginLeft={2}>
                            <FormControlLabel
                              size="small"
                              value={"true"}
                              sx={{ fontSize: 15 }}
                              control={<Radio size="small" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              size="small"
                              value={"false"}
                              sx={{ fontSize: 15 }}
                              control={<Radio size="small" />}
                              label="No"
                            />
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="standard-basic"
                          size="small"
                          fullWidth
                          name="carryForwardCount"
                          value={carryForwardCount}
                          onChange={handleChange}
                          label="Is Carryorword Count"
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid item xs={12} md={6} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                        gap: "1%",
                      },
                    }}
                  >
                    <Button
                      onClick={handleCancel}
                      variant="contained"
                      size="small"
                      type="button"
                      color="error"
                      // sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        marginLeft: "10px",
                        color: "white",
                        background: "rgb(27, 55, 121)",
                        // m: 1,
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {editLeaveType ? "Update" : "Add"}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ContainerBox>
          {/* </Card> */}
        </form>
      </Modal>
    </Wrapper>
  );
}
export default LeaveType;
