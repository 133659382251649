import React, { useEffect, useState, useContext } from "react";
import css from "../../styles/Dashboard/Dashboard.module.css";
import Box from "@mui/material/Box";
import { get } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import { Card, Paper } from "@mui/material";
import { urls as url } from "../../services/urlConstant";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";

const styles = {
  card: { margin: 15, padding: 10, marginBottom: "10px" },
  textfield: {
    width: "50%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
  table: {
    width: "100%",
    marginTop: "15px",
    height: "100%",

    borderCollapse: "collapse",
    border: "1px solid #e0e0e0",
  },
  Paper: {
    margin: "6px 16px",
    width: "140px",
    height: "140px",
  },
};
export default function StudentRoutine() {
  const [routines, setRoutines] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const getRoutines = async () => {
      try {
        const getRoutine = await get(`${urls.students.studentRoutineList}`);
        setRoutines(getRoutine.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoutines();
  }, [selectedSetting]);

  return (
    <table style={styles.table} className={css.alter}>
      <tr style={{ textAlign: "center" }}>
        <th
          style={{
            border: "1px solid #e0e0e0",
            width: "150px",
            padding: "10px",
            borderCollapse: "collapse",
          }}
        >
          Day
        </th>
        <th
          style={{
            border: "1px solid #e0e0e0",
            padding: "13px",
            borderCollapse: "collapse",
          }}
        >
          Routine
        </th>
      </tr>
      {routines.map((data, i) => (
        <tr style={{ border: "1px solid black" }} key={i}>
          <td
            style={{
              padding: "13px",
              border: "1px solid black",
              borderCollapse: "collapse",
            }}
          >
            {data.day}
          </td>
          <td
            style={{
              padding: "5px",
              border: "1px solid black",
              borderCollapse: "collapse",
            }}
          >
            <Box component="span" sx={{ p: 1.5, border: "1px solid grey" }}>
              {data.startTime}-{data.endTime}
            </Box>

            {/* <Box component="span" sx={{ p: 1.5, border: "1px solid grey" }}>
                ghghffdff
              </Box> */}
          </td>
        </tr>
      ))}
    </table>
  );
}
