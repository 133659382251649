import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Menu, Search } from "@mui/icons-material";
import SettingContext from "../../../context/SettingsContext";
import { urls as url } from "../../../services/urlConstant";

import { get, post, put } from "../../../services/apis";
import { useLocation, useNavigate } from "react-router-dom";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Item = () => {
  let navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openStock, setOpenStock] = React.useState(false);
  const [openIssue, setOpenIssue] = React.useState(false);
  const { selectedSetting } = useContext(SettingContext);
  const [tabValue, setTabValue] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  // const [employee, setEmployee] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [addLoad, setAddLoad] = useState(false);
  const [editInventoryType, setEditInventoryType] = useState();
  const [excelLoading, setExcelLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const { state: localState } = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const [sort, setSort] = useState({
    name: "asc",
    department: "asc",
    brand: "asc",
  });
  const sortByName = () => {
    if (sort.name == "asc") {
      const sortedList = [
        ...inventoryData.sort((a, b) => b.name.localeCompare(a.name)),
      ];
      setInventoryData(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [
        ...inventoryData.sort((a, b) => a.name.localeCompare(b.name)),
      ];
      setInventoryData(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByDepartment = () => {
    if (sort.department == "asc") {
      const sortedList = [
        ...inventoryData.sort((a, b) =>
          b.department.department.localeCompare(a.department.department)
        ),
      ];
      setInventoryData(sortedList);
      setSort({ ...sort, department: "des" });
    } else {
      const sortedList = [
        ...inventoryData.sort((a, b) =>
          a.department.department.localeCompare(b.department.department)
        ),
      ];
      setInventoryData(sortedList);
      setSort({ ...sort, department: "asc" });
    }
  };
  const sortByBrand = () => {
    if (sort.brand == "asc") {
      const sortedList = [
        ...inventoryData.sort((a, b) => b.brand.localeCompare(a.brand)),
      ];
      setInventoryData(sortedList);
      setSort({ ...sort, brand: "des" });
    } else {
      const sortedList = [
        ...inventoryData.sort((a, b) => a.brand.localeCompare(b.brand)),
      ];
      setInventoryData(sortedList);
      setSort({ ...sort, brand: "asc" });
    }
  };
  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };

  useEffect(() => {
    if (localState) {
      if (localState == "item") {
        setTabValue(1);
      }
    }
  }, [localState]);

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };
  const handleClose = () => {
    setTabValue(0);
    setOpen(false);
    setData({});
    setEditInventoryType(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseStock = () => {
    setOpenStock(false);
  };
  const handleClickOpenStock = () => {
    setOpenStock(true);
  };
  const handleCloseIssue = () => {
    setOpenIssue(false);
  };
  const handleClickOpenIssue = () => {
    setOpenIssue(true);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      setShowEdit(false);
      setEditInventoryType();
      setData({
        name: "",
        department: "",
        itemId: "",
        description: "",
        brand: "",
      });
    }
    if (newValue === 0) {
      setShowEdit(false);
      setEditInventoryType();
      setData({
        name: "",
        department: "",
        itemId: "",
        description: "",
        brand: "",
      });
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      // inventoryData.length > 0 &&
      setSearchFilter(
        inventoryData.filter((ele) =>
          ele.name.toLowerCase().includes(value.toLowerCase().trim())
        )
      );
    } else {
      setSearchFilter([]);
    }
  };

  let [data, setData] = useState({
    name: "",
    department: "",
    itemId: "",
    description: "",
    brand: "",
  });

  let { name, department, itemId, description, brand } = data;

  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddLoad(true);
    let payload = { name, department, itemId, description, brand };

    if (!editInventoryType) {
      try {
        let { data } = await post(`${urls.inventory.createItem}`, payload, {
          headers: { roleFunction: "item" },
        });

        if (data.success) {
          let newList = [...inventoryData, data.data];
          setInventoryData(newList);
          fetchData();
          setTabValue(0);
          setShowEdit(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await put(
          `${urls.inventory.updateItem}${editInventoryType}`,
          "",
          payload,
          {
            headers: { roleFunction: "item" },
          }
        );

        if (response.data.success) {
          setInventoryData(
            inventoryData.map((e) => {
              if (e._id === response.data.data._id) {
                return response.data.data;
              } else {
                return e;
              }
            })
          );
          fetchData();
          setTabValue(0);
          setShowEdit(false);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setAddLoad(false);
  };

  useEffect(() => {
    const fetchDept = async () => {
      try {
        let { data } = await get(`${urls.department.getDepartment}`, {
          headers: { roleFunction: "item" },
        });
        setDepartments(data.data);
        console.log(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDept();
  }, [selectedSetting]);

  useEffect(() => {
    fetchData();
  }, [selectedSetting]);
  const fetchData = async () => {
    try {
      const { data } = await get(`${urls.inventory.getAllItem}`, {
        headers: { roleFunction: "item" },
      });
      console.log(data.data, "datattta");
      setInventoryData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  let handleEdit = (id, value) => {
    console.log("value", value);
    setOpen(true);
    setEditInventoryType(id);
    setTabValue(2);
    setShowEdit(true);

    console.log("id", id);

    setData({
      name: value.name,
      department: value.department._id,
      itemId: value.itemId,
      description: value.description,
      brand: value.brand,
    });
  };
  let [issue, setIssue] = useState({ name: "" });

  let handleChangeIssue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setIssue({ ...issue, [name]: value });
  };

  let handleSubmitIssue = (e) => {
    e.preventDefault();
    console.log(issue);
  };

  let handleNavigate = (item) => {
    navigate("/dashboard/category", { state: item });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleExcelDownload = async (e) => {
    e.preventDefault();
    setExcelLoading(true);
    try {
      const downloadUrl = `${urls.inventory.downloadExcel}`;

      const response = await get(
        downloadUrl,
        {
          headers: { roleFunction: "item" },
        },
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileName = `InventoryItem.xlsx`;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setExcelLoading(false);
  };
  const handlePdfDownload = async (e) => {
    e.preventDefault();
    setPdfLoading(true);
    try {
      const getdata = await get(
        `${urls.inventory.downloadPdf}`,
        {
          headers: { roleFunction: "item" },
        },
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getdata.data);
      window.open(uri, "_blank");
    } catch (error) {
      console.log(error);
    }
    setPdfLoading(false);
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Item" />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "15px 0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Item List" {...a11yProps(0)} />
          <Tab label="Add Item" {...a11yProps(1)} />
          {showEdit && <Tab label="Edit Item" {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <div style={styles.cardButton}>
            <LoadingButton
              loading={excelLoading}
              variant="contained"
              size="small"
              onClick={handleExcelDownload}
            >
              Excel
            </LoadingButton>
            <LoadingButton
              loading={pdfLoading}
              variant="contained"
              size="small"
              onClick={handlePdfDownload}
            >
              Pdf
            </LoadingButton>
          </div>

          <Grid item xs={12} md={3} lg={3}>
            <TextField
              size="small"
              fullWidth
              value={search}
              onChange={handleSearch}
              label="Search Item"
              placeholder="Enter Name"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByName} className="class-table-header">
                    Item Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByDepartment}
                    className="class-table-header"
                  >
                    Deparment
                    <span style={{ cursor: "pointer" }}>
                      {sort.department === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByBrand} className="class-table-header">
                    Brand
                    <span style={{ cursor: "pointer" }}>
                      {sort.brand === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((item, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">
                            {searchPage * searchRowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">
                            {item.department.department}
                          </TableCell>
                          <TableCell align="center">{item.brand}</TableCell>
                          <TableCell align="center">
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="item"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() => handleEdit(item._id, item)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : inventoryData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">
                            {item.department.department}
                          </TableCell>
                          <TableCell align="center">{item.brand}</TableCell>
                          <TableCell align="center">
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="item"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() => handleEdit(item._id, item)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!inventoryData.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : inventoryData.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <form className="form-tag" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TextField
                required
                id="outlined-basic"
                label="Enter Item Name"
                name="name"
                value={name}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">Department</InputLabel>
                <Select
                  label="Department"
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  name="department"
                  value={data.department || ""}
                  onChange={handleChange}
                >
                  {departments.map((value, index) => (
                    <MenuItem key={value._id} value={value._id}>
                      {value.department}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TextField
                required
                id="outlined-basic"
                label="Enter brand"
                name="brand"
                value={brand}
                onChange={handleChange}
                fullWidth
                size="small"
                // sx={{ m: 1, label: { fontSize: 12 } }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TextField
                required
                id="outlined-basic"
                label="Enter Item id"
                name="itemId"
                value={itemId}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextareaAutosize
                placeholder="Type Description..."
                name="description"
                value={description}
                onChange={handleChange}
                style={{
                  width: "100%",
                  maxHeight: 110,
                  borderRadius: "5px",
                  padding: 10,
                  height: 118,
                  overflow: "auto",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              mt: 2,
              mr: 1,
            }}
          >
            {/* <div className="cancel-submit-btn"> */}
            <Button
              onClick={handleClose}
              variant="contained"
              color="error"
              size="small"
              type="button"
            >
              Cancel
            </Button>
            <ShowComponent roleFunction="item" action="add">
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                loading={addLoad}
                sx={{
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
              >
                Add
              </LoadingButton>
            </ShowComponent>
          </Grid>
          {/* </div> */}
        </form>
      </TabPanel>
      {showEdit && (
        <TabPanel value={tabValue} index={2}>
          <form className="form-tag" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  required
                  id="outlined-basic"
                  label="Enter Item Name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small-label">
                    Department
                  </InputLabel>
                  <Select
                    label="Department"
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="department"
                    value={data.department || ""}
                    onChange={handleChange}
                  >
                    {departments.map((value, index) => (
                      <MenuItem key={value._id} value={value._id}>
                        {value.department}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  required
                  id="outlined-basic"
                  label="Enter brand"
                  name="brand"
                  value={brand}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  required
                  id="outlined-basic"
                  label="Enter Item id"
                  name="itemId"
                  value={itemId}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  placeholder="Type Description..."
                  name="description"
                  value={description}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    borderRadius: "5px",
                    padding: 10,
                    height: 118,
                    overflow: "auto",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="error"
                  size="small"
                  type="button"
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  size="small"
                  type="submit"
                  loading={addLoad}
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </TabPanel>
      )}
    </Wrapper>
  );
};

export default Item;
