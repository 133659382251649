import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import "../../../styles/components/Horizontal/Horizontal.scss";

const defaultPopupData = {
  title: "",
  text: "",
};

const SplashNewsHorizontal = ({ horizontalData = defaultPopupData }) => {
  let text = horizontalData.map((d) => `${d.text}`);

  if (window.location.pathname !== "/") return null;
  return (
    <div className="hwrap">
      <div className="hitem">
        <marquee direction="left">
          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                color: "#fff",
              }}
            >
              {text.join(" || ")}
            </Typography>
          </Box>
        </marquee>
      </div>
    </div>
  );
};

export default SplashNewsHorizontal;
