import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const styles = {
  cardsMargin: {
    // margin: "15px 0",
  },
  cardStyle: {
    margin: "15px 0",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginTop: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    // label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
    marginLeft: "5px",
    marginTop: "5px",
  },
  addbutton: {
    // paddingTop: "6.2px",
    // paddingBottom: "6.2px",
    // marginBottom: "4px",
  },
};
const Firm = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [open, setOpen] = React.useState(false);
  const [editFirm, setEditFirm] = useState();
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ====================================

  const [sort, setSort] = useState({
    firmName: "asc",
  });

  const sortByFirmName = () => {
    if (sort.firmName === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.firmName.localeCompare(a.firmName)),
      ];
      setList(sortedList);
      setSort({ ...sort, firmName: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.firmName.localeCompare(b.firmName)),
      ];
      setList(sortedList);
      setSort({ ...sort, firmName: "asc" });
    }
  };

  useEffect(() => {
    getFirm();
  }, [selectedSetting]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditFirm(null);
    setAddForm({});
    setOpen(false);
  };

  const getFirm = async () => {
    setList([]);
    try {
      const { data, status } = await get(urls.firm.getList, {
        headers: { roleFunction: "maintainance" },
      });
      setList(data.data);
      console.log(data.data, "llklk");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editFirm) {
        const res = await post(
          `${urls.firm.create}`,
          {
            firmName: addForm.firmName,
            phoneNumber: addForm.phoneNumber,
            email: addForm.email,
            GSTNumber: addForm.GSTNumber,
            vendorWebsite: addForm.vendorWebsite,
            address: addForm.address,
            city: addForm.city,
            state: addForm.state,
            zipCode: addForm.zipCode,
          },
          { headers: { roleFunction: "maintainance" } }
        );
        if (res.data.success) {
          getFirm();
          handleClose();
        }
      } else {
        const res = await put(
          `${urls.firm.update}${editFirm}`,
          "",
          {
            firmName: addForm.firmName,
            phoneNumber: addForm.phoneNumber,
            email: addForm.email,
            GSTNumber: addForm.GSTNumber,
            vendorWebsite: addForm.vendorWebsite,
            address: addForm.address,
            city: addForm.city,
            state: addForm.state,
            zipCode: addForm.zipCode,
          },
          { headers: { roleFunction: "maintainance" } }
        );
        if (res.data.success) {
          getFirm();
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleFirmEdit = (id, data) => {
    setEditFirm(id);
    setAddForm({
      firmName: data.firmName,
      nameSmall: data.nameSmall,
      phoneNumber: data.phoneNumber,
      email: data.email,
      GSTNumber: data.GSTNumber,
      vendorWebsite: data.vendorWebsite,
      address: data.address,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.firm.delFirm}`,
        id,
        {},
        { roleFunction: "maintainance" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
        getFirm();
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end" mb={2}>
        <Grid item xs="auto">
          <Button
            variant="contained"
            style={styles.addbutton}
            onClick={handleClickOpen}
          >
            <AddIcon />
            Add
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #Sl
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByFirmName} className="class-table-header">
                  Name
                  <span style={{ cursor: "pointer" }}>
                    {sort.firmName === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Phone Number
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                Email
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((firm, index) => (
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{firm.firmName}</TableCell>
                <TableCell align="center">{firm.phoneNumber}</TableCell>
                <TableCell align="center">{firm.email}</TableCell>
                <TableCell align="center">
                  <div className="edit-delete">
                    <Tooltip title="Update">
                      <IconButton
                        size="small"
                        style={{ color: "#1b3779" }}
                        onClick={() => handleFirmEdit(firm._id, firm)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => setDeleteModal(firm._id, firm)}
                      >
                        <Delete fontSize="small" color="error" />
                      </IconButton>
                    </Tooltip>
                    <DeleteModal
                      deleteModal={deleteModal}
                      handleDelete={handleDeleteone}
                      id={firm._id}
                      setDeleteModal={setDeleteModal}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!list.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start,",
          alignItems: "center",
          my: 1,
        }}
      />
      {/*================= Add/Update Firm ========= */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontWeight="bold"
                  fontSize="20px"
                  onChange={handleAddForm}
                >
                  {editFirm ? "Update Firm" : "Add Firm"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="Name"
                  name="firmName"
                  onChange={handleAddForm}
                  value={addForm.firmName || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="number"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="PhoneNumber"
                  name="phoneNumber"
                  onChange={handleAddForm}
                  value={addForm.phoneNumber || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="Email"
                  name="email"
                  onChange={handleAddForm}
                  value={addForm.email || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="GSTNumber"
                  name="GSTNumber"
                  onChange={handleAddForm}
                  value={addForm.GSTNumber || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="VendorWebsite"
                  name="vendorWebsite"
                  onChange={handleAddForm}
                  value={addForm.vendorWebsite || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="Address"
                  name="address"
                  onChange={handleAddForm}
                  value={addForm.address || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="City"
                  name="city"
                  onChange={handleAddForm}
                  value={addForm.city || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="State"
                  name="state"
                  onChange={handleAddForm}
                  value={addForm.state || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  // sx={{ m: 1, minWidth: 227 }}
                  label="ZipCode"
                  name="zipCode"
                  onChange={handleAddForm}
                  value={addForm.zipCode || ""}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    mt: 1,
                    justifyContent: { md: "right", sm: "right", xs: "center" },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    size="small"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    {editFirm ? "Update" : "Add"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      {/* ======================== */}
    </>
  );
};
export default Firm;
