import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "../../styles/HostelTable/HostelTable.scss";
import {
  Box,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TableContainer,
  TablePagination,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Search } from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import dayjs from "dayjs";
import ShowComponent from "../ShowComponent";
import Wrapper from "../Wrapper";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Hostel = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editHostel, setEditHostel] = useState();
  const [addForm, setAddForm] = useState({});
  const [hostel, setHostel] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [type, setType] = React.useState("");
  const [list, setList] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const [sort, setSort] = useState({
    name: "asc",
    type: "asc",
  });
  const sortByTitle = () => {
    if (sort.name === "asc") {
      const sortedList = [...list.sort((a, b) => b.name.localeCompare(a.name))];
      setList(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.name.localeCompare(b.name))];
      setList(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByType = () => {
    if (sort.type === "asc") {
      const sortedList = [...list.sort((a, b) => b.type.localeCompare(a.type))];
      setList(sortedList);
      setSort({ ...sort, type: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.type.localeCompare(b.type))];
      setList(sortedList);
      setSort({ ...sort, type: "asc" });
    }
  };
  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  useEffect(() => {
    getHostel();
  }, [selectedSetting]);
  useEffect(() => {
    const getList = async () => {
      try {
        const getEmps = await get(urls.hostel.getList, {
          headers: { roleFunction: "hostel" },
        });
        setList(getEmps.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getList();
  }, [selectedSetting]);

  // useEffect(() => {
  //   const getWarden = async () => {
  //     try {
  //       const { data: role, status } = await get(`${urls.role.getAllRole}`, {
  //         headers: { roleFunction: "hostel" },
  //         params: {
  //           search: {
  //             roleName: "WARDEN",
  //           },
  //         },
  //       });
  //       console.log(role, "oooooooooooooooooooooooooooooooooooooooooooooo");
  //       if (status > 199 && status < 299) {
  //         const { data: employees, status } = await get(
  //           `${urls.employee.getAllEmployee}`,
  //           {
  //             headers: { roleFunction: "hostel" },
  //             params: {
  //               search: {
  //                 roleName: "WARDEN",
  //               },
  //             },
  //           }
  //         );
  //         console.log(
  //           employees,
  //           "rolesssssssssssssssssssssssssssssssssssssssssssssssssss++++++++++++++++++++++++++++++++++"
  //         );
  //         if (status > 199 && status < 299) {
  //           setEmployees(employees.data);
  //           console.log(employees.data, "warden");
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getWarden();
  // }, [selectedSetting]);
  useEffect(() => {
    const getWarden = async () => {
      try {
        const { data: roleData, status: roleStatus } = await get(
          `${urls.role.getAllRole}`,
          {
            headers: { roleFunction: "hostel" },
            params: {
              search: {
                roleName: "WARDEN",
              },
            },
          }
        );

        if (roleStatus > 199 && roleStatus < 299) {
          const wardenRole = roleData.data[0];

          const { data: employees, status } = await get(
            `${urls.employee.getAllEmployee}`,
            {
              headers: { roleFunction: "hostel" },
              params: {
                search: {
                  roleName: "WARDEN",
                },
              },
            }
          );

          if (status > 199 && status < 299) {
            const warden = employees.data.filter((employee) =>
              employee.role.includes(wardenRole._id)
            );

            setEmployees(warden);
            console.log(warden, "warden employees");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getWarden();
  }, [selectedSetting]);

  const getHostel = async () => {
    try {
      const { data: hostels, status } = await get(`${urls.hostel.getList}`, {
        headers: { roleFunction: "hostel" },
      });

      setList(hostels.data);
      console.log(hostels.data, "hostellls");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.hostel.delHostel}`,
        id,
        {},
        { roleFunction: "hostel" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditHostel(null);
    setAddForm({});
    setOpen(false);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickOpenDialog = (hostel) => {
    setShowDetails(hostel);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
    setAddForm({});
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    try {
      let res;

      if (!editHostel) {
        res = await post(
          `${urls.hostel.create}`,
          {
            name: addForm.name,
            type: addForm.type,
            warden: addForm.warden,
            note: addForm.note,
            address: addForm.address,
            contactNumber: addForm.contactNumber,
          },
          { headers: { roleFunction: "hostel" } }
        );
      } else {
        res = await put(
          `${urls.hostel.editHostel}${editHostel}`,
          "",
          {
            name: addForm.name,
            type: addForm.type,
            warden: addForm.warden,
            note: addForm.note,
            address: addForm.address,
            contactNumber: addForm.contactNumber,
          },
          { headers: { roleFunction: "hostel" } }
        );
      }

      if (res.data.success) {
        handleClose();

        getHostel();
        setLoader(false);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },

    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleClassMapEdit = (id, data) => {
    setEditHostel(id);
    setAddForm({
      name: data.name,
      type: data.type,
      warden: data.warden._id,
      note: data.note,
      address: data.address,
      contactNumber: data.contactNumber,
    });
    handleClickOpen();
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Hostel" />
      <div style={{ margin: "20px 0" }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Grid item xs="auto" mb={2}>
            <TextField
              size="small"
              style={styles.textfield}
              value={search}
              onChange={handleSearch}
              label="Search by Hostel name"
              placeholder="Enter Hostel Name"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen={fullScreen}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <form onSubmit={handleAddSubmit}>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    {editHostel ? "Update Hostel" : "Add Hostel"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Hostel Name"
                    name="name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small" required>
                    <TextField
                      fullWidth
                      required
                      id="filled-required"
                      size="small"
                      type="number"
                      label="Phone No"
                      name="contactNumber"
                      onChange={handleAddForm}
                      value={addForm.contactNumber || ""}
                      sx={{
                        // minWidth: { md: 227, xs: "100%" },
                        label: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 8 }}>Hostel Type</InputLabel>
                    <Select
                      label="Hostel Type"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="type"
                      value={addForm.type || ""}
                      onChange={handleAddForm}
                    >
                      <MenuItem value={"Boys"}>Boys</MenuItem>
                      <MenuItem value={"Girls"}>Girls</MenuItem>
                      <MenuItem value={"Combined"}>Combined</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 8 }}>Warden</InputLabel>
                    <Select
                      label="Warden"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="warden"
                      required
                      value={addForm.warden || ""}
                      onChange={handleAddForm}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee._id} value={employee._id}>
                          {employee.basicInfo.empName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Address"
                    name="address"
                    value={addForm.address || ""}
                    onChange={handleAddForm}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    gap: "1%",
                    alignItems: "center",
                    justifyContent: { md: "right", sm: "right", xs: "center" },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    type="button"
                    color="error"
                    sx={{ m: 1, color: "#fff" }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    {editHostel ? "Update" : "Add"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByTitle} className="class-table-header">
                    Hostel Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByType} className="class-table-header">
                    Hostel Type
                    <span style={{ cursor: "pointer" }}>
                      {sort.type === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Address
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((hostel, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{hostel.name}</TableCell>
                          <TableCell align="center">{hostel.type}</TableCell>
                          <TableCell align="center">{hostel.address}</TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip title="View">
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    handleClickOpenDialog(hostel._id)
                                  }
                                >
                                  <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                              <ShowComponent
                                roleFunction="hostel"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    style={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleClassMapEdit(hostel._id, hostel)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="hostel"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      setDeleteModal(hostel._id, hostel)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={hostel._id}
                                  setDeleteModal={setDeleteModal}
                                />
                              </ShowComponent>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((hostel, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{hostel.name}</TableCell>
                          <TableCell align="center">{hostel.type}</TableCell>
                          <TableCell align="center">{hostel.address}</TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip title="View">
                                <IconButton
                                  color="primary"
                                  onClick={() => handleClickOpenDialog(hostel)}
                                >
                                  <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                              <ShowComponent
                                roleFunction="hostel"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    style={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleClassMapEdit(hostel._id, hostel)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>

                              <ShowComponent
                                roleFunction="hostel"
                                action="update"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      setDeleteModal(hostel._id, hostel)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={hostel._id}
                                  setDeleteModal={setDeleteModal}
                                />
                              </ShowComponent>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!list.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />
        {/* Add Hostel=========== */}
        <Box className="add-icon">
          <ShowComponent roleFunction="hostel" action="add">
            <Tooltip title="Add Hostel">
              <Fab
                variant="contained"
                sx={{
                  color: "#ffff",

                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                onClick={handleClickOpen}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </ShowComponent>
        </Box>
        {/* ==================== */}
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Hostel Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td>Hostel Name</td>
                      <td>{showDetails.name}</td>
                    </tr>
                    <tr>
                      <td>Hostel Type</td>
                      <td>{showDetails.type}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{showDetails.address}</td>
                    </tr>
                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>
                        {" "}
                        {dayjs(showDetails.createdAt).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </Wrapper>
  );
};
export default Hostel;
