import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  useTheme,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tabs,
  TextField,
  TextareaAutosize,
  Typography,
  IconButton,
  Paper,
  TableContainer,
  Tooltip,
} from "@mui/material";
import React from "react";
import SectionsSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";
import { useContext } from "react";
import { useEffect } from "react";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const OffBoarding = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [dob, setDob] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [joinDate, setJoindate] = useState("");
  const [resignDate, setResigndate] = useState("");
  const [relivingDate, setRelivingDate] = useState("");
  const [showEditTab, setShowEditTab] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [selectrole, setSelectRole] = useState("");
  const [role, setRole] = useState([]);
  const [list, setList] = useState([]);
  const [editId, setEditId] = useState();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [employee, setEmployee] = useState([]);
  const [page, setPage] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [formData, setFormData] = useState({
    userType: "",
    user: "",
    name: "",
    gender: "",
    bloodGroup: "",
    status: "",
    feedback: "",
    documentInformation: [],
  });
  const [fileName, setFileName] = useState("");
  const [formFields, setFormFields] = useState([{ name: "", file: null }]);
  const [editFormdata, setEditFormdata] = useState({});
  const theme = useTheme();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    getListOffBoarding();
  }, [selectedSetting]);

  const getListOffBoarding = async () => {
    try {
      const res = await get(urls.OffBoarding.getList, {
        headers: { roleFunction: "offboarding" },
      });
      setList(res.data.result);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleInputChange = (e, index) => {
  //   const { name, value, files } = e.target;
  //   const selectedFile = files[0];

  //   setFormFields((prevFields) => {
  //     const newFields = [...prevFields];
  //     newFields[index] = {
  //       ...newFields[index],
  //       [name]: name === "file" ? selectedFile : value,
  //     };
  //     return newFields;
  //   });

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     documents: formFields.map((field) => ({
  //       name: field.name,
  //       file: field.file,
  //     })),
  //   }));

  //   setFileName(selectedFile ? selectedFile.name : "");
  // };

  const handleInputChange = (e, index) => {
    const { name, value, files } = e.target;
    const selectedFile = files ? files[0] : null;

    setFormFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index] = {
        ...newFields[index],
        [name]: name === "file" ? selectedFile : value,
      };
      return newFields;
    });

    // Using FormData to handle files
    const updatedFormData = new FormData();
    formFields.forEach((field, i) => {
      updatedFormData.append(`name_${i}`, field.name);
      updatedFormData.append(`file_${i}`, field.file);
    });

    setFormData(updatedFormData);
    setFileName(selectedFile ? selectedFile.name : "");
  };

  const handleAddRow = () => {
    setFormFields([...formFields, { name: "", file: "" }]);
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.OffBoarding.del}`,
        id,
        {},
        { roleFunction: "offboarding" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
        getListOffBoarding();
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (date) => {
    setDob(date);
  };

  const handleDateChangeJoining = (date) => {
    setJoindate(date);
  };
  const handleDateChangeResing = (data) => {
    setResigndate(data);
  };

  const handleDateChangeReliving = (data) => {
    setRelivingDate(data);
  };

  const handleOnChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRoleChange = (event) => {
    const selectedRoleId = event.target.value;
    const filteredEmployees = employee.filter(
      (emp) => emp.role === selectedRoleId
    );
    setFormData({ ...formData, userType: selectedRoleId, user: "" });
    setFilteredEmployees(filteredEmployees);
  };

  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const handleRemove = (index) => {
    const list = [...formFields];
    list.splice(index, 1);
    setFormFields(list);
  };

  const handleTabChange = (event, newValue) => {
    if (tabValue === 2 && newValue === 0) {
      setTabValue(newValue);
      setFormData({
        userType: "",
        user: "",
        name: "",
        gender: "",
        bloodGroup: "",
        status: "",
        feedback: "",
        documentInformation: [],
      });
      setFileName("");
      setFormFields([{ name: "", file: null }]);
      setEditFormdata({});
      setEditId(null);
    } else {
      setTabValue(newValue);
    }
    if (newValue === 1) {
      setFormData({
        userType: "",
        user: "",
        name: "",
        gender: "",
        bloodGroup: "",
        status: "",
        feedback: "",
        documentInformation: [],
      });
      setFileName("");
      setFormFields([{ name: "", file: null }]);
      setEditFormdata({});
      setEditId(null);
    }
  };

  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole, {
          headers: { roleFunction: "offboarding" },
        });
        setRole(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, [selectedSetting]);

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const res = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "offboarding" },
        });
        setEmployee(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployee();
  }, [selectedSetting]);

  const handleSubmitBoarding = async (e) => {
    e.preventDefault();
    setAddLoader(true);
    try {
      if (editId) {
        const res = await put(
          `${urls.OffBoarding.update}${editId}`,
          "",
          {
            name: formData.name,
            Gender: formData.gender,
            bloodGroup: formData.bloodGroup,
            status: formData.status,
            feedback: formData.feedback,
            birthDate: dob,
            joiningDate: joinDate,
            resignDate: resignDate,
            relievingDate: relivingDate,
            documentInformation: formData.documents,
          },
          { headers: { roleFunction: "offboarding" } }
        );
        if (res.data.success) {
          getListOffBoarding();
          setTabValue(0);
          setEditId(null);
          setAddLoader(false);
        }
      } else {
        const res = await post(
          `${urls.OffBoarding.create}`,
          {
            userType: formData.userType,
            user: formData.user,
            name: formData.name,
            Gender: formData.gender,
            bloodGroup: formData.bloodGroup,
            status: formData.status,
            feedback: formData.feedback,
            birthDate: dob,
            joiningDate: joinDate,
            resignDate: resignDate,
            relievingDate: relivingDate,
            documentInformation: formData.documents,
          },
          { headers: { roleFunction: "offboarding" } }
        );
        if (res.data.success) {
          getListOffBoarding();
          setTabValue(0);
          setEditId(null);
          setAddLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setAddLoader(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await get(urls.OffBoarding.getList, {
        headers: { roleFunction: "offboarding" },
      });
      const responseData = res.data.result;
      const selectedEmployeeId = formData.user;
      const filterData = responseData.filter(
        (employee) => employee.user === selectedEmployeeId
      );
      setFormData({
        ...formData,
        name: filterData[0].name,
        gender: filterData[0].Gender,
        feedback: filterData[0].feedback,
        bloodGroup: filterData[0].bloodGroup,
        status: filterData[0].status,
      });
      setDob(filterData[0].birthDate);
      setJoindate(filterData[0].joiningDate);
      setRelivingDate(filterData[0].relievingDate);
      setResigndate(filterData[0].resignDate);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleEditBoarding = (id, item) => {
    setEditId(id);
    setFormData({
      userType: item.userType,
      user: item.user,
      name: item.name,
      gender: item.Gender,
      bloodGroup: item.bloodGroup,
      status: item.status,
      feedback: item.feedback,
    });
    setDob(item.birthDate);
    setJoindate(item.joinDate);
    setRelivingDate(item.relivingDate);
    setResigndate(item.resignDate);
    setTabValue(2);
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Off-Boarding" />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "15px 0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Off-Boarding List" {...a11yProps(0)} />
          <Tab label="Add Off-Boarding" {...a11yProps(1)} />
          {editId && <Tab label="Edit Off-Boarding" {...a11yProps(2)} />}
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }} align="center">
                  #sl
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Joining Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, i) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell align="center">{data.name}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.joiningDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <ShowComponent
                          roleFunction="offboarding"
                          action="update"
                        >
                          <Tooltip title="Update">
                            <IconButton
                              size="small"
                              style={{ color: "#1b3779" }}
                              onClick={(e) =>
                                handleEditBoarding(data._id, data)
                              }
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <ShowComponent
                          roleFunction="offboarding"
                          action="delete"
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => setDeleteModal(data._id)}
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>

                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {list.length == 0 && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl required fullWidth size="small">
                <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="userType"
                  label="UserType"
                  value={formData.userType}
                  onChange={handleRoleChange}
                >
                  {role &&
                    role.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.roleName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl required fullWidth size="small">
                <InputLabel id="demo-simple-select-label">User</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="user"
                  label="User"
                  value={formData.user}
                  onChange={(e) =>
                    setFormData({ ...formData, user: e.target.value })
                  }
                >
                  {filteredEmployees &&
                    filteredEmployees.map((row) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.basicInfo.empName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <LoadingButton
                loading={loader}
                variant="contained"
                onClick={handleSubmit}
                type="submit"
                sx={{
                  mt: 0.5,

                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                size="small"
              >
                Find
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
            Basic Information
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="name"
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl required fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="gender"
                  value={formData.gender}
                  label="gender"
                  onChange={handleOnChangeForm}
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>FeMale</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl required fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Blood Group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="bloodGroup"
                  value={formData.bloodGroup}
                  onChange={handleOnChangeForm}
                  label="bloodGroup"
                  variant="outlined"
                >
                  <MenuItem value={"a+"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    A+
                  </MenuItem>
                  <MenuItem value={"a-"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    A-
                  </MenuItem>
                  <MenuItem value={"b+"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    B+
                  </MenuItem>
                  <MenuItem value={"b-"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    B-
                  </MenuItem>
                  <MenuItem value={"o+"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    O+
                  </MenuItem>
                  <MenuItem value={"o-"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    O-
                  </MenuItem>
                  <MenuItem
                    value={"ab+"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    AB+
                  </MenuItem>
                  <MenuItem
                    value={"ab-"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    AB-
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl>
                <TextField
                  fullWidth
                  size="small"
                  name="dob"
                  type="date"
                  // value={dates}
                  value={dayjs(dob).format("YYYY-MM-DD")}
                  onChange={(e) => handleDateChange(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
            Academic Information
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <InputLabel sx={{ fontWeight: "bold", mb: 1 }}>
                Joining Date:
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  size="small"
                  name="joinDate"
                  type="date"
                  // value={dates}
                  value={dayjs(joinDate).format("YYYY-MM-DD")}
                  onChange={(e) => handleDateChangeJoining(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <InputLabel sx={{ fontWeight: "bold", mb: 1 }}>
                Resign Date:
              </InputLabel>

              <TextField
                fullWidth
                size="small"
                name="resignDate"
                type="date"
                // value={dates}
                value={dayjs(resignDate).format("YYYY-MM-DD")}
                onChange={(e) => handleDateChangeResing(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <InputLabel sx={{ fontWeight: "bold", mb: 1 }}>
                Reliving Date:
              </InputLabel>

              <TextField
                fullWidth
                size="small"
                name="relivingDate"
                type="date"
                value={dayjs(relivingDate).format("YYYY-MM-DD")}
                onChange={(e) => handleDateChangeReliving(e.target.value)}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
            Profile Information
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl required fullWidth size="small">
                <InputLabel id="demo-simple-select-label">status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={formData.status}
                  onChange={handleOnChangeForm}
                  label="status"
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              <TextareaAutosize
                placeholder="feedback"
                name="feedback"
                value={formData.feedback}
                onChange={handleOnChangeForm}
                fullWidth
                style={{
                  width: "100%",
                  maxHeight: 110,

                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 2 }}>
            Additional Document Information
          </Typography>

          {formFields.map((field, index) => (
            <Grid
              container
              spacing={2}
              key={index}
              sx={{
                backgroundColor: theme.palette.grey[100],
                borderRadius: "5px",
                padding: "0 15px 20px 5px",
                ml: 0.1,
                mb: 1,

                border: "1px solid lightgrey",
              }}
            >
              <Grid item xs={5} md={4} lg={5}>
                <InputLabel sx={{ fontWeight: "bold" }}>Name:</InputLabel>
                <TextField
                  fullWidth
                  name="name"
                  size="small"
                  onChange={(e) => handleInputChange(e, index)}
                  variant="outlined"
                  value={field.name}
                />
              </Grid>

              <Grid item xs={5} md={6} lg={5}>
                <InputLabel sx={{ fontWeight: "bold" }}>
                  Choose File:
                </InputLabel>
                <Button
                  component="label"
                  variant="outlined"
                  fullWidth
                  sx={{
                    padding: "7px",
                    maxWidth: 400,
                  }}
                  startIcon={<FileUploadIcon fontSize="small" />}
                  size="small"
                >
                  Choose File
                  <input
                    hidden
                    type="file"
                    name="file"
                    onChange={(e) => handleInputChange(e, index)}
                    style={{ display: "block" }}
                  />
                </Button>
                <Typography>{fileName}</Typography>
              </Grid>
              {/* <Typography>{fileName}</Typography> */}

              <Grid item xs={1} md={1} lg={1} sx={{ mt: 3 }}>
                <Box onClick={() => handleRemove(index)}>
                  <ClearIcon color="error" />
                </Box>
              </Grid>
            </Grid>
          ))}
          <ShowComponent roleFunction="offerletter" action="add">
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 2 }}
              onClick={handleAddRow}
              startIcon={<AddIcon />}
            >
              ADD
            </Button>
          </ShowComponent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button variant="contained" color="error" size="small">
              Cancel
            </Button>
            <LoadingButton
              loading={addLoader}
              variant="contained"
              type="submit"
              sx={{
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleSubmitBoarding}
              size="small"
            >
              {editId ? "update" : "Add"}
            </LoadingButton>
          </Box>
        </Card>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
            Basic Information
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="name"
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl required fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="gender"
                  value={formData.gender}
                  label="gender"
                  onChange={handleOnChangeForm}
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>FeMale</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl required fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Blood Group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="bloodGroup"
                  value={formData.bloodGroup}
                  onChange={handleOnChangeForm}
                  label="bloodGroup"
                  variant="outlined"
                >
                  <MenuItem value={"a+"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    A+
                  </MenuItem>
                  <MenuItem value={"a-"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    A-
                  </MenuItem>
                  <MenuItem value={"b+"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    B+
                  </MenuItem>
                  <MenuItem value={"b-"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    B-
                  </MenuItem>
                  <MenuItem value={"o+"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    O+
                  </MenuItem>
                  <MenuItem value={"o-"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    O-
                  </MenuItem>
                  <MenuItem
                    value={"ab+"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    AB+
                  </MenuItem>
                  <MenuItem
                    value={"ab-"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    AB-
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl>
                <TextField
                  fullWidth
                  size="small"
                  name="dob"
                  type="date"
                  // value={dates}
                  value={dayjs(dob).format("YYYY-MM-DD")}
                  onChange={(e) => handleDateChange(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
            Academic Information
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <InputLabel sx={{ fontWeight: "bold", mb: 1 }}>
                Joining Date:
              </InputLabel>

              <TextField
                fullWidth
                size="small"
                name="joinDate"
                type="date"
                // value={dates}
                value={dayjs(joinDate).format("YYYY-MM-DD")}
                onChange={(e) => handleDateChangeJoining(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <InputLabel sx={{ fontWeight: "bold", mb: 1 }}>
                Resign Date:
              </InputLabel>

              <TextField
                fullWidth
                size="small"
                name="resignDate"
                type="date"
                // value={dates}
                value={dayjs(resignDate).format("YYYY-MM-DD")}
                onChange={(e) => handleDateChangeResing(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <InputLabel sx={{ fontWeight: "bold", mb: 1 }}>
                Reliving Date:
              </InputLabel>

              <TextField
                fullWidth
                size="small"
                name="relivingDate"
                type="date"
                value={dayjs(relivingDate).format("YYYY-MM-DD")}
                onChange={(e) => handleDateChangeReliving(e.target.value)}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
            Profile Information
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl required fullWidth size="small">
                <InputLabel id="demo-simple-select-label">status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={formData.status}
                  onChange={handleOnChangeForm}
                  label="status"
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              <TextareaAutosize
                placeholder="feedback"
                name="feedback"
                value={formData.feedback}
                onChange={handleOnChangeForm}
                fullWidth
                style={{
                  width: "100%",
                  maxHeight: 110,

                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
            Additional Document Information
          </Typography>

          {formFields.map((field, index) => (
            <Grid
              container
              spacing={1}
              key={index}
              sx={{
                marginBottom: "10px",

                backgroundColor: theme.palette.grey[100],
                borderRadius: "5px",
                padding: "0 15px 20px 15px",

                border: "1px solid lightgrey",
              }}
            >
              <Grid item xs={6} md={6} lg={5}>
                <InputLabel sx={{ fontWeight: "bold" }}>Name:</InputLabel>
                <TextField
                  fullWidth
                  name="name"
                  size="small"
                  onChange={(e) => handleInputChange(e, index)}
                  variant="outlined"
                  value={field.name}
                />
              </Grid>

              <Grid item xs={6} md={6} lg={5}>
                <InputLabel sx={{ fontWeight: "bold" }}>
                  Choose File:
                </InputLabel>
                <Button
                  component="label"
                  variant="outlined"
                  fullWidth
                  sx={{
                    padding: "7px",
                    maxWidth: 400,
                  }}
                  startIcon={<FileUploadIcon fontSize="small" />}
                  size="small"
                >
                  Choose File
                  <input
                    hidden
                    type="file"
                    name="file"
                    onChange={(e) => handleInputChange(e, index)}
                    style={{ display: "block" }}
                  />
                </Button>
                <Typography>{fileName}</Typography>
              </Grid>
              {/* <Typography>{fileName}</Typography> */}

              <Grid item xs={1} md={1} lg={1} sx={{ mt: 3 }}>
                <Box onClick={() => handleRemove(index)}>
                  <ClearIcon color="error" />
                </Box>
              </Grid>
            </Grid>
          ))}
          <ShowComponent roleFunction="offboarding" action="add">
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 2 }}
              onClick={handleAddRow}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </ShowComponent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button variant="contained" color="error" size="small">
              Cancel
            </Button>
            <LoadingButton
              loading={addLoader}
              variant="contained"
              type="submit"
              sx={{
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleSubmitBoarding}
              size="small"
            >
              Update
            </LoadingButton>
          </Box>
        </Card>
      </TabPanel>
    </Wrapper>
  );
};

export default OffBoarding;
