import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, useContext, useRef } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SquareIcon from "@mui/icons-material/Square";
import SectionSubHeader from "../../SectionSubHeader";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { red } from "@mui/material/colors";
import { LoadingButton } from "@mui/lab";
import Wrapper from "../../Wrapper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  p: 4,
  overflowY: "scroll",
};

const styles = {
  cardsMargin: {
    marginTop: "15px",
  },
  cardStyle: {
    margin: "15px",
    padding: "10px",
  },

  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
    marginTop: "15px",
  },
  card1: {
    marginBottom: 20,
    marginTop: "15px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LibraryReport = () => {
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [findload, setFindLoad] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [toDate, setToDate] = useState(Date.now());
  const [academicYears, setAcademicYears] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const colors = ["#FFBB28", "#00C49F", "#32CD32"];
  const [groupBy, setGroupBy] = useState("");
  const [findData, setFindData] = useState([]);
  const [gfromDate, setGFromDate] = useState(Date.now());
  const [gtoDate, setGToDate] = useState(Date.now());
  const [graphLoad, setGraphLoad] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [graphData, setGraphData] = useState(false);
  const { selectedSetting } = useContext(SettingContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleGroupByChange = (event) => {
    const selectedGroupBy = event.target.value;
    setGroupBy(selectedGroupBy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getColor = (index) => colors[index % colors.length];
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "reports" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFind = async (e) => {
    e.preventDefault();
    setFindLoad(true);
    try {
      const response = await get(`${urls.report.libraryReport}`, {
        headers: { roleFunction: "reports" },
        params: {
          search: {
            groupBy: groupBy,
            fromDate: fromDate,
            toDate: toDate,
          },
        },
      });
      setFindData(response.data.data);
      console.log(response.data.data, "tekjkkkkr");
    } catch (error) {
      console.log(error);
    }
    setFindLoad(false);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePdfDownload = async (e) => {
    e.preventDefault();
    setPdfLoading(true);
    try {
      const getdata = await get(
        `${urls.report.libraryReportDownload}?groupBy=${groupBy}&fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: { roleFunction: "reports" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getdata.data);
      window.open(uri, "_blank");
    } catch (error) {
      console.log(error);
    }
    setPdfLoading(false);
  };

  const handleGraphFind = async (e) => {
    e.preventDefault();
    setGraphLoad(true);
    try {
      const response = await get(`${urls.report.libraryReportGraph}`, {
        headers: { roleFunction: "reports" },
        params: {
          search: {
            fromDate: gfromDate,
            toDate: gtoDate,
          },
        },
      });
      setPieData([
        { name: "issue", value: response.data.data.issue },
        { name: "return", value: response.data.data.returnData },
        { name: "remain", value: response.data.data.totalBalance },
      ]);
      setGraphData(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setGraphLoad(false);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Library Report" />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Tabular Report" {...a11yProps(0)} />
          <Tab label="Grapical Report" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Card sx={{ padding: 2, mt: 1, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel>Academic Year</InputLabel>
                <Select
                  label="Academic Year"
                  id="demo-simple-select-filled"
                  name="academicYear"
                  variant="outlined"
                  value={formData.academicYear || ""}
                  onChange={handleOnChange}
                  size="small"
                >
                  {academicYears &&
                    academicYears.map((row, index) => (
                      <MenuItem key={row._id} value={row._id}>
                        {row.academicYearFrom} - {row.academicYearTo}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <FormControl fullWidth size="small">
                <InputLabel required sx={{ fontSize: 12 }}>
                  Group By Data
                </InputLabel>
                <Select
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  label="Group By Data"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={groupBy}
                  onChange={handleGroupByChange}
                >
                  <MenuItem value={"daily"}>Daily</MenuItem>
                  <MenuItem value={"weekly"}>Weekly</MenuItem>
                  <MenuItem value={"monthly"}>Monthly</MenuItem>
                  <MenuItem value={"class"}>Class</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={3} alignSelf="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="From Date"
                    inputFormat="DD-MM-YYYY"
                    value={fromDate || ""}
                    onChange={setFromDate}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4} lg={3} alignSelf="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="To Date"
                    value={toDate || ""}
                    onChange={setToDate}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <LoadingButton
                variant="contained"
                loading={findload}
                size="small"
                onClick={handleFind}
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                }}
              >
                Find
              </LoadingButton>
              <LoadingButton
                loading={pdfLoading}
                variant="contained"
                size="small"
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                }}
                onClick={handlePdfDownload}
              >
                Print
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Group By Data
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue Total
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Return Total
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Remain Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {findData?.groupByData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <TableRow>
                    <TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </TableCell>

                    <TableCell align="center">{data.groupBy}</TableCell>
                    <TableCell align="center">{data.issue}</TableCell>
                    <TableCell align="center">{data.returnData}</TableCell>
                    <TableCell align="center">{data.balance}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!findData.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={findData?.groupByData?.length || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Card style={styles.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel>Academic Year</InputLabel>
                <Select
                  fullWidth
                  label="Academic Year"
                  id="demo-simple-select-filled"
                  name="academicYear"
                  variant="outlined"
                  value={formData.academicYear || ""}
                  onChange={handleOnChange}
                  size="small"
                >
                  {academicYears &&
                    academicYears.map((row, index) => (
                      <MenuItem key={row._id} value={row._id}>
                        {row.academicYearFrom} - {row.academicYearTo}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="From Date"
                    inputFormat="DD-MM-YYYY"
                    value={gfromDate || ""}
                    onChange={setGFromDate}
                    renderInput={(params) => (
                      <TextField variant="outlined" size="small" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="To Date"
                    value={gtoDate || ""}
                    onChange={setGToDate}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField variant="outlined" size="small" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <LoadingButton
                variant="contained"
                loading={graphLoad}
                size="small"
                onClick={handleGraphFind}
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                }}
              >
                Find
              </LoadingButton>

              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    size="small"
                    style={styles.Button}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Grid>
          </Grid>
        </Card>

        <div ref={componentRef}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card sx={{ minWidth: 350, width: 100, maxWidth: 420, mt: 3 }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <img src={selectedSetting.logo.link} height={30} width={30} /> */}
                  <Typography
                    gutterBottom
                    sx={{ color: "red", fontSize: "7px", mt: 1.2, ml: 0.5 }}
                  >
                    {selectedSetting.schoolName}
                  </Typography>
                </div>
                <Typography
                  gutterBottom
                  variant="h7"
                  sx={{ textAlign: "center", mt: 2 }}
                  component="div"
                >
                  {selectedSetting.schoolName}
                </Typography>
                <Typography
                  variant="h11"
                  sx={{ textAlign: "center" }}
                  component="div"
                >
                  {selectedSetting.address}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3px",
                  }}
                >
                  <AssessmentIcon style={{ color: "#1b3779" }} />
                  <Typography
                    style={{ color: "red" }}
                    variant="h8"
                    component="div"
                  >
                    Libary Report
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PieChart width={1000} height={380}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={pieData}
                outerRadius={150}
                label
              >
                {pieData.map((entry, index) => (
                  <Cell key={index} fill={colors[index % colors.length]} />
                ))}
              </Pie>

              <Tooltip />
            </PieChart>
          </div>
          {pieData.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SquareIcon
                style={{
                  color: "#FFBB28",
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
              <p>Return</p>
              <SquareIcon
                style={{
                  color: "#00C49F",
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
              <p> Issue</p>
              <SquareIcon
                style={{
                  color: "#32CD32",
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
              <p>Remain</p>
            </div>
          )}
        </div>
      </TabPanel>
    </Wrapper>
  );
};

export default LibraryReport;
