import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "../../../services/apis";
import roleSetting from "./roleSetting.json";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useNavigate } from "react-router-dom";
import RoleUpdateCountContext from "../../../context/RoleUpdateCountContext";
import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import { LoadingButton } from "@mui/lab";
import { Search } from "@mui/icons-material";
import StickyBar from "../../StickyBar";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CRUD_OPERATIONS = ["view", "add", "update", "delete"];

function AssignRolePermission() {
  const navigate = useNavigate();
  const { setRoleUpdateCount } = useContext(RoleUpdateCountContext);
  const { selectedSetting } = useContext(SettingContext);
  const [roleData, setRoleData] = useState({});
  const [storageData, setStorageData] = useState({});
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  console.log(searchFilter, "lklkll");
  const handleCheckBoxClicked = (event) => {
    const { name, checked } = event.target;
    const nameArray = name.split("/");
    const [moduleName, propertyName] = nameArray;

    const moduleValue = roleData[moduleName];
    const updatedModuleValue = {
      ...moduleValue,
      [propertyName]: checked,
    };

    setRoleData({
      ...roleData,
      [moduleName]: updatedModuleValue,
    });
  };

  useEffect(() => {
    function setUserRole() {
      const storageData = localStorage.getItem(process.env.REACT_APP_ROLE);

      if (storageData) {
        setStorageData(JSON.parse(storageData));
      }
    }
    setUserRole();
    window.addEventListener("storage", setUserRole);

    return () => {
      window.removeEventListener("storage", setUserRole);
    };
  }, []);

  const getRoleData = async () => {
    const roleData = await get(`${urls.role.getAllRole}/${params.id}`, {
      headers: { roleFunction: "rolePermission" },
    });
    setRoleData(roleData.data.data);
  };

  const handleBulkUpdate = (event) => {
    const { name, checked } = event.target;
    const toUpdateRoleDate = { ...roleData };
    for (const roleKey of Object.keys(toUpdateRoleDate)) {
      const moduleName = toUpdateRoleDate[roleKey];
      if (typeof moduleName.accessible !== "undefined") {
        // this check is for roleName property
        moduleName[name] = checked;
      }
    }
    setRoleData(toUpdateRoleDate);
  };

  const updateRole = async () => {
    setLoader(true);
    try {
      const updatedRoleData = await put(
        `${urls.role.putRole}${params.id}`,
        "",
        roleData,
        { headers: { roleFunction: "rolePermission" } }
      );

      const { data } = await get(selectedSetting._id + "/" + "me", {
        headers: { roleFunction: "rolePermission" },
      });
      window.localStorage.setItem(
        process.env.REACT_APP_ROLE,
        JSON.stringify(data.employee.role)
      );
      setRoleUpdateCount((prev) => prev + 1);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getRoleData();
  }, [params.id]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (Array.isArray(roleSetting.modules)) {
      if (value.trim()) {
        setSearchFilter(
          roleSetting.modules.filter((module) => {
            const labelMatch = module.label
              .toLowerCase()
              .includes(value.toLowerCase());
            const functionalityMatch = module.functionalities.some((func) =>
              func.label.toLowerCase().includes(value.toLowerCase())
            );
            return labelMatch || functionalityMatch;
          })
        );
      } else {
        setSearchFilter([...roleSetting]);
      }
    } else {
      // Handle the case where roleSetting is not an array (e.g., set an empty array as the default)
      setSearchFilter([]);
    }
  };

  return (
    roleData &&
    Object.keys(roleData).length > 0 && (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {/* <Card style={{ margin: 15, padding: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} mg={12} lg={12}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Editing Role Permission For:
                <span style={{ color: "black" }}>{roleData.roleName}</span>
              </span>
            </Grid>
          </Grid>
        </Card> */}
        <Card style={{ margin: 15, padding: "22px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={8} mg={8} lg={8}>
              <Typography
                style={{
                  color: "red",

                  display: "inline-block",
                }}
              >
                Editing Role Permission For:
                <span style={{ color: "black" }}>{roleData.roleName}</span>
              </Typography>
            </Grid>
          </Grid>
        </Card>
        {/* <Grid container spacing={2}>
          <Grid item xs={4} mg={4} lg={4}>
            <TextField
              size="small"
              value={search}
              placeholder="Search Module and Function"
              onChange={handleSearch}
              label="Search"
              variant="outlined"
            />
          </Grid>
        </Grid> */}

        <div className={styles.searchInputContainer}>
          <Grid
            container
            // spacing={2}
            justifyContent="flex-end"
            sx={{ marginTop: "15px" }}
          >
            <Grid xs="auto">
              <TextField
                size="small"
                value={search}
                placeholder="Search Module and Function"
                onChange={handleSearch}
                label="Search"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Box sx={{ margin: "20px" }}>
          <TableContainer sx={{ maxHeight: 650 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                    }}
                  >
                    Module Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                    }}
                  >
                    Function Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                      textAlign: "center",
                    }}
                  >
                    View
                    <Checkbox onChange={handleBulkUpdate} name="view" />
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                      textAlign: "center",
                    }}
                  >
                    Add <Checkbox onChange={handleBulkUpdate} name="add" />{" "}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                      textAlign: "center",
                    }}
                  >
                    Update{" "}
                    <Checkbox onChange={handleBulkUpdate} name="update" />
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                      textAlign: "center",
                    }}
                  >
                    Delete{" "}
                    <Checkbox onChange={handleBulkUpdate} name="delete" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((roleModule) => {
                      const { label, functionalities } = roleModule;
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{
                                backgroundColor: "#EEEEEE",
                              }}
                              colSpan={6}
                            >
                              <b>{label}</b>
                            </TableCell>
                          </TableRow>
                          {functionalities.map((func) => {
                            const { label, id } = func;
                            const markup = (
                              <TableRow>
                                <TableCell>{"   "}</TableCell>
                                <TableCell
                                  style={{ borderRight: "1px solid #c5c1c1" }}
                                >
                                  {label}
                                </TableCell>
                                {CRUD_OPERATIONS.map((operation) => {
                                  return roleData[id] &&
                                    roleData[id][operation] !== undefined ? (
                                    <TableCell
                                      key={operation}
                                      style={{
                                        borderRight: "1px solid #c5c1c1",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Checkbox
                                        name={`${id}/${operation}`}
                                        onChange={handleCheckBoxClicked}
                                        value={
                                          roleData[id]
                                            ? roleData[id][operation]
                                            : false
                                        }
                                        checked={
                                          roleData[id]
                                            ? roleData[id][operation]
                                            : false
                                        }
                                      />
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      style={{
                                        borderRight: "1px solid #c5c1c1",
                                      }}
                                    ></TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                            return markup;
                          })}
                        </>
                      );
                    })
                  : roleSetting.modules.map((roleModule) => {
                      const { label, functionalities } = roleModule;
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{
                                backgroundColor: "#EEEEEE",
                              }}
                              colSpan={6}
                            >
                              <b>{label}</b>
                            </TableCell>
                          </TableRow>
                          {functionalities.map((func) => {
                            const { label, id } = func;
                            const markup = (
                              <TableRow>
                                <TableCell>{"   "}</TableCell>
                                <TableCell
                                  style={{ borderRight: "1px solid #c5c1c1" }}
                                >
                                  {label}
                                </TableCell>
                                {CRUD_OPERATIONS.map((operation) => {
                                  return roleData[id] &&
                                    roleData[id][operation] !== undefined ? (
                                    <TableCell
                                      key={operation}
                                      style={{
                                        borderRight: "1px solid #c5c1c1",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Checkbox
                                        name={`${id}/${operation}`}
                                        onChange={handleCheckBoxClicked}
                                        value={
                                          roleData[id]
                                            ? roleData[id][operation]
                                            : false
                                        }
                                        checked={
                                          roleData[id]
                                            ? roleData[id][operation]
                                            : false
                                        }
                                      />
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      style={{
                                        borderRight: "1px solid #c5c1c1",
                                      }}
                                    ></TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                            return markup;
                          })}
                        </>
                      );
                    })}
              </TableBody>
            </Table>
            {search.trim() && !searchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            <div style={{ marginBottom: "10px" }}></div>
          </TableContainer>
        </Box>
        {!search && roleSetting.modules.length > 0 && (
          <StickyBar
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Stack spacing={2} direction="row" margin={1}>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loader}
                    onClick={updateRole}
                    size="small"
                    sx={{
                      marginRight: "2px",
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                    variant="contained"
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </div>
            }
          />
        )}

        {search && searchFilter.length > 0 && (
          <StickyBar
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Stack spacing={2} direction="row" margin={1}>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loader}
                    onClick={updateRole}
                    size="small"
                    sx={{
                      marginRight: "2px",
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                    variant="contained"
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </div>
            }
          />
        )}
      </Paper>
    )
  );
}

export default AssignRolePermission;
