import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Delete, Edit, Search } from "@mui/icons-material";
import { useEffect, useContext } from "react";
import AddClassModal from "./AddClassModal";
import "../../../styles/components/Academic/Academic.scss";
import { useState } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import DeleteModal from "./DeleteModal";
import {
  Grid,
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import Wrapper from "../../Wrapper";
import ShowComponent from "../../ShowComponent";

const SchoolClass = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [schoolClass, setSchoolClass] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateClass, setUpdateClass] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [deleteloader, setDeleteLoader] = useState(false);
  const [updateloader, setUpdateLoader] = useState(false);
  const [sort, setSort] = useState({
    className: "asc",
    studentAttendenceType: "asc",
    classNote: "asc",
    isPublish: "asc",
    status: "asc",
  });
  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateClass(undefined);
  };

  const handleDelete = async (id) => {
    setDeleteLoader(true);
    try {
      const response = await del(
        url.class.delClass,
        id,
        {},
        { roleFunction: "class" }
      );
      if (response.data.success) {
        const filteredClass = schoolClass.filter(
          (item) => item._id !== response.data.data._id
        );
        setSchoolClass(filteredClass);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoader(false);
  };

  const handleSubmit = async (e, data) => {
    setLoader(true);
    e.preventDefault();
    try {
      const response = await post(url.class.postClass, data, {
        headers: { roleFunction: "class" },
      });
      if (response.data.success) {
        setSchoolClass([...schoolClass, response.data.data]);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(url.class.putClass, id, data, {
        headers: { roleFunction: "class" },
      });
      setSchoolClass(
        schoolClass.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
      handleClose();
      getSchoolClass();
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getClassById = schoolClass.find((item) => item._id === id);
      console.log(
        getClassById,
        "idddddddddddddddddddddddddddddddddddddddddddddd"
      );
      setUpdateClass(getClassById);
    } catch (error) {
      console.log(error);
    }
  };

  const getSchoolClass = async () => {
    try {
      const response = await get(url.class.getAllClass, {
        headers: { roleFunction: "class" },
      });
      setSchoolClass([...response.data.data]);
      setSearchFilter([...response.data.data]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSchoolClass();
  }, [selectedSetting]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolClass.filter((ele) =>
          ele.className.toLowerCase().includes(value.toLowerCase().trim())
        )
      );
    } else {
      setSearchFilter([]);
    }
  };

  // const sortBySectionName = () => {
  //   console.log("sortBySectionName");
  //   let sortedList;

  //   if (search) {
  //     if (sort.sectionName === "asc") {
  //       sortedList = [
  //         ...searchFilter.sort((a, b) =>
  //           b.sectionName.localeCompare(a.sectionName)
  //         ),
  //       ];
  //       setSort({ ...sort, sectionName: "des" });
  //     } else {
  //       sortedList = [
  //         ...searchFilter.sort((a, b) =>
  //           a.sectionName.localeCompare(b.sectionName)
  //         ),
  //       ];
  //       setSort({ ...sort, sectionName: "asc" });
  //     }
  //   } else {
  //     if (sort.sectionName === "asc") {
  //       sortedList = [
  //         ...schoolSection.sort((a, b) =>
  //           b.sectionName.localeCompare(a.sectionName)
  //         ),
  //       ];
  //       setSort({ ...sort, sectionName: "des" });
  //     } else {
  //       sortedList = [
  //         ...schoolSection.sort((a, b) =>
  //           a.sectionName.localeCompare(b.sectionName)
  //         ),
  //       ];
  //       setSort({ ...sort, sectionName: "asc" });
  //     }
  //   }
  //   setSchoolSection(sortedList);
  // };

  const classNameSorted = () => {
    console.log("className");
    let sortedList;
    if (search) {
      if (sort.className === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) => b.className - a.className),
        ];
        setSort({ ...sort, className: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) => a.className - b.className),
        ];
        setSort({ ...sort, className: "asc" });
      }
    } else {
      if (sort.className === "asc") {
        sortedList = [...schoolClass.sort((a, b) => b.className - a.className)];
        setSort({ ...sort, className: "des" });
      } else {
        sortedList = [...schoolClass.sort((a, b) => a.className - b.className)];
        setSort({ ...sort, className: "asc" });
      }
    }

    setSearchFilter(sortedList);
  };

  const studentAttendenceTypeSorted1 = () => {
    console.log("studentAttendanceType");
    if (sort.studentAttendenceType === "dec") {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (
            a.studentAttendenceType === "classWise" &&
            b.studentAttendenceType === "subjectWise"
          ) {
            // console.log("yes");
            return -1;
          } else if (
            a.studentAttendenceType === "subjectWise" &&
            b.studentAttendenceType === "classWise"
          ) {
            // console.log("no");

            return 1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, studentAttendenceType: "asc" });
    } else {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (
            a.studentAttendenceType === "classWise" &&
            b.studentAttendenceType === "subjectWise"
          ) {
            console.log("yes");
            return 1;
          } else if (
            a.studentAttendenceType === "subjectWise" &&
            b.studentAttendenceType === "classWise"
          ) {
            console.log("no");

            return -1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, studentAttendenceType: "dec" });
    }
  };

  const statusSorted = () => {
    console.log("statusSorted");
    if (sort.status === "asc") {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (a.status === "inactive" && b.status === "active") {
            console.log("yes");
            return -1;
          } else if (a.status === "active" && b.status === "inactive") {
            console.log("no");

            return 1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, status: "des" });
    } else {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (a.status === "inactive" && b.status === "active") {
            console.log("yes");
            return 1;
          } else if (a.status === "active" && b.status === "inactive") {
            console.log("no");

            return -1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, status: "asc" });
    }
  };

  const publishSorted = () => {
    console.log("publishSorted");
    if (sort.isPublish === "asc") {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (a.isPublish === "inactive" && b.isPublish === "active") {
            console.log("yes");
            return -1;
          } else if (a.isPublish === "active" && b.isPublish === "inactive") {
            console.log("no");

            return 1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, isPublish: "des" });
    } else {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (a.isPublish === "inactive" && b.isPublish === "active") {
            console.log("yes");
            return 1;
          } else if (a.isPublish === "active" && b.isPublish === "inactive") {
            console.log("no");

            return -1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, isPublish: "asc" });
    }
  };

  const classNoteSorted = () => {
    console.log("classNoteSorted");
    let sortedList;

    if (search) {
      if (sort.classNote === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            b.classNote.localeCompare(a.classNote)
          ),
        ];
        setSort({ ...sort, classNote: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            a.classNote.localeCompare(b.classNote)
          ),
        ];
        setSort({ ...sort, classNote: "asc" });
      }
    } else {
      if (sort.classNote === "asc") {
        sortedList = [
          ...schoolClass.sort((a, b) => b.classNote.localeCompare(a.classNote)),
        ];
        setSort({ ...sort, classNote: "des" });
      } else {
        sortedList = [
          ...schoolClass.sort((a, b) => a.classNote.localeCompare(b.classNote)),
        ];
        setSort({ ...sort, classNote: "asc" });
      }
    }
    setSearchFilter(sortedList);
  };

  console.log(schoolClass, "classssssssssssss");

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { md: "right", xs: "center" },
          mt: 1,
          mb: 2,
        }}
      >
        <Grid item xs="auto">
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            label="Search Class Name"
            variant="outlined"
            placeholder="Search..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">#SL</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">
                  Class
                  <span onClick={classNameSorted} style={{ cursor: "pointer" }}>
                    {sort.className === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              {/* <TableCell align="center">
                <span className="class-table-header">
                  Attendance
                  <span
                    onClick={studentAttendenceTypeSorted1}
                    style={{ cursor: "pointer" }}
                  >
                    {sort.studentAttendenceType === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell> */}
              {/* <TableCell align="center">
                <span className="class-table-header">Note</span>
                <span
                  onClick={classNoteSorted}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  {sort.classNote === "asc" ? "▲" : "▼"}
                </span>
              </TableCell> */}
              <TableCell align="center">
                <span className="class-table-header">Status</span>
                <span
                  onClick={statusSorted}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  {sort.status === "asc" ? "▲" : "▼"}
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Is Publish</span>
                <span
                  onClick={publishSorted}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  {sort.isPublish === "asc" ? "▲" : "▼"}
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Action </span>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {search
              ? searchFilter.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.className}
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.studentAttendenceType}
                    </TableCell> */}
                    {/* <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.classNote}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.isPublish === "active" ? "yes" : "No"}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      <div className="edit-delete">
                        <ShowComponent roleFunction="class" action="update">
                          <Tooltip title="Update" disableInteractive>
                            <IconButton
                              sx={{ color: "#1b3779" }}
                              size="small"
                              onClick={() => handleEdit(row._id)}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <ShowComponent roleFunction="class" action="delete">
                          <Tooltip title="Delete" disableInteractive>
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => setDeleteModal(row._id)}
                            >
                              <Delete fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                      </div>
                      <DeleteModal
                        deleteModal={deleteModal}
                        handleDelete={handleDelete}
                        id={row._id}
                        setDeleteModal={setDeleteModal}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : schoolClass
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.className}
                      </TableCell>
                      {/* <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.studentAttendenceType}
                      </TableCell> */}
                      {/* <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.classNote}
                      </TableCell> */}
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.isPublish}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="edit-delete">
                          <ShowComponent roleFunction="class" action="update">
                            <Tooltip title="Update" disableInteractive>
                              <IconButton
                                size="small"
                                sx={{ color: "#1b3779" }}
                                onClick={() => handleEdit(row._id)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <ShowComponent roleFunction="class" action="delete">
                            <Tooltip title="Delete" disableInteractive>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => setDeleteModal(row._id)}
                              >
                                <Delete fontSize="small" color="error" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
        {!schoolClass.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={search ? searchFilter.length : schoolClass.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* Add Class */}
      <ShowComponent roleFunction="class" action="add">
        <div className="add-icon">
          <AddClassModal
            open={open}
            loader={loader}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            updateClass={updateClass}
            handleUpdate={handleUpdate}
          />
        </div>
      </ShowComponent>
    </>
  );
};

export default SchoolClass;
