import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  styled,
  Fab,
  TableContainer,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import DeleteModal from "../Layout/Academic/DeleteModal";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete, Search } from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import ShowComponent from "../ShowComponent";
import { toast } from "react-toastify";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   height: 450,
//   bgcolor: "background.paper",
//   p: 4,
// };

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "750px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ContainerBox1 = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxWidth: "550px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const styles = {
  button: {
    color: "#ffff",
    background: "rgb(27, 55, 121)",
    ":hover": { background: "rgb(27, 55, 121)" },
  },

  textfield: {
    marginTop: "25px",
    marginRight: "5%",
  },

  tab: {
    width: "100%",
    borderCollapse: "collapse",
  },
};

const Periodical = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [list, setList] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [file, setFile] = useState();
  const [openUpload, setOpenUpload] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editPerdiodical, setEditPeriodical] = useState();
  const [addForm, setAddForm] = useState({});
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [countDownloadLoader, setCountDownloadLoader] = useState(false);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [sort, setSort] = useState({
    periodicalTitle: "asc",
    type: "asc",
    entrydate: "asc",
    price: "asc",
    quantity: "asc",
    issueNumber: "asc",
    volumeNumber: "asc",
  });

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ====================================

  const sortByTitle = () => {
    if (sort.periodicalTitle == "asc") {
      let sortedList = [
        ...list.sort((a, b) =>
          b.periodicalTitle.localeCompare(a.periodicalTitle)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, periodicalTitle: "des" });
    } else {
      let sortedList = [
        ...list.sort((a, b) =>
          a.periodicalTitle.localeCompare(b.periodicalTitle)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, periodicalTitle: "asc" });
    }
  };
  const sortByType = () => {
    if (sort.type == "asc") {
      let sortedList = [...list.sort((a, b) => b.type.localeCompare(a.type))];
      setList(sortedList);
      setSort({ ...sort, type: "des" });
    } else {
      let sortedList = [...list.sort((a, b) => a.type.localeCompare(b.type))];
      setList(sortedList);
      setSort({ ...sort, type: "asc" });
    }
  };
  const sortByDate = () => {
    if (sort.entrydate == "asc") {
      let sortedList = [
        ...list.sort((a, b) => b.entrydate.localeCompare(a.entrydate)),
      ];
      setList(sortedList);
      setSort({ ...sort, entrydate: "des" });
    } else {
      let sortedList = [
        ...list.sort((a, b) => a.entrydate.localeCompare(b.entrydate)),
      ];
      setList(sortedList);
      setSort({ ...sort, entrydate: "asc" });
    }
  };
  const sortByPrice = () => {
    console.log(sort, "klklkl");
    if (sort.price == "asc") {
      let sortedList = [...list.sort((a, b) => b.price - a.price)];
      setList(sortedList);
      setSort({ ...sort, price: "des" });
    } else {
      let sortedList = [...list.sort((a, b) => a.price - b.price)];
      setList(sortedList);
      setSort({ ...sort, price: "asc" });
    }
  };
  const sortByQuantity = () => {
    console.log(sort, "klklkl");
    if (sort.quantity == "asc") {
      let sortedList = [...list.sort((a, b) => b.quantity - a.quantity)];
      setList(sortedList);
      setSort({ ...sort, quantity: "des" });
    } else {
      let sortedList = [...list.sort((a, b) => a.quantity - b.quantity)];
      setList(sortedList);
      setSort({ ...sort, quantity: "asc" });
    }
  };
  const sortByIssue = () => {
    console.log(sort, "klklkl");
    if (sort.issueNumber == "asc") {
      let sortedList = [...list.sort((a, b) => b.issueNumber - a.issueNumber)];
      setList(sortedList);
      setSort({ ...sort, issueNumber: "des" });
    } else {
      let sortedList = [...list.sort((a, b) => a.issueNumber - b.issueNumber)];
      setList(sortedList);
      setSort({ ...sort, issueNumber: "asc" });
    }
  };
  const sortByVolumeNumber = () => {
    console.log(sort, "klklkl");
    if (sort.volumeNumber == "asc") {
      let sortedList = [
        ...list.sort((a, b) => b.volumeNumber - a.volumeNumber),
      ];
      setList(sortedList);
      setSort({ ...sort, volumeNumber: "des" });
    } else {
      let sortedList = [
        ...list.sort((a, b) => a.volumeNumber - b.volumeNumber),
      ];
      setList(sortedList);
      setSort({ ...sort, volumeNumber: "asc" });
    }
  };

  useEffect(() => {
    getperiodical();
  }, [selectedSetting]);
  const getperiodical = async () => {
    try {
      const data = await get(urls.periodical.getList, {
        headers: { roleFunction: "periodical" },
      });
      console.log(data.data.data);
      setList(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.periodicalTitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.periodical.delPeriodical}`,
        id,
        {},
        { roleFunction: "periodical" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    setDownloadLoader(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/periodical/downloadexcel`,
        {
          headers: { roleFunction: "periodical" },
        },
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `periodical-stock.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setDownloadLoader(false);
  };

  const handleGetCountDownloadSheet = async (e) => {
    setCountDownloadLoader(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/periodical/downloadcountexcel`,
        {
          headers: { roleFunction: "periodical" },
        },
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `periodical-count.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setCountDownloadLoader(false);
  };

  const handleGetSampleSheet = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/periodical/downloadsample`,
        {
          headers: { roleFunction: "periodical" },
        },
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `sample-periodicalfile.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditPeriodical(null);
    setOpenDialog(false);
    resetForm();
  };
  const resetForm = () => {
    setAddForm({});
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const handleImages = (e) => {
    setImages((prev) => [...prev, ...e.target.files]);
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      if (!editPerdiodical) {
        let imageIds = [];
        const formImage = new FormData();
        if (images.length > 0) {
          for (const img of images) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage, {
            headers: { roleFunction: "periodical" },
          });
          imageIds = res.data.result.map((id) => id._id);
        }
        const res = await post(
          `${urls.periodical.create}`,
          {
            periodicalTitle: addForm.periodicalTitle,
            type: addForm.type,
            department: addForm.department,
            issueNumber: addForm.issueNumber,
            volumeNumber: addForm.volumeNumber,
            quantity: addForm.quantity,
            price: addForm.price,
            entrydate: date,
            backCover: imageIds,
          },
          {
            headers: { roleFunction: "periodical" },
          }
        );
        if (res.data.success) {
          getperiodical();
          handleClose();
          setImages([]);
        }
      } else {
        const res = await put(
          `${urls.periodical.editPerdiodical}${editPerdiodical}`,
          "",
          {
            periodicalTitle: addForm.periodicalTitle,
            type: addForm.type,
            department: addForm.department,
            issueNumber: addForm.issueNumber,
            volumeNumber: addForm.volumeNumber,
            quantity: addForm.quantity,
            price: addForm.price,
            entrydate: date,
            // backCover: imageIds,
          },
          {
            headers: { roleFunction: "periodical" },
          }
        );
        if (res.data.success) {
          getperiodical();
          handleClose();
          setImages([]);
        }
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleTypeChange = (e) => {
    if (e.target.name === "bookCover") {
      setAddForm({
        ...addForm,
        bookCover: e.target.files[0],
      });
    } else {
      {
        setAddForm({
          ...addForm,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleEditChange = (id, data) => {
    setEditPeriodical(id);
    setAddForm({
      periodicalTitle: data.periodicalTitle,
      type: data.type,
      department: data.department,
      issueNumber: data.issueNumber,
      volumeNumber: data.volumeNumber,
      quantity: data.quantity,
      price: data.price,
      entrydate: date,
    });
    handleClickOpen();
  };

  const handleSheetUpload = async (e) => {
    setBulkUploadLoader(true);
    e.preventDefault();
    // console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        toast.error("Please select a file.");
        setBulkUploadLoader(false);

        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);
      const response = await put(
        `/${selectedSetting._id}/periodical/uploadsample`,
        "",
        formData,
        {
          headers: { roleFunction: "periodical" },
        }
      );
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      getperiodical();
      handleClose();
    } catch (error) {
      console.error(error);
      setBulkUploadLoader(false);
    }
    setBulkUploadLoader(false);
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Periodical List" />
      <Paper
        sx={{
          padding: "10px",
          margin: "15px 0",
          display: "flex",
          flexDirection: { xs: "column", md: "row", sm: "column" },
          justifyContent: { xs: "center", md: "space-between", sm: "center" },
          alignItems: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <LoadingButton
            size="small"
            loading={downloadLoader}
            sx={styles.button}
            onClick={handleGetDownloadSheet}
          >
            Download
          </LoadingButton>
          <LoadingButton
            size="small"
            loading={countDownloadLoader}
            sx={styles.button}
            onClick={handleGetCountDownloadSheet}
          >
            Count By title
          </LoadingButton>
          <Button
            size="small"
            sx={styles.button}
            onClick={() => setBulkUpdateModal(true)}
          >
            Bulk Upload
          </Button>
        </Stack>
        <TextField
          size="small"
          value={search}
          onChange={handleSearch}
          placeholder="Type periodical title..."
          label="Search title"
          variant="outlined"
          sx={{
            marginTop: {
              xs: "10px",
              sm: "10px",
              md: "0px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" type="submit">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Paper>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByTitle} className="class-table-header">
                  Title
                  <span style={{ cursor: "pointer" }}>
                    {sort.periodicalTitle === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByType} className="class-table-header">
                  Type
                  <span style={{ cursor: "pointer" }}>
                    {sort.type === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByIssue} className="class-table-header">
                  Issue Number
                  <span style={{ cursor: "pointer" }}>
                    {sort.issueNumber === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span
                  onClick={sortByVolumeNumber}
                  className="class-table-header"
                >
                  Volume Number
                  <span style={{ cursor: "pointer" }}>
                    {sort.volumeNumber === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Book Cover
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByPrice} className="class-table-header">
                  Price
                  <span style={{ cursor: "pointer" }}>
                    {sort.price === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByQuantity} className="class-table-header">
                  Quantity
                  <span style={{ cursor: "pointer" }}>
                    {sort.quantity === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByDate} className="class-table-header">
                  Journal Entry Date
                  <span style={{ cursor: "pointer" }}>
                    {sort.entrydate === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {data.periodicalTitle}
                      </TableCell>
                      <TableCell align="center">{data.type}</TableCell>
                      <TableCell align="center">{data.issueNumber}</TableCell>
                      <TableCell align="center">{data.volumeNumber}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.backCover ? (
                          <img
                            src={data.backCover.link}
                            alt="NA"
                            style={{
                              width: "100px",
                              height: "60px",
                              resize: "contain",
                            }}
                          ></img>
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell align="center">{data.price}</TableCell>
                      <TableCell align="center">{data.quantity}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.entrydate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <ShowComponent
                          roleFunction="periodical"
                          action="update"
                        >
                          <Tooltip title="Update">
                            <IconButton
                              size="small"
                              sx={{ color: "#1b3779" }}
                              onClick={() => handleEditChange(data._id, data)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <ShowComponent
                          roleFunction="periodical"
                          action="delete"
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => setDeleteModal(data._id, data)}
                            >
                              <Delete color="error" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              : list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell align="center">
                        {data.periodicalTitle}
                      </TableCell>
                      <TableCell align="center">{data.type}</TableCell>
                      <TableCell align="center">{data.issueNumber}</TableCell>
                      <TableCell align="center">{data.volumeNumber}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.backCover ? (
                          <img
                            src={data.backCover.link}
                            alt="NA"
                            style={{
                              width: "100px",
                              height: "60px",
                              resize: "contain",
                            }}
                          ></img>
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell align="center">{data.price}</TableCell>
                      <TableCell align="center">{data.quantity}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.entrydate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <ShowComponent
                          roleFunction="periodical"
                          action="update"
                        >
                          <Tooltip title="Update">
                            <IconButton
                              size="small"
                              sx={{ color: "#1b3779" }}
                              onClick={() => handleEditChange(data._id, data)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <ShowComponent
                          roleFunction="periodical"
                          action="delete"
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => setDeleteModal(data._id, data)}
                            >
                              <Delete color="error" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={search ? searchFilter.length : list.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
          "& .MuiTablePagination-spacer": {
            overflow: "none",
          },
        }}
      />

      {/* ======= Pluse Icons */}
      <ShowComponent roleFunction="periodical" action="add">
        <Box className="add-icon">
          <Tooltip title="Add Periodical List">
            <Fab
              variant="contained"
              sx={{
                color: "#ffff",
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleClickOpen}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box>
      </ShowComponent>

      {/* ============== Periodical List modal==============  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <ContainerBox>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
              fontWeight="bold"
              // onChange={handleAddForm}
            >
              {editPerdiodical
                ? "Update Periodical List"
                : "Add Periodical List"}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Periodical Title"
                  name="periodicalTitle"
                  value={addForm.periodicalTitle || ""}
                  onChange={handleTypeChange}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    // label: { fontSize: 12 },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 0.3, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel sx={{ fontSize: 8 }}>Type</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="type"
                    name="type"
                    value={addForm.type || ""}
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={"journal"}>journal</MenuItem>
                    <MenuItem value={"magazines"}>magazines</MenuItem>
                    <MenuItem value={"newspaper"}>newspaper</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Department"
                  name="department"
                  value={addForm.department || ""}
                  onChange={handleTypeChange}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  type="number"
                  label="Issue Number"
                  name="issueNumber"
                  value={addForm.issueNumber || ""}
                  onChange={handleTypeChange}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Volume Number"
                  type="number"
                  name="volumeNumber"
                  value={addForm.volumeNumber || ""}
                  onChange={handleTypeChange}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Quantity"
                  type="number"
                  name="quantity"
                  value={addForm.quantity || ""}
                  onChange={handleTypeChange}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  type="number"
                  label="Price"
                  name="price"
                  value={addForm.price || ""}
                  onChange={handleTypeChange}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    value={date}
                    onChange={(newDate) => setDate(newDate)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        size="small"
                        // sx={{ marginTop: "5px",width:{md:227,xs:"100%"} }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                {editPerdiodical ? (
                  ""
                ) : (
                  <Box sx={{ marginTop: "2px", display: "flex" }}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{
                        label: { fontSize: 12 },
                      }}
                    >
                      <label style={{ fontWeight: "bold" }}>Book Cover</label>
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={handleImages}
                      />
                    </FormControl>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} md={6} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: {
                      md: "right",
                      sm: "right",
                      xs: "center",
                    },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    type="button"
                    color="error"
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </ContainerBox>
        </form>
      </Modal>

      {/* ======= Bulk upload model =========== */}
      <Modal
        open={bulkUpdateModal}
        onClose={() => setBulkUpdateModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContainerBox1>
          <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
              fontWeight="bold"
            >
              Bulk Upload
            </Typography>
          </Box>
          <form onSubmit={handleSheetUpload}>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Box>
                <LoadingButton
                  variant="contained"
                  // size="small"
                  type="submit"
                  loading={loadingBtn}
                  onClick={handleGetSampleSheet}
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  Sample
                </LoadingButton>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Upload
                    <input
                      name="bulkUpdate"
                      onChange={handleFileChange}
                      hidden
                      accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                      type="file"
                    />
                  </Button>

                  <Box>{fileChoosen && fileChoosen.name}</Box>
                </Box>
              </Box>
              <Box>
                <LoadingButton
                  loading={bulkUploadLoader}
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </ContainerBox1>
      </Modal>

      <Modal
        open={openUpload}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag">
          <ContainerBox>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Bulk Upload
            </Typography>

            <Box sx={{ marginTop: "2px", display: "flex" }}>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: { md: 227, xs: "100%" },
                  label: { fontSize: 12 },
                }}
              >
                <label>Upload File</label>
                <input type="file" onChange={handleChange} />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { md: "right", sm: "right", xs: "center" },
              }}
            >
              <Button
                onClick={handleCloseUpload}
                variant="outlined"
                size="medium"
                type="button"
                sx={{ m: 1 }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                size="medium"
                type="submit"
                sx={styles.button}
              >
                Add
              </Button>
            </Box>
          </ContainerBox>
        </form>
      </Modal>
    </Wrapper>
  );
};

export default Periodical;
