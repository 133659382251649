import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
  Tooltip,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Stack,
  styled,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  TableContainer,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete, Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import ShowComponent from "../ShowComponent";
import { row } from "@syncfusion/ej2-react-grids";

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "650px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#1b3779",
    color: "white",
  },
  button: {
    backgroundColor: "orange",
  },
  textfield: {
    marginTop: "25px",
    // width: "70%",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
  tab: {
    width: "100%",
    borderCollapse: "collapse",
  },
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  // textfield: {
  //   width: "100%",
  //   label: { fontSize: 12 },
  //   m: 1,
  // },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
};

const BookDetailed = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

const EmployeeIssue = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState();
  const [date, setDate] = useState(dayjs(Date.now()));

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [dueSearchFilter, setDueSearchFilter] = useState([]);
  const [dueSearch, setDueSearch] = useState("");
  const [hisSearchFilter, setHisSearchFilter] = useState([]);
  const [hisSearch, setHisSearch] = useState("");

  const [fromDate, setFromDate] = useState(dayjs(Date.now()));
  const [toDate, setToDate] = useState(dayjs(Date.now()));
  const [edate, setEDate] = useState(dayjs(Date.now()));

  const [issue, setIssue] = useState([]);
  const [dueIssue, setDueIssue] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [issueTotalCount, setIssueTotalCount] = useState();
  const [dueTotalCount, setDueTotalCount] = useState();
  const [book, setBook] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [history, setHistory] = useState([]);
  const [addForm, setAddForm] = useState({});
  const [editIssue, setEditBook] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [findLoadingbtn, setFindLoadingbtn] = useState(false);
  const [printloadingBtn, setPrintloadingBtn] = useState(false);
  const [popOpen, sePopOpen] = useState(false);
  const [addBtnLoader, setAddBtnLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [sort, setSort] = useState({
    student: "",
    libraryId: "",
    title: "",
    bookId: "",
    issueDate: "",
    SubmittedDate: "",
    returnDate: "",
  });
  const sortByIssueName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...issue.sort((a, b) =>
          b.issuedTo.basicInfo.empName.localeCompare(
            a.issuedTo.basicInfo.empName
          )
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) =>
          a.issuedTo.basicInfo.empName.localeCompare(
            b.issuedTo.basicInfo.empName
          )
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByLibraryId = () => {
    if (sort.libraryId == "asc") {
      let sortedList = [
        ...issue.sort(
          (a, b) => b.issuedTo.basicInfo.empId - a.issuedTo.basicInfo.empId
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, libraryId: "des" });
    } else {
      let sortedList = [
        ...issue.sort(
          (a, b) => a.issuedTo.basicInfo.empId - b.issuedTo.basicInfo.empId
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, libraryId: "asc" });
    }
  };
  const sortByTitle = () => {
    if (sort.title == "asc") {
      let sortedList = [
        ...issue.sort((a, b) =>
          b.book.booktitle.localeCompare(a.book.booktitle)
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) =>
          a.book.booktitle.localeCompare(b.book.booktitle)
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };

  const sortByBookID = () => {
    if (sort.bookId == "asc") {
      let sortedList = [
        ...issue.sort((a, b) => b.book.booksId.localeCompare(a.book.booksId)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, bookId: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) => a.book.booksId.localeCompare(b.book.booksId)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, bookId: "asc" });
    }
  };
  const sortByIssueDate = () => {
    if (sort.issueDate == "asc") {
      let sortedList = [
        ...issue.sort((a, b) => b.issueDate.localeCompare(a.issueDate)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, issueDate: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) => a.issueDate.localeCompare(b.issueDate)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, issueDate: "asc" });
    }
  };
  const sortByReturn = () => {
    if (sort.returnDate == "asc") {
      let sortedList = [
        ...issue.sort((a, b) => b.returnDate.localeCompare(a.returnDate)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, returnDate: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) => a.returnDate.localeCompare(b.returnDate)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, returnDate: "asc" });
    }
  };
  const sortByNonIssueName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          b.issuedTo.basicInfo.empName.localeCompare(
            a.issuedTo.basicInfo.empName
          )
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          a.issuedTo.basicInfo.empName.localeCompare(
            b.issuedTo.basicInfo.empName
          )
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByNonLibraryId = () => {
    if (sort.libraryId == "asc") {
      let sortedList = [
        ...dueIssue.sort(
          (a, b) => b.issuedTo.basicInfo.empId - a.issuedTo.basicInfo.empId
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, libraryId: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort(
          (a, b) => a.issuedTo.basicInfo.empId - b.issuedTo.basicInfo.empId
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, libraryId: "asc" });
    }
  };
  const sortByNonTitle = () => {
    if (sort.title == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          b.book.booktitle.localeCompare(a.book.booktitle)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          a.book.booktitle.localeCompare(b.book.booktitle)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };
  const sortByNonBookID = () => {
    if (sort.bookId == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          b.book.booksId.localeCompare(a.book.booksId)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, bookId: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          a.book.booksId.localeCompare(b.book.booksId)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, bookId: "asc" });
    }
  };
  const sortByNonIssueDate = () => {
    if (sort.issueDate == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) => b.issueDate.localeCompare(a.issueDate)),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, issueDate: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) => a.issueDate.localeCompare(b.issueDate)),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, issueDate: "asc" });
    }
  };
  const sortByNonReturn = () => {
    if (sort.returnDate == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) => b.returnDate.localeCompare(a.returnDate)),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, returnDate: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) => a.returnDate.localeCompare(b.returnDate)),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, returnDate: "asc" });
    }
  };
  const sortByHisIssueName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...history.sort((a, b) =>
          b.issuedTo.basicInfo.empName.localeCompare(
            a.issuedTo.basicInfo.empName
          )
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) =>
          a.issuedTo.basicInfo.empName.localeCompare(
            b.issuedTo.basicInfo.empName
          )
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByHisLibraryId = () => {
    if (sort.libraryId == "asc") {
      let sortedList = [
        ...history.sort(
          (a, b) => b.issuedTo.basicInfo.empId - a.issuedTo.basicInfo.empId
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, libraryId: "des" });
    } else {
      let sortedList = [
        ...history.sort(
          (a, b) => a.issuedTo.basicInfo.empId - b.issuedTo.basicInfo.empId
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, libraryId: "asc" });
    }
  };
  const sortByHisTitle = () => {
    if (sort.title == "asc") {
      let sortedList = [
        ...history.sort((a, b) =>
          b.book.booktitle.localeCompare(a.book.booktitle)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) =>
          a.book.booktitle.localeCompare(b.book.booktitle)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };

  const sortByHisBookID = () => {
    if (sort.bookId == "asc") {
      let sortedList = [
        ...history.sort((a, b) => b.book.booksId.localeCompare(a.book.booksId)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, bookId: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) => a.book.booksId.localeCompare(b.book.booksId)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, bookId: "asc" });
    }
  };
  const sortByHisIssueDate = () => {
    if (sort.issueDate == "asc") {
      let sortedList = [
        ...history.sort((a, b) => b.issueDate.localeCompare(a.issueDate)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, issueDate: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) => a.issueDate.localeCompare(b.issueDate)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, issueDate: "asc" });
    }
  };
  const sortByHisReturn = () => {
    if (sort.returnDate == "asc") {
      let sortedList = [
        ...history.sort((a, b) => b.returnDate.localeCompare(a.returnDate)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, returnDate: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) => a.returnDate.localeCompare(b.returnDate)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, returnDate: "asc" });
    }
  };
  const sortByHisSubmittedDate = () => {
    if (sort.SubmittedDate == "asc") {
      let sortedList = [
        ...history.sort((a, b) =>
          b.SubmittedDate?.localeCompare(a.SubmittedDate)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, SubmittedDate: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) =>
          a.SubmittedDate?.localeCompare(b.SubmittedDate)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, SubmittedDate: "asc" });
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      issue.length > 0 &&
        setSearchFilter(
          issue.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleClickPopOpen = () => {
    sePopOpen(true);
  };

  const handlePopClose = () => {
    sePopOpen(false);
  };

  const handleDueSearch = (e) => {
    const { value } = e.target;
    setDueSearch(value.trim());
    if (value.trim() !== "") {
      dueIssue.length > 0 &&
        setDueSearchFilter(
          dueIssue.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setDueSearchFilter([]);
    }
  };
  const handleHisSearch = (e) => {
    const { value } = e.target;
    setHisSearch(value.trim());
    if (value.trim() !== "") {
      history.length > 0 &&
        setHisSearchFilter(
          history.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setHisSearchFilter([]);
    }
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleClose = () => {
    setOpen(false);
    setAddForm({});
  };

  useEffect(() => {
    getIssueList();
    getDueIssueList();
    getBookList();
    getLibraryEmployee();
  }, [selectedSetting]);

  const getIssueList = async () => {
    // console.log("issue");
    try {
      setShowPagination(true);
      const response = await get(urls.empIssueList.get, {
        headers: { roleFunction: "empIssue" },
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filterIssue = response.data.data.filter(
        (issues) => issues.submitted === "no"
      );
      setIssue(filterIssue);
      console.log(filterIssue, "isssssssssss");
      setIssueTotalCount(filterIssue.length);
    } catch (error) {
      console.log(error);
    }
  };
  const getDueIssueList = async () => {
    console.log("due");

    try {
      setShowPagination(true);
      const response = await get(urls.empIssueList.getdue, {
        headers: { roleFunction: "empIssue" },
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filteredDue = response.data.data.filter(
        (dues) => dues.submitted === "no"
      );

      setDueIssue(filteredDue);

      setDueTotalCount(filteredDue.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getBookList = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.book.getList, {
        headers: { roleFunction: "empIssue" },
        params: {
          limit: 10,
          page: 1,
        },
      });
      setBook(response.data.data);
      setTotalCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const getLibraryEmployee = async () => {
    try {
      const searchEmployee = await get(urls.employee.getEmployee, {
        headers: { roleFunction: "empIssue" },
        params: {
          search: {
            libraryMember: "yes",
          },
        },
      });
      setEmployee(searchEmployee.data.data);
      console.log(searchEmployee.data, "searchEmployee.data.data");

      setTotalCount(searchEmployee.length);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitList = async (e, id) => {
    console.log("jhjhjhjhj");
    e.preventDefault();
    try {
      const { status } = await put(
        `${urls.empIssueList.submitBook}/`,
        id,
        {},
        {
          headers: { roleFunction: "empIssue" },
        }
      );

      getDueIssueList();
      getIssueList();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await post(
        `${urls.empIssueList.create}`,
        {
          issueDate: date,
          book: addForm.book,
          isbnNo: addForm.isbnNo,
          edison: addForm.edison,
          author: addForm.author,
          language: addForm.language,
          price: addForm.price,
          quantity: addForm.quantity,
          almiraNo: addForm.almiraNo,
          returnDate: edate,
          issuedTo: addForm.issuedTo,
        },
        {
          headers: { roleFunction: "empIssue" },
        }
      );

      if (res.data.success) {
        getIssueList();
        getDueIssueList();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleSearchHistory = async (e) => {
    setFindLoadingbtn(true);
    e.preventDefault();
    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.empIssueList.getHistory, {
        headers: { roleFunction: "empIssue" },
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setHistory(searchHistory.data.data);
      console.log(searchHistory.data.data, "searchHistory");
    } catch (error) {
      console.error(error);
      setFindLoadingbtn(false);
    }
    setFindLoadingbtn(false);
  };

  const handleGetDownloadSheet = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    try {
      const response = await get(
        `/empissue/downloadexcel/${fromDate}/${toDate}`,
        {
          headers: { roleFunction: "empIssue" },
        },
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `library-history${fromDate}-${toDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    setPrintloadingBtn(true);
    try {
      const getPrint = await get(
        `/empissue/downloadpdf/${fromDate}/${toDate}`,
        {
          headers: { roleFunction: "empIssue" },
        },
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setPrintloadingBtn(false);
  };
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <Wrapper>
      <SectionsSubHeader title="Employee Issue & Return" />

      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          borderColor: "divider",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: {
            md: "space-between",
            xs: "center",
            sm: "center",
          },
          alignItems: {
            md: "center",
            xs: "flex-start",
            sm: "center",
          },
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Issue List" {...a11yProps(0)} />
          <Tab label="Due List" {...a11yProps(1)} />
          <Tab label="History List" {...a11yProps(2)} />
        </Tabs>
        <ShowComponent roleFunction="empIssue" action="update">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
              marginTop: {
                xs: "10px",
                sm: "10px",
                md: "0",
              },
              background: "#1b3779",
              "&:hover": {
                background: "#1b3779",
              },
            }}
            startIcon={<AddIcon />}
          >
            Issue Book
          </Button>
        </ShowComponent>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: {
              md: "flex-end",
              xs: "center",
              sm: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
            },
          }}
        >
          <Grid xs="auto" mt={2}>
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search Book"
              variant="outlined"
              placeholder="Search..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell></TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Issue
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Due
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell align="center">{issueTotalCount}</TableCell>
              <TableCell align="center">{dueTotalCount}</TableCell>
            </TableRow>
          </TableBody>
        </Table> */}
        <TableContainer component={Paper}>
          <BookDetailed>
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              fontSize={16}
              my={1}
            >
              Total Books: {issueTotalCount + dueTotalCount}
            </Typography>
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              fontSize={16}
              my={1}
            >
              Issued: {issueTotalCount}
            </Typography>
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              fontSize={16}
              my={1}
            >
              Due: {dueTotalCount}
            </Typography>
          </BookDetailed>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByIssueName}
                    className="class-table-header"
                  >
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByLibraryId}
                    className="class-table-header"
                  >
                    Library ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.libraryId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByTitle} className="class-table-header">
                    Title
                    <span style={{ cursor: "pointer" }}>
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByBookID} className="class-table-header">
                    Book ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.bookId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByIssueDate}
                    className="class-table-header"
                  >
                    Issue Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.issueDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByReturn} className="class-table-header">
                    Due Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.returnDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                {/* <TableCell sx={{ color: "white" }} align="center">
                Return Date
              </TableCell> */}
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.issuedTo?.basicInfo?.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo?.basicInfo?.empId}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">--</TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <ShowComponent
                              roleFunction="empIssue"
                              action="update"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleClickPopOpen}
                                startIcon={<AddIcon />}
                              >
                                Return
                              </Button>
                            </ShowComponent>
                          </Box>

                          <Dialog
                            open={popOpen}
                            onClose={handlePopClose}
                            maxWidth="xs"
                            fullWidth
                          >
                            <DialogContent>
                              Are You Sure You Want to Change The Issue Status
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="plain"
                                color="neutral"
                                size="small"
                                sx={{
                                  background: "#ddd",
                                  ":hover": {
                                    background: "#ddd",
                                    opacity: 0.8,
                                  },
                                }}
                                onClick={handlePopClose}
                              >
                                No, cancel
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  background: "#1b3779",
                                  "&:hover": {
                                    background: "#1b3779",
                                  },
                                }}
                                onClick={(e) => {
                                  handleSubmitList(e, data._id);
                                  handlePopClose();
                                }}
                              >
                                Yes, I'm sure
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))
                : issue
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.issuedTo?.basicInfo?.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo?.basicInfo?.empId}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ShowComponent
                              roleFunction="empIssue"
                              action="update"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleClickPopOpen}
                                startIcon={<AddIcon />}
                              >
                                Return
                              </Button>
                            </ShowComponent>
                          </Box>
                          <Dialog
                            open={popOpen}
                            onClose={handlePopClose}
                            maxWidth="xs"
                            fullWidth
                          >
                            <DialogContent>
                              Are You Sure You Want to Change The Issue Status
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="plain"
                                color="neutral"
                                size="small"
                                sx={{
                                  background: "#ddd",
                                  ":hover": {
                                    background: "#ddd",
                                    opacity: 0.8,
                                  },
                                }}
                                onClick={handlePopClose}
                              >
                                No, cancel
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  background: "#1b3779",
                                  "&:hover": {
                                    background: "#1b3779",
                                  },
                                }}
                                onClick={(e) => {
                                  handleSubmitList(e, data._id);
                                  handlePopClose();
                                }}
                              >
                                Yes, I'm sure
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!issue.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : issue.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div style={{ margin: "15px 0" }}>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: {
                md: "flex-end",
                xs: "center",
                sm: "center",
              },
            }}
          >
            <Grid xs="auto" mt={2}>
              <TextField
                size="small"
                value={dueSearch}
                onChange={handleDueSearch}
                label="Search Book"
                variant="outlined"
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonIssueName}
                    className="class-table-header"
                  >
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonLibraryId}
                    className="class-table-header"
                  >
                    Library ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.libraryId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByNonTitle} className="class-table-header">
                    Title
                    <span style={{ cursor: "pointer" }}>
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonBookID}
                    className="class-table-header"
                  >
                    Book ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.bookId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonIssueDate}
                    className="class-table-header"
                  >
                    Issue Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.issueDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonReturn}
                    className="class-table-header"
                  >
                    Due Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.returnDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                {/* <TableCell sx={{ color: "white" }} align="center">
            Return Date
          </TableCell> */}
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dueSearch
                ? dueSearchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.issuedTo?.basicInfo?.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo?.basicInfo?.empId}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">--</TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <ShowComponent
                              roleFunction="empIssue"
                              action="update"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleClickPopOpen}
                                startIcon={<AddIcon />}
                              >
                                Return
                              </Button>
                            </ShowComponent>
                          </Box>
                          <Dialog
                            open={popOpen}
                            onClose={handlePopClose}
                            maxWidth="xs"
                            fullWidth
                          >
                            <DialogContent>
                              Are You Sure You Want to Change The Issue Status
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="plain"
                                color="neutral"
                                size="small"
                                sx={{
                                  background: "#ddd",
                                  ":hover": {
                                    background: "#ddd",
                                    opacity: 0.8,
                                  },
                                }}
                                onClick={handlePopClose}
                              >
                                No, cancel
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  background: "#1b3779",
                                  "&:hover": {
                                    background: "#1b3779",
                                  },
                                }}
                                onClick={(e) => {
                                  handleSubmitList(e, data._id);
                                  handlePopClose();
                                }}
                              >
                                Yes, I'm sure
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))
                : dueIssue
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo?.basicInfo?.empName}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo?.basicInfo?.empId}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ShowComponent
                              roleFunction="empIssue"
                              action="update"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleClickPopOpen}
                                startIcon={<AddIcon />}
                              >
                                Return
                              </Button>
                            </ShowComponent>
                          </Box>
                          <Dialog
                            open={popOpen}
                            onClose={handlePopClose}
                            maxWidth="xs"
                            fullWidth
                          >
                            <DialogContent>
                              Are You Sure You Want to Change The Issue Status
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="plain"
                                color="neutral"
                                size="small"
                                sx={{
                                  background: "#ddd",
                                  ":hover": {
                                    background: "#ddd",
                                    opacity: 0.8,
                                  },
                                }}
                                onClick={handlePopClose}
                              >
                                No, cancel
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  background: "#1b3779",
                                  "&:hover": {
                                    background: "#1b3779",
                                  },
                                }}
                                onClick={(e) => {
                                  handleSubmitList(e, data._id);
                                  handlePopClose();
                                }}
                              >
                                Yes, I'm sure
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>

          {!dueIssue.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {dueSearch.trim() && !dueSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={dueSearch ? dueSearchFilter.length : dueIssue.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <form onSubmit={handleSearchHistory}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={4}
                sx={{ alignSelf: "center", mt: 2 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    variant="standard"
                    label="From Date"
                    inputFormat="DD-MM-YYYY"
                    value={fromDate}
                    onChange={(newValue) => {
                      setFromDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={4}
                sx={{ alignSelf: "center", mt: 2 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    variant="standard"
                    label="To Date"
                    inputFormat="DD-MM-YYYY"
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sx={{ alignSelf: "center" }}>
                <LoadingButton
                  loading={findLoadingbtn}
                  variant="contained"
                  sx={styles.Button}
                  size="small"
                  type="submit"
                >
                  Find
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ alignSelf: "center" }}>
                <Box style={{ paddingBottom: "10px" }}>
                  <Stack spacing={2} direction="row">
                    <LoadingButton
                      loading={loadingBtn}
                      onClick={handleGetDownloadSheet}
                      sx={{
                        color: "#ffff",
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Download
                    </LoadingButton>
                    <LoadingButton
                      loading={printloadingBtn}
                      sx={{
                        color: "#ffff",
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                      onClick={handleGetPrintPdf}
                    >
                      Print
                    </LoadingButton>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Card>

        <Box sx={{ margin: "15px 0" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: {
                  md: "flex-end",
                  xs: "center",
                  sm: "center",
                },
              }}
            >
              <Grid xs="auto" my={2}>
                <TextField
                  size="small"
                  fullWidth
                  value={hisSearch}
                  onChange={handleHisSearch}
                  label="Search Book"
                  variant="outlined"
                  placeholder="Search..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByHisIssueName}
                      className="class-table-header"
                    >
                      Name
                      <span style={{ cursor: "pointer" }}>
                        {sort.name === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByHisLibraryId}
                      className="class-table-header"
                    >
                      Library ID
                      <span style={{ cursor: "pointer" }}>
                        {sort.libraryId === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByHisTitle}
                      className="class-table-header"
                    >
                      Title
                      <span style={{ cursor: "pointer" }}>
                        {sort.title === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByHisBookID}
                      className="class-table-header"
                    >
                      Book ID
                      <span style={{ cursor: "pointer" }}>
                        {sort.bookId === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByHisIssueDate}
                      className="class-table-header"
                    >
                      Issue Date
                      <span style={{ cursor: "pointer" }}>
                        {sort.issueDate === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByHisReturn}
                      className="class-table-header"
                    >
                      Due Date
                      <span style={{ cursor: "pointer" }}>
                        {sort.returnDate === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByHisSubmittedDate}
                      className="class-table-header"
                    >
                      Return Date
                      <span style={{ cursor: "pointer" }}>
                        {sort.SubmittedDate === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  {/* <TableCell sx={{ color: "white" }} align="center">
          Return Date
        </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {hisSearch
                  ? hisSearchFilter.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo?.basicInfo?.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo?.basicInfo?.empId}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {data.submittedDate
                            ? dayjs(data.submittedDate).format("DD-MM-YYYY")
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    ))
                  : history.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo?.basicInfo?.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo?.basicInfo?.empId}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {data.submittedDate
                            ? dayjs(data.submittedDate).format("DD-MM-YYYY")
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {!history.length && !hisSearch.trim() && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}

            {hisSearch.trim() && !hisSearchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={hisSearch ? hisSearchFilter.length : history.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              "& .MuiTablePagination-spacer": {
                overflow: "none",
              },
            }}
          />
        </Box>
      </TabPanel>

      {/* ================ ISSUE BOOK MODEL =============== */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <ContainerBox>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
              fontWeight={600}
              // onChange={handleAddForm}
            >
              Issue Book
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    variant="standar"
                    label="Issue Date"
                    // openTo="day"
                    // views={["day", "month", "year"]}
                    inputFormat="DD-MM-YYYY"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small" // sx={{ m: 0.3, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel sx={{ fontSize: 8 }}>Select Book</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Select Book"
                    name="book"
                    value={addForm.book || ""}
                    onChange={handleAddForm}
                  >
                    {book.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.booktitle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small" // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  label="ISBN No"
                  name="isbnNo"
                  onChange={handleAddForm}
                  value={addForm.isbnNo || ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small" // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  label="Edison"
                  name="edison"
                  onChange={handleAddForm}
                  value={addForm.edison || ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  label="Author"
                  name="author"
                  value={addForm.author || ""}
                  onChange={handleAddForm}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  label="Language"
                  name="language"
                  value={addForm.language || ""}
                  onChange={handleAddForm}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small" // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  label="Price"
                  type="number"
                  name="price"
                  onChange={handleAddForm}
                  value={addForm.price || ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Quantity"
                  type="number"
                  name="quantity"
                  value={addForm.quantity || ""}
                  onChange={handleAddForm}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  label="Almira No"
                  name="almiraNo"
                  value={addForm.almiraNo || ""}
                  onChange={handleAddForm}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    variant="standar"
                    label="Return Date"
                    // openTo="day"
                    // views={["day", "month", "year"]}
                    inputFormat="DD-MM-YYYY"
                    value={edate}
                    onChange={(newValue) => {
                      setEDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small" // sx={{ m: 0.3, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel sx={{ fontSize: 8 }}>Select Member</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Select Member"
                    name="issuedTo"
                    value={addForm.issuedTo || ""}
                    onChange={handleAddForm}
                  >
                    {employee.map((data) => (
                      <MenuItem value={data._id}>
                        {data?.basicInfo?.empName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: { md: "right", sm: "right", xs: "center" },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    type="button"
                    color="error"
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Add
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </ContainerBox>
        </form>
      </Modal>
    </Wrapper>
  );
};

export default EmployeeIssue;
