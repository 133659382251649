import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserTypeContext from "./context/UserTypeContext";

const PublicRoute = ({ children }) => {
  const { userType } = useContext(UserTypeContext);
  const isAuth = window.localStorage.getItem(process.env.REACT_APP_TOKEN);

  const navDashboard = () => {
    toast.warning("Already login");
    return (
      <Navigate to={userType === "employee" ? "/dashboard" : "/dashboard/sd"} />
    );
  };
  return isAuth ? navDashboard() : children;
};

export default PublicRoute;
