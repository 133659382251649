import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TableContainer,
  TablePagination,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Menu, Search } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { row } from "@syncfusion/ej2-react-grids";
import Wrapper from "../Wrapper";
import SectionSubHeader from "../SectionSubHeader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadingButton } from "@mui/lab";
import Autocomplete from "@mui/material/Autocomplete";

const style = {
  position: "relative",
  width: "100%",

  height: "auto",
  bgcolor: "background.paper",
  maxWidth: { xs: "100%", sm: "350px", md: "350px", lg: "350px" },
  p: 2,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StudentCheckout = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [names, setNames] = useState([]);
  const [formData, setFormData] = useState([]);
  const [studentCheckOutDetais, setStudentCheckOutDetais] = useState([]);
  const [editStudentCheckOutDetais, setEditStudentCheckOutDetais] = useState();
  const [showDetails, setShowDetails] = useState(null);
  const [studentName, setStudentName] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(350));
  const [otploading, setOtpLoading] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [receiveOtp, setReceiveOtp] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [studentId, setStudentId] = useState("");
  console.log(receiveOtp, "receiveOtp");
  const [state, setState] = useState({
    visitorName: "",
    relationship: "",
    visitorPhone: "",
    checkOut: "",
  });

  let { visitorName, relationship, visitorPhone, checkOut } = state;

  const urls = appendStringToUrls(url, `${selectedSetting._id}`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loading, setLoading] = useState(false);
  const [searchPage, setSearchPage] = useState(0);

  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);

  const fetchedData = async () => {
    try {
      const { data } = await get(`${urls.studentCheckOuting.getAll}`, {
        headers: { roleFunction: "studentCheckout" },
      });
      setStudentCheckOutDetais(data.data);
      console.log(data.data, "all checkout");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchedData();
  }, [selectedSetting._id]);
  // table pagination============
  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };
  // ==========================

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowPage(true);
    setStudentName(
      names.filter((n) => n._id == formData.name)[0].basicInfo.name
    );
    setStudentId(formData.name);
  };
  console.log(studentId, "vnvnvnvnv");
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "studentCheckout" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "studentCheckout" },
        });
        let search = res.data.data.length
          ? { class: res.data.data[0]._id }
          : {};
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "studentCheckout" },
          params: {
            search,
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);

        setFormData((prev) => ({
          ...prev,
          className: res.data?.data[0]?._id,
          section: resSections.data?.data[0]?._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    const getStudents = async () => {
      console.log(formData, "Formdata");
      if (!formData.className || !formData.section) return;
      let payload = {};
      if (formData.section === "all") {
        payload = {
          academicYear: formData.academicYear,

          "academicInfo.class": formData.className,
        };
      } else {
        payload = {
          academicYear: formData.academicYear,
          "academicInfo.section": formData.section,
          "academicInfo.class": formData.className,
        };
      }
      const searchStudents = await get(urls.students.getAllStudent, {
        headers: { roleFunction: "studentCheckout" },
        params: {
          search: payload,
        },
      });
      console.log(searchStudents, "stdd");
      setNames(searchStudents.data.data);
    };
    getStudents();
  }, [formData.className, formData.section, selectedSetting._id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      let payload = {
        studentName,
        visitorName,
        relationship,
        visitorPhone,
      };
      console.log(payload, "payload");

      const res = await post(
        `${urls.studentCheckOuting.sendStudentCheckOutOtp}`,
        payload,
        {
          headers: { roleFunction: "studentCheckout" },
        }
      );
      // console.log(res);
      // setState({
      //   visitorName: "",
      //   relationship: "",
      //   visitorPhone: "",
      // });
      // setStudentName("");
      // handleClose();
      setLoader(false);
    } catch (error) {
      console.log(error);

      setLoader(false);
    }
  };
  const handleAddCheckOut = async (e) => {
    e.preventDefault();

    setSubmitLoader(true);

    try {
      let payload = {
        studentName,
        visitorName,
        relationship,
        visitorPhone,
        receiveOtp,
      };
      console.log(payload, "payload");

      const res = await put(
        `${urls.studentCheckOuting.sendStudentCheckOut}/${studentId}`,
        "",
        payload,
        {
          headers: { roleFunction: "studentCheckout" },
        }
      );
      console.log(res);
      setState({
        visitorName: "",
        relationship: "",
        visitorPhone: "",
      });
      setReceiveOtp("");
      setStudentName("");
      setStudentId("");
      handleClose();
      setSubmitLoader(false);
      fetchedData();
    } catch (error) {
      console.log(error);

      setSubmitLoader(false);
    }
  };

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "studentCheckout" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setShowPage(false);
    setState({
      visitorName: "",
      relationship: "",
      visitorPhone: "",
    });
    setStudentName("");
  };
  const handleClickOpenDialog = (value) => {
    setOpenDialog(true);
    setShowDetails(value);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    button: {
      color: "white",
      backgroundColor: "#1b3779",
      ":hover": { backgroundColor: "#1b3779" },
    },
    cardButton: {
      display: "flex",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };

  const handleCancel = () => {
    setShowPage(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      studentCheckOutDetais.length > 0 &&
        setSearchFilter(
          studentCheckOutDetais.filter((ele) =>
            ele.student?.basicInfo?.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/visitorstudent/downloadStudentCheckoutPdf`,
        {
          headers: { roleFunction: "visitory" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Student Checkout" />
      <div style={{ margin: "15px 0" }}>
        <Card style={{ margin: "15px 0px 15px 0px", padding: 10 }}>
          <Box>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2} marginBottom={"5px"}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small">
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      size="small"
                      id="demo-simple-select-filled"
                      name="className"
                      value={formData.className || ""}
                      onChange={handleClassChange}>
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      size="small"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}>
                      {formData.className && (
                        <MenuItem value="all">All</MenuItem>
                      )}
                      {sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}>
                          {row.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  style={{ alignSelf: "flex-end" }}>
                  {/* <InputLabel>Student Name</InputLabel>
                    <Select
                      label="Student Name"
                      size="small"
                      id="demo-simple-select-filled"
                      name="name"
                      value={formData.name || ""}
                      onChange={handleOnChange}
                    >
                      {names.map((row, index) => (
                        <MenuItem key={index} value={row._id}>
                          {row.basicInfo.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                  <Autocomplete
                    fullWidth
                    value={
                      formData.name
                        ? names.find((student) => student._id === formData.name)
                        : null
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          name: newValue._id,
                        }));
                      } else {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          name: "",
                        }));
                      }
                    }}
                    options={names}
                    getOptionLabel={(option) => option.basicInfo?.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Student"
                        variant="outlined"
                        size="small"
                        placeholder="Search student with name"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  gap={2}
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                    type="submit">
                    Find
                  </Button>
                  <LoadingButton
                    onClick={handleGetPrintPdf}
                    loading={loading}
                    variant="contained"
                    size="small"
                    style={styles.button}>
                    Pdf
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>

        {/* {loading ? (
            <div>
              <CircularProgress />
            </div>
          ) : showPage ? ( */}

        {/* )
           : (
            // Show the form when loading is false and showPage is false

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Generate OTP"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="verified OTP"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Button
                  sx={{
                    color: "white",
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  // onClick={handleButtonClick}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          ) 
          }*/}

        {/* <Card style={{ padding: "10px", margin: "10px 0px" }}>
          <div style={styles.cardButton}>
            <Button variant="contained" size="small" style={styles.button}>
              Excel
            </Button>
            <Button variant="contained" size="small" style={styles.button}>
              Pdf
            </Button>
          </div>
        </Card> */}

        {/* <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          my={2}>
          <Box style={styles.cardButton} ml={1}>
            <Button variant="contained" size="small" style={styles.button}>
              Excel
            </Button>
            <Button
              onClick={handleGetPrintPdf}
              variant="contained"
              size="small"
              style={styles.button}>
              Pdf
            </Button>
          </Box>
          <Grid item xs="auto">
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search Name"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "5px",
          }}>
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            label="Search Name"
            placeholder="Enter Name"
            variant="outlined"
            sx={{ mt: { xs: 2, md: 0 } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Class
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Section
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Check Out
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Parent Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Relation
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Visitor Contact Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((value, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {value.student?.basicInfo?.name}
                        </TableCell>
                        <TableCell align="center">
                          {value.student.academicInfo.class.className}
                        </TableCell>
                        <TableCell align="center">
                          {value.student.academicInfo.section.sectionName}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(value.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell align="center">
                          {value.visitorName}
                        </TableCell>
                        <TableCell align="center">
                          {value.relationship}
                        </TableCell>
                        <TableCell align="center">
                          {value.visitorContactNumber}
                        </TableCell>
                        <TableCell align="center">
                          <div className="edit-delete">
                            {/* <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleEdit(value._id, value)}
                          >
                            <EditIcon />
                          </Button> */}
                            <Tooltip title="View">
                              <IconButton
                                color="primary"
                                size="small"
                                style={{ color: "#1b3779" }}
                                onClick={() => handleClickOpenDialog()}>
                                <VisibilityIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                            {/* <Button
                             onClick={() => handleDelete(value._id)}
                            style={{
                              color: "#1b3779",
                            }}
                          >
                            <Delete />
                          </Button> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                : studentCheckOutDetais
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((value, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {value.student.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {value.student.academicInfo.class.className}
                        </TableCell>
                        <TableCell align="center">
                          {value.student.academicInfo.section.sectionName}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(value.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell align="center">
                          {value.visitorName}
                        </TableCell>
                        <TableCell align="center">
                          {value.relationship}
                        </TableCell>
                        <TableCell align="center">
                          {value.visitorContactNumber}
                        </TableCell>
                        <TableCell align="center">
                          <div className="edit-delete">
                            {/* <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleEdit(value._id, value)}
                          >
                            <EditIcon />
                          </Button> */}
                            <Tooltip title="View">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => handleClickOpenDialog(value)}>
                                <VisibilityIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>

                            {/* <Button
                            //  onClick={() => handleDelete(value._id, value)}
                            style={{
                              color: "#1b3779",
                            }}
                          >
                            <Delete />
                          </Button> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!studentCheckOutDetais.length && !search.trim() && (
            <Typography
              variant="h6"
              style={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : studentCheckOutDetais.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        {/*== View Modal =========== */}
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}>
            Visitor Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <>
                    <tr>
                      <td>School Name</td>
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td> Name</td>
                      <td>{showDetails.student.basicInfo.name}</td>
                    </tr>
                    <tr>
                      <td>class</td>
                      <td>
                        {showDetails.student.academicInfo.class.className}
                      </td>
                    </tr>
                    <tr>
                      <td>Section</td>
                      <td>
                        {showDetails.student.academicInfo.section.sectionName}
                      </td>
                    </tr>
                    <tr>
                      <td>Visitor Name</td>
                      <td>{showDetails.visitorName}</td>
                    </tr>
                    <tr>
                      <td>Relation</td>
                      <td>{showDetails.relationship}</td>
                    </tr>
                    <tr>
                      <td>Visitor Contact Number</td>
                      <td>{showDetails.visitorContactNumber}</td>
                    </tr>
                    <tr>
                      <td>Check Out</td>
                      <td>
                        {new Date(showDetails.createdAt).toLocaleString()}
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
        {/* ======================== */}

        {/* ========== Add Student Checkout ========= */}
        <Dialog
          fullScreen={fullScreen}
          open={showPage}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box sx={style}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontWeight="bold"
                    fontSize="20px">
                    {/* {editStudentCheckOutDetais
                  ? "Update Student Checkout"
                  : "Add Student Checkout"} */}
                    Add Student Checkout
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    disabled={true}
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Name"
                    name="studentName"
                    value={studentName}
                    // onChange={handleChange}
                    // sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    fullWidth
                    label="Relationship"
                    name="relationship"
                    value={relationship}
                    onChange={handleChange}
                    // sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid> */}

                <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 8 }}>Relationship</InputLabel>
                    <Select
                      label="Reason To Meet"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="relationship"
                      value={relationship}
                      onChange={handleChange}>
                      <MenuItem value={"vendor"}>Vendor</MenuItem>
                      <MenuItem value={"relative"}>Relative</MenuItem>
                      <MenuItem value={"friend"}>Friend</MenuItem>
                      <MenuItem value={"guardian"}>Guardian</MenuItem>
                      <MenuItem value={"family"}>Family</MenuItem>
                      <MenuItem value={"interview"}>InterView</MenuItem>
                      <MenuItem value={"meeting"}>Meeting</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    fullWidth
                    label="Visitor Name"
                    name="visitorName"
                    value={visitorName}
                    onChange={handleChange}
                    // sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    fullWidth
                    label="Visitor Phone number"
                    name="visitorPhone"
                    type="number"
                    value={visitorPhone}
                    onChange={handleChange}
                    // sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      mt: 2,
                    }}>
                    <LoadingButton
                      loading={loader}
                      size="small"
                      variant="contained"
                      sx={{ color: "#fff" }}
                      onClick={handleSubmit}>
                      Send OTP
                    </LoadingButton>
                  </Grid>
                  {editStudentCheckOutDetais ? (
                    <TextField
                      required
                      id="filled-required"
                      size="small"
                      fullWidth
                      label="Check Out"
                      // type="time"
                      name="checkOut"
                      value={state.checkOut}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // inputProps={{ readOnly: Boolean(editStudentCheckOutDetais) }}
                      // sx={{
                      //   m: 1,
                      //   width: { md: 227, xs: "95%" },
                      //   label: { fontSize: 12 },
                      // }}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Enter Received OTP"
                    variant="outlined"
                    size="small"
                    value={receiveOtp}
                    name="receiveOtp"
                    onChange={(e) => {
                      setReceiveOtp(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  gap={1}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    color="error"
                    type="button"
                    sx={{ color: "#fff" }}>
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={submitLoader}
                    variant="contained"
                    size="small"
                    type="submit"
                    onClick={handleAddCheckOut}
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}>
                    {/* {editStudentCheckOutDetais ? "Update" : "Submit"} */}
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>
      </div>
    </Wrapper>
  );
};

export default StudentCheckout;
