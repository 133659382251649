import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
  useMediaQuery,
  Select,
  Divider,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
// icons
import { Add, Close, Search, Visibility } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
// custome components
import { get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import appendStringToUrls from "../../../utils/appendString";
import SettingContext from "../../../context/SettingsContext";
import ShowComponent from "../../ShowComponent";
import css from "../../../styles/fees/ClassMap.module.css";
import moment from "moment";

function removeElementFromArray(array, elementToRemove) {
  const index = array.indexOf(elementToRemove);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

const MuiTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  margin: "5px",
  padding: "5px",
}));

const MuiText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1b3779",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const Label = styled("label")(() => ({
  fontWeight: 600,
  fontSize: "15px",
  color: "#424242",
}));

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const LABEL = {
  classOld: "Class-Old",
  classNew: "Class-New",
  class: "Class",
  transportCategory: "Transport Category",
};

const showInfo = (data) => {
  let result = [];

  for (let dep of data.dependencies) {
    if (dep === "academicYear") {
      result.push(
        `[${data.academicYearId.academicYearFrom}-${data.academicYearId.academicYearTo}]-Academic Year`
      );
    } else if (dep === "class") {
      let newItem = `[${data.classes
        ?.map((c) => c.className)
        ?.join(",")}]-Class`;
      result.push(newItem);
    } else if (dep === "classOld") {
      let newItem = `[${data.classes
        ?.map((c) => c.className)
        ?.join(",")}]-Class-Old`;
      result.push(newItem);
    } else if (dep === "classNew") {
      let newItem = `[${data.classes
        ?.map((c) => c.className)
        ?.join(",")}]-Class-New`;
      result.push(newItem);
    } else if (dep === "transportCategory") {
      let newItem = `[${data.transportCategory}]-Transport Category`;
      result.push(newItem);
    }
  }

  return result.join(" | ");
};

function FeeMap({ selectedClassMap }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [selectReceipt, setSelectReceipt] = useState(selectedClassMap || "");
  const [installmentType, setInstallMentType] = useState([
    {
      id: 1,
      label: "Monthly",
    },
    {
      id: 2,
      label: "Quaterly",
    },
    {
      id: 3,
      label: "Half-Yearly",
    },
    {
      id: 4,
      label: "Yearly",
    },
    // {
    //   id: 5,
    //   label: "Others",
    // },
  ]);

  const [addForm, setAddForm] = useState({});
  const [receipts, setReceipts] = useState([]);
  const [feeMaps, setFeeMaps] = useState([]);
  const [filteredFeeMaps, setFiltereredFeeMaps] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [editClassMap, setEditClassMap] = useState("");
  const [installments, setInstallments] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [showFeeMap, setShowFeeMap] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState({});
  const [classes, setClasses] = useState([]);
  const [allDependencies, setAllDependencies] = useState([]);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // =======================

  useEffect(() => {
    const init = async () => {
      try {
        const { data: allReceipts, status } = await get(
          `${urls.receiptTitle.list}`,
          {
            headers: { roleFunction: "feeMap" },
          }
        );
        if (status > 199 && status < 299) {
          setReceipts(allReceipts.data);
          if (allReceipts.data.length > 0) {
            setSelectReceipt(
              selectReceipt ? selectReceipt : allReceipts.data[0]._id
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getSchoolClasses = async () => {
      try {
        const { data: schoolClassesList, status } = await get(
          `${urls.class.getAllClass}`,
          {
            headers: { roleFunction: "receiptTitle" },
          }
        );
        if (status > 199 && status < 299) {
          setSchoolClasses(schoolClassesList.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
    getSchoolClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    if (selectReceipt) {
      const fetchClassMaps = async () => {
        try {
          await getFeeMaps();
        } catch (error) {}
      };
      fetchClassMaps();
    }
  }, [selectReceipt, selectedSetting._id]);

  const getFeeMaps = async () => {
    try {
      const { data: classMapList, status } = await get(
        `${urls.feeMap.list}/${selectReceipt}`,
        {
          headers: { roleFunction: "feeMap" },
        }
      );
      if (status > 199 && status < 299) {
        setFeeMaps(classMapList.data);
        setFiltereredFeeMaps(classMapList.data);
      }
    } catch (error) {
      return error;
    }
  };

  const resetForm = () => {
    setAddForm({});
    setEditClassMap("");
    setInstallments([]);
    setDependencies([]);
  };

  const handleCloseAddDialog = () => {
    setAddDialog(false);
  };

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    if (name == "fee") {
      setAddForm((prev) => ({
        ...prev,
        [name]: parseInt(value),
      }));
    } else if (name == "others") {
      setAddForm((prev) => ({
        ...prev,
        [name]: Math.ceil(value),
      }));
    } else {
      setAddForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (!addForm.pickType) {
      removeElementFromArray(dependencies, "pickType");
    }
    try {
      if (!editClassMap) {
        const { status } = await post(
          `${urls.feeMap.create}`,
          {
            receiptTitleId: selectReceipt,
            collectedFrom: "student",
            dependencies: dependencies,
            classIds: addForm.schoolClass,
            fee: addForm.fee,
            installments: installments,
            transportCategory: addForm.transportCategory,
            installmentType: addForm.installmentType,
            title: addForm.title,
            description: addForm.description,
            priority: addForm.priority,
          },
          {
            headers: { roleFunction: "feeMap" },
          }
        );

        setSearch({});
        if (status > 199 && status < 299) {
          await getFeeMaps();
          resetForm();
          // handleCloseAddDialog();
        }
      } else {
        const { status } = await put(
          `${urls.feeMap.update}${editClassMap}`,
          "",
          {
            receiptTitleId: selectReceipt,
            collectedFrom: "student",
            dependencies: dependencies,
            classIds: addForm.schoolClass,
            fee: addForm.fee,
            installments: installments,
            transportCategory: addForm.transportCategory,
            installmentType: addForm.installmentType,
            title: addForm.title,
            description: addForm.description,
            priority: addForm.priority,
          },
          {
            headers: { roleFunction: "feeMap" },
          }
        );
        setSearch({});
        if (status > 199 && status < 299) {
          await getFeeMaps();
          resetForm();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
    setLoader(false);
  };

  const handleClassMapEdit = (id, data) => {
    setEditClassMap(id);
    setAddForm({
      ...data,
      schoolClass: data.classes.map((c) => c._id),
      installmentType: data.installmentType,
    });
    setDependencies(data.dependencies);
    setInstallments(data?.installments);
    handleOpenAddDialog();
  };

  const handleAddInstallments = () => {
    if (!addForm.installmentType || !addForm.fee) return;
    let installments = [];

    if (addForm.installmentType === "Monthly") {
      const monthlyAmount = Math.floor(addForm.fee / 12);
      const missing = addForm.fee - monthlyAmount * 12;

      // console.log(missing, "missing");
      installments = Array.from({ length: 12 }).map((v, i) => ({
        id: i + 1,
        amount: monthlyAmount,
        missing: missing,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Quaterly") {
      const quarterlyAmount = Math.floor(addForm.fee / 4);
      const missing = addForm.fee - quarterlyAmount * 4;

      installments = Array.from({ length: 4 }).map((v, i) => ({
        id: i + 1,
        amount: quarterlyAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Half-Yearly") {
      const halfYearlyAmount = Math.floor(addForm.fee / 2);
      const missing = addForm.fee - halfYearlyAmount * 2;

      installments = Array.from({ length: 2 }).map((v, i) => ({
        id: i + 1,
        amount: halfYearlyAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Yearly") {
      installments = Array.from({ length: 1 }).map((v, i) => ({
        id: i,
        amount: addForm.fee,
        dueDate: dayjs(),
      }));
    } else if (addForm.installmentType === "Others") {
      const othersAmount = Math.floor(addForm.fee / addForm.others);
      const missing = addForm.fee - othersAmount * addForm.others;

      installments = Array.from({ length: addForm.others }).map((v, i) => ({
        id: i + 1,
        amount: othersAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else {
      setInstallments([]);
      return;
    }
    setInstallments(installments);
  };

  useEffect(() => {
    handleAddInstallments();
  }, [addForm.installmentType, addForm.others, addForm.fee]);

  const handleInstallmentChange = (val, key, changeIndex) => {
    setInstallments((prev) =>
      prev.map((installment, index) => {
        if (index === changeIndex) {
          return key === "amount"
            ? { ...installment, [key]: parseInt(val) }
            : key === "dueDate"
            ? { ...installment, [key]: val }
            : installment;
        } else if (index > changeIndex) {
          let diffInMonths = index - changeIndex;
          if (addForm.installmentType === "Quaterly") {
            diffInMonths = diffInMonths * 3;
          } else if (addForm.installmentType === "Half-Yearly") {
            diffInMonths = diffInMonths * 6;
          } else if (addForm.installmentType === "Others") {
            diffInMonths = diffInMonths * 1;
          }
          const updatedDueDate = dayjs(val)
            .add(diffInMonths, "month")
            .format("YYYY-MM-DD");
          return { ...installment, dueDate: updatedDueDate };
        } else {
          return installment;
        }
      })
    );
  };

  const handleStatusChange = async (id) => {
    try {
      const { status } = await put(
        `${urls.feeMap.updateStatus}${id}`,
        "",
        {},
        { headers: { roleFunction: "feeMap" } }
      );
      if (status > 199 && status < 299) {
        await getFeeMaps();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (chipToDelete) => {
    setDependencies((chips) => chips.filter((chip) => chip !== chipToDelete));
    if (chipToDelete == "class") {
      setAddForm((prev) => ({ ...prev, schoolClasses: "" }));
    }
    if (chipToDelete == "classOld") {
      setAddForm((prev) => ({ ...prev, schoolClasses: "" }));
    }
    if (chipToDelete == "classNew") {
      setAddForm((prev) => ({ ...prev, schoolClasses: "" }));
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value, "value=========");
    setDependencies(typeof value === "string" ? value.split(",") : value);
  };

  const getClasses = async () => {
    try {
      const { data } = await get(urls.class.getAllClass, {
        headers: { roleFunction: "feeMap" },
      });

      setClasses(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (filteredFeeMaps.length) {
  //     let deps = [];
  //     for (let feeMap of filteredFeeMaps) {
  //       deps = [...deps, ...feeMap.extendedDependencies];
  //     }

  //     let uniqueDeps = new Set(deps);
  //     setAllDependencies([...uniqueDeps]);
  //   }
  // }, [filteredFeeMaps]);

  useEffect(() => {
    getClasses();
  }, []);

  const handleClickToView = (item) => {
    setShowFeeMap(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handelSelectSearch = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  useEffect(() => {
    if (dependencies.includes("class")) {
      setDependencies([
        ...dependencies.filter((d) => !["classOld", "classNew"].includes(d)),
      ]);
    } else if (dependencies.includes("classNew")) {
      setDependencies([
        ...dependencies.filter((d) => !["class", "classOld"].includes(d)),
      ]);
    } else if (dependencies.includes("classOld")) {
      setDependencies([
        ...dependencies.filter((d) => !["class", "classNew"].includes(d)),
      ]);
    }
  }, [dependencies]);

  return (
    <>
      <Box className={css.funcContainer}>
        <FormControl
          sx={{ width: { xs: "100%", sm: "100%", md: "30%", lg: "20%" } }}
          fullWidth
          size="small"
        >
          <InputLabel>Select Receipt</InputLabel>
          <Select
            fullWidth
            value={selectReceipt || ""}
            onChange={(e) => setSelectReceipt(e.target.value)}
            label="Select Receipt"
          >
            {receipts.map((receipt) => (
              <MenuItem value={receipt._id} key={receipt._id || ""}>
                {receipt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={handleOpenAddDialog}
          size="small"
          variant="contained"
          startIcon={<Add />}
        >
          Add Fee Particular
        </Button>
      </Box>
      {/* <Box
        sx={{
          background: "#bebebe36",

          marginBottom: 2,
          padding: "15px 10px 5px 10px",
          borderRadius: "5px",
        }}
      >
        <Label id="demo-simple-select-label">
          <FilterListIcon fontSize="small" />
          Filter
        </Label>

        <Grid container spacing={1} mb={2}>
          {(allDependencies.includes("class") ||
            allDependencies.includes("classNew") ||
            allDependencies.includes("classOld")) && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Class</InputLabel>
                <Select
                  multiple
                  name="className"
                  value={search.className || []}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select Class"
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem key={row._id} value={row._id || ""}>
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {allDependencies.includes("transportCategory") && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Transport Category</InputLabel>
                <Select
                  name="transportCategory"
                  value={search.transportCategory || ""}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select Transport Category"
                >
                  <MenuItem value="Category1">Category1</MenuItem>
                  <MenuItem value="Category2">Category2</MenuItem>
                  <MenuItem value="Category3">Category3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {Object.keys(search).filter((v) => search[v]).length ? (
          <Button
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              setSearch({});
              setFiltereredFeeMaps(feeMaps);
            }}
          >
            Clear Filter
          </Button>
        ) : null}
      </Box> */}

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Priority
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Title
              </TableCell>

              <TableCell
                sx={{ color: "white", maxWidth: "150px" }}
                align="center"
              >
                Collected From
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Fee
              </TableCell>
              {/* <TableCell sx={{ color: "white" }} align="center">
                Installments
              </TableCell> */}
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFeeMaps
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((feeMap, index) => (
                <TableRow
                  key={feeMap._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{feeMap.priority}</TableCell>
                  <TableCell align="center">{feeMap.title}</TableCell>
                  <TableCell sx={{ maxWidth: "150px" }} align="center">
                    {showInfo(feeMap)}
                  </TableCell>
                  <TableCell align="center">₹{feeMap.fee}</TableCell>

                  {/* <TableCell align="center">
                    <Tooltip title="View">
                      <Button
                        color="primary"
                        endIcon={<Visibility fontSize="small" />}
                        onClick={() => handleClickToView(feeMap?.installments)}
                      >
                        {feeMap.installments?.length}
                      </Button>
                    </Tooltip>
                  </TableCell> */}
                  <TableCell align="center">
                    <div className={css.tableActions}>
                      <ShowComponent roleFunction="feeMap" action="update">
                        <Button
                          onClick={() => handleClassMapEdit(feeMap._id, feeMap)}
                          size="small"
                          variant="contained"
                        >
                          edit
                        </Button>
                      </ShowComponent>
                      <ShowComponent roleFunction="feeMap" action="update">
                        <Button
                          color={!feeMap.active ? "success" : "error"}
                          onClick={() => handleStatusChange(feeMap._id)}
                          size="small"
                          variant="contained"
                        >
                          {feeMap.active ? "Deactivate" : "Activate"}
                        </Button>
                      </ShowComponent>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!filteredFeeMaps.length && (
          <MuiTypography variant="h6">No data found</MuiTypography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredFeeMaps.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* ======= View Fee Map =================== */}
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{
            textAlign: "center",
          }}
        >
          Installment Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>

        {/* ======== Desktop view table ============ */}
        <DialogContent dividers>
          <Table aria-label="customized table" className="profile-table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                  #SL
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Amount
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", width: 200 }}
                >
                  Due Date
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showFeeMap.map((showData, i) => (
                <>
                  <TableRow>
                    <StyledTableCell align="center">{i + 1}</StyledTableCell>
                    <StyledTableCell align="center">
                      ₹{" "}
                      {showData.amount && showData.amount
                        ? showData.amount
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dayjs(
                        showData.dueDate && showData.dueDate
                          ? showData.dueDate
                          : "NA"
                      ).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>

      {/* ======= Add/Update Fee Map ============= */}
      <Dialog
        fullScreen={fullScreen}
        open={addDialog}
        onClose={handleCloseAddDialog}
      >
        <form onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MuiText>
                  {editClassMap ? "Update" : "Add"} Fee Particular
                </MuiText>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="body">
                  Choose the dependencies that will serve as the basis for the
                  fee calculation.
                </Typography>
                <FormControl size="small" sx={{ mt: 2, width: 400 }}>
                  <InputLabel id="demo-multiple-chip-label">
                    Choose the dependencies
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    size="small"
                    multiple
                    label="Select the dependencies"
                    value={dependencies}
                    onChange={handleChange}
                  >
                    {Object.keys(LABEL).map((name) => (
                      <MenuItem key={name} value={name}>
                        {LABEL[name]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {dependencies && (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {dependencies.map((value, i) => (
                      <Chip
                        sx={{ mt: 2 }}
                        key={value}
                        label={LABEL[value]}
                        onDelete={() => handleDelete(value)}
                      />
                    ))}
                  </Box>
                )}
              </Grid>

              {(dependencies.includes("class") ||
                dependencies.includes("classOld") ||
                dependencies.includes("classNew")) && (
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Class</InputLabel>
                    <Select
                      size="small"
                      name="schoolClass"
                      required
                      multiple
                      value={addForm.schoolClass || []}
                      onChange={handleAddForm}
                      label="Select Class"
                    >
                      {schoolClasses.map((schoolClass) => (
                        <MenuItem key={schoolClass._id} value={schoolClass._id}>
                          {schoolClass.className}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {dependencies.includes("transportCategory") && (
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Transport Category</InputLabel>
                    <Select
                      size="small"
                      name="transportCategory"
                      required
                      value={addForm.transportCategory || ""}
                      onChange={handleAddForm}
                      label="Select Transport Category"
                    >
                      <MenuItem value="Category1">Category1</MenuItem>
                      <MenuItem value="Category2">Category2</MenuItem>
                      <MenuItem value="Category3">Category3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Title"
                  type="text"
                  required
                  name="title"
                  value={addForm.title || ""}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Description"
                  type="text"
                  name="description"
                  value={addForm.description || ""}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Priority"
                  type="number"
                  name="priority"
                  value={addForm.priority || ""}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Fee"
                  type="number"
                  required
                  name="fee"
                  value={addForm.fee || ""}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Installment Type</InputLabel>
                  <Select
                    size="small"
                    name="installmentType"
                    value={addForm.installmentType || ""}
                    onChange={handleAddForm}
                    label="Installment Type"
                  >
                    {installmentType.map((installmentType) => (
                      <MenuItem
                        key={installmentType.id}
                        value={installmentType.label}
                      >
                        {installmentType.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                {addForm.installmentType == "Others" && (
                  <TextField
                    // sx={{ marginTop: 1 }}
                    fullWidth
                    label="Number of installments"
                    size="small"
                    name="others"
                    type="number"
                    value={addForm.others || ""}
                    onChange={handleAddForm}
                  />
                )}
              </Grid>

              {installments.map((installment, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    display="flex"
                    gap={2}
                  >
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label={`installment ${index + 1}`}
                        value={installment.amount}
                        size="small"
                        enabled={editClassMap}
                        onChange={(e) =>
                          handleInstallmentChange(
                            e.target.value,
                            "amount",
                            index
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="DD-MM-YYYY"
                          enabled={editClassMap}
                          label="Due Date"
                          form
                          value={installment.dueDate}
                          onChange={(newValue) =>
                            handleInstallmentChange(newValue, "dueDate", index)
                          }
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              enabled={editClassMap}
                              {...params}
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                gap={2}
              >
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    resetForm();
                    handleCloseAddDialog();
                  }}
                >
                  Close
                </Button>

                <LoadingButton
                  loading={loader}
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                    color: "#fff",
                  }}
                >
                  {editClassMap ? "update" : "add"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      {/* =========================== */}
    </>
  );
}

export default FeeMap;
