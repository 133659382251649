import {
  Autocomplete,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TableContainer,
  TablePagination,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Menu, Search } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";
import SectionSubHeader from "../SectionSubHeader";
import Wrapper from "../Wrapper";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import ShowComponent from "../ShowComponent";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const styles = {
  button: {
    color: "white",
    backgroundColor: "#1b3779",
    ":hover": { backgroundColor: "#1b3779" },
  },
  card: {
    padding: 10,
    margin: "10px 0px",
  },
  cardButton: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },

  textfield: {
    marginTop: "25px",
    width: "70%",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
  tab: {
    width: "100%",
    borderCollapse: "collapse",
  },
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const VisitoryInfo = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);

  const [search, setSearch] = useState("");
  const [schoolEmployee, setSchoolEmployee] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editVisitorInfo, setEditVisitorInfo] = useState();
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [schoolUserRole, setSchoolUserRole] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setChcekOut] = useState("");

  const [allEmployees, setAllEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("");
  const [departments, setDepartment] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employeeAttendances, setEmployeeAttendances] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const [roleName, setRoleName] = useState("");
  const [classes, setClasses] = useState([]);
  const [section, setSection] = useState([]);
  const [student, setStudent] = useState([]);
  const [selectStudent, setSelectStudent] = useState("");
  const [sort, setSort] = useState({
    name: "asc",
    tomeet: "asc",
    reasonToMeet: "asc",
    checkIn: "asc",
    checkOut: "asc",
  });

  const sortByName = () => {
    if (sort.name == "asc") {
      const sortedList = [...list.sort((a, b) => b.name.localeCompare(a.name))];
      setList(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.name.localeCompare(b.name))];
      setList(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByToMeet = () => {
    if (sort.tomeet == "asc") {
      const sortedList = [
        ...list.sort(
          (a, b) =>
            b.toMeetUser.basicInfo.empName ||
            b.toMeetUser.basicInfo.name.localeCompare(
              a.toMeetUser.basicInfo.empName || a.toMeetUser.basicInfo.name
            )
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, tomeet: "des" });
    } else {
      const sortedList = [
        ...list.sort(
          (a, b) =>
            a.toMeetUser.basicInfo.empName ||
            a.toMeetUser.basicInfo.name.localeCompare(
              b.toMeetUser.basicInfo.empName || b.toMeetUser.basicInfo.name
            )
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, tomeet: "asc" });
    }
  };

  const sortByReasonToMeet = () => {
    if (sort.reasonToMeet == "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.reasonToMeet.localeCompare(a.reasonToMeet)),
      ];
      setList(sortedList);
      setSort({ ...sort, reasonToMeet: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.reasonToMeet.localeCompare(b.reasonToMeet)),
      ];
      setList(sortedList);
      setSort({ ...sort, reasonToMeet: "asc" });
    }
  };

  const sortByCheckIn = () => {
    if (sort.checkIn == "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.checkIn.localeCompare(a.checkIn)),
      ];
      setList(sortedList);
      setSort({ ...sort, checkIn: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.checkIn.localeCompare(b.checkIn)),
      ];
      setList(sortedList);
      setSort({ ...sort, checkIn: "asc" });
    }
  };

  const sortByCheckOut = () => {
    if (sort.checkOut == "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.checkOut.localeCompare(a.checkOut)),
      ];
      setList(sortedList);
      setSort({ ...sort, checkOut: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.checkOut.localeCompare(b.checkOut)),
      ];
      setList(sortedList);
      setSort({ ...sort, checkOut: "asc" });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole, {
          headers: { roleFunction: "visitory" },
        });
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, [selectedSetting]);

  useEffect(() => {
    setEmployees([]);
    setSelectEmployee("");
    setAddForm({});
  }, [selectedSetting]);

  useEffect(() => {
    const schoolEmployeeGet = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "visitory" },
          params: {
            limit: 10,
            page: 1,
          },
        });
        setSchoolEmployee(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const schoolEmployeeGetAll = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "visitory" },
        });
        setAllEmployees(response.data.data);
        // console.log(response.data.data, "alllllll");
      } catch (error) {
        console.log(error);
      }
    };

    const getAllDepartment = async () => {
      try {
        const departments = await get(urls.department.getDepartment, {
          headers: { roleFunction: "visitory" },
        });
        setDepartment(departments.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDepartment();
    schoolEmployeeGet();
    schoolEmployeeGetAll();
  }, [selectedSetting._id]);
  console.log(classes, "classes77");
  const handleRoleChange = async (e) => {
    try {
      const { name, value } = e.target;
      setAddForm((prev) => ({
        ...prev,
        [name]: value,
      }));

      const getRole = await get(`${urls.role.getOneRole}/${value}`);

      setRoleName(getRole.data.data.roleName);
      if (getRole.data.data.roleName == "STUDENT") {
        const getClass = await get(urls.class.getAllClass, {
          headers: { roleFunction: "visitory" },
        });
        console.log(getClass, "getCLasss");
        setClasses(getClass.data.data);
      } else {
        const getEmployees = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "visitory" },
          params: {
            search: {
              role: value,
            },
          },
        });
        if (getEmployees.data.success) setEmployees(getEmployees.data.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleClassChange = async (e) => {
    try {
      const { name, value } = e.target;
      setAddForm((prev) => ({
        ...prev,
        [name]: value,
      }));

      const getSection = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "visitory" },
        params: {
          search: {
            class: value,
          },
        },
      });
      console.log(getSection, "getSection");
      if (getSection.data.success) setSection(getSection.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleSectionChange = async (e) => {
    try {
      const { name, value } = e.target;
      setAddForm((prev) => ({
        ...prev,
        [name]: value,
      }));

      const getSection = await get(urls.students.getAllStudent, {
        headers: { roleFunction: "visitory" },
        params: {
          search: {
            "academicInfo.section": value,
          },
        },
      });
      console.log(getSection, "getSection");
      if (getSection.data.success) setStudent(getSection.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleEmployeeChange = (e, val) => {
    setSelectEmployee(e.target.value);
  };
  const handleStudent = (e) => {
    setSelectStudent(e.target.value);
  };

  useEffect(() => {
    getVisitorInfo();
  }, [selectedSetting._id]);

  const getVisitorInfo = async () => {
    try {
      const { data, status } = await get(`${urls.visitorInfo.getList}`, {
        headers: { roleFunction: "visitory" },
      });

      setList(data.data);
      console.log(data.data, "vvvvvvsss");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let res;
      if (!editVisitorInfo) {
        res = await post(
          `${urls.visitorInfo.create}`,
          {
            name: addForm.name,
            phone: addForm.phone,
            comingForm: addForm.comingForm,
            toMeetUserType: addForm.toMeetUserType,
            toMeetUser: selectEmployee || selectStudent,
            reasonToMeet: addForm.reasonToMeet,
            note: addForm.note,
            // toMeetStudentUser: selectStudent,
            classes: addForm.class,
            section: addForm.section,
            roleName: roleName,
          },
          { headers: { roleFunction: "visitory" } }
        );
      } else {
        res = await put(
          `${urls.visitorInfo.update}${editVisitorInfo}`,
          "",
          {
            name: addForm.name,
            phone: addForm.phone,
            comingForm: addForm.comingForm,
            toMeetUserType: addForm.toMeetUserType,
            toMeetUser: selectEmployee || selectStudent,
            reasonToMeet: addForm.reasonToMeet,
            note: addForm.note,
            checkIn: addForm.checkIn,
            // toMeetStudentUser: selectStudent,
            classes: addForm.class,
            section: addForm.section,
            checkOut: addForm.checkOut,
            roleName: roleName,
          },
          { headers: { roleFunction: "visitory" } }
        );
      }

      if (res.data.success) {
        getVisitorInfo();
        handleClose();
        setLoader(false);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleCheckIn = (e) => {
    setCheckIn(e.target.value);
  };

  const handleCheckOut = (e) => {
    setChcekOut(e.target.value);
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.visitorInfo.delete}`,
        id,
        {},
        { roleFunction: "visitory" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVisitorInfoEdit = async (id, data) => {
    const getClass = await get(urls.class.getAllClass, {
      headers: { roleFunction: "visitory" },
    });
    console.log(getClass, "getCLasss");
    setClasses(getClass.data.data);
    const getSection = await get(urls.schoolSection.getAllSection, {
      headers: { roleFunction: "visitory" },
      params: {
        search: {
          class: data.classes,
        },
      },
    });
    console.log(getSection, "getSection");
    setSection(getSection.data.data);
    const getStudent = await get(urls.students.getAllStudent, {
      headers: { roleFunction: "visitory" },
      params: {
        search: {
          "academicInfo.class": data.classes,
          "academicInfo.section": data.section,
        },
      },
    });
    console.log(getStudent, "getStudent");
    setStudent(getStudent.data.data);
    const getEmployees = await get(urls.employee.getAllEmployee, {
      headers: { roleFunction: "visitory" },
      params: {
        search: {
          role: data.toMeetUserType._id,
        },
      },
    });
    setEmployees(getEmployees.data.data);
    setEditVisitorInfo(id);
    console.log(data, "ediiiiit");
    setAddForm({
      name: data.name,
      phone: data.phone,
      comingForm: data.comingForm,
      roleName: data.roleName,
      // toMeetUser: data.toMeetUser._id,
      toMeetUserType: data.toMeetUserType._id,
      reasonToMeet: data.reasonToMeet,
      checkIn: data.checkIn,
      checkOut: data.checkOut,
      class: data.classes,
      section: data.section,
      note: data.note,
    });
    setSelectStudent(data.toMeetUser._id);
    setSelectEmployee(data.toMeetUser._id);
    setRoleName(data.roleName);
    handleClickOpen();
  };
  console.log(addForm, "addForm");
  console.log(selectStudent, "selectStudent");
  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddForm({
      name: "",
      phone: 0,
      comingForm: "",
      toMeetUserType: "",
      toMeetUser: "",
      reasonToMeet: "",
      note: "",
      class: "",
      section: "",
      checkIn: "",
    });
    setRoleName("");
    setEditVisitorInfo(null);
    setSelectEmployee("");
    setSelectStudent("");
  };

  const handleClickOpenDialog = (visitor) => {
    setShowDetails(visitor);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const getDownload = await get(
        `${selectedSetting._id}/visitorinfo/downloadexcel/`,
        {
          headers: { roleFunction: "visitory" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getDownload.data);
      const link = document.createElement("a");
      console.log(uri);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  const handleGetPrintPdf = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/visitorinfo/downloadpdf`,
        {
          headers: { roleFunction: "visitory" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Visitor Info" />
      <div style={{ margin: "15px 0" }}>
        {/* <div style={styles.cardButton}>
          <LoadingButton
            onClick={handleGetDownloadSheet}
            variant="contained"
            size="small"
            sx={styles.button}
            loading={loadingBtn}
          >
            Excel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="small"
            loading={loading}
            sx={styles.button}
            onClick={handleGetPrintPdf}
          >
            Pdf
          </LoadingButton>
        </div> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px 0px",
            flexDirection: { xs: "column", md: "row", lg: "row" },
          }}
        >
          <Box style={styles.cardButton}>
            <LoadingButton
              onClick={handleGetDownloadSheet}
              variant="contained"
              size="small"
              sx={styles.button}
              loading={loadingBtn}
            >
              Excel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              size="small"
              loading={loading}
              sx={styles.button}
              onClick={handleGetPrintPdf}
            >
              Pdf
            </LoadingButton>
          </Box>
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            label="Search Name"
            placeholder="Enter Name"
            variant="outlined"
            sx={{ mt: { xs: 2, md: 0 } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Phone
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByToMeet} className="class-table-header">
                    To Meet
                    <span style={{ cursor: "pointer" }}>
                      {sort.tomeet === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByReasonToMeet}
                    className="class-table-header"
                  >
                    Reason To Meet
                    <span style={{ cursor: "pointer" }}>
                      {sort.reasonToMeet === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByCheckIn} className="class-table-header">
                    Check In
                    <span style={{ cursor: "pointer" }}>
                      {sort.checkIn === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Check Out
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((visitor, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {searchPage * searchRowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">{visitor.name}</TableCell>
                          <TableCell align="center">{visitor.phone}</TableCell>
                          <TableCell align="center">
                            {visitor.toMeetUser.basicInfo.empName ||
                              visitor.toMeetUser.basicInfo.name}
                          </TableCell>
                          <TableCell align="center">
                            {visitor.reasonToMeet}
                          </TableCell>
                          <TableCell align="center">
                            {/* {new Date(visitor.checkIn).toLocaleTimeString()} */}
                            {new Date(visitor.checkIn).toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            {visitor.checkOut
                              ? new Date(visitor.checkOut).toDateString()
                              : "not checked out"}
                          </TableCell>
                          <TableCell align="center">
                            <div className="edit-delete">
                              <Tooltip title="View">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleClickOpenDialog(visitor)}
                                >
                                  <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>

                              <ShowComponent
                                roleFunction="visitory"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleVisitorInfoEdit(
                                        visitor._id,
                                        visitor
                                      )
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>

                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={visitor._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((visitor, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">{visitor.name}</TableCell>
                          <TableCell align="center">{visitor.phone}</TableCell>
                          <TableCell align="center">
                            {visitor.toMeetUser.basicInfo.empName ||
                              visitor.toMeetUser.basicInfo.name}
                          </TableCell>
                          <TableCell align="center">
                            {visitor.reasonToMeet}
                          </TableCell>
                          <TableCell align="center">
                            {/* {new Date(visitor.checkIn).toLocaleTimeString()} */}
                            {new Date(visitor.checkIn).toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            {visitor.checkOut
                              ? new Date(visitor.checkOut).toLocaleString()
                              : "Not checked out"}
                          </TableCell>
                          <TableCell align="center">
                            <div className="edit-delete">
                              <Tooltip title="View">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleClickOpenDialog(visitor)}
                                >
                                  <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>

                              <ShowComponent
                                roleFunction="visitory"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleVisitorInfoEdit(
                                        visitor._id,
                                        visitor
                                      )
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>

                              {/* <Button
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(visitor._id, visitor)}
                          >
                            <Delete />
                          </Button> */}
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={visitor._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!list.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        {/*== Add VisitorInfo Modal ============= */}
        <Box className="add-icon">
          <ShowComponent roleFunction="visitory" action="add">
            <Tooltip title="Add Visitor Info">
              <Fab
                variant="contained"
                sx={{
                  color: "#ffff",

                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                onClick={handleClickOpen}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </ShowComponent>
        </Box>
        {/* =========================== */}

        {/*== View VisitorInfo ============= */}
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Visitor Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <>
                    <tr>
                      <td>School Name</td>
                      {/* <td>Excellent English Medium Primary & High School</td> */}
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td> Name</td>
                      <td>{showDetails.name}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{showDetails.phone}</td>
                    </tr>
                    <tr>
                      <td>Coming From</td>
                      <td>{showDetails.comingForm}</td>
                    </tr>
                    <tr>
                      <td>User Type</td>
                      <td>{showDetails.toMeetUserType.roleName}</td>
                    </tr>
                    <tr>
                      <td>To Meet</td>
                      <td>
                        {showDetails.toMeetUser.basicInfo.name ||
                          showDetails.toMeetUser.basicInfo.empName}
                      </td>
                    </tr>
                    <tr>
                      <td>Reason To Meet</td>
                      <td>{showDetails.reasonToMeet}</td>
                    </tr>
                    <tr>
                      <td>Check In</td>
                      <td>{new Date(showDetails.checkIn).toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td>Check Out</td>
                      <td>
                        {showDetails.checkOut
                          ? new Date(showDetails.checkOut).toLocaleString()
                          : "not checked out"}
                      </td>
                    </tr>

                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
        {/* ============================= */}

        {/*== Add/Update VisitorInfo ============= */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    {editVisitorInfo ? "Update VisitorInfo" : "Add VisitorInfo"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    id="filled-required"
                    fullWidth
                    size="small"
                    label="Name"
                    name="name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                    inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                    disabled={editVisitorInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small" required>
                    <TextField
                      required
                      id="filled-required"
                      fullWidth
                      size="small"
                      label="Phone "
                      name="phone"
                      type="number"
                      value={addForm.phone}
                      onChange={handleAddForm}
                      inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                      disabled={editVisitorInfo}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    id="filled-required"
                    fullWidth
                    size="small"
                    label="Coming From"
                    name="comingForm"
                    value={addForm.comingForm || ""}
                    onChange={handleAddForm}
                    inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                    disabled={editVisitorInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 8 }}>
                      To Meet User Type
                    </InputLabel>
                    <Select
                      label="To Meet User Type"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="toMeetUserType"
                      value={addForm.toMeetUserType || ""}
                      onChange={handleRoleChange}
                      inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                      disabled={editVisitorInfo}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role._id} value={role._id}>
                          {role.roleName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {roleName == "STUDENT" ? (
                  <>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel sx={{ fontSize: 8 }}>Class</InputLabel>
                        <Select
                          label="class"
                          labelId="demo-simpless-select-filled-label"
                          id="demo-simple-select-filled-l"
                          name="class"
                          value={addForm.class}
                          onChange={handleClassChange}
                          inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                          disabled={editVisitorInfo}
                        >
                          {classes.map((emp) => (
                            <MenuItem key={emp._id} value={emp._id}>
                              {emp.className}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel sx={{ fontSize: 8 }}>section</InputLabel>
                        <Select
                          label="Section"
                          labelId="demo-simpless-select-filled-label"
                          id="demo-simple-select-filled-l"
                          name="section"
                          value={addForm.section}
                          onChange={handleSectionChange}
                          inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                          disabled={editVisitorInfo}
                        >
                          {section.map((emp) => (
                            <MenuItem key={emp._id} value={emp._id}>
                              {emp.sectionName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {/* <FormControl fullWidth size="small">
                        <InputLabel sx={{ fontSize: 8 }}>Student</InputLabel>
                        <Select
                          label="Student"
                          labelId="demo-simpless-select-filled-label"
                          id="demo-simple-select-filled-l"
                          name="student"
                          value={selectStudent}
                          onChange={handleStudent}
                          inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                          disabled={editVisitorInfo}
                        >
                          {student.map((emp) => (
                            <MenuItem key={emp._id} value={emp._id}>
                              {emp.basicInfo.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}

                      <FormControl fullWidth size="small">
                        {editVisitorInfo ? (
                          <>
                            <InputLabel sx={{ fontSize: 8 }}>
                              Student
                            </InputLabel>
                            <Select
                              label="Student"
                              labelId="demo-simpless-select-filled-label"
                              id="demo-simple-select-filled-l"
                              name="student"
                              value={selectStudent}
                              onChange={handleStudent}
                              inputProps={{
                                readOnly: Boolean(editVisitorInfo),
                              }}
                              disabled={editVisitorInfo}
                            >
                              {student.map((emp) => (
                                <MenuItem key={emp._id} value={emp._id}>
                                  {emp.basicInfo.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        ) : (
                          <Autocomplete
                            options={student}
                            value={
                              student.find((s) => s._id === selectStudent) ||
                              null
                            }
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setSelectStudent(newValue._id);
                              } else {
                                setSelectStudent("");
                              }
                            }}
                            getOptionLabel={(option) => option.basicInfo.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Student"
                                size="small"
                                name="student"
                                placeholder="Search student with name"
                                inputProps={{
                                  ...params.inputProps,
                                  readOnly: Boolean(editVisitorInfo),
                                }}
                                disabled={editVisitorInfo}
                              />
                            )}
                          />
                        )}
                      </FormControl>
                      {/* <FormControl fullWidth size="small">
                        <Autocomplete
                          options={student}
                          value={
                            student.find((s) => s._id === selectStudent) || null
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSelectStudent(newValue._id);
                            } else {
                              setSelectStudent("");
                            }
                          }}
                          getOptionLabel={(option) => option.basicInfo.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Student"
                              size="small"
                              name="student"
                              placeholder="Search student with name"
                              inputProps={{
                                ...params.inputProps,
                                readOnly: Boolean(editVisitorInfo),
                              }}
                              disabled={editVisitorInfo}
                            />
                          )}
                        />
                      </FormControl> */}
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel sx={{ fontSize: 8 }}>To Meet User</InputLabel>
                      <Select
                        label="To Meet User"
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        name="toMeetUser"
                        value={selectEmployee}
                        onChange={handleEmployeeChange}
                        // options={employees}
                        inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                        disabled={editVisitorInfo}
                      >
                        {employees.map((emp) => (
                          <MenuItem key={emp._id} value={emp._id}>
                            {emp.basicInfo.empName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 8 }}>Reason to Meet</InputLabel>
                    <Select
                      label="Reason To Meet"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="reasonToMeet"
                      value={addForm.reasonToMeet || ""}
                      onChange={handleAddForm}
                      inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                      disabled={editVisitorInfo}
                    >
                      <MenuItem value={"vendor"}>Vendor</MenuItem>
                      <MenuItem value={"relative"}>Relative</MenuItem>
                      <MenuItem value={"friend"}>Friend</MenuItem>
                      <MenuItem value={"guardian"}>Guardian</MenuItem>
                      <MenuItem value={"family"}>Family</MenuItem>
                      <MenuItem value={"interview"}>InterView</MenuItem>
                      <MenuItem value={"meeting"}>Meeting</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {editVisitorInfo ? (
                  <>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        required
                        id="filled-required"
                        fullWidth
                        size="small"
                        label="Check In"
                        // type="time"
                        name="checkIn"
                        // value={addForm.checkIn || ""}
                        value={
                          moment(addForm.checkIn).format(
                            "MM/DD/YYYY, h:mm:ss A"
                          ) || ""
                        }
                        onChange={handleAddForm}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                        disabled={editVisitorInfo}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        required
                        id="filled-required"
                        fullWidth
                        size="small"
                        label="Check Out"
                        name="checkOut"
                        type="datetime-local"
                        value={addForm.checkOut || ""}
                        onChange={handleAddForm}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  gap={1}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    color="error"
                    type="button"
                    sx={{ color: "#fff" }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    {editVisitorInfo ? "Update" : "Add"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
        {/* ==================================== */}
      </div>
    </Wrapper>
  );
};

export default VisitoryInfo;
