import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";
import Wrapper from "../../Wrapper";

const EmployeeYearlyAttendence = () => {
  const [formData, setFormData] = useState([]);

  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);

  const [classes, setClasses] = useState([]);
  const [employee, setEmployee] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "reports" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "reports" },
        });
        console.log(response);
        setEmployee(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "reports" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "reports" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const styles = {
    Cards: {
      marginTop: "20px",
      padding: 10,
    },

    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
      marginLeft: "10px",
      marginTop: "5px",
    },
  };

  const numbers = [];
  for (let i = 1; i <= 31; i++) {
    numbers.push(
      <TableCell sx={{ color: "white", display: "inline" }} key={i}>
        {i}
      </TableCell>
    );
  }
  const handleFind = async () => {
    setLoader(true);
    try {
      let data = {
        academicYearId: formData.academicYear,
        employeeId: formData.employee,
      };
      const response = await post(
        `${urls.employeeReport.employeeYearlyReport}`,
        data,
        { headers: { roleFunction: "reports" } }
      );
      setData(response.data);
      setMonthlyData(response.data.data);
      console.log(response);
      // setLoader(false);
    } catch (error) {
      console.log(error);
      // setLoader(false);
    }
    setLoader(false);
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Employee Yearly Attendance" />
      <Box>
        <Card style={styles.Cards}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} marginBottom={"5px"}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth size="samll" required>
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    variant="outlined"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Select Employee</InputLabel>
                  <Select
                    label="Select employee"
                    id="demo-simple-select-filled"
                    name="employee"
                    variant="outlined"
                    value={formData.employee || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {employee.map((data) => (
                      <MenuItem key={data._id} value={data._id}>
                        {data.basicInfo.empName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                display="flex"
                alignSelf="center"
              >
                <LoadingButton
                  type="submit"
                  size="small"
                  loading={loader}
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  onClick={handleFind}
                >
                  Find
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Card style={{ padding: 10, marginTop: "12px" }}>
          <div
            style={{
              paddingLeft: "1rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <div>
              Working Days:<span>{data?.totalWorkingDays}</span>
            </div>
            <div>
              Present Days:<span>{data?.presentDays}</span>
            </div>
            <div>
              Absent Days:<span>{data?.absentDays}</span>
            </div>
            <div>
              Percentage:<span>{data?.precentageWork}%</span>
            </div>
          </div>
        </Card>
        <TableContainer component={Paper} sx={{ overflowX: "scroll" }}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  Month-Year
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Working Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Present Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Absent Days
                </TableCell>
                {numbers.map((num) => (
                  <TableCell
                    key={num.key}
                    sx={{ color: "white" }}
                    align="center"
                  >
                    {num.key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {monthlyData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <TableRow>
                    <TableCell align="center">{data.monthYear}</TableCell>

                    <TableCell align="center">
                      {data.updatedDocuments.filter(
                        (ele) => ele.status === "present"
                      ).length +
                        data.updatedDocuments.filter(
                          (ele) => ele.status === "absent"
                        ).length}
                    </TableCell>

                    <TableCell align="center">
                      {
                        data.updatedDocuments.filter(
                          (ele) => ele.status === "present"
                        ).length
                      }
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      {
                        data.updatedDocuments.filter(
                          (ele) => ele.status === "absent"
                        ).length
                      }
                    </TableCell>

                    {numbers.map((num) => {
                      const matchingDocument = data.updatedDocuments.filter(
                        (ele) => ele.date == num.key
                      );
                      return (
                        <TableCell key={num.key} align="center">
                          {matchingDocument.length > 0
                            ? matchingDocument.some(
                                (sta) => sta.status === "present"
                              )
                              ? "P"
                              : matchingDocument.some(
                                  (sta) => sta.status === "absent"
                                )
                              ? "A"
                              : "-"
                            : "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={monthlyData.length || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </Box>
    </Wrapper>
  );
};

export default EmployeeYearlyAttendence;
