import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Delete, Edit, Search } from "@mui/icons-material";
import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import AddSplashNews from "./AddSplashNews";
import Horizontal from "./Horizontal";
import { urls as url } from "../../../services/urlConstant";
import { get, post, put, del } from "../../../services/apis";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Popup from "./Popup";
import Wrapper from "../../Wrapper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const SplashNews = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState();
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [flashNews, setFlashNews] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editData, setEditData] = useState(null);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.announceFlashNews.delFlashNews}`,
        id,
        {},
        { roleFunction: "splashNews" }
      );
      if (res.data.success) {
        const filteredEvent = flashNews.filter((item) => item._id !== id);
        setFlashNews(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      flashNews.length > 0 &&
        setSearchFilter(
          flashNews.filter((ele) =>
            ele.title.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  useEffect(() => {
    const getSplashNews = async () => {
      try {
        const response = await get(urls.announceFlashNews.getFlashNews, {
          headers: { roleFunction: "splashNews" },
          params: {
            page: 1,
            limit: 10,
          },
        });
        setFlashNews(response.data.data);
        console.log("setFlashNews", response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSplashNews();
  }, [selectedSetting]);
  const getSplashNews = async () => {
    try {
      const response = await get(urls.announceFlashNews.getFlashNews, {
        headers: { roleFunction: "splashNews" },
      });
      setFlashNews(response.data.data);
      console.log("setFlashNews", response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditClick = (data) => {
    setEditData(data);
    handleOpen();
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Splash News" />
      {/* <Card style={{ margin: "20px 0", padding: 10 }}> */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "15px 0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Horizontal" {...a11yProps(0)} />
          <Tab label="Popup" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {/* </Card> */}
      <TabPanel value={tabValue} index={0}>
        <Horizontal />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Popup />
      </TabPanel>
    </Wrapper>
  );
};

export default SplashNews;
