import { Box, Container, Typography, styled } from "@mui/material";
import React from "react";
// import image from '../../assets/Images/bg-image.png'
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import NavBar from "../navbar/Navbar";
import TopNav from "../navbar/TopNav";

const ContentBox = styled(Box)(({ theme }) => ({
  // padding: "30px",

  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 15px",
    // overflow:"auto",
  },
}));

const OverViewTitleContainer = styled(Box)(({ theme }) => ({
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const OverViewTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const OverView = () => {
  return (
    <>
      <TopNav />
      <NavBar />

      <ContentBox sx={{ height: "max-content" }}>
        <OverViewTitleContainer variant="h4" align="center">
          <OverViewTitle>OVERVIEW</OverViewTitle>
          <Typography sx={{ color: "white" }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              HOME
            </Link>
            &nbsp; / ABOUT
          </Typography>
        </OverViewTitleContainer>
        <Container>
          <Box sx={{ textAlign: "justify" }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                color: "#F86F03",
                textIndent: "4rem",
                marginTop: "2rem",
              }}>
              Akshara Public School, we take pride in our rich history and the
              visionary leader who laid the foundation for our
              institution."Lorem sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit deserunt mollit
              anim id est laborum."
            </Typography>

            <Typography sx={{ marginTop: "2rem" }}>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit deserunt mollit anim id est laborum."
            </Typography>

            <Typography sx={{ marginTop: "2rem" }}>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit deserunt mollit anim id est laborum."
            </Typography>

            <Typography sx={{ marginTop: "2rem" }}>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit deserunt mollit anim id est laborum."
            </Typography>

            <Typography
              sx={{
                marginTop: "3rem",
                fontWeight: "bold",
                color: "#F86F03",
                textAlign: "center",
              }}>
              Come and Join to be a part of the Akshara Public School!
            </Typography>
          </Box>
        </Container>
      </ContentBox>
      <Footer />
    </>
  );
};

export default OverView;
