import {
  Box,
  Button,
  Dialog,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Add } from "@mui/icons-material";
import { Delete, Edit, Search } from "@mui/icons-material";
import { urls as url } from "../../../services/urlConstant";
import { get, post, put, del } from "../../../services/apis";
import appendStringToUrls from "../../../utils/appendString";
import SettingContext from "../../../context/SettingsContext";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { useTheme } from "@mui/material/styles";
import ShowComponent from "../../ShowComponent";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Horizontal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [editText, setEditText] = useState();
  const [addForm, setAddForm] = useState({});
  const [flashNews, setFlashNews] = useState([]);
  const [deleteModal, setDeleteModal] = useState();
  const [loading, setLoading] = useState(false);
  const { settings, setSettings, selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = useState({
    title: "asc",
    webView: "asc",
  });
  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditText(null);
    setAddForm({});
    setOpen(false);
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.announceFlashNews.delFlashNews}`,
        id,
        {},
        { roleFunction: "splashNews" }
      );
      if (res.data.success) {
        const filteredEvent = flashNews.filter((item) => item._id !== id);
        setFlashNews(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewsEdit = (id, data) => {
    setEditText(id);
    setAddForm({
      title: data.title,
      text: data.text,
      webView: data.webView,
    });
    handleClickOpen();
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  useEffect(() => {
    getSplashNews();
  }, [selectedSetting]);
  const getSplashNews = async () => {
    try {
      const response = await get(urls.announceFlashNews.getFlashNews, {
        headers: { roleFunction: "splashNews" },
        params: {
          page: 1,
          limit: 10,
        },
      });
      const horizontalvalues = response.data.data.filter(
        (newitem) => newitem.type === "Horizontal"
      );
      setFlashNews(horizontalvalues);
      console.log("Horizontal", horizontalvalues);
    } catch (error) {
      console.log(error);
    }
  };
  const sortByTitle = () => {
    if (sort.title == "asc") {
      let sortedList = [
        ...flashNews.sort((a, b) => b.title.localeCompare(a.title)),
      ];
      setFlashNews(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...flashNews.sort((a, b) => a.title.localeCompare(b.title)),
      ];
      setFlashNews(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };
  const sortByWebView = () => {
    if (sort.webView == "asc") {
      let sortedList = [
        ...flashNews.sort((a, b) => b.webView.localeCompare(a.webView)),
      ];
      setFlashNews(sortedList);
      setSort({ ...sort, webView: "des" });
    } else {
      let sortedList = [
        ...flashNews.sort((a, b) => a.webView.localeCompare(b.webView)),
      ];
      setFlashNews(sortedList);
      setSort({ ...sort, webView: "asc" });
    }
  };
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!editText) {
        const res = await post(
          `${urls.announceFlashNews.postFlashNews}`,
          {
            title: addForm.title,
            text: addForm.text,
            webView: addForm.webView,
            type: "Horizontal",
            contentType: "Text",
          },
          {
            headers: { roleFunction: "splashNews" },
          }
        );
        if (res.data.success) {
          getSplashNews();
          handleClose();
        }
      } else {
        const res = await put(
          `${urls.announceFlashNews.putFlashNews}${editText}`,
          "",
          {
            title: addForm.title,
            text: addForm.text,
            webView: addForm.webView,
            type: "Horizontal",
            contentType: "Text",
          },
          { headers: { roleFunction: "splashNews" } }
        );
        if (res.data.success) {
          getSplashNews();
          handleClose();
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">#SL</span>
              </TableCell>
              <TableCell align="center">
                <span onClick={sortByTitle} className="class-table-header">
                  Title
                  <span style={{ cursor: "pointer" }}>
                    {sort.title === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>

              <TableCell align="center">
                <span className="class-table-header">Text</span>
              </TableCell>
              <TableCell align="center">
                <span onClick={sortByWebView} className="class-table-header">
                  Is View On Web?
                  <span style={{ cursor: "pointer" }}>
                    {sort.webView === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Action </span>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {flashNews
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {data.title}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {data.text}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {data.webView}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <div className="edit-delete">
                      <ShowComponent roleFunction="splashNews" action="update">
                        <Tooltip title="Update">
                          <IconButton
                            size="small"
                            style={{ color: "#1b3779" }}
                            onClick={() => handleNewsEdit(data._id, data)}
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ShowComponent>
                      <ShowComponent roleFunction="splashNews" action="delete">
                        <Tooltip title="Delete">
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => setDeleteModal(data._id, data)}
                          >
                            <Delete fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                      </ShowComponent>
                      <DeleteModal
                        deleteModal={deleteModal}
                        handleDelete={handleDeleteone}
                        id={data._id}
                        setDeleteModal={setDeleteModal}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!flashNews.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No Data Found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={flashNews.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      <div className="add-icon">
        <ShowComponent roleFunction="splashNews" action="add">
          <Tooltip title="Add Horizontal Splash News">
            <Fab
              size="medium"
              style={{ background: "#1b3779", color: "#fff" }}
              aria-label="add"
              onClick={handleClickOpen}
            >
              <Add />
            </Fab>
          </Tooltip>
        </ShowComponent>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  {editText ? "Update News" : "Add News"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label=" Splash News Title"
                  name="title"
                  value={addForm.title}
                  onChange={handleInputChange}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Text"
                  name="text"
                  onChange={handleInputChange}
                  value={addForm.text}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl fullWidth size="small">
                  <InputLabel sx={{ fontSize: 12 }}>Is view on web?</InputLabel>
                  <Select
                    label="Is View On Web"
                    name="webView"
                    onChange={handleInputChange}
                    value={addForm.webView}
                  >
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"yes"}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"no"}
                    >
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", xs: "center" },
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    {editText ? "Update" : "Add"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default Horizontal;
