import React, { useEffect } from "react";
import { Paper, TextField, Typography, Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Person, Key, VisibilityOff, Visibility } from "@mui/icons-material";
import { Avatar, Link } from "@mui/material";
import "../../../styles/components/Login/Login.scss";
import { InputAdornment, IconButton } from "@mui/material";
import { useState, useContext } from "react";
import { LockOutlined } from "@mui/icons-material";
import { urls } from "../../../services/urlConstant";
import { post } from "../../../services/apis";
import { useNavigate } from "react-router-dom";
import SettingContext from "../../../context/SettingsContext";
import SchoolSelector from "../../SchoolSelector";
import Logo from "../../../assets/Images/kayaka-logo.png";
import Input from "@mui/base/Input";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { inputClasses } from "@mui/base/Input";
import UserTypeContext from "../../../context/UserTypeContext";
import { jwtDecode } from "jwt-decode";

const style = {
  maxWidth: 350,
  height: "auto",
  p: 2,
  borderRadius: "20px",
  boxSizing: " borderBox",
  placeContent: "center",
  marginTop: "30px",
};
const divStyle = {
  padding: 28,
  width: 320,
  textAlign: "center",
};
// const avatarStyle = { backgroundColor: "#1bbd7e" };
// const btnstyle = { margin: "8px 0" };
const styles = {
  TextField: {
    label: {
      fontSize: 12,
      padding: 10,
    },
  },
};

const Login = () => {
  const { selectedSetting } = useContext(SettingContext);
  const { setUserType } = useContext(UserTypeContext);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  // const [showPassword, setShowPassword] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  useEffect(() => {
    window.localStorage.removeItem(process.env.REACT_APP_TOKEN);
    window.localStorage.removeItem(process.env.REACT_APP_USER_TYPE);
    window.localStorage.removeItem(process.env.REACT_APP_ROLE);
    window.localStorage.removeItem(process.env.REACT_APP_CURRENT_USER);
    window.localStorage.removeItem(process.env.REACT_APP_PROFILE_ID);
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = {
        username: name,
        password: password,
        rememberMe,
      };
      const res = await post(`/${selectedSetting._id}/login`, data);

      if (res.data.success) {
        let decodedToken = jwtDecode(res.data.data.token);
        window.localStorage.setItem(
          process.env.REACT_APP_TOKEN,
          res.data.data.token
        );
        window.localStorage.setItem(
          process.env.REACT_APP_USER_TYPE,
          res.data.userType
        );
        window.localStorage.setItem(
          process.env.REACT_APP_ROLE,
          JSON.stringify(res.data.data.employee.role)
        );
        window.localStorage.setItem(
          process.env.REACT_APP_CURRENT_USER,
          JSON.stringify(res.data.data.employee)
        );
        window.localStorage.setItem(
          process.env.REACT_APP_PROFILE_ID,
          res.data.data.employee._id
        );
        const photo = res.data.data.employee.empPhoto
          ? res.data.data.employee.empPhoto.link
          : res.data.data.employee.studentPhoto?.link;
        window.localStorage.setItem("empPhoto", photo);
        setUserType(decodedToken.userType);

        if (decodedToken.userType === "employee") {
          navigate("/dashboard");
        } else if (decodedToken.userType === "student") {
          navigate("/dashboard/sd");
        } else {
          navigate("/login");
        }
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="imageStyless">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={selectedSetting.logo?.link}
          height={"200px"}
          width={"200px"}
          style={{
            alignSelf: "center",
            resize: "contain",
            marginTop: "50px",
            objectFit: "contain",
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style}>
          <Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SchoolSelector />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      boxShadow: (theme) => theme.shadows[5],
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    className="forms"
                  >
                    <TextField
                      size="small"
                      placeholder="User Name"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      sx={{
                        ".css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "black",
                          borderRadius: "20px",
                        },
                      }}
                      className="box"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start">
                              <Person sx={{ color: "#ef6c00" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      boxShadow: (theme) => theme.shadows[5],
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    className="forms"
                  >
                    <TextField
                      size="small"
                      placeholder="Password"
                      fullWidth
                      // type={password.showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{
                        ".css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "black",
                          borderRadius: "20px",
                        },
                      }}
                      className="box"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              edge="start"
                              onClick={handleClickShowPassword}
                            >
                              <Key sx={{ color: "#1976d2" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" sx={{ pr: 1 }}>
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff sx={{ color: "grey" }} />
                              ) : (
                                <Visibility sx={{ color: "grey" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      type={showPassword ? "text" : "password"}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#ffff",
                    fontSize: "20px",
                    fontWeight: "bold",
                    gap: 3,
                  }}
                >
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setRememberMe(e.target.checked);
                      }}
                    />
                    <span className="rememberStyle" style={{ color: "black" }}>
                      Remember me
                    </span>
                  </label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Button type="submit" variant="contained">
                    Log In
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default Login;
