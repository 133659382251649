import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import dayjs from "dayjs";
// icons
import { LoadingButton } from "@mui/lab";
import { Search } from "@material-ui/icons";
import { TableBody } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import { Delete } from "@mui/icons-material";
// custome components
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <Box>{children}</Box>
        </>
      )}
    </div>
  );
}

const SalaryPayment = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [month, setMonth] = useState(dayjs(Date.now()));
  const [search, setSearch] = useState("");
  const [selectmonth, setSelectMonth] = useState(dayjs(Date.now()));
  const [year, setYear] = useState(dayjs(Date.now()));
  const [history, setHistory] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [selectDeduction, setSelectDeduction] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);

  const [processLoader, setProcessLoader] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ====================================

  useEffect(() => {
    getHistory();
    getSalaryDeduction();
  }, [selectedSetting]);

  const getSalaryDeduction = async () => {
    try {
      const { data, status } = await get(`${urls.salaryGrade.getList}`, {
        headers: { roleFunction: "salaryPayment" },
      });

      setDeduction(data.result.data);
      console.log(data.result.data, "sssss");
    } catch (error) {
      console.log(error);
    }
  };
  const getHistory = async () => {
    // console.log("hjhjhjhjh");
    try {
      const { data, status } = await get(`${urls.makePayment.getAll}`, {
        headers: { roleFunction: "salaryPayment" },
      });
      setHistory(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    // console.log("hjhjhjhjhj");
    setPdfLoader(true);
    try {
      const selectedMonth = dayjs(month, "MMMM").format("MMMM");

      const getPrint = await get(
        `/${selectedSetting._id}/makePayment/downloadpdf?month=${selectedMonth}&year=${year}`,
        {
          headers: { roleFunction: "salaryPayment" },
          responseType: "blob",
        }
      );
      // console.log(getPrint, "getPrint");
      if (getPrint) {
        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
      getHistory();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setPdfLoader(false);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      history.length > 0 &&
        setSearchFilter(
          history.filter((ele) =>
            ele.month.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleGetPrintDeductionPdf = async (e) => {
    e.preventDefault();
    // console.log("hjhjhjhjhj");
    setProcessLoader(true);
    try {
      const selectedMonth = dayjs(selectmonth, "MMMM").format("MMMM");
      const getPrint = await get(
        `/${selectedSetting._id}/downloadDeductionPdf?deduction=${selectDeduction}&month=${selectedMonth}`,
        {
          headers: { roleFunction: "salaryPayment" },
          responseType: "blob",
        }
      );
      // console.log(getPrint, "getPrint");
      if (getPrint) {
        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }

      getHistory();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setProcessLoader(false);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Payments" />
      <div style={{ margin: "15px 0" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "15px 0",
            mt: 3,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Salary Sheet" {...a11yProps(0)} />
            <Tab label="History" {...a11yProps(1)} />
            <Tab label="Salary Deduction" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Card sx={{ padding: "15px 5px", mt: 1.5 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="Select Month"
                      views={["month"]}
                      inputFormat="MMMM"
                      value={month}
                      onChange={(newValue) => {
                        setMonth(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="Select Year"
                      views={["year"]}
                      inputFormat="YYYY"
                      value={year}
                      onChange={(newValue) => {
                        setYear(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-start",
                }}
                alignSelf="center"
              >
                <LoadingButton
                  loading={pdfLoader}
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  variant="contained"
                  onClick={handleGetPrintPdf}
                >
                  Process
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {/* <Paper
            sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
          > */}
          <Grid
            container
            spacing={2}
            justifyContent={{ md: "right", xs: "center" }}
            my={2}
          >
            <Grid item xs="auto" mb={1}>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search Month"
                placeholder="Enter Month"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    Month
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Year
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Download
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((data, i) => (
                      <TableRow>
                        <TableCell align="center">{data.month}</TableCell>
                        <TableCell align="center">
                          {new Date(data.year).getFullYear()}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => handleLinkClick(data.data.link)}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : history &&
                    history.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{data.month}</TableCell>
                        <TableCell align="center">
                          {new Date(data.year).getFullYear()}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <IconButton
                            size="small"
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => handleLinkClick(data.data.link)}
                          >
                            <DownloadIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {!history.length && !search.trim() && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {search.trim() && !searchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={search ? searchFilter.length : history.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start,",
              alignItems: "center",
              my: 1,
            }}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Card sx={{ padding: "15px 5px", mt: 1.5 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth required size="small">
                  <InputLabel required>Deduction Type</InputLabel>
                  <Select
                    label="Deduction Type"
                    size="small"
                    value={selectDeduction}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      setSelectDeduction(newValue);
                    }}
                  >
                    {deduction &&
                      deduction.map((data) =>
                        data.deduction.map((datas) => (
                          <MenuItem key={datas.id} value={datas.name}>
                            {datas.name}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      required
                      label="Select Date"
                      views={["month", "year"]}
                      inputFormat="MMM-YYYY"
                      value={selectmonth}
                      onChange={(newValue) => {
                        setSelectMonth(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                display="flex"
                alignItems="center"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-start",
                }}
              >
                <LoadingButton
                  loading={processLoader}
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  variant="contained"
                  onClick={handleGetPrintDeductionPdf}
                >
                  Process
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>
        </TabPanel>
      </div>
    </Wrapper>
  );
};

export default SalaryPayment;
