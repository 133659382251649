import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import Container from "@mui/material/Container";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";

const StudentAttendenceReport = () => {
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const [month, setMonth] = useState(dayjs(Date.now()));
  const [classes, setClasses] = useState([]);
  const [days, setDays] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setAcademicYears(e.target.value);
  };

  // useEffect(() => {
  //   if (month && academicYears) {
  //     const numberOfDays = new Date(
  //       academicYears,
  //       months.indexOf(month) + 1,
  //       0
  //     ).getDate();
  //     setDays(Array.from({ length: numberOfDays }, (_, index) => index + 1));
  //   } else {
  //     setDays([]);
  //   }
  // }, [month, academicYears]);

  // useEffect(() => {
  //   const generateTableHeader = () => {
  //     const headers = days.map((day) => (
  //       <TableCell style={{ color: "white" }} key={day} align="center">
  //         {day}
  //       </TableCell>
  //     ));
  //     setTableHeader(headers);
  //   };
  //   generateTableHeader();
  // }, [days]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "reports" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "reports" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "reports" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const styles = {
    cardsMargin: {
      marginTop: "15px",
    },
    cardStyle: {
      margin: "15px",
      padding: "10px",
    },
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
    },
    card: {
      padding: 15,
      marginBottom: 20,
      width: "100%",
      marginTop: "15px",
    },
    textfield: {
      width: "100%",
      label: { fontSize: 12 },
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
      marginLeft: "10px",
      marginTop: "5px",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };

  const numbers = [];
  for (let i = 1; i <= 31; i++) {
    numbers.push(
      <TableCell sx={{ color: "white", display: "inline" }} key={i}>
        {i}
      </TableCell>
    );
  }
  const handleFind = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let data = {
        academicYearId: formData.academicYear,
        classId: formData.className,
        sectionId: formData.section,
        month: month,
      };
      const response = await post(
        urls.studentYearlyReport.monthlyReport,
        data,
        { headers: { roleFunction: "reports" } }
      );
      console.log(response.data.data, "jkjkjkj");
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  return (
    <Container maxWidth="xl">
      <SectionSubHeader title="Student Attendance Report" />
      <Box sx={{ mt: "15px" }}>
        <Card style={{ margin: "15px 0px 15px 0px", padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form>
              <Grid container spacing={2} marginBottom={"5px"}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      id="demo-simple-select-filled"
                      name="className"
                      value={formData.className || ""}
                      onChange={handleClassChange}
                      size="small"
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {formData.className && (
                        <MenuItem value="all">All</MenuItem>
                      )}
                      {sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Select Month"
                        views={["month"]}
                        inputFormat="MMMM"
                        value={month}
                        onChange={(newValue) => {
                          setMonth(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  style={{ alignSelf: "flex-end" }}
                >
                  <LoadingButton
                    size="small"
                    variant="contained"
                    loading={loader}
                    onClick={handleFind}
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                  >
                    find
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
        <Box sx={{ overflowX: "scroll" }}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  Student Name
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Working Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Present Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Absent Days
                </TableCell>
                {numbers.map((num) => (
                  <TableCell
                    key={num.key}
                    sx={{ color: "white" }}
                    align="center"
                  >
                    {num.key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((dat) => (
                <TableRow>
                  <TableCell align="center">
                    {dat.student.basicInfo.name}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {dat.attendanceList.filter((ele) => ele.status === true)
                      .length +
                      dat.attendanceList.filter((ele) => ele.status === false)
                        .length}
                  </TableCell>
                  <TableCell align="center">
                    {
                      dat.attendanceList.filter((ele) => ele.status === true)
                        .length
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      dat.attendanceList.filter((ele) => ele.status === false)
                        .length
                    }
                  </TableCell>
                  {numbers.map((num) => {
                    const matchingDocument = dat.attendanceList.filter(
                      (ele) => ele.date == num.key
                    );
                    return (
                      <TableCell key={num.key} align="center">
                        {matchingDocument.length > 0
                          ? matchingDocument.some((sta) => sta.status === true)
                            ? "P"
                            : matchingDocument.some(
                                (sta) => sta.status === false
                              )
                            ? "A"
                            : "-"
                          : "-"}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Container>
  );
};

export default StudentAttendenceReport;
