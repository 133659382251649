import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { get } from "../../services/apis";
import appendStringToUrls from "../../utils/appendString";
import { useState } from "react";
import { useEffect } from "react";
import { urls as url } from "../../services/urlConstant";
import SettingContext from "../../context/SettingsContext";
import { useContext } from "react";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const MyReceipts = () => {
  const [receiptslist, setReceiptsList] = useState(null);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  useEffect(() => {
    const getReceipts = async () => {
      try {
        const res = await get(urls.myReceipts.getMyReceipts);

        setReceiptsList(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    getReceipts();
  }, [selectedSetting]);

  const handleGetPrintPdf = async (id) => {
    console.log(id, "iiiiii");
    try {
      {
        const getPrint = await get(`${urls.myReceipts.downloaReceipts}/${id}`, {
          responseType: "blob",
        });

        if (getPrint.data) {
          const uri = URL.createObjectURL(getPrint.data);
          window.open(uri, "__blank");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(receiptslist, "oookokokokojkokokoo");
  return (
    <Box sx={{ overflowY: "scroll", padding: 2 }}>
      <Grid container spacing={2}>
        {receiptslist?.receipts?.map((rec, i) => (
          <Grid item xs={12} sm={6} lg={4} key={rec._id}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}>
                    <Typography
                      sx={{ fontSize: 15, textAlign: "start" }}
                      color="text.secondary"
                      gutterBottom>
                      <b style={{ color: "#E1AD01" }}>Amount Paid:</b>{" "}
                      <CurrencyRupeeIcon sx={{ fontSize: "18px" }} />{" "}
                      {rec.amountPaid}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 15, textAlign: "start" }}
                      color="text.secondary"
                      gutterBottom>
                      <b style={{ color: "#E1AD01" }}>Payment Mode:</b>{" "}
                      {rec.paymentMode}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, textAlign: "start" }}
                      color="text.secondary"
                      gutterBottom>
                      <b style={{ color: "#E1AD01" }}>Paid On :</b>{" "}
                      {dayjs(rec.paidAt).format("DD-MM-YYYY")}
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Button onClick={() => handleGetPrintPdf(rec._id)}>
                      <DownloadIcon
                        sx={{ fontSize: 35, cursor: "pointer" }}
                        color="success"
                      />
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MyReceipts;
