import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import MovingText from "react-moving-text";

import Footer from "../Components2/Footer";
import NavTopnav from "../components/NavTopnav/NavTopnav";

const VisionandMission = () => {
  // const styles = {
  //   background: {
  //     // backgroundImage: "url(/course1.png)",
  //     backgroundRepeat: "no-repeat",
  //     backgroundSize: "cover",
  //     width: "100%",
  //     opacity: "1",
  //     height: "100%",
  //     position: "absolute",
  //     top: 0,
  //   },
  // };

  const OuterBox = styled(Box)(({ theme }) => ({
    backgroundImage:
      "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://img.freepik.com/free-photo/businessman-big-office_53876-144319.jpg)",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "100% 100%",
    paddingTop: "150px",
  }));

  const ContentContainer = styled(Container)(({ theme }) => ({
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 15px",
    },
  }));

  return (
    <>
      <NavTopnav />
      <OuterBox>
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              // marginTop: "180px",
            }}>
            <Grid item xs={12} md={10}>
              <Typography
                variant="h4"
                sx={{
                  color: "#F86F03",
                  fontWeight: "700",
                  textAlign: "center",
                }}>
                VISION
              </Typography>
              <br />
              <ContentContainer>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    fontSize: "16px",
                    // marginLeft: "5%",
                    // marginRight: "5%",
                  }}>
                  Akshara Foundation, Gulbarga, proposes a life-oriented
                  education that equips students to be agents of transformation
                  and growth at various levels of life through a humanising and
                  liberative process. Enabled and empowered, they actively
                  address issues and worries that are part of today's reality,
                  particularly those of students and people who are unable to
                  exercise their right to human freedom, and they fight for the
                  integrity of the natural world. The focus is on a
                  knowledge-based civilisation that the founder, Sri. Shivaraj
                  Patil, foresaw and encouraged
                </Typography>
              </ContentContainer>
            </Grid>
            <br />

            <Grid item xs={12} md={10}>
              <Typography
                variant="h4"
                sx={{
                  color: "#F86F03",
                  fontWeight: "700",
                  textAlign: "center",
                }}>
                MISSION
              </Typography>
              <br />

              <ContentContainer>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    fontSize: "16px",
                    // marginLeft: "5%",
                    // marginRight: "5%",
                  }}>
                  <Typography component="ul">
                    <Typography component="li" gutterBottom>
                      To make a positive impact on society by practising social
                      responsibility and consciousness.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      To promote societal harmony with regard to fundamental
                      human rights.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      To encourage unity between cultures and religions and care
                      for the human family.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      To encourage student leadership and give them the tools
                      they need to effectively participate in social
                      emancipation.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      To deliver a high-quality, comprehensive, career-focused
                      education and to foster an environment that supports both
                      academic and personal achievement.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      To promote family, connectivity, and eco-justice while
                      working to preserve the integrity of creation.
                    </Typography>
                  </Typography>
                </Typography>
              </ContentContainer>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </OuterBox>
    </>
  );
};

export default VisionandMission;
