import React, { useState } from "react";
import SchoolClass from "./SchoolClass";
import { Box, Card, Grid, Typography } from "@mui/material";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";

const Academic = () => {
  const [loader, setLoader] = useState(false);
  const handleLoader = () => {
    setLoader(!loader);
  };

  return (
    <Wrapper>
      {/* <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Class :
            </Box>
          </Grid>
        </Grid>
      </Card> */}
      <SectionSubHeader title="Class" />

      <SchoolClass handleLoader={handleLoader} />
    </Wrapper>
  );
};

export default Academic;
