import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";

import { Search } from "@material-ui/icons";
import { TableBody } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionsSubHeader from "../../../components/SectionSubHeader";

import { useTheme } from "@mui/material/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import ShowComponent from "../../ShowComponent";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const SalaryGrade = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editSalaryGrade, setEditSalaryGrade] = useState();
  const [list, setList] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [removeField, setRemoveField] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);

  const [sort, setSort] = useState({
    gradeCode: "asc",
    grade: "asc",
    from: "asc",
    to: "asc",
    status: "asc",
  });

  const sortByGradeCode = () => {
    if (sort.gradeCode === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.gradeCode.localeCompare(a.gradeCode)),
      ];
      setList(sortedList);
      setSort({ ...sort, gradeCode: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.gradeCode.localeCompare(b.gradeCode)),
      ];
      setList(sortedList);
      setSort({ ...sort, gradeCode: "asc" });
    }
  };

  const sortByGrade = () => {
    if (sort.grade === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.grade.localeCompare(a.grade)),
      ];
      setList(sortedList);
      setSort({ ...sort, grade: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.grade.localeCompare(b.grade)),
      ];
      setList(sortedList);
      setSort({ ...sort, grade: "asc" });
    }
  };

  const sortByStatus = () => {
    if (sort.status === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.status.localeCompare(a.status)),
      ];
      setList(sortedList);
      setSort({ ...sort, status: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.status.localeCompare(b.status)),
      ];
      setList(sortedList);
      setSort({ ...sort, status: "asc" });
    }
  };

  const sortByFrom = () => {
    if (sort.from === "asc") {
      const sortedList = [...list.sort((a, b) => b.from - a.from)];
      setList(sortedList);
      setSort({ ...sort, from: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.from - b.from)];
      setList(sortedList);
      setSort({ ...sort, from: "asc" });
    }
  };

  const sortByTo = () => {
    if (sort.to === "asc") {
      const sortedList = [...list.sort((a, b) => b.to - a.to)];
      setList(sortedList);
      setSort({ ...sort, to: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.to - b.to)];
      setList(sortedList);
      setSort({ ...sort, to: "asc" });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };

  const [addForm, setAddForm] = useState({
    grade: "",
    gradeId: "",
    from: 0,
    to: 0,
    breakups: [{ id: 1, name: "", percentage: "" }],
    deduction: [{ id: 1, name: "", percentage: "" }],
  });

  let [loader, setLoader] = useState(false);

  const handleClick = () => {
    setAddForm({
      ...addForm,
      breakups: [
        ...addForm.breakups,
        {
          id: addForm.breakups.length + 1,
          name: "",
          percentage: "",
        },
      ],
    });
  };

  const handleClickDeduction = () => {
    setAddForm({
      ...addForm,
      deduction: [...addForm.deduction, { name: "", percentage: "" }],
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditSalaryGrade(null);
    setAddForm({
      grade: "",
      gradeId: "",
      from: 0,
      to: 0,
      breakups: [{ name: "", percentage: "" }],
      deduction: [{ name: "", percentage: "" }],
    });
    setOpen(false);
  };

  useEffect(() => {
    getSalaryGrade();
  }, [selectedSetting]);

  useEffect(() => {
    setList([]);
    setSearchFilter([]);
  }, [selectedSetting]);

  const getSalaryGrade = async () => {
    try {
      const { data, status } = await get(`${urls.salaryGrade.getList}`, {
        headers: { roleFunction: "salaryGrade" },
      });

      setList(data.result.data);
      console.log(data, "sssss");
    } catch (error) {
      console.log(error);
    }
  };

  const handleBreakupInputChange = (e, id) => {
    setAddForm({
      ...addForm,
      breakups: [
        ...addForm.breakups.map((d) =>
          d.id === id ? { ...d, [e.target.name]: e.target.value } : d
        ),
      ],
    });
  };

  const handleDeductionInputChange = (e, id) => {
    setAddForm({
      ...addForm,
      deduction: [
        ...addForm.deduction.map((d) =>
          d.id === id ? { ...d, [e.target.name]: e.target.value } : d
        ),
      ],
    });
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editSalaryGrade) {
        const { status } = await post(
          `${urls.salaryGrade.create}`,
          {
            grade: addForm.grade,
            gradeId: addForm.gradeId,
            from: addForm.from,
            to: addForm.to,
            breakups: addForm.breakups,
            deduction: addForm.deduction,
            status: addForm.status,
          },
          { headers: { roleFunction: "salaryGrade" } }
        );

        getSalaryGrade();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.salaryGrade.update}${editSalaryGrade}`,
          "",
          {
            grade: addForm.grade,
            gradeId: addForm.gradeId,
            from: addForm.from,
            to: addForm.to,
            breakups: addForm.breakups,
            deduction: addForm.deduction,
            status: addForm.status,
          },
          { headers: { roleFunction: "salaryGrade" } }
        );

        getSalaryGrade();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleGradeEdit = (id, data) => {
    setEditSalaryGrade(id);
    setAddForm({
      grade: data.grade,
      gradeId: data.gradeId,
      from: data.from,
      to: data.to,
      breakups: data.breakups,
      deduction: data.deduction,
      status: data.status,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.salaryGrade.delete}`,
        id,
        {},
        { roleFunction: "salaryGrade" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.grade.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleRemoveConBreakups = (indexToRemove) => {
    setAddForm((prevAddForm) => ({
      ...prevAddForm,
      breakups: prevAddForm.breakups.filter((val, i) => i !== indexToRemove),
    }));
  };

  const handleRemoveConDeduction = (indexToRemove) => {
    setAddForm((prevAddForm) => ({
      ...prevAddForm,
      deduction: prevAddForm.deduction.filter((val, i) => i !== indexToRemove),
    }));
  };

  return (
    <>
      <div style={{ margin: "15px" }}>
        <SectionsSubHeader title="Salary Grade" />

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", sm: "right", xs: "center" },
            my: 2,
          }}
        >
          <Grid item xs="auto">
            <TextField
              size="small"
              label="Search Grade"
              value={search}
              onChange={handleSearch}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByGradeCode}
                    className="class-table-header"
                  >
                    Grade Id
                    <span style={{ cursor: "pointer" }}>
                      {sort.gradeCode === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByGrade} className="class-table-header">
                    Grade
                    <span style={{ cursor: "pointer" }}>
                      {sort.grade === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByFrom} className="class-table-header">
                    From
                    <span style={{ cursor: "pointer" }}>
                      {sort.from === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByTo} className="class-table-header">
                    To
                    <span style={{ cursor: "pointer" }}>
                      {sort.to === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByStatus} className="class-table-header">
                    Status
                    <span style={{ cursor: "pointer" }}>
                      {sort.status === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((grade, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {grade.gradeCode}
                          </TableCell>
                          <TableCell align="center">{grade.grade}</TableCell>
                          <TableCell align="center">{grade.from}</TableCell>
                          <TableCell align="center">{grade.to}</TableCell>
                          <TableCell align="center">{grade.status}</TableCell>
                          <TableCell align="center">
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="salaryGrade"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleGradeEdit(grade._id, grade)
                                    }
                                  >
                                    <EditIcon fontSize="smal" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="salaryGrade"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      setDeleteModal(grade._id, grade)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={grade._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((grade, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {grade.gradeCode}
                          </TableCell>
                          <TableCell align="center">{grade.grade}</TableCell>
                          <TableCell align="center">{grade.from}</TableCell>
                          <TableCell align="center">{grade.to}</TableCell>
                          <TableCell align="center">{grade.status}</TableCell>
                          <TableCell align="center">
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="salaryGrade"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleGradeEdit(grade._id, grade)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>

                              <ShowComponent
                                roleFunction="salaryGrade"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      setDeleteModal(grade._id, grade)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={grade._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!list.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        {/* ============== Add Salary Grade ====== */}
        <div className="add-icon">
          <Tooltip title="Add Salary Grade">
            <Fab
              variant="contained"
              style={{ color: "#ffff", backgroundColor: "#1b3779" }}
              onClick={handleClickOpen}
            >
              <ShowComponent roleFunction="salaryGrade" action="add">
                <AddIcon />
              </ShowComponent>
            </Fab>
          </Tooltip>

          {/* =========================== */}

          {/* ============= Add/Update Salary Grade ====================== */}
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <form className="form-tag" onSubmit={handleAddSubmit}>
              <Box sx={style}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      textAlign="center"
                      fontSize="20px"
                      fontWeight="bold"
                    >
                      {editSalaryGrade
                        ? "Update Salary Grade"
                        : "Add Salary Grade"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <TextField
                        required
                        label="Grade"
                        name="grade"
                        value={addForm.grade || ""}
                        onChange={handleAddForm}
                        size="small"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <TextField
                        required
                        label="From"
                        type="number"
                        value={addForm.from || ""}
                        onChange={handleAddForm}
                        size="small"
                        name="from"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <TextField
                        required
                        size="small"
                        label="To"
                        type="number"
                        name="to"
                        value={addForm.to || ""}
                        onChange={handleAddForm}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Status</InputLabel>
                      <Select
                        fullWidth
                        label="Status"
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        size="small"
                        name="status"
                        value={addForm.status || ""}
                        onChange={handleAddForm}
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"inactive"}>InActive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      sx={{ fontSize: "15px", fontWeight: 600, mt: 1 }}
                    >
                      Breakups:
                    </Typography>
                  </Grid>
                  {addForm.breakups.map((val, i) => (
                    <React.Fragment key={i}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          marginBottom: "1px",
                          backgroundColor: theme.palette.grey[100],
                          borderRadius: "5px",
                          padding: "10px 5px 15px 5px",
                          ml: 1,

                          border: "1px solid lightgrey",
                          marginTop: "5px",
                        }}
                      >
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <FormControl fullWidth size="small">
                            <TextField
                              fullWidth
                              required
                              value={val.name}
                              label="Name"
                              name="name"
                              size="small"
                              onChange={(e) =>
                                handleBreakupInputChange(e, val.id)
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <FormControl fullWidth size="small">
                            <TextField
                              fullWidth
                              required
                              type="number"
                              value={val.percentage}
                              label="Percentage"
                              name="percentage"
                              size="small"
                              onChange={(e) =>
                                handleBreakupInputChange(e, val.id)
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={1}>
                          <Button
                            size="small"
                            onClick={() => handleRemoveConBreakups(i)}
                          >
                            <RemoveIcon color="error" fontSize="medium" />
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add More
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      sx={{ fontSize: "15px", fontWeight: 600, mt: 1 }}
                    >
                      Deduction:
                    </Typography>
                  </Grid>
                  {addForm.deduction.map((val, i) => (
                    <React.Fragment key={i}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          marginBottom: "1px",
                          backgroundColor: theme.palette.grey[100],
                          borderRadius: "5px",
                          padding: "10px 5px 15px 5px",
                          ml: 1,

                          border: "1px solid lightgrey",
                          marginTop: "5px",
                        }}
                      >
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <FormControl fullWidth size="small">
                            <TextField
                              required
                              fullWidth
                              label="Name"
                              value={val.name}
                              name="name"
                              size="small"
                              onChange={(e) =>
                                handleDeductionInputChange(e, val.id)
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <FormControl fullWidth size="small">
                            <TextField
                              fullWidth
                              required
                              type="number"
                              value={val.percentage}
                              label="Percentage"
                              name="percentage"
                              size="small"
                              onChange={(e) =>
                                handleDeductionInputChange(e, val.id)
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={1}>
                          <Button onClick={() => handleRemoveConDeduction(i)}>
                            <RemoveIcon fontSize="medium" color="error" />
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleClickDeduction}
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add More
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={6} lg={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        justifyContent: {
                          md: "right",
                          sm: "center",
                          xs: "center",
                        },
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        size="small"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>

                      <LoadingButton
                        loading={loader}
                        variant="contained"
                        size="small"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        {editSalaryGrade ? "Update" : "Add"}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Dialog>
          {/* ============================= */}
        </div>
      </div>
    </>
  );
};

export default SalaryGrade;
