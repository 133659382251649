import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  Button,
  Tooltip,
  Avatar,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import { Add, Delete, Edit, ListAlt, Print } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "../../../styles/components/Employee/Employee.scss";
import DeleteModal from "../Academic/DeleteModal";
import { del, get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { objHas } from "../../../utils/utils";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";

import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import { Search } from "@material-ui/icons";
import ShowComponent from "../../ShowComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Employee = () => {
  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [value, setValue] = useState(0);
  const [schoolEmployee, setSchoolEmployee] = useState([]);
  const [inactiveEmployee, setInactiveEmployee] = useState([]);
  const [inactiveEmployee2, setInactiveEmployee2] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [departments, setDepartment] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [searchInactive, setSearchInActive] = useState("");
  const [showPagination, setShowPagination] = useState(false);
  const [showPaginationInactive, setShowPaginationInactive] = useState(false);
  const [empPdfDownloading, setEmpPdfDownloading] = useState(false);
  const [empSheetDownloading, setEmpSheetDownloading] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [searchFilterInactiveEmp, setSearchFilterInactiveEmp] = useState([]);
  const [sort, setSort] = useState({
    department: "asc",
    department: "asc",
    designation: "asc",
    empName: "asc",
    empId: "asc",
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortByDepartments = () => {
    console.log("departments");
    let sortedList;

    if (sort.department === "asc") {
      sortedList = [
        ...departments.sort((a, b) => b.department.localeCompare(a.department)),
      ];
      setSort({ ...sort, department: "des" });
    } else {
      sortedList = [
        ...departments.sort((a, b) => a.department.localeCompare(b.department)),
      ];
      setSort({ ...sort, department: "asc" });
    }

    setDepartment(sortedList);
  };

  const sortByEmployeeListDepartments = () => {
    console.log("sortByEmployeeListDepartments");

    let sortedList;
    let newSortOrder;

    if (sort.department === "asc") {
      sortedList = [
        ...schoolEmployee.sort((a, b) =>
          b.academicInfo.department.department.localeCompare(
            a.academicInfo.department.department
          )
        ),
      ];
      newSortOrder = "des";
    } else {
      sortedList = [
        ...schoolEmployee.sort((a, b) =>
          a.academicInfo.department.department.localeCompare(
            b.academicInfo.department.department
          )
        ),
      ];
      newSortOrder = "asc";
    }

    setSchoolEmployee(sortedList);
    setSort({ ...sort, department: newSortOrder });
  };

  const sortByEmployeeListDepartments1 = () => {
    console.log("sortByEmployeeListDepartments");

    let sortedList;
    let newSortOrder;

    if (sort.department === "asc") {
      sortedList = [
        ...inactiveEmployee.sort((a, b) =>
          b.academicInfo.department.department.localeCompare(
            a.academicInfo.department.department
          )
        ),
      ];
      newSortOrder = "des";
    } else {
      sortedList = [
        ...inactiveEmployee.sort((a, b) =>
          a.academicInfo.department.department.localeCompare(
            b.academicInfo.department.department
          )
        ),
      ];
      newSortOrder = "asc";
    }

    setInactiveEmployee(sortedList);
    setSort({ ...sort, department: newSortOrder });
  };

  const sortBydesignation = () => {
    console.log("sortBydesignation");
    let sortedList;

    if (sort.designation === "asc") {
      sortedList = [
        ...schoolEmployee.sort((a, b) =>
          b.basicInfo.designation.designation.localeCompare(
            a.basicInfo.designation.designation
          )
        ),
      ];
      setSort({ ...sort, designation: "des" });
    } else {
      sortedList = [
        ...schoolEmployee.sort((a, b) =>
          a.basicInfo.designation.designation.localeCompare(
            b.basicInfo.designation.designation
          )
        ),
      ];
      setSort({ ...sort, designation: "asc" });
    }

    setSchoolEmployee(sortedList);
  };

  const sortBydesignation1 = () => {
    console.log("sortBydesignation");
    let sortedList;

    if (sort.designation === "asc") {
      sortedList = [
        ...inactiveEmployee.sort((a, b) =>
          b.basicInfo.designation.designation.localeCompare(
            a.basicInfo.designation.designation
          )
        ),
      ];
      setSort({ ...sort, designation: "des" });
    } else {
      sortedList = [
        ...inactiveEmployee.sort((a, b) =>
          a.basicInfo.designation.designation.localeCompare(
            b.basicInfo.designation.designation
          )
        ),
      ];
      setSort({ ...sort, designation: "asc" });
    }

    setInactiveEmployee(sortedList);
  };

  const sortByEmpName = () => {
    console.log("empName");
    let sortedList;
    if (sort.empName === "asc") {
      sortedList = [
        ...schoolEmployee.sort((a, b) =>
          b.basicInfo.empName.localeCompare(a.basicInfo.empName)
        ),
      ];
      setSort({ ...sort, empName: "des" });
    } else {
      sortedList = [
        ...schoolEmployee.sort((a, b) =>
          a.basicInfo.empName.localeCompare(b.basicInfo.empName)
        ),
      ];
      setSort({ ...sort, empName: "asc" });
    }

    setSchoolEmployee(sortedList);
  };
  const sortByEmpName1 = () => {
    console.log("empName");
    let sortedList;
    if (sort.empName === "asc") {
      sortedList = [
        ...inactiveEmployee.sort((a, b) =>
          b.basicInfo.empName.localeCompare(a.basicInfo.empName)
        ),
      ];
      setSort({ ...sort, empName: "des" });
    } else {
      sortedList = [
        ...inactiveEmployee.sort((a, b) =>
          a.basicInfo.empName.localeCompare(b.basicInfo.empName)
        ),
      ];
      setSort({ ...sort, empName: "asc" });
    }

    setInactiveEmployee(sortedList);
  };

  const sortByEmpId = () => {
    console.log("sortByEmpId");
    let sortedList;
    if (sort.empId === "asc") {
      sortedList = [
        ...schoolEmployee.sort((a, b) => b.basicInfo.empId - a.basicInfo.empId),
      ];
      setSort({ ...sort, empId: "des" });
    } else {
      sortedList = [
        ...schoolEmployee.sort((a, b) => a.basicInfo.empId - b.basicInfo.empId),
      ];
      setSort({ ...sort, empId: "asc" });
    }

    setSchoolEmployee(sortedList);
  };

  const sortByEmpId1 = () => {
    console.log("sortByEmpId");
    let sortedList;
    if (sort.empId === "asc") {
      sortedList = [
        ...inactiveEmployee.sort(
          (a, b) => b.basicInfo.empId - a.basicInfo.empId
        ),
      ];
      setSort({ ...sort, empId: "des" });
    } else {
      sortedList = [
        ...inactiveEmployee.sort(
          (a, b) => a.basicInfo.empId - b.basicInfo.empId
        ),
      ];
      setSort({ ...sort, empId: "asc" });
    }

    setInactiveEmployee(sortedList);
  };

  const handleIconChange = async (e) => {
    e.preventDefault();
    try {
      setShowPagination(false);

      const res = await get(urls.employee.getAllEmployee, {
        params: {
          headers: { roleFunction: "employee" },
          search: {
            "basicInfo.empName": { $regex: search },
          },
        },
      });
      const res2 = await get(urls.employee.inactiveemployee, {
        params: {
          headers: { roleFunction: "employee" },
          search: {
            "basicInfo.empName": { $regex: search },
          },
        },
      });
      setSchoolEmployee(res.data.data);

      setInactiveEmployee(res2.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        urls.employee.delEmployee,
        id,
        {},
        { roleFunction: "employee" }
      );
      if (res.data.success) {
        const filteredSection = schoolEmployee.filter(
          (item) => item._id !== res.data.data._id
        );
        const filteredSection2 = inactiveEmployee.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolEmployee(filteredSection);
        setInactiveEmployee(filteredSection2);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("console");

    const schoolEmployeeGet = async () => {
      try {
        setShowPagination(true);
        const response = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "employee" },
          params: {
            limit: 10,
            page: 1,
          },
        });
        const response2 = await get(urls.employee.inactiveemployee, {
          headers: { roleFunction: "employee" },
          params: {
            limit: 10,
            page: 1,
          },
        });
        setSchoolEmployee(response.data.data);
        setAllEmployees(response.data.data);
        setInactiveEmployee(response2.data.data);
        setSearchFilter(response.data.data);
        // console.log(response.data.data, "xxxxxxxx");
        setTotalCount(response.data.count);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllDepartment = async () => {
      try {
        const departments = await get(urls.department.getDepartment, {
          headers: { roleFunction: "employee" },
        });
        setDepartment(departments.data.data);
        console.log("setDepartment", departments.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDepartment();
    schoolEmployeeGet();
    // schoolEmployeeGetAll();
  }, [selectedSetting._id]);

  // const handleChangePage = async (event, newPage) => {
  //   try {
  //     const res = await get(urls.employee.getAllEmployee, {
  //       params: {
  //         limit: 10,
  //         page: newPage + 1,
  //       },
  //     });

  //     setPage(newPage);
  //     setSchoolEmployee(res.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleChangePageInactive = async (event, newPage) => {
    try {
      const res2 = await get(urls.employee.inactiveemployee, {
        headers: { roleFunction: "employee" },
        params: {
          limit: 10,
          page: newPage + 1,
        },
      });
      setPage(newPage);
      setInactiveEmployee(res2.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEmpPdf = async () => {
    try {
      setEmpPdfDownloading(true);
      const getEmpPdf = await get(
        `/${selectedSetting._id}/employees-list-pdf`,
        {
          headers: { roleFunction: "employee" },
          responseType: "blob",
        }
      );
      const pdfUri = URL.createObjectURL(getEmpPdf.data);
      window.open(pdfUri, "__blank");
      setEmpPdfDownloading(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleEmpSheet = async () => {
    try {
      setEmpSheetDownloading(true);
      const getEmpSheet = await get(
        `/${selectedSetting._id}/employees-list-excel`,
        {
          headers: { roleFunction: "employee" },
          responseType: "blob",
        }
      );
      const sheetUri = URL.createObjectURL(getEmpSheet.data);
      const link = document.createElement("a");
      link.href = sheetUri;
      link.setAttribute("download", "employees.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setEmpSheetDownloading(false);
    } catch (error) {}
  };
  const handleInactiveEmpPdf = async () => {
    try {
      setEmpPdfDownloading(true);
      const getEmpPdf = await get(
        `/${selectedSetting._id}/inactiveemployees-list-pdf`,
        {
          headers: { roleFunction: "employee" },
          responseType: "blob",
        }
      );
      const pdfUri = URL.createObjectURL(getEmpPdf.data);
      window.open(pdfUri, "__blank");
      setEmpPdfDownloading(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleInactiveEmpSheet = async () => {
    try {
      setEmpSheetDownloading(true);
      const getEmpSheet = await get(
        `/${selectedSetting._id}/inactiveemployees-list-excel`,
        {
          headers: { roleFunction: "employee" },
          responseType: "blob",
        }
      );
      const sheetUri = URL.createObjectURL(getEmpSheet.data);
      const link = document.createElement("a");
      link.href = sheetUri;
      link.setAttribute("download", "employees.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setEmpSheetDownloading(false);
    } catch (error) {}
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value);
    e.preventDefault();
    let search = {};
    if (e.target.value?.trim()) {
      if (/^\d+$/.test(e.target.value)) {
        search.contactNumber = Number(e.target.value);
      } else {
        search["basicInfo.empName"] = { $regex: e.target.value, $options: "i" };
      }
    }
    try {
      const res = await get(urls.employee.getAllEmployee, {
        headers: { roleFunction: "employee" },
        params: {
          search,
        },
      });
      // console.log(res.data.data);
      setSearchFilter(res.data.data);
    } catch (error) {}
  };

  const handleSearchInactiveEmp = async (e) => {
    setSearchInActive(e.target.value);
    e.preventDefault();
    let search = {};
    if (e.target.value?.trim()) {
      if (/^\d+$/.test(e.target.value)) {
        search.contactNumber = Number(e.target.value);
      } else {
        search["basicInfo.empName"] = { $regex: e.target.value, $options: "i" };
      }
    }
    try {
      const res = await get(urls.employee.inactiveemployee, {
        headers: { roleFunction: "employee" },
        params: {
          search,
        },
      });
      console.log(res.data.data);
      setSearchFilterInactiveEmp(res.data.data);
    } catch (error) {}
  };

  return (
    <Wrapper>
      <Box sx={{ width: "100%" }}>
        <SectionSubHeader title="Employee" />
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "15px 0",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Employee List" {...a11yProps(1)} />
            <Tab label="Inactive Employee List" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">Department</span>
                      <span
                        onClick={sortByDepartments}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.department === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Male</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Female</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Total</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departments
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.department}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {
                              allEmployees.filter(
                                (emp) =>
                                  emp.basicInfo.empGender === "male" &&
                                  objHas(emp, "academicInfo.department._id") ===
                                    row._id
                              ).length
                            }
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {
                              allEmployees.filter(
                                (emp) =>
                                  emp.basicInfo.empGender === "female" &&
                                  objHas(emp, "academicInfo.department._id") ===
                                    row._id
                              ).length
                            }
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {
                              allEmployees.filter(
                                (emp) =>
                                  objHas(emp, "academicInfo.department._id") ===
                                  row._id
                              ).length
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={departments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              my: 1,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                margin: "15px 0",
              }}
            >
              <TextField
                sx={{ ml: 1 }}
                size="small"
                value={search}
                onChange={handleSearch}
                placeholder="Search here..."
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Tooltip title="Print">
                <IconButton
                  onClick={handleEmpPdf}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  {empPdfDownloading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Print />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Excel Sheet Download">
                <IconButton
                  onClick={handleEmpSheet}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  {empSheetDownloading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <ListAlt />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Employee Id</span>
                      <span
                        onClick={sortByEmpId}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.empId === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Photo</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Name</span>
                      <span
                        onClick={sortByEmpName}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.empName === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Department</span>
                      <span
                        onClick={sortByEmployeeListDepartments}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.department === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Designation</span>
                      <span
                        onClick={sortBydesignation}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.designation === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Status</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search.trim()
                    ? searchFilter
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.basicInfo.empId}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  src={objHas(row, "empPhoto.link", "")}
                                />
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.basicInfo.empName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.academicInfo.department.department}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.basicInfo.designation.designation}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.status}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              <div className="edit-delete">
                                <Link to={`/edit-employee/${row._id}`}>
                                  <ShowComponent
                                    roleFunction="employee"
                                    action="update"
                                  >
                                    <Tooltip title="Update">
                                      <IconButton
                                        size="small"
                                        sx={{ color: "#1b3779" }}
                                      >
                                        <Edit fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </ShowComponent>
                                </Link>
                                <ShowComponent
                                  roleFunction="employee"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                              </div>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDelete}
                                id={row._id}
                                setDeleteModal={setDeleteModal}
                                title="employee"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                    : schoolEmployee
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.basicInfo.empId}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  src={objHas(row, "empPhoto.link", "")}
                                />
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.basicInfo.empName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row?.academicInfo?.department?.department}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.basicInfo.designation.designation}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.status}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              <div className="edit-delete">
                                <Link to={`/edit-employee/${row._id}`}>
                                  <ShowComponent
                                    roleFunction="employee"
                                    action="update"
                                  >
                                    <Tooltip title="Update">
                                      <IconButton
                                        size="small"
                                        style={{ color: "#1b3779" }}
                                      >
                                        <Edit fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </ShowComponent>
                                </Link>
                                <ShowComponent
                                  roleFunction="employee"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                              </div>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDelete}
                                id={row._id}
                                setDeleteModal={setDeleteModal}
                                title="employee"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {!schoolEmployee.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {showPagination === true && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={search ? searchFilter.length : schoolEmployee.length}
              // count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                my: 1,
              }}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div
            style={{
              display: "flex",
              gap: 10,
              margin: "15px 0",
            }}
          >
            <TextField
              sx={{ ml: 1 }}
              size="small"
              value={searchInactive}
              onChange={handleSearchInactiveEmp}
              placeholder="Search here..."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip title="Print">
              <IconButton
                onClick={handleInactiveEmpPdf}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                {empPdfDownloading ? <CircularProgress size={20} /> : <Print />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Excel Sheet Download">
              <IconButton
                onClick={handleInactiveEmpSheet}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                {empSheetDownloading ? (
                  <CircularProgress size={20} />
                ) : (
                  <ListAlt />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Employee Id</span>
                    <span
                      onClick={sortByEmpId1}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.empId === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Photo</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Name</span>
                    <span
                      onClick={sortByEmpName1}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.empName === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Department</span>
                    <span
                      onClick={sortByEmployeeListDepartments1}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.department === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Designation</span>
                    <span
                      onClick={sortBydesignation1}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.designation === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Status</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchInactive
                  ? searchFilterInactiveEmp.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.basicInfo.empId}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Avatar src={objHas(row, "empPhoto.link", "")} />
                          </Box>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.basicInfo.empName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.department.department}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.basicInfo.designation.designation}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Link to={`/edit-employee/${row._id}`}>
                              <ShowComponent
                                roleFunction="employee"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    sx={{ color: "#1b3779" }}
                                    size="small"
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                            </Link>
                            <ShowComponent
                              roleFunction="employee"
                              action="delete"
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                            title="employee"
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : inactiveEmployee.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.basicInfo.empId}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Avatar src={objHas(row, "empPhoto.link", "")} />
                          </Box>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.basicInfo.empName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.department.department}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.basicInfo.designation.designation}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Link to={`/edit-employee/${row._id}`}>
                              <ShowComponent
                                roleFunction="employee"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    sx={{ color: "#1b3779" }}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                            </Link>
                            <ShowComponent
                              roleFunction="employee"
                              action="delete"
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            // emp={row._id}
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                            title="employee"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {!inactiveEmployee.length && !searchInactive.trim() && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {searchInactive.trim() && !searchFilterInactiveEmp.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
          {showPagination === true && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={
                searchInactive
                  ? searchFilterInactiveEmp.length
                  : inactiveEmployee.length
              }
              page={page}
              rowsPerPage={rowsPerPage}
              // count={totalCount}
              // rowsPerPage={10}
              // onPageChange={handleChangePageInactive}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                my: 1,
              }}
            />
          )}
        </TabPanel>
      </Box>
      <ShowComponent roleFunction="employee" action="add">
        <div className="add-btn">
          <Tooltip title="Add Employee">
            <Link to="/addemployee">
              <Fab
                size="medium"
                style={{ background: "#1b3779", color: "#fff" }}
                aria-label="add"
              >
                <Add />
              </Fab>
            </Link>
          </Tooltip>
        </div>
      </ShowComponent>
    </Wrapper>
  );
};

export default Employee;
