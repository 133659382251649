import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  Fab,
  Grid,
  styled,
  TextareaAutosize,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";

import { LoadingButton } from "@mui/lab";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const AddDepartment = (props) => {
  const [department, setDepartment] = useState("");
  const [orderSequence, setOrderSequence] = useState(0);
  const [note, setNote] = useState("");
  const [editId, setEditId] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (props.open || props.updateDepartment) {
      setDepartment("");
      setNote("");
      setOrderSequence(0);

      if (props.updateDepartment) {
        setDepartment(props.updateDepartment.department);
        setOrderSequence(
          props.updateDepartment.orderSequence
            ? props.updateDepartment.orderSequence
            : ""
        );
        setNote(props.updateDepartment.note);
        setEditId(props.updateDepartment._id);
      }
    }
  }, [props.open, props.updateDepartment]);

  const handleDepartment = (e) => {
    setDepartment(e.target.value);
  };

  const handleOrderSequence = (e) => {
    setOrderSequence(e.target.value);
  };

  const handleDepartmentNote = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.updateDepartment) {
      await props.handleUpdate(
        {
          department,
          orderSequence,
          note,
        },
        props.updateDepartment._id
      );
    } else {
      await props.handleSubmit(e, {
        department,
        orderSequence,
        note,
      });
    }
  };

  const handleCloseModal = () => {
    setDepartment("");
    setOrderSequence();
    setNote("");
    setEditId("");
    props.handleClose();
  };
  const handleClose = () => {
    setDepartment("");
    setOrderSequence();
    setNote("");
    setEditId("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add Department" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}>
          <Add />
        </Fab>
      </Tooltip>

      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form
          className="form-tag"
          onSubmit={handleSubmit}
          style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                fontSize="20px"
                fontWeight="bold">
                {editId ? "Update Department" : "Add Department"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                required
                id="filled-required"
                variant="outlined"
                size="small"
                label="Department"
                value={department}
                onChange={handleDepartment}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                required
                type="number"
                size="small"
                id="filled-required"
                variant="outlined"
                label="Order Sequence"
                value={orderSequence}
                onChange={handleOrderSequence}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextareaAutosize
                maxRows={4}
                aria-label="maximum height"
                placeholder="Drop a note"
                value={note}
                onChange={handleDepartmentNote}
                style={{
                  width: "100%",
                  maxHeight: 110,
                  borderRadius: "5px",
                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: {
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  },
                }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="small"
                  type="button"
                  color="error"
                  sx={{
                    color: "#ffff",
                  }}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={props.loader}
                  disabled={!department}
                  size="small"
                  type="submit"
                  variant="contained"
                  sx={{
                    ml: 2,
                    color: "#fff",
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}>
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </div>
  );
};

export default AddDepartment;
