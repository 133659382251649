import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import React, { useEffect, useState, useContext } from "react";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";

import Wrapper from "../../Wrapper";
const styles = {
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
const StudentYearlyAttendence = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [loader, setLoader] = useState(false);
  const { selectedSetting } = useContext(SettingContext);
  const [data, setData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "reports" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getStudent = async () => {
      try {
        const response = await get(`${urls.students.getAllStudent}`, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              "academicInfo.class": formData.className,
              "academicInfo.section": formData.section,
            },
          },
        });
        setStudents(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStudent();
  }, [formData.className, formData.section]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "reports" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "reports" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "reports" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const numbers = [];
  for (let i = 1; i <= 31; i++) {
    numbers.push(
      <TableCell sx={{ color: "white", display: "inline" }} key={i}>
        {i}
      </TableCell>
    );
  }
  const handleFind = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let data = {
        academicYearId: formData.academicYear,
        studentId: formData.student,
      };
      const response = await post(
        `${urls.studentYearlyReport.yearlyReport}`,
        data,
        { headers: { roleFunction: "reports" } }
      );
      console.log(response, "hjhjhjhjhjhj");
      setData(response.data);
      setMonthlyData(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Student Yearly Attendance" />
      <Box sx={{ mt: "15px" }}>
        <Card style={{ marginTop: 10, padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      variant="outlined"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      variant="outlined"
                      fullWidth
                      id="demo-simple-select-filled"
                      name="className"
                      value={formData.className || ""}
                      onChange={handleClassChange}
                      size="small"
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      variant="outlined"
                      fullWidth
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  {/* <FormControl fullWidth size="small" required>
                    <InputLabel>Select Student</InputLabel>
                    <Select
                      label="Select report"
                      id="demo-simple-select-filled"
                      variant="outlined"
                      fullWidth
                      name="student"
                      value={formData.student || ""}
                      onChange={handleOnChange}
                      size="small">
                      {students.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.basicInfo.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={students}
                    getOptionLabel={(student) => student.basicInfo.name}
                    value={students.find(
                      (student) => student._id === formData.student
                    )}
                    onChange={(event, newValue) => {
                      setFormData({
                        ...formData,
                        student: newValue ? newValue._id : "",
                      });
                      setSelectedStudentName(
                        newValue ? newValue.basicInfo.name : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student"
                        variant="outlined"
                        size="small"
                        placeholder="Search student with name"
                        value={selectedStudentName}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <LoadingButton
                    size="small"
                    variant="contained"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                    loading={loader}
                    onClick={handleFind}
                  >
                    Find
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
        <Card style={{ padding: 10, marginTop: "12px" }}>
          <div
            style={{
              paddingLeft: "1rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <div>
              Working Days:<span>{data?.totalWorkingDays}</span>
            </div>
            <div>
              Present Days:<span>{data?.presentDays}</span>
            </div>
            <div>
              Absent Days:<span>{data?.absentDays}</span>
            </div>
            <div>
              Percentage:<span>{data?.precentageWork}%</span>
            </div>
          </div>
        </Card>
        <TableContainer component={Paper} sx={{ overflowX: "scroll" }}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  Month-Year
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Working Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Present Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Absent Days
                </TableCell>
                {numbers.map((num) => (
                  <TableCell
                    key={num.key}
                    sx={{ color: "white" }}
                    align="center"
                  >
                    {num.key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {monthlyData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <TableRow>
                    <TableCell align="center">{data.monthYear}</TableCell>

                    <TableCell align="center">
                      {data.updatedDocuments.filter(
                        (ele) => ele.status === "present"
                      ).length +
                        data.updatedDocuments.filter(
                          (ele) => ele.status === "absent"
                        ).length}
                    </TableCell>

                    <TableCell align="center">
                      {
                        data.updatedDocuments.filter(
                          (ele) => ele.status === "present"
                        ).length
                      }
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      {
                        data.updatedDocuments.filter(
                          (ele) => ele.status === "absent"
                        ).length
                      }
                    </TableCell>

                    {numbers.map((num) => {
                      const matchingDocument = data.updatedDocuments.filter(
                        (ele) => ele.date == num.key
                      );
                      console.log(matchingDocument, "matchingDocument");
                      return (
                        <TableCell key={num.key} align="center">
                          {matchingDocument.length > 0
                            ? matchingDocument.some(
                                (sta) => sta.status === "present"
                              )
                              ? "P"
                              : matchingDocument.some(
                                  (sta) => sta.status === "absent"
                                )
                              ? "A"
                              : "-"
                            : "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={monthlyData.length || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </Box>
    </Wrapper>
  );
};

export default StudentYearlyAttendence;
