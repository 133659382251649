import React, { useEffect, useContext } from "react";
import "../../../styles/components/Certificate/TransferCertificate.scss";
import {
  Card,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Autocomplete,
  styled,
  Container,
  Typography,
} from "@mui/material";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useState } from "react";
import axios from "axios";
import { instance } from "../../../services/axios.Config";
import { FaBox } from "react-icons/fa";
import data from "./certificate";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import { LoadingButton } from "@mui/lab";
const styles = {
  card: {
    // margin: 15,
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 50 },
    // mt: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
  boxwidth: {
    width: { xs: "100%", sm: "100%", md: "100%", lg: "80%" },
    marginTop: 2,
    // background: "red",
  },
  textfieldwidth: {
    width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
    // marginTop: { xs: 0, sm: 0, md: -1, lg: -1 },
  },
  textHeading: {
    fontSize: { xs: "20px", sm: "20px", md: "25px", lg: "25px" },
    fontWeight: "bold",
  },
  griditem: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
    },
  },
};

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "30px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "left",
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "25px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "21px",
  },
}));

const Signature = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
}));

const ListItem = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  width: "48%",
  // backgroundColor: "blue",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    width: "100%",
  },
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: "20px",

  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: 500,
  [theme.breakpoints.down("md")]: {
    fontSize: "25px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));

function TransferCertificate() {
  const { selectedSetting } = useContext(SettingContext);
  const [settings, setSettings] = useState({});
  const [formData, setFormData] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const [selectAcademicYear, setSelectAcademicYear] = useState([]);
  const [studentsSC, setStudentsSC] = useState([]);
  const [studentSelect, setStudentSelect] = useState({});
  const [printLoader, setPrintLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const getSettings = async () => {
      try {
        const settings = await get(urls.settings.getAllSettings, {
          headers: { roleFunction: "transferCertificate" },
        });
        setSettings(settings.data.data[0]);
        const data = settings.data.data[0];
        setFormData({
          ...formData,
          schoolName: data.schoolName,
        });
        setSelectAcademicYear([]);
        setFormData({});
      } catch (error) {
        console.log(error);
      }
    };
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "transferCertificate" },
        });
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
    getSettings();
    setSelectAcademicYear([]);

    setStudentSelect(null);
    setFormData({});
  }, [selectedSetting._id]);
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleAcademicYears = async (e) => {
    try {
      setStudentSelect(null);
      setSelectAcademicYear(e.target.value);

      const res = await get(urls.students.getAllStudent, {
        headers: { roleFunction: "transferCertificate" },
        params: {
          search: {
            academicYear: e.target.value,
          },
        },
      });
      setStudentsSC(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStudentSelect = (val, newVal) => {
    console.log(newVal);
    setStudentSelect(newVal);
    setFormData({});
  };

  const handleIssue = async (e) => {
    e.preventDefault();

    try {
      setFormData({
        ...formData,
        studentName: studentSelect.basicInfo && studentSelect.basicInfo.name,
        birthPlace:
          studentSelect.basicInfo && studentSelect.basicInfo.birthPlace,
        sex: studentSelect.basicInfo && studentSelect.basicInfo.gender,
        religion: studentSelect.basicInfo && studentSelect.basicInfo.religion,
        caste: studentSelect.basicInfo && studentSelect.basicInfo.caste,
        aadhar: studentSelect.basicInfo && studentSelect.basicInfo.aadharNo,
        fatherName:
          studentSelect.fatherInfo && studentSelect.fatherInfo.fatherName,
        motherName:
          studentSelect.motherInfo && studentSelect.motherInfo.motherName,
        admissionDate:
          studentSelect.basicInfo && studentSelect.basicInfo.addmissionDate,
        prevSchool:
          studentSelect.prevSchInfo && studentSelect.prevSchInfo.prevSchool,
        currentClass:
          studentSelect.academicInfo &&
          studentSelect.academicInfo.class.className,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    setPrintLoader(true);
    try {
      const transferCertificate = await instance({
        method: "post",
        url: `/${selectedSetting._id}/transfer-certificate`,
        data: {
          data: formData,
        },
        responseType: "blob",
      });
      const uri = URL.createObjectURL(transferCertificate.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setPrintLoader(false);
  };

  console.log(studentSelect, "selected Student");
  return (
    <Wrapper>
      <SectionSubHeader title="Transfer Certificate" />
      <Card style={styles.card}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Academic Year</InputLabel>
              <Select
                label="Academic Year"
                fullWidth
                name="Academic Year"
                value={selectAcademicYear}
                onChange={(event) => handleAcademicYears(event)}
              >
                {academicYears &&
                  academicYears.map((row, index) => (
                    <MenuItem
                      key={row._id}
                      value={row._id}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      {row.academicYearFrom} - {row.academicYearTo}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            // sx={{ display: "flex", justifyContent: "center" }}
          >
            <Autocomplete
              fullWidth
              size="small"
              key={selectedSetting._id}
              //itemID={selectedSetting._id}
              // sx={{ width: { md: 227, xs: "100%" } }}
              // inputValue={studentSelect ? studentSelect.basicInfo.name : ""}
              disablePortal
              getOptionLabel={(option) => option.basicInfo.name}
              id="combo-box-demo-id"
              options={studentsSC}
              onChange={handleStudentSelect}
              renderInput={(params) => (
                <TextField
                  //   value={studentSelect? studentSelect.basicInfo.name : ""}
                  size="small"
                  fullWidth
                  {...params}
                  label="Students"
                  placeholder="Search student with name"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              display: "flex",
              gap: "5px",
              // mt: 2,
              justifyContent: { md: "flex-end", xs: "center", sm: "center" },
            }}
          >
            <Button
              // onClick={handleSubmit}
              type="submit"
              onClick={handleIssue}
              size="small"
              variant="contained"
              sx={styles.Button}
            >
              Issue
            </Button>
            <Button size="small" variant="contained" sx={styles.Button}>
              Bulk Issue
            </Button>
            <LoadingButton
              loading={printLoader}
              size="small"
              onClick={handleSubmit}
              variant="contained"
              sx={{
                background: "#1b3779",
                ":hover": { background: "#1b3779" },
              }}
            >
              Print
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: "center" }}
        >
          <Typography
            sx={{
              fontSize: { xs: "25px", sm: "25px", md: "30px", lg: "30px" },
              fontWeight: "bold",
            }}
          >
            {selectedSetting.schoolName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: "center" }}
        >
          <Title>ENGLISH MEDIUM PRIMARY AND HIGH SCHOOL</Title>
          <Title>{selectedSetting.address}</Title>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: "center" }}
        >
          <Header>TRANSFER CERTIFICATE</Header>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          // bgcolor={"yellow"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box sx={styles.boxwidth}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>1] Name of the School:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  fullWidth
                  name="schoolName"
                  value={selectedSetting.schoolName}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>2] Name of the Pupil in full:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="studentName"
                  value={formData.studentName || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>3] G. R. No:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="grNo"
                  value={formData.grNo || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>4] Place of Birth:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="birthPlace"
                  value={formData.birthPlace || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem> 5] Date of Birth(in figures and words):</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="dobWords"
                  value={formData.dobWords || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>6] Sex:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="sex"
                  value={formData.sex || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>7] Nationality:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="nationality"
                  value={formData.nationality || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>8] Religion:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="religion"
                  value={formData.religion || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>9] Caste:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="caste"
                  value={formData.caste || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>
                  {" "}
                  10] Whether the candidate belongs to SC and ST":
                </ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="belongs"
                  value={formData.belongs || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>11] Aadhar No.:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="aadhar"
                  value={formData.aadhar || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>12] Name of the father:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="fatherName"
                  value={formData.fatherName || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>13] Name of the mother:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="motherName"
                  value={formData.motherName || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem> 14] Date of Admission in the Scchool:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  name="admissionDate"
                  value={formData.admissionDate || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>15] School Previously Attended:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="prevSchool"
                  value={formData.prevSchool || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>
                  {" "}
                  16] Standard in which admission is granted:
                </ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="admissionGrant"
                  value={formData.admissionGrant}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>
                  {" "}
                  17] Standard in which the pupil was studying at the time of
                  leaving the school(in words):
                </ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="currentClass"
                  value={formData.currentClass || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>18] Medium of instruction:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="instructions"
                  value={formData.instructions || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>
                  {" "}
                  19] Whether the pupil has paid all the free due of the school:
                </ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="fees"
                  value={formData.fees || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>20] Promoted to/ Detained in:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="proDet"
                  value={formData.proDet || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>
                  {" "}
                  21] Whether medically examinated or not ? :
                </ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="medical"
                  value={formData.medical || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>
                  {" "}
                  22] Date of pupil's last attendance at the school:
                </ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="lastAttend"
                  value={formData.lastAttend || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>
                  {" "}
                  23] Date of which the application for the Transfer Certificate
                  was received:
                </ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="transferRecivied"
                  value={formData.transferRecivied || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>
                  {" "}
                  24] Date of issue of the Transfer Certificate:
                </ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="dateIssue"
                  value={formData.dateIssue || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>25] Attendance:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="attendance"
                  value={formData.attendance || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.griditem}>
                <ListItem>26] Character & Conduct:</ListItem>
                <TextField
                  sx={styles.textfieldwidth}
                  id="standard-basic"
                  variant="standard"
                  name="character"
                  value={formData.character || ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                <Header>CERTIFICATE</Header>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <Content>
                  Certified that the entries made in this Transfer Certificate
                  are taken from the entries made as per the general egister
                  maintained in the institution. The entries with the original
                  records persented by the parents
                </Content>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Signature>Prepared By</Signature>
                <Signature>Checked By</Signature>
                <Signature>Head Master</Signature>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default TransferCertificate;
