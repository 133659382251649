import React, { useState, useEffect, useContext } from "react";
import Calendar from "react-calendar";
import { format } from "date-fns";
import "react-calendar/dist/Calendar.css";
import "../../styles/Calendar/Calendar.scss";
import CircleIcon from "@mui/icons-material/Circle";
import { get } from "../../services/apis";

import { urls as url } from "../../services/urlConstant";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

const StudentAttendence = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const getAttendence = async () => {
      try {
        const response = await get(
          `${urls.students.getDashboardStudentAttendenceList}`
        );
        setAttendanceData([...response.data.data]);
        console.log([...response.data.data], "attendenceee");
      } catch (error) {
        console.log(error);
      }
    };
    getAttendence();
  }, [selectedSetting]);
  const renderCell = ({ date }) => {
    const calenderDate = dayjs(new Date(date)).get("date");
    const calenderMonth = dayjs(new Date(date)).get("month") + 1;
    const calenderYear = dayjs(new Date(date)).get("year");
    const dateKey = format(date, "yyyy-MM-dd");
    const attendance = attendanceData.find(
      (data) =>
        dayjs(new Date(data.date)).get("date") === calenderDate &&
        dayjs(new Date(data.date)).get("month") + 1 === calenderMonth &&
        dayjs(new Date(data.date)).get("year") === calenderYear
    );
    let cellClassName = "";
    if (attendance.attendanceDetails.attendanceStatus === true) {
      cellClassName = "present";
    } else if (attendance.attendanceDetails.attendanceStatus === false) {
      cellClassName = "absent";
    }
    return <div className={`cell ${cellClassName}`}></div>;
  };

  const tileContent = ({ date }) => {
    const calenderDate = dayjs(new Date(date)).get("date");
    const calenderMonth = dayjs(new Date(date)).get("month") + 1;
    const calenderYear = dayjs(new Date(date)).get("year");

    const attendance = attendanceData.find(
      (data) =>
        dayjs(new Date(data.date)).get("date") === calenderDate &&
        dayjs(new Date(data.date)).get("month") + 1 === calenderMonth &&
        dayjs(new Date(data.date)).get("year") === calenderYear
    );
    console.log(attendance, "tilecontentS");
    if (attendance) {
      return renderCell({ date });
    }
    return null;
  };

  const tileClassName = ({ date }) => {
    const currentDate = new Date();
    const isSaturday = date.getDay() === 6;

    if (date.toDateString() === currentDate.toDateString()) {
      return "blue-day";
    }
    if (isSaturday) {
      return "saturday-date";
    }
    return "";
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "10px 0px",
          gap: 5,
        }}>
        <div className="colorBotton">
          <CircleIcon sx={{ fontSize: 15, color: "green" }} />
          <Typography>Present</Typography>
        </div>
        <div className="colorBotton">
          <CircleIcon sx={{ fontSize: 15, color: "red" }} />
          <Typography>Absent</Typography>
        </div>
      </Box>
      <Calendar tileContent={tileContent} tileClassName={tileClassName} />
    </>
  );
};
export default StudentAttendence;
