import React, { useContext, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReactPlayer from "react-player";
import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
// import logo from "../assets/Images/wesprucelogo icon.png";
// import NavbarContext from "../context/NavbarContext";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//==================drawer icons=================//
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import QuizIcon from "@mui/icons-material/Quiz";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
// import UserContext from "../context/UserContext";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popper from "@mui/material/Popper";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import SchoolIcon from "@mui/icons-material/School";
import { ImportContacts, StarBorder } from "@mui/icons-material";

import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { Toast, ToastContainer } from "react-bootstrap";
import DownloadIcon from "@mui/icons-material/Download";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";

import DeveloperModeRoundedIcon from "@mui/icons-material/DeveloperModeRounded";
import { theme } from "../../theme";
import { settings } from "./NavDetails";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const styles = {
  borderRadius: "5px",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

//=======================||=====================//
const ICONS = {
  FlashCard: <ViewAgendaIcon fontSize="small" color="warning" />,
  Quiz: <QuizIcon fontSize="small" color="success" />,
  Video: <YouTubeIcon fontSize="small" color="error" />,
  Material: <InsertDriveFileIcon fontSize="small" color="info" />,
  CodePractice: (
    <DeveloperModeRoundedIcon fontSize="small" sx={{ color: "#ffd600" }} />
  ),
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "primary" : "#308fe8",
  },
}));

export default function VideoPlayer() {
  const { id } = useParams();
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(true);
  //   const { setShowNavBar } = useContext(NavbarContext);
  const [chapter, setChapter] = useState([]);
  const [title, setTitle] = useState("");
  const [materialURLs, setMaterialURLs] = useState();
  const [materialFilename, setMaterialFilename] = useState();
  //   const { user, setUser } = useContext(UserContext);

  // quiz
  const [selectedQuiz, setSelectedQuiz] = useState([]);
  const [quizScore, setQuizScore] = useState(0);
  const [selectedQuizCompleteStatus, setSelectedQuizCompleteStatus] =
    useState(false);
  const [quizLoading, setQuizLoading] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  //   const getCurrentUser = async () => {
  //     try {
  //       let { data } = await get(urls.user.getCurrentUser);
  //       window.localStorage.setItem(
  //         "user-customer-webspruce",
  //         JSON.stringify(data.result)
  //       );
  //       setUser(data.result);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   console.log("setUser", user);
  // menu open state
  const [openChapters, setOpenChapters] = useState([]);

  // hide navbar and show on un-mount
  //   useEffect(() => {
  //     setShowNavBar(false);
  //     return () => setShowNavBar(true);
  //   }, []);

  // get course chapters
  const getContent = async () => {
    try {
      const { data } = await get(`${urls.courseContent.getContents}${id}`);
      // console.log(data, "====data");
      const filter = data.result.chapters.filter((m) => m.contents.length > 0);
      setChapter(filter);
      setTitle(data.result.courseId.title);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContent();
  }, [id]);

  const [showChapter, setShowChapter] = useState(null);
  // console.log(showChapter.title, "x");

  useEffect(() => {
    if (selectedContent) {
      for (let chapt of chapter) {
        if (
          chapt.contents.filter((c) => c._id === selectedContent._id).length
        ) {
          setShowChapter(chapt);
        }
      }
    }
  }, [selectedContent]);

  useEffect(() => {
    if (chapter && chapter.length > 0) {
      const firstChapter = chapter[0];
      setSelectedContent(firstChapter.contents[0]);
    }
  }, [chapter]);

  const handleToggleCollapseChapter = (index, chapters) => {
    let indexExists = openChapters.filter((i) => i === index)[0];
    if (indexExists || indexExists === 0) {
      return setOpenChapters(openChapters.filter((i) => i !== index));
    } else {
      setOpenChapters([...openChapters, index]);
    }
  };

  // update course status =======
  const updateCourseStatus = async (score) => {
    try {
      if (!selectedContent) return;
      let chapterId = chapter.filter(
        (c) => c.contents.filter((c) => c._id == selectedContent._id)[0]
      )[0]?._id;
      const payload = {
        courseId: id,
        chapterId: chapterId,
        contentId: selectedContent._id,
        score: score,
      };
      const res = await put(`${urls.user.updateCourseStatus}`, payload);
      //   getCurrentUser();
    } catch (error) {
      console.log(error);
    }
  };

  // handle content navigation =======
  const handleNext = async () => {
    try {
      const currentChapterIndex = chapter.findIndex(
        (m) => m.contents && m.contents.includes(selectedContent)
      );

      if (currentChapterIndex !== -1) {
        const currentChapter = chapter[currentChapterIndex];
        const currentContentIndex = currentChapter.contents.findIndex(
          (content) => content === selectedContent
        );

        if (currentContentIndex < currentChapter.contents.length - 1) {
          const nextContent = currentChapter.contents[currentContentIndex + 1];
          setSelectedContent(nextContent);
          handleContentClick(nextContent);
        } else if (currentChapterIndex < chapter.length - 1) {
          const nextChapter = chapter[currentChapterIndex + 1];
          const firstContentOfNextChapter = nextChapter.contents[0];
          setSelectedContent(firstContentOfNextChapter);
          handleContentClick(firstContentOfNextChapter);
        } else {
          return;
        }
      }
    } catch (error) {
      console.error("Error in handleNext:", error);
    }
  };

  // const handlePrevious = () => {
  //   const currentChapterIndex = chapter.findIndex(
  //     (m) => m.contents && m.contents.includes(selectedContent)
  //   );
  //   console.log(currentChapterIndex, "currentChapterIndex");
  //   if (currentChapterIndex !== -1) {
  //     const currentChapter = chapter[currentChapterIndex];
  //     const currentContentIndex = currentChapter.contents.findIndex(
  //       (content) => content === selectedContent
  //     );
  //     if (currentContentIndex > 0) {
  //       const previousContent =
  //         currentChapter.contents[currentContentIndex - 1];
  //       setSelectedContent(previousContent);
  //       handleContentClick(previousContent);
  //     } else if (currentChapterIndex > 0) {
  //       const previousChapter = chapter[currentChapterIndex - 1];
  //       const lastContentOfPreviousChapter =
  //         previousChapter.contents[previousChapter.contents.length - 1];
  //       setSelectedContent(lastContentOfPreviousChapter);
  //       handleContentClick(lastContentOfPreviousChapter);
  //     }
  //   }
  // };

  // content actions
  const handlePrevious = () => {
    const currentChapterIndex = chapter.findIndex(
      (m) => m.contents && m.contents.includes(selectedContent)
    );

    if (currentChapterIndex !== -1) {
      const currentChapter = chapter[currentChapterIndex];
      const currentContentIndex = currentChapter.contents.findIndex(
        (content) => content === selectedContent
      );

      if (currentContentIndex > 0) {
        const previousContent =
          currentChapter.contents[currentContentIndex - 1];
        setSelectedContent(previousContent);
        handleContentClick(previousContent);
      } else if (currentChapterIndex > 1) {
        const previousChapter = chapter[currentChapterIndex - 1];
        const lastContentOfPreviousChapter =
          previousChapter.contents[previousChapter.contents.length - 1];

        if (lastContentOfPreviousChapter) {
          setSelectedContent(lastContentOfPreviousChapter);
          handleContentClick(lastContentOfPreviousChapter);
        } else {
          const lastChapter = chapter[chapter.length - 1];
          const lastContentOfLastChapter =
            lastChapter.contents[lastChapter.contents.length - 1];

          setSelectedContent(lastContentOfLastChapter);
          handleContentClick(lastContentOfLastChapter);
        }
      } else if (currentChapterIndex === 1) {
        const firstChapter = chapter[0];
        const lastContentOfFirstChapter =
          firstChapter.contents[firstChapter.contents.length - 1];

        setSelectedContent(lastContentOfFirstChapter);
        handleContentClick(lastContentOfFirstChapter);
      }
    } else if (chapter.length > 1) {
      const secondLastChapter = chapter[chapter.length - 2];
      const lastContentOfSecondLastChapter =
        secondLastChapter.contents[secondLastChapter.contents.length - 1];

      if (lastContentOfSecondLastChapter) {
        setSelectedContent(lastContentOfSecondLastChapter);
        handleContentClick(lastContentOfSecondLastChapter);
      } else {
        const lastChapter = chapter[chapter.length - 1];
        const lastContentOfLastChapter =
          lastChapter.contents[lastChapter.contents.length - 1];

        setSelectedContent(lastContentOfLastChapter);
        handleContentClick(lastContentOfLastChapter);
      }
    }
  };

  const handleContentClick = (content) => {
    setSelectedContent(content);
  };

  // quiz ===========
  useEffect(() => {
    if (selectedContent && selectedContent.type == "Quiz") {
      let newQuizList = selectedContent.quiz.map((s) => ({
        question: s.question,
        options: s.options.map((o) => o.name),
        correctOption: s.correctOption,
        givenAnswer: "",
      }));

      //   let currentCourse = user.courses.filter((c) => c.courseId._id == id)[0];
      //   let chapter = currentCourse.chapters.filter(
      //     (c) => c.contents.filter((c) => c._id == selectedContent._id)[0]
      //   )[0];
      let content = chapter.contents.filter(
        (c) => c._id == selectedContent._id
      )[0];

      setSelectedQuiz(newQuizList);
      if (content?.completed) {
        setSelectedQuizCompleteStatus(true);
        setQuizScore(content.score);
      } else {
        setSelectedQuizCompleteStatus(false);
        setQuizScore(0);
      }
    }
  }, [selectedContent]);

  const handleAnswerChange = (event, questionIndex, optionIndex) => {
    let question = selectedQuiz[questionIndex];
    let selectedAnswer = question.options[optionIndex];
    let updatedQuizQuestion = { ...question, givenAnswer: selectedAnswer };
    setSelectedQuiz(
      selectedQuiz.map((s, i) =>
        i === questionIndex ? updatedQuizQuestion : s
      )
    );
  };

  const handleQuizSubmit = async () => {
    setQuizLoading(true);
    if (!selectedQuiz) return;
    let totalQuestions = selectedQuiz.length;
    if (selectedQuiz.filter((q) => !q.givenAnswer).length)
      return toast.error("Please select all the answers before submitting!");

    let totalCorrectAnswers = selectedQuiz.filter(
      (s) => s.correctOption == s.givenAnswer
    ).length;

    updateCourseStatus(totalCorrectAnswers);
    setQuizLoading(false);
  };

  // video actions =======
  const onVideoEnd = () => {
    handleNext();
    updateCourseStatus();
  };

  // drawer actions ======
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // Navbar actions ======
  const handleLogout = () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("user-customer-webspruce");
    window.location.href = "/";
  };

  const handleDownload = (material) => {
    try {
      window.open(material, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadMaterial = (material) => {
    try {
      window.open(material, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigateCoures = () => {
    navigate("/my-courses");
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!chapter) return null;
  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          // height: "100vh",
        }}>
        <CssBaseline />

        {/* AppBar */}
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: "#fafafa",
            color: `${theme.palette.primary.main}`,
          }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}>
              <MenuIcon />
            </IconButton>
            <Button
              style={{ display: "flex", justifyContent: "space-around" }}
              size="large"
              onClick={() => navigate(-1)}>
              {" "}
              <ArrowBackIcon />
            </Button>
            <Typography noWrap sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {title}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex" }}>
              <Tooltip>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{
                      borderRadius: "20px",
                      fontSize: "2.rem",
                      backgroundColor: theme.palette.secondary.main,
                      border: "1px solid lightgray",
                      width: "40px",
                      height: "40px",
                      objectFit: "contain",

                      overflow: "hidden",
                    }}
                    alt="Remy Sharp"
                    // src={user?.image}
                  >
                    {/* {user?.name?.charAt(0).toUpperCase()} */}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  mt: 1,
                  ml: 2,
                  color: "black",
                  textDecoration: "none",
                  color: theme.palette.primary.main,
                  display: { xs: "none", md: "flex" },
                  fontWeight: "bold",
                  fontSize: "18px",
                }}>
                {/* {user.name} */}
                {/* {user?.name
                  ? user.name.charAt(0).toUpperCase() +
                    user.name.slice(1).toLowerCase()
                  : ""} */}
              </Typography>
              <Menu
                sx={{ mt: "54px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings
                  .filter((s) => s.path !== "/logout")
                  .map((setting, index) => (
                    <MenuItem key={index} onClick={handleCloseUserMenu}>
                      <Link
                        style={{
                          textDecoration: "none",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        to={setting.path}>
                        <Typography textAlign="center">
                          {setting.name}
                        </Typography>
                      </Link>
                    </MenuItem>
                  ))}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            ".MuiDrawer-paper": {
              // width: 300,
              backgroundColor: (theme) => theme.palette.primary.dark,
            },
          }}
          variant="permanent"
          open={open}>
          <DrawerHeader>
            {/* <DrawerHeader sx={{ backgroundColor: theme.palette.secondary.dark }}> */}
            <Box
              width="100%"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleNavigateCoures()}>
              <img src="" alt="Lodding..." height={40} />
            </Box>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon
                  sx={{ color: `${theme.palette.secondary.light}` }}
                />
              ) : (
                <ChevronLeftIcon
                  sx={{ color: `${theme.palette.secondary.light}` }}
                />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ backgroundColor: "#ffffff5c" }} />

          {chapter?.map((chapters, index) => (
            <List key={chapters._id}>
              <ListItemButton
                onClick={() => handleToggleCollapseChapter(index, chapters)}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <SchoolIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ".MuiListItemText-primary": {
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: `${theme.palette.secondary.light}`,
                      // color: "wheat",
                    },
                    paddingLeft: "20px",
                    // paddingRight: "24px",
                  }}
                  primary={chapters.title.substring(0, 15)}
                />
                {openChapters.filter((i) => i === index)[0] === index ||
                chapters.contents.filter(
                  (c) => c._id == selectedContent?._id
                )[0] ? (
                  <ExpandLess
                    style={{
                      marginLeft: "8px",
                      color: `${theme.palette.secondary.light}`,
                    }}
                  />
                ) : (
                  <ExpandMore
                    style={{
                      marginLeft: "8px",
                      color: `${theme.palette.secondary.light}`,
                    }}
                  />
                )}
              </ListItemButton>
              <Collapse
                in={
                  openChapters.filter((i) => i === index)[0] === index ||
                  chapters.contents.filter(
                    (c) => c._id == selectedContent?._id
                  )[0]
                }
                timeout="auto"
                unmountOnExit>
                <List component="div" disablePadding>
                  {chapters.contents.map((content, ind) => (
                    <ListItemButton
                      selected={selectedContent?._id == content._id}
                      onClick={() => handleContentClick(content)}
                      key={ind}
                      // sx={{ pl: 4 }}
                      style={{
                        fontSize: "26px",
                        paddingRight: "10px",
                        backgroundColor:
                          selectedContent?._id === content._id
                            ? "#62627e"
                            : "inherit",
                      }}>
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        {ICONS[content.type]}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          ".MuiListItemText-primary": {
                            display: !open ? "none" : "block",
                            fontWeight: "bold",
                            fontSize: "13px",
                            color: "#fff",

                            paddingLeft: "10px",
                          },
                        }}
                        primary={
                          content.title?.substring(0, 25) || content.type
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
          ))}
        </Drawer>
        {/* download material */}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: 5,
            // backgroundColor: (theme) => theme.palette.grey[300],
          }}>
          <Drawer />
          <Box
            gap={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              // mb: 3,
              width: "-webkit-fill-available",
              position: "fixed",
              zIndex: 100,
              marginRight: 3,
              padding: 2,
              backgroundColor: "white",
            }}>
            {chapter.map(
              (m) =>
                m.material &&
                m.contents.map((con) =>
                  selectedContent && con._id === selectedContent._id ? (
                    // <Tooltip title="Download Chapter" key={con._id}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<DownloadIcon />}
                      onClick={() => handleDownload(m.material)}>
                      Chapter Material
                    </Button>
                  ) : // </Tooltip>
                  null
                )
            )}

            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 16,
                }}>
                {showChapter?.title}
              </Typography>
              <ChevronRightIcon
                sx={{ color: `${theme.palette.secondary.dark}` }}
              />
              <Typography
                variant="h6"
                sx={{
                  // color: `${theme.palette.primary.main}`,
                  // fontWeight: 600,
                  fontSize: 16,
                }}>
                {selectedContent?.title}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}>
              <Button
                variant="contained"
                onClick={handlePrevious}
                size="small"
                sx={{
                  background: "#eeeeee",
                  color: "black",
                  minWidth: "40px",
                  height: "40px",
                  "&:hover": {
                    background: "primary",
                    color: "#fff",
                  },
                }}
                // startIcon={<SkipPreviousIcon />}
              >
                {/* Previous */}
                <SkipPreviousIcon />
              </Button>

              <Button
                variant="contained"
                onClick={handleNext}
                size="small"
                sx={{
                  background: "#eeeeee",
                  color: "black",
                  minWidth: "40px",
                  height: "40px",
                  "&:hover": {
                    background: "primary",
                    color: "#fff",
                  },
                }}
                // endIcon={<SkipNextIcon />}
              >
                <SkipNextIcon />
                {/* Next */}
              </Button>
            </Box>
          </Box>
          {/* <Box
            sx={{
              marginTop: { xs: 0, sm: 0, md: 7, lg: 7 },
              border: "1px solid #eeeeee",
              position: "fixed",
              width: "-webkit-fill-available",
              marginRight: 2,
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          /> */}
          <Box mt={10} />
          <Box>
            {selectedContent?.type === "Video" && (
              <>
                <Box sx={{ height: { xs: "100%", sm: "100%", md: "78vh" } }}>
                  <ReactPlayer
                    url={selectedContent.video}
                    controls={true}
                    height="100%"
                    width="100%"
                    onEnded={() => onVideoEnd()}
                    style={{
                      background: "black",
                      margin: "20px auto",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  />
                </Box>
              </>
            )}

            {selectedContent?.type === "FlashCard" && (
              <>
                <Box
                  style={{
                    padding: "10px",
                    margin: "10px",
                    textAlign: "center",
                  }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      // mt: "20%",
                    }}>
                    <Box sx={styles}>
                      {selectedContent.flashCard.cardType === "Image" ? (
                        <img
                          src={selectedContent?.flashCard.image}
                          alt="Image"
                          style={{ height: "auto", width: "100%" }}
                        />
                      ) : (
                        <Typography sx={{ textAlign: "justify" }}>
                          {selectedContent?.flashCard.text}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {/* quiz completed section */}
            {selectedContent?.type === "Quiz" &&
              (selectedQuizCompleteStatus ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    rowGap: "10px",
                    alignItems: "center",
                    flex: 1,
                    padding: "50px 0",
                  }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    Quiz Completed
                  </Typography>
                  <Stack
                    direction={{ xs: "column", sm: "column", md: "row" }}
                    spacing={3}>
                    <Button
                      size="small"
                      disableRipple
                      disableTouchRipple
                      component="label"
                      variant="outlined"
                      sx={{ cursor: "none" }}>
                      Score : {quizScore}/{selectedContent.quiz.length}
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => setSelectedQuizCompleteStatus(false)}>
                      Re-Take Quiz
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <>
                  <Box>
                    {selectedQuiz.map((q, j) => (
                      <Paper
                        key={j}
                        elevation={0}
                        sx={{
                          padding: "10px",
                          // margin: "5px",

                          backgroundColor: "whitesmoke",
                          my: 1,
                        }}>
                        <Grid item xs={12} md={6}>
                          <Typography fontSize="16px" fontWeight="bold" mb={1}>
                            {j + 1}. {q.question}
                          </Typography>
                        </Grid>

                        <Grid container>
                          {q.options.map((option, k) => (
                            <Grid item xs={6} sm={6} md={6} lg={6} key={k}>
                              <FormControl component="fieldset" mb={1}>
                                <RadioGroup
                                  value={q.givenAnswer || ""}
                                  onChange={(event) =>
                                    handleAnswerChange(event, j, k)
                                  }>
                                  <FormControlLabel
                                    value={option}
                                    control={
                                      <Radio
                                        size="small"
                                        style={{
                                          color: theme.palette.secondary.dark,
                                        }}
                                      />
                                    }
                                    label={option}
                                    style={{ padding: "0", margin: "0" }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          ))}
                        </Grid>
                      </Paper>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px",
                    }}>
                    <LoadingButton
                      variant="contained"
                      onClick={handleQuizSubmit}
                      sx={{
                        background: theme.palette.primary.dark,
                        "&:hover": {
                          background: theme.palette.secondary.dark,
                        },
                      }}
                      loading={quizLoading}>
                      Submit
                    </LoadingButton>
                  </Box>
                </>
              ))}
            {selectedContent?.type === "CodePractice" && (
              <>
                <Box sx={{ mb: 2 }}>
                  {" "}
                  <Typography sx={{ fontSize: "small" }}>
                    {selectedContent?.description}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", height: "80vh", width: "100%" }}>
                  <iframe
                    src="https://codesandbox.io/embed/ryk8vw?view=Editor+%2B+Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: 0,
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                    title="PracticeMakeManPerfect"
                    allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
                    sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
                </Box>
              </>
            )}
            {selectedContent?.type === "Material" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "200px 0",
                  }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    endIcon={<DownloadIcon />}
                    onClick={() =>
                      handleDownload(selectedContent.contentMaterial)
                    }>
                    {selectedContent.contentMaterial.split("/").pop()}
                  </Button>
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              width: "-webkit-fill-available",
              textAlign: "center",
              padding: "10px",
              marginRight: "40px",
            }}>
            {/* <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ width: "100%", ml: "1px" }}> */}
            <BorderLinearProgress
              variant="determinate"
              //   value={
              //     user.courses.filter((c) => c.courseId._id === id)[0]?.completed
              //   }
            />
          </Box>

          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {/* Quiz completed! Your score:{score}/{selectedContent?.quiz?.length} */}
              </Typography>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
}
