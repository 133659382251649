import React, { useState } from "react";

import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  Avatar,
  Modal,
  Typography,
  Tooltip,
  CircularProgress,
  Dialog,
  useMediaQuery,
  Stack,
  TablePagination,
} from "@mui/material";
import { useEffect, useContext } from "react";
import { urls as url } from "../../../services/urlConstant";
import "../../../styles/components/Exam/Exam.scss";
import { get, put } from "../../../services/apis";
import { objHas } from "../../../utils/utils";

import style from "../../../styles/components/Layout/Attendance/AttendanceReport.module.css";
import { DownloadForOffline, Print } from "@mui/icons-material";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import DownloadIcon from "@mui/icons-material/Download";
const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
    marginTop: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    // m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    // marginTop: "10px",
  },
};
const modalStyle = {
  bgcolor: "background.paper",

  p: 4,
  // width: 800,
};

const VcButton = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: 3,
  alignSelf: "center",
  justifyContent: "center",
}));

const ExamResult = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const [studentsExamResult, setStudentsExamResult] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [examResultDetails, setExamResultDetails] = useState({});
  const [academicYear, setAcademicYear] = useState([]);
  const [studentMarksDetailsModal, setStudentMarksDetailsModal] =
    useState(false);
  const [studentExamResult, setStudentExamResult] = useState(undefined);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [sheet, setSheet] = useState(undefined);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [sheetLoader, setSheetLoader] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [viewLoadingStates, setViewLoadingStates] = useState({});
  const [cardLoadingStates, setCardLoadingStates] = useState({});
  const [value, setValue] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [downloadLoaderAll, setDownloadLoaderAll] = useState(false);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [sort, setSort] = useState({
    rollNo: "asc",
    name: "asc",
    totalMaxMarks: "asc",
    totalScoredMarks: "asc",
    percentage: "asc",
    grade: "asc",
  });

  let [loader, setLoader] = useState(false);
  const [bulkLoader, setBulKLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const sortByRollNo = () => {
    console.log("RollNo");
    if (sort.rollNo === "asc") {
      let sortedList = [
        ...studentsExamResult.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const sortByGrade = () => {
    console.log("Grade");
    if (sort.grade === "asc") {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => b.grade - a.grade),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, grade: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => a.grade - b.grade),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, grade: "asc" });
    }
  };

  const sortByTotalMaxMarks = () => {
    console.log("TotalMarks");
    if (sort.totalMaxMarks === "asc") {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => b.totalMaxMarks - a.totalMaxMarks),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, totalMaxMarks: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => a.totalMaxMarks - b.totalMaxMarks),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, totalMaxMarks: "asc" });
    }
  };
  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0].name);
  };
  const sortByTotalScoredMarks = () => {
    console.log("TotalScoredMarks");
    if (sort.totalScoredMarks === "asc") {
      let sortedList = [
        ...studentsExamResult.sort(
          (a, b) => b.totalScoredMarks - a.totalScoredMarks
        ),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, totalScoredMarks: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort(
          (a, b) => a.totalScoredMarks - b.totalScoredMarks
        ),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, totalScoredMarks: "asc" });
    }
  };

  const sortByPercentage = () => {
    console.log("gggg");
    if (sort.percentage === "asc") {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => b.percentage - a.percentage),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, percentage: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => a.percentage - b.percentage),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, percentage: "asc" });
    }
  };

  const sortByName = () => {
    console.log("name");
    if (sort.name === "asc") {
      let sortedList = [
        ...studentsExamResult.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setExamResultDetails({ ...examResultDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "examResult" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setExamResultDetails({ ...examResultDetails, [name]: value });
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setExamResultDetails({ ...examResultDetails, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "examResult" },
        });
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "examResult" },
        });
        setExamterms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, [selectedSetting]);
  useEffect(() => {
    const getAcademicYear = async () => {
      try {
        const res = await get(urls.administrator.academicYearGet, {
          headers: { roleFunction: "examResult" },
        });
        setAcademicYear(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYear();
  }, [selectedSetting]);
  const handleFindStudentResult = async (e) => {
    setLoader(true);

    e.preventDefault();
    try {
      const { examTitle, sectionName, className } = examResultDetails;
      const resultsRes = await get(
        `/${selectedSetting._id}/students-exam-percentage/class/${className}/section/${sectionName}/exam/${examTitle}`,
        {
          headers: { roleFunction: "examResult" },
        }
      );
      if (resultsRes.data.success) {
        setStudentsExamResult(resultsRes.data.data);
      } else {
        console.log(resultsRes);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleModalClose = () => setStudentMarksDetailsModal(false);
  const handleModalOpen = () => setStudentMarksDetailsModal(true);
  const handleStudentMarksDetails = async (stud) => {
    setViewLoadingStates((prevStates) => ({ ...prevStates, [stud._id]: true }));

    try {
      const detailedMarksRes = await get(
        `/${selectedSetting._id}/exam-result-student/student/${stud._id}/exam/${examResultDetails.examTitle}`,
        {
          headers: { roleFunction: "examResult" },
        }
      );

      if (detailedMarksRes.data.success) {
        setStudentExamResult(detailedMarksRes.data.data);
        handleModalOpen();
      } else {
        console.log(detailedMarksRes);
      }
    } catch (error) {
      console.log(error);
    }
    setViewLoadingStates((prevStates) => ({
      ...prevStates,
      [stud._id]: false,
    }));
  };
  const handleGettingMarkCard = async (stud) => {
    setCardLoadingStates((prevStates) => ({ ...prevStates, [stud._id]: true }));
    try {
      const studentMarkCard = await get(
        `/${selectedSetting._id}/exam-result-report/student/${stud._id}/exam/${examResultDetails.examTitle}`,
        {
          headers: { roleFunction: "examResult" },
          responseType: "blob",
        }
      );
      const downloadUri = URL.createObjectURL(studentMarkCard.data);
      window.open(downloadUri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setCardLoadingStates((prevStates) => ({
      ...prevStates,
      [stud._id]: false,
    }));
  };
  const handleGetUploadSheet = async () => {
    setDownloadLoader(true);
    try {
      const { className, sectionName, examTitle } = examResultDetails;
      const getMarksSheet = await get(
        `/${selectedSetting._id}/marks-update-sheet/${className}/${sectionName}/${examTitle}`,
        { headers: { roleFunction: "examResult" } },
        {
          responseType: "blob",
        }
      );
      console.log("class", classes);
      const url = window.URL.createObjectURL(new Blob([getMarksSheet.data]));
      const link = document.createElement("a");
      link.href = url;
      const classename = className;
      const getExcelById = classes.find((excel) => excel._id === classename);
      const sectionsname = sectionName;
      const getExcelsectionById = sections.find(
        (excel) => excel._id === sectionsname
      );
      const excelname = examTitle;
      const getExcelExamById = examTerms.find(
        (excel) => excel._id === excelname
      );

      link.setAttribute(
        "download",
        `${getExcelById.className}Std ,${getExcelsectionById.sectionName},${getExcelExamById.examTitle}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadLoader(false);
    } catch (error) {
      console.log(error);
      setDownloadLoader(false);
    }
  };

  const handleGetAllUploadSheet = async () => {
    setDownloadLoaderAll(true);
    try {
      const { className, examTitle } = examResultDetails;
      const getAllMarksSheet = await get(
        `/${selectedSetting._id}/students-alllist-excel/${className}/${examTitle}`,
        {
          headers: { roleFunction: "examResult" },
        },
        {
          responseType: "blob",
        }
      );
      console.log("class", classes);
      const url = window.URL.createObjectURL(new Blob([getAllMarksSheet.data]));
      const link = document.createElement("a");
      link.href = url;
      const classename = className;
      const getExcelById = classes.find((excel) => excel._id === classename);

      const excelname = examTitle;
      const getExcelExamById = examTerms.find(
        (excel) => excel._id === excelname
      );

      link.setAttribute(
        "download",
        `${getExcelById.className}Std ,${getExcelExamById.examTitle}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadLoaderAll(false);
    } catch (error) {
      console.log(error);
      setDownloadLoaderAll(false);
    }
  };
  const handleSheetUpload = async (e) => {
    // setValue(true);
    e.preventDefault();
    console.log("test ", e.target.files);
    try {
      const formData = new FormData();
      formData.append("sheet", fileChoosen.e.target.files[0]);
      const { className, sectionName, examTitle } = examResultDetails;
      const uploadSheet = await put(
        `/${selectedSetting._id}/marks-update-sheet/${className}/${sectionName}/${examTitle}`,
        "",
        formData,
        { headers: { roleFunction: "examResult" } }
      );
      // setValue(false);
      // const uploadSheet = awai
    } catch (error) {
      console.log(error);
      // setValue(false);
    }
  };
  const handleMarksPdfDownload = async () => {
    try {
      setPdfLoader(true);
      if (
        examResultDetails.className &&
        examResultDetails.sectionName &&
        examResultDetails.examTitle
      ) {
        const getMarksListPdf = await get(
          `/${selectedSetting._id}/students-marks-pdf/${examResultDetails.className}/${examResultDetails.sectionName}/${examResultDetails.examTitle}`,
          {
            headers: { roleFunction: "examResult" },
          },
          {
            responseType: "blob",
          }
        );
        if (getMarksListPdf.status === 200) {
          const uri = URL.createObjectURL(getMarksListPdf.data);
          const link = document.createElement("a");
          link.href = uri;
          const getPDFacademicyearById = academicYear.find(
            (excel) => excel.status === "active"
          );
          const classename = examResultDetails.className;
          const getPDFById = classes.find((excel) => excel._id === classename);
          const sectionsname = examResultDetails.sectionName;
          const getPDFsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const examsTitle = examResultDetails.examTitle;
          const getPDFexamTitleById = examTerms.find(
            (excel) => excel._id === examsTitle
          );
          link.setAttribute(
            "download",
            `${getPDFacademicyearById.academicYearFrom}-${getPDFacademicyearById.academicYearTo} ${getPDFById.className}th std ,${getPDFsectionById.sectionName} ${getPDFexamTitleById.examTitle}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
      setPdfLoader(false);
    } catch (error) {
      toast.error(error.message);
      setPdfLoader(false);
    }
  };
  const handleMarksExcelDownload = async () => {
    try {
      setSheetLoader(true);
      if (
        examResultDetails.className &&
        examResultDetails.sectionName &&
        examResultDetails.examTitle
      ) {
        const getMarksListSheet = await get(
          `/${selectedSetting._id}/students-marks-excel/${examResultDetails.className}/${examResultDetails.sectionName}/${examResultDetails.examTitle}`,
          {
            headers: { roleFunction: "examResult" },
          },
          {
            responseType: "blob",
          }
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const getExcelacademicyearById = academicYear.find(
            (excel) => excel.status === "active"
          );
          console.log("academicYear", academicYear);
          const classename = examResultDetails.className;
          const getExcelById = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = examResultDetails.sectionName;
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const examsTitle = examResultDetails.examTitle;
          const getExcelexamTitleById = examTerms.find(
            (excel) => excel._id === examsTitle
          );

          link.setAttribute(
            "download",
            `${getExcelacademicyearById.academicYearFrom}-${getExcelacademicyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName} ${getExcelexamTitleById.examTitle}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
      setSheetLoader(false);
    } catch (error) {
      toast.error(error.message);
      setSheetLoader(false);
    }
  };

  return (
    <div>
      <div style={{ margin: 15 }}>
        <SectionSubHeader title=" Exam Result " />

        <form onSubmit={handleFindStudentResult}>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="className"
                      value={examResultDetails.className || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Section
                    </InputLabel>
                    <Select
                      label="Section"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="sectionName"
                      value={examResultDetails.sectionName || ""}
                      onChange={(event) => handleSections(event)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Exam
                    </InputLabel>
                    <Select
                      label="Exam"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="examTitle"
                      value={examResultDetails.examTitle || ""}
                      onChange={(event) => handleExamTerms(event)}
                    >
                      {examTerms &&
                        examTerms.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.examTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={3}
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: {
                      xs: "flex-end",
                      sm: "flex-end",
                      md: "space-between",
                      lg: "flex-start",
                    },
                    // justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  {examResultDetails.className &&
                    examResultDetails.sectionName &&
                    examResultDetails.examTitle && (
                      <Button
                        size="small"
                        variant="contained"
                        style={styles.Button}
                        onClick={() => setBulkUploadModal(true)}
                      >
                        Bulk Upload
                      </Button>
                    )}

                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                    size="small"
                    type="submit"
                  >
                    Find
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
      </div>
      <div style={{ margin: "15px" }}>
        {examResultDetails.className &&
          examResultDetails.sectionName &&
          examResultDetails.examTitle && (
            <div className={style.iconContainer}>
              <div>
                {sheetLoader ? (
                  <Button size="small">
                    <CircularProgress size={25} />
                  </Button>
                ) : (
                  <Tooltip title="Download">
                    <Button onClick={handleMarksExcelDownload}>
                      <DownloadForOffline />
                    </Button>
                  </Tooltip>
                )}
              </div>
              <div>
                {pdfLoader ? (
                  <Button size="small">
                    <CircularProgress size={25} />
                  </Button>
                ) : (
                  <Tooltip title="Print">
                    <Button onClick={handleMarksPdfDownload}>
                      <Print />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
          )}
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">Roll No</span>
                  <span
                    onClick={sortByRollNo}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.rollNo === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Name</span>
                  <span
                    onClick={sortByName}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.name === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Photo</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Exam Mark</span>
                  <span
                    onClick={sortByTotalMaxMarks}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.totalMaxMarks === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Obtained Mark</span>
                  <span
                    onClick={sortByTotalScoredMarks}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.totalScoredMarks === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Percentage</span>
                  <span
                    onClick={sortByPercentage}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.percentage === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Grade</span>
                  <span
                    onClick={sortByGrade}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.grade === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">View</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentsExamResult &&
                studentsExamResult
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((result, index) => (
                    <TableRow
                      key={result._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {result.academicInfo.rollNo}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.basicInfo.name}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          textTransform: "capitalize",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src={`${objHas(result, "studentPhoto.link", "")}`}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.totalMaxMarks}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.totalScoredMarks}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.percentage}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.grade}
                      </TableCell>
                      <TableCell align="center">
                        <VcButton>
                          <LoadingButton
                            loading={viewLoadingStates[result._id]}
                            style={
                              {
                                // background: "#1b3779",
                                // ":hover": { background: "#1b3779" },
                                // margin: "0px 2px",
                              }
                            }
                            size="small"
                            variant="contained"
                            onClick={() => handleStudentMarksDetails(result)}
                          >
                            View
                          </LoadingButton>
                          <LoadingButton
                            loading={cardLoadingStates[result._id]}
                            sx={{
                              background: "rgb(27, 55, 121)",
                              ":hover": { background: "rgb(27, 55, 121)" },
                            }}
                            size="small"
                            variant="contained"
                            onClick={() => handleGettingMarkCard(result)}
                            startIcon={<DownloadIcon />}
                          >
                            Card
                          </LoadingButton>
                        </VcButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!studentsExamResult.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={studentsExamResult.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />

        <Dialog
          fullScreen={fullScreen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          open={studentMarksDetailsModal}
          onClose={handleModalClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
              },
            },
          }}
        >
          <Box sx={modalStyle}>
            {studentExamResult && (
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 250 }} aria-label="simple table">
                    <TableHead style={{ background: "rgb(27 55 121)" }}>
                      <TableRow>
                        <TableCell align="center">
                          <span className="class-table-header">SL No</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Subject</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Max Marks</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">
                            Obtained Marks
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Grade</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentExamResult &&
                        studentExamResult.studentMarks.map((mark, index) => (
                          <TableRow
                            key={mark._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {mark.subject.subjectName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {mark.maxMarks}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {mark.writtenMarks}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {mark.grade}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            {studentExamResult && (
              <div>
                <Typography variant="subtitle1">
                  Total Subject: {studentExamResult.studentMarks.length}
                </Typography>
                <Typography variant="subtitle1">
                  Max Marks: {studentExamResult.totalMax}
                </Typography>
                <Typography variant="subtitle1">
                  Obtained Marks: {studentExamResult.totalMarks}
                </Typography>
                <Typography variant="subtitle1">
                  Percentage: {studentExamResult.percentage}
                </Typography>
              </div>
            )}
          </Box>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={bulkUploadModal}
          onClose={() => setBulkUploadModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <form onSubmit={handleSheetUpload}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      variant="h6"
                      component="h2"
                      textAlign="center"
                      fontSize="20px"
                      fontWeight="bold"
                    >
                      Bulk Upload
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <LoadingButton
                        loading={downloadLoader}
                        variant="contained"
                        sx={{
                          background: "#1b3779",
                          ":hover": { background: "#1b3779" },
                        }}
                        onClick={handleGetUploadSheet}
                      >
                        Download
                      </LoadingButton>
                    </div>

                    <div style={{ position: "relative" }}>
                      <Button
                        variant="contained"
                        sx={styles.Button}
                        component="label"
                      >
                        Upload
                        <input
                          onChange={handleFileChange}
                          name="file"
                          hidden
                          accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                          type="file"
                        />
                      </Button>
                      <div
                        style={{
                          position: "fixed",
                          top: 70,
                          right: 10,
                          visibility: fileChoosen ? "visible" : "hidden",
                        }}
                      >
                        {fileChoosen && fileChoosen}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <LoadingButton
                        loading={downloadLoaderAll}
                        variant="contained"
                        sx={{
                          background: "#1b3779",
                          ":hover": { background: "#1b3779" },
                        }}
                        onClick={handleGetAllUploadSheet}
                      >
                        Download All Student
                      </LoadingButton>
                    </div>
                    <LoadingButton
                      loading={value}
                      variant="contained"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                      type="submit"
                    >
                      submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Box>
        </Dialog>
      </div>
    </div>
  );
};

export default ExamResult;
