import * as React from "react";
import { useState, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Checkbox,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  TablePagination,
} from "@mui/material";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EmployeeAttendanceReport from "./EmployeeAttendanceReport";
import { Diversity1Rounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Wrapper from "../../Wrapper";
import SectionSubHeader from "../../SectionSubHeader";
import StickyBar from "../../StickyBar";
import ShowComponent from "../../ShowComponent";

const styles = {
  card: {
    padding: 10,
    margin: "10px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    // m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    // marginTop: "10px",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EmployeeAttendance = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({});
  const [date, setDate] = useState(dayjs(Date.now()));
  // const [studentsAttendence, setStudentsAttendence] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employeeAttendances, setEmployeeAttendances] = useState([]);
  const [attendance, setAttendance] = useState({ attendanceStatus: "present" });
  const [presentCheck, setPresentCheck] = useState(false);
  const [absentCheck, setAbsentCheck] = useState(false);
  const [lateCheck, setLateCheck] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  let [loader, setLoader] = useState(false);
  let [loaderFind, setLoaderFind] = useState(false);

  const [sort, setSort] = useState({
    empName: "asc",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const sortByName = () => {
    console.log("Name");
    if (sort.empName === "asc") {
      let sortedList = [
        ...employeeAttendances.sort((a, b) =>
          b.attendance.employee.basicInfo.empName.localeCompare(
            a.attendance.employee.basicInfo.empName
          )
        ),
      ];

      setEmployeeAttendances(sortedList);
      setSort({ ...sort, empName: "des" });
    } else {
      let sortedList = [
        ...employeeAttendances.sort((a, b) =>
          a.attendance.employee.basicInfo.empName.localeCompare(
            b.attendance.employee.basicInfo.empName
          )
        ),
      ];
      setEmployeeAttendances(sortedList);
      setSort({ ...sort, empName: "asc" });
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole, {
          headers: { roleFunction: "employeeAttendanceTest" },
        });
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, [selectedSetting]);

  const handleSections = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleEmployeeAttendanceFindSubmit = async (e) => {
    e.preventDefault();
    setLoaderFind(true);

    try {
      const data = {
        date: date.format("YYYY-MM-DD"),
        role: formData.role,
      };
      const employeeAttendanceRes = await post(
        `${urls.employee.createIfNotExists}`,
        data,
        { headers: { roleFunction: "employeeAttendanceTest" } }
      );
      if (employeeAttendanceRes.data.success) {
        setEmployeeAttendances(employeeAttendanceRes.data.data);
        console.log(employeeAttendanceRes.data.data, "kkkkkkkkkkkkkkkk");
      }
    } catch (error) {
      console.log(error);
    }
    setLoaderFind(false);
  };
  const handleAttendanceStatusChange = (e, id) => {
    const status = e.target.value;
    const newAttendanceStatus = e.target.value;
    setAttendance({ ...attendance, attendanceStatus: newAttendanceStatus });

    setEmployeeAttendances(
      employeeAttendances.map((empAttend) => {
        if (empAttend._id === id) {
          return {
            ...empAttend,
            attendanceStatus: status,
          };
        } else {
          return empAttend;
        }
      })
    );
  };

  const handleAllPresent = (e) => {
    const isChecked = e.target.checked;
    setPresentCheck(isChecked);
    setAbsentCheck(false);
    setLateCheck(false);

    setEmployeeAttendances(
      employeeAttendances.map((emp) => ({
        ...emp,
        attendanceStatus: isChecked ? "present" : emp.attendanceStatus,
      }))
    );
  };

  const handleAllAbsent = (e) => {
    const isChecked = e.target.checked;
    setAbsentCheck(isChecked);
    setPresentCheck(false);
    setLateCheck(false);

    setEmployeeAttendances(
      employeeAttendances.map((emp) => ({
        ...emp,
        attendanceStatus: isChecked ? "absent" : emp.attendanceStatus,
      }))
    );
  };
  const handleAllLate = (e) => {
    const isChecked = e.target.checked;
    setLateCheck(isChecked);
    setPresentCheck(false);
    setAbsentCheck(false);

    setEmployeeAttendances(
      employeeAttendances.map((emp) => ({
        ...emp,
        attendanceStatus: isChecked ? "late" : emp.attendanceStatus,
      }))
    );
  };
  const handleUpdateEmployeeAttendance = async () => {
    setLoader(true);
    try {
      const updateStudAttendance = await put(
        `${urls.employee.updateEmployeeAttendanceBulk}`,
        "",
        {
          attendances: employeeAttendances,
        },
        { headers: { roleFunction: "employeeAttendanceTest" } }
      );
      console.log(updateStudAttendance);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Employee Attendance" />
      <Box style={{ margin: "10px 0 20px 0" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Attendance" {...a11yProps(0)} />
              <Tab label="Report" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Card style={styles.card}>
              <Box>
                <form onSubmit={handleEmployeeAttendanceFindSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl size="small" sx={styles.textfield} required>
                        <InputLabel
                          id="demo-simple-select-filled-label"
                          sx={{ fontSize: 12 }}
                        >
                          Roles
                        </InputLabel>
                        <Select
                          label="Roles"
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="role"
                          value={formData.role || ""}
                          onChange={(event) => handleSections(event)}
                        >
                          {roles.length > 0 &&
                            roles.map((row, index) => (
                              <MenuItem
                                key={row._id}
                                value={row._id}
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {row.roleName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      sx={{ alignSelf: "center" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date"
                          value={date}
                          inputFormat="DD-MM-YYYY"
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              style={styles.textfield}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      sx={{
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: {
                          xs: "flex-end",
                          sm: "flex-end",
                          md: "flex-start",
                          lg: "flex-start",
                        },
                      }}
                    >
                      <LoadingButton
                        loading={loaderFind}
                        variant="contained"
                        sx={{
                          color: "#fff",
                          background: "#1b3779",
                          "&:hover": {
                            background: "#1b3779",
                          },
                        }}
                        size="small"
                        type="submit"
                      >
                        Find
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Card>

            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Employee Name</span>
                      <span
                        onClick={sortByName}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.empName === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div align="center">
                          <span className="class-table-header">
                            Present All
                            <Checkbox
                              onChange={handleAllPresent}
                              checked={presentCheck}
                              sx={{
                                fontSize: "small",
                                padding: 0,
                                color: "#fff",
                                "&.Mui-checked": {
                                  color: "#fff",
                                },
                              }}
                            />
                          </span>
                        </div>
                        <div align="center">
                          <span className="class-table-header">
                            Absent All
                            <Checkbox
                              onChange={handleAllAbsent}
                              checked={absentCheck}
                              sx={{
                                padding: 0,
                                color: "#fff",
                                "&.Mui-checked": {
                                  color: "#fff",
                                },
                              }}
                            />
                          </span>
                        </div>
                        <div align="center">
                          <span className="class-table-header">Late</span>
                          <Checkbox
                            onChange={handleAllLate}
                            checked={lateCheck}
                            sx={{
                              padding: 0,
                              color: "#fff",
                              "&.Mui-checked": {
                                color: "#fff",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeAttendances.length > 0 &&
                    employeeAttendances
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((attendance, index) => (
                        <TableRow
                          key={attendance._id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {attendance.employee.basicInfo.empName}
                          </TableCell>
                          <TableCell>
                            <RadioGroup
                              style={{ justifyContent: "space-around" }}
                              name="controlled-radio-buttons-group"
                              defaultValue={
                                attendance.attendanceStatus
                                  ? attendance.attendanceStatus
                                  : "absent"
                              }
                              value={attendance.attendanceStatus}
                              onChange={(e) =>
                                handleAttendanceStatusChange(e, attendance._id)
                              }
                              row
                            >
                              <div>
                                <div
                                  align="center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <FormControlLabel
                                    style={{
                                      position: "relative",
                                      left: "22px",
                                    }}
                                    value="present"
                                    control={<Radio />}
                                  />
                                </div>
                              </div>
                              <div>
                                <div
                                  align="center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <FormControlLabel
                                    style={{
                                      position: "relative",
                                      left: "34px",
                                    }}
                                    value="absent"
                                    control={<Radio />}
                                  />
                                </div>
                              </div>
                              <div>
                                <div
                                  align="center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <FormControlLabel
                                    style={{
                                      position: "relative",
                                      left: "30px",
                                    }}
                                    value="late"
                                    control={<Radio />}
                                  />
                                </div>
                              </div>
                            </RadioGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
              {!employeeAttendances.length && (
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    margin: "5px",
                    padding: "5px",
                  }}
                >
                  No data found
                </Typography>
              )}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={employeeAttendances.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                my: 1,
              }}
            />

            <ShowComponent roleFunction="employeeAttendanceTest" action="add">
              {employeeAttendances.length > 0 && (
                <StickyBar
                  content={
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <LoadingButton
                        loading={loader}
                        sx={{
                          color: "#fff",
                          background: "#1b3779",
                          "&:hover": {
                            background: "#1b3779",
                          },
                        }}
                        size="small"
                        variant="contained"
                        onClick={handleUpdateEmployeeAttendance}
                      >
                        Submit
                      </LoadingButton>
                    </div>
                  }
                />
              )}
            </ShowComponent>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EmployeeAttendanceReport />
          </TabPanel>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default EmployeeAttendance;
