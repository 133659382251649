import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Tabs,
  Tab,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  TablePagination,
  InputAdornment,
  TableBody,
  ButtonGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Dialog,
} from "@mui/material";
import React from "react";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { get, put, del } from "../../services/apis";
import { Close, Delete } from "@mui/icons-material";
import dayjs from "dayjs";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import EditIcon from "@mui/icons-material/Edit";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { TableContainer } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Search } from "@material-ui/icons";
import { theme } from "../../theme";
import { useNavigate } from "react-router-dom";
const styles = {
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const MuiDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const MuiTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const Result = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [loader, setLoader] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [activeButton, setActiveButton] = useState("Approved");
  const [updatingEnquiryStatus, setUpdatingEnquiryStatus] = useState("");

  const [modalData, setModalData] = useState({
    open: false,
    results: "",
    studentDetails: "",
    studentName: "",
  });
  const [classes, setClasses] = useState([]);
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showBackArrow, setShowBackArrow] = useState(true);
  const navigate = useNavigate();

  // =============================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  // ==============================

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleButtonClick = (status) => {
    setActiveButton(status);
  };

  useEffect(() => {
    if (!activeButton) {
      return setFilteredList(list);
    }
    setFilteredList(list.filter((e) => e.status === activeButton));
  }, [activeButton]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "result" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "result" },
        });

        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await get(`${urls.preAdmission.getList}`, {
        headers: { roleFunction: "result" },
        params: {
          search: {
            "studentDetails.academicDetails.academicYear":
              formData.academicYear,
            "studentDetails.academicDetails.class": formData.schoolClass,
            examConducted: true,
            $or: [
              {
                status: "Approved",
              },
              {
                status: "Shortlisted",
              },
              {
                status: "Admitted",
              },
            ],
          },
        },
      });

      let data = res.data.data;

      setList(data);
      setFilteredList(data.filter((e) => e.status === activeButton));
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleupdate = async (enquiryId, status) => {
    setUpdatingEnquiryStatus(status);
    const res = await put(
      `${urls.preAdmission.approveddata}${enquiryId}`,
      "",
      { status },
      { headers: { roleFunction: "preaddmissionEnquiry" } }
    );

    setList((prevList) =>
      prevList.map((item) => (item._id === enquiryId ? res?.data?.data : item))
    );
    setFilteredList((prevList) =>
      prevList
        .map((item) => (item._id === enquiryId ? res?.data?.data : item))
        .filter((e) => e?.status === activeButton)
    );
    setUpdatingEnquiryStatus("");
  };

  const handleClickViewResult = (id, results) => {
    console.log(results, "===results");
    setModalData({
      open: true,
      results: results.examTakenDetails,
      studentDetails: results.studentDetails.academicDetails,
      studentName: results.studentName,
    });
  };

  const handleClose = () => {
    setModalData({
      open: false,
      results: "",
      studentDetails: "",
      studentName: "",
    });
  };

  const handleNavigateStudent = (id) => {
    const getStudentById = filteredList.find((item) => item._id === id);
    navigate(`/edit-student/${getStudentById.admittedStudentId}`, {
      state: filteredList,
    });
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Result" />

      <Card style={{ marginBottom: "15px", padding: 10 }}>
        <Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    variant="outlined"
                    label="academic year"
                    size="small"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Class</InputLabel>
                  <Select
                    size="small"
                    variant="outlined"
                    label="class"
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="schoolClass"
                    value={formData.schoolClass || ""}
                    onChange={handleOnChange}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={2} sx={{ mt: 0.5 }}>
                <LoadingButton
                  loading={loader}
                  sx={{
                    color: "#fff",
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  type="submit"
                  size="small"
                  variant="contained"
                >
                  Find
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>

      {/* <Card style={{ margin: "20px 0", padding: 10 }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example">
            <Tab label="Result" {...a11yProps(0)} />
            <Tab label="Shortlisted" {...a11yProps(1)} />
            <Tab label="Admitted" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: 1,
        }}
      >
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          {["Approved", "Shortlisted", "Admitted"].map((v, i) => (
            <Button
              key={i}
              variant="outlined"
              onClick={() => {
                handleButtonClick(v);
              }}
              style={{
                backgroundColor: activeButton === v ? "#1b3779" : "",
                color: activeButton === v ? "white" : "black",
                textTransform: "capitalize",
              }}
            >
              {v}:{list.filter((item) => item?.status === v).length}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Class
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Max marks
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Passing marks
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Obtained marks
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, i) => {
                console.log(data, "===data");
                return (
                  <TableRow>
                    <TableCell align="center">
                      {page * rowsPerPage + 1}
                    </TableCell>
                    <TableCell align="center">{data.studentName}</TableCell>
                    <TableCell align="center">
                      {data.studentDetails?.academicDetails?.class?.className}
                    </TableCell>
                    <TableCell align="center">
                      {data.examTakenDetails?.totalMarks}
                    </TableCell>
                    <TableCell align="center">
                      {data.examTakenDetails?.passingMarks}
                    </TableCell>
                    <TableCell align="center">
                      {data.examTakenDetails?.obtainedMarks}
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 2,
                        }}
                      >
                        <Tooltip title="View">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleClickViewResult(data._id, data)
                            }
                          >
                            <VisibilityIcon fontSize="small" color="primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton
                            size="small"
                            sx={{ color: "#1b3779" }}
                            onClick={() => handleNavigateStudent(data._id)}
                          >
                            {" "}
                            <EditIcon
                              fontSize="small"
                              sx={{ color: "#1b3779" }}
                            />
                          </IconButton>
                        </Tooltip>
                        {data.status === "Approved" ? (
                          <LoadingButton
                            loading={
                              updatingEnquiryStatus === "Shortlisted"
                                ? true
                                : false
                            }
                            onClick={() =>
                              handleupdate(data._id, "Shortlisted")
                            }
                            variant="contained"
                            size="small"
                          >
                            Shortlist
                          </LoadingButton>
                        ) : null}
                        {data.status === "Shortlisted" ? (
                          <LoadingButton
                            variant="contained"
                            loading={
                              updatingEnquiryStatus === "Shortlisted"
                                ? true
                                : false
                            }
                            onClick={() => handleupdate(data._id, "Admitted")}
                            size="small"
                            sx={{
                              backgroundColor: "#1B3779",
                              "&:hover": { backgroundColor: "#1B3779" },
                            }}
                          >
                            Admit
                          </LoadingButton>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {filteredList.length === 0 && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* =============view offer-letter========== */}
      <MuiDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalData.open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            borderBottom: 1,
            borderColor: "divider",
          }}
          id="customized-dialog-title"
        >
          {modalData?.results?.examName} Exam{" "}
          <b>
            ({modalData?.studentDetails?.academicYear?.academicYearFrom}-
            {modalData?.studentDetails?.academicYear?.academicYearTo})
          </b>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Box sx={{ px: 1 }}>
            <Typography>
              <b>Name:</b> {modalData?.studentName}
            </Typography>
            <Typography>
              <b>Class:</b> {modalData?.studentDetails?.class?.className}
            </Typography>
            <Grid conatiner spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                {modalData?.results?.quiz?.map(
                  (quizItem, i) => (
                    console.log(quizItem, "quizItem"),
                    (
                      <Box mt={2}>
                        <Typography sx={{ fontWeight: 550 }}>
                          {` ${i + 1}. ${quizItem.question}`}
                        </Typography>
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            marginBottom: "10px",
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: "5px",
                            padding: "0 15px 20px 15px",
                            border: "1px solid lightgrey",
                            marginTop: "10px",
                          }}
                        >
                          {quizItem?.options?.map((item, j) => (
                            <Grid item xs={12} md={6} key={j}>
                              <Typography
                                sx={{
                                  color:
                                    quizItem.correctOption === item.value
                                      ? "green"
                                      : "",
                                }}
                              >
                                {`${String.fromCharCode(65 + j)}. 
                              ${item.value}`}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid container gap={1} sx={{ display: "flex" }}>
                          <MuiTypography>
                            Selected Option:{" "}
                            <b>
                              {
                                quizItem?.options?.filter(
                                  (c) => c._id === quizItem?.selectedOption
                                )[0]?.value
                              }
                            </b>
                            ,
                          </MuiTypography>
                          <MuiTypography>
                            Marks:{" "}
                            <b style={{ color: "#42A5F5" }}>
                              {modalData?.results?.marksPerQuestion}
                            </b>
                            ,
                          </MuiTypography>
                          <MuiTypography>
                            Attempted:{" "}
                            <b>{quizItem?.attempted ? "yes" : "No"}</b>
                          </MuiTypography>
                        </Grid>
                      </Box>
                    )
                  )
                )}
              </Grid>
            </Grid>
          </Box>
          <Typography sx={{ fontWeight: 600, mt: 1, color: "#42A5F5" }}>
            Total Mark: {modalData?.results?.obtainedMarks}/
            {modalData?.results?.totalMarks}
          </Typography>
        </DialogContent>
      </MuiDialog>
    </Wrapper>
  );
};

export default Result;
