import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
  MenuItem,
  Tooltip,
  Avatar,
  InputAdornment,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { objHas } from "../../utils/utils";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import DeleteModal from "../Layout/Academic/DeleteModal";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete, Remove, Search } from "@mui/icons-material";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import AddModel from "../Layout/Academic/AddModel";
import ShowComponent from "../ShowComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const StudentMember = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [students, setStudents] = useState([]);
  const [nonStudents, setNonStudents] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [nonSearchFilter, setNonSearchFilter] = useState([]);
  const [nonSearch, setNonSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [addId, setAddId] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [modalData, setModalData] = useState({
    open: false,
    title: "",
    description: "",
    action: () => {},
  });

  const [sort, setSort] = useState({
    name: "asc",
    class: "asc",
    section: "asc",
    rollNo: "asc",
  });

  const sortByName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...students.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...students.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByClass = () => {
    if (sort.class == "asc") {
      let sortedList = [
        ...students.sort((a, b) =>
          b.academicInfo.class.className.localeCompare(
            a.academicInfo.class.className
          )
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, class: "des" });
    } else {
      let sortedList = [
        ...students.sort((a, b) =>
          a.academicInfo.class.className.localeCompare(
            b.academicInfo.class.className
          )
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, class: "asc" });
    }
  };

  const sortBySection = () => {
    if (sort.section == "asc") {
      let sortedList = [
        ...students.sort((a, b) =>
          b.academicInfo.section.sectionName.localeCompare(
            a.academicInfo.section.sectionName
          )
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, section: "des" });
    } else {
      let sortedList = [
        ...students.sort((a, b) =>
          a.academicInfo.section.sectionName.localeCompare(
            b.academicInfo.section.sectionName
          )
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, section: "asc" });
    }
  };

  const sortByRoll = () => {
    console.log(sort, "klklkl");
    if (sort.rollNo == "asc") {
      let sortedList = [
        ...students.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...students.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const sortByNonName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...nonStudents.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...nonStudents.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByNonClass = () => {
    if (sort.class == "asc") {
      let sortedList = [
        ...nonStudents.sort((a, b) =>
          b.academicInfo.class.className.localeCompare(
            a.academicInfo.class.className
          )
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, class: "des" });
    } else {
      let sortedList = [
        ...nonStudents.sort((a, b) =>
          a.academicInfo.class.className.localeCompare(
            b.academicInfo.class.className
          )
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, class: "asc" });
    }
  };

  const sortByNonSection = () => {
    if (sort.section == "asc") {
      let sortedList = [
        ...nonStudents.sort((a, b) =>
          b.academicInfo.section.sectionName.localeCompare(
            a.academicInfo.section.sectionName
          )
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, section: "des" });
    } else {
      let sortedList = [
        ...nonStudents.sort((a, b) =>
          a.academicInfo.section.sectionName.localeCompare(
            b.academicInfo.section.sectionName
          )
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, section: "asc" });
    }
  };
  const sortByNonRoll = () => {
    console.log(sort, "klklkl");
    if (sort.rollNo == "asc") {
      let sortedList = [
        ...nonStudents.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...nonStudents.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    getNonLibraryStudent();
    getLibraryStudent();
  }, [selectedSetting]);
  const getNonLibraryStudent = async () => {
    try {
      const searchStudents = await get(urls.students.getAllStudent, {
        headers: { roleFunction: "studentLibrary" },
        params: {
          limit: rowsPerPage,
          page: page === 0 ? 1 : page + 1,
        },
      });

      const filteredStudents = searchStudents.data.data.filter(
        (student) => student?.otherInfo?.libraryMember === "no"
      );
      console.log(filteredStudents, "111111");
      setNonStudents(filteredStudents);
      if (searchStudents.data.count > rowsPerPage) {
        setShowPagination(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getLibraryStudent = async () => {
    try {
      const searchStudents = await get(urls.students.getAllStudent, {
        headers: { roleFunction: "studentLibrary" },
        params: {
          limit: rowsPerPage,
          page: page === 0 ? 1 : page + 1,
        },
      });

      const filteredStudents = searchStudents.data.data.filter(
        (student) => student?.otherInfo?.libraryMember === "yes"
      );
      console.log(filteredStudents, "666666");
      setStudents(filteredStudents);
      if (searchStudents.data.count > rowsPerPage) {
        setShowPagination(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      students.length > 0 &&
        setSearchFilter(
          students.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleNonSearch = (e) => {
    const { value } = e.target;
    setNonSearch(value.trim());
    if (value.trim() !== "") {
      nonStudents.length > 0 &&
        setNonSearchFilter(
          nonStudents.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setNonSearchFilter([]);
    }
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
    setDeleteId("");
  };
  const handleAddClose = () => {
    setAddOpen(false);
  };
  const handleAddOpen = (id) => {
    setModalData({
      open: true,
      title: "Add Member",
      description: "Do you want to add this student from library member?",
      action: () => handleAddSubmit(id),
    });
  };

  const handleDeleteOpen = (id) => {
    setDeleteId(id);
    setModalData({
      open: true,
      title: "Remove Member",
      description: "Do you want to remove this student from library member?",
      action: () => handleDeleteMember(id),
    });
  };

  const handleAddSubmit = async (id) => {
    setLoader(true);
    try {
      const { status } = await put(
        `${urls.libraryStudentMember.editMember}/`,
        id,
        {},
        {
          headers: { roleFunction: "studentLibrary" },
        }
      );
      handleClose();
      getNonLibraryStudent();
      getLibraryStudent();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleDeleteMember = async (id) => {
    setLoader(true);
    try {
      const { status } = await put(
        `${urls.libraryStudentMember.delMember}/`,
        id,
        {},
        {
          headers: { roleFunction: "studentLibrary" },
        }
      );
      handleClose();
      getLibraryStudent();
      getNonLibraryStudent();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Student Library Member" />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Student member list" {...a11yProps(0)} />
          <Tab label="Student non member list" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search student"
              placeholder="Type student name..."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Photo
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByClass} className="class-table-header">
                    Class
                    <span style={{ cursor: "pointer" }}>
                      {sort.class === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortBySection} className="class-table-header">
                    Section
                    <span style={{ cursor: "pointer" }}>
                      {sort.section === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByRoll} className="class-table-header">
                    Roll No
                    <span style={{ cursor: "pointer" }}>
                      {sort.rollNo === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library Id
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "-webkit-center",
                          }}
                        >
                          <Avatar src={objHas(data, "studentPhoto.link", "")} />
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {data.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        {data.academicInfo.class
                          ? data.academicInfo.class.className
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.academicInfo.section
                          ? data.academicInfo.section.sectionName
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {data.academicInfo.rollNo}
                      </TableCell>
                      <TableCell align="center">
                        {data.academicInfo.admissionNo}
                      </TableCell>
                      <TableCell align="center">
                        <ShowComponent
                          roleFunction="studentLibrary"
                          action="update"
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={(e) => handleDeleteOpen(data._id)}
                            startIcon={<Remove />}
                          >
                            Member
                          </Button>
                        </ShowComponent>
                      </TableCell>
                    </TableRow>
                  ))
                : students.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "-webkit-center",
                          }}
                        >
                          <Avatar src={objHas(data, "studentPhoto.link", "")} />
                        </Box>
                      </TableCell>

                      <TableCell align="center">
                        {data.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        {data.academicInfo.class
                          ? data.academicInfo.class.className
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.academicInfo.section
                          ? data.academicInfo.section.sectionName
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {data.academicInfo.rollNo}
                      </TableCell>
                      <TableCell align="center">
                        {data.academicInfo.admissionNo}
                      </TableCell>
                      <TableCell align="center">
                        <ShowComponent
                          roleFunction="studentLibrary"
                          action="update"
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={(e) => handleDeleteOpen(data._id)}
                            startIcon={<Remove />}
                          >
                            Member
                          </Button>
                        </ShowComponent>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!students.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : students.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <TextField
              size="small"
              value={nonSearch}
              onChange={handleNonSearch}
              label="Search student"
              variant="outlined"
              placeholder="Type student name..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Photo
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByNonName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByNonClass} className="class-table-header">
                    Class
                    <span style={{ cursor: "pointer" }}>
                      {sort.class === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonSection}
                    className="class-table-header"
                  >
                    Section
                    <span style={{ cursor: "pointer" }}>
                      {sort.section === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByNonRoll} className="class-table-header">
                    Roll No
                    <span style={{ cursor: "pointer" }}>
                      {sort.rollNo === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library Id
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonSearch
                ? nonSearchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <Avatar
                              src={objHas(data, "studentPhoto.link", "")}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {data.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.academicInfo.class
                            ? data.academicInfo.class.className
                            : "NA"}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.academicInfo.section
                            ? data.academicInfo.section.sectionName
                            : "NA"}
                        </TableCell>
                        <TableCell align="center">
                          {data.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.academicInfo.admissionNo}
                        </TableCell>
                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="studentLibrary"
                            action="update"
                          >
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => handleAddOpen(data._id)}
                              startIcon={<AddIcon />}
                            >
                              Member
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))
                : nonStudents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <Avatar
                              src={objHas(data, "studentPhoto.link", "")}
                            />
                          </Box>{" "}
                        </TableCell>
                        <TableCell align="center">
                          {data.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.academicInfo.class
                            ? data.academicInfo.class.className
                            : "NA"}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.academicInfo.section
                            ? data.academicInfo.section.sectionName
                            : "NA"}
                        </TableCell>
                        <TableCell align="center">
                          {data.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.academicInfo.admissionNo}
                        </TableCell>
                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="studentLibrary"
                            action="update"
                          >
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => handleAddOpen(data._id)}
                              startIcon={<AddIcon />}
                            >
                              Member
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!nonStudents.length && !nonSearch.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {nonSearch.trim() && !nonSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? nonSearchFilter.length : nonStudents.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>

      {/* ================ Add/Remove Student model =========== */}
      <AddModel
        open={modalData.open}
        handleClose={handleClose}
        handleSubmitModal={modalData.action}
        actionTitle={modalData.title}
        descriptions={modalData.description}
        loading={loader}
      />
    </Wrapper>
  );
};

export default StudentMember;
