import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  TextField,
  Tooltip,
  Modal,
  Dialog,
  useMediaQuery,
  Typography,
  TablePagination,
} from "@mui/material";

import { useEffect, useContext } from "react";
import { urls as url } from "../../../services/urlConstant";
import "../../../styles/components/Exam/Exam.scss";
import { get, post, put } from "../../../services/apis";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import { useTheme } from "@mui/material/styles";
import StickyBar from "../../StickyBar";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
    marginTop: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    // mt: 2,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};
const modalStyle = {
  bgcolor: "background.paper",
  height: "auto",
  p: 2,
};

const ManageMark = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);

  const [studentsManageMarks, setStudentsManageMarks] = useState(undefined);
  const [grades, setGrades] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [manageDetails, setManageDetails] = useState({});
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  let [loader, setLoader] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(null);
  const [sheet, setSheet] = useState(undefined);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [downloadLoaderAll, setDownloadLoaderAll] = useState(false);
  const [reshuffleLoader, setReshuffleLoader] = useState(false);
  const [sheetLoader, setSheetLoader] = useState(false);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setManageDetails({ ...manageDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "studentMark" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      const response = await get(urls.schoolSubject.getAllSubject, {
        headers: { roleFunction: "studentMark" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSubjects(response.data.data);
    } catch (error) {}
  };

  const handleSections = (e) => {
    const { name, value } = e.target;
    setManageDetails({ ...manageDetails, [name]: value });
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setManageDetails({ ...manageDetails, [name]: value });
  };

  const handleSubjects = (e) => {
    const { name, value } = e.target;
    setManageDetails({ ...manageDetails, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "studentMark" },
        });
        setClasses(res.data.data);
      } catch (error) {}
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "studentMark" },
        });
        setExamterms(res.data.data);
      } catch (error) {}
    };
    getExamTerms();
  }, [selectedSetting]);

  const handleFindStudentsMarks = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await get(
        `${selectedSetting._id}/studentmark/exam-term/${manageDetails.examTitle}/class/${manageDetails.className}/subject/${manageDetails.subjectName}/section/${manageDetails.sectionName}`,
        { headers: { roleFunction: "studentMark" } }
      );
      const resGrade = await get(`${selectedSetting._id}/examgrade`, {
        headers: { roleFunction: "studentMark" },
      });
      if (res.data.success && resGrade.data.success) {
        setStudentsManageMarks(res.data.data);
        setGrades(resGrade.data.data);
      } else {
      }
    } catch (error) {}
    setLoader(false);
  };

  const handleMarksSubmit = async () => {
    setReshuffleLoader(true);
    try {
      const res = await put(
        `${selectedSetting._id}/student-mark/multiple`,
        "",
        {
          marks: studentsManageMarks,
        },
        { headers: { roleFunction: "studentMark" } }
      );
      if (res.data.success) {
        setReshuffleLoader(false);
      }
    } catch (error) {
      setReshuffleLoader(false);
    }
  };

  const handleMarkChange = async (e, id) => {
    setStudentsManageMarks(
      studentsManageMarks.map((mark) => {
        if (mark._id == id) {
          return {
            ...mark,
            writtenMarks: e.target.value,
          };
        } else {
          return mark;
        }
      })
    );
  };

  const handleGradeChange = async (e, id) => {
    setStudentsManageMarks(
      studentsManageMarks.map((mark) => {
        if (mark._id == id) {
          return {
            ...mark,
            grade: e.target.value,
          };
        } else {
          return mark;
        }
      })
    );
  };

  const handleGetUploadSheet = async () => {
    setDownloadLoader(true);
    try {
      const { className, sectionName, examTitle, subjectName } = manageDetails;
      const getMarksSheet = await get(
        `${selectedSetting._id}/subjectmarks-update-sheet/${className}/${sectionName}/${examTitle}/${subjectName}`,
        { hearder: { roleFunction: "studentMark" } },
        {
          responseType: "blob",
        }
      );
      console.log("class", classes);
      const url = window.URL.createObjectURL(new Blob([getMarksSheet.data]));
      const link = document.createElement("a");
      link.href = url;
      const classename = className;
      const getExcelById = classes.find((excel) => excel._id === classename);
      const sectionsname = sectionName;
      const getExcelsectionById = sections.find(
        (excel) => excel._id === sectionsname
      );
      const excelname = examTitle;
      const getExcelExamById = examTerms.find(
        (excel) => excel._id === excelname
      );

      link.setAttribute(
        "download",
        `${getExcelById.className}Std ,${getExcelsectionById.sectionName},${getExcelExamById.examTitle}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
      setDownloadLoader(false);
    }
    setDownloadLoader(false);
  };

  const handleGetAllUploadSheet = async () => {
    setDownloadLoaderAll(true);
    try {
      const { className, examTitle, sectionName } = manageDetails;
      const getAllMarksSheet = await get(
        `${selectedSetting._id}/allsubjectmarks-update-sheet/${className}/${sectionName}/${examTitle}`,
        { hearder: { roleFunction: "studentMark" } },
        {
          responseType: "blob",
        }
      );
      console.log("class", classes);
      const url = window.URL.createObjectURL(new Blob([getAllMarksSheet.data]));
      const link = document.createElement("a");
      link.href = url;
      const classename = className;
      const getExcelById = classes.find((excel) => excel._id === classename);

      const excelname = examTitle;
      const getExcelExamById = examTerms.find(
        (excel) => excel._id === excelname
      );

      link.setAttribute(
        "download",
        `${getExcelById.className}Std ,${getExcelExamById.examTitle}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadLoaderAll(false);
    } catch (error) {
      console.log(error);
      setDownloadLoaderAll(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileChoosen(file);
    } else {
      setFileChoosen(null);
    }
  };

  const handleSheetUpload = async (e) => {
    setSheetLoader(true);
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);

      const { className, sectionName, examTitle, subjectName } = manageDetails;
      const response = await post(
        `${selectedSetting._id}/subjectmarks-update-sheet/${className}/${sectionName}/${examTitle}/${subjectName}`,
        formData,
        { hearder: { roleFunction: "studentMark" } }
      );

      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
    } catch (error) {
      console.error(error);
      setSheetLoader(false);
    }
    setSheetLoader(false);
  };

  return (
    <>
      <div className="examfilter-searchbar">
        <SectionSubHeader title=" Manage Mark " />
        <form onSubmit={handleFindStudentsMarks}>
          <Card style={styles.card}>
            {/* <Box sx={{ flexGrow: 1 }}> */}
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    label="Class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="className"
                    value={manageDetails.className}
                    onChange={(event) => handleClasses(event)}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Section
                  </InputLabel>
                  <Select
                    label="Section"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="sectionName"
                    value={manageDetails.sectionName}
                    onChange={(event) => handleSections(event)}
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Exam
                  </InputLabel>
                  <Select
                    label="Exam"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="examTitle"
                    value={manageDetails.examTitle}
                    onChange={(event) => handleExamTerms(event)}
                  >
                    {examTerms &&
                      examTerms.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.examTitle}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    label="Subject"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="subjectName"
                    value={manageDetails.subjectName}
                    onChange={(event) => handleSubjects(event)}
                  >
                    {subjects &&
                      subjects.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.subjectName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  alignSelf: "center",
                  display: "flex",

                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                {manageDetails.className &&
                  manageDetails.sectionName &&
                  manageDetails.examTitle &&
                  manageDetails.subjectName && (
                    <Button
                      size="small"
                      variant="contained"
                      style={styles.Button}
                      onClick={() => setBulkUploadModal(true)}
                    >
                      Bulk Upload
                    </Button>
                  )}

                <LoadingButton
                  loading={loader}
                  variant="contained"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  size="small"
                  type="submit"
                >
                  Find
                </LoadingButton>
              </Grid>

              {/* <Grid
                  item
                  xs={12}
                  md={6}
                  lg={2}
                  sx={{
                    alignSelf: "center",
                  }}
                >
                  {manageDetails.className &&
                    manageDetails.sectionName &&
                    manageDetails.examTitle &&
                    manageDetails.subjectName && (
                      <Button
                        size="small"
                        variant="contained"
                        style={styles.Button}
                        onClick={() => setBulkUploadModal(true)}
                      >
                        Bulk Upload
                      </Button>
                    )}
                </Grid> */}
            </Grid>
            {/* </Box> */}
          </Card>
        </form>
      </div>
      {!studentsManageMarks ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ height: 241 }} src="./record.png" alt="notfound" />
        </Box>
      ) : (
        <div style={{ margin: "15px" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Roll No</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Written</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Grade</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Comment</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentsManageMarks.length > 0 &&
                  studentsManageMarks
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((studentMark, index) => (
                      <TableRow
                        key={studentMark._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {studentMark.student.academicInfo.rollNo}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {studentMark.student.basicInfo.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <TextField
                            onChange={(e) =>
                              handleMarkChange(e, studentMark._id)
                            }
                            size="small"
                            id="outlined-basic"
                            label="Student Mark"
                            // variant="standard"
                            value={studentMark.writtenMarks}
                            defaultValue={studentMark.writtenMarks}
                            type="number"
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <FormControl
                            size="small"
                            sx={{ m: 1, minWidth: "90px" }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Grade
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // variant="standard"
                              value={studentMark.grade}
                              label="Grade"
                              onChange={(e) =>
                                handleGradeChange(e, studentMark._id)
                              }
                              defaultValue={studentMark.grade}
                            >
                              {grades.map((grade) => (
                                <MenuItem value={grade._id}>
                                  {grade.examGrade}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Comment"
                            // variant="standard"
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {!studentsManageMarks.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={studentsManageMarks.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              my: 1,
              mb: "50px",
            }}
          />

          {studentsManageMarks.length > 0 && (
            <StickyBar
              content={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <LoadingButton
                    loading={reshuffleLoader}
                    size="small"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                    onClick={handleMarksSubmit}
                    variant="contained"
                  >
                    Submit
                  </LoadingButton>
                </div>
              }
            />
          )}
        </div>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={bulkUploadModal}
        onClose={() => setBulkUploadModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { xs: "100%", sm: 350, md: 350, lg: 350 },
            },
          },
        }}
      >
        {/* <Box sx={modalStyle}>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Bulk Upload
            </span>
          </div>
          <form onSubmit={handleSheetUpload}>
            <div>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <div>
                    <Button
                      variant="contained"
                      style={styles.Button}
                      onClick={handleGetUploadSheet}>
                      Download
                    </Button>
                  </div>

                  <div style={{ position: "relative" }}>
                    <Button
                      variant="contained"
                      sx={styles.Button}
                      component="label">
                      Upload
                      <input
                        onChange={handleFileChange}
                        name="file"
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                    <div
                      style={{
                        position: "fixed",
                        top: 70,
                        right: 10,
                        visibility: fileChoosen ? "visible" : "hidden",
                      }}>
                      {fileChoosen && fileChoosen.name}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <div>
                    <Button
                      variant="contained"
                      style={styles.Button}
                      onClick={handleGetAllUploadSheet}>
                      Download All Student
                    </Button>
                  </div>
                  <Button variant="contained" sx={styles.Button} type="submit">
                    submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Box> */}

        <Box sx={modalStyle}>
          <form onSubmit={handleSheetUpload}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Bulk Upload
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                  loading={downloadLoader}
                  variant="contained"
                  sx={{
                    width: "100%",
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                    color: "#fff",
                  }}
                  endIcon={<DownloadIcon />}
                  onClick={handleGetUploadSheet}
                >
                  Download
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                  loading={downloadLoaderAll}
                  variant="contained"
                  style={{
                    width: "100%",
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  endIcon={<DownloadIcon />}
                  onClick={handleGetAllUploadSheet}
                >
                  Download All Students
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    background: "#1b3779",
                    "&:hover": { background: "#1b3779" },
                  }}
                  endIcon={<UploadIcon />}
                  component="label"
                >
                  Upload
                  <input
                    onChange={handleFileChange}
                    name="file"
                    hidden
                    accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                    type="file"
                  />
                </Button>
                <div
                  style={{
                    visibility: fileChoosen ? "visible" : "hidden",
                  }}
                >
                  {fileChoosen && fileChoosen.name}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent="flex-end"
              >
                <LoadingButton
                  loading={sheetLoader}
                  variant="contained"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  type="submit"
                >
                  submit
                </LoadingButton>
              </Grid>
              {/* 
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      style={styles.Button}
                      onClick={handleGetUploadSheet}
                    >
                      Download
                    </Button>
                  </div>

                  <div style={{ position: "relative" }}>
                    <Button
                      variant="contained"
                      sx={styles.Button}
                      component="label"
                    >
                      Upload
                      <input
                        onChange={handleFileChange}
                        name="file"
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                    <div
                      style={{
                        position: "fixed",
                        top: 70,
                        right: 10,
                        visibility: fileChoosen ? "visible" : "hidden",
                      }}
                    >
                      {fileChoosen && fileChoosen.name}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      style={styles.Button}
                      onClick={handleGetAllUploadSheet}
                    >
                      Download All Student
                    </Button>
                  </div>
                  <Button variant="contained" sx={styles.Button} type="submit">
                    submit
                  </Button>
                </Grid> */}
            </Grid>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default ManageMark;
