import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

// Icons
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
// import { theme } from "../../theme";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";

// apis
import { urls as url } from "../../../../services/urlConstant";
import appendStringToUrls from "../../../../utils/appendString";
import SettingContext from "../../../../context/SettingsContext";
import { get, post, put, del } from "../../../../services/apis";

import Wrapper from "../../../Wrapper";
import { theme } from "../../../../theme";

import { useContext } from "react";

const Buttons1 = styled(Button)(() => ({
  backgroundColor: "#e0e0e0",
  color: theme.palette.primary.main,
  border: "1px solid e0e0e0",
  borderRadius: 20,
  fontSize: "12px",
  height: "35px",
  width: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: 3,
  textTransform: "uppercase",
  marginTop: "2px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

const style = {
  width: "100%",
  position: "relative",
  border: "2px solid white",
  bgcolor: "background.paper",
  p: 2,
};

const RadioButtons = styled(Radio)(() => ({
  marginTop: "-10px",
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const InputLabels = styled(InputLabel)(() => ({
  fontSize: "12px",
  fontWeight: 580,
  color: "#616161",
  paddingLeft: "10px",
}));

const Boxes = styled(Box)(() => ({
  display: "flex",
}));
const DownloadBox = styled(Box)(() => ({
  background: `rgb(133 140 223 / 19%)`,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",
  color: `${theme.palette.primary.main}`,
}));

export default function UploadCourseButtons({
  courseId,
  chapter,
  courseName,
  updateList = () => {},
}) {
  const fileInputRef = useRef(null);

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // quiz
  const [quizDescription, setQuizDescription] = useState("");
  const [codeDescription, setCodeDescription] = useState("");
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  //flshCards
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [contentHours, setContentHours] = useState(0);
  const [openFlash, setOpenFlash] = useState(false);
  const [name, setName] = useState("");
  const [course, setCourse] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [chapterDescription, setChapterDescription] = useState("");
  const [flashCardTitle, setFlashCardTitle] = useState("");
  const [flashCardDescription, setFlashCardDescription] = useState("");
  const [quizTitle, setQuizTitle] = useState("");
  const [chapterVideoLoading, setChapterVideoLoading] = useState(false);
  const [flashcard, setFlashcard] = useState("Text");
  const [imageFile, setImageFile] = useState(null);
  let [flashText, setFlashText] = useState("");
  const [flashcardLoading, setFlashcardLoading] = useState(false);
  const [video, setVideo] = useState("");
  const [openMaterial, setOpenMaterial] = useState(false);
  const [openCode, setOpenCode] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileCode, setFileCode] = useState(null);
  const [fileCodeName, setFileCodename] = useState("");
  const [loadCode, setLoadCode] = useState(false);
  const [loadMaterial, setLoadMaterial] = useState(false);
  const [materialName, setMaterialName] = useState("");
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  const handleChangeContent = (event) => {
    setContent(event.target.value);
  };

  useEffect(() => {
    getCourse();
  }, []);

  const getCourse = async () => {
    try {
      const { data, status } = await get(`${urls?.course?.courselist}`, {
        headers: {
          roleFunction: "courseContent",
        },
      });
      setCourse(data?.result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleOpenFlash = () => setOpenFlash(true);
  const handleOpenMaterial = () => setOpenMaterial(true);
  const handleOpenCode = () => setOpenCode(true);
  const handleCodeclose = () => {
    setOpenCode(false);
    setFileCode(null);
    setTitle("");
    setFileCodename("");
    setContentHours(0);
    setCodeDescription("");
  };
  const handleMaterialClose = () => {
    setOpenMaterial(false);
    setFile(null);
    setFileName("");
    setMaterialName("");
    setContentHours(0);
  };
  const handleRemoveMaterialFile = () => {
    setFile(null);
    setFileName("");
  };

  const handleRemoveCodeFile = () => {
    setFileCode(null);
    setFileCodename("");
  };
  const handleCloseFlash = () => {
    setOpenFlash(false);
    setFlashcard("Text");
    setImageFile("");
    setFlashText("");
    setContentHours(0);
  };

  //   handle Quiz Dialog
  const handleClose = () => {
    setOpen(false);
    setName("");
    setVideoFile("");
    setChapterDescription("");
    setContentHours(0);
  };

  //Video

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setVideoFile(URL.createObjectURL(file));
    setVideo(file);
  };
  const handleMaterialChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleCodeChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileCode(selectedFile);
    setFileCodename(selectedFile.name);
  };
  const handleMaterialSubmit = async (event) => {
    event.preventDefault();
    setLoadMaterial(true);
    const formsData = new FormData();

    const material = {
      type: "Material",
      orderSequence: chapter.contents ? chapter.contents.length + 1 : 1,
      title: materialName,
      chapterId: chapter._id,
      contentHours,
    };

    formsData.append("material", JSON.stringify(material));

    // Convert material to JSON string
    formsData.append("file", file);

    console.log(formsData, "--add");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        roleFunction: "courseContent",
      },
    };
    try {
      const response = await put(
        `${urls.courseContent.createCourseContent}/${courseId}`,
        "",
        formsData,
        config
      );

      setLoadMaterial(false);
      updateList();
      handleMaterialClose();
      // setTimeout(() => {
      //   handleClose();
      //   setName("");
      //   setVideoFile("");
      //   setChapterDescription("");
      // }, 1000);
    } catch (error) {
      console.error(error);
      setLoadMaterial(false);
    }
  };

  const handleCodeSubmit = async (event) => {
    event.preventDefault();
    setLoadCode(true);
    const formsData = new FormData();

    const material = {
      type: "CodePractice",
      orderSequence: chapter.contents ? chapter.contents.length + 1 : 1,
      title: title,
      description: codeDescription,
      chapterId: chapter._id,
      contentHours,
    };

    formsData.append("material", JSON.stringify(material));

    // Convert material to JSON string
    formsData.append("file", fileCode);

    console.log(formsData, "--add");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        roleFunction: "courseContent",
      },
    };
    try {
      const response = await put(
        `${urls.courseContent.createCourseContent}/${courseId}`,
        "",
        formsData,
        config
      );

      setLoadCode(false);
      updateList();
      handleCodeclose();
      // setTimeout(() => {
      //   handleClose();
      //   setName("");
      //   setVideoFile("");
      //   setChapterDescription("");
      // }, 1000);
    } catch (error) {
      console.error(error);
      setLoadCode(false);
    }
  };

  //   submit video dialog
  const handleSubmit = async (event) => {
    event.preventDefault();
    setChapterVideoLoading(true);
    const formsData = new FormData();

    const material = {
      type: "Video",
      orderSequence: chapter.contents ? chapter.contents.length + 1 : 1,
      title: name,
      description: chapterDescription,
      chapterId: chapter._id,
      contentHours,
    };

    formsData.append("material", JSON.stringify(material));
    console.log(video, "=====videps");
    formsData.append("file", video);
    console.log(formsData, "formsDatahhh");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        roleFunction: "courseContent",
      },
    };
    try {
      const response = await put(
        `${urls.courseContent.createCourseContent}/${courseId}`,
        "",
        formsData,
        config
      );

      updateList();
      // setTimeout(() => {
      //   handleClose();
      //   setName("");
      //   setVideoFile("");
      //   setChapterDescription("");
      // }, 1000);
      setChapterVideoLoading(false);
    } catch (error) {
      console.error(error);
      setChapterVideoLoading(false);
    }
    setChapterVideoLoading(false);
  };

  // quiz ==========
  const [openQuiz, setOpenQuiz] = useState(false);
  const [creatingQuiz, setCreatingQuiz] = useState(false);
  const handleOpenQuiz = () => setOpenQuiz(true);

  const handleCloseQuiz = () => {
    setQuizData([
      {
        question: "",
        options: ["", "", "", ""],
        correctAnswer: "",
      },
    ]);
    setOpenQuiz(false);
  };

  const handleAddQuiz = () => {
    setQuizData([
      ...quizData,
      {
        question: "",
        options: ["", "", "", ""],
        correctAnswer: "",
      },
    ]);
  };

  const removeQuiz = (index) => {
    setQuizData([...quizData.filter((q, i) => i !== index)]);
  };

  const [quizData, setQuizData] = useState([
    {
      question: "",
      options: ["", "", "", ""],
      correctAnswer: "",
    },
  ]);

  const handleQuestionChange = (e, i) => {
    setQuizData(
      quizData.map((q, index) =>
        index === i ? { ...q, question: e.target.value } : q
      )
    );
  };

  const handleSetCorrectOption = (o, questionIndex) => {
    setQuizData(
      quizData.map((q, i) =>
        i === questionIndex ? { ...q, correctAnswer: o } : q
      )
    );
  };

  const handleOptionChange = (e, questionIndex, optionIndex) => {
    let questionToUpdate = quizData.filter((q, i) => i === questionIndex)[0];
    let options = questionToUpdate.options;
    let newOptions = options.map((o, i) =>
      i === optionIndex ? e.target.value : o
    );
    setQuizData(
      quizData.map((q, i) =>
        i === questionIndex ? { ...q, options: newOptions } : q
      )
    );
  };

  const handleSubmitQuiz = async (event) => {
    event.preventDefault();
    try {
      const quizToUpdate = quizData.map((q) => ({
        question: q.question,
        correctOption: q.correctAnswer,
        options: [
          { name: q.options[0] },
          { name: q.options[1] },
          { name: q.options[2] },
          { name: q.options[3] },
        ],
      }));
      if (quizToUpdate.filter((q) => !q.question).length)
        return toast.error("Question and correct option cannot be empty");
      if (
        quizToUpdate.some((q) => q.options.some((option) => option.name === ""))
      )
        return toast.error("Option cannot be empty");

      if (quizToUpdate.filter((q) => !q.correctOption).length)
        return toast.error("Please select a correct option");

      setCreatingQuiz(true);
      const material = {
        type: "Quiz",
        orderSequence: chapter.contents ? chapter.contents.length + 1 : 1,
        quiz: quizToUpdate,
        title: quizTitle,
        description: quizDescription,
        chapterId: chapter._id,
        contentHours,
      };

      const formData = new FormData();

      formData.append("material", JSON.stringify(material));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          roleFunction: "courseContent",
        },
      };
      const response = await put(
        `${urls.courseContent.createCourseContent}/${courseId}`,
        "",
        formData,
        config
      );

      setCreatingQuiz(false);
      handleCloseQuiz();
      updateList();
    } catch (error) {
      console.log(error);
      setCreatingQuiz(false);
    }
  };

  // flash cards
  const handleChangeFlash = (event) => {
    setFlashcard(event.target.value);
    if (event.target.value !== "Image") {
      setImageFile(null);
    }
  };

  //   Flash Submit
  const handleSubmitFlash = async (e) => {
    e.preventDefault();

    try {
      if (courseId) {
        const material = {
          type: "FlashCard",
          orderSequence: chapter.contents ? chapter.contents.length + 1 : 1,
          flashCard: {
            cardType: flashcard,
            text: flashText,
          },
          title: flashCardTitle,
          contentHours,
          description: flashCardDescription,
          chapterId: chapter._id,
        };

        if (flashcard == "Image" && !image)
          return toast.error("Please select an Image file for Flash Card!");

        setFlashcardLoading(true);

        const formData = new FormData();
        formData.append("material", JSON.stringify(material));
        formData.append("file", image);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            roleFunction: "courseContent",
          },
        };
        const response = await put(
          `${urls.courseContent.createCourseContent}/${courseId}`,
          "",
          formData,
          config
        );
        updateList();
        setFlashcardLoading(false);
        handleCloseFlash();
      } else {
        console.error("Course not found");
        setFlashcardLoading(false);
      }
    } catch (error) {
      console.error(error);
      setFlashcardLoading(false);
    }
    setFlashcardLoading(false);
  };

  const handleUploadImg = () => {
    fileInputRef.current.click();
  };

  const handleFileChangeFlash = (event) => {
    const file = event.target.files[0];
    setImageFile(URL.createObjectURL(file));
    setImage(file);
  };

  const Content = [
    {
      id: 1,
      label: "Video",
      value: "video",
      onClick: handleOpen,
    },
    {
      id: 2,
      label: "Quiz",
      value: "quiz",
      onClick: handleOpenQuiz,
    },
    {
      id: 3,
      label: "Flashcard",
      value: "flashcard",
      onClick: handleOpenFlash,
    },
    {
      id: 4,
      label: "Material",
      value: "material",
      onClick: handleOpenMaterial,
    },
    {
      id: 5,
      label: "Code Practice",
      value: "codepractice",
      onClick: handleOpenCode,
    },
  ];

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            md: "flex-end",
            sm: "center",
            lg: "flex-end",
          },
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Add Content</InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={content}
              label="Add Content"
              onChange={handleChangeContent}
            >
              {Content.map((data, i) => (
                <MenuItem
                  key={data.id}
                  value={data.value}
                  onClick={() => data.onClick()}
                >
                  {data.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* vedio- model--------------- */}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} md={12} my={1}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Add Video for Course:{" "}
                  {courseName.find((m) => m.value === courseId)?.label}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <InputLabels>Video Name {name.length}/80*</InputLabels>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Type here..."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      inputProps={{ maxLength: 80 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <InputLabels>Content Hours</InputLabels>

                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      placeholder="Type here..."
                      value={contentHours}
                      onChange={(e) => setContentHours(e.target.value)}
                    />
                  </Box>
                </Box>

                <Button
                  sx={{ width: "100%", height: "45px" }}
                  variant="outlined"
                  size="small"
                  onClick={handleUploadButtonClick}
                  startIcon={<UploadFileIcon />}
                >
                  Upload Video
                </Button>

                <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                {/* <TextField
                  fullWidth
                  size="small"
                  placeholder="Enter Description"
                  value={chapterDescription}
                  onChange={(e) => setChapterDescription(e.target.value)}
                  sx={{ marginTop: "25px" }}
                /> */}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  fontSize: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {videoFile && (
                  <video
                    src={videoFile}
                    style={{
                      backgroundColor: "black",
                      alignItems: "center",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                    width="90%"
                    height="auto"
                    controls
                  />
                )}
              </Grid>
              {!name || !videoFile ? null : (
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    my: 2,
                  }}
                  item
                  xs={12}
                  md={12}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 2,
                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                      size="small"
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                      loading={chapterVideoLoading}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Dialog>
      {/* Quiz model----------------------- */}

      <Dialog
        open={openQuiz}
        onClose={handleCloseQuiz}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={handleSubmitQuiz}>
          <Box sx={{ maxHeight: "80vh", padding: 2, overflowY: "auto" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bolder",
                fontSize: "20px",
                marginBottom: "20px",
              }}
            >
              Add Quiz: {courseName.find((m) => m.value === courseId)?.label}
            </Typography>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabels> Title {quizTitle.length}/80*</InputLabels>

                <TextField
                  fullWidth
                  size="small"
                  value={quizTitle}
                  name="title"
                  placeholder="Type here..."
                  sx={{ marginBottom: "25px" }}
                  onChange={(e) => setQuizTitle(e.target.value)}
                  inputProps={{ maxLength: 80 }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputLabels>Content Hours</InputLabels>

                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  placeholder="Type here..."
                  value={contentHours}
                  onChange={(e) => setContentHours(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputLabels> Description</InputLabels>

                <TextField
                  fullWidth
                  value={quizDescription}
                  size="small"
                  multiline
                  name="description"
                  sx={{ marginBottom: "25px" }}
                  placeholder="Type here..."
                  onChange={(e) => setQuizDescription(e.target.value)}
                />
              </Grid>
            </Grid>

            {quizData?.map((q, questionIndex) => (
              <Grid
                container
                rowSpacing={2}
                key={questionIndex}
                sx={{
                  marginTop: "20px",
                  marginBottom: "40px",
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: "5px",
                  padding: "0 15px 20px 15px",
                  border: "1px solid lightgrey",
                }}
              >
                <Grid item xs={12} md={12}>
                  <InputLabels>
                    {questionIndex + 1}.{" "}
                    {q.question
                      ? "Update Your Question!"
                      : "Write your question"}
                  </InputLabels>
                  <TextField
                    fullWidth
                    size="small"
                    name="question"
                    value={q.question}
                    placeholder="Type here..."
                    onChange={(e) => handleQuestionChange(e, questionIndex)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {questionIndex > 0 ? (
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => removeQuiz(questionIndex)}
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container rowGap={2} columnSpacing={2}>
                    {q.options.map((o, i) => (
                      <Grid key={i} item xs={12} md={6}>
                        <Boxes
                          sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <RadioButtons
                            size="small"
                            {...label}
                            color="default"
                            checked={q.correctAnswer && q.correctAnswer === o}
                            onChange={(e) =>
                              handleSetCorrectOption(o, questionIndex)
                            }
                            value={o}
                            type="radio"
                          />
                          <InputLabels>Option {i + 1}</InputLabels>
                        </Boxes>
                        <TextField
                          fullWidth
                          size="small"
                          name={`option${i + 1}`}
                          value={o}
                          onChange={(e) =>
                            handleOptionChange(e, questionIndex, i)
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))}

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleAddQuiz}
                    startIcon={<AddIcon />}
                  >
                    Question
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleCloseQuiz}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    type="submit"
                    size="small"
                    variant="contained"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                    loading={creatingQuiz}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>

      {/* Flash Cards */}

      <Dialog
        open={openFlash}
        onClose={handleCloseFlash}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={{ padding: 2 }}>
          <form onSubmit={handleSubmitFlash}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Add FlashCard for Course:{" "}
                {courseName.find((m) => m.value === courseId)?.label}
              </Typography>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} md={6}>
                <InputLabels> Title {flashCardTitle.length}/80*</InputLabels>

                <TextField
                  fullWidth
                  size="small"
                  placeholder="Type here..."
                  onChange={(e) => setFlashCardTitle(e.target.value)}
                  inputProps={{ maxLength: 80 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabels>Content Hours</InputLabels>

                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  placeholder="Type here..."
                  value={contentHours}
                  onChange={(e) => setContentHours(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputLabels> Description</InputLabels>

                <TextField
                  fullWidth
                  size="small"
                  placeholder="Type here..."
                  onChange={(e) => setFlashCardDescription(e.target.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <InputLabels>Card Type</InputLabels>

                <FormControl required fullWidth>
                  <Select
                    value={flashcard}
                    onChange={handleChangeFlash}
                    displayEmpty
                    size="small"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="Text">Flashcard Text</MenuItem>
                    <MenuItem value={"Image"}>Flashcard Image</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                {flashcard === "Text" ? (
                  <>
                    <TextField
                      value={flashText}
                      required
                      fullWidth
                      size="small"
                      placeholder="Enter Flashcard Text"
                      onChange={(e) => setFlashText(e.target.value)}
                    />
                  </>
                ) : (
                  <>
                    <Grid item xs={12} md={12} my={2}>
                      {flashcard === "Image" && imageFile && (
                        <>
                          <img
                            src={imageFile}
                            alt="Flashcard"
                            style={{ width: 300 }}
                          />
                        </>
                      )}
                    </Grid>

                    <Button
                      onClick={handleUploadImg}
                      variant="outlined"
                      size="small"
                      startIcon={<UploadFileIcon />}
                    >
                      Upload Image
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChangeFlash}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} mt={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={handleCloseFlash}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  loading={flashcardLoading}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </form>
        </Box>
      </Dialog>
      {/* Materials...---------- */}
      <Dialog
        fullScreen={fullScreen}
        open={openMaterial}
        onClose={handleMaterialClose}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={handleMaterialSubmit}>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Add Material:{" "}
                {courseName.find((m) => m.value === courseId)?.label}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              // sx={{
              //   textAlign: "center",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "space-between",
              //   columnGap: 2,
              // }}
            >
              <InputLabels>Material Name</InputLabels>
              <TextField
                fullWidth
                size="small"
                name="materialName"
                required
                value={materialName}
                placeholder="Enter Material Name"
                onChange={(e) => setMaterialName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabels>Content Hours</InputLabels>

              <TextField
                fullWidth
                type="number"
                size="small"
                placeholder="Type here..."
                value={contentHours}
                onChange={(e) => setContentHours(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {" "}
              <Button
                component="label"
                variant="outlined"
                fullWidth
                sx={{
                  padding: "10px",
                  // maxWidth: 200,
                }}
                startIcon={<FileUploadIcon fontSize="small" />}
                size="small"
              >
                upload material
                <input
                  hidden
                  type="file"
                  onChange={handleMaterialChange}
                  style={{ display: "block" }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              {fileName ? (
                <DownloadBox px={2} py={1}>
                  <Typography>{fileName}</Typography>
                  <Button onClick={handleRemoveMaterialFile} color="error">
                    <ClearIcon />
                  </Button>
                </DownloadBox>
              ) : (
                ""
              )}
            </Grid>

            <Grid
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
              item
              xs={12}
              md={12}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  alignSelf: "center",
                  flex: 1,
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleMaterialClose}
                  size="small"
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  loading={loadMaterial}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>

      {/*Code Practice....---------------*/}

      <Dialog
        fullScreen={fullScreen}
        open={openCode}
        onClose={handleCodeclose}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={handleCodeSubmit}>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Add Material:
                {courseName.find((m) => m.value === courseId)?.label}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              // sx={{
              //   textAlign: "center",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "space-between",
              //   columnGap: 2,
              // }}
            >
              <InputLabels>Title</InputLabels>
              <TextField
                fullWidth
                size="small"
                name="title"
                required
                value={title}
                placeholder="Enter Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabels>Content Hours</InputLabels>

              <TextField
                fullWidth
                type="number"
                size="small"
                placeholder="Type here..."
                value={contentHours}
                onChange={(e) => setContentHours(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <InputLabels> Description</InputLabels>

              <TextField
                fullWidth
                value={codeDescription}
                size="small"
                multiline
                name="description"
                sx={{ marginBottom: "25px" }}
                placeholder="Type here..."
                onChange={(e) => setCodeDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {" "}
              <Button
                component="label"
                variant="outlined"
                fullWidth
                sx={{
                  padding: "10px",
                  // maxWidth: 200,
                }}
                startIcon={<FileUploadIcon fontSize="small" />}
                size="small"
              >
                upload material
                <input
                  hidden
                  type="file"
                  onChange={handleCodeChange}
                  style={{ display: "block" }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              {fileCodeName ? (
                <DownloadBox px={2} py={1}>
                  <Typography>{fileCodeName}</Typography>
                  <Button onClick={handleRemoveCodeFile} color="error">
                    <ClearIcon />
                  </Button>
                </DownloadBox>
              ) : (
                ""
              )}
            </Grid>

            <Grid
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
              item
              xs={12}
              md={12}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  alignSelf: "center",
                  flex: 1,
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCodeclose}
                  size="small"
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  loading={loadCode}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  );
}
