import {
  Autocomplete,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { get } from "../services/apis";
import { urls } from "../services/urlConstant";

import {
  Add,
  CardMembership,
  FindInPage,
  Groups,
  Message,
} from "@mui/icons-material";
import ChartBar from "../components/Layout/ChartBar/ChartBar";
import "../styles/components/Dashboard/Dashboard.scss";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Collapsible from "react-collapsible";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import SettingContext from "../context/SettingsContext";
import appendStringToUrls from "../utils/appendString";
import SectionSubHeader from "../components/SectionSubHeader";
import Wrapper from "../components/Wrapper";

// import NewsNotice from "../Data/news.json";

const styles = {
  card: {
    margin: 15,
    padding: 10,
  },
  textfield: {
    width: "100%",
  },
  Button: {
    background: "#1b3779",
    "&:hover": { background: "#1b3779" },
  },
};

const DashBoard = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [sections, setSections] = useState([]);
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [calValue, setCalValue] = useState(new Date());
  const [announceNotice, setAnounceNotice] = useState([]);
  const [selectStudent, setSelectStudent] = useState("");
  const [notice, setNotice] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [studentAttendanceStats, setStudentAttendanceStats] = useState(null);
  const [empAttendanceStat, setEmpAttendanceStat] = useState(null);
  const currentDate = new Date();
  const [today, setToday] = useState(new Date());

  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  const navigate = useNavigate();

  const handleOnchange = async (e) => {
    const { name, value } = e.target;
    setDashboardDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    // if (value === "all") {
    //   const getStudents = await get(url.students.getAllStudent, {
    //     headers: { roleFunction: "dashboard" },
    //   });
    //   setStudents(getStudents.data.data);
    // } else {
    //   const getStudents = await get(url.students.getAllStudent, {
    //     params: {
    //       headers: { roleFunction: "dashboard" },
    //       search: {
    //         "academicInfo.class": value,
    //       },
    //     },
    //   });
    //   setStudents(getStudents.data.data);
    // }
    // } catch (error) {
    //   toast.error(error.response.data.message);
    // }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resSections = await get(url.schoolSection.getAllSection, {
          headers: { roleFunction: "dashboard" },
          params: {
            search: {
              class: dashboardDetails.className,
            },
          },
        });
        // console.log(resSections, "sectionssssss");
        setSections(resSections.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dashboardDetails.className]);

  console.log(dashboardDetails.className, "dashboardDetails.className");
  useEffect(() => {
    const res = async () => {
      const getStudents = await get(url.students.getAllStudent, {
        headers: { roleFunction: "dashboard" },
        params: {
          search: {
            "academicInfo.class": dashboardDetails.className,
            "academicInfo.section": dashboardDetails.sectionName,
          },
        },
      });
      setStudents(getStudents.data.data);
    };
    res();
  }, [dashboardDetails.sectionName]);

  useEffect(() => {
    const studentsAttendanceStatsRes = async () => {
      const { data, status } = await get(
        `/${selectedSetting._id}/dashboard/attendance-total`,
        { headers: { roleFunction: "dashboard" } }
      );
      const { data: empStat, status: empStatStatus } = await get(
        `/${selectedSetting._id}/dashboard/employee-attendance-stats`,
        { headers: { roleFunction: "dashboard" } }
      );
      if (status === 200) {
        setStudentAttendanceStats(data.data);
      }
      if (empStatStatus === 200) {
        setEmpAttendanceStat(empStat.data);
      }
    };
    studentsAttendanceStatsRes();
  }, [selectedSetting._id]);
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(url.class.getAllClass, {
          headers: { roleFunction: "dashboard" },
        });

        const getStudents = await get(url.students.getAllStudent, {
          headers: { roleFunction: "dashboard" },
        });

        setClasses(res.data.data);
        // setSections(resSections.data.data);
        setStudents(getStudents.data.data);
        setDashboardDetails((prev) => ({
          ...prev,
          // className: "all",
        }));
      } catch (error) {
        // toast.error(error.response.data.message)
      }
    };
    getClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    setSelectStudent(null);
    const getStudents = async () => {
      try {
        const res = await get(url.students.getAllStudent, {
          headers: { roleFunction: "dashboard" },
        });
        setStudents(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStudents();
  }, [selectedSetting._id]);
  const handleStudentAuto = (event, newVal) => {
    setSelectStudent(newVal);
  };
  const handleSearchStudentSubmit = (e) => {
    e.preventDefault();
    if (selectStudent) navigate(`/edit-student/${selectStudent._id}`);
  };
  useEffect(() => {
    const getNoticeReq = async () => {
      try {
        const getNotice = await get(
          `/${selectedSetting._id}/dashboard/month-notice/${dayjs().format(
            "YYYY-MM-DD"
          )}`,
          { headers: { roleFunction: "dashboard" } }
        );
        setNotice(getNotice.data.data);
        const getHolidays = await get(
          `/${selectedSetting._id}/dashboard/month-holiday/${dayjs().format(
            "YYYY-MM-DD"
          )}`,
          { headers: { roleFunction: "dashboard" } }
        );
        setHolidays(getHolidays.data.data);
      } catch (error) {}
    };
    getNoticeReq();
  }, [selectedSetting._id]);

  const handleCalChange = async (e, date) => {
    setCalValue(e, date);
    try {
      const getNotice = await get(
        `/${selectedSetting._id}/dashboard/month-notice/${dayjs(e).format(
          "YYYY-MM-DD"
        )}`,
        { headers: { roleFunction: "dashboard" } }
      );
      setNotice(getNotice.data.data);
      const getHolidays = await get(
        `/${selectedSetting._id}/dashboard/month-holiday/${dayjs(e).format(
          "YYYY-MM-DD"
        )}`,
        { headers: { roleFunction: "dashboard" } }
      );
      setHolidays(getHolidays.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Dashboard" />
      {/* //=========class============// */}
      <Paper sx={{ padding: 2 }}>
        <form onSubmit={handleSearchStudentSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl size="small" sx={styles.textfield} required>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Class
                </InputLabel>
                <Select
                  label="Class"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="className"
                  value={dashboardDetails.className || ""}
                  onChange={(event) => handleOnchange(event)}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl size="small" sx={styles.textfield} required>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Section
                </InputLabel>
                <Select
                  label="Class"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="sectionName"
                  value={dashboardDetails.sectionName || ""}
                  onChange={(event) => handleOnchange(event)}
                >
                  {sections &&
                    sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <FormControl size="small" sx={styles.textfield} required>
                <Autocomplete
                  onChange={handleStudentAuto}
                  options={students}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    option &&
                    `${option && option.basicInfo.name} | ${
                      option && option.academicInfo.rollNo
                    } | ${option && option.contactNumber}`
                  }
                  value={selectStudent}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Student"
                      size="small"
                      placeholder="Search student with name"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3} style={{ alignSelf: "center" }}>
              <Button
                style={styles.Button}
                size="small"
                variant="contained"
                type="submit"
              >
                find
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {/* //============chart=========// */}
      <Paper sx={{ padding: 2, margin: "20px 0px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card
              sx={{
                marginBottom: "15px",
                padding: "10px",

                height: "auto",
                width: "100%",
                // display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: { md: "row", sm: "column", xs: "column" },
              }}
            >
              <Box
                sx={{
                  alignSelf: "center",

                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Groups
                  style={{
                    width: "80px",
                    height: "80px",
                    color: "#1b3779",
                  }}
                />
              </Box>
              <Link to={"/student-attendance"} style={{ color: "inherit" }}>
                <Box
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",

                    justifyContent: "center",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <div>Students Attendance </div>
                    <div>
                      {studentAttendanceStats &&
                        `${studentAttendanceStats.presentCount}/${studentAttendanceStats.studentCount}`}
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        paddingTop: "5px",
                      }}
                    >
                      <Typography>
                        <span style={{ color: "green", fontWeight: 600 }}>
                          P:
                        </span>
                        {studentAttendanceStats &&
                          `${studentAttendanceStats.presentCount}`}
                      </Typography>
                      <Typography>
                        <span style={{ color: "red", fontWeight: 600 }}>
                          A:
                        </span>
                        {studentAttendanceStats &&
                          `${studentAttendanceStats.absentCount}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Card>

            <Card
              sx={{
                // margin: "15px",
                padding: "10px",
                // height:"115px",
                height: "auto",
                width: "100%",
                // display: "flex",
                justifyContent: "space-around",
                flexDirection: { md: "row", sm: "column", xs: "column" },
                textAlign: "center",
                // border:"2px solid yellow"
              }}
            >
              <Box sx={{ alignSelf: "center" }}>
                <Groups
                  style={{
                    width: "80px",
                    height: "80px",
                    color: "#1b3779",
                  }}
                />
              </Box>
              <Link
                to={"/employee-attendance"}
                style={{
                  color: "inherit",
                }}
              >
                <Box
                  style={{
                    // textAlign: "right",
                    // alignSelf: "center",
                    display: "flex",
                    flexDirection: { md: "row", sm: "column" },
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div>Employee Attendance</div>
                    <div>
                      {empAttendanceStat &&
                        `${empAttendanceStat.presentEmpAttendanceCount}/${empAttendanceStat.employeeCount}`}
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        paddingTop: "5px",
                      }}
                    >
                      <Typography>
                        <span style={{ color: "green", fontWeight: 600 }}>
                          P:
                        </span>
                        {empAttendanceStat &&
                          `${empAttendanceStat.presentEmpAttendanceCount}`}
                      </Typography>
                      <Typography>
                        <span style={{ color: "red", fontWeight: 600 }}>
                          A:
                        </span>
                        {empAttendanceStat &&
                          `${empAttendanceStat.absentEmpAttendanceCount}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <ChartBar />
          </Grid>
        </Grid>
      </Paper>
      {/* //========quick links========// */}
      <Paper sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
          >
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
              Quick Links
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
            <Link to="/admitstudent" className="quick-link">
              <Card
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "space-evenly",
                  flexDirection: {
                    md: "column",
                    sm: "column",
                    xs: "column",
                  },
                  p: "25px 0",
                }}
              >
                <Box>
                  <Fab
                    sx={{
                      background: "#1b3779",
                      color: "#fff",
                      "&:hover": {
                        color: "#1b3779",
                      },
                    }}
                    size="small"
                  >
                    <Groups />
                  </Fab>
                </Box>
                <Box>Admit Students</Box>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
            <Link to="/addemployee" className="quick-link">
              <Card
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "space-evenly",
                  flexDirection: {
                    md: "column",
                    sm: "column",
                    xs: "column",
                  },
                  p: "25px 0",
                }}
              >
                <Box>
                  <Fab
                    sx={{
                      background: "#1b3779",
                      color: "#fff",
                      "&:hover": {
                        color: "#1b3779",
                      },
                    }}
                    size="small"
                  >
                    <Groups />
                  </Fab>
                </Box>
                <Box>Admit Employee</Box>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
            <Link to="/examresult" className="quick-link">
              <Card
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "space-evenly",
                  flexDirection: {
                    md: "column",
                    sm: "column",
                    xs: "column",
                  },
                  p: "25px 0",
                }}
              >
                <Box>
                  <Fab
                    sx={{
                      background: "#1b3779",
                      color: "#fff",
                      "&:hover": {
                        color: "#1b3779",
                      },
                    }}
                    size="small"
                  >
                    <FindInPage />
                  </Fab>
                </Box>
                <Box> Exam Results</Box>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
            <Link to="/marks-card" className="quick-link">
              <Card
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "space-evenly",
                  flexDirection: {
                    md: "column",
                    sm: "column",
                    xs: "column",
                  },
                  p: "25px 0",
                }}
              >
                <Box>
                  <Fab
                    sx={{
                      background: "#1b3779",
                      color: "#fff",
                      "&:hover": {
                        color: "#1b3779",
                      },
                    }}
                    size="small"
                  >
                    <CardMembership />
                  </Fab>
                </Box>
                <Box>Marks Cards</Box>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
            <Link to="/compose" className="quick-link">
              <Card
                sx={{
                  display: "flex",
                  textAlign: "center",

                  justifyContent: "space-evenly",
                  flexDirection: {
                    md: "column",
                    sm: "column",
                    xs: "column",
                  },
                  p: "25px 0",
                }}
              >
                <Box>
                  <Fab
                    sx={{
                      background: "#1b3779",
                      color: "#fff",
                      "&:hover": {
                        color: "#1b3779",
                      },
                    }}
                    size="small"
                  >
                    <Message />
                  </Fab>
                </Box>
                <Typography>Communication</Typography>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Paper>
      {/* //============calender & notice==========// */}
      <Paper sx={{ padding: 2, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Calendar
              onChange={handleCalChange}
              tileClassName={({ date }) => {
                const today = new Date(); // Get today's date
                if (date.toDateString() === today.toDateString()) {
                  return "blue-day"; // Apply the "blue-day" class to today's date
                }
                return "";
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card style={{ padding: 10 }}>
              <Collapsible
                trigger={`Notices: (${notice.length})`}
                // open={notice.length > 0}
              >
                <div style={{ maxHeight: "130px", overflow: "auto" }}>
                  <div className="scroll-container">
                    {notice.map((data, index) => (
                      <Card key={index} className="dashboard-scroll-text">
                        <div className="dashboard-news-content">
                          <Card
                            className="news-date-month"
                            style={{ backgroundColor: "rgb(211, 211, 211)" }}
                          >
                            <div className="date">
                              {dayjs(data.date).format("DD-MMM-YYYY")}
                            </div>
                          </Card>
                          <div className="title-discription">
                            <div>
                              <h5>{data.title}</h5>
                            </div>
                            <div className="discription">{data.notice}</div>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Collapsible>

              {!notice.length && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
            </Card>

            <Card style={{ padding: 10, margin: "10px 0px" }}>
              <Collapsible trigger={`Holidays: (${holidays.length})`}>
                <div style={{ maxHeight: "140px", overflow: "auto" }}>
                  <div className="scroll-container">
                    {holidays.map((data, index) => (
                      <Card key={index} className="dashboard-scroll-text">
                        <div className="dashboard-news-content">
                          <Card
                            className="news-date-month"
                            style={{ backgroundColor: "rgb(211, 211, 211)" }}
                          >
                            <div className="date">
                              {dayjs(data.fromDate).format("DD-MMM-YYYY")}
                            </div>
                          </Card>
                          <div className="title-discription">
                            <div className="titles">
                              <h5>{data.title}</h5>
                            </div>
                            <div className="discription">{data.note}</div>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Collapsible>
              {!holidays.length && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Wrapper>
  );
};
export default DashBoard;
