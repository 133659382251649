import React, { useContext, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  Typography,
  TablePagination,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { urls as url } from "../../../services/urlConstant";
import { del, post, put, get } from "../../../services/apis";
import { Delete, Edit } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import DeleteModal from "../Academic/DeleteModal";
import AddUserRole from "./AddUserRole";
import { useNavigate } from "react-router-dom";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import ShowComponent from "../../ShowComponent";
import { Search } from "@material-ui/icons";

const RolePermission = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [schoolUserRole, setSchoolUserRole] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateUserRole, setUpdateUserRole] = useState(undefined);
  const [sort, setSort] = useState({ roleName: "asc" });
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [searchFilter, setSearchFilter] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [id, setId] = useState("");

  console.log(searchFilter, "==cc");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  let [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateUserRole(undefined);
    setId("");
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(urls.role.delRole, id, "", {
        roleFunction: "rolePermission",
      });
      if (res.data.success) {
        setSchoolUserRole(
          schoolUserRole.filter((item) => item._id !== res.data.data._id)
        );
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, data) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await post(urls.role.postRole, data, {
        headers: { roleFunction: "rolePermission" },
      });
      if (res.data.success) {
        setSchoolUserRole([...schoolUserRole, res.data.data]);
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.role.putRole, id, data, {
        headers: { roleFunction: "rolePermission" },
      });
      if (response.data.success) {
        setSchoolUserRole(
          schoolUserRole.map((e) => {
            if (e._id === response.data.data._id) {
              return response.data.data;
            } else {
              return e;
            }
          })
        );
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleEdit = async (id) => {
    setId(id);
    try {
      navigate(`/role-permission/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getUserRole = async () => {
      try {
        const res = await get(urls.role.getAllRole, {
          headers: { roleFunction: "rolePermission" },
        });
        setSchoolUserRole([...res.data.data]);
        setSearchFilter([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getUserRole();
  }, []);

  const roleNameSorted = () => {
    if (sort.roleName == "asc") {
      let sortedList = [
        ...schoolUserRole.sort((a, b) => b.roleName.localeCompare(a.roleName)),
      ];

      setSchoolUserRole(sortedList);
      setSort({ ...sort, roleName: "des" });
    } else {
      let sortedList = [
        ...schoolUserRole.sort((a, b) => a.roleName.localeCompare(b.roleName)),
      ];
      setSchoolUserRole(sortedList);
      setSort({ ...sort, roleName: "asc" });
    }
  };

  // Serch Role
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.trim() !== "") {
      setSearchFilter(
        schoolUserRole.filter((ele) =>
          ele.roleName.toLowerCase().includes(value.trim().toLowerCase())
        )
      );
    } else {
      setSearchFilter([...schoolUserRole]);
    }
  };

  return (
    <>
      <Card style={{ margin: 15, padding: "22px 0px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <Typography
              style={{
                color: "red",

                display: "inline-block",
              }}
            >
              Role Permission:
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Box style={{ margin: "15px" }}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          sx={{ mb: 2, mt: 1 }}
        >
          <TextField
            size="small"
            value={search}
            placeholder="Enter Role Name"
            onChange={handleSearch}
            label="Search Role Name"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">
                    Role Name
                    <span
                      onClick={roleNameSorted}
                      style={{ cursor: "pointer" }}
                    >
                      {sort.roleName === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.roleName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            <ShowComponent
                              roleFunction="rolePermission"
                              action="update"
                            >
                              <div className="edit-delete">
                                <Button onClick={() => handleEdit(row._id)}>
                                  Role permission settings
                                </Button>
                              </div>
                            </ShowComponent>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : schoolUserRole
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.roleName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            <ShowComponent
                              roleFunction="rolePermission"
                              action="update"
                            >
                              <div className="edit-delete">
                                <Button onClick={() => handleEdit(row._id)}>
                                  Role permission settings
                                </Button>
                              </div>
                            </ShowComponent>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!schoolUserRole.length || !searchFilter.length ? (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          ) : (
            ""
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : schoolUserRole.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: " flex",
            justifyContent: "flex-start",
            alignItem: "center",
            my: 1,
          }}
        />

        {/*================ Add Role Permission ============= */}
        <ShowComponent
          roleFunction="rolePermission"
          action={id ? "update" : "add"}
        >
          <div className="add-icon">
            <AddUserRole
              loader={loader}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              handleUpdate={handleUpdate}
              updateUserRole={updateUserRole}
            />
          </div>
          {/* ========================== */}
        </ShowComponent>
      </Box>
    </>
  );
};

export default RolePermission;
