import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
  styled,
  Box,
  Grid,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const AddSectionModal = (props) => {
  // console.log(props.updateClass && props.updateClass.className);
  const { selectedSetting } = useContext(SettingContext);
  const [sectionName, setSectionName] = useState("");
  const [classSection, setClassSection] = useState("");
  const [classes, setClasses] = useState([]);
  const [sectionTeacher, setSectionTeacher] = useState("");
  const [sectionNote, setSectionNote] = useState("");
  const [status, setStatus] = useState("");
  const [isPublish, setIsPublish] = useState("");
  const [employees, setEmployees] = useState([]);
  const [editId, setEditId] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  useEffect(() => {
    const getEmps = async () => {
      try {
        const getEmps = await get(url.employee.getAllEmployee, {
          headers: { roleFunction: "section" },
        });
        setEmployees(getEmps.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmps();
  }, []);

  useEffect(() => {
    if (props.open || props.updateSection) {
      setSectionName("");
      setClassSection("");
      setSectionNote("");
      setSectionTeacher("");
      setStatus("");
      setEditId("");
      setIsPublish("");

      if (props.updateSection) {
        console.log(props.updateSection, "ppppppppppppppppppppppppppppp");
        setSectionName(props.updateSection.sectionName);
        setClassSection(props.updateSection.class._id);
        setSectionNote(props.updateSection.sectionNote);
        setSectionTeacher(
          props.updateSection.sectionTeacher
            ? props.updateSection.sectionTeacher._id
            : ""
        );
        setStatus(props.updateSection.status);
        setEditId(props.updateSection._id);
        setIsPublish(props.updateSection.isPublish);
      }
    }
  }, [props.open, props.updateSection]);

  // console.log(sectionTeacher, "sectionteachers");

  useEffect(() => {
    const sectionClass = async () => {
      try {
        const response = await get(url.class.getAllClass, {
          headers: { roleFunction: "section" },
        });
        setClasses(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    sectionClass();
  }, []);

  const handleSectionName = (e) => {
    setSectionName(e.target.value);
  };
  const handleClassSection = (e) => {
    setClassSection(e.target.value);
  };
  const handleSectionNote = (e) => {
    setSectionNote(e.target.value);
  };
  const handleSectionTeacher = (e) => {
    setSectionTeacher(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleIsPublish = (e) => {
    setIsPublish(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.updateSection) {
      await props.handleUpdate(
        {
          sectionName: sectionName,
          class: classSection,
          sectionNote: sectionNote,
          sectionTeacher,
          status,
          isPublish,
        },
        props.updateSection._id
      );
    } else {
      await props.handleSubmit(e, {
        sectionName: sectionName,
        class: classSection,
        sectionNote: sectionNote,
        sectionTeacher,
        status,
        isPublish,
      });
    }
  };

  const handleCloseModal = () => {
    setSectionName("");
    setClassSection("");
    setSectionNote("");
    setSectionTeacher("");
    setStatus("");
    setEditId("");
    setIsPublish("");
    props.handleClose();
  };

  const handleClose = () => {
    setSectionName("");
    setClassSection("");
    setSectionNote("");
    setSectionTeacher("");
    setStatus("");
    setEditId("");
    setIsPublish("");
    props.handleClose();
  };

  return (
    <div>
      <Tooltip title="Add Section" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}>
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form
          className="form-tag"
          onSubmit={handleSubmit}
          style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                fontSize="20px"
                fontWeight="bold">
                {props.updateSection ? "Update Section" : "Add Section"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                required
                id="filled-required"
                size="small"
                label="Section Name"
                value={sectionName}
                onChange={handleSectionName}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-simple-select-filled-label">
                  Class
                </InputLabel>
                <Select
                  label="Class"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={classSection}
                  onChange={handleClassSection}>
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}>
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel required>Section Teacher</InputLabel>
                <Select
                  label="Section Teacher"
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  value={sectionTeacher}
                  onChange={handleSectionTeacher}>
                  {employees &&
                    employees.map((emp) => (
                      <MenuItem
                        key={emp._id}
                        value={emp._id}
                        style={{ fontSize: 12, fontWeight: 500 }}>
                        {emp.basicInfo.empName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel required id="demo-simple-select-filled-label">
                  Status
                </InputLabel>
                <Select
                  label="Status"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={status}
                  onChange={handleStatus}>
                  <MenuItem
                    value={"inactive"}
                    sx={{ fontSize: 12, fontWeight: 500 }}>
                    Inactive
                  </MenuItem>
                  <MenuItem
                    value={"active"}
                    sx={{ fontSize: 12, fontWeight: 500 }}>
                    Active
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel required id="demo-simple-select-required-label">
                  Is Publish?
                </InputLabel>
                <Select
                  label="Is Publish"
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={isPublish}
                  onChange={handleIsPublish}>
                  <MenuItem
                    value={"inactive"}
                    sx={{ fontSize: 12, fontWeight: 500 }}>
                    No
                  </MenuItem>
                  <MenuItem
                    value={"active"}
                    sx={{ fontSize: 12, fontWeight: 500 }}>
                    Yes
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextareaAutosize
                maxRows={4}
                placeholder="Drop a note"
                value={sectionNote}
                onChange={handleSectionNote}
                style={{
                  width: "100%",
                  maxHeight: 110,
                  borderRadius: "5px",
                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { md: "right", xs: "center" },
                }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="small"
                  color="error"
                  type="button">
                  Cancel
                </Button>
                <LoadingButton
                  loading={props.loader}
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    ml: 2,
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  disabled={
                    !(
                      sectionName &&
                      classSection &&
                      sectionTeacher &&
                      status &&
                      isPublish
                    )
                  }>
                  {props.updateSection ? "Update" : "Submit"}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </div>
  );
};

export default AddSectionModal;
