import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import Stack from "@mui/material/Stack";
import SectionSubHeader from "../../../components/SectionSubHeader";
import { get, post, put, del } from "../../../services/apis";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { urls as url } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import Wrapper from "../../Wrapper";

const MainBox = styled(Box)(({ theme }) => ({
  // width: 500,
  // padding: 15,
  // marginBottom: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Cards = styled(Card)(({ theme }) => ({
  width: 500,
  padding: 15,
  marginBottom: 20,
  marginTop: 30,
}));

// const style = {
//   card: {
//     color: "red",
//     fontFamily: "cursive",
//     textAlign: "start",
//   },
//   card1: {
//     width: 500,
//     padding: 15,
//     marginBottom: 20,
//     margin: "70px 200px",
//   },
// };

const PasswordReset = () => {
  let [loader, setLoader] = useState(false);
  const [addForm, setAddForm] = useState({ oldPassword: "", newPassword: "" });
  const { selectedSetting } = useContext(SettingContext);
  const [profileId, setProfileId] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const profileId = window.localStorage.getItem(
      process.env.REACT_APP_PROFILE_ID
    );
    setProfileId(profileId);
    // console.log(profileId, process.env.REACT_APP_PROFILE_ID);
  }, []);

  const handleUserChangePassword = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      await post(
        `${urls.password.resetPasswordForUser}/${profileId}`,
        {
          oldpassword: addForm.oldPassword,
          password: addForm.newPassword,
        },
        { headers: { roleFunction: "auth" } }
      );
      setAddForm({ oldPassword: "", newPassword: "" });
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
    setLoader(false);
  };

  return (
    <div>
      <SectionSubHeader title="Reset Password" style={{ margin: "15px" }} />
      <MainBox>
        <Cards component={"form"} onSubmit={handleUserChangePassword}>
          <Typography variant="h6" textAlign="center" mb={2}>
            Reset Password
          </Typography>
          <FormControl variant="outlined" fullWidth required>
            <TextField
              id={addForm.oldPassword}
              variant="outlined"
              label="Old Password"
              name="oldPassword"
              value={addForm.oldPassword}
              onChange={handleFormChange}
              required
            />
          </FormControl>

          <FormControl variant="outlined" fullWidth required sx={{ mt: 2 }}>
            <TextField
              id={addForm.newPassword}
              variant="outlined"
              label="New Password"
              name="newPassword"
              value={addForm.newPassword}
              onChange={handleFormChange}
              required
            />
          </FormControl>

          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <Stack spacing={2} direction="row">
              <Button variant="contained" color="error">
                Cancel
              </Button>

              <LoadingButton
                loader={loader}
                variant="contained"
                sx={{
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </Cards>
      </MainBox>
    </div>
  );
};

export default PasswordReset;
