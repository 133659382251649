import React, { useContext, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  TextField,
  Typography,
  styled,
  InputAdornment,
  IconButton,
  TablePagination,
  Box,
} from "@mui/material";

import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import AddDesignation from "../HumanResource/AddDesignation";
import DeleteModal from "../Academic/DeleteModal";
import { urls as url } from "../../../services/urlConstant";
import { del, get, post, put } from "../../../services/apis";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import ShowComponent from "../../ShowComponent";

const SearchField = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down(900)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(685)]: {
    justifyContent: "flex-start",
  },
}));

const Designation = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [schoolDesignation, setSchoolDesignation] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateDesignation, setUpdateDesignation] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ designation: "asc", note: "asc" });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };
  let [loader, setLoader] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    setUpdateDesignation(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        urls.designation.delDesignation,
        id,
        {},
        { roleFunction: "designation" }
      );
      if (res.data.success) {
        const filteredSection = schoolDesignation.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolDesignation(filteredSection);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, data) => {
    setLoader(true);

    try {
      e.preventDefault();
      const res = await post(urls.designation.postDesignation, data, {
        headers: { roleFunction: "designation" },
      });
      if (res.data.success) {
        setSchoolDesignation([...schoolDesignation, res.data.data]);
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.designation.putDesignation, id, data, {
        headers: { roleFunction: "designation" },
      });
      if (response.data.success) {
        setSchoolDesignation(
          schoolDesignation.map((e) => {
            if (e._id === response.data.data._id) {
              return response.data.data;
            } else {
              return e;
            }
          })
        );
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getDesignationById = schoolDesignation.find(
        (item) => item._id === id
      );
      setUpdateDesignation(getDesignationById);
    } catch (error) {
      console.log(error);
    }
  };
  const getSchoolDesignation = async () => {
    try {
      const res = await get(urls.designation.getAllDesignation, {
        headers: { roleFunction: "designation" },
      });
      setSchoolDesignation([...res.data.data]);
      setSearchFilter([...res.data.data]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSchoolDesignation();
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolDesignation.filter((ele) =>
          ele.designation.toLowerCase().includes(value.toLowerCase().trim())
        )
      );
    } else {
      setSearchFilter([]);
    }
  };

  const designationSorted = () => {
    if (search) {
      if (sort.designation === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.designation.localeCompare(a.designation)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, designation: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.designation.localeCompare(b.designation)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, designation: "asc" });
      }
    } else {
      if (sort.designation === "asc") {
        let sortedList = [
          ...schoolDesignation.sort((a, b) =>
            b.designation.localeCompare(a.designation)
          ),
        ];
        setSchoolDesignation(sortedList);
        setSort({ ...sort, designation: "des" });
      } else {
        let sortedList = [
          ...schoolDesignation.sort((a, b) =>
            a.designation.localeCompare(b.designation)
          ),
        ];
        setSchoolDesignation(sortedList);
        setSort({ ...sort, designation: "asc" });
      }
    }
  };

  useEffect(() => {
    setSearch("");
  }, []);

  return (
    <>
      <Card style={{ margin: 15, padding: "22px 0px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <Typography
              style={{
                color: "red",

                display: "inline-block",
              }}>
              Designation:
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <Box sx={{ margin: "15px" }}>
        <SearchField container spacing={1} my={2}>
          <Grid item xs="auto">
            <TextField
              size="small"
              value={search}
              autoComplete="no"
              onChange={handleSearch}
              label="Search Designation"
              variant="outlined"
              placeholder="Enter Designation"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </SearchField>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">
                    Designation
                    <span
                      onClick={designationSorted}
                      style={{ cursor: "pointer" }}>
                      {sort.designation === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Note</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell align="center">
                            {searchPage * searchRowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}>
                            {row.designation}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}>
                            {row.note}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}>
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="designation"
                                action="update">
                                <Tooltip title="Edit" disableInteractive>
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() => handleEdit(row._id)}>
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="designation"
                                action="delete">
                                {!row.canDelete ? null : (
                                  <Tooltip title="Delete" disableInteractive>
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => setDeleteModal(row._id)}>
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </ShowComponent>
                            </div>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDelete}
                              id={row._id}
                              setDeleteModal={setDeleteModal}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                : schoolDesignation
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}>
                            {row.designation}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}>
                            {row.note}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}>
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="designation"
                                action="update">
                                <Tooltip title="Update" disableInteractive>
                                  <IconButton
                                    size="small"
                                    style={{ color: "#1b3779" }}
                                    onClick={() => handleEdit(row._id)}>
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="designation"
                                action="delete">
                                {!row.canDelete ? null : (
                                  <Tooltip title="Delete" disableInteractive>
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => setDeleteModal(row._id)}>
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </ShowComponent>
                            </div>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDelete}
                              id={row._id}
                              setDeleteModal={setDeleteModal}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!schoolDesignation.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : schoolDesignation.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </Box>

      <ShowComponent roleFunction="designation" action="add">
        <div className="add-icon">
          <AddDesignation
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateDesignation={updateDesignation}
          />
        </div>
      </ShowComponent>
    </>
  );
};

export default Designation;
