import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useContext } from "react";
import css from "../../../styles/fees/ClassMap.module.css";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
  Card,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  styled,
  InputAdornment,
  IconButton,
  TablePagination,
} from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import DeleteModal from "../Academic/DeleteModal";
import { urls as url } from "../../../services/urlConstant";
import { get, post, put, del } from "../../../services/apis";
import AddExamSchedule from "./AddExamSchedule";
import "../../../styles/components/Exam/Exam.scss";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import style from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { objHas } from "../../../utils/utils";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import ShowComponent from "../../ShowComponent";

const ClassBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down(900)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(685)]: {
    justifyContent: "flex-start",
  },
}));

dayjs.extend(localeData);

const styles = {
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExamSchedule = () => {
  const [value, setValue] = useState(0);
  const [schoolExamTerm, setSchoolExamTerm] = useState([]);
  const { selectedSetting } = useContext(SettingContext);

  //ExamSchedule
  const [examSchedule, setExamSchedule] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateExamSchedule, setUpdateExamSchedule] = useState(undefined);
  const [examTitles, setExamTitles] = useState([]);
  const [exam, setExam] = useState("");
  const [classSchedule, setClassSchedule] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectReceipt, setSelectReceipt] = useState("");
  const [formData, setFormData] = useState({});
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  let [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [sort, setSort] = useState({
    examTitle: "asc",
    examType: "asc",
    subjectName: "asc",
    minMarks: "asc",
    maxMarks: "asc",
  });

  const sortByExamTitle = (e) => {
    console.log("ExamTitle");
    if (sort.examTitle == "asc") {
      let sortedList = [
        ...schoolExamTerm.sort((a, b) =>
          b.examTitle.localeCompare(a.examTitle)
        ),
      ];

      setSchoolExamTerm(sortedList);
      setSort({ ...sort, examTitle: "des" });
    } else {
      let sortedList = [
        ...schoolExamTerm.sort((a, b) =>
          a.examTitle.localeCompare(b.examTitle)
        ),
      ];
      setSchoolExamTerm(sortedList);
      setSort({ ...sort, examTitle: "asc" });
    }
  };

  const sortByExamType = (e) => {
    console.log("ExamType");
    if (sort.examType == "asc") {
      let sortedList = [
        ...schoolExamTerm.sort((a, b) => b.examType.localeCompare(a.examType)),
      ];

      setSchoolExamTerm(sortedList);
      setSort({ ...sort, examType: "des" });
    } else {
      let sortedList = [
        ...schoolExamTerm.sort((a, b) => a.examType.localeCompare(b.examType)),
      ];
      setSchoolExamTerm(sortedList);
      setSort({ ...sort, examType: "asc" });
    }
  };

  const sortBySubjectName = () => {
    console.log("SubjectName");
    if (search) {
      if (sort.subjectName === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.subject.subjectName.localeCompare(a.subject.subjectName)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, sub: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.subject.subjectName.localeCompare(b.subject.subjectName)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, subjectName: "asc" });
      }
    } else {
      if (sort.subjectName === "asc") {
        let sortedList = [
          ...examSchedule.sort((a, b) =>
            b.subject.subjectName.localeCompare(a.subject.subjectName)
          ),
        ];
        setExamSchedule(sortedList);
        setSort({ ...sort, subjectName: "des" });
      } else {
        let sortedList = [
          ...examSchedule.sort((a, b) =>
            a.subject.subjectName.localeCompare(b.subject.subjectName)
          ),
        ];
        setExamSchedule(sortedList);
        setSort({ ...sort, subjectName: "asc" });
      }
    }
  };

  const sortByMaxMark = () => {
    console.log("MaxMark");
    if (search) {
      if (sort.maxMarks === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.maxMarks - a.maxMarks),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, sub: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.maxMarks - b.maxMarks),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, maxMarks: "asc" });
      }
    } else {
      if (sort.maxMarks === "asc") {
        let sortedList = [
          ...examSchedule.sort((a, b) => b.maxMarks - a.maxMarks),
        ];
        setExamSchedule(sortedList);
        setSort({ ...sort, maxMarks: "des" });
      } else {
        let sortedList = [
          ...examSchedule.sort((a, b) => a.maxMarks - b.maxMarks),
        ];
        setExamSchedule(sortedList);
        setSort({ ...sort, maxMarks: "asc" });
      }
    }
  };

  const sortByMinMark = () => {
    console.log("MaxMark");
    if (search) {
      if (sort.minMarks === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.minMarks - a.minMarks),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, sub: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.minMarks - b.minMarks),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, minMarks: "asc" });
      }
    } else {
      if (sort.minMarks === "asc") {
        let sortedList = [
          ...examSchedule.sort((a, b) => b.minMarks - a.minMarks),
        ];
        setExamSchedule(sortedList);
        setSort({ ...sort, minMarks: "des" });
      } else {
        let sortedList = [
          ...examSchedule.sort((a, b) => a.minMarks - b.minMarks),
        ];
        setExamSchedule(sortedList);
        setSort({ ...sort, minMarks: "asc" });
      }
    }
  };

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const getSchoolExam = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "examSchedule" },
        });
        if (res && res.data && res.data.data) {
          setSchoolExamTerm([...res.data.data]);
        }
      } catch (error) {}
    };
    getSchoolExam();
  }, [selectedSetting]);
  useEffect(() => {
    const getExamTitle = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "examSchedule" },
        });
        if (res.data.success && res.data.data.length) {
          setFormData((prev) => ({
            ...prev,
            exam: res.data.data[0]._id,
          }));
          setExamTitles(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getExamTitle();
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "examSchedule" },
        });
        if (res.data.success && res.data.data.length) {
          setFormData((prev) => ({
            ...prev,
            schoolClass: res.data.data[0]._id,
          }));
        }
        setClasses(res.data.data);
        if (res.data.length > 0) {
          setSelectReceipt(res.data[0]?._id);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getSchoolExam = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm, {
          headers: { roleFunction: "examSchedule" },
        });
        setSchoolExamTerm([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolExam();
  }, [selectedSetting]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateExamSchedule(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        urls.examSchedule.delExamSchedule,
        id,
        {},
        { roleFunction: "examSchedule" }
      );
      if (res.data.success) {
        const filteredExamSchedule = examSchedule.filter(
          (item) => item._id !== res.data.data._id
        );
        setExamSchedule(filteredExamSchedule);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, data) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await post(urls.examSchedule.postExamSchedule, data, {
        headers: { roleFunction: "examSchedule" },
      });
      if (res.data.success) {
        setExamSchedule([res.data.data, ...examSchedule]);
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const resupdate = await put(urls.examSchedule.putExamSchedule, id, data, {
        headers: { roleFunction: "examSchedule" },
      });

      const res = await get(urls.examSchedule.getAllExamSchedule, {
        headers: { roleFunction: "examSchedule" },
        params: {
          search: {
            class: formData.schoolClass,
            exam: formData.exam,
          },
        },
      });
      if (resupdate.data.success) {
        setExamSchedule([...res.data.data]);
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getExamScheduleById = examSchedule.find((item) => item._id === id);
      setUpdateExamSchedule(getExamScheduleById);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "examSchedule" },
        });
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);
  useEffect(() => {
    const getExamSchedule = async () => {
      try {
        if (formData.schoolClass && formData.exam) {
          const res = await get(urls.examSchedule.getAllExamSchedule, {
            headers: { roleFunction: "examSchedule" },
            params: {
              search: {
                class: formData.schoolClass,
                exam: formData.exam,
              },
            },
          });
          console.log("reach", res.data.data);
          setExamSchedule([...res.data.data]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getExamSchedule();
  }, [formData.schoolClass, formData.exam]);

  const handleScheduleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await get(urls.examSchedule.getAllExamSchedule, {
        headers: { roleFunction: "examSchedule" },
        params: {
          search: {
            class: formData.schoolClass,
            exam: formData.exam,
          },
        },
      });
      setExamSchedule([...res.data.data]);
    } catch (error) {
      console.log(error);
    }
  };
  console.log("Exam schedules========", examSchedule);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        examSchedule.filter(
          (ele) =>
            ele.subject.subjectName.toLowerCase().includes(value) ||
            ele.examDate.toLowerCase().includes(value) ||
            ele.examDate.toLowerCase().includes(value) ||
            ele.startTime.toLowerCase().includes(value)
        )
      );
    } else {
      setSearchFilter([]);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Exam Schedule" />

      <div style={{ margin: "15px 0" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Exam List" {...a11yProps(0)} />
              <Tab label="Schedule List" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* <div className={css.funcContainer}>
              <ClassBox>
                <FormControl
                  sx={{
                    width: "100px",
                  }}
                >
                  <InputLabel>Class</InputLabel>
                  <Select
                    size="small"
                    value={selectReceipt || ""}
                    onChange={(e) => setSelectReceipt(e.target.value)}
                    label="Class"
                  >
                    {classes.map((row) => (
                      <MenuItem value={row._id} key={row._id}>
                        {row.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ClassBox>
            </div> */}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3} lg={3} m="10px 0px">
                <FormControl fullWidth size="small">
                  <InputLabel>Class</InputLabel>
                  <Select
                    size="small"
                    value={selectReceipt || ""}
                    onChange={(e) => setSelectReceipt(e.target.value)}
                    label="Class"
                  >
                    {classes.map((row) => (
                      <MenuItem value={row._id} key={row._id}>
                        {row.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Exam Title</span>
                      <span
                        onClick={sortByExamTitle}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.examTitle === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Type</span>
                      <span
                        onClick={sortByExamType}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.examType === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schoolExamTerm
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.examTitle}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.examType}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {!schoolExamTerm.length && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={schoolExamTerm.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                my: 1,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <form onSubmit={handleScheduleSubmit}>
              <Card style={{ margin: "15px 0", padding: 10 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FormControl size="small" fullWidth required>
                      <InputLabel id="demo-simple-select-filled-label">
                        Class
                      </InputLabel>
                      <Select
                        label="Class"
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        name="schoolClass"
                        value={formData.schoolClass || ""}
                        onChange={handleClassChange}
                      >
                        {classes &&
                          classes.map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FormControl size="small" required fullWidth>
                      <InputLabel id="demo-simple-select-filled-label">
                        Exam
                      </InputLabel>
                      <Select
                        label="Exam"
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        name="exam"
                        value={formData.exam || ""}
                        onChange={handleClassChange}
                      >
                        {examTitles &&
                          examTitles.map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.examTitle}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
            </form>
            <div className={style.searchInputContainer}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs="auto">
                  <TextField
                    size="small"
                    value={search}
                    onChange={handleSearch}
                    label="Search Subject"
                    variant="outlined"
                    placeholder="Search..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" type="submit">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Subject</span>
                      <span
                        onClick={sortBySubjectName}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.subjectName === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Date</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Time</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Max Marks</span>
                      <span
                        onClick={sortByMaxMark}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.maxMarks === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Min Marks</span>
                      <span
                        onClick={sortByMinMark}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.minMarks === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search
                    ? searchFilter
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.subject.subjectName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.examDate}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {dayjs(Date.now())
                                .set("hour", row.startTime.split(":")[0])
                                .set("minutes", row.startTime.split(":")[1])
                                .format("h:mm A")}
                              -
                              {dayjs(Date.now())
                                .set("hour", row.endTime.split(":")[0])
                                .set("minutes", row.endTime.split(":")[1])
                                .format("h:mm A")}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.maxMarks}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.minMarks}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              <div className="edit-delete">
                                <ShowComponent
                                  roleFunction="examSchedule"
                                  action="update"
                                >
                                  <Tooltip title="Update" disableInteractive>
                                    <IconButton
                                      size="small"
                                      sx={{ color: "#1b3779" }}
                                      onClick={() => handleEdit(row._id)}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <ShowComponent
                                  roleFunction="examSchedule"
                                  action="delete"
                                >
                                  <Tooltip title="Delete" disableInteractive>
                                    <IconButton
                                      color="error"
                                      size="small"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                              </div>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDelete}
                                id={row._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                    : examSchedule
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {objHas(row, "subject.subjectName", "")}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.examDate}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {dayjs(Date.now())
                                .set("hour", row.startTime.split(":")[0])
                                .set("minutes", row.startTime.split(":")[1])
                                .format("h:mm A")}
                              -
                              {dayjs(Date.now())
                                .set("hours", row.endTime.split(":")[0])
                                .set("minutes", row.endTime.split(":")[1])
                                .format("h:mm A")}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.maxMarks}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {row.minMarks}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              <div className="edit-delete">
                                <ShowComponent
                                  roleFunction="examSchedule"
                                  action="update"
                                >
                                  <Tooltip title="Update">
                                    <IconButton
                                      size="small"
                                      sx={{ color: "#1b3779" }}
                                      onClick={() => handleEdit(row._id)}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <ShowComponent
                                  roleFunction="examSchedule"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      color="error"
                                      size="small"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                              </div>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDelete}
                                id={row._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                </TableBody>
              </Table>
              {!examSchedule.length && !search.trim() && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
              {search.trim() && !searchFilter.length && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={search ? searchFilter.length : examSchedule.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                my: 1,
              }}
            />
            <div className="add-icon">
              <AddExamSchedule
                loader={loader}
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleUpdate={handleUpdate}
                updateExamSchedule={updateExamSchedule}
              />
            </div>
          </TabPanel>
        </Box>
      </div>
    </Wrapper>
  );
};
export default ExamSchedule;
