import React, { useContext, useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import css from "../../../styles/fees/ClassMap.module.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Add } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SectionSubHeader from "../../SectionSubHeader";
import appendStringToUrls from "../../../utils/appendString";

import dayjs from "dayjs";
import SettingContext from "../../../context/SettingsContext";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const ClassMap = ({ selectedClassMap }) => {
  const { selectedSetting } = useContext(SettingContext);
  const [selectReceipt, setSelectReceipt] = useState(selectedClassMap || "");
  const [installmentType, setInstallMentType] = useState([
    {
      id: 1,
      label: "Monthly",
    },
    {
      id: 2,
      label: "Quaterly",
    },
    {
      id: 3,
      label: "Half-Yearly",
    },
    {
      id: 4,
      label: "Yearly",
    },
    {
      id: 4,
      label: "Others",
    },
  ]);

  const [receipts, setReceipts] = useState([]);
  const [classMaps, setClassMaps] = useState([]);
  const [addDialog, setAddDialog] = useState(false);
  const [addForm, setAddForm] = useState({});
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [addDate, setAddDate] = useState(dayjs());
  const [editClassMap, setEditClassMap] = useState("");
  const [installments, setInstallments] = useState([]);
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // =======================

  useEffect(() => {
    const init = async () => {
      try {
        const { data: allReceipts, status } = await get(
          `${urls.recepitBook.getAll}`,
          {
            headers: { roleFunction: "receiptBookItem" },
          }
        );
        if (status > 199 && status < 299) {
          setReceipts(allReceipts.data);
          console.log(allReceipts);
          if (allReceipts.data.length > 0) {
            setSelectReceipt(
              selectReceipt ? selectReceipt : allReceipts.data[0]._id
            );
          }
        }
      } catch (error) {}
    };

    const getSchoolClasses = async () => {
      try {
        const { data: schoolClassesList, status } = await get(
          `${urls.class.getAllClass}`,
          {
            headers: { roleFunction: "receiptBookItem" },
          }
        );
        if (status > 199 && status < 299) {
          setSchoolClasses(schoolClassesList.data);
        }
      } catch (error) {}
    };
    init();
    getSchoolClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    if (selectReceipt) {
      const fetchClassMaps = async () => {
        try {
          await getClassMaps();
        } catch (error) {}
      };
      fetchClassMaps();
    }
  }, [selectReceipt, selectedSetting._id]);

  const getClassMaps = async () => {
    try {
      const { data: classMapList, status } = await get(
        `${urls.classMap.list}${selectReceipt}`,
        {
          headers: { roleFunction: "receiptBookItem" },
        }
      );
      if (status > 199 && status < 299) {
        setClassMaps(classMapList.data);
        return {
          data: classMapList,
          status,
        };
      }
    } catch (error) {
      return error;
    }
  };

  const handleCloseAddDialog = () => {
    setAddForm({});
    setEditClassMap("");
    setInstallments([]);
    setAddDialog(false);
  };

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;

    if (name == "fee") {
      setAddForm((prev) => ({
        ...prev,
        [name]: parseInt(value),
      }));
    } else if (name == "others") {
      setAddForm((prev) => ({
        ...prev,
        [name]: Math.ceil(value),
      }));
    } else {
      setAddForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      if (!editClassMap) {
        const { status } = await post(
          `${urls.classMap.create}${selectReceipt}`,
          {
            classId: addForm.schoolClass,
            dueDate: addDate.toDate(),
            fee: addForm.fee,
            installMents: installments,
            others: addForm.others,
            installmentType: addForm.installmentType,
            paymentStyle: installments.length ? "Installments" : "All at once",
          },
          {
            headers: { roleFunction: "receiptBookItem" },
          }
        );
        if (status > 199 && status < 299) {
          await getClassMaps();
          handleCloseAddDialog();
        }
      } else {
        const { status } = await put(
          `${urls.classMap.update}${editClassMap}`,
          "",
          {
            classId: addForm.schoolClass,
            dueDate: addDate.toDate(),
            fee: addForm.fee,
            installmentType: addForm.installmentType,
            installMents: installments,
            others: addForm.others,
            paymentStyle:
              installments.length > 0 ? "Installments" : "All at once",
          },
          {
            headers: { roleFunction: "receiptBookItem" },
          }
        );
        if (status > 199 && status < 299) {
          await getClassMaps();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleClassMapEdit = (id, data) => {
    setEditClassMap(id);
    setAddForm({
      schoolClass: data.class._id,
      fee: data.fee,
      installmentType: data.installmentType,
    });
    setAddDate(dayjs(data.dueDate));
    setInstallments(data?.installMents);
    handleOpenAddDialog();
  };

  const handleAddInstallments = () => {
    if (!addForm.installmentType || !addForm.fee || !addForm.schoolClass)
      return;
    let installments = [];

    if (addForm.installmentType === "Monthly") {
      const monthlyAmount = Math.floor(addForm.fee / 12);
      const missing = addForm.fee - monthlyAmount * 12;

      console.log(missing, "missing");
      installments = Array.from({ length: 12 }).map((v, i) => ({
        id: i + 1,
        amount: monthlyAmount,
        missing: missing,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Quaterly") {
      const quarterlyAmount = Math.floor(addForm.fee / 4);
      const missing = addForm.fee - quarterlyAmount * 4;

      installments = Array.from({ length: 4 }).map((v, i) => ({
        id: i + 1,
        amount: quarterlyAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Half-Yearly") {
      const halfYearlyAmount = Math.floor(addForm.fee / 2);
      const missing = addForm.fee - halfYearlyAmount * 2;

      installments = Array.from({ length: 2 }).map((v, i) => ({
        id: i + 1,
        amount: halfYearlyAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Yearly") {
      installments = Array.from({ length: 1 }).map((v, i) => ({
        id: i,
        amount: addForm.fee,
        dueDate: dayjs(),
      }));
    } else if (addForm.installmentType === "Others") {
      const othersAmount = Math.floor(addForm.fee / addForm.others);
      const missing = addForm.fee - othersAmount * addForm.others;

      installments = Array.from({ length: addForm.others }).map((v, i) => ({
        id: i + 1,
        amount: othersAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else {
      setInstallments([]);
      return;
    }
    setInstallments(installments);
  };

  useEffect(() => {
    handleAddInstallments();
  }, [
    addForm.installmentType,
    addForm.others,
    addForm.fee,
    addForm.schoolClass,
  ]);

  const handleInstallmentChange = (val, key, changeIndex) => {
    setInstallments((prev) =>
      prev.map((installment, index) => {
        if (index === changeIndex) {
          return key === "amount"
            ? { ...installment, [key]: parseInt(val) }
            : key === "dueDate"
            ? { ...installment, [key]: val }
            : installment;
        } else if (index > changeIndex) {
          let diffInMonths = index - changeIndex;
          if (addForm.installmentType === "Quaterly") {
            diffInMonths = diffInMonths * 3;
          } else if (addForm.installmentType === "Half-Yearly") {
            diffInMonths = diffInMonths * 6;
          } else if (addForm.installmentType === "Others") {
            diffInMonths = diffInMonths * 1;
          }
          const updatedDueDate = dayjs(val)
            .add(diffInMonths, "month")
            .format("YYYY-MM-DD");
          return { ...installment, dueDate: updatedDueDate };
        } else {
          return installment;
        }
      })
    );
  };

  // const handleInstallmentChange = (val, key, changeIndex) => {
  //   setInstallments((prev) =>
  //     prev.map((installment, index) =>
  //       index === changeIndex
  //         ? key == "amount"
  //           ? { ...installment, [key]: parseInt(val) }
  //           : { ...installment, [key]: val }
  //         : installment
  //     )
  //   );
  // };

  const handleStatusChange = async (id) => {
    try {
      const { status } = await put(
        `${urls.classMap.status}${id}`,
        "",
        {},
        { headers: { roleFunction: "receiptBookItem" } }
      );
      if (status > 199 && status < 299) {
        await getClassMaps();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveInstallment = (index) => {
    const updatedInstallments = [...installments];
    updatedInstallments.splice(index, 1);
    setInstallments(updatedInstallments);
  };

  return (
    <div>
      <div className={css.funcContainer}>
        <FormControl
          // sx={{
          //   width: "16%",
          //   m: 1,
          // }}
          // className={css.dialog_select}
          sx={{ width: { xs: "100%", sm: "100%", md: "30%", lg: "20%" } }}
          fullWidth
          size="small"
        >
          <InputLabel>Select Receipt</InputLabel>
          <Select
            fullWidth
            value={selectReceipt || ""}
            onChange={(e) => setSelectReceipt(e.target.value)}
            label="Select Receipt"
          >
            {receipts.map((receipt) => (
              <MenuItem value={receipt._id} key={receipt._id}>
                {receipt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleOpenAddDialog} size="small" variant="contained">
          <Add /> Add class map
        </Button>
      </div>

      <Grid
        container
        my={2}
        sx={{
          alignSelf: "center",
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Receipt</InputLabel>
              <Select
                fullWidth
                value={selectReceipt || ""}
                onChange={(e) => setSelectReceipt(e.target.value)}
                label="Select Receipt"
              >
                {receipts.map((receipt) => (
                  <MenuItem value={receipt._id} key={receipt._id}>
                    {receipt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <ShowComponent roleFunction="receiptBookItem" action="add">
            <Button
              onClick={handleOpenAddDialog}
              size="small"
              variant="contained"
            >
              <Add /> Add class map
            </Button>
          </ShowComponent>
        </Grid>
        <ShowComponent roleFunction="receiptBookItem" action="add">
          <Button
            onClick={handleOpenAddDialog}
            size="small"
            variant="contained"
          >
            <Add /> Add class map
          </Button>
        </ShowComponent>
        {/* </Grid> */}
      </Grid>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Class
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Fee
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Due Date
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Installments
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classMaps
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((classMap, index) => (
                <TableRow
                  key={classMap._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {classMap.class?.className}
                  </TableCell>
                  <TableCell align="center">{classMap.fee}</TableCell>
                  <TableCell align="center">
                    {dayjs(classMap.dueDate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {classMap.installMents?.length}
                  </TableCell>
                  <TableCell align="center">
                    <div className={css.tableActions}>
                      <ShowComponent
                        roleFunction="receiptBookItem"
                        action="update"
                      >
                        <Button
                          onClick={() =>
                            handleClassMapEdit(classMap._id, classMap)
                          }
                          size="small"
                          variant="contained"
                        >
                          edit
                        </Button>
                      </ShowComponent>
                      <ShowComponent
                        roleFunction="receiptBookItem"
                        action="update"
                      >
                        <Button
                          color={classMap.activated ? "success" : "error"}
                          onClick={() => handleStatusChange(classMap._id)}
                          size="small"
                          variant="contained"
                        >
                          {classMap.activated ? "active" : "inactive"}
                        </Button>
                      </ShowComponent>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!classMaps.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={classMaps.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* === Add/Update Class Map ============= */}
      <Dialog
        fullScreen={fullScreen}
        open={addDialog}
        onClose={handleCloseAddDialog}
      >
        <form onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {editClassMap ? "Update" : "Add"} Class Map
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Class</InputLabel>
                  <Select
                    size="small"
                    name="schoolClass"
                    required
                    value={addForm.schoolClass || ""}
                    onChange={handleAddForm}
                    label="Class"
                  >
                    {schoolClasses.map((schoolClass) => (
                      <MenuItem key={schoolClass._id} value={schoolClass._id}>
                        {schoolClass.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Fee"
                  type="number"
                  required
                  name="fee"
                  value={addForm.fee || ""}
                  onChange={handleAddForm}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                  <DatePicker
                    fullWidth
                    label="Due Date"
                    inputFormat="DD-MM-YYYY"
                    value={addDate}
                    onChange={(newDate) => setAddDate(newDate)}
                    renderInput={(params) => (
                      <TextField required {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Installment Type</InputLabel>
                  <Select
                    size="small"
                    name="installmentType"
                    value={addForm.installmentType || ""}
                    onChange={handleAddForm}
                    label="Installment Type"
                  >
                    {installmentType.map((installmentType) => (
                      <MenuItem
                        key={installmentType.id}
                        value={installmentType.label}
                      >
                        {installmentType.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {addForm.installmentType == "Others" && (
                  <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    label="Number of installments"
                    size="small"
                    name="others"
                    type="number"
                    value={addForm.others || ""}
                    onChange={handleAddForm}
                  />
                )}
              </Grid>
              {/* <Grid item xs={12} md={12} lg={12}> */}
              {installments.map((installment, index) => (
                <React.Fragment key={index}>
                  {/* <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ paddingTop: "0px" }}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <Button onClick={() => handleRemoveInstallment(index)}>
                        <RemoveIcon />
                      </Button>
                    </Box>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label={`installment ${index + 1}`}
                      value={installment.amount}
                      size="small"
                      enabled={editClassMap}
                      onChange={(e) =>
                        handleInstallmentChange(e.target.value, "amount", index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD-MM-YYYY"
                        enabled={editClassMap}
                        label="Due Date"
                        form
                        value={installment.dueDate}
                        onChange={(newValue) =>
                          handleInstallmentChange(newValue, "dueDate", index)
                        }
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            enabled={editClassMap}
                            {...params}
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "flex-end",
                        lg: "flex-end",
                      },
                    }}
                    style={{ paddingTop: "0px" }}
                  >
                    <Button onClick={() => handleRemoveInstallment(index)}>
                      <RemoveIcon />
                    </Button>
                  </Grid> */}
                </React.Fragment>
              ))}
              {/* </Grid> */}
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                gap={2}
              >
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  onClick={handleCloseAddDialog}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={loader}
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                    color: "#fff",
                  }}
                >
                  {editClassMap ? "update" : "add"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      {/* =========================== */}
    </div>
  );
};

export default ClassMap;
