import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  Typography,
  IconButton,
  useTheme,
  InputAdornment,
  Dialog,
  useMediaQuery,
  Tooltip,
  TablePagination,
  TableContainer,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { urls } from "../../services/urlConstant";
import { get, post, put } from "../../services/apis";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import { Link } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { urls as url } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ShowComponent from "../ShowComponent";
import { toast } from "react-toastify";
import ModalDialog from "@mui/joy/ModalDialog";
import deleteIcon from "../../assets/Images/deleteIcon.png";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 680,
  height: 500,
  overflowY: "Scroll",
  bgcolor: "background.paper",
  p: 2,
  borderRadius: "5px",
};
const InputLabels = styled(InputLabel)(() => ({
  fontSize: "12px",
  fontWeight: 580,
  color: "#616161",
  paddingLeft: "10px",
}));

const RadioButtons = styled(Radio)(() => ({
  marginTop: "-10px",
}));

const Boxes = styled(Box)(() => ({
  display: "flex",
}));

const PreExams = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [academicYears, setAcademicYears] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [additionalInstructions, setAdditionalInstructions] = useState([""]);
  const [classes, setClasses] = useState([]);
  const [formData, setFormData] = useState({});
  const [addForm, setAddForm] = useState({});
  const [markForm, setMarkForm] = useState({});
  const [negativeMarking, setNegativeMarking] = useState(false);
  const [submiload, setSubmitload] = useState(false);
  const [editload, setEditLoad] = useState(false);
  const [findLoad, setFindLoad] = useState(false);
  const [datas, setDatas] = useState([]);
  const theme = useTheme();
  const [deletes, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState("");
  const [showquiz, setShowQuiz] = useState({});
  const [openQuiz, setOpenQuiz] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const handleOpenQuiz = (data) => {
    setShowQuiz(data);
    setOpenQuiz(true);
  };
  const handleCloseQuiz = () => setOpenQuiz(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleCheckboxChange = (event) => {
    setNegativeMarking(event.target.checked);
  };

  const [quizData, setQuizData] = useState([
    {
      question: "",
      options: ["", "", "", ""],
      correctOption: "",
    },
  ]);

  const handleAddQuizs = () => {
    setQuizData([
      ...quizData,
      {
        question: "",
        options: ["", "", "", ""],
        correctOption: "",
      },
    ]);
  };
  const handleRemoveQuestion = (questionIndex) => {
    setQuizData((prevQuizData) =>
      prevQuizData.filter((_, index) => index !== questionIndex)
    );
  };

  const handleQuestionChange = (e, i) => {
    setQuizData(
      quizData.map((q, index) =>
        index === i ? { ...q, question: e.target.value } : q
      )
    );
  };
  const handleOptionChange = (e, questionIndex, optionIndex) => {
    let questionToUpdate = quizData.filter((q, i) => i === questionIndex)[0];
    let options = questionToUpdate.options;
    let newOptions = options.map((o, i) =>
      i === optionIndex ? e.target.value : o
    );
    setQuizData(
      quizData.map((q, i) =>
        i === questionIndex ? { ...q, options: newOptions } : q
      )
    );
  };
  const handleEditOptionChange = (e, questionIndex, optionIndex) => {
    let questionToUpdate = quizData.filter((q, i) => i === questionIndex)[0];
    let options = questionToUpdate.options;
    let newOptions = options.map((o, i) =>
      i === optionIndex ? { ...o, value: e.target.value } : o
    );
    setQuizData(
      quizData.map((q, i) =>
        i === questionIndex ? { ...q, options: newOptions } : q
      )
    );
  };
  const handleSetCorrectOption = (o, questionIndex) => {
    setQuizData(
      quizData.map((q, i) =>
        i === questionIndex ? { ...q, correctOption: o } : q
      )
    );
  };
  const handleEditSetCorrectOption = (o, questionIndex) => {
    setQuizData(
      quizData.map((q, i) =>
        i === questionIndex ? { ...q, correctOption: o } : q
      )
    );
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnChangeForm = (e) => {
    const { name, value } = e.target;
    setAddForm({
      ...addForm,
      [name]: value,
    });
  };
  const hanleMarkChange = (e) => {
    const { name, value } = e.target;
    setMarkForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "preaddmissionExam" },
        });
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "preaddmissionExam" },
        });

        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCLickEditOpen = () => {
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditId(null);
    setEditOpen(false);
    setQuizData([
      {
        question: "",
        options: ["", "", "", ""],
        correctOption: "",
      },
    ]);
    setAddForm({});
    setMarkForm({});
    setNegativeMarking(false);
    setAdditionalInstructions([]);
  };
  const handleClose = () => {
    setEditId(null);
    setOpen(false);
    setQuizData([
      {
        question: "",
        options: ["", "", "", ""],
        correctOption: "",
      },
    ]);
    setAddForm({});
    setMarkForm({});
    setNegativeMarking(false);
    setAdditionalInstructions([]);
  };
  console.log(additionalInstructions, "dditionalInstructions");
  const handleAdditionalInstructionChange = (e, index) => {
    const updatedInstructions = [...additionalInstructions];
    updatedInstructions[index] = e.target.value;
    setAdditionalInstructions(updatedInstructions);
  };

  const handleDeleteInstruction = (index) => {
    const updatedInstructions = [...additionalInstructions];
    updatedInstructions.splice(index, 1);
    setAdditionalInstructions(updatedInstructions);
  };

  const handleAddInstruction = () => {
    setAdditionalInstructions([...additionalInstructions, ""]);
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    setFindLoad(true);
    try {
      const res = await get(urls.preExam.get, {
        headers: { roleFunction: "preaddmissionExam" },
      });

      const filteredData = res.data.data.filter((exam) => {
        return (
          exam.academicYear?._id == formData.academicYear &&
          exam.class?._id == formData.schoolClass
        );
      });
      setDatas(filteredData);
      console.log(filteredData, "mmmmmmmmmmmmmmmmmmmmmmmmmmm");
    } catch (error) {
      console.log(error);
    }
    setFindLoad(false);
  };

  const updateList = async (e) => {
    try {
      const res = await get(urls.preExam.get, {
        headers: { roleFunction: "preaddmissionExam" },
      });

      const filteredData = res.data.data.filter((exam) => {
        return (
          exam.academicYear._id == formData.academicYear &&
          exam.class._id == formData.schoolClass
        );
      });
      setDatas(filteredData);
      // console.log(filteredData, "mmmmmmmmmmmmmmmmmmmmmmmmmmm");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const quiz = quizData.map((q, index) => {
        return {
          question: q.question,
          options: [
            { value: q.options[0] },
            { value: q.options[1] },
            { value: q.options[2] },
            { value: q.options[3] },
          ],

          correctOption: q.correctOption,
        };
      });
      if (quiz.filter((q) => !q.question || !q.options).length)
        return toast.error("Question cannot be empty");
      if (quiz.some((q) => q.options.some((option) => option.name === "")))
        return toast.error("Option cannot be empty");

      if (quiz.filter((q) => !q.correctOption).length)
        return toast.error("Please select a correct option");
      setSubmitload(true);
      let data = {
        academicYear: addForm.academicYear,
        classId: addForm.schoolClass,
        examName: markForm.examName,
        passingPercentage: markForm.passingMark,
        quiz: quiz,
        negativeMarking: negativeMarking,
        negativeMarkingPerQuestion: markForm.negativeMark,
        marksPerQuestion: markForm.mark,
        additionalInstructions: additionalInstructions.map((add) => ({
          point: add,
        })),
      };
      const res = await post(urls.preExam.post, data, {
        headers: { roleFunction: "preaddmissionExam" },
      });

      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
      handleEditClose();
    }
    setSubmitload(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const quiz = quizData.map((q, index) => {
        return {
          question: q.question,
          options: [
            { value: q.options[0].value ? q.options[0].value : q.options[0] },
            { value: q.options[1].value ? q.options[1].value : q.options[1] },
            { value: q.options[2].value ? q.options[2].value : q.options[2] },
            { value: q.options[3].value ? q.options[3].value : q.options[3] },
          ],
          correctOption: q.correctOption,
        };
      });

      if (quiz.filter((q) => !q.question || !q.options).length)
        return toast.error("Question cannot be empty");
      if (quiz.some((q) => q.options.some((option) => option.name === "")))
        return toast.error("Option cannot be empty");

      if (quiz.filter((q) => !q.correctOption).length)
        return toast.error("Please select a correct option");
      setEditLoad(true);
      let data = {
        academicYear: addForm.academicYear,
        classId: addForm.schoolClass,
        examName: markForm.examName,
        passingPercentage: markForm.passingMark,
        quiz: quiz,
        negativeMarking: negativeMarking,
        negativeMarkingPerQuestion: markForm.negativeMark,
        marksPerQuestion: markForm.mark,
        additionalInstructions: additionalInstructions.map((add) => ({
          point: add,
        })),
      };

      // console.log("Before API Call - Current Data:", data);
      const res = await put(`${urls.preExam.update}/${editId}`, "", data, {
        headers: { roleFunction: "preaddmissionExam" },
      });

      // console.log("API Response:", res);

      updateList();
      handleEditClose();
    } catch (error) {
      console.log(error);
      handleClose();
      handleEditClose();
    }
    setEditLoad(false);
  };

  const handleDeleteClose = () => {
    setDelete(false);
    setDeleteId("");
  };

  const handleDelete = (id) => {
    setDelete(true);
    setDeleteId(id);
  };

  const handleDeleteQuiz = async () => {
    try {
      const response = await put(
        `${urls.preExam.put}/${deleteId}`,
        "",
        {},
        { headers: { roleFunction: "preaddmissionExam" } }
      );
      handleDeleteClose();
      handleSearch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (id, data) => {
    console.log(data, "datatata");
    setEditId(id);
    setAddForm({
      academicYear: data.academicYear._id,
      schoolClass: data.class._id,
    });
    setMarkForm({
      passingMark:
        (data.passingMarks / (data.marksPerQuestion * data.quiz.length)) * 100,
      negativeMark: data.negativeMarkingPerQuestion,
      mark: data.marksPerQuestion,
      examName: data.examName,
    });
    setNegativeMarking(data.negativeMarking);
    setAdditionalInstructions(data.additionalInstructions.map((i) => i.point));
    setQuizData(data.quiz);
    handleCLickEditOpen();
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Exams" />
      <Card style={{ marginBottom: "15px", padding: 10 }}>
        <Box>
          <form onSubmit={handleSearch}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    size="small"
                    label="academic year"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" fullWidth required>
                  <InputLabel>Class</InputLabel>
                  <Select
                    size="small"
                    required
                    label="class"
                    variant="outlined"
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="schoolClass"
                    value={formData.schoolClass || ""}
                    onChange={handleOnChange}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={2} sx={{ mt: 0.5 }}>
                <LoadingButton
                  type="submit"
                  size="small"
                  sx={{
                    color: "#fff",
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  loading={findLoad}
                  variant="contained"
                >
                  Find
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow>
              <TableCell align="center" sx={{ color: "white" }}>
                #SL
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Academic Year
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Class
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Question
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {datas
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, index) => (
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  {data.academicYear.academicYearFrom}-
                  {data.academicYear.academicYearTo}
                </TableCell>
                <TableCell align="center">{data.class.className}</TableCell>
                <TableCell align="center">
                  <Tooltip title="View">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleOpenQuiz(data)}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ShowComponent
                      roleFunction="preaddmissionExam"
                      action="update"
                    >
                      <Tooltip title="Update">
                        <IconButton
                          style={{
                            color: "#1b3779",
                          }}
                          size="small"
                          onClick={() => handleEdit(data._id, data)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ShowComponent>
                    <ShowComponent
                      roleFunction="preaddmissionExam"
                      action="delete"
                    >
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => handleDelete(data._id)}
                        >
                          <Delete fontSize="small" color="error" />
                        </IconButton>
                      </Tooltip>
                    </ShowComponent>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </Table>
        {!datas.length > 0 && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No Data Found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={datas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      <ShowComponent roleFunction="preaddmissionExam" action="add">
        <Box className="add-icon">
          <Tooltip title="Add Exams">
            <Fab
              variant="contained"
              sx={{
                color: "#ffff",

                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleClickOpen}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box>
      </ShowComponent>

      <Dialog
        open={openQuiz}
        onClose={handleCloseQuiz}
        fullScreen={fullScreen}
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={{ padding: 2 }}>
          <Typography
            id="modal-modal-title"
            sx={{ textAlign: "center" }}
            variant="h6"
            fontWeight="bold"
            component="h2"
          >
            Questions
          </Typography>
          <Grid conatiner spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              {showquiz?.quiz?.map((quizItem, i) => (
                <Box key={i}>
                  <Typography sx={{ fontWeight: 550 }}>{` ${i + 1}: ${
                    quizItem.question
                  }`}</Typography>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      marginBottom: "10px",
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: "5px",
                      padding: "0 15px 20px 15px",
                      border: "1px solid lightgrey",
                      marginTop: "10px",
                    }}
                  >
                    {quizItem?.options?.map((option, j) => (
                      <Grid item xs={12} md={6} key={j}>
                        <Typography>{`${String.fromCharCode(65 + j)}. ${
                          option.value
                        }`}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ padding: 2 }}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Exam
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl size="small" fullWidth required>
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    varient="outlined"
                    label="academic year"
                    name="academicYear"
                    value={addForm.academicYear || ""}
                    onChange={handleOnChangeForm}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl size="small" fullWidth required>
                  <InputLabel>Class</InputLabel>
                  <Select
                    variant="outlined"
                    label="class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={addForm.schoolClass || ""}
                    onChange={handleOnChangeForm}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4} sx={{ mt: 0.2 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                  label="Exam name"
                  name="examName"
                  id="filled-required"
                  value={markForm.examName}
                  onChange={hanleMarkChange}
                  sx={{
                    label: { fontSize: 10 },
                  }}
                />
              </Grid>
            </Grid>

            <Typography sx={{ textAlign: "left", fontWeight: "bold", mt: 4 }}>
              Add Questions:
            </Typography>
            {quizData?.map((q, questionIndex) => (
              <Grid
                container
                rowSpacing={2}
                key={questionIndex}
                sx={{
                  marginBottom: "10px",
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: "5px",
                  padding: "0 15px 20px 15px",

                  border: "1px solid lightgrey",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12} md={12} sx={{ padding: "5px" }}>
                  <InputLabels
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {questionIndex + 1}.{" "}
                    {q.question
                      ? "Update Your Question!"
                      : "Write your question"}
                    {/* <Button
                      size="small"
                      onClick={() => handleRemoveQuestion(questionIndex)}>
                      <CloseIcon color="error" fontSize="small" />
                    </Button> */}
                  </InputLabels>

                  <TextField
                    fullWidth
                    size="small"
                    name="question"
                    multiline
                    value={q.question}
                    placeholder="Type here..."
                    onChange={(e) => handleQuestionChange(e, questionIndex)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {questionIndex > 0 ? (
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() =>
                                handleRemoveQuestion(questionIndex)
                              }
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} sx={{ padding: "5px" }}>
                  <Grid container rowGap={2} columnSpacing={2}>
                    {q.options.map((o, i) => (
                      <Grid key={i} item xs={12} md={6}>
                        <Boxes
                          sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <RadioButtons
                            size="small"
                            {...label}
                            color="default"
                            checked={q.correctOption && q.correctOption === o}
                            onChange={(e) =>
                              handleSetCorrectOption(o, questionIndex)
                            }
                            value={o}
                            type="radio"
                          />
                          <InputLabels>Option {i + 1}</InputLabels>
                        </Boxes>
                        <TextField
                          fullWidth
                          size="small"
                          name={`option${i + 1}`}
                          value={o}
                          onChange={(e) =>
                            handleOptionChange(e, questionIndex, i)
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))}

            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mt: 1,
                    marginBottom: "15px",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleAddQuizs}
                    startIcon={<AddIcon />}
                  >
                    ADD
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Additional Instruction:
              </Typography>
              {additionalInstructions.map((instruction, index) => (
                <Grid container key={index} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      multiline
                      value={instruction}
                      onChange={(e) =>
                        handleAdditionalInstructionChange(e, index)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {/* {index > 0 ? ( */}
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => handleDeleteInstruction(index)}
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                            {/* ) : null} */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/* {index > 0 ? (
                    <Grid item xs={1} md={1} lg={1}>
                      <Button
                        sx={{ mt: 1 }}
                        onClick={() => handleDeleteInstruction(index)}>
                        <DeleteIcon color="error" fontSize="small" />
                      </Button>
                    </Grid>
                  ) : null} */}
                </Grid>
              ))}
              <Button
                size="small"
                sx={{ mt: 2 }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddInstruction}
              >
                Add
              </Button>
            </Box>

            <Grid container spacing={2} sx={{ mt: 1.5 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>
                    Negative Marking:{" "}
                  </Typography>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          fontSize="small"
                          checked={negativeMarking}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "15px" }}>
                          Yes
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          fontSize="small"
                          checked={!negativeMarking}
                          onChange={(event) =>
                            setNegativeMarking(!event.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "15px" }}>No</Typography>
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              {negativeMarking === true ? (
                <Grid item xs={12} md={12} lg={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    label="Negative Marks Per Question"
                    type="number"
                    name="negativeMark"
                    value={markForm.negativeMark}
                    onChange={hanleMarkChange}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  required
                  label="Marks Per Question"
                  type="number"
                  name="mark"
                  value={markForm.mark}
                  onChange={hanleMarkChange}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Pass percentage (%)"
                  size="small"
                  required
                  type="number"
                  name="passingMark"
                  value={markForm.passingMark}
                  onChange={hanleMarkChange}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "3%",
                  mt: 3,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={submiload}
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{
                    background: "#1b3779",
                    "&:hover": {
                      background: "#1b3779",
                    },
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Box>
        </form>
      </Dialog>
      <Dialog
        open={editOpen}
        onClose={handleEditClose}
        fullScreen={fullScreen}
        maxWidth="sm"
      >
        <form className="form-tag" onSubmit={handleEditSubmit}>
          <Box sx={{ padding: 2 }}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Update Exam
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={addForm.academicYear || ""}
                    onChange={handleOnChangeForm}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    label="Class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={addForm.schoolClass || ""}
                    onChange={handleOnChangeForm}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4} sx={{ mt: 0.2 }}>
                <TextField
                  fullWidth
                  size="small"
                  label="Exam Name"
                  name="examName"
                  id="filled-required"
                  value={markForm.examName}
                  onChange={hanleMarkChange}
                  sx={{
                    label: { fontSize: 10 },
                  }}
                />
              </Grid>
            </Grid>
            <Typography sx={{ textAlign: "left", fontWeight: "bold", mt: 4 }}>
              Update Questions:
            </Typography>
            {quizData?.map((q, questionIndex) => (
              <Grid
                container
                rowSpacing={2}
                key={questionIndex}
                sx={{
                  marginBottom: "10px",

                  backgroundColor: theme.palette.grey[100],
                  borderRadius: "5px",
                  padding: "0 15px 20px 15px",

                  border: "1px solid lightgrey",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12} md={12} sx={{ padding: "5px" }}>
                  <InputLabels
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {questionIndex + 1}.{" "}
                    {q.question
                      ? "Update Your Question!"
                      : "Write your question"}
                    <Button onClick={() => handleRemoveQuestion(questionIndex)}>
                      <DeleteIcon fontSize="small" color="error" />
                    </Button>
                  </InputLabels>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    name="question"
                    value={q.question}
                    placeholder="Type here..."
                    onChange={(e) => handleQuestionChange(e, questionIndex)}
                  />
                </Grid>
                <Grid item xs={12} md={12} sx={{ padding: "5px" }}>
                  <Grid container rowGap={2} columnSpacing={2}>
                    {q.options.map((o, i) => (
                      <Grid key={i} item xs={12} md={6}>
                        <Boxes
                          sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <RadioButtons
                            size="small"
                            {...label}
                            color="default"
                            checked={
                              q.correctOption && q.correctOption === o.value
                            }
                            onChange={(e) =>
                              handleEditSetCorrectOption(o.value, questionIndex)
                            }
                            value={o.value}
                            type="radio"
                          />
                          <InputLabels>Option {i + 1}</InputLabels>
                        </Boxes>
                        <TextField
                          fullWidth
                          size="small"
                          name={`option${i + 1}`}
                          value={o.value}
                          onChange={(e) =>
                            handleEditOptionChange(e, questionIndex, i)
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleAddQuizs}
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Additional Instruction:
              </Typography>
              {additionalInstructions.map((instruction, index) => (
                <Grid container key={index} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      size="small"
                      value={instruction}
                      onChange={(e) =>
                        handleAdditionalInstructionChange(e, index)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => handleDeleteInstruction(index)}
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={2} md={2} lg={1}>
                    <Button
                      sx={{ mt: 1 }}
                      onClick={() => handleDeleteInstruction(index)}>
                      <DeleteIcon color="error" fontSize="small" />
                    </Button>
                  </Grid> */}
                </Grid>
              ))}
              <Button
                size="small"
                sx={{ mt: 3 }}
                variant="contained"
                onClick={handleAddInstruction}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Box>
            <Grid container spacing={2} sx={{ mt: 1.5 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>
                    Negative Marking:{" "}
                  </Typography>
                  <Box>
                    {" "}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={negativeMarking}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "15px" }}>
                          Yes
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!negativeMarking}
                          onChange={(event) =>
                            setNegativeMarking(!event.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "15px" }}>No</Typography>
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              {negativeMarking === true ? (
                <Grid item xs={12} md={12} lg={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    label="Negative Marks Per Question"
                    type="number"
                    name="negativeMark"
                    value={markForm.negativeMark}
                    onChange={hanleMarkChange}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  label="Marks Per Question"
                  name="mark"
                  value={markForm.mark}
                  onChange={hanleMarkChange}
                  sx={{ label: { fontSize: 12 } }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Pass percentage (%)"
                  type="number"
                  name="passingMark"
                  value={markForm.passingMark}
                  onChange={hanleMarkChange}
                  sx={{ label: { fontSize: 12 } }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "3%",
                  mt: 3,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={handleEditClose}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={editload}
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{
                    background: "#1b3779",
                    "&:hover": {
                      background: "#1b3779",
                    },
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Box>
        </form>
      </Dialog>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={deletes}
        onClose={handleDeleteClose}
      >
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{
            background: "#fff",
            border: "none",
          }}
        >
          <Box sx={{ textAlign: "center", margin: "10px auto" }}>
            <img src={deleteIcon} width={50} height={50} />
          </Box>

          <Box p={2}>
            <Typography
              id="alert-dialog-modal-description"
              textAlign="center"
              my={1}
            >
              Are you sure you want to remove this?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                mt: 2,
              }}
            >
              <Button
                variant="plain"
                color="neutral"
                size="small"
                onClick={handleDeleteClose}
                sx={{
                  background: "#ddd",
                  ":hover": { background: "#ddd", opacity: 0.8 },
                  objectFit: "contain",
                }}
              >
                No,Cancel
              </Button>

              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDeleteQuiz}
              >
                Yes, I'm Sure
              </Button>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
    </Wrapper>
  );
};

export default PreExams;
