import * as React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  MenuItem,
  Select,
  Box,
  FormControl,
  Grid,
  InputLabel,
  Button,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Paper,
  TableContainer,
  Table,
  TableHead,
  Tooltip,
  Typography,
  TablePagination,
} from "@mui/material";
import { get, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import StickyBar from "../../StickyBar";

const styles = {
  Tabs: {
    margin: " 15px 0",
  },
  card: {
    padding: "10px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
  stickyContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    background: "#fff", // Adjust the background color as needed
    padding: "10px", // Adjust the padding as needed
    display: "flex",
    justifyContent: "center",
    zIndex: 1000, // Adjust the z-index as needed
  },
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Promotion = () => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    setIsSticky(scrollPosition >= documentHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [classes, setClasses] = useState([]);
  const [allAcademicYears, setAllAcademicYears] = useState([]);
  const [activeAcademicYear, setActiveAcademicYears] = useState([]);
  const [promoteDetails, setPromoteDetails] = useState({});
  const [promoteStudents, setPromoteStudents] = useState([]);
  const [open, setOpen] = useState(false);
  const [sections, setSections] = useState([]);
  const [checkBox, setCheckBox] = useState([]);
  const [curSections, setCurSections] = useState([]);
  const [loader, setLoader] = useState(false);
  const [promote, setPromote] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const [sort, setSort] = useState({
    name: "asc",
    rollNo: "asc",
  });

  const sortByName = () => {
    console.log("name");
    if (sort.name === "asc") {
      let sortedList = [
        ...promoteStudents.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setPromoteStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...promoteStudents.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setPromoteStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByRollNo = () => {
    console.log("RollNo");
    if (sort.rollNo === "asc") {
      let sortedList = [
        ...promoteStudents.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setPromoteStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...promoteStudents.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setPromoteStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const handleOnChange = (e) => {
    setPromoteStudents([]);
    const { name, value } = e.target;
    setPromoteDetails({ ...promoteDetails, [name]: value });

    // Call handlePromoteToClass when "Promote To Class" changes
    if (name === "promoteClass") {
      handlePromoteToClass(e);
    }
  };

  const handlePromoteToClass = async (e) => {
    setPromoteStudents([]);
    const { name, value } = e.target;
    setPromoteDetails({ ...promoteDetails, [name]: value });
    const res = await get(urls.schoolSection.getAllSection, {
      headers: { roleFunction: "studentPromotion" },
      params: {
        search: {
          class: value,
        },
      },
    });
    setSections(res.data.data);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await get(urls.students.getAllStudent, {
        headers: {
          roleFunction: "studentPromotion",
        },
        params: {
          search: {
            "academicInfo.class": promoteDetails.className,
            "academicInfo.section": promoteDetails.currentSection,
          },
        },
      });
      setPromoteStudents(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handlePromotionCheckBox = (_id, e) => {
    if (e.target.checked) {
      setCheckBox((prev) => [...prev, _id]);
    } else {
      setCheckBox(checkBox.filter((item) => item !== _id));
    }
  };

  const handleMultipleChecks = (e) => {
    if (e.target.checked) {
      const ids = promoteStudents.map((item) => item._id);
      setCheckBox([...ids]);
    } else {
      setCheckBox([]);
    }
  };

  const handlePromotion = async () => {
    setPromote(true);
    try {
      setOpen(true);
      console.log(promoteDetails.promoteClass);
      console.log(promoteDetails.academicYear);
      console.log(promoteDetails.className);
      console.log(checkBox);
      const res = await put(
        urls.students.putStudentPromo,
        "",
        {
          promoteClassId: promoteDetails.promoteClass,
          promoteAcademicYearId: promoteDetails.academicYear,
          currentClassId: promoteDetails.className,
          currentSection: promoteDetails.currentSection,
          promoteSection: promoteDetails.promoteSection,
          students: checkBox,
        },
        { headers: { roleFunction: "studentPromotion" } }
      );

      if (res.data.success) {
        setPromoteStudents([]);
      } else {
        setOpen(false);
      }
      setPromote(false);
      console.log(res);
    } catch (error) {
      console.log(error);
      setPromote(false);
    }
  };

  useEffect(() => {
    const getActiveAcademicYear = async () => {
      const res = await get(urls.administrator.academicYearGetAll, {
        headers: {
          roleFunction: "studentPromotion",
        },
        params: {
          search: {
            status: "active",
          },
        },
      });
      setActiveAcademicYears(res.data.data);
    };
    getActiveAcademicYear();
  }, [selectedSetting]);

  useEffect(() => {
    const getAllAcademicYear = async () => {
      const res = await get(urls.administrator.academicYearGetAll, {
        headers: { roleFunction: "studentPromotion" },
      });
      setAllAcademicYears(res.data.data);
    };
    getAllAcademicYear();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      const res = await get(urls.class.getAllClass, {
        headers: { roleFunction: "studentPromotion" },
      });
      setClasses(res.data.data);
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getCurrentClassSections = async () => {
      try {
        if (promoteDetails.className) {
          const getSections = await get(`/${selectedSetting._id}/section`, {
            headers: { roleFunction: "studentPromotion" },
            params: {
              search: {
                class: promoteDetails.className,
              },
            },
          });
          setCurSections(getSections.data.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    getCurrentClassSections();
  }, [promoteDetails.className, selectedSetting]);
  return (
    <Wrapper>
      <SectionSubHeader title="Promotion " />
      <div style={styles.Tabs}>
        <form onSubmit={handleSubmit}>
          <Card sx={{ margin: "20px 0", padding: "10px" }}>
            <Box sx={{ flexGrow: 1 }}>
              {activeAcademicYear &&
                activeAcademicYear.map((row, index) => (
                  <div
                    key={row._id}
                    value={row._id}
                    style={{ fontSize: 16, fontWeight: "bold" }}
                  >
                    <span style={{ color: "forestgreen" }}>
                      Running Session:{" "}
                    </span>
                    {row.academicYearFrom} - {row.academicYearTo}
                  </div>
                ))}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel>Promote to session </InputLabel>
                    <Select
                      label="Promote To Session"
                      name="academicYear"
                      value={promoteDetails.academicYear}
                      onChange={(event) => handleOnChange(event)}
                    >
                      {allAcademicYears &&
                        allAcademicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            style={{ fontSize: 16, fontWeight: "bold" }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel>Current class</InputLabel>
                    <Select
                      label="Current Class"
                      name="className"
                      value={promoteDetails.className}
                      onChange={(event) => handleOnChange(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel>Current section</InputLabel>
                    <Select
                      label="Current Section"
                      name="currentSection"
                      value={promoteDetails.currentSection}
                      onChange={(event) => handleOnChange(event)}
                    >
                      {/* {curSections &&
                        curSections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))} */}
                      {curSections &&
                        curSections
                          .slice()
                          .sort((a, b) =>
                            a.sectionName.localeCompare(b.sectionName)
                          ) // Sort the sections array alphabetically
                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.sectionName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} required>
                  <FormControl size="small" style={styles.textfield}>
                    <InputLabel>Promote to class</InputLabel>
                    <Select
                      label="Promote To Class"
                      name="promoteClass"
                      value={promoteDetails.promoteClass}
                      onChange={(event) => handleOnChange(event)}
                    >
                      {classes &&
                        classes
                          .filter(
                            (classItem) =>
                              classItem._id !== promoteDetails.className
                          ) // Exclude the current class
                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.className}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={2}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel>Promote to section</InputLabel>
                    <Select
                      label="Promote To Section"
                      name="promoteSection"
                      value={promoteDetails.promoteSection}
                      onChange={(event) => handleOnChange(event)}
                    >
                      {sections &&
                        sections
                          .slice()
                          .sort((a, b) =>
                            a.sectionName.localeCompare(b.sectionName)
                          )
                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.sectionName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={1}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-start",
                    },
                    alignSelf: "center",
                  }}
                >
                  <LoadingButton
                    loading={loader}
                    varient="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                      color: "#fff",
                    }}
                  >
                    Find
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>

        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="left">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="left">
                  <span className="class-table-header">Name</span>
                  <span
                    onClick={sortByName}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.name === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="left">
                  <span className="class-table-header">Roll No</span>
                  <span
                    onClick={sortByRollNo}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.rollNo === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="left">
                  <span className="class-table-header">Father Name</span>
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    onClick={handleMultipleChecks}
                    {...label}
                    sx={{
                      padding: 0,
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#fff",
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promoteStudents
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell
                      align="left"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.basicInfo.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.academicInfo.rollNo}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.fatherInfo.fatherName}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ textTransform: "capitalize" }}
                    >
                      <Checkbox
                        size="small"
                        checked={checkBox.includes(row._id)}
                        onChange={(e) => handlePromotionCheckBox(row._id, e)}
                        {...label}
                        sx={{
                          color: "#1b3779",
                          "&.Mui-checked": {
                            color: "#1b3779",
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!promoteStudents.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={promoteStudents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
        {promoteStudents.length > 0 && (
          <StickyBar
            content={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  loading={promote}
                  varient="contained"
                  size="small"
                  type="submit"
                  onClick={handlePromotion}
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                    color: "#fff",
                  }}
                >
                  Promote
                </LoadingButton>
              </div>
            }
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Promotion;
