import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function AdminRolePermission() {
  return (
    <div>
      <div style={{ margin: 15}}>
        <Card>
          <Box >
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell>Module Name</TableCell>
                  <TableCell>Function Name</TableCell>
                  <TableCell>
                    View
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    Add <Checkbox {...label} />{" "}
                  </TableCell>
                  <TableCell>
                    Edit <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    Delete <Checkbox {...label} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={3}>Settings</TableCell>
                  <TableCell>Payment Setting</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>General Setting</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SMS Setting</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={15}>Administrator »</TableCell>
                  <TableCell>Activity Log</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Backup Database</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email Template</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Guardian Feedback</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Reset User Password</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Payment</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Role Permission</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>User Role</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>School</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>General Setting</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SMS</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SMS Template</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Super Admin</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Manage User</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Academic Year</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={4}>Human Resource »</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Designation</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Employee</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Offboarding</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={1}>Teacher »</TableCell>
                  <TableCell>Teacher</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={7}>Academic Activity »</TableCell>
                  <TableCell>Classes</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Promotion</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Promotion Interschool</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Class Routine</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Section</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Subject</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Syllabus</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2}>Guardian »</TableCell>
                  <TableCell>Feedback</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Guardian</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={10}> Student »</TableCell>
                  <TableCell>Student Activity</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bulk Import</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Credential</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Language Map</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Quick Add</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Reshuffle</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Reshuffle1</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Simple Add</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Student</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Subject Map</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={5}> Attendance »</TableCell>
                  <TableCell>Absent Email</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Absent SMS</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Employee Attendance</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Student Attendance</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Teacher Attendance</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={1}>Assignment »</TableCell>
                  <TableCell>Assignment</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={9}>Exam »</TableCell>
                  <TableCell>Exam Attendance</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Consolidated Marks</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Exam Term</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Exam Grade</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hallticket</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hallticket 1</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hallticketpractical</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Exam Schedule</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Exam Suggestion</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={11}>Exam Mark »</TableCell>
                  <TableCell>Exam Result</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Final Result</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Mark send by Email</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Exam Mark</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Marks Card</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Mark Sheet</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Merit List</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Result</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Result Card</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Result Email</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Result SMS</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={5}> Library »</TableCell>
                  <TableCell>Library Book</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Employee Checkinout</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Issue & Return</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Library Member</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Student Checkinout</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={7}>Transport »</TableCell>
                  <TableCell>Fees Plan</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>manage stopfee</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transport Member</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transport Route</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vehicle</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vehicle Log</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vehicle Maintenance</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={3}>Hostel »</TableCell>
                  <TableCell>Hostel</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hostel Member</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hostel Room</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={4}>Message, Email & SMS »</TableCell>
                  <TableCell>Groups</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>message</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Text SMS</TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...label} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default AdminRolePermission;
