// import {
//   Box,
//   Button,
//   Card,
//   FormControl,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   TextField,
//   Typography,
// } from "@mui/material";
// import { Autocomplete, Stack } from "@mui/material";
// import React from "react";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import { useState, useContext } from "react";
// import { useEffect } from "react";
// import { get } from "../../../services/apis";
// import { urls as url } from "../../../services/urlConstant";
// import SettingContext from "../../../context/SettingsContext";
// import appendStringToUrls from "../../../utils/appendString";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import SectionSubHeader from "../../SectionSubHeader";
// import { LoadingButton } from "@mui/lab";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Box>{children}</Box>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// const LeaveReport = () => {
//   const { selectedSetting } = useContext(SettingContext);

//   const [value, setValue] = React.useState(0);
//   const [academicYear, setAcademicYear] = useState([]);
//   const [fromDate, setFromDate] = useState(new Date());
//   const [toDate, setToDate] = useState(new Date());
//   const [leaveTypeData, setLeaveTypeData] = useState([]);
//   const [addForm, setAddForm] = useState({});
//   const [leave, setLeave] = useState("");
//   const [userleave, setUserLeave] = useState("");
//   const [classes, setClasses] = useState([]);
//   const [sections, setSections] = useState([]);
//   const [formData, setFormData] = useState({ class: null, section: null });
//   const [students, setStudents] = useState([]);
//   const [selectStudent, setSelectStudent] = useState(null);
//   const [role, setRole] = useState([]);
//   const [employee, setEmployee] = useState([]);
//   const [femployee, setFEmployee] = useState([]);
//   const [userType, setUserType] = useState("student");
//   const [userId, setUserId] = useState("");
//   const [academicYearId, setAcademicYearId] = useState();
//   const [selectEmployee, setSelectEmployee] = useState("");
//   const [selectUserStudent, setSelectUserStudent] = useState(null);
//   const [selectUserEmployee, setSelectUserEmployee] = useState("");
//   const [userPdfType, setUserPdfType] = useState("student");
//   const [downloadLoader, setDownloadLoader] = useState(false);
//   const [printLoader, setPrintLoader] = useState(false);

//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
//   const handleTypeChange = (event) => {
//     setLeave(event.target.value);
//     setSelectStudent(null);
//     setSelectEmployee(null);
//     setUserType(event.target.value);
//     setUserId("");
//   };
//   const handleTypeUserChange = (event) => {
//     setUserLeave(event.target.value);
//     setSelectUserStudent(null);
//     setSelectUserEmployee(null);
//     setUserPdfType(event.target.value);
//     setUserId("");
//   };

//   const handleStudentSelect = (e, val) => {
//     setSelectStudent(val);
//     setUserType("student");
//     setUserId(val._id);
//   };

//   const handleEmployeeSelect = (event) => {
//     const selectedValue = event.target.value;
//     setSelectEmployee(selectedValue);
//     setUserType("employee");
//     setUserId(selectedValue);
//   };

//   const handlePdfStudentSelect = (e, val) => {
//     setSelectUserStudent(val);
//     setUserPdfType("student");
//     setUserId(val._id);
//   };

//   const handlePdfEmployeeSelect = (event) => {
//     const selectedValue = event.target.value;
//     setSelectUserEmployee(selectedValue);
//     setUserPdfType("employee");
//     setUserId(selectedValue);
//   };
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
//   const handleaddChange = (e) => {
//     setAddForm({
//       ...addForm,
//       [e.target.name]: e.target.value,
//     });
//   };

//   useEffect(() => {
//     const getLeaveType = async () => {
//       try {
//         let { data } = await get(`${urls.leaveType.getList}/all`, {
//           headers: { roleFunction: "leaveReport" },
//         });
//         setLeaveTypeData(data.data);
//         console.log(data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getLeaveType();
//   }, []);

//   useEffect(() => {
//     let fetchData = async () => {
//       try {
//         let { data } = await get(`${urls.administrator.academicYearGetAll}`, {
//           headers: { roleFunction: "leaveReport" },
//         });
//         setAcademicYear(data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchData();
//   }, [selectedSetting]);

//   useEffect(() => {
//     const getClasses = async () => {
//       try {
//         const res = await get(urls.class.getAllClass, {
//           headers: { roleFunction: "leaveReport" },
//         });
//         let search = res.data.data.length
//           ? { class: res.data.data[0]._id }
//           : {};
//         const resSections = await get(urls.schoolSection.getAllSection, {
//           headers: { roleFunction: "leaveReport" },
//           params: {
//             search,
//           },
//         });

//         setSections(resSections.data.data);
//         setClasses(res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getClasses();
//   }, [selectedSetting]);

//   useEffect(() => {
//     const getSections = async () => {
//       try {
//         const res = await get(urls.schoolSection.getAllSection, {
//           headers: { roleFunction: "leaveReport" },
//         });
//         setSections(res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getSections();
//   }, [selectedSetting]);

//   useEffect(() => {
//     const getStudents = async () => {
//       try {
//         if (formData.class && formData.section) {
//           const { data: students, status } = await get(
//             `${urls.students.getAllStudent}`,
//             {
//               headers: { roleFunction: "leaveReport" },
//             },
//             {
//               params: {
//                 search: {
//                   "academicInfo.class": formData.class,
//                   "academicInfo.section": formData.section,
//                 },
//               },
//             }
//           );
//           if (status > 199 && status < 299) {
//             setSelectStudent(null);
//             setStudents(students.data);
//           }
//         }
//       } catch (error) {}
//     };
//     getStudents();
//   }, [formData]);

//   const handleClasses = async (e) => {
//     try {
//       const { name, value } = e.target;
//       setFormData({ ...formData, [name]: value });
//       const res = await get(urls.schoolSection.getAllSection, {
//         headers: { roleFunction: "leaveReport" },
//         params: {
//           search: {
//             class: value,
//           },
//         },
//       });
//       setSections(res.data.data);
//       setFormData((prev) => ({
//         ...prev,
//         section: "",
//       }));
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleSections = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   useEffect(() => {
//     const getRole = async () => {
//       try {
//         const res = await get(urls.role.getAllRole, {
//           headers: { roleFunction: "leaveReport" },
//         });
//         setRole(res.data.data);
//         console.log("setRole", res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getRole();
//   }, [selectedSetting]);

//   useEffect(() => {
//     const getEmployee = async () => {
//       try {
//         const res = await get(urls.employee.getAllEmployee, {
//           headers: { roleFunction: "leaveReport" },
//         });
//         setEmployee(res.data.data);
//         console.log("setEmployee", res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getEmployee();
//   }, [selectedSetting]);

//   const handleRoleChange = (event) => {
//     const selectedRoleId = event.target.value;
//     const filteredEmployees = employee.filter(
//       (emp) => emp.role === selectedRoleId
//     );
//     setFormData({ ...formData, role: selectedRoleId, employeee: "" });
//     setFEmployee(filteredEmployees);
//   };

//   const handleGetDownloadSheet = async (e) => {
//     e.preventDefault();
//     setDownloadLoader(true);
//     try {
//       const downloadUrl = `${urls.employeeLeaveApplication.downloadExcel}?userId=${userId}&academicYearId=${academicYearId}&userType=${userType}`;

//       const response = await get(
//         downloadUrl,
//         {
//           headers: { roleFunction: "leaveReport" },
//         },
//         {
//           responseType: "blob",
//         }
//       );

//       const blob = new Blob([response.data], {
//         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//       });

//       const url = URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = url;

//       const userTypeSuffix = userType === "student" ? "Student" : "Employee";
//       const fileName = `${userTypeSuffix}_Leave_Report.xlsx`;
//       link.setAttribute("download", fileName);

//       document.body.appendChild(link);
//       link.click();
//       link.parentNode.removeChild(link);
//     } catch (error) {
//       console.log(error);
//     }
//     setDownloadLoader(false);
//   };

//   const handleGetPrintPdf = async (e) => {
//     e.preventDefault();
//     console.log("kjkjkjk");
//     setPrintLoader(true);
//     try {
//       const getPrint = await get(
//         `${urls.employeeLeaveApplication.downloadPdf}?userId=${userId}&academicYearId=${academicYearId}&userType=${userPdfType}&fromDate=${fromDate}&toDate=${toDate}`,
//         {
//           headers: { roleFunction: "leaveReport" },
//         },
//         {
//           responseType: "blob",
//         }
//       );
//       const uri = URL.createObjectURL(getPrint.data);
//       window.open(uri, "__blank");
//     } catch (error) {
//       console.log(error);
//     }
//     setPrintLoader(false);
//   };

//   return (
//     <div>
//       <SectionSubHeader title="Leave Report" style={{ margin: "15px" }} />
//       <Box
//         sx={{
//           borderBottom: 1,
//           borderColor: "divider",
//           margin: 2,
//         }}
//       >
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           aria-label="basic tabs example"
//         >
//           <Tab label="Summary" {...a11yProps(0)} />
//           <Tab label="Leave Planner" {...a11yProps(1)} />
//         </Tabs>
//       </Box>
//       <TabPanel value={value} index={0}>
//         <Card style={{ margin: "15px", padding: "15px" }}>
//           <Box>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6} lg={4}>
//                 <FormControl fullWidth size="small" required>
//                   <InputLabel id="demo-simple-select-filled-label">
//                     Academic Year
//                   </InputLabel>

//                   <Select
//                     labelId="demo-simple-select-filled-label"
//                     id="demo-simple-select-filled"
//                     label="Academic Year"
//                     value={academicYearId}
//                     onChange={(event) => setAcademicYearId(event.target.value)}
//                   >
//                     {academicYear.length
//                       ? academicYear.map((data, i) => (
//                           <MenuItem value={data._id} key={data._id}>
//                             {data.academicYearFrom}-{data.academicYearTo}
//                           </MenuItem>
//                         ))
//                       : null}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={12} md={6} lg={4}>
//                 <FormControl fullWidth size="small" required>
//                   <InputLabel id="demo-simple-select-filled-label">
//                     Type
//                   </InputLabel>

//                   <Select
//                     labelId="demo-simpless-select-filled-label"
//                     id="demo-simple-select-filled-l"
//                     name="leave"
//                     label="Type"
//                     value={leave || ""}
//                     onChange={handleTypeChange}
//                     style={{ minWidth: 180, label: { fontSize: 12 } }}
//                   >
//                     <MenuItem value={"student"}>Student</MenuItem>
//                     <MenuItem value={"employee"}>Employee</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={12} md={6} lg={4}>
//                 <LoadingButton
//                   loading={downloadLoader}
//                   variant="contained"
//                   size="medium"
//                   type="submit"
//                   sx={{
//                     display: "flex",
//                     justifyContent: "flex-end",
//                     alignItems: "center",

//                     background: "#1b3779",
//                     ":hover": { background: "#1b3779" },
//                   }}
//                   onClick={handleGetDownloadSheet}
//                 >
//                   Download
//                 </LoadingButton>
//               </Grid>
//             </Grid>

//             <div style={{ marginTop: "25px" }}>
//               {leave === "student" && (
//                 <div>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} md={6} lg={4}>
//                       <FormControl size="small" fullWidth required>
//                         <InputLabel id="demo-simple-select-filled-label">
//                           Class
//                         </InputLabel>
//                         <Select
//                           labelId="demo-simple-select-filled-label"
//                           id="demo-simple-select-filled"
//                           name="class"
//                           label="Class"
//                           required
//                           value={formData.class || ""}
//                           onChange={(event) => handleClasses(event)}
//                         >
//                           {classes &&
//                             classes.map((row, index) => (
//                               <MenuItem
//                                 key={row._id}
//                                 value={row._id}
//                                 sx={{ fontSize: 12, fontWeight: 500 }}
//                               >
//                                 {row.className}
//                               </MenuItem>
//                             ))}
//                         </Select>
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={6} lg={4}>
//                       <FormControl size="small" fullWidth required>
//                         <InputLabel id="demo-simple-select-filled-label">
//                           Section
//                         </InputLabel>
//                         <Select
//                           labelId="demo-simple-select-filled-label"
//                           id="demo-simple-select-filled"
//                           name="section"
//                           label="Section"
//                           value={formData.section || ""}
//                           required
//                           onChange={(event) => handleSections(event)}
//                         >
//                           {sections.length &&
//                             sections.map((row, index) => (
//                               <MenuItem
//                                 key={row._id}
//                                 value={row._id}
//                                 sx={{ fontSize: 12, fontWeight: 500 }}
//                               >
//                                 {row.sectionName}
//                               </MenuItem>
//                             ))}
//                         </Select>
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={6} lg={4}>
//                       <Autocomplete
//                         size="small"
//                         value={selectStudent}
//                         onChange={handleStudentSelect}
//                         isOptionEqualToValue={(option, value) =>
//                           option._id === value._id
//                         }
//                         options={students}
//                         getOptionLabel={(option) => `${option.basicInfo.name} `}
//                         renderInput={(params) => (
//                           <TextField
//                             required
//                             fullWidth
//                             size="small"
//                             {...params}
//                             label="Students"
//                             placeholder="Name "
//                           />
//                         )}
//                       />
//                     </Grid>
//                   </Grid>
//                 </div>
//               )}
//               {leave === "employee" && (
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} md={6} lg={4}>
//                     <FormControl size="small" fullWidth required>
//                       <InputLabel>Role</InputLabel>
//                       <Select
//                         labelId="demo-simple-select-filled-label"
//                         id="demo-simple-select-filled"
//                         name="role"
//                         label="Role"
//                         value={formData.role}
//                         onChange={handleRoleChange}
//                       >
//                         {role &&
//                           role.map((row, index) => (
//                             <MenuItem
//                               key={row._id}
//                               value={row._id}
//                               sx={{ fontSize: 12, fontWeight: 500 }}
//                             >
//                               {row.roleName}
//                             </MenuItem>
//                           ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12} md={6} lg={4}>
//                     <FormControl size="small" fullWidth required>
//                       <InputLabel>Employee</InputLabel>
//                       <Select
//                         labelId="demo-simple-select-filled-label"
//                         id="demo-simple-select-filled"
//                         name="selectEmployee"
//                         label="selectEmployee"
//                         value={selectEmployee}
//                         onChange={handleEmployeeSelect}
//                       >
//                         {femployee &&
//                           femployee.map((row) => (
//                             <MenuItem
//                               key={row._id}
//                               value={row._id}
//                               sx={{ fontSize: 12, fontWeight: 500 }}
//                             >
//                               {row.basicInfo.empName}
//                             </MenuItem>
//                           ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>
//               )}
//             </div>
//           </Box>
//         </Card>
//       </TabPanel>
//       <TabPanel value={value} index={1}>
//         <Card style={{ margin: "10px 15px" }}>
//           <Grid container spacing={2} mt={1}>
//             <Grid item xs={12} md={6} lg={3}>
//               <FormControl fullWidth size="small" required>
//                 <InputLabel id="demo-select-small-label">
//                   Academic Year
//                 </InputLabel>

//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   label="Academic Year"
//                   value={academicYearId}
//                   onChange={(event) => setAcademicYearId(event.target.value)}
//                 >
//                   {academicYear.length
//                     ? academicYear.map((data, i) => (
//                         <MenuItem value={data._id} key={data._id}>
//                           {data.academicYearFrom}-{data.academicYearTo}
//                         </MenuItem>
//                       ))
//                     : null}
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} md={6} lg={3}>
//               <FormControl fullWidth size="small" required>
//                 <InputLabel id="demo-select-small-label">Type</InputLabel>
//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   label="Type"
//                   name="leaveType"
//                   value={addForm.leaveType || ""}
//                   onChange={handleaddChange}
//                   style={{ label: { fontSize: 12 } }}
//                 >
//                   {leaveTypeData.map((data, i) => (
//                     <MenuItem key={i} value={data._id}>
//                       {data.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} md={6} lg={3}>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <Stack spacing={2}>
//                   <DatePicker
//                     label="From Date"
//                     inputFormat="DD-MM-YYYY"
//                     value={fromDate || ""}
//                     onChange={setFromDate}
//                     renderInput={(params) => (
//                       <TextField fullWidth size="small" {...params} />
//                     )}
//                   />
//                 </Stack>
//               </LocalizationProvider>
//             </Grid>
//             <Grid item xs={12} md={6} lg={3}>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <Stack spacing={2}>
//                   <DatePicker
//                     label="To Date"
//                     inputFormat="DD-MM-YYYY"
//                     value={toDate || ""}
//                     onChange={setToDate}
//                     renderInput={(params) => (
//                       <TextField fullWidth size="small" {...params} />
//                     )}
//                   />
//                 </Stack>
//               </LocalizationProvider>
//             </Grid>

//             <Grid item xs={12} md={6} lg={3}>
//               <FormControl size="small" required fullWidth>
//                 <InputLabel id="demo-select-small-label">User Type</InputLabel>
//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   name="userleave"
//                   label="User Type"
//                   value={userleave || ""}
//                   onChange={handleTypeUserChange}
//                   style={{ label: { fontSize: 12 } }}
//                 >
//                   <MenuItem value={"student"}>Student</MenuItem>
//                   <MenuItem value={"employee"}>Employee</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>
//             {userleave === "student" && (
//               <>
//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl size="small" fullWidth required>
//                     <InputLabel id="demo-select-small-label">Class</InputLabel>
//                     <Select
//                       labelId="demo-simple-select-label"
//                       id="demo-simple-select"
//                       name="class"
//                       label="Class"
//                       value={formData.class || ""}
//                       onChange={(event) => handleClasses(event)}
//                     >
//                       {classes &&
//                         classes.map((row, index) => (
//                           <MenuItem
//                             key={row._id}
//                             value={row._id}
//                             sx={{ fontSize: 12, fontWeight: 500 }}
//                           >
//                             {row.className}
//                           </MenuItem>
//                         ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl fullWidth required size="small">
//                     <InputLabel id="demo-select-small-label">
//                       Section
//                     </InputLabel>
//                     <Select
//                       labelId="demo-simple-select-label"
//                       id="demo-simple-select"
//                       name="section"
//                       label="Section"
//                       value={formData.section || ""}
//                       required
//                       onChange={(event) => handleSections(event)}
//                     >
//                       {sections.map((row, index) => (
//                         <MenuItem
//                           key={row._id}
//                           value={row._id}
//                           sx={{ fontSize: 12, fontWeight: 500 }}
//                         >
//                           {row.sectionName}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl fullWidth required>
//                     <Autocomplete
//                       size="small"
//                       value={selectStudent}
//                       fullWidth
//                       onChange={handlePdfStudentSelect}
//                       isOptionEqualToValue={(option, value) =>
//                         option._id === value._id
//                       }
//                       options={students}
//                       getOptionLabel={(option) => `${option.basicInfo.name} `}
//                       renderInput={(params) => (
//                         <TextField
//                           id="outlined-size-small"
//                           required
//                           fullWidth
//                           {...params}
//                           label="Students"
//                           placeholder="Name "
//                         />
//                       )}
//                     />
//                   </FormControl>
//                 </Grid>
//               </>
//             )}

//             {userleave === "employee" && (
//               <>
//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl size="small" required fullWidth>
//                     <InputLabel id="demo-select-small-label">Role</InputLabel>
//                     <Select
//                       labelId="demo-simple-select-label"
//                       id="demo-simple-select"
//                       name="role"
//                       label="Role"
//                       value={formData.role}
//                       onChange={handleRoleChange}
//                     >
//                       {role &&
//                         role.map((row, index) => (
//                           <MenuItem
//                             key={row._id}
//                             value={row._id}
//                             sx={{ fontSize: 12, fontWeight: 500 }}
//                           >
//                             {row.roleName}
//                           </MenuItem>
//                         ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl size="small" fullWidth required>
//                     <InputLabel id="demo-select-small-label">
//                       Employee
//                     </InputLabel>
//                     <Select
//                       labelId="demo-simple-select-label"
//                       id="demo-simple-select"
//                       name="selectUserEmployee"
//                       label="selectEmployee"
//                       value={selectUserEmployee}
//                       onChange={handlePdfEmployeeSelect}
//                     >
//                       {femployee &&
//                         femployee.map((row) => (
//                           <MenuItem
//                             key={row._id}
//                             value={row._id}
//                             sx={{ fontSize: 12, fontWeight: 500 }}
//                           >
//                             {row.basicInfo.empName}
//                           </MenuItem>
//                         ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </>
//             )}
//           </Grid>
//           <LoadingButton
//             loading={printLoader}
//             variant="contained"
//             size="medium"
//             type="submit"
//             sx={{
//               display: "flex",
//               justifyContent: "flex-end",
//               alignItems: "center",
//               mb: "10px",
//               mt: 2,
//               ml: "10px",
//             }}
//             style={{
//               color: "white",
//               background: "rgb(27, 55, 121)",
//               ":hover": { background: "rgb(27, 55, 121)" },
//             }}
//             onClick={handleGetPrintPdf}
//           >
//             Print
//           </LoadingButton>
//         </Card>
//       </TabPanel>
//     </div>
//   );
// };

// export default LeaveReport;

import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete, Stack } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useContext } from "react";
import { useEffect } from "react";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";
import Wrapper from "../../Wrapper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LeaveReport = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [value, setValue] = React.useState(0);
  const [academicYear, setAcademicYear] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [addForm, setAddForm] = useState({});
  const [leave, setLeave] = useState("");
  const [userleave, setUserLeave] = useState("");
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState({ class: null, section: null });
  const [students, setStudents] = useState([]);
  const [selectStudent, setSelectStudent] = useState(null);
  const [role, setRole] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [femployee, setFEmployee] = useState([]);
  const [userType, setUserType] = useState("student");
  const [userId, setUserId] = useState("");
  const [academicYearId, setAcademicYearId] = useState();
  const [selectEmployee, setSelectEmployee] = useState("");
  const [selectUserStudent, setSelectUserStudent] = useState(null);
  const [selectUserEmployee, setSelectUserEmployee] = useState("");
  const [userPdfType, setUserPdfType] = useState("student");
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [printLoader, setPrintLoader] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const handleTypeChange = (event) => {
    setLeave(event.target.value);
    setSelectStudent(null);
    setSelectEmployee(null);
    setUserType(event.target.value);
    setUserId("");
  };
  const handleTypeUserChange = (event) => {
    setUserLeave(event.target.value);
    setSelectUserStudent(null);
    setSelectUserEmployee(null);
    setUserPdfType(event.target.value);
    setUserId("");
  };

  const handleStudentSelect = (e, val) => {
    setSelectStudent(val);
    setUserType("student");
    setUserId(val._id);
  };

  const handleEmployeeSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectEmployee(selectedValue);
    setUserType("employee");
    setUserId(selectedValue);
  };

  const handlePdfStudentSelect = (e, val) => {
    setSelectUserStudent(val);
    setUserPdfType("student");
    setUserId(val._id);
  };

  const handlePdfEmployeeSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectUserEmployee(selectedValue);
    setUserPdfType("employee");
    setUserId(selectedValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleaddChange = (e) => {
    setAddForm({
      ...addForm,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const getLeaveType = async () => {
      try {
        let { data } = await get(`${urls.leaveType.getList}/all`, {
          headers: { roleFunction: "leaveReport" },
        });
        setLeaveTypeData(data.data);
        console.log(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getLeaveType();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      try {
        let { data } = await get(`${urls.administrator.academicYearGetAll}`, {
          headers: { roleFunction: "leaveReport" },
        });
        setAcademicYear(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "leaveReport" },
        });
        let search = res.data.data.length
          ? { class: res.data.data[0]._id }
          : {};
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "leaveReport" },
          params: {
            search,
          },
        });

        setSections(resSections.data.data);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getSections = async () => {
      try {
        const res = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "leaveReport" },
        });
        setSections(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSections();
  }, [selectedSetting]);

  useEffect(() => {
    const getStudents = async () => {
      try {
        if (formData.class && formData.section) {
          const { data: students, status } = await get(
            `${urls.students.getAllStudent}`,
            {
              headers: { roleFunction: "leaveReport" },
            },
            {
              params: {
                search: {
                  "academicInfo.class": formData.class,
                  "academicInfo.section": formData.section,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            setSelectStudent(null);
            setStudents(students.data);
          }
        }
      } catch (error) {}
    };
    getStudents();
  }, [formData]);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "leaveReport" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      setFormData((prev) => ({
        ...prev,
        section: "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSections = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole, {
          headers: { roleFunction: "leaveReport" },
        });
        setRole(res.data.data);
        console.log("setRole", res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, [selectedSetting]);

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const res = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "leaveReport" },
        });
        setEmployee(res.data.data);
        console.log("setEmployee", res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployee();
  }, [selectedSetting]);

  const handleRoleChange = (event) => {
    const selectedRoleId = event.target.value;
    const filteredEmployees = employee.filter(
      (emp) => emp.role === selectedRoleId
    );
    setFormData({ ...formData, role: selectedRoleId, employeee: "" });
    setFEmployee(filteredEmployees);
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();
    setDownloadLoader(true);
    try {
      const downloadUrl = `${urls.employeeLeaveApplication.downloadExcel}?userId=${userId}&academicYearId=${academicYearId}&userType=${userType}`;

      const response = await get(
        downloadUrl,
        {
          headers: { roleFunction: "leaveReport" },
        },
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      const userTypeSuffix = userType === "student" ? "Student" : "Employee";
      const fileName = `${userTypeSuffix}_Leave_Report.xlsx`;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setDownloadLoader(false);
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    console.log("kjkjkjk");
    setPrintLoader(true);
    try {
      const getPrint = await get(
        `${urls.employeeLeaveApplication.downloadPdf}?userId=${userId}&academicYearId=${academicYearId}&userType=${userPdfType}&fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: { roleFunction: "leaveReport" },
        },
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setPrintLoader(false);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Leave Report" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Leave Planner" {...a11yProps(1)} />
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <Paper sx={{ padding: 2, marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-simple-select-filled-label">
                  Academic Year
                </InputLabel>

                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  label="Academic Year"
                  value={academicYearId}
                  onChange={(event) => setAcademicYearId(event.target.value)}
                >
                  {academicYear.length
                    ? academicYear.map((data, i) => (
                        <MenuItem value={data._id} key={data._id}>
                          {data.academicYearFrom}-{data.academicYearTo}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-simple-select-filled-label">
                  Type
                </InputLabel>

                <Select
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  name="leave"
                  label="Type"
                  fullWidth
                  value={leave || ""}
                  onChange={handleTypeChange}
                  style={{ label: { fontSize: 12 } }}
                >
                  <MenuItem value={"student"}>Student</MenuItem>
                  <MenuItem value={"employee"}>Employee</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {leave === "student" && (
              <>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="class"
                      label="Class"
                      required
                      value={formData.class || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Section
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      label="Section"
                      value={formData.section || ""}
                      required
                      onChange={(event) => handleSections(event)}
                    >
                      {sections.length &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Autocomplete
                    size="small"
                    value={selectStudent}
                    onChange={handleStudentSelect}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    options={students}
                    getOptionLabel={(option) => `${option.basicInfo.name} `}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        size="small"
                        {...params}
                        label="Students"
                        placeholder="Search student with name"
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {leave === "employee" && (
              <>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel>Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="role"
                      label="Role"
                      value={formData.role}
                      onChange={handleRoleChange}
                    >
                      {role &&
                        role
                          .filter(
                            (role) => role.roleName.toLowerCase() !== "student"
                          )
                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.roleName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel>Employee</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="selectEmployee"
                      label="selectEmployee"
                      value={selectEmployee}
                      onChange={handleEmployeeSelect}
                    >
                      {femployee &&
                        femployee.map((row) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.basicInfo.empName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              display="flex"
              alignSelf="center"
              justifyContent="flex-start"
            >
              <LoadingButton
                loading={downloadLoader}
                variant="contained"
                size="small"
                type="submit"
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                }}
                onClick={handleGetDownloadSheet}
              >
                Download
              </LoadingButton>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2} mt="2px">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent="flex-end"
            >
              <LoadingButton
                loading={downloadLoader}
                variant="contained"
                size="small"
                type="submit"
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                }}
                onClick={handleGetDownloadSheet}
              >
                Download
              </LoadingButton>
            </Grid>
          </Grid> */}
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper sx={{ padding: 2, marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-select-small-label">
                  Academic Year
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Academic Year"
                  value={academicYearId}
                  onChange={(event) => setAcademicYearId(event.target.value)}
                >
                  {academicYear.length
                    ? academicYear.map((data, i) => (
                        <MenuItem value={data._id} key={data._id}>
                          {data.academicYearFrom}-{data.academicYearTo}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="From Date"
                    inputFormat="DD-MM-YYYY"
                    value={fromDate || ""}
                    onChange={setFromDate}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DatePicker
                    label="To Date"
                    inputFormat="DD-MM-YYYY"
                    value={toDate || ""}
                    onChange={setToDate}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-select-small-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type"
                  name="leaveType"
                  value={addForm.leaveType || ""}
                  onChange={handleaddChange}
                  style={{ label: { fontSize: 12 } }}
                >
                  {leaveTypeData.map((data, i) => (
                    <MenuItem key={i} value={data._id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl size="small" required fullWidth>
                <InputLabel id="demo-select-small-label">User Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="userleave"
                  label="User Type"
                  value={userleave || ""}
                  onChange={handleTypeUserChange}
                  style={{ label: { fontSize: 12 } }}
                >
                  <MenuItem value={"student"}>Student</MenuItem>
                  <MenuItem value={"employee"}>Employee</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {userleave === "student" && (
              <>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-select-small-label">Class</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="class"
                      label="Class"
                      value={formData.class || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel id="demo-select-small-label">
                      Section
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="section"
                      label="Section"
                      value={formData.section || ""}
                      required
                      onChange={(event) => handleSections(event)}
                    >
                      {sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required>
                    <Autocomplete
                      size="small"
                      value={selectStudent}
                      fullWidth
                      onChange={handlePdfStudentSelect}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      options={students}
                      getOptionLabel={(option) => `${option.basicInfo.name} `}
                      renderInput={(params) => (
                        <TextField
                          id="outlined-size-small"
                          required
                          fullWidth
                          {...params}
                          label="Students"
                          placeholder="Search student with name"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {userleave === "employee" && (
              <>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel id="demo-select-small-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="role"
                      label="Role"
                      value={formData.role}
                      onChange={handleRoleChange}
                    >
                      {role &&
                        role
                          .filter(
                            (role) => role.roleName.toLowerCase() !== "student"
                          )
                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.roleName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-select-small-label">
                      Employee
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="selectUserEmployee"
                      label="selectEmployee"
                      value={selectUserEmployee}
                      onChange={handlePdfEmployeeSelect}
                    >
                      {femployee &&
                        femployee.map((row) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.basicInfo.empName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              display="flex"
              // justifyContent="flex-end"
              alignSelf="center"
            >
              <LoadingButton
                loading={printLoader}
                variant="contained"
                size="small"
                type="submit"
                sx={
                  {
                    // display: "flex",
                    // justifyContent: "flex-end",
                    // alignItems: "center",
                  }
                }
                style={{
                  color: "white",
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                onClick={handleGetPrintPdf}
              >
                Print
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </TabPanel>
    </Wrapper>
  );
};

export default LeaveReport;
