import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";

// style
import css from "../../../../styles/fees/QuickFeeCollectModal.module.css";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
const style = {
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
const QuickFeeCollectModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = useState({
    payment: "",
  });

  const [paymentDetails, setPaymentDetails] = useState({});

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePaymentDetails = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({});
    props.onSubmit(
      {
        ...formData,
      },
      paymentDetails
    );
    setPaymentDetails({});
  };

  const onPreview = (e) => {
    e.preventDefault();
    props.onPreviewButtonClick(
      {
        ...formData,
      },
      paymentDetails
    );
  };

  const handleClose = () => {
    setFormData({
      payment: "cash",
    });
    props.onClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <Box sx={style}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                fontSize="20px"
                fontWeight="bold"
              >
                Collect Fee
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body">
                <b>Total Amount Being Paid:</b>{" "}
                {`₹${Number(props?.payingAmount).toFixed(1)}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl required={true}>
                <FormLabel>Select payment method</FormLabel>
                <RadioGroup
                  row
                  value={formData.payment || ""}
                  name="payment"
                  onChange={handleFormChange}
                >
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <FormControlLabel
                      value="Cash"
                      control={<Radio />}
                      label="CASH"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <FormControlLabel
                      value="Cheque"
                      control={<Radio />}
                      label="Cheque"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={2}
                    lg={2}
                    display="flex"
                    justifyContent={{
                      xs: "flex-start",
                      sm: "flex-start",
                      md: "center",
                      lg: "center",
                    }}
                  >
                    <FormControlLabel
                      value="DD"
                      control={<Radio />}
                      label="DD"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <FormControlLabel
                      value="Upi"
                      control={<Radio />}
                      label="UPI"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <FormControlLabel
                      value="Card"
                      control={<Radio />}
                      label="Card"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <FormControlLabel
                      value="Netbanking"
                      control={<Radio />}
                      label="Netbanking"
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                label="Note"
                multiline
                rows={3}
                name="note"
                value={props.note || ""}
                size="small"
                fullWidth
                onChange={(e) => props.onUpdateNote(e.target.value)}
              />
            </Grid>

            {formData.payment === "Cheque" && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Bank Name"
                    value={paymentDetails.bankName || ""}
                    fullWidth
                    name="bankName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Branch Name"
                    value={paymentDetails.branchName || ""}
                    fullWidth
                    name="branchName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Cheque Number"
                    value={paymentDetails.chequeNumber || ""}
                    fullWidth
                    name="chequeNumber"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Cheque Date"
                      value={paymentDetails.chequeDate || ""}
                      onChange={(newDate) =>
                        handlePaymentDetails({
                          target: {
                            name: "chequeDate",
                            value: newDate,
                          },
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}

            {formData.payment === "DD" && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Bank Name"
                    value={paymentDetails.bankName || ""}
                    fullWidth
                    name="bankName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Branch Name"
                    value={paymentDetails.branchName || ""}
                    fullWidth
                    name="branchName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
              </>
            )}

            {(formData.payment === "Upi" ||
              formData.payment === "Netbanking") && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Bank Name"
                    value={paymentDetails.bankName || ""}
                    fullWidth
                    name="bankName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Ref Number"
                    value={paymentDetails.refNumber || ""}
                    fullWidth
                    name="refNumber"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Paid By"
                    value={paymentDetails.paidByName || ""}
                    fullWidth
                    name="paidByName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
              </>
            )}

            {formData.payment === "Card" && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Bank Name"
                    value={paymentDetails.bankName || ""}
                    fullWidth
                    name="bankName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Card Type"
                    value={paymentDetails.cardType || ""}
                    fullWidth
                    name="cardType"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
              </>
            )}

            {/* {formData.payment === "Netbanking" && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Bank Name"
                    value={paymentDetails.bankName || ""}
                    fullWidth
                    name="bankName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    size="small"
                    label="Branch Name"
                    value={paymentDetails.branchName || ""}
                    fullWidth
                    name="branchName"
                    onChange={handlePaymentDetails}
                  />
                </Grid>
              </>
            )} */}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent="flex-end"
              gap={2}
            >
              <Button
                onClick={() => handleClose()}
                variant="contained"
                color="error"
              >
                cancel
              </Button>
              <LoadingButton
                loading={props.collectingFee}
                type="submit"
                variant="contained"
                sx={{
                  background: "#1B3779",
                  "&:hover": {
                    background: "#1B3779",
                  },
                }}
              >
                collect
              </LoadingButton>
              <LoadingButton
                loading={props.downloadingPreview}
                onClick={onPreview}
                variant="contained"
                sx={{
                  background: "#1B3779",
                  "&:hover": {
                    background: "#1B3779",
                  },
                }}
              >
                preview
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
};

export default QuickFeeCollectModal;
