import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import Wrapper from "../components/Wrapper";
// import { LoadingButton } from "@mui/lab";

const Headnng = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 18,
  textAlign: "center",
}));

const StudentResetPassword = ({ handleCloseModal }) => {
  let [loader, setLoader] = useState(false);

  return (
    <Wrapper>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Headnng>Reset Password</Headnng>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Old Password"
              required
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="New Password"
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="flex-end"
            gap={1}
          >
            <Button variant="outlined" color="error" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              loading={loader}
              variant="contained"
              sx={{
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Wrapper>
  );
};

export default StudentResetPassword;
