import { Clear } from "@material-ui/icons";
import { Button, IconButton, Paper, TextField } from "@mui/material";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import { del, get, post } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../Academic/DeleteModal";
import css from "../../../styles/Birthday.module.css";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

const Birthday = () => {
  const inputRef = useRef();
  const { selectedSetting } = useContext(SettingContext);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [birthdays, setBirthdays] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const getBirthdays = async () => {
      try {
        const birthdays = await get(`${urls.birthday.getBirthday}`);
        if (birthdays.data.success) {
          setBirthdays(birthdays.data.data);
        }
      } catch (error) {}
    };
    getBirthdays();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const fileForm = new FormData();
      fileForm.append("files", image);
      const imageRes = await post(urls.fileUpload.postFileUpload, fileForm);
      if (imageRes.data.success) {
        const data = {
          title,
          image: imageRes.data.result[0]._id,
        };
        await post(`${urls.birthday.createBirthday}`, data);
        inputRef.current.value = "";
        setTitle("");
        const birthdays = await get(`${urls.birthday.getBirthday}`);
        if (birthdays.data.success) {
          setBirthdays(birthdays.data.data);
          console.log("setBirthdays", birthdays.data.data);
        }
      }
      setTitle("");
      setImage(null);
    } catch (error) {}
    setLoader(false);
  };
  const handleDelete = async (id) => {
    try {
      const deleted = await del(`${urls.birthday.deleteBirthday}/${id}`, "");
      if (deleted.data.success) {
        setBirthdays((prevState) =>
          prevState.filter((birthday) => birthday._id !== id)
        );
      }
    } catch (error) {}
  };
  return (
    <div className={css.container}>
      <Paper className={css.paper}>
        <form className={css.form} onSubmit={handleSubmit}>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            size="small"
          />
          <input
            id="inputFile"
            ref={inputRef}
            type="file"
            accept="image/*"
            required
            onChange={(e) => setImage(e.target.files[0])}
          />
          <LoadingButton
            loading={loader}
            size="small"
            variant="contained"
            type="submit"
          >
            ADD IMAGE
          </LoadingButton>
        </form>
        <div className={css.imageFlex}>
          {birthdays.map((birthday) => (
            <>
              {" "}
              <div key={birthday._id} className={css.imageContainer}>
                <img className={css.img} src={birthday.image.link} alt="" />
                <IconButton
                  className={css.btn}
                  onClick={() => setDeleteModal(birthday._id)}
                  color="error"
                >
                  <Clear />
                </IconButton>
              </div>
              <DeleteModal
                deleteModal={deleteModal}
                handleDelete={handleDelete}
                id={birthday._id}
                setDeleteModal={setDeleteModal}
              />
            </>
          ))}
        </div>

        <div className={css.noData}>
          {!birthdays.length && <h5>No Images</h5>}
        </div>
      </Paper>
    </div>
  );
};
export default Birthday;
