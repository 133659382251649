import React from "react";
import css from "../../styles/Dashboard/Dashboard.module.css";
import { useState, useEffect, useContext } from "react";
import { get } from "../../services/apis";
import dayjs from "dayjs";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue, red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { urls as url } from "../../services/urlConstant";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";

const Notice = () => {
  const [announceNotice, setAnounceNotice] = useState([]);
  const [expanded, setExpanded] = React.useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  useEffect(() => {
    const getNotice = async () => {
      try {
        const response = await get(`${urls.studentDashboard.getnotice}`, {
          params: {
            search: {
              webView: true,
            },
          },
        });
        setAnounceNotice([...response.data.data]);
        console.log(response, "hhhhhhhhhhh");
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, [selectedSetting]);

  const handleExpandClick = (index) => {
    // setExpanded(!expanded);
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  return (
    <div className={css.scrollableCard}>
      <div>
        {announceNotice && announceNotice.length > 0 ? (
          announceNotice.map((news, i) => (
            <React.Fragment key={i}>
              <Box className={css.cardScroll}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      N
                    </Avatar>
                  }
                  action={
                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded[i]}
                        onClick={() => handleExpandClick(i)}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                  }
                  title={
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      {news.title}
                    </Typography>
                  }
                  subheader={
                    <Typography
                      style={{
                        display: "flex",
                        textAlign: "left",
                        fontSize: 14,
                      }}
                    >
                      {dayjs(news.date).format("DD/MM/YYYY")}
                    </Typography>
                  }
                />

                <Collapse
                  in={expanded[i]}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    padding: 2,
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <CardMedia
                  // component="img"
                  // height="194"
                  // image=""
                  // alt="no image"
                  />
                  <Typography
                    paragraph
                    sx={{ textAlign: "left", fontSize: 14 }}
                  >
                    {news.notice}
                  </Typography>
                </Collapse>
              </Box>
            </React.Fragment>
          ))
        ) : (
          <Typography sx={{ textAlign: "center" }}>No notice found.</Typography>
        )}
      </div>
    </div>
  );
};

export default Notice;
