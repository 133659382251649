import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import * as Yup from "yup";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactQuill from "react-quill";
import { Delete, Edit, Search } from "@mui/icons-material";
import { get, post, del, put } from "../../../services/apis";
import { useFormik } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import ShowComponent from "../../ShowComponent";

// icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReactToPrint, { useReactToPrint } from "react-to-print";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SearchField = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down(900)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(685)]: {
    justifyContent: "flex-start",
  },
}));

const FormikError = styled(Typography)(() => ({
  color: "#d32f2f",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  fontSize: "0.75rem",
  marginTop: "50px",
}));

const columns = [
  {
    id: "name",
    label: "Employee Name",
    align: "center",
  },
  {
    id: "joining",
    label: "Joining Date",
    align: "center",
  },
  {
    id: "created",
    label: "Created",
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    align: "center",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "0 15px 0 15px" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RelievingLetterComponents() {
  const [value, setValue] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [relievingLetter, setRelievingLetter] = React.useState([]);
  const { selectedSetting } = React.useContext(SettingContext);
  const [loadingBtn, setLoadingBtn] = useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [name, setName] = useState("");
  const [dates, setDates] = useState();
  const [notes, setNotes] = useState("");
  const [editId, setEditId] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [addLoading, setAddLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [modalData, setModalData] = useState({
    open: false,
    containt: "",
    action: () => {},
  });

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClickOpen = (id, element) => {
    // console.log(element, "---element");
    setModalData({
      ...modalData,
      open: true,
      containt: element.relievingLetter,
      action: () => handleGetDownloadSheet(id),
    });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setEditId(null);
    setName("");
    setDates();
    setNotes("");
  };

  // get relieving letter
  const handleGetRelievingLetter = async () => {
    try {
      const res = await get(`${urls.relievingLetter.getListRelievingLetter}`, {
        headers: { roleFunction: "relievingletter" },
      });
      setRelievingLetter(res.data.data);
      setSearchFilter(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  //
  useEffect(() => {
    handleGetRelievingLetter();
  }, []);

  // handle delete
  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.relievingLetter.delete}`,
        id,
        {},
        { roleFunction: "relievingletter" }
      );
      if (res.data.success) {
        const filteredEvent = relievingLetter.filter((item) => item._id !== id);
        setRelievingLetter(filteredEvent);
        handleGetRelievingLetter();
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   =========ADD RELIEVING LETTER HANDLER ==========
  const onHandleFormik = useFormik({
    initialValues: {
      name: "",
      joiningDate: "",
      relievingLetter: `<p>DIPTI PRADHAN </p>
                  <p>Rajajingar, Banglore, Karnataka</p>
                  <p>From: Excellent English Medium Primary & High School Ittangihal road, Vijaypur, Karnataka 586103</p>
                  <p>Contact: +91 876xxxxxxxxx</p>
                  <p>E Mail: dpxxxxx@gmail.com YY</p>
                  <br/>
                  <p>Date: DD, MM, YY,</p>
                  <br/>
                  <p>Subject: Relieving Letter</p>
                  <br/>
                  <p>Dear Dipti,</p>
                  <br/>
                  <p>This is in reference to your resignation dated March 05, 2016 wherein you had requested to be relieved from your services on March 31, 2016. We wish to inform you that your resignation has been accepted and you shall be relieved from your duties as Manager – Operations, post serving notice period, with effect from March 31, 2016.</p>
                  <br/>
                  <p>We would also want to confirm that your full & final settlement would be cleared in the next 30 days with the organization. We appreciate your contributions made to the organization and wish you all the best for your future endeavors.</p>
                  <br/>
                  <p>Regards,</p>
                  <p>HR, Manager</p> `,
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Employee Name"),
      joiningDate: Yup.string().required().label("Joining Date"),
      relievingLetter: Yup.string().required().label("relieving Letter"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          name: values.name,
          joiningDate: values.joiningDate,
          relievingLetter: values.relievingLetter,
        };

        const res = await post(
          `${urls.relievingLetter.createRelievingLetter}`,
          body,
          { headers: { roleFunction: "relievingletter" } }
        );
        if (res.data.success) {
          onHandleFormik.resetForm();
          handleGetRelievingLetter();
          setValue(0);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
  });

  // download sheet
  const handleGetDownloadSheet = async (id) => {
    setLoadingBtn(id);

    try {
      const getDownload = await get(
        `${selectedSetting._id}/relievingletter/downloadpdf/${id}`,
        {
          headers: { roleFunction: "relievingletter" },
          responseType: "blob",
        }
      );

      const uri = URL.createObjectURL(getDownload.data);

      const link = document.createElement("a");
      link.href = uri;
      link.target = "_blank"; // Open in a new tab
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link); // Remove the link element
      setLoadingBtn("");
    } catch (error) {
      console.error(error);
    }
  };

  // update handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddLoading(true);
    try {
      const res = await put(
        `${urls.relievingLetter.update}/${editId}`,
        "",
        {
          name: name,
          joiningDate: dates,
          relievingLetter: notes,
        },
        { headers: { roleFunction: "relievingletter" } }
      );
      if (res.data.success) {
        setValue(0);
        handleGetRelievingLetter();
        setEditId(null);
        setName("");
        setDates();
        setNotes("");
        setAddLoading(false);
      }
    } catch (error) {
      console.error(error);
      setValue(0);
      setEditId(null);
      setAddLoading(false);
    }
  };

  // Update
  const handleEdit = (id, data) => {
    setEditId(id);
    setValue(2);
    setName(data.name);
    setDates(data.joiningDate);
    setNotes(data.relievingLetter);
  };

  // change notes
  const handleNotesChange = (content) => {
    setNotes(content);
  };

  // search Employee
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() != "") {
      setSearchFilter(
        relievingLetter.filter((data) =>
          data.name.toLowerCase().includes(value.trim().toLowerCase())
        )
      );
    } else {
      setSearchFilter([...relievingLetter]);
    }
  };

  return (
    <>
      <Wrapper>
        <SectionSubHeader title="Relieving Letter" />
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "15px 0",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Relieving Letter List" {...a11yProps(0)} />
            <Tab label="Add Relieving Letter" {...a11yProps(1)} />
            {editId && <Tab label="Edit Relieving Letter" {...a11yProps(2)} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SearchField container spacing={1} my={2}>
            <Grid item xs="auto">
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search Employee"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </SearchField>
          <TableContainer component={Paper}>
            <Table aria-labelledby="tableTitle" size="small">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center" style={{ color: "#fff" }}>
                    #SL
                  </TableCell>

                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: "#fff" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                              {dayjs(row.joiningDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(row.createdAt).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              <div className="edit-delete">
                                <Tooltip title="View">
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      handleClickOpen(row._id, row)
                                    }
                                  >
                                    <VisibilityIcon
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <ShowComponent
                                  roleFunction="relievingletter"
                                  action="update"
                                >
                                  <Tooltip title="Update">
                                    <IconButton
                                      style={{ color: "#1b3779" }}
                                      size="small"
                                      onClick={(e) => handleEdit(row._id, row)}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <ShowComponent
                                  roleFunction="relievingletter"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      color="error"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                        color="error"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>

                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={row._id}
                                  setDeleteModal={setDeleteModal}
                                />
                                {/* <LoadingButton
                                  size="small"
                                  loading={loadingBtn === row._id}
                                  onClick={() =>
                                    handleGetDownloadSheet(row._id)
                                  }
                                >
                                  <FileDownloadIcon />
                                </LoadingButton> */}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : relievingLetter
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell align="center">
                              {page * rowsPerPage + i + 1}
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                              {dayjs(row.joiningDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(row.createdAt).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              <div className="edit-delete">
                                <Tooltip title="View">
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      handleClickOpen(row._id, row)
                                    }
                                  >
                                    <VisibilityIcon
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <ShowComponent
                                  roleFunction="relievingletter"
                                  action="update"
                                >
                                  <Tooltip title="Update">
                                    <IconButton
                                      style={{ color: "#1b3779" }}
                                      size="small"
                                      onClick={(e) => handleEdit(row._id, row)}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <ShowComponent
                                  roleFunction="relievingletter"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      color="error"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                        color="error"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>

                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={row._id}
                                  setDeleteModal={setDeleteModal}
                                />
                                {/* <LoadingButton
                                  size="small"
                                  loading={loadingBtn === row._id}
                                  onClick={() =>
                                    handleGetDownloadSheet(row._id)
                                  }
                                >
                                  <FileDownloadIcon />
                                </LoadingButton> */}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
              </TableBody>
            </Table>
            {searchFilter.length === 0 || relievingLetter.length === 0 ? (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            ) : (
              ""
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={search ? searchFilter.length : relievingLetter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              my: 1,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            container
            spacing={2}
            component="form"
            mt={1}
            onSubmit={onHandleFormik.handleSubmit}
          >
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    color: "gray",
                    fontSize: "small",
                  }}
                >
                  Employee Name*
                </FormLabel>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  name="name"
                  size="small"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "Name",
                  }}
                  value={onHandleFormik.values.name}
                  onChange={onHandleFormik.handleChange}
                  onBlur={onHandleFormik.handleBlur}
                  error={
                    onHandleFormik.touched.name && onHandleFormik.errors.name
                      ? true
                      : false
                  }
                  helperText={
                    onHandleFormik.touched.name
                      ? onHandleFormik.errors.name
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "small",
                    color: "gray",
                  }}
                >
                  Joining Date*
                </FormLabel>

                <TextField
                  size="small"
                  name="joiningDate"
                  type="date"
                  sx={{
                    minWidth: { md: 227, xs: "100%" },
                  }}
                  value={onHandleFormik.values.joiningDate}
                  onChange={onHandleFormik.handleChange}
                  onBlur={onHandleFormik.handleBlur}
                  error={
                    onHandleFormik.touched.joiningDate &&
                    onHandleFormik.errors.joiningDate
                      ? true
                      : false
                  }
                  helperText={
                    onHandleFormik.touched.joiningDate
                      ? onHandleFormik.errors.joiningDate
                      : ""
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <FormLabel
                sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
              >
                Relieving Letter*
              </FormLabel>
              <ReactQuill
                theme="snow"
                name="relievingLetter"
                style={{
                  height: "210px",
                }}
                value={onHandleFormik.values.relievingLetter}
                onChange={(v) =>
                  onHandleFormik.setFieldValue("relievingLetter", v)
                }
                onBlur={onHandleFormik.handleBlur}
                error={
                  onHandleFormik.touched.relievingLetter &&
                  onHandleFormik.errors.relievingLetter
                    ? true
                    : false
                }
              />
              <FormikError variant="body">
                {onHandleFormik.touched.relievingLetter
                  ? onHandleFormik.errors.relievingLetter
                  : ""}
              </FormikError>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
              }}
            >
              <Button variant="contained" size="small" color="error">
                Cancel
              </Button>
              <ShowComponent roleFunction="relievingletter" action="add">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  Submit
                </LoadingButton>
              </ShowComponent>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <form>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={12} lg={4}>
                <FormControl fullWidth required>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      color: "gray",
                      fontSize: "small",
                    }}
                  >
                    Employee Name*
                  </FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <FormControl fullWidth required>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "small",
                      color: "gray",
                    }}
                  >
                    Joining Date*
                  </FormLabel>
                  <TextField
                    type="date"
                    // label="date"
                    size="small"
                    name="dates"
                    value={dayjs(dates).format("YYYY-MM-DD")}
                    onChange={(e) => setDates(e.target.value)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <FormLabel
                  sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
                >
                  Relieving Letter*
                </FormLabel>
                <ReactQuill
                  theme="snow"
                  contentType="html"
                  style={{
                    height: "150px",
                  }}
                  value={notes}
                  onChange={handleNotesChange} // Properly handle the onChange event
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "2px",
                mt: 6,
              }}
            >
              <Button variant="contained" size="small" color="error">
                Cancel
              </Button>
              <LoadingButton
                loading={addLoading}
                size="small"
                variant="contained"
                type="submit"
                sx={{
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                onClick={handleSubmit}
              >
                Update
              </LoadingButton>
            </Box>
          </form>
        </TabPanel>

        {/* =============view offer-letter========== */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={modalData.open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Relieving Letter
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent ref={componentRef}>
            <Typography gutterBottom>
              <div
                dangerouslySetInnerHTML={{ __html: modalData.containt }}
              ></div>
            </Typography>
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => (
                <LoadingButton
                  variant="contained"
                  size="small"
                  loading={loadingBtn}
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  onClick={handlePrint}
                  startIcon={<PrintIcon />}
                >
                  Print
                </LoadingButton>
              )}
              content={() => componentRef.current}
            />
            {/* <LoadingButton
                size="small"
                loading={loadingBtn}
                onClick={modalData.action}
              >
                <FileDownloadIcon />
                Download
              </LoadingButton> */}
            {/* </Tooltip> */}
            {/* <Button autoFocus onClick={handleClose}>
              Download
            </Button> */}
          </DialogActions>
        </BootstrapDialog>
      </Wrapper>
    </>
  );
}

export default RelievingLetterComponents;
