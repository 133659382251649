import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  TableContainer,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TablePagination,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Menu, Search } from "@mui/icons-material";
import SectionSubHeader from "../../SectionSubHeader";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { setMonth } from "date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import Overview from "../Student/Overview";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { makeStyles, styled } from "@mui/styles";
import { BsBox2, BsBoxArrowDown } from "react-icons/bs";
import ShowComponent from "../../ShowComponent";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "1px solid black",
    padding: "4px",
    marginRight: "4px", // Add margin for space
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 460,
  bgcolor: "background.paper",
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    width: "100%",
  },
}));

const ItemInventory = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [fromDate, setFromDate] = useState(dayjs(Date.now()));
  const [toDate, setToDate] = useState(dayjs(Date.now()));
  const [findLoader, setFindLoader] = useState(false);
  const [stock, setStock] = useState([]);
  const [overView, setOverView] = useState([]);
  const [totalPurpose, setTotalPurpose] = useState([]);
  const [totalInvenCount, setTotalInventCount] = useState([]);
  const [totalInvenAmount, setTotalInventAmount] = useState([]);
  const [issueAmount, setIssueAmount] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = useState({
    name: "asc",
    price: "asc",
    stock: "asc",
  });

  const sortByName = () => {
    if (sort.name == "asc") {
      const soldListed = [
        ...stock.sort((a, b) => b.name.localeCompare(a.name)),
      ];
      setStock(soldListed);
      setSort({ ...sort, name: "des" });
    } else {
      const soldListed = [
        ...stock.sort((a, b) => a.name.localeCompare(b.name)),
      ];
      setStock(soldListed);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByStock = () => {
    if (sort.stock == "asc") {
      const soldListed = [...stock.sort((a, b) => b.totalStock - a.totalStock)];
      setStock(soldListed);
      setSort({ ...sort, stock: "des" });
    } else {
      const soldListed = [...stock.sort((a, b) => a.totalStock - b.totalStock)];
      setStock(soldListed);
      setSort({ ...sort, stock: "asc" });
    }
  };

  const sortByPrice = () => {
    if (sort.price == "asc") {
      const soldListed = [...stock.sort((a, b) => b.totalPrice - a.totalPrice)];
      setStock(soldListed);
      setSort({ ...sort, price: "des" });
    } else {
      const soldListed = [...stock.sort((a, b) => a.totalPrice - b.totalPrice)];
      setStock(soldListed);
      setSort({ ...sort, price: "asc" });
    }
  };

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ==============================

  const navigate = useNavigate();
  let { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabValue(1);
    }
  }, []);

  const styles = {
    button: {
      color: "#ffff",
      backgroundColor: "#1b3779",
      ":hover": { background: "rgb(27, 55, 121)" },
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  let handleNavigate = () => {
    navigate("/dashboard/vendor/", { state: "inventory" });
  };

  let handleNavigateItem = () => {
    navigate("/dashboard/item", { state: "item" });
  };
  let handleNavigateSell = () => {
    navigate("/dashboard/sold", { state: "sold" });
  };
  let handleNavigateIssue = () => {
    navigate("/dashboard/issueDetails", { state: "issue" });
  };

  let handleNavigateStock = () => {
    navigate("/dashboard/transaction", { state: "stock" });
  };

  const handleSearchHistory = async (e) => {
    setFindLoader(true);

    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.stockList.getAll, {
        headers: { roleFunction: "itemInventory" },
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setStock(searchHistory.data.data);
      console.log(searchHistory.data.data, "AAAAAA");
      setOverView(searchHistory.data.purposesTotalPrice);
      setTotalPurpose(searchHistory.data.totalAllPurposeTotalPrice);
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  const handleTotalInventoryCount = async (e) => {
    setFindLoader(true);

    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.transaction.totalInventoryCount, {
        headers: { roleFunction: "itemInventory" },
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setTotalInventCount(searchHistory.data);
      console.log(searchHistory.data, "setTotalInventCount");
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  const handleTotalInventoryAmount = async (e) => {
    setFindLoader(true);

    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.totalInventoryAmount.get, {
        headers: { roleFunction: "itemInventory" },
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setTotalInventAmount(searchHistory.data.data);
      setIssueAmount(searchHistory.data.totalIssueQuantity);
      console.log(searchHistory.data.totalIssueQuantity, "totalIssueQuantity");
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  console.log(issueAmount, "issueAmount");

  return (
    <>
      <SectionSubHeader title="Item Stock" style={{ margin: "15px" }} />
      <div style={{ margin: "15px" }}>
        {/* <Card style={{ padding: "10px", margin: "10px 0px" }}>
          <div style={styles.cardButton}>
            <Button size="small" variant="contained">
              Excel
            </Button>

            <Button size="small" variant="contained">
              Pdf
            </Button>
          </div>
        </Card> */}

        <Paper sx={{ p: 2 }}>
          {/* <Typography
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              paddingBottom: "5px",
              paddingLeft: "5px",
            }}
          >
            Select By Date Range
          </Typography> */}

          <Box>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearchHistory();
                handleTotalInventoryCount();
                handleTotalInventoryAmount();
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} mt={1.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={4} mt={1.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD-MM-YYYY"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12} lg={2} sx={{ mt: 1.5 }}>
                  <LoadingButton
                    loading={findLoader}
                    style={styles.button}
                    // size="small"
                    type="submit"
                  >
                    Find
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "5px",
                marginRight: "5px",
                pt: "10px",
                pb: "10px",
              }}
            >
              <Stack
                spacing={1}
                direction={{ xs: "column", sm: "row" }}
                textAlign={{ xs: "center" }}
                mt={2}
              >
                <ShowComponent roleFunction="itemInventory" action="add">
                  <Button
                    style={styles.button}
                    onClick={() => handleNavigateSell()}
                  >
                    Sell
                  </Button>

                  <ShowComponent roleFunction="itemInventory" action="add">
                    <Button
                      style={styles.button}
                      onClick={() => handleNavigateIssue()}
                    >
                      Issue
                    </Button>
                  </ShowComponent>
                </ShowComponent>
                <ShowComponent roleFunction="itemInventory" action="add">
                  <Button
                    style={styles.button}
                    onClick={() => handleNavigateItem()}
                  >
                    Add Item
                  </Button>
                </ShowComponent>
                <ShowComponent roleFunction="itemInventory" action="add">
                  <Button
                    sx={{ mr: 3 }}
                    style={styles.button}
                    onClick={() => handleNavigate()}
                  >
                    Add vendor
                  </Button>
                </ShowComponent>
              </Stack>
            </Box>
          </Box>
        </Paper>
        <Box sx={styles.cardButton} mt={2}>
          <Button size="small" variant="contained">
            Excel
          </Button>

          <Button size="small" variant="contained">
            Pdf
          </Button>
        </Box>
        <TableContainer
          sx={{
            display: "grid",
            // mt: "20px",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              textAlign: "start",
            }}
          >
            Overview
          </Typography>
          {/* ========= Desktop view =============== */}
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "inline-block",
                lg: "inline-block",
              },
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell
                  align="left"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Total Purchase
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Purchase ? overView.Purchase : 0}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Total Issue
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {issueAmount.length !== 0 ? issueAmount : 0}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Total Return
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Return ? overView.Return : 0}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Total Inhouse
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Inhouse ? overView.Inhouse : 0}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell
                  align="left"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Total Damage
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Damage ? overView.Damage : 0}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Total Sell
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Sell ? overView.Sell : 0}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Total Inventory Quantity
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  {totalInvenCount.length !== 0 ? totalInvenCount.data : 0}
                  {"  "}Qty
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Total Inventory Amount
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {issueAmount.length !== 0 ? issueAmount : 0}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          {/* ======== Tablet view table ============ */}
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              display: {
                xs: "none",
                sm: "inline-block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Purchase
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Purchase ? overView.Purchase : 0}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Issue
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {issueAmount.length !== 0 ? issueAmount : 0}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Return
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Return ? overView.Return : 0}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Inhouse
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Inhouse ? overView.Inhouse : 0}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Damage
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Damage ? overView.Damage : 0}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Sell
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Sell ? overView.Sell : 0}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Inventory Quantity
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  {totalInvenCount.length !== 0 ? totalInvenCount.data : 0}
                  {"  "}Qty
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Inventory Amount
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {issueAmount.length !== 0 ? issueAmount : 0}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          {/* ======== Mobile view table ============ */}
          <Table
            aria-label="customized table"
            className="profile-table"
            sx={{
              display: {
                xs: "inline-block",
                sm: "none",
                md: "none",
                lg: "none",
              },
            }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Purchase
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Purchase ? overView.Purchase : 0}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Issue
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {issueAmount.length !== 0 ? issueAmount : 0}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Return
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Return ? overView.Return : 0}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Inhouse
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Inhouse ? overView.Inhouse : 0}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Damage
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Damage ? overView.Damage : 0}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Sell
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {overView.Sell ? overView.Sell : 0}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Inventory Quantity
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  {totalInvenCount.length !== 0 ? totalInvenCount.data : 0}
                  {"  "}Qty
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                  Total Inventory Amount
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  <CurrencyRupeeIcon fontSize="small" />
                  {issueAmount.length !== 0 ? issueAmount : 0}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" sx={{ fontWeight: "600", marginTop: "20px" }}>
          List of Stocks
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Description
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByPrice} className="class-table-header">
                    Total Price
                    <span style={{ cursor: "pointer" }}>
                      {sort.price === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByStock} className="class-table-header">
                    Total Stock
                    <span style={{ cursor: "pointer" }}>
                      {sort.stock === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stock
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{data.name}</TableCell>
                    <TableCell align="center">{data.description}</TableCell>
                    <TableCell align="center">
                      <CurrencyRupeeIcon fontSize="small" />
                      {data.totalPrice}
                    </TableCell>
                    <TableCell align="center">{data.totalStock}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!stock.length && stock && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No Data Found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={stock.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />
      </div>
    </>
  );
};

export default ItemInventory;
