import React from "react";
import { Grid } from "@material-ui/core";
import classroom from "../../assets/Images/icon-classroom.png";
import Sports from "../../assets/Images/icon-playground.png";
import Library from "../../assets/Images/icon-library.png";
import Laboratory from "../../assets/Images/icon-laboratory.png";
import Canteen from "../../assets/Images/icon-canteen.png";
import CCTV from "../../assets/Images/icon-cctv.png";
import "../../styles/components/Infrastructure/Infrastructure.scss";
import { Link } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const TextBox1 = styled(Box)(({ theme }) => ({
  marginTop: "5%",
  // marginLeft:"5%",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Infrastructure = () => {
  const infraItems = [
    {
      src: classroom,
      title: "Classroom",
      link: "/facilities/edusmart",
    },
    {
      src: Sports,
      title: "Sports",
      link: "/facilities/sports",
    },
    {
      src: Library,
      title: "Library",
      link: "/facilities/library",
    },
    {
      src: Laboratory,
      title: "Laboratory",
      link: "/",
    },

    {
      src: Canteen,
      title: "Canteen",
      link: "/facilities/canteen",
    },
    {
      src: CCTV,
      title: "CCTV",
      link: "/",
    },
  ];
  return (
    <div className="infrastructure">
      <div className="main-container_infra">
        {/* <div>
          <h1 className="infrastructure-title">
            INFRASTRUCTURE
            <div className="all-line-1">
              <div className="first-line"></div>
              <div className="middle-line"></div>
              <div className="first-line last-line"></div>
            </div>
          </h1>
        </div> */}

        <TextBox1>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box>
              <Typography
                variant="h3"
                color="black"
                sx={{ fontWeight: "bold", fontSize: "40px" }}>
                Infrastructure
              </Typography>
            </Box>
            {/* &nbsp;&nbsp;
            <Box>
              <Typography
                variant="h3"
                color="#f86f03"
                sx={{ fontWeight: "bold", fontSize: "40px" }}>
                Feedback
              </Typography>
            </Box> */}
          </Box>
        </TextBox1>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}>
          <Typography component="p">_____________</Typography>
          <FiberManualRecordIcon sx={{ fontSize: "8px", marginTop: "15px" }} />
          <FiberManualRecordIcon
            sx={{
              color: "#F86F03",
              fontSize: "10px",
              marginTop: "14px",
              marginLeft: "5px",
            }}
          />
          <FiberManualRecordIcon
            sx={{ fontSize: "8px", marginTop: "15px", marginLeft: "6px" }}
          />
          <Typography component="p">_____________</Typography>
        </Box>
        <div>
          <Grid container justyfy="center" spacing={0.5}>
            {infraItems.map((item) => (
              <Grid item xs={12} sm={6} md={2}>
                <Link to={`${item.link}`} style={{ color: "inherit" }}>
                  <img src={item.src} alt="" />
                  <div className="infra-title">{item.title}</div>
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Infrastructure;
