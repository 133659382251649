import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Box,
  CardActionArea,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { get } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";

import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { TableBody } from "@material-ui/core";
import dayjs from "dayjs";

const TableHeader = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: 600,
}));
const TableData = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  alignItems: "center",
}));

const DataBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  padding: "8px",
}));

const ExamVenue = () => {
  const { id } = useParams();
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [venulist, setVenueList] = useState(null);
  useEffect(() => {
    const getVenu = async () => {
      try {
        const res = await get(`${urls.preExamSchedules.getVenueDetails}${id}`);
        setVenueList(res.data.data);
        console.log(res, "data");
      } catch (error) {
        console.log(error);
      }
    };
    getVenu();
  }, []);

  console.log(id, "examVenuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu");

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Card sx={{ borderRadius: "10px", boxShadow: "5px" }}>
            <CardActionArea>
              <CardContent>
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#2196f3",
                    padding: "8px",
                    color: "#ffff",
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    Venue Details
                  </Typography>
                </Box>
                <DataBox>
                  {" "}
                  <Typography sx={{ fontWeight: "18px", fontWeight: 300 }}>
                    Academic Year : {venulist?.academicYear?.academicYearFrom}-
                    {venulist?.academicYear?.academicYearTo}
                  </Typography>
                </DataBox>
                <DataBox>
                  <Typography sx={{ fontWeight: "18px", fontWeight: 300 }}>
                    Class : {venulist?.class?.className}
                  </Typography>
                </DataBox>

                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableHeader align="center">Date & Time</TableHeader>
                      <TableHeader align="center">Room Number</TableHeader>{" "}
                      <TableHeader align="center">Address</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableData align="center">
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography sx={{ mb: 0.5 }}>
                            {" "}
                            {dayjs(venulist?.dateOfExam).format("DD-MM-YYYY")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            Start Time - {venulist?.startTime}
                          </Typography>
                          <Typography>
                            End Time - {venulist?.endTime}
                          </Typography>
                        </Box>
                      </TableData>
                      <TableData align="center">
                        {venulist?.venue?.roomNumber}
                      </TableData>
                      <TableData align="center">
                        {venulist?.venue?.address}
                      </TableData>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default ExamVenue;
