import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { LoadingButton } from "@mui/lab";
import React, { useState, useContext } from "react";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(localeData);

const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    // m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const EmployeeAttendanceReport = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [reportDetails, setReportDetails] = useState({});
  const [roles, setRoles] = useState([]);
  const [attendanceReports, setAttendanceReports] = useState([]);
  const [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [fromDate, setFromDate] = useState(dayjs(Date.now()));
  const [toDate, setToDate] = useState(dayjs(Date.now()));
  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole, {
          headers: { roleFunction: "employeeAttendanceTest" },
        });
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, [selectedSetting]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setReportDetails({ ...reportDetails, [name]: value });
  };

  const handleAttendanceReportSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const studentAttendanceReportRes = await get(
        `${urls.employee.getemployeeReport}/${reportDetails.role}/${fromDate}/${toDate}`,
        { headers: { roleFunction: "employeeAttendanceTest" } }
      );
      if (studentAttendanceReportRes.data.success) {
        setAttendanceReports(studentAttendanceReportRes.data.data);
        console.log(studentAttendanceReportRes, "kjkjkjkjkjkj");
      } else {
        console.log(studentAttendanceReportRes);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  return (
    <>
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleAttendanceReportSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Roles
                  </InputLabel>
                  <Select
                    label="Roles"
                    size="small"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="role"
                    value={reportDetails.role || ""}
                    onChange={(event) => handleOnChange(event)}
                  >
                    {roles.length > 0 &&
                      roles.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.roleName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} lg={4} sx={{ alignSelf: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="fromDate"
                    value={fromDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setFromDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={styles.textfield}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sx={{ alignSelf: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="toDate"
                    value={toDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setToDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={styles.textfield}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: {
                    xs: "flex-end",
                    sm: "flex-end",
                    md: "flex-start",
                    lg: "flex-start",
                  },
                }}
              >
                <LoadingButton
                  loading={loader}
                  variant="contained"
                  sx={{
                    color: "#fff",
                    background: "#1b3779",
                    "&:hover": {
                      background: "#1b3779",
                    },
                  }}
                  size="small"
                  type="submit"
                >
                  Find
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">Employee Name</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Working Days</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Present Days</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Percentage</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceReports.length > 0 &&
              attendanceReports
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((attendanceReport, index) => (
                  <TableRow
                    key={attendanceReport._id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {attendanceReport.employee.basicInfo.empName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {attendanceReport.attendanceList.length}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {
                        attendanceReport.attendanceList.filter(
                          (ele) => ele.status == "present"
                        ).length
                      }
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {Math.ceil(
                        (attendanceReport.attendanceList.filter(
                          (ele) => ele.status == "present"
                        ).length /
                          attendanceReport.attendanceList.length) *
                          100
                      )}
                      %
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!attendanceReports.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={attendanceReports.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />
    </>
  );
};

export default EmployeeAttendanceReport;
