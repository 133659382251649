import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export default function AddModel(props) {
  console.log(props.loading, "---ff");
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontWeight: 600 }}>{props.actionTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.descriptions}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button
            variant="plain"
            color="neutral"
            size="small"
            sx={{
              background: "#ddd",
              ":hover": { background: "#ddd", opacity: 0.8 },
            }}
            onClick={props.handleClose}
          >
            No, cancel
          </Button>
          <LoadingButton
            loading={props.loading}
            onClick={props.handleSubmitModal}
            variant="contained"
            size="small"
            sx={{
              background: "#1b3779",
              "&:hover": {
                background: "#1b3779",
              },
            }}
          >
            Yes, I'm sure
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
