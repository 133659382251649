import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@material-ui/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { TableContainer } from "@material-ui/core";
import ShowComponent from "../ShowComponent";
const style = {
  position: "relative",
  width: "100%",
  maxWidth: "1200px",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const styling = {
  fontSize: "12px",
  textAlign: "left",
  fontWeight: 600,
};

function TableRows({
  rows,
  tableRowRemove,
  onValUpdate,
  editRoute,
  onAddClick = () => {},
  onUpdateClick = () => {},
  onDeleteClick = () => {},
}) {
  const theme = useTheme();
  return rows
    ? rows.map((rowData, index) => {
        const {
          stopName,
          stopKM,
          pickTime,
          pickEndTime,
          dropTime,
          dropEndTime,
        } = rowData;

        const handleValueUpdate = (event) => {
          onValUpdate(index, event);
        };

        return (
          <Grid
            container
            spacing={1}
            key={index}
            sx={{
              marginBottom: "10px",
              backgroundColor: theme.palette.grey[100],
              borderRadius: "5px",
              padding: "0 15px 20px 15px",
              ml: 0.2,

              border: "1px solid lightgrey",
              marginTop: "10px",
            }}
          >
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography sx={styling}>Stop Name</Typography>
              <TextField
                size="small"
                fullWidth
                type="text"
                value={stopName}
                onChange={handleValueUpdate}
                name="stopName"
                placeholder="stop Name"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography sx={styling}> Stop Km</Typography>
              <TextField
                size="small"
                fullWidth
                type="text"
                value={stopKM}
                onChange={handleValueUpdate}
                name="stopKM"
                placeholder="stop Km"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography sx={styling}> Pick Start</Typography>
              <TextField
                size="small"
                fullWidth
                type="time"
                value={pickTime}
                onChange={handleValueUpdate}
                name="pickTime"
                placeholder="Pick Time"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography sx={styling}>Pick End</Typography>
              <TextField
                size="small"
                fullWidth
                type="time"
                value={pickEndTime}
                onChange={handleValueUpdate}
                name="pickEndTime"
                placeholder="Drop Time"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography sx={styling}> Drop Start</Typography>
              <TextField
                size="small"
                fullWidth
                type="time"
                value={dropTime}
                onChange={handleValueUpdate}
                name="dropTime"
                placeholder="Drop Time"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography sx={styling}> Drop End</Typography>
              <TextField
                size="small"
                fullWidth
                type="time"
                value={dropEndTime}
                onChange={handleValueUpdate}
                name="dropEndTime"
                placeholder="Drop End"
              />
            </Grid>

            {!editRoute ? (
              <Grid item xs={1} sm={1} md={1} lg={1} sx={{ mt: 3 }}>
                <Button onClick={(e) => tableRowRemove(index, e)}>
                  <RemoveIcon color="error" fontSize="medium" />
                </Button>
              </Grid>
            ) : (
              <Grid item xs={1} sm={1} md={1} lg={1} sx={{ mt: 3 }}>
                <>
                  {rowData._id ? (
                    <Stack direction="row" spacing={1}>
                      <LoadingButton
                        onClick={() => onUpdateClick(rowData)}
                        variant="contained"
                        size="small"
                      >
                        Update
                      </LoadingButton>
                      <LoadingButton
                        onClick={() => onDeleteClick(rowData)}
                        variant="outlined"
                        color="error"
                        size="small"
                      >
                        Delete
                      </LoadingButton>
                    </Stack>
                  ) : (
                    <Stack direction="row" spacing={1}>
                      <LoadingButton
                        onClick={() => onAddClick(rowData, editRoute)}
                        variant="contained"
                        size="small"
                      >
                        Add
                      </LoadingButton>
                      <Button onClick={(e) => tableRowRemove(index, e)}>
                        <RemoveIcon color="error" fontSize="medium" />
                      </Button>
                    </Stack>
                  )}
                </>
              </Grid>
            )}
          </Grid>
        );
      })
    : "";
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const styles = {
  button: {
    backgroundColor: "orange",
  },
  textfield: {
    marginTop: "25px",
    // width: "70%",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
  tab: {
    width: "100%",
    borderCollapse: "collapse",
  },
  cardButton: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "center",
  },
};

const ManageRoute = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [addForm, setAddForm] = useState({
    transportRouteTitle: "",
    vehicleNumber: "",
    routeStart: "",
    routeEnd: "",
    pickStart: "",
    pickEnd: "",
    dropStart: "",
    dropEnd: "",
    note: "",
    routeDetails: [],
  });
  const [open, setOpen] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [list, setList] = React.useState([]);
  const [editRoute, setEditRoute] = useState();
  const [dialog, setOpenDialog] = React.useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [rows, setRows] = useState([]);
  const [pickStart, setPickStart] = useState("");
  const [pickEnd, setPickEnd] = useState("");
  const [dropStart, setDropStart] = useState("");
  const [dropEnd, setDropEnd] = useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);

  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);

  const [sort, setSort] = useState({
    title: "asc",
    routeStart: "asc",
    routeEnd: "asc",
    vehicleNumber: "asc",
  });

  const sortByTitle = () => {
    if (sort.title === "asc") {
      const sortedList = [
        ...list.sort((a, b) =>
          b.transportRouteTitle.localeCompare(a.transportRouteTitle)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) =>
          a.transportRouteTitle.localeCompare(b.transportRouteTitle)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };

  const sortByRouteStart = () => {
    if (sort.routeStart === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.routeStart.localeCompare(a.routeStart)),
      ];
      setList(sortedList);
      setSort({ ...sort, routeStart: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.routeStart.localeCompare(b.routeStart)),
      ];
      setList(sortedList);
      setSort({ ...sort, routeStart: "asc" });
    }
  };

  const sortByRouteEnd = () => {
    if (sort.routeEnd === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.routeEnd.localeCompare(a.routeEnd)),
      ];
      setList(sortedList);
      setSort({ ...sort, routeEnd: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.routeEnd.localeCompare(b.routeEnd)),
      ];
      setList(sortedList);
      setSort({ ...sort, routeEnd: "asc" });
    }
  };

  const sortByVehicleNumber = () => {
    if (sort.vehicleNumber === "asc") {
      const sortedList = [
        ...list.sort((a, b) =>
          b.vehicleNumber.vehicleNumber.localeCompare(
            a.vehicleNumber.vehicleNumber
          )
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, vehicleNumber: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) =>
          a.vehicleNumber.vehicleNumber.localeCompare(
            b.vehicleNumber.vehicleNumber
          )
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, vehicleNumber: "asc" });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };

  useEffect(() => {
    const initialRow = {
      stopName: "",
      stopKM: "",
      pickTime: "",
      pickEndTime: "",
      dropTime: "",
      dropEndTime: "",
    };

    setAddForm((prevAddForm) => ({
      ...prevAddForm,
      routeDetails: [initialRow],
    }));
  }, [selectedSetting]);

  const addRowTable = (e) => {
    // console.log(e, "event");
    e.preventDefault();
    const data = {
      stopName: "",
      stopKM: "",
      pickTime: "",
      pickEndTime: "",
      dropTime: "",
      dropEndTime: "",
    };
    let initialRouteList = [...addForm.routeDetails, data];
    setAddForm({ ...addForm, routeDetails: initialRouteList });
    // console.log(initialRouteList, "ininininininin");
  };

  const tableRowRemove = (index, e) => {
    e.preventDefault();
    let newRouteList = addForm.routeDetails?.filter((item, i) => i !== index);
    setAddForm({ ...addForm, routeDetails: newRouteList });
  };

  const onValUpdate = (i, event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let initialRoute = [...addForm.routeDetails];
    let newRoutes = [];
    for (let b of initialRoute) {
      if (initialRoute.indexOf(b) == i) {
        let newItem = { ...b, [name]: value };
        newRoutes.push(newItem);
      } else {
        newRoutes.push(b);
      }
    }
    setAddForm({ ...addForm, routeDetails: newRoutes });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter(
            (ele) =>
              ele.transportRouteTitle
                .toLowerCase()
                .includes(value.toLowerCase().trim()) ||
              ele.routeStart
                .toLowerCase()
                .includes(value.toLowerCase().trim()) ||
              ele.routeEnd.toLowerCase().includes(value.toLowerCase().trim()) ||
              ele.vehicleNumber.vehicleNumber
                .toLowerCase()
                .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditRoute(null);
    setAddForm({
      transportRouteTitle: "",
      vehicleNumber: "",
      routeStart: "",
      routeEnd: "",
      pickStart: "",
      pickEnd: "",
      dropStart: "",
      dropEnd: "",
      note: "",
      routeDetails: [
        {
          stopName: "",
          stopKM: "",
          pickTime: "",
          pickEndTime: "",
          dropTime: "",
          dropEndTime: "",
          fee: "",
        },
      ],
    });
    setOpen(false);
    setPickStart("");
    setPickEnd("");
    setDropStart("");
    setDropEnd("");
    setRows([]);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickOpenDialog = (route) => {
    setShowDetails(route);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };

  useEffect(() => {
    getRoute();
  }, [selectedSetting]);

  const getRoute = async () => {
    try {
      const { data, status } = await get(urls.route.getList, {
        headers: { roleFunction: "route" },
      });
      setList(data.data);
      setSearchFilter(
        data.data.filter(
          (ele) =>
            ele.transportRouteTitle
              .toLowerCase()
              .includes(search.toLowerCase().trim()) ||
            ele.routeStart
              .toLowerCase()
              .includes(search.toLowerCase().trim()) ||
            ele.routeEnd.toLowerCase().includes(search.toLowerCase().trim()) ||
            ele.vehicleNumber.vehicleNumber
              .toLowerCase()
              .includes(search.toLowerCase().trim())
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setList([]);
  }, [selectedSetting]);

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editRoute) {
        const { status } = await post(
          `${urls.route.create}`,
          {
            transportRouteTitle: addForm.transportRouteTitle,
            vehicleNumber: addForm.vehicleNumber,
            routeStart: addForm.routeStart,
            routeEnd: addForm.routeEnd,
            pickStart: pickStart,
            pickEnd: pickEnd,
            dropStart: dropStart,
            dropEnd: dropEnd,
            routeDetails: addForm.routeDetails,
            note: addForm.note,
          },
          { headers: { roleFunction: "route" } }
        );
        getRoute();
        handleClose();
        setLoader(false);
      } else {
        const { status } = await put(
          `${urls.route.update}${editRoute}`,
          "",
          {
            transportRouteTitle: addForm.transportRouteTitle,
            vehicleNumber: addForm.vehicleNumber,
            routeStart: addForm.routeStart,
            routeEnd: addForm.routeEnd,
            pickStart: pickStart,
            pickEnd: pickEnd,
            dropStart: dropStart,
            dropEnd: dropEnd,
            note: addForm.note,
          },
          { headers: { roleFunction: "route" } }
        );
        getRoute();
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handlePickStart = (e) => {
    setPickStart(e.target.value);
  };
  const handlePickEnd = (e) => {
    setPickEnd(e.target.value);
  };

  const handleDropStart = (e) => {
    setDropStart(e.target.value);
  };
  const handleDropEnd = (e) => {
    setDropEnd(e.target.value);
  };

  useEffect(() => {
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`, {
          headers: { roleFunction: "route" },
        });

        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
          console.log(data.data, "pppppppppp");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, [selectedSetting]);

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.route.deletRoute}`,
        id,
        {},
        { roleFunction: "route" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRouteEdit = (id, data) => {
    setEditRoute(id);
    setAddForm({
      transportRouteTitle: data.transportRouteTitle,
      vehicleNumber: data.vehicleNumber._id,
      routeStart: data.routeStart,
      routeEnd: data.routeEnd,
      pickStart: data.pickStart,
      pickEnd: data.pickEnd,
      dropStart: data.dropStart,
      dropEnd: data.dropEnd,
      routeDetails: data.routeDetails,
      note: data.note,
    });
    setPickEnd(data.pickEnd);
    setPickStart(data.pickStart);
    setDropStart(data.dropStart);
    setDropEnd(data.dropEnd);
    handleClickOpen();
  };

  // bulk upload
  const handleDownloadSample = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/route/downloadsample`, {
        headers: { roleFunction: "route" },
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `sample-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSheetUpload = async (e) => {
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);
      const response = await put(`/library/uploadsample`, "", formData, {
        headers: { roleFunction: "route" },
      });
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      // getbooks();
    } catch (error) {
      console.error(error);
    }
  };

  // stops

  const handleAddStopClick = async (stopData, route) => {
    try {
      const { data } = await post(urls.route.addStop + route, stopData, {
        headers: { roleFunction: "route" },
      });
      setAddForm((prev) => ({ ...prev, routeDetails: data.data.routeDetails }));
      getRoute();
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateStopClick = async (stopData) => {
    try {
      const { data } = await put(
        urls.route.updateStop + stopData._id,
        "",
        stopData,
        {
          headers: { roleFunction: "route" },
        }
      );
      setAddForm((prev) => ({ ...prev, routeDetails: data.data.routeDetails }));
      getRoute();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteStopClick = async (stopData) => {
    try {
      const { data } = await del(
        urls.route.deleteStop,
        stopData._id,
        {},
        { roleFunction: "route" }
      );
      setAddForm((prev) => ({ ...prev, routeDetails: data.data.routeDetails }));
      getRoute();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Manage Route & Trips" />
      <Box sx={{ margin: "15px 0" }}>
        <Box sx={styles.cardButton}>
          <Button variant="contained" onClick={() => setBulkUpdateModal(true)}>
            Bulk Transport Route Update
          </Button>
        </Box>
      </Box>

      <Box sx={{ margin: "15px 0" }}>
        <Box
          sx={{
            paddingTop: "22px",
            margin: "20px 0px",
          }}
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid xs="auto">
              <TextField
                fullWidth
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search Title"
                placeholder="Enter Place Route"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByTitle} className="class-table-header">
                    Route Title
                    <span style={{ cursor: "pointer" }}>
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByRouteStart}
                    className="class-table-header"
                  >
                    Route Start
                    <span style={{ cursor: "pointer" }}>
                      {sort.routeStart === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByRouteEnd} className="class-table-header">
                    Route End
                    <span style={{ cursor: "pointer" }}>
                      {sort.routeEnd === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByVehicleNumber}
                    className="class-table-header"
                  >
                    Vehicle For Route
                    <span style={{ cursor: "pointer" }}>
                      {sort.vehicleNumber === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Stop Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((route, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {route.transportRouteTitle}
                          </TableCell>
                          <TableCell align="center">
                            {route.routeStart}
                          </TableCell>
                          <TableCell align="center">{route.routeEnd}</TableCell>
                          <TableCell align="center">
                            {route.vehicleNumber
                              ? route.vehicleNumber.vehicleNumber
                              : "NA"}
                          </TableCell>
                          <TableCell align="center">
                            {route.routeDetails
                              ?.map((n) => n.stopName)
                              .join(" || ")}
                          </TableCell>

                          <TableCell align="center">
                            <Box className="edit-delete">
                              <Tooltip title="View">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => handleClickOpenDialog(route)}
                                >
                                  <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                              <ShowComponent
                                roleFunction="route"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    sx={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleRouteEdit(route._id, route)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>

                              <ShowComponent
                                roleFunction="route"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      setDeleteModal(route._id, route)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={route._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((route, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {route.transportRouteTitle}
                          </TableCell>
                          <TableCell align="center">
                            {route.routeStart}
                          </TableCell>
                          <TableCell align="center">{route.routeEnd}</TableCell>
                          <TableCell align="center">
                            {route.vehicleNumber
                              ? route.vehicleNumber.vehicleNumber
                              : "NA"}
                          </TableCell>
                          <TableCell align="center">
                            {route.routeDetails
                              ?.map((n) => n.stopName)
                              .join(" || ")}
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              className="edit-delete"
                            >
                              <Tooltip title="View">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => handleClickOpenDialog(route)}
                                >
                                  <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                              <ShowComponent
                                roleFunction="route"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    sx={{ color: "#1b3779" }}
                                    onClick={() =>
                                      handleRouteEdit(route._id, route)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="route"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      setDeleteModal(route._id, route)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={route._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!list.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        {/* =======Add Route======= */}
        <Box className="add-icon">
          <ShowComponent roleFunction="route" action="add">
            <Tooltip title="Add Route & Trips">
              <Fab
                variant="contained"
                sx={{
                  color: "#ffff",
                  backgroundColor: "#1b3779",
                  "&:hover": { color: "#ffff", backgroundColor: "#1b3779" },
                }}
                onClick={handleClickOpen}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </ShowComponent>
        </Box>
        {/* ==================== */}

        {/*============= View Route modal =========== */}
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Trasport Route Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <Box>
                    <tr>
                      <td>School Name</td>
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td>Route Title</td>
                      <td>{showDetails.transportRouteTitle}</td>
                    </tr>
                    <tr>
                      <td>Route Start</td>
                      <td>{showDetails.routeStart}</td>
                    </tr>
                    <tr>
                      <td>Route End</td>
                      <td>{showDetails.routeEnd}</td>
                    </tr>
                    <tr>
                      <td>Vehicle For Route</td>
                      <td>{showDetails.vehicleNumber.vehicleNumber}</td>
                    </tr>

                    <tr>
                      <td>Route Stops</td>
                      <td>
                        <tr>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Stop Name
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Stop KM
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Pick Start
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Pick End
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Drop Start
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Drop End
                          </th>
                        </tr>
                        {showDetails.routeDetails?.map((route, index) => (
                          <tr key={index}>
                            <td>{route.stopName}</td>
                            <td>{route.stopKM}</td>
                            <td>{route.pickTime}</td>
                            <td>{route.pickEndTime}</td>
                            <td>{route.dropTime}</td>
                            <td>{route.dropEndTime}</td>
                          </tr>
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                  </Box>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
        {/* ==================== */}

        {/*============= add Route modal =========== */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    {editRoute ? "Update Route" : "Add Route"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Transport Route Title"
                    name="transportRouteTitle"
                    value={addForm.transportRouteTitle || ""}
                    onChange={handleAddForm}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel required sx={{ fontSize: 12 }}>
                      Vehicle Number
                    </InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="vehicle number"
                      name="vehicleNumber"
                      onChange={handleAddForm}
                      value={addForm.vehicleNumber || ""}
                    >
                      {vehicleNumber.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.vehicleNumber}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Route Start"
                    name="routeStart"
                    onChange={handleAddForm}
                    value={addForm.routeStart || ""}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Route End"
                    name="routeEnd"
                    onChange={handleAddForm}
                    value={addForm.routeEnd || ""}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    type="time"
                    value={pickStart}
                    onChange={handlePickStart}
                    label="Pick Start"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Pick End"
                    type="time"
                    value={pickEnd}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handlePickEnd}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Drop Start"
                    type="time"
                    value={dropStart}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleDropStart}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Drop End"
                    type="time"
                    onChange={handleDropEnd}
                    value={dropEnd}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <table className="table table-striped">
                    <tbody>
                      <TableRows
                        editRoute={editRoute}
                        rows={addForm.routeDetails}
                        tableRowRemove={tableRowRemove}
                        onValUpdate={onValUpdate}
                        onAddClick={handleAddStopClick}
                        onDeleteClick={handleDeleteStopClick}
                        onUpdateClick={handleUpdateStopClick}
                      />
                    </tbody>
                    <Button
                      size="small"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        color: "white",
                        ":hover": { background: "rgb(27, 55, 121)" },
                        marginTop: "5px",
                        cursor: "pointer",
                      }}
                      onClick={addRowTable}
                    >
                      Add more
                    </Button>
                  </table>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ paddingTop: "0px" }}
                >
                  <TextareaAutosize
                    fullWidth
                    maxRows={4}
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                      borderRadius: "5px",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    size="small"
                    type="button"
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    {editRoute ? "update" : " Add"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
        {/* ===================== */}

        {/*============== Bulk tranport route Modal============ */}
        <Dialog
          fullScreen={fullScreen}
          open={bulkUpdateModal}
          onClose={() => setBulkUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                    onChange={handleAddForm}
                  >
                    Bulk Upload
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={loadingBtn}
                    // disabled
                    sx={{
                      backgroundColor: "#ef6c00",
                      ":hover": { backgroundColor: "#ef6c00" },
                      width: "100%",
                    }}
                  >
                    Sample
                  </LoadingButton>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    disabled
                    sx={{ width: "100%" }}
                  >
                    Upload
                    <input
                      name="file"
                      hidden
                      accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                      type="file"
                    />
                  </Button>

                  <Box>{fileChoosen && fileChoosen.name}</Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%" }}
                    type="submit"
                    // disabled
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography textAlign="center" fontWeight={600}>
                    Under Development
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>
        {/* ===================== */}
      </Box>
    </Wrapper>
  );
};

export default ManageRoute;
