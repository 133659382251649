import { Search } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import {
  Box,
  Button,
  Card,
  Dialog,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { useTheme } from "@mui/material/styles";
import ShowComponent from "../../ShowComponent";
import { LoadingButton } from "@mui/lab";

import Autocomplete from "@mui/material/Autocomplete";

const style = {
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const StudentActivity = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [formData, setFormData] = useState({ student: "" });
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [student, setStudent] = useState([]);
  const [open, setOpen] = useState("");
  const [date, setDate] = useState(Date.now());
  const [list, setList] = React.useState([]);
  const [addForm, setAddForm] = useState({
    academicYear: "",
    student: "",
    name: "",
    description: "",
  });

  const [data, setData] = useState([{ name: "", date: "" }]);
  const [editActivity, setEditActivity] = useState();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [loader, setLoader] = useState(false);
  const [findLoader, setFindLoader] = useState(false);

  const [academicYearsForModal, setAcademicYearsForModal] = useState([]);
  const [studentsForModal, setStudentsForModal] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = useState({
    name: "asc",
    date: "asc",
  });
  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "studentactivity" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "studentactivity" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      console.log(section, "==descs==");
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(sections, "==sections");
  useEffect(() => {
    const getsearchStudents = async () => {
      try {
        if (!formData.className || !formData.section) return;
        const response = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "studentactivity" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.className,
              "academicInfo.section": formData.section,
            },
          },
        });
        setStudent(response.data.data);
        console.log("setStudent", response.data.data);
      } catch (error) {}
    };
    getsearchStudents();
  }, [formData.className, formData.section]);

  useEffect(() => {
    const getAcademicYearsForModal = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "studentactivity" },
        });
        setAcademicYearsForModal(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYearsForModal();
  }, []);

  useEffect(() => {
    const getStudentsForModal = async () => {
      try {
        const response = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "studentactivity" },
          params: {
            search: {
              academicYear: addForm.academicYear,
            },
          },
        });
        setStudentsForModal(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStudentsForModal();
  }, []);

  // ... (previous code)

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "studentactivity" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "studentactivity" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });

        setSections(resSections.data.data);
        setClasses(res.data.data);
        setSections(resSections.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]?._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  console.log(formData, "formData========");

  const styles = {
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
    },
    card: {
      padding: 15,
      marginBottom: 20,
    },
    textfield: {
      width: "100%",
      label: { fontSize: 12 },
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
    },
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm({
      ...addForm,
      [name]: value,
    });
  };
  console.log(addForm, "gfff");

  const handleClickOpen = () => {
    // if (!formData.student) return alert("Please select student..");
    setOpen(true);
  };

  const handleClose = () => {
    setEditActivity(null);
    setAddForm({});
    setOpen(false);
  };

  const getActivity = async () => {
    setFindLoader(true);
    try {
      const res = await get(
        `${urls.studentActivity.getList}/${formData.student}/${formData.academicYear}`,
        {
          headers: { roleFunction: "studentactivity" },
          params: {
            student: formData.student,
            academicYear: formData.academicYear,
          },
        }
      );
      if (res.data.success) {
        setList(res.data.data);
      }
      console.log(data.data, "jkjkjk");
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editActivity) {
        const res = await post(
          `${urls.studentActivity.create}`,
          {
            date: date,
            name: addForm.name,
            description: addForm.description,
            student: addForm.student,
            academicYear: addForm.academicYear,
          },
          { headers: { roleFunction: "studentactivity" } }
        );
        if (res.data.success) {
          handleClose();
        }
      } else {
        const res = await put(
          `${urls.studentActivity.update}/${editActivity}`,
          "",
          {
            date: date,
            name: addForm.name,
            description: addForm.description,
            student: addForm.student,
            academicYear: addForm.academicYear,
          },
          { headers: { roleFunction: "studentactivity" } }
        );
        if (res.data.success) {
          getActivity();
          handleClose();
        }
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const sortByName = (e) => {
    console.log("name");
    if (sort.name == "asc") {
      let sortedList = [...list.sort((a, b) => b.name.localeCompare(a.name))];
      setList(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [...list.sort((a, b) => a.name.localeCompare(b.name))];
      setList(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByDate = (e) => {
    if (sort.date == "asc") {
      let sortedList = [...list.sort((a, b) => b.date.localeCompare(a.date))];
      setList(sortedList);
      setSort({ ...sort, date: "des" });
    } else {
      let sortedList = [...list.sort((a, b) => a.date.localeCompare(b.date))];
      setList(sortedList);
      setSort({ ...sort, date: "asc" });
    }
  };
  const handleDeleteone = async (id) => {
    try {
      console.log(id, "===deleteid");
      const res = await del(
        `${urls.studentActivity.delete}`,
        id,
        {},
        { roleFunction: "studentactivity" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleActivityEdit = (id, data) => {
    console.log(data, "ggggggggggggggggggggggggggggggggggggggggggggggggg");
    setEditActivity(id);
    setAddForm({
      name: data.name,
      description: data.description,
      student: data.student,
      academicYear: data.academicYear,
    });
    setDate(data.date);
    handleClickOpen();
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <>
      <Box style={{ margin: 15, padding: "10px" }}>
        <Card style={{ padding: "22px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} mg={12} lg={12}>
              <Typography
                style={{
                  color: "red",
                }}
              >
                Student Activity :
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 2, mt: 2 }}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Class</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="className"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                    size="small"
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Section</InputLabel>
                  <Select
                    label="Section"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth size="small" required>
                  <Autocomplete
                    options={studentsForModal}
                    getOptionLabel={(option) => option.basicInfo.name}
                    value={
                      studentsForModal.find(
                        (student) => student._id === addForm.student
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleAddForm({
                        target: {
                          name: "student",
                          value: newValue ? newValue._id : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Select Student"
                        size="small"
                        placeholder="Search student with name"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography>{option.basicInfo.name}</Typography>
                      </li>
                    )}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.basicInfo.name
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Select Student</InputLabel>
                  <Select
                    label="Select student"
                    id="demo-simple-select-filled"
                    name="student"
                    value={formData.student || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {student.map((row, index) => (
                      <MenuItem key={row._id} value={row._id}>
                        {row.basicInfo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent="flex-end"
              >
                <LoadingButton
                  loading={findLoader}
                  size="small"
                  onClick={getActivity}
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                >
                  Find
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Card>

        <Grid container spacing={1} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <form>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search Student"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
        </Grid>
        {/* </div>
      </Card> */}

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortByName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortByDate} className="class-table-header">
                    Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.date === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>

                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((activity, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{activity.name}</TableCell>
                        <TableCell align="center">
                          {dayjs(activity.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="studentactivity"
                            action="update"
                          >
                            <Tooltip title="Update">
                              <IconButton
                                style={{ color: "#1b3779" }}
                                onClick={() =>
                                  handleActivityEdit(activity._id, activity)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <ShowComponent
                            roleFunction="studentactivity"
                            action="delete"
                          >
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                onClick={() =>
                                  setDeleteModal(activity._id, activity)
                                }
                              >
                                <DeleteIcon color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={activity._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((activity, index) => (
                      <TableRow>
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">{activity.name}</TableCell>
                        <TableCell align="center">
                          {dayjs(activity.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            <ShowComponent
                              roleFunction="studentactivity"
                              action="update"
                            >
                              <Tooltip title="Update">
                                <IconButton
                                  size="small"
                                  style={{ color: "#1b3779" }}
                                  onClick={() =>
                                    handleActivityEdit(activity._id, activity)
                                  }
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent
                              roleFunction="studentactivity"
                              action="delete"
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => setDeleteModal(activity._id)}
                                >
                                  <DeleteIcon color="error" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDeleteone}
                              id={activity._id}
                              setDeleteModal={setDeleteModal}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!list.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </Box>

      {/* Add Student Activity */}
      <ShowComponent roleFunction="studentactivity" action="add">
        <div className="add-icon">
          <Tooltip title="Add Student Activity">
            <Fab
              variant="contained"
              style={{ color: "#ffff", backgroundColor: "#1b3779" }}
              onClick={handleClickOpen}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </div>
      </ShowComponent>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleAddSubmit} className="form-tag">
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Student Activity
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    label="Academic Year"
                    fullWidth
                    id="academic-year-select"
                    name="academicYear"
                    value={addForm.academicYear || ""}
                    onChange={handleAddForm}
                    size="small"
                  >
                    {academicYearsForModal.map((row) => (
                      <MenuItem key={row._id} value={row._id}>
                        {row.academicYearFrom} - {row.academicYearTo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Select Student</InputLabel>
                  <Select
                    label="Select student"
                    id="student-select"
                    name="student"
                    fullWidth
                    value={addForm.student || ""}
                    onChange={handleAddForm}
                    size="small"
                  >
                    {studentsForModal.map((row) => (
                      <MenuItem key={row._id} value={row._id}>
                        {row.basicInfo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl required fullWidth>
                  <TextField
                    fullWidth
                    label="Activity Name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                    name="name"
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <Stack spacing={2}> */}
                    <DatePicker
                      label="Select date"
                      inputFormat="DD-MM-YYYY"
                      value={date}
                      onChange={setDate}
                      renderInput={(params) => (
                        <TextField size="small" {...params} fullWidth />
                      )}
                    />
                    {/* </Stack> */}
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  aria-label="Activitiy Description"
                  placeholder="Activitiy Description"
                  name="description"
                  onChange={handleAddForm}
                  value={addForm.description || ""}
                  style={{
                    maxHeight: 110,
                    // marginTop: 20,
                    width: "100%",
                    padding: 10,
                    height: 118,
                    overflow: "auto",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                gap={1}
              >
                <Button
                  onClick={handleClose}
                  color="error"
                  size="small"
                  type="button"
                  variant="contained"
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={loader}
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  Add
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default StudentActivity;
