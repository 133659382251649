import {
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";
import ShowComponent from "../../ShowComponent";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const styles = {
  cardsMargin: {
    margin: "15px 0",
  },
  cardStyle: {
    margin: "15px 0",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginTop: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
    // marginLeft: "5px",
    // marginTop: "15px",
  },
  addbutton: {
    // paddingTop: "6.2px",
    // paddingBottom: "6.2px",
  },
};

const VehicleMaintenanceRepair = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabValue, setTabValue] = useState(0);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [addForm, setAddForm] = useState({});
  const [findForm, setFindForm] = useState({});
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [firmName, setFirmName] = React.useState([]);
  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editRepair, setEditRepair] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);

  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [loader, setLoader] = useState(false);
  const [loaderFind, setLoaderFind] = useState(false);
  const [repairLoader, setRepairLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ====================================

  const [sort, setSort] = useState({
    date: "asc",
    particulars: "asc",
    amount: "asc",
  });

  const sortByDate = () => {
    if (sort.date === "asc") {
      const sortedList = [...list.sort((a, b) => b.date.localeCompare(a.date))];
      setList(sortedList);
      setSort({ ...sort, date: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.date.localeCompare(b.date))];
      setList(sortedList);
      setSort({ ...sort, date: "asc" });
    }
  };

  const sortByParticulars = () => {
    if (sort.particulars === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.particulars.localeCompare(a.particulars)),
      ];
      setList(sortedList);
      setSort({ ...sort, particulars: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.particulars.localeCompare(b.particulars)),
      ];
      setList(sortedList);
      setSort({ ...sort, particulars: "asc" });
    }
  };

  const sortByAmount = () => {
    if (sort.amount === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.amount.localeCompare(a.amount)),
      ];
      setList(sortedList);
      setSort({ ...sort, amount: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.amount.localeCompare(b.amount)),
      ];
      setList(sortedList);
      setSort({ ...sort, amount: "asc" });
    }
  };

  useEffect(() => {
    setVehicleNumber([]);
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`, {
          headers: { roleFunction: "maintainance" },
        });
        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
          console.log(data.data, "dattttttttttt");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, [selectedSetting]);

  useEffect(() => {
    setFirmName([]);
    const getFirmName = async () => {
      try {
        const { data, status } = await get(`${urls.firm.getList}`, {
          headers: { roleFunction: "maintainance" },
        });
        if (status > 199 && status < 299) {
          setFirmName(data.data);
          console.log(data.data, "firmnammmmmmmeeee");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFirmName();
  }, [selectedSetting]);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name == "vehicleNumber") {
      let vehicle = vehicleNumber.filter((v) => v._id == value)[0];
      setSelectedVehicleNumber(vehicle.vehicleNumber);
    }
  };

  const handleFindForm = (e) => {
    const { name, value } = e.target;
    setFindForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name == "vehicleNumber") {
      let vehicle = vehicleNumber.filter((v) => v._id == value)[0];
      setSelectedVehicleNumber(vehicle.vehicleNumber);
    }
  };

  const handleClickOpen = () => {
    if (!findForm.vehicleNumber)
      return alert("Please select the vehicle number");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddForm({});
    setEditRepair("");
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.repair.delete}`,
        id,
        {},
        { roleFunction: "maintainance" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRepair = async () => {
    setLoaderFind(true);
    try {
      const fromDateObj = new Date(fromDate);
      fromDateObj.setDate(fromDateObj.getDate() - 1);
      const { data, status } = await get(urls.repair.getList, {
        headers: { roleFunction: "maintainance" },
        params: {
          vehicleNumber: findForm.vehicleNumber,
          firmName: findForm.firmName,
          fromDate: fromDateObj.toISOString(),
          toDate: toDate,
        },
      });
      setList(data.data);
      console.log(data.data, "gggg");
    } catch (error) {
      console.log(error);
    }
    setLoaderFind(false);
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/repair/downloadpdf/${findForm.vehicleNumber}/${findForm.firmName}/${fromDate}/${toDate}`,
        {
          headers: { roleFunction: "maintainance" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setRepairLoader(true);
    try {
      if (!editRepair) {
        const res = await post(
          `${urls.repair.create}`,
          {
            vehicleNumber: findForm.vehicleNumber,
            firmName: addForm.firmName,
            date: date,
            particulars: addForm.particulars,
            amount: addForm.amount,
          },
          { headers: { roleFunction: "maintainance" } }
        );
        if (res.data.success) {
          getRepair();
          handleClose();
          setRepairLoader(false);
        }
      } else {
        const res = await put(
          `${urls.repair.update}/${editRepair}`,
          "",
          {
            vehicleNumber: findForm.vehicleNumber,
            firmName: addForm.firmName,
            date: date,
            particulars: addForm.particulars,
            amount: addForm.amount,
          },
          { headers: { roleFunction: "maintainance" } }
        );
        if (res.data.success) {
          getRepair();
          handleClose();
          setRepairLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setRepairLoader(false);
    }
  };

  const handleRepairEdit = (id, data) => {
    setEditRepair(id);
    setAddForm({
      vehicleNumber: data.vehicleNumber,
      firmName: data.firmName._id,
      date: date,
      particulars: data.particulars,
      amount: data.amount,
    });
    setSelectedVehicleNumber(data.vehicleNumber.vehicleNumber);
    handleClickOpen();
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="flex-end" mb={1}>
        <ShowComponent roleFunction="maintainance" action="add">
          <Button
            variant="contained"
            style={styles.addbutton}
            onClick={handleClickOpen}
          >
            <AddIcon />
            Add
          </Button>
        </ShowComponent>
      </Grid>

      <Grid container spacing={1} mb={2} mt={1}>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth size="small" required>
            <InputLabel>Vehicle Number</InputLabel>
            <Select
              labelId="demo-simpless-select-filled-label"
              id="demo-simple-select-filled-l"
              label="vehicle number"
              name="vehicleNumber"
              onChange={handleFindForm}
              value={findForm.vehicleNumber || ""}
            >
              {vehicleNumber.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  {name.vehicleNumber}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth size="small" required>
            <InputLabel>Firm Name</InputLabel>
            <Select
              labelId="demo-simpless-select-filled-label"
              id="demo-simple-select-filled-l"
              label="Firm Name"
              name="firmName"
              onChange={handleFindForm}
              value={findForm.firmName || ""}
            >
              {firmName.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  {name.firmName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
              <DatePicker
                variant="standar"
                label="From Date"
                // inputFormat="DD-MM-YYYY"
                value={fromDate || ""}
                onChange={setFromDate}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={fromDate !== null && fromDate === null}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
              <DatePicker
                variant="standar"
                label="To Date"
                // inputFormat="DD-MM-YYYY"
                value={toDate || ""}
                onChange={setToDate}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={toDate !== null && toDate === null}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Stack spacing={1} direction="row">
            <LoadingButton
              loading={loaderFind}
              variant="contained"
              size="small"
              onClick={getRepair}
              // style={styles.Button}
              sx={{
                background: "#1b3779",
                ":hover": { background: "#1b3779" },
                color: "#fff",
              }}
            >
              Find
            </LoadingButton>
            <LoadingButton
              onClick={handleGetPrintPdf}
              variant="contained"
              size="small"
              sx={{
                background: "#1b3779",
                ":hover": { background: "#1b3779" },
              }}
              loading={loader}
            >
              Print
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByDate} className="class-table-header">
                  Date
                  <span style={{ cursor: "pointer" }}>
                    {sort.date === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span
                  onClick={sortByParticulars}
                  className="class-table-header"
                >
                  Particulars
                  <span style={{ cursor: "pointer" }}>
                    {sort.particulars === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByAmount} className="class-table-header">
                  Amount
                  <span style={{ cursor: "pointer" }}>
                    {sort.amount === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((repair, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {dayjs(repair.date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="center">{repair.particulars}</TableCell>
                  <TableCell align="center">{repair.amount}</TableCell>
                  <TableCell align="center">
                    <div className="edit-delete">
                      <ShowComponent
                        roleFunction="maintainance"
                        action="update"
                      >
                        <Tooltip title="Update">
                          <IconButton
                            style={{ color: "#1b3779" }}
                            onClick={() => handleRepairEdit(repair._id, repair)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ShowComponent>
                      <ShowComponent
                        roleFunction="maintainance"
                        action="delete"
                      >
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            onClick={() => setDeleteModal(repair._id, repair)}
                          >
                            <Delete fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                      </ShowComponent>
                      <DeleteModal
                        deleteModal={deleteModal}
                        handleDelete={handleDeleteone}
                        id={repair._id}
                        setDeleteModal={setDeleteModal}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        {!list.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start,",
          alignItems: "center",
          my: 1,
        }}
      />

      {/*================= Add/Update Repair Maintenance =========== */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  {editRepair
                    ? "Update Repair Maintenance"
                    : "Add Repair Maintenance"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="h4">{selectedVehicleNumber}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth size="small">
                  <InputLabel sx={{ fontSize: 8 }}>Firm</InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Firm Name"
                    name="firmName"
                    onChange={handleAddForm}
                    value={addForm.firmName || ""}
                  >
                    {firmName.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.firmName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ m: 1.3, minWidth: 227 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  // sx={{ m: 3, minWidth: 280 }}
                  label="Particulars"
                  name="particulars"
                  onChange={handleAddForm}
                  value={addForm.particulars || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  type="number"
                  // sx={{ m: 3, minWidth: 280 }}
                  label="Amount"
                  name="amount"
                  onChange={handleAddForm}
                  value={addForm.amount || ""}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      md: "right",
                      sm: "right",
                      xs: "center",
                      gap: "1%",
                    },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    size="small"
                    type="button"
                    sx={{ m: 1, color: "#fff" }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={repairLoader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    {editRepair ? "Update" : "Add"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      {/* ============================== */}
    </>
  );
};

export default VehicleMaintenanceRepair;
