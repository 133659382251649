import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  TextareaAutosize,
  Dialog,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SectionsSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Clear, Delete, Edit } from "@mui/icons-material";

const style = {
  bgcolor: "background.paper",
  borderRadius: "5px",
  p: 3,
};

const Tickets = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // table pagination============
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==========================

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFiles((prev) => [...prev, ...e.target.files]);
  };

  const removeFiles = (index) => {
    setSelectedFiles(selectedFiles.filter((file, pos) => pos !== index));
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Tickets" />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Subject
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Description
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
        </Table>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
        >
          No data found
        </Typography>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* === Add Tickets ========== */}
      <div className="add-icon">
        <Tooltip title="Add Tickets">
          <Fab
            variant="contained"
            style={{ color: "#ffff", backgroundColor: "#1b3779" }}
            onClick={handleOpen}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      {/* =========================== */}

      {/* === Report Problem Modal ======== */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style}>
          <form>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Report Problem
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextField placeholder="Subject" size="small" fullWidth />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Description"
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    padding: 10,
                    height: 70,
                    overflow: "auto",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Button
                  variant="contained"
                  style={{ background: "#1b3779", color: "#fff" }}
                  onClick={handleUploadButtonClick}
                >
                  Add Files
                  <input
                    hidden
                    multiple
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                {selectedFiles.map((image, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      alignItems: "center",
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                      padding: "10px",
                      mt: 1,
                      gap: "10px",
                    }}
                    key={index}
                  >
                    {image.name}
                    <IconButton size="small" onClick={() => removeFiles(index)}>
                      <Clear />
                    </IconButton>
                  </Box>
                ))}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ background: "#1b3779", color: "#fff" }}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
      {/* =========================== */}
    </Wrapper>
  );
};

export default Tickets;
