import React, { useContext, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  TablePagination,
  Box,
} from "@mui/material";
import { urls as url } from "../../../services/urlConstant";
import { del, post, put, get } from "../../../services/apis";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import DeleteModal from "../Academic/DeleteModal";
import AddUserRole from "./AddUserRole";

// styles
import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import ShowComponent from "../../ShowComponent";

const UserRole = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [schoolUserRole, setSchoolUserRole] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateUserRole, setUpdateUserRole] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ roleName: "asc" });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const [id, setId] = useState("");

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  let [loader, setLoader] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setUpdateUserRole(undefined);
    setId("");
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(
        urls.role.delRole,
        id,
        {},
        { roleFunction: "role" }
      );
      if (res.data.success) {
        setSchoolUserRole(
          schoolUserRole.filter((item) => item._id !== res.data.data._id)
        );
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, data) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await post(urls.role.postRole, data, {
        headers: { roleFunction: "role" },
      });
      if (res.data.success) {
        setSchoolUserRole([...schoolUserRole, res.data.data]);
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.role.putRole, id, data, {
        headers: { roleFunction: "role" },
      });
      setSchoolUserRole(
        schoolUserRole.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
      getUserRole();
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleEdit = async (id) => {
    try {
      setId(id);
      setOpen(true);
      const getUserRoleById = schoolUserRole.find((item) => item._id === id);
      setUpdateUserRole(getUserRoleById);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserRole = async () => {
    try {
      const res = await get(urls.role.getAllRole, {
        headers: { roleFunction: "role" },
      });
      setSchoolUserRole([...res.data.data]);
      setSearchFilter([...res.data.data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserRole();
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.trim() !== "") {
      setSearchFilter(
        schoolUserRole.filter((ele) =>
          ele.roleName.toLowerCase().includes(value.trim().toLowerCase())
        )
      );
    } else {
      setSearchFilter([...schoolUserRole]);
    }
  };

  const roleNameSorted = () => {
    console.log("roleName");
    if (sort.roleName == "asc") {
      let sortedList = [
        ...schoolUserRole.sort((a, b) => b.roleName.localeCompare(a.roleName)),
      ];

      setSchoolUserRole(sortedList);
      setSort({ ...sort, roleName: "des" });
    } else {
      let sortedList = [
        ...schoolUserRole.sort((a, b) => a.roleName.localeCompare(b.roleName)),
      ];
      setSchoolUserRole(sortedList);
      setSort({ ...sort, roleName: "asc" });
    }
  };

  console.log("schoolUserRole", schoolUserRole);

  return (
    <>
      <Card style={{ margin: 15, padding: "22px 0px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <Typography
              style={{
                color: "red",

                display: "inline-block",
              }}
            >
              User Role:
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Box style={{ margin: 15, padding: "22px 0px" }}>
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mb: 2 }}>
          <Grid xs="auto">
            <TextField
              size="small"
              value={search}
              placeholder="Enter Role Name"
              onChange={handleSearch}
              label="Search Role Name"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">
                    {" "}
                    Role Name
                    <span
                      onClick={roleNameSorted}
                      style={{ cursor: "pointer" }}
                    >
                      {sort.roleName === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.roleName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="role"
                                action="update"
                              >
                                <Tooltip title="Update" disableInteractive>
                                  <IconButton
                                    style={{ color: "#1b3779" }}
                                    size="small"
                                    onClick={() => handleEdit(row._id)}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>

                              <ShowComponent
                                roleFunction="role"
                                action="delete"
                              >
                                {row.canDelete ? null : (
                                  <Tooltip title="Delete" disableInteractive>
                                    <IconButton
                                      color="error"
                                      size="small"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </ShowComponent>
                            </div>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDelete}
                              id={row._id}
                              setDeleteModal={setDeleteModal}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                : schoolUserRole
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.roleName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            <div className="edit-delete">
                              <ShowComponent
                                roleFunction="role"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    style={{ color: "#1b3779" }}
                                    size="small"
                                    onClick={() => handleEdit(row._id)}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="role"
                                action="delete"
                              >
                                {!row.canDelete ? null : (
                                  <Tooltip title="Delete" disableInteractive>
                                    <IconButton
                                      color="error"
                                      size="small"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </ShowComponent>
                            </div>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDelete}
                              id={row._id}
                              setDeleteModal={setDeleteModal}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!schoolUserRole.length || !searchFilter.length ? (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          ) : (
            ""
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : schoolUserRole.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        <ShowComponent roleFunction="role" action={id ? "update" : "add"}>
          <div className="add-icon">
            <AddUserRole
              loader={loader}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              handleUpdate={handleUpdate}
              updateUserRole={updateUserRole}
            />
          </div>
        </ShowComponent>
      </Box>
    </>
  );
};

export default UserRole;
