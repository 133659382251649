import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  useMediaQuery,
  Fab,
  styled,
  TextareaAutosize,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { get, post } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";

const styles = {
  textfield: {
    // width: { md: 227, xs: "100%" },
    label: { fontSize: 12 },
    // m: 1,
  },
};

const AddEvent = (props) => {
  const { url } = props;
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const theme = useTheme();

  const [eventDetails, setEventDetails] = useState({});
  const [roles, setRoles] = useState([]);
  const [imageEvent, setImageEvent] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEventDetails({ ...eventDetails, [name]: value });
  };
  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(url.role.getAllRole, {
          headers: { roleFunction: "event" },
        });
        setRoles(res.data.data);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);

  useEffect(() => {
    if (props.open || props.updateEvent) {
      setEventDetails({});
      setFromDate(Date.now());
      setToDate(Date.now());
      setImageEvent(null);

      if (props.updateEvent) {
        setEventDetails(props.updateEvent);
        setFromDate(props.updateEvent.fromDate);
        setToDate(props.updateEvent.toDate);
      }
    }
  }, [props.open, props.updateEvent]);

  const handleSubmit = async (e) => {
    console.log(fromDate, toDate, "timing");
    e.preventDefault();

    const fileForm = new FormData();
    let img = undefined;
    if (imageEvent) {
      fileForm.append("files", imageEvent);
      img = await post(url.fileUpload.postFileUpload, fileForm, {
        headers: { roleFunction: "event" },
      });
    }
    const data = {
      eventTitle: eventDetails.eventTitle,
      eventFor: eventDetails.eventFor,
      location: eventDetails.location,
      fromDate: new window.Date(fromDate),
      toDate: new window.Date(toDate),
      status: eventDetails.status,
      hostedBy: eventDetails.hostedBy,
      note: eventDetails.note,
      shortEvent: eventDetails.shortEvent,
      video: eventDetails.video,
      webView: eventDetails.webView,
    };
    if (imageEvent) {
      data.image = img.data.result[0]._id;
    }
    if (props.updateEvent) {
      await props.handleUpdate(data, props.updateEvent._id);
    } else {
      await props.handleSubmit(e, data);
    }
  };

  const handleCloseModal = () => {
    setEventDetails("");
    setFromDate(Date.now());
    setToDate(Date.now());
    setImageEvent(null);
    props.handleClose();
  };
  const handleClose = () => {
    setEventDetails("");
    setFromDate(Date.now());
    setToDate(Date.now());
    setImageEvent(null);
    props.handleClose();
  };
  return (
    <Box>
      <ShowComponent roleFunction="event" action="add">
        <Tooltip title="add Events">
          <Fab
            size="medium"
            style={{ background: "#1b3779", color: "#fff" }}
            aria-label="add"
            onClick={props.handleOpen}>
            <Add />
          </Fab>
        </Tooltip>
      </ShowComponent>
      <Dialog
        open={props.open}
        onClose={handleCloseModal}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title">
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={{ padding: 2 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              fontWeight="bold">
              {props.updateEvent ? "Update Event" : "Add Event"}
            </Typography>

            <Grid
              container
              spacing={2}
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  label="Event Title"
                  name="eventTitle"
                  value={eventDetails.eventTitle || ""}
                  onChange={(event) => handleOnChange(event)}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={styles.textfield}
                  required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}>
                    Event For
                  </InputLabel>
                  <Select
                    required
                    // labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    label="Event For"
                    value={eventDetails.eventFor || ""}
                    // defaultValue={props.employee && props.employee.role}
                    name="eventFor"
                    onChange={(event) => handleOnChange(event)}>
                    <MenuItem
                      key={"all"}
                      value={"all"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      All
                    </MenuItem>
                    {roles &&
                      roles.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row.roleName}
                          sx={{ fontSize: 12, fontWeight: 500 }}>
                          {row.roleName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  label="location"
                  name="location"
                  value={eventDetails.location || ""}
                  onChange={(event) => handleOnChange(event)}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    name="fromDate"
                    inputFormat="DD-MM-YYYY"
                    value={fromDate}
                    onChange={(newValue) => {
                      {
                        setFromDate(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        required
                        sx={{
                          // m: 1,
                          // minWidth: { md: 227, xs: "100%" },
                          label: { fontSize: 12 },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To Date"
                    name="toDate"
                    inputFormat="DD-MM-YYYY"
                    value={toDate}
                    onChange={(newValue) => {
                      {
                        setToDate(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        required
                        sx={{
                          // m: 1,
                          // minWidth: { md: 227, xs: "100%" },
                          label: { fontSize: 12 },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  name="hostedBy"
                  variant="outlined"
                  size="small"
                  value={eventDetails.hostedBy || ""}
                  onChange={(event) => handleOnChange(event)}
                  label="Hosted By"
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="video"
                    variant="outlined"
                    size="small"
                    value={eventDetails.video}
                    onChange={(event) => handleOnChange(event)}
                    label="video"
                    placeholder="only embedded video"
                    sx={{
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                      marginTop: "5px",
                    }}
                  />
                </>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  component="label"
                  sx={{
                    textTransform: "none",
                    color: "grey",
                    border: "1px solid grey",
                  }}>
                  Upload image
                  <input
                    onChange={(e) => setImageEvent(e.target.files[0])}
                    type="file"
                    accept="image/*"
                    hidden
                  />
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "flex-end",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                alignItems="center">
                <span
                  style={{
                    marginLeft: "10xp",
                  }}>
                  {imageEvent && imageEvent.name}
                </span>
                {!imageEvent && eventDetails.image && (
                  <img
                    style={{ marginLeft: 10 }}
                    src={`${eventDetails.image.link}`}
                    alt=""
                    height={50}
                    width={50}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <FormControl fullWidth variant="outlined" sx={styles.textfield}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}>
                    Is View on Web?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    name="webView"
                    label="Is View on Web?"
                    // size="small"
                    value={eventDetails.webView || ""}
                    onChange={(event) => handleOnChange(event)}>
                    <MenuItem
                      value={"yes"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      Yes
                    </MenuItem>
                    <MenuItem
                      value={"no"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <>
                  <label style={{ fontSize: "small" }}>Short event*</label>
                  <TextareaAutosize
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      // margin: 8,
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                    required
                    name="shortEvent"
                    value={eventDetails.shortEvent || ""}
                    placeholder="Type event..."
                    onChange={(event) => handleOnChange(event)}
                  />
                </>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <>
                  <label style={{ fontSize: "small" }}>Short Note</label>
                  <TextareaAutosize
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      // margin: 8,
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                    name="note"
                    value={eventDetails.note || ""}
                    placeholder="Type note here..."
                    onChange={(event) => handleOnChange(event)}
                  />
                </>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", xs: "center" },
                    gap: 2,
                  }}>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    type="button"
                    color="error">
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={props.loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}>
                    {props.updateEvent ? "Update" : "Add"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </Box>
  );
};

export default AddEvent;
