import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Grid,
  Tooltip,
  styled,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";
import ShowComponent from "../../ShowComponent";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddNotice = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(dayjs(12 / 12 / 2001).format("DD/MM/YYYY"));
  const [noticeFor, setNoticeFor] = useState("");
  const [roles, setRoles] = useState([]);
  const [notice, setNotice] = useState("");
  const [webView, setWebView] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    if (props.updateNotice) {
      setTitle(props.updateNotice.title);
      setDate(dayjs(props.updateNotice.date).format("YYYY-MM-DD"));
      setNoticeFor(props.updateNotice.noticeFor);
      setNotice(props.updateNotice.notice);
      setWebView(props.updateNotice.webView);
    }
  }, [props.updateNotice]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const res = await get(urls.role.getAllRole, {
          headers: { roleFunction: "notice" },
        });
        console.log(res);
        setRoles(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, []);
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  const handleNoticeFor = (e) => {
    setNoticeFor(e.target.value);
  };
  const handleNotice = (e) => {
    setNotice(e.target.value);
  };
  const handleWebView = (e) => {
    setWebView(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.updateNotice) {
      await props.handleUpdate(
        {
          title,
          date: dayjs(date).toDate(),
          noticeFor,
          notice,
          webView,
        },
        props.updateNotice._id
      );
    } else {
      await props.handleSubmit(e, {
        title,
        date: dayjs(date).toDate(),
        noticeFor,
        notice,
        webView,
      });
    }
    setTitle("");
    setDate("");
    setNoticeFor("");
    setNotice("");
    setWebView("");

    props.handleClose();
  };

  const handleCloseModal = () => {
    setTitle("");
    setDate("");
    setNoticeFor("");
    setNotice("");
    setWebView("");

    props.handleClose();
  };

  const handleClose = () => {
    setTitle("");
    setDate("");
    setNoticeFor("");
    setNotice("");
    setWebView("");
    props.handleClose();
  };

  return (
    <div>
      <ShowComponent roleFunction="notice" action="add">
        <Tooltip title="Add Notice">
          <Fab
            size="medium"
            style={{ background: "#1b3779", color: "#fff" }}
            aria-label="add"
            onClick={props.handleOpen}
          >
            <Add />
          </Fab>
        </Tooltip>
      </ShowComponent>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    {props.updateNotice ? "Update Notice" : "Add Notice"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Notice Title"
                    value={title}
                    onChange={handleTitle}
                    sx={{
                      // m: 1,
                      // width: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={date}
                    onChange={handleDate}
                    sx={{
                      // m: 1,
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    required
                    // sx={{ m: 1, width: {md:227,xs:"100%"} }}
                  >
                    <InputLabel sx={{ fontSize: 12 }}>Notice for</InputLabel>
                    <Select
                      label="Notice For"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={noticeFor}
                      onChange={handleNoticeFor}
                    >
                      <MenuItem
                        key={"all"}
                        value={"all"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        All
                      </MenuItem>
                      {roles &&
                        roles.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row.roleName}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.roleName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    required
                  >
                    <InputLabel sx={{ fontSize: 12 }}>
                      Is view on web?
                    </InputLabel>
                    <Select
                      label="Is View On Web"
                      value={webView}
                      onChange={handleWebView}
                    >
                      <MenuItem
                        value={"true"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"false"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    required
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Notice"
                    value={notice}
                    onChange={handleNotice}
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      // margin: 8,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "right", xs: "center" },
                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      type="button"
                      color="error"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      loading={props.loader}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {props.updateNotice ? "Update" : "Add"}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default AddNotice;
