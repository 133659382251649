// import React from "react";
// import {
//   Paper,
//   TextField,
//   Button,
//   Box,
//   InputAdornment,
//   IconButton,
//   Avatar,
// } from "@mui/material";
// import "../../styles/components/ForgotPassword/ForgotPassword.scss";
// import { VisibilityOff, Visibility, Key, Person } from "@mui/icons-material";
// import { useState } from "react";
// import { post ,get} from "../../services/apis";
// import { useNavigate } from "react-router-dom";

// import Password from "@mui/icons-material/Password";
// import { urls } from "../../services/urlConstant";

// const paperStyle = { padding: 28, width: 350 };
// const btnstyle = { margin: "8px 0" };
// const avatarStyle = { backgroundColor: "#1bbd7e" };

// const ForgotPassword = () => {
//   const [username, setUsername] = useState("");
//   const [submitted,setSubmitted] = useState(false);
//   const [code,setCode] = useState("");
//   const [password,setPassword] = useState("");

//   const handleCodeSubmit=async (e)=>{
//     e.preventDefault();
//     try{

//         const res=await post(`${urls.password.forgotPassword}`,{
//           userName:username,
//           resetCode:code,
//           newPassword:password

//         });

//     }catch(error){
//       console.log(error)
//     }

//   };

//   const handleResend=async(e)=>{
//     e.preventDefault();
//     try{
//       const res=await post(`${urls.password.sendForgotPasswordCode}`,{
//         userName:username
//       });
//       console.log(res);
//     }catch(error){
//       console.log(error)
//     }
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const res = await post(`${urls.password.sendForgotPasswordCode}`, {
//         userName: username,
//       });
//       if(res.data.success){

//         console.log(res);
//         setSubmitted(true);
//       }else{

//       }
//     } catch (error) {
//       console.log(error);
//     }

//   };

//   const handleUserChange = (e) => {
//     setUsername(e.target.value);
//   };
//   return (
//     <div>
//       {!submitted ? (
//         <form onSubmit={handleSubmit}>
//           <div className="imageStyles">
//             <div className="divStyle">
//               <Paper elevation={10} style={paperStyle}>
//                 <div align="center">
//                   <Avatar style={avatarStyle}>
//                     <Key />
//                   </Avatar>
//                   <h2 className="font">Forgot Your Password ?</h2>
//                   <h6 className="fontStyle">
//                     No worries! Enter your username and We will send you a reset
//                     code.
//                   </h6>
//                 </div>

//                 {/* { && <h1>no user found</h1>} */}
//                 <Box sx={{ display: "flex", alignItems: "flex-end" }}>
//                   <Person sx={{ color: "action.active", mr: 1, my: 0.5 }} />

//                   <TextField
//                     label=" Username"
//                     variant="standard"
//                     value={username}
//                     onChange={handleUserChange}
//                     fullWidth
//                     required
//                     id="standard-adornment-password"
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <IconButton
//                             edge="end"
//                             aria-label="toggle password visibility"
//                           ></IconButton>
//                         </InputAdornment>
//                       ),
//                     }}
//                   />
//                 </Box>

//                 <div className="button">
//                   <Button
//                     type="forgot"
//                     color="primary"
//                     fullWidth
//                     variant="contained"
//                     style={btnstyle}
//                   >
//                     Send Request
//                   </Button>
//                 </div>
//               </Paper>
//             </div>
//           </div>
//         </form>
//       ) : (
//         <form onSubmit={handleCodeSubmit}>
//           <div className="imageStyles">
//             <div className="divStyle">
//               <Paper elevation={10} style={paperStyle}>
//                 <div align="center">
//                   <Avatar style={avatarStyle}>
//                     <Password />
//                   </Avatar>
//                   <h2 className="font">Enter verification Code</h2>
//                   <h6 className="fontStyle">
//                     A verification code has been dispatched to your email
//                     ,Please check.
//                   </h6>
//                   <h6 className="fontStyle">
//                     Once you receive the code ,enter it in the textbox given
//                     below.
//                   </h6>
//                 </div>
//                 <Box sx={{ display: "flex", alignItems: "flex-end" }}>
//                   <Key sx={{ color: "action.active", mr: 1, my: 0.5 }} />

//                   <TextField
//                     label=" Code"
//                     variant="standard"
//                     value={code}
//                     onChange={(e)=>setCode(e.target.value)}
//                     fullWidth
//                     required
//                     id="standard-adornment-password"
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <IconButton
//                             edge="end"
//                             aria-label="toggle password visibility"
//                           ></IconButton>
//                         </InputAdornment>
//                       ),
//                     }}
//                   />
//                   </Box>
//                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
//                   <Key sx={{ color: "action.active", mr: 1, my: 0.5 }} />
//                   <TextField
//                     label="new password"
//                     variant="standard"
//                     value={password}
//                     onChange={(e)=>setPassword(e.target.value)}
//                     fullWidth
//                     required
//                     id="standard-adornment-password"
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <IconButton
//                             edge="end"
//                             aria-label="toggle password visibility"
//                           ></IconButton>
//                         </InputAdornment>
//                       ),
//                     }}
//                   />
//                 </Box>
//                 <div>
//                   <Button
//                   style={{fontSize:"10px"}}

//                   onClick={handleResend}
//                   >
//                  Resend code?
//                   </Button>
//                 </div>

//                 <div className="button">
//                   <Button
//                     type="forgot"
//                     color="primary"
//                     fullWidth
//                     variant="contained"
//                     style={btnstyle}
//                   >
//                     Reset
//                   </Button>
//                 </div>
//               </Paper>
//             </div>
//           </div>
//         </form>
//       )}
//     </div>
//   );
// };

// export default ForgotPassword;
import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Avatar,
} from "@mui/material";
import "../../styles/components/Login/Login.scss";
import "../../styles/components/ForgotPassword/ForgotPassword.scss";
import { Key, Person } from "@mui/icons-material";

import { post, get } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import SettingContext from "../../context/SettingsContext";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const divStyle = { padding: 28, width: 350 };
const btnstyle = { margin: "8px 0" };
const avatarStyle = { backgroundColor: "orange" };

const ForgotPassword = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [username, setUsername] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await post(
        `/${selectedSetting._id}/${urls.password.forgotPassword}`,
        {
          userName: username,
          resetCode: code,
          newPassword: password,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      const res = await post(`${urls.password.sendForgotPasswordCode}`, {
        userName: username,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await post(
        `/${selectedSetting._id}${urls.password.forgotPassword}`,
        {
          userName: username,
        }
      );
      if (res.data.success) {
        console.log(res);
        setSubmitted(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserChange = (e) => {
    setUsername(e.target.value);
  };

  return (
    <div className="imageStyless">
      <div className="divStyle">
        <form className="form" onSubmit={handleSubmit}>
          <div elevation={10} style={divStyle}>
            <div align="center">
              <Avatar style={avatarStyle}>
                <Key />
              </Avatar>
              <h2 className="font">Forgot Your Password ?</h2>
            </div>

            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Person sx={{ color: "action.active", mr: 1, my: 0.5 }} />

              <TextField
                label=" Username"
                variant="standard"
                onChange={handleUserChange}
                fullWidth
                required
                id="standard-adornment-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                      ></IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <div className="button">
              <Button
                type="forgot"
                color="primary"
                fullWidth
                variant="contained"
                style={btnstyle}
              >
                Send Request
              </Button>

              <Typography sx={{ textAlign: "center", marginTop: "20px" }}>
                <Link
                  href="/login"
                  style={{
                    color: "#1b3779",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Login
                </Link>
              </Typography>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
