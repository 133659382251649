import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete, Search } from "@mui/icons-material";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import RemoveIcon from "@mui/icons-material/Remove";
import AddModel from "../Layout/Academic/AddModel";
import ShowComponent from "../ShowComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const EmployeeMember = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [nonSearchFilter, setNonSearchFilter] = useState([]);
  const [nonSearch, setNonSearch] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [employee, setEmployee] = useState([]);
  const [nonemployee, setNonEmployee] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [addId, setAddId] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [modalData, setModalData] = useState({
    open: false,
    title: "",
    description: "",
    action: () => {},
  });
  const [sort, setSort] = useState({
    name: "asc",
  });

  const sortByName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...employee.sort((a, b) =>
          b.basicInfo.empName.localeCompare(a.basicInfo.empName)
        ),
      ];
      setEmployee(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...employee.sort((a, b) =>
          a.basicInfo.empName.localeCompare(b.basicInfo.empName)
        ),
      ];
      setEmployee(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByNonName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...nonemployee.sort((a, b) =>
          b.basicInfo.empName.localeCompare(a.basicInfo.empName)
        ),
      ];
      setNonEmployee(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...nonemployee.sort((a, b) =>
          a.basicInfo.empName.localeCompare(b.basicInfo.empName)
        ),
      ];
      setNonEmployee(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getNonLibraryEmployee();
    getLibraryEmployee();
  }, [selectedSetting]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      employee.length > 0 &&
        setSearchFilter(
          employee.filter((ele) =>
            ele.basicInfo.empName
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleNonSearch = (e) => {
    const { value } = e.target;
    setNonSearch(value.trim());
    if (value.trim() !== "") {
      nonemployee.length > 0 &&
        setNonSearchFilter(
          nonemployee.filter((ele) =>
            ele.basicInfo.empName
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setNonSearchFilter([]);
    }
  };

  const getNonLibraryEmployee = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.employee.getAllEmployee, {
        headers: { roleFunction: "employeeLibrary" },
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filteredEmployee = response.data.data.filter(
        (employee) => employee.libraryMember === "no"
      );

      setNonEmployee(filteredEmployee);
      console.log(filteredEmployee, "xxxxxxxx");
      setTotalCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const getLibraryEmployee = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.employee.getAllEmployee, {
        headers: { roleFunction: "employeeLibrary" },
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filteredEmployee = response.data.data.filter(
        (employee) => employee.libraryMember === "yes"
      );

      setEmployee(filteredEmployee);
      setTotalCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleAddOpen = (id) => {
    setModalData({
      open: true,
      title: "Add Member",
      description: "Do you want to add this employee from library member?",
      action: () => handleAddSubmit(id),
    });
  };

  const handleDeleteOpen = (id) => {
    setModalData({
      open: true,
      title: "Remove Member",
      description: "Do you want to add this employee from library member?",
      action: () => handleDeleteMember(id),
    });
  };

  const handleAddSubmit = async (id) => {
    setLoader(true);

    try {
      const res = await put(
        `${urls.libraryEmpMember.editMember}/`,
        id,
        {},
        {
          headers: { roleFunction: "employeeLibrary" },
        }
      );
      if (res.data.success) {
        handleClose();
        getNonLibraryEmployee();
        getLibraryEmployee();
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleDeleteMember = async (id) => {
    setLoader(true);
    try {
      const { status } = await put(
        `${urls.libraryEmpMember.delMember}/`,
        id,
        {},
        {
          headers: { roleFunction: "employeeLibrary" },
        }
      );
      handleClose();
      getLibraryEmployee();
      getNonLibraryEmployee();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Wrapper>
      <SectionsSubHeader title="Employee Library Member" />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Employee member list" {...a11yProps(0)} />
          <Tab label="Employee non member list" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search employee"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Phone
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library Id
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.basicInfo.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.contactNumber}
                        </TableCell>
                        <TableCell align="center">
                          {data.basicInfo.empId}
                        </TableCell>

                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="employeeLibrary"
                            action="update"
                          >
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleDeleteOpen(data._id)}
                              startIcon={<RemoveIcon />}
                            >
                              Member
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))
                : employee
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {data.basicInfo.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.contactNumber}
                        </TableCell>
                        <TableCell align="center">
                          {data.basicInfo.empId}
                        </TableCell>

                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="employeeLibrary"
                            action="update"
                          >
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleDeleteOpen(data._id)}
                              startIcon={<RemoveIcon />}
                            >
                              Member
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!employee.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : employee.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            justifyItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <TextField
              size="small"
              value={nonSearch}
              onChange={handleNonSearch}
              label="Search employee"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByNonName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Phone
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library Id
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonSearch
                ? nonSearchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.basicInfo.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.contactNumber}
                        </TableCell>
                        <TableCell align="center">
                          {data.basicInfo.empId}
                        </TableCell>

                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="employeeLibrary"
                            action="update"
                          >
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => handleAddOpen(data._id)}
                              startIcon={<AddIcon />}
                            >
                              Member
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))
                : nonemployee
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {data.basicInfo.empName}
                        </TableCell>
                        <TableCell align="center">
                          {data.contactNumber}
                        </TableCell>
                        <TableCell align="center">
                          {data.basicInfo.empId}
                        </TableCell>
                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="employeeLibrary"
                            action="update"
                          >
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => handleAddOpen(data._id)}
                              startIcon={<AddIcon />}
                            >
                              Member
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!nonemployee.length && !nonSearch.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {nonSearch.trim() && !nonSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={nonSearch ? nonSearchFilter.length : nonemployee.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>

      {/* ========= Add/remove employee model =========== */}
      <AddModel
        open={modalData.open}
        handleClose={handleClose}
        handleSubmitModal={modalData.action}
        actionTitle={modalData.title}
        descriptions={modalData.description}
        loading={loader}
      />
    </Wrapper>
  );
};

export default EmployeeMember;
