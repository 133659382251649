import React from "react";
import {
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import AddNews from "./AddNews";
import "../../../styles/components/Academic/Academic.scss";
import { useState, useContext } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Delete, Edit, Search } from "@mui/icons-material";
import DeleteModal from "../Academic/DeleteModal";
import TablePagination from "@mui/material/TablePagination";
import dayjs from "dayjs";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import ShowComponent from "../../ShowComponent";

const AnnounceNews = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [anounceNews, setAnounceNews] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateNews, setUpdateNews] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [sort, setSort] = useState({
    title: "asc",
    webView: "asc",
    date: "asc",
  });

  const sortByTitle = () => {
    console.log("Title");
    if (search) {
      if (sort.title === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.title.localeCompare(a.title)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, Title: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.title.localeCompare(b.title)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, title: "asc" });
      }
    } else {
      if (sort.title === "asc") {
        let sortedList = [
          ...anounceNews.sort((a, b) => b.title.localeCompare(a.title)),
        ];
        setAnounceNews(sortedList);
        setSort({ ...sort, title: "des" });
      } else {
        let sortedList = [
          ...anounceNews.sort((a, b) => a.title.localeCompare(b.title)),
        ];
        setAnounceNews(sortedList);
        setSort({ ...sort, title: "asc" });
      }
    }
  };

  const sortByWebView = () => {
    console.log("WebView");
    if (search) {
      if (sort.webView === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.webView.localeCompare(a.webView)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, webView: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.webView.localeCompare(b.webView)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, webView: "asc" });
      }
    } else {
      if (sort.webView === "asc") {
        let sortedList = [
          ...anounceNews.sort((a, b) => b.webView.localeCompare(a.webView)),
        ];
        setAnounceNews(sortedList);
        setSort({ ...sort, webView: "des" });
      } else {
        let sortedList = [
          ...anounceNews.sort((a, b) => a.webView.localeCompare(b.webView)),
        ];
        setAnounceNews(sortedList);
        setSort({ ...sort, webView: "asc" });
      }
    }
  };

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);

    return dateB - dateA;
  };

  const sortByDate = () => {
    console.log("Date");
    if (search) {
      if (sort.date === "asc") {
        let sortedList = [...searchFilter.sort(compareDatesDec)];
        setSearchFilter(sortedList);
        setSort({ ...sort, date: "des" });
      } else {
        let sortedList = [...searchFilter.sort(compareDatesAsc)];
        setSearchFilter(sortedList);
        setSort({ ...sort, date: "asc" });
      }
    } else {
      if (sort.date === "asc") {
        let sortedList = [...anounceNews.sort(compareDatesDec)];
        setAnounceNews(sortedList);
        setSort({ ...sort, date: "des" });
      } else {
        let sortedList = [...anounceNews.sort(compareDatesAsc)];
        setAnounceNews(sortedList);
        setSort({ ...sort, date: "asc" });
      }
    }
  };

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangePage = async (event, newPage) => {
  //   try {
  //     const res = await get(urls.announceNews.getNews, {
  //       headers: { roleFunction: "news" },
  //       params: {
  //         limit: 10,
  //         page: newPage + 1,
  //       },
  //     });
  //     setPage(newPage);
  //     setAnounceNews([...res.data.data]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateNews(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const response = await del(
        urls.announceNews.delNews,
        id,
        {},
        { roleFunction: "news" }
      );
      if (response.data.success) {
        setAnounceNews(
          anounceNews.filter((item) => item._id !== response.data.data._id)
        );
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoader(true);

    try {
      const response = await post(urls.announceNews.postNews, data, {
        headers: { roleFunction: "news" },
      });

      setAnounceNews([...anounceNews, response.data.data]);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.announceNews.putNews, id, data, {
        headers: { roleFunction: "news" },
      });

      setAnounceNews(
        anounceNews.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
    setLoader(false);
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getNewsById = anounceNews.find((item) => item._id === id);
      setUpdateNews(getNewsById);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllNews = async () => {
    try {
      const response = await get(urls.announceNews.getNews, {
        headers: { roleFunction: "news" },
      });
      setAnounceNews(response.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const getSchoolNews = async () => {
      try {
        const response = await get(urls.announceNews.getNews, {
          headers: { roleFunction: "news" },
          params: {
            page: 1,
            limit: 10,
          },
        });
        setAnounceNews(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolNews();
  }, [selectedSetting]);

  useEffect(() => {
    const getSchoolNews = async (e) => {
      try {
        const response = await get(urls.announceNews.getNews, {
          headers: { roleFunction: "news" },
        });
        setTotalCount(response.data.data.length);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolNews();
  }, [selectedSetting]);
  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearch(value);
  //   if (value.trim() === "") {
  //     setSearchFilter([]);
  //     setShowSearch(false);
  //   }
  // };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      anounceNews.length > 0 &&
        setSearchFilter(
          anounceNews.filter((ele) =>
            ele.title.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const newsSearch = await get(`${urls.announceNews.getNews}`, {
        headers: { roleFunction: "news" },
        params: {
          search: {
            $or: [
              {
                title: { $regex: search.trim() },
              },
              {
                date: dayjs(search, "DD-MM-YYYY").isValid()
                  ? dayjs(search, "DD-MM-YYYY").toDate()
                  : "",
              },
            ],
          },
        },
      });
      setSearchFilter(newsSearch.data.data);
      setShowSearch(true);
    } catch (error) {
      console.log(error.stack);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="News" />

      <div style={{ margin: "20px 0" }}>
        {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
        {/* <div className={styles.searchInputContainer}> */}
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Grid item xs="auto" mb={2}>
            {/* <form onSubmit={handleSearchSubmit}> */}
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search by Title"
              variant="outlined"
              placeholder="Search By Title"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* </form> */}
          </Grid>
        </Grid>
        {/* </div> */}
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Title</span>
                  <span
                    onClick={sortByTitle}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.title === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Date</span>
                  <span
                    onClick={sortByDate}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.date === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Is View On Web?</span>
                  <span
                    onClick={sortByWebView}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.webView === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action </span>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.webView}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <ShowComponent roleFunction="news" action="update">
                              <Tooltip title="Update">
                                <IconButton
                                  style={{ color: "#1b3779" }}
                                  size="small"
                                  onClick={() => handleEdit(row._id)}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent roleFunction="news" action="delete">
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                : anounceNews
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.webView}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <ShowComponent roleFunction="news" action="update">
                              <Tooltip title="Update">
                                <IconButton
                                  size="small"
                                  style={{ color: "#1b3779" }}
                                  onClick={() => handleEdit(row._id)}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                            <ShowComponent roleFunction="news" action="delete">
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => setDeleteModal(row._id)}
                                >
                                  <Delete fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </ShowComponent>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!anounceNews.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : anounceNews.length}
          // count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
        <div className="add-icon">
          <AddNews
            loader={loader}
            setLoader={setLoader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            updateNews={updateNews}
            handleUpdate={handleUpdate}
            getAllNews={getAllNews}
          />
        </div>
        {/* </Paper> */}
      </div>
    </Wrapper>
  );
};

export default AnnounceNews;
