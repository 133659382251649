import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";

import Wrapper from "../Wrapper";
import { TableContainer } from "@material-ui/core";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Trips = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [dialogRoute, setOpenDialogRoute] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [list, setList] = React.useState([]);
  const [total, setTotal] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==========================

  const [sort, setSort] = useState({
    routeStart: "asc",
    routeEnd: "asc",
    dropStart: "asc",
    dropEnd: "asc",
    vehicleNumber: "asc",
  });

  const sortByRouteStart = () => {
    if (sort.routeStart === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.routeStart.localeCompare(a.routeStart)),
      ];
      setList(sortedList);
      setSort({ ...sort, routeStart: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.routeStart.localeCompare(b.routeStart)),
      ];
      setList(sortedList);
      setSort({ ...sort, routeStart: "asc" });
    }
  };

  const sortByRouteEnd = () => {
    if (sort.routeEnd === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.routeEnd.localeCompare(a.routeEnd)),
      ];
      setList(sortedList);
      setSort({ ...sort, routeEnd: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.routeEnd.localeCompare(b.routeEnd)),
      ];
      setList(sortedList);
      setSort({ ...sort, routeEnd: "asc" });
    }
  };

  const sortByDropStart = () => {
    if (sort.dropStart === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.routeStart.localeCompare(a.routeStart)),
      ];
      setList(sortedList);
      setSort({ ...sort, dropStart: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.routeStart.localeCompare(b.routeStart)),
      ];
      setList(sortedList);
      setSort({ ...sort, dropStart: "asc" });
    }
  };

  const sortByDropEnd = () => {
    if (sort.dropEnd === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.routeEnd.localeCompare(a.routeEnd)),
      ];
      setList(sortedList);
      setSort({ ...sort, dropEnd: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.routeEnd.localeCompare(b.routeEnd)),
      ];
      setList(sortedList);
      setSort({ ...sort, dropEnd: "asc" });
    }
  };

  const sortByVehicleNumber = () => {
    if (sort.vehicleNumber === "asc") {
      const sortedList = [
        ...list.sort((a, b) =>
          b.vehicleNumber.vehicleNumber.localeCompare(
            a.vehicleNumber.vehicleNumber
          )
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, vehicleNumber: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) =>
          a.vehicleNumber.vehicleNumber.localeCompare(
            b.vehicleNumber.vehicleNumber
          )
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, vehicleNumber: "asc" });
    }
  };

  // useEffect(() => {
  //   const getVehicleNumber = async () => {
  //     try {
  //       const { data, status } = await get(`${urls.transport.getList}`);
  //       if (status > 199 && status < 299) {
  //         setVehicleNumber(data.data);
  //         console.log(data.data, "dattttttttttt");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getVehicleNumber();
  // }, []);

  useEffect(() => {
    getRoute();
    getTotal();
  }, [selectedSetting]);

  const getRoute = async () => {
    setList([]);
    try {
      const { data, status } = await get(urls.route.getList, {
        headers: { roleFunction: "trips" },
      });
      setList(data.data);
      console.log(data.data, "AAAA");
    } catch (error) {
      console.log(error);
    }
  };

  const getTotal = async () => {
    setList([]);
    try {
      const { data, status } = await get(urls.transport.getList, {
        headers: { roleFunction: "trips" },
      });

      const totalPassengerCount = data.data.reduce(
        (total, currentItem) =>
          total + parseInt(currentItem.totalPassenger || 0),
        0
      );
      console.log(totalPassengerCount, "xxxxxxxxxxxx");
      setTotal(totalPassengerCount);
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };

  const handleClickOpenDialogRoute = (trips) => {
    setShowDetails(trips);
    setOpenDialogRoute(true);
  };

  const handleCloseDialogRoute = () => {
    setOpenDialogRoute(false);
  };

  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Trips" />
      <div style={{ margin: "15px 0" }}>
        <Card style={{ padding: 10 }}>
          <div
            style={{
              paddingLeft: "1rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <div>
              Vehicle:<span>{list.length}</span>
            </div>
            <div>
              Total Seats Added:<span>{total}</span>
            </div>
          </div>
        </Card>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByVehicleNumber}
                    className="class-table-header"
                  >
                    Vehicle
                    <span style={{ cursor: "pointer" }}>
                      {sort.vehicleNumber === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Route
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByRouteStart}
                    className="class-table-header"
                  >
                    Pick Start
                    <span style={{ cursor: "pointer" }}>
                      {sort.routeStart === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByRouteEnd} className="class-table-header">
                    Pick End
                    <span style={{ cursor: "pointer" }}>
                      {sort.routeEnd === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByDropStart}
                    className="class-table-header"
                  >
                    Drop Start
                    <span style={{ cursor: "pointer" }}>
                      {sort.dropStart === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByDropEnd} className="class-table-header">
                    Drop End
                    <span style={{ cursor: "pointer" }}>
                      {sort.dropEnd === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((trips, i) => {
                  return (
                    <TableRow
                      key={trips._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {trips.vehicleNumber
                          ? trips.vehicleNumber.vehicleNumber
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {trips.routeStart} - {trips.routeEnd}
                      </TableCell>
                      <TableCell align="center">{trips.routeStart}</TableCell>
                      <TableCell align="center">{trips.routeEnd}</TableCell>
                      <TableCell align="center">{trips.routeEnd}</TableCell>
                      <TableCell align="center">{trips.routeStart}</TableCell>

                      <TableCell align="center">
                        <Tooltip title="View Vehicle Information">
                          <IconButton
                            size="small"
                            style={{ color: "#1b3779" }}
                            onClick={() => handleClickOpenDialogRoute(trips)}
                          >
                            <DirectionsTransitFilledIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!list.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        {/*====== View Vehicle information ===== */}
        <BootstrapDialog
          onClose={handleCloseDialogRoute}
          aria-labelledby="customized-dialog-title"
          open={dialogRoute}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialogRoute}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Vehicle Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td>Route Title</td>
                      <td>
                        {showDetails.routeStart}--{showDetails.routeEnd}
                      </td>
                    </tr>
                    <tr>
                      <td>Route Start</td>
                      <td>{showDetails.routeStart}</td>
                    </tr>
                    <tr>
                      <td>Route End</td>
                      <td>{showDetails.routeEnd}</td>
                    </tr>
                    <tr>
                      <td>Route Stops</td>
                      <td>
                        <tr>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Stop Name
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Stop KM
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Pick Start
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Pick End
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Drop Start
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Drop Time
                          </th>
                        </tr>
                        {showDetails.routeDetails.map((route, index) => (
                          <tr key={index}>
                            <td>{route.stopName}</td>
                            <td>{route.stopKM}</td>
                            <td>{route.pickTime}</td>
                            <td>{route.pickEndTime}</td>
                            <td>{route.dropTime}</td>
                            <td>{route.dropEndTime}</td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
        {/* ==================================== */}
      </div>
    </Wrapper>
  );
};

export default Trips;
