import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import DeleteModal from "../Academic/DeleteModal";
import { get, post } from "../../../services/apis";
import dayjs from "dayjs";
import { useEffect } from "react";
import { urls } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";

const styles = {
  card: {
    margin: 15,
    padding: 10,
  },
};

const TextMessage = () => {
  const { selectedSetting, setNotificationsUpdateCount } =
    useContext(SettingContext);
  const [textMessage, setTextMessage] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [deleteModal, setDeleteModal] = useState(false);
  const [report, setReport] = useState([]);
  const [smsType, setSmsType] = useState("manual");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  const [sort, setSort] = useState({
    smsSubject: "asc",
    smsType: "asc",
    sentTime: "asc",
  });

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.sentTime);
    let dateB = new Date(b.sentTime);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.sentTime);
    let dateB = new Date(b.sentTime);

    return dateB - dateA;
  };

  const sortBySentTime = () => {
    console.log("time");
    if (sort.sentTime === "asc") {
      let sortedList = [...report.sort(compareDatesDec)];

      setReport(sortedList);
      setSort({ ...sort, sentTime: "des" });
    } else {
      let sortedList = [...report.sort(compareDatesAsc)];
      setReport(sortedList);
      setSort({ ...sort, sentTime: "asc" });
    }
  };

  const sortBySmsSubject = () => {
    console.log("SMSSubject");
    if (sort.smsSubject === "asc") {
      let sortedList = [
        ...report.sort((a, b) => b.smsSubject.localeCompare(a.smsSubject)),
      ];

      setReport(sortedList);
      setSort({ ...sort, smsSubject: "des" });
    } else {
      let sortedList = [
        ...report.sort((a, b) => a.smsSubject.localeCompare(b.smsSubject)),
      ];
      setReport(sortedList);
      setSort({ ...sort, smsSubject: "asc" });
    }
  };

  const sortBySmsType = () => {
    console.log("SMsType");
    if (sort.smsType === "asc") {
      let sortedList = [
        ...report.sort((a, b) => b.smsType.localeCompare(a.smsType)),
      ];

      setReport(sortedList);
      setSort({ ...sort, smsType: "des" });
    } else {
      let sortedList = [
        ...report.sort((a, b) => a.smsType.localeCompare(b.smsType)),
      ];
      setReport(sortedList);
      setSort({ ...sort, smsType: "asc" });
    }
  };

  const updateSeenStatus = async (user) => {
    try {
      const { data } = await post(url.communication.updateSeenStatus, {
        search: { "receipients.contactNumber": `+91${user.contactNumber}` },
        headers: { roleFunction: "smsNotification" },
      });

      setNotificationsUpdateCount((prev) => prev + 1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let currentUser = window.localStorage.getItem(
      process.env.REACT_APP_CURRENT_USER
    );
    if (currentUser) {
      updateSeenStatus(JSON.parse(currentUser));
    }
  }, [selectedSetting._id]);
  return (
    <>
      <Box
        style={{
          margin: "1.5rem",
        }}
      >
        <SectionSubHeader title="Notifications" />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Subject</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Type</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Message</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Total SMS</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Time/Date</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {report &&
                report.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.smsSubject}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.smsType}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.message}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.messageDetails.length}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {dayjs(row.sentTime).format("DD-MM-YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!report.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={report.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </Box>
    </>
  );
};

export default TextMessage;
