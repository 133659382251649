import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import React, { useContext, useEffect, useState } from "react";
import { del, get, post, put } from "../../../services/apis";
import AddClassRoutine from "./AddClassRoutine";
import { Delete, Edit } from "@mui/icons-material";
import DeleteModal from "../Academic/DeleteModal";
import { toast } from "react-toastify";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import ShowComponent from "../../ShowComponent";

const GridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    //  border:"solid red",
  },
}));

const Textfield = styled(FormControl)(({ theme }) => ({
  width: "50%",
  label: { fontSize: 12 },
  margin: 1,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const styles = {
  card: { margin: "10px 0", padding: 10 },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
  table: {
    width: "100%",
  },
  Paper: {
    margin: "6px 16px",
    width: "140px",
    height: "140px",
  },
};

const ClassRoutine = () => {
  const [value, setValue] = useState("");
  const [classRoutines, setClassRoutines] = useState([]);
  const [routineDetails, setRoutineDetails] = useState({});
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [open, setOpen] = useState(false);
  const [routines, setRoutine] = useState([]);
  const [updateClassRoutine, setUpdateClassRoutine] = useState(undefined);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectSectionTab, setSelectSectionTab] = useState("");
  const [loader, setLoader] = useState(false);
  const { selectedSetting } = useContext(SettingContext);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateClassRoutine(undefined);
  };

  const handleEdit = async (id, index) => {
    try {
      setOpen(true);
      const getClassRoutineById = routines[index].periods.find(
        (item) => item._id === id
      );
      setUpdateClassRoutine({
        ...getClassRoutineById,
        dayIndex: index,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (data, id, index) => {
    setLoader(true);
    try {
      const response = await put(urls.classRoutine.putClassRoutine, id, data, {
        headers: { roleFunction: "periodRoutine" },
      });

      if (response.data.success) {
        let temp = [...routines];
        temp[index].periods = temp[index].periods.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        });
        setRoutine(temp);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleChange = async (event, newValue) => {
    try {
      const res = await get(
        `${urls.class.getClassRoutineWeek}/${routineDetails.className}/section/${newValue}`,
        { headers: { roleFunction: "periodRoutine" } }
      );
      if (res.data.success) {
        setSelectSectionTab(newValue);
        setRoutine(res.data.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }

    setValue(newValue);
  };
  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setRoutineDetails({ ...routineDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "periodRoutine" },
        params: {
          search: {
            class: value,
          },
        },
      });

      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id, index) => {
    try {
      const res = await del(
        urls.classRoutine.delClassRoutine,
        id,
        {},
        { roleFunction: "periodRoutine" }
      );
      if (res.data.success) {
        let temp = [...routines];
        temp[index].periods = temp[index].periods.filter(
          (e) => e._id !== res.data.data._id
        );
        setRoutine(temp);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, data) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await post(urls.classRoutine.postClassRoutine, data, {
        headers: { roleFunction: "periodRoutine" },
      });
      if (res.data.success) {
        const getPeriodsUpdated = await get(
          `${urls.class.getClassRoutineWeek}/${data.class}/section/${data.section}`,
          { headers: { roleFunction: "periodRoutine" } }
        );
        if (selectSectionTab === data.section) {
          setRoutine(getPeriodsUpdated.data.data);
        }
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const getClasses = await get(urls.class.getAllClass, {
          headers: { roleFunction: "periodRoutine" },
        });
        if (getClasses.data.success) setClasses(getClasses.data.data);
        setRoutineDetails((prev) => ({
          ...prev,
          className: getClasses.data.data[0] && getClasses.data.data[0]._id,
        }));
        const getSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "periodRoutine" },
          params: {
            search: {
              class: getClasses.data.data[0] && getClasses.data.data[0]._id,
            },
          },
        });
        if (getSections.data.success) setSections(getSections.data.data);
        const [schoolClass, section] = [
          getClasses.data.data[0] && getClasses.data.data[0]._id,
          getSections.data.data[0] && getSections.data.data[0]._id,
        ];
        if (schoolClass && section) {
          const initRoutine = await get(
            `${urls.class.getClassRoutineWeek}/${schoolClass}/section/${section}`,
            { headers: { roleFunction: "periodRoutine" } }
          );
          setSelectSectionTab(section);
          setValue(section);
          setRoutine(initRoutine.data.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    init();
  }, [selectedSetting]);

  return (
    <Wrapper>
      <SectionSubHeader title="" />
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <GridContainer container spacing={2}>
            <Grid item xs={12} md={6}>
              {/* <span
                style={{
                  color: "red",
                  fontFamily: "cursive",
                  marginLeft: "-70%",
                }}
              >
                Class Routine:
              </span> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { md: "left", xs: "center" },
                }}
              >
                <Typography sx={{ color: "red" }}>Class Routine:</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Textfield variant="standard" required>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Class
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="className"
                  value={routineDetails.className || ""}
                  onChange={(event) => handleClasses(event)}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </Textfield>
            </Grid>
          </GridContainer>
        </Box>
      </Card>

      <div style={{ margin: "15px" }}>
        <>
          {/* <span
            style={{
              color: "red",
              fontFamily: "auto",
              fontWeight: 600,
            }}
          >
            Sections:
          </span> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { md: "left", xs: "center" },
            }}
          >
            <Typography sx={{ color: "red" }}>Sections:</Typography>
          </Box>
        </>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "#1b3779" }}>
            <TabList onChange={handleChange} aria-label="basic tabs example">
              {sections.map((tab, index) => (
                <Tab key={tab._id} label={tab.sectionName} value={tab._id} />
              ))}
            </TabList>
          </Box>
          {sections.map((tab, index) => (
            <TabPanel style={{ padding: "14px" }} key={tab._id} value={tab._id}>
              {routines.length > 0 && (
                <div>
                  <table style={styles.table}>
                    <tr style={{ textAlign: "center" }}>
                      <th style={{ border: "1px solid black", width: "110px" }}>
                        Day
                      </th>
                      <th style={{ border: "1px solid black" }}>Routine</th>
                    </tr>
                    {routines.map((routine, routineIndex) => (
                      <tr key={routineIndex}>
                        <td
                          style={{
                            border: "1px solid black",
                            textTransform: "capitalize",
                            textAlign: "center",
                          }}
                        >
                          {routine.day}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          <div
                            style={{
                              display: "flex",
                              textAlign: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            {routine.periods.map((period, index) => (
                              <Paper
                                elevation={2}
                                style={styles.Paper}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div className="period-details">
                                  <div>{index + 1}</div>
                                  <div>
                                    {period.startTime} - {period.endTime}
                                  </div>
                                  <div>{period.subject.subjectName}</div>
                                  <div>{period.roomNo}</div>
                                  <div className="edit-delete">
                                    <ShowComponent
                                      roleFunction="periodRoutine"
                                      action="update"
                                    >
                                      <Tooltip title="Update">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleEdit(period._id, routineIndex)
                                          }
                                        >
                                          <Edit size="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </ShowComponent>
                                    <ShowComponent
                                      roleFunction="periodRoutine"
                                      action="delete"
                                    >
                                      <Tooltip title="Delete">
                                        <IconButton
                                          color="error"
                                          size="small"
                                          onClick={() =>
                                            setDeleteModal(period._id)
                                          }
                                        >
                                          <Delete
                                            color="error"
                                            fontSize="small"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </ShowComponent>
                                  </div>
                                  <DeleteModal
                                    routineIndex={routineIndex}
                                    deleteModal={deleteModal}
                                    handleDelete={handleDelete}
                                    id={period._id}
                                    setDeleteModal={setDeleteModal}
                                  />
                                </div>
                              </Paper>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </TabPanel>
          ))}
        </TabContext>
      </div>

      {/* Add Class routine */}
      <ShowComponent roleFunction="periodRoutine" action="add">
        <div className="add-icon">
          <AddClassRoutine
            open={open}
            loader={loader}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleUpdate={handleUpdate}
            updateClassRoutine={updateClassRoutine}
            handleSubmit={handleSubmit}
          />
        </div>
      </ShowComponent>
    </Wrapper>
  );
};

export default ClassRoutine;
