import React, { useContext, useEffect, useState } from "react";
import { Search } from "@material-ui/icons";
import {
  Box,
  Card,
  Fab,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Stack,
  Tooltip,
  Button,
  TableContainer,
  TablePagination,
  tableCellClasses,
} from "@mui/material";
import { get, post, put, del } from "../../../services/apis";
import { urls, urls as url } from "../../../services/urlConstant";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useLocation } from "react-router-dom";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import "./inventory.css";
import { LoadingButton } from "@mui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import ShowComponent from "../../ShowComponent";

const MaterialDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

function MaterialDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
MaterialDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Transaction = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [fromType, setFromType] = useState("");
  const [toType, setToType] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [from, setSettingOrVendor] = useState("");
  const [to, setAdditionalDropdownValue] = useState("");
  const [roles, setRoles] = useState([]);
  const [schoolEmployee, setSchoolEmployee] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [departments, setDepartment] = useState([]);
  const [addForm, setAddForm] = useState({});
  const [student, setStudent] = useState([]);
  const [formData, setFormData] = useState({});
  const [dialog, setOpenDialog] = React.useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const { state: localState } = useLocation();
  const [settings, setSettings] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  let [transactionData, setTransactionData] = useState([]);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const [sort, setSort] = useState({
    itemId: "asc",
    itemName: "asc",
    total: "asc",
    type: "asc",
    date: "asc",
    status: "asc",
  });
  const sortByItemId = () => {
    if (sort.itemId == "asc") {
      const sortedList = [
        ...transactionData.sort((a, b) =>
          b.item.itemId.localeCompare(a.item.itemId)
        ),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, itemId: "des" });
    } else {
      const sortedList = [
        ...transactionData.sort((a, b) =>
          a.item.itemId.localeCompare(b.item.itemId)
        ),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, itemId: "asc" });
    }
  };
  const sortByItemName = () => {
    if (sort.itemName == "asc") {
      const sortedList = [
        ...transactionData.sort((a, b) =>
          b.item.name.localeCompare(a.item.name)
        ),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, itemName: "des" });
    } else {
      const sortedList = [
        ...transactionData.sort((a, b) =>
          a.item.name.localeCompare(b.item.name)
        ),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, itemName: "asc" });
    }
  };
  const sortByTotal = () => {
    if (sort.total == "asc") {
      const sortedList = [...transactionData.sort((a, b) => b.total - a.total)];
      setTransactionData(sortedList);
      setSort({ ...sort, total: "des" });
    } else {
      const sortedList = [...transactionData.sort((a, b) => a.total - b.total)];
      setTransactionData(sortedList);
      setSort({ ...sort, total: "asc" });
    }
  };
  const sortByType = () => {
    if (sort.type == "asc") {
      const sortedList = [
        ...transactionData.sort((a, b) => b.type.localeCompare(a.type)),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, type: "des" });
    } else {
      const sortedList = [
        ...transactionData.sort((a, b) => a.type.localeCompare(b.type)),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, type: "asc" });
    }
  };
  const sortByDate = () => {
    if (sort.date == "asc") {
      const sortedList = [
        ...transactionData.sort((a, b) => b.date.localeCompare(a.date)),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, date: "des" });
    } else {
      const sortedList = [
        ...transactionData.sort((a, b) => a.date.localeCompare(b.date)),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, date: "asc" });
    }
  };
  const sortByStatus = () => {
    if (sort.status == "asc") {
      const sortedList = [
        ...transactionData.sort((a, b) => b.status.localeCompare(a.status)),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, status: "des" });
    } else {
      const sortedList = [
        ...transactionData.sort((a, b) => a.status.localeCompare(b.status)),
      ];
      setTransactionData(sortedList);
      setSort({ ...sort, status: "asc" });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };
  useEffect(() => {
    if (localState) {
      if (localState == "stock") {
        setTabValue(1);
      }
    }
  }, [localState]);

  const [transactionFrom, setTransactionFrom] = useState({
    fromSchoolId: "",
    vendorId: "",
  });

  const [transactionTo, setTransactionTo] = useState({
    toSchoolId: "",
    vendorId: "",
    studentId: "",
    employeeId: "",
  });

  let [viewData, setViewData] = useState(null);

  console.log(sections, "sections");

  let { toSchoolId, vendorId, studentId, employeeId } = transactionTo;

  const [classes, setClasses] = useState([]);

  const [photo, setPhoto] = useState("");
  const [image, setImage] = useState("");

  function handleChangePhoto(e) {
    console.log(e.target.files);
    setPhoto(e.target.files[0]);
    setImage(e.target.files[0].name);
    setSelectedFile(e.target.files[0]);
  }

  const handleToTypeChange = (event) => {
    const selectedToType = event.target.value;
    setToType(selectedToType);
    setAdditionalDropdownValue("");
  };

  const handleAdditionalDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setAdditionalDropdownValue(selectedValue);
  };
  const handleFromTypeChange = (event) => {
    const selectedFromType = event.target.value;
    setFromType(selectedFromType);
    setSettingOrVendor("");
  };

  const handleSettingOrVendorChange = (event) => {
    const selectedSettingOrVendor = event.target.value;
    setSettingOrVendor(selectedSettingOrVendor);
  };

  const handleClickOpenDialog = (items) => {
    setOpenDialog(true);
    console.log(items, "view");
    setViewData(items);
  };
  const handleCloseDialog = () => {
    setViewData(null);
    setOpenDialog(false);
  };

  const styles = {
    card: {
      margin: "15px 0",
      padding: 10,
    },
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole, {
          headers: { roleFunction: "transaction" },
        });
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, []);

  useEffect(() => {
    const schoolEmployeeGet = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "transaction" },
          params: {
            limit: 10,
            page: 1,
          },
        });

        setSchoolEmployee(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const schoolEmployeeGetAll = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "transaction" },
        });
        setAllEmployees(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getAllDepartment = async () => {
      try {
        const departments = await get(urls.department.getDepartment, {
          headers: { roleFunction: "transaction" },
        });
        setDepartment(departments.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDepartment();
    schoolEmployeeGet();
    schoolEmployeeGetAll();
  }, []);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "transaction" },
        });
        setFormData((prev) => ({
          ...prev,
          //   academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "transaction" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "transaction" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          // section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getsearchStudents = async () => {
      try {
        if (!formData.className || !formData.section) return;
        const response = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "transaction" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.className,
              "academicInfo.section": formData.section,
            },
          },
        });
        setStudent(response.data.data);
      } catch (error) {}
    };
    getsearchStudents();
  }, [formData.className, formData.section]);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "transaction" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let [inventoryItem, setInventoryItem] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.inventory.getAllItem}`, {
          headers: { roleFunction: "transaction" },
        });
        setInventoryItem(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  let [vendorData, setVendorData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.vendor.getAll}`, {
          headers: { roleFunction: "transaction" },
        });
        setVendorData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  let [data, setData] = useState({
    itemId: "",
    pricePerItem: 0,
    quantity: 0,
    trasactionMode: "",
    type: "",
    purpose: "",
    status: "",
    description: "",
    invoice: "",
  });

  let {
    itemId,
    pricePerItem,
    quantity,
    trasactionMode,
    type,
    purpose,
    status,
    description,
    invoice,
  } = data;

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let payload = {
      itemId,
      pricePerItem,
      quantity,
      trasactionMode,
      type,
      purpose,
      status,
      description,
      invoice,
    };

    let imageRes = "ghghghghghgh";
    if (image) {
      const fileForm = new FormData();
      fileForm.append("files", photo);
      imageRes = await post(urls.fileUpload.postFileUpload, fileForm, {
        headers: { roleFunction: "transaction" },
      });
    } else {
      return alert("Please select an Image");
    }

    try {
      let { data } = await post(
        `${urls.transaction.createTransaction}`,
        {
          ...payload,
          fromType,
          toType,
          vendorId: transactionFrom.vendorId || transactionTo.vendorId,
          fromSchoolId: transactionFrom.fromSchoolId,
          toSchoolId: transactionTo.toSchoolId,
          employeeId: transactionTo.employeeId,
          studentId: transactionTo.studentId,
          invoice: imageRes.data.result[0]._id,
        },
        {
          headers: { roleFunction: "transaction" },
        }
      );
      if (data.success) {
        setTransactionData([...transactionData, data.data]);
        setTabValue(0);
        setData({
          itemId: "",
          pricePerItem: 0,
          quantity: 0,
          trasactionMode: "",
          type: "",
          purpose: "",
          status: "",
          description: "",
          invoice: "",
        });

        setTransactionFrom({
          fromSchoolId: "",
          vendorId: "",
        });

        setTransactionTo({
          toSchoolId: "",
          vendorId: "",
          studentId: "",
          employeeId: "",
        });
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchData();
  }, [selectedSetting]);
  const fetchData = async () => {
    try {
      let { data } = await get(`${urls.transaction.getAll}`, {
        headers: { roleFunction: "transaction" },
      });
      console.log(data.data, "transaction");
      setTransactionData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  let [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    //Employee data
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.employee.getAllEmployee}`, {
          headers: { roleFunction: "transaction" },
        });
        setEmployeeData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  let [studentData, setStudentData] = useState([]);

  useEffect(() => {
    //Student data
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.employee.getAllEmployee}`, {
          headers: { roleFunction: "transaction" },
        });
        setStudentData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  let { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabValue(1);
      let pricePerItem = state.totalPrice / state.totalStock || 0;
      setData({
        ...data,
        itemId: state._id,
        pricePerItem: pricePerItem,
      });
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.settings.getAllSettings}`, {
          headers: { roleFunction: "transaction" },
        });
        setSettings(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      transactionData.length > 0 &&
        setSearchFilter(
          transactionData.filter((ele) =>
            ele.item.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Transaction" />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "15px 0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Transaction List" {...a11yProps(0)} />
          <Tab label="Make Transaction" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        {/* <Paper style={styles.card}> */}
        <div style={{ paddingTop: "22px" }}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <form>
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search Item"
                  placeholder="Enter Item Name"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </div>

        <TableContainer sx={{ margin: "15px 0" }} component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByItemId} className="class-table-header">
                    Item Id
                    <span style={{ cursor: "pointer" }}>
                      {sort.itemId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByItemName} className="class-table-header">
                    Item Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.itemName === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByTotal} className="class-table-header">
                    Total Price
                    <span style={{ cursor: "pointer" }}>
                      {sort.total === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByType} className="class-table-header">
                    Type
                    <span style={{ cursor: "pointer" }}>
                      {sort.type === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByDate} className="class-table-header">
                    Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.date === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByStatus} className="class-table-header">
                    Status
                    <span style={{ cursor: "pointer" }}>
                      {sort.status === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((items, index) => {
                      return (
                        <React.Fragment key={items._id}>
                          <TableRow>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {items.item.itemId}
                            </TableCell>
                            <TableCell align="center">
                              {items.item.name}
                            </TableCell>
                            <TableCell align="center">{items.total}</TableCell>
                            <TableCell align="center">{items.type}</TableCell>
                            <TableCell align="center">
                              {items.date.slice(0, 10)}
                            </TableCell>
                            <TableCell align="center">
                              <Paper
                                elevation={0}
                                size="small"
                                sx={{
                                  py: 1,
                                  color: "#fff",
                                  background:
                                    items.status === "Pending"
                                      ? "orange"
                                      : items.status === "Completed"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {items.status}
                              </Paper>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="View">
                                <IconButton
                                  size="small"
                                  onClick={() => handleClickOpenDialog(items)}
                                  color="primary"
                                >
                                  <VisibilityIcon
                                    color="primary"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })
                : transactionData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((items, index) => {
                      return (
                        <React.Fragment key={items._id}>
                          <TableRow>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {items.item.itemId}
                            </TableCell>
                            <TableCell align="center">
                              {items.item.name}
                            </TableCell>
                            <TableCell align="center">{items.total}</TableCell>
                            <TableCell align="center">{items.type}</TableCell>
                            <TableCell align="center">
                              {items.date.slice(0, 10)}
                            </TableCell>
                            <TableCell align="center">
                              <Paper
                                elevation={0}
                                size="small"
                                sx={{
                                  py: 1,
                                  color: "#fff",
                                  background:
                                    items.status === "Pending"
                                      ? "orange"
                                      : items.status === "Completed"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {items.status}
                              </Paper>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="View">
                                <IconButton
                                  onClick={() => handleClickOpenDialog(items)}
                                  color="primary"
                                  size="small"
                                >
                                  <VisibilityIcon
                                    color="primary"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
            </TableBody>
          </Table>
          {!transactionData.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : transactionData.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Paper style={{ margin: "15px 0" }}>
          <form onSubmit={handleSubmit}>
            <Box>
              <Grid container spacing={1} p={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Item Details
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl required fullWidth size="small">
                    <InputLabel>Item </InputLabel>
                    <Select
                      name="itemId"
                      label="Item"
                      value={itemId || ""}
                      onChange={handleChange}
                    >
                      {inventoryItem.map((items) => {
                        return (
                          <MenuItem key={items._id} value={items._id}>
                            {items.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Price Per Item"
                    value={pricePerItem}
                    name="pricePerItem"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Quantity"
                    value={quantity}
                    name="quantity"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ fontSize: "15px", fontWeight: "bold", marginTop: 1 }}
                >
                  Transaction Details
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Mode</InputLabel>
                    <Select
                      label="small"
                      name="trasactionMode"
                      value={trasactionMode}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                      <MenuItem value={"Upi"}>UPI</MenuItem>
                      <MenuItem value={"Netbanking"}>Net Banking</MenuItem>
                      <MenuItem value={"Cheque"}>Cheque</MenuItem>
                      <MenuItem value={"Card"}>Card</MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                      <MenuItem value={"Null"}>Null</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl
                    size="small"
                    required
                    fullWidth
                    style={{ margin: 1 }}
                  >
                    <InputLabel>Type</InputLabel>
                    <Select
                      name="type"
                      value={type}
                      label="Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={"In"}>In</MenuItem>
                      <MenuItem value={"Out"}>Out</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Purpose</InputLabel>
                    <Select
                      label="Purpose"
                      name="purpose"
                      value={purpose}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Inhouse"}>Inhouse</MenuItem>
                      <MenuItem value={"Damage"}>Damage</MenuItem>
                      <MenuItem value={"Purchase"}>Purchase</MenuItem>
                      {/* <MenuItem value={"Sell"}>Sell</MenuItem> */}
                      <MenuItem value={"Return"}>Return</MenuItem>
                      <MenuItem value={"InventoryUpdate"}>
                        Inventory Update
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ fontSize: "15px", fontWeight: "bold", marginTop: 1 }}
                >
                  Transaction Between
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>From Type</InputLabel>
                    <Select
                      label="From Type"
                      value={fromType}
                      name="fromType"
                      onChange={handleFromTypeChange}
                    >
                      <MenuItem value={"School"}>School</MenuItem>
                      <MenuItem value={"Vendor"}>Vendor</MenuItem>
                    </Select>
                  </FormControl>
                  {fromType === "School" ? (
                    <Grid item xs={12} md={12} lg={12} mt={1}>
                      <FormControl size="small" required fullWidth>
                        <InputLabel>School</InputLabel>
                        <Select
                          label="School"
                          name="fromSchoolId"
                          value={transactionFrom.fromSchoolId || ""}
                          onChange={(e) =>
                            setTransactionFrom({
                              fromSchoolId: e.target.value,
                              vendorId: "",
                            })
                          }
                        >
                          {employeeData.map((role) => (
                            <MenuItem key={role._id} value={role._id}>
                              {role.basicInfo.empName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {addForm.toMeetUserType && (
                        <Grid item xs={12} md={12} lg={12}>
                          <FormControl size="small" required fullWidth>
                            <InputLabel>Employee Name</InputLabel>
                            <Select
                              label="Employee Name"
                              value={addForm.toMeetUser}
                              onChange={handleAddForm}
                              name="toMeetUser"
                            >
                              {schoolEmployee.map((emp) => (
                                <MenuItem key={emp._id} value={emp._id}>
                                  {emp.basicInfo.empName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  ) : fromType === "Vendor" ? (
                    <Grid item xs={12} md={12} lg={12} mt={1}>
                      <FormControl size="small" required fullWidth>
                        <InputLabel>Vendors</InputLabel>
                        <Select
                          label="Vendors"
                          name="from"
                          value={transactionFrom.vendorId}
                          onChange={(e) =>
                            setTransactionFrom({
                              fromSchoolId: "",
                              vendorId: e.target.value,
                            })
                          }
                        >
                          {vendorData.map((items) => {
                            return (
                              <MenuItem key={items._id} value={items._id}>
                                {items.basicInfo.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      {formData.className && (
                        <Grid item xs={12} md={12} lg={12} mt={1}>
                          <FormControl size="small" required fullWidth>
                            <InputLabel> Section</InputLabel>
                            <Select
                              label="Section"
                              name="section"
                              value={formData.section || ""}
                              onChange={handleOnChange}
                            >
                              {sections.map((row, index) => (
                                <MenuItem key={row._id} value={row._id}>
                                  {row.sectionName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {formData.className && (
                        <Grid item xs={12} md={12} lg={12} mt={1}>
                          {/* <FormControl size="small" required fullWidth>
                            <InputLabel>Student Name</InputLabel>
                            <Select
                              label="Student Name"
                              name="name"
                              value={formData.name}
                              onChange={handleOnChange}
                            >
                              {student.map((row, index) => (
                                <MenuItem key={row._id} value={row._id}>
                                  {row.basicInfo.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl> */}
                          <FormControl size="small" required fullWidth>
                            <Autocomplete
                              options={student}
                              getOptionLabel={(option) => option.basicInfo.name}
                              value={formData.name || null}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  setFormData({
                                    ...formData,
                                    name: newValue._id,
                                  });
                                } else {
                                  setFormData({ ...formData, name: "" });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Student Name"
                                  size="small"
                                  required
                                  fullWidth
                                  placeholder="Search student with name"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Transaction Status</InputLabel>
                    <Select
                      label="Transction Status"
                      value={status}
                      name="status"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Pending"}>Pending</MenuItem>
                      <MenuItem value={"Completed"}>Completed</MenuItem>
                      <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12} mt={1}>
                  <TextareaAutosize
                    InputLabelProps={{
                      shrink: true,
                    }}
                    maxRows={4}
                    name="description"
                    value={description}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      // marginLeft: 10,
                      height: 100,
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    aria-label="maximum height"
                    placeholder="Drop a note"
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* <div> */}
                      <Button
                        sx={{
                          color: "#ffff",
                          background: "#1b3779",
                          "&:hover": { background: "#1b3779" },
                          textTransform: "none",
                          fontSize: "15px",
                          fontWeight: "bold",
                          marginLeft: "-15px",
                        }}
                        color="primary"
                        variant="contained"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          label="Invoices"
                          accept=".pdf, .doc, .docx, .txt"
                          type="file"
                          onChange={handleChangePhoto}
                        />
                        Upload Invoice
                      </Button>
                      {selectedFile && selectedFile.name && (
                        <p>Uploaded file: {selectedFile.name}</p>
                      )}
                      {/* </div> */}
                    </div>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                  gap={1}
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    type="button"
                  >
                    Cancel
                  </Button>

                  <ShowComponent roleFunction="transaction" action="add">
                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Done
                    </LoadingButton>
                  </ShowComponent>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Paper>
      </TabPanel>

      {/* ========= Transaction Information View  =========== */}
      {viewData ? (
        <MaterialDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <MaterialDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Transaction Information
          </MaterialDialogTitle>

          <DialogContent
            sx={{ width: { xs: 290, sm: 390, md: 500 }, p: 0, mt: 1 }}
          >
            <Table aria-label="customized table" className="profile-table">
              <TableBody>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    Item Name
                  </StyledTableCell>
                  <StyledTableCell>{viewData.item.name}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    Price Per Item
                  </StyledTableCell>
                  <StyledTableCell>{viewData.pricePerItem}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell>{viewData.quantity}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    Mode
                  </StyledTableCell>
                  <StyledTableCell>{viewData.trasactionMode}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    Type
                  </StyledTableCell>
                  <StyledTableCell>{viewData.type}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    Purpose
                  </StyledTableCell>
                  <StyledTableCell>{viewData.purpose}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    From Type
                  </StyledTableCell>
                  <StyledTableCell>{viewData.fromType}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    To Type
                  </StyledTableCell>
                  <StyledTableCell>{viewData.toType}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    Transaction Status
                  </StyledTableCell>
                  <StyledTableCell>{viewData.status}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell>{viewData.description}</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
        </MaterialDialog>
      ) : null}
    </Wrapper>
  );
};

export default Transaction;
