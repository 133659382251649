import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Card,
  FormControl,
  Grid,
  Modal,
  TextField,
  Tooltip,
  styled,
  Typography,
  InputAdornment,
  IconButton,
  Fab,
  Dialog,
  useMediaQuery,
  TableContainer,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Search } from "@mui/icons-material";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import { useTheme } from "@mui/material/styles";
import ShowComponent from "../ShowComponent";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const ManageRoomType = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editRoomType, setEditRoomType] = useState();
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchRowsPerPage, setSearchRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    if (search) {
      setSearchPage(newPage);
    } else {
      setPage(newPage);
    }
  };
  const [sort, setSort] = useState({
    name: "asc",
    specification: "asc",
  });
  const sortByTitle = () => {
    if (sort.name === "asc") {
      const sortedList = [...list.sort((a, b) => b.name.localeCompare(a.name))];
      setList(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.name.localeCompare(b.name))];
      setList(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortBySpecification = () => {
    if (sort.specification === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.specification.localeCompare(a.specification)),
      ];
      setList(sortedList);
      setSort({ ...sort, specification: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.specification.localeCompare(b.specification)),
      ];
      setList(sortedList);
      setSort({ ...sort, specification: "asc" });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setSearchRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchPage(0);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setEditRoomType(null);
    setAddForm({});

    setOpen(false);
  };

  useEffect(() => {
    getRoomType();
  }, [selectedSetting]);

  const getRoomType = async () => {
    try {
      const response = await get(`${urls.roomType.getList}`, {
        headers: { roleFunction: "roomType" },
        params: {
          search: {},
          page: 1,
          limit: 1000,
        },
      });
      setList(response.data.data);
      console.log(response.data.data, "ddddd");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editRoomType) {
        const res = await post(
          `${urls.roomType.create}`,
          {
            name: addForm.name,
            specification: addForm.specification,
          },
          { headers: { roleFunction: "roomType" } }
        );
        if (res.data.success) {
          handleClose();
          getRoomType();
          setLoader(false);
        }
      } else {
        const res = await put(
          `${urls.roomType.editRoomType}${editRoomType}`,
          "",
          {
            name: addForm.name,
            specification: addForm.specification,
          },
          { headers: { roleFunction: "roomType" } }
        );
        if (res.data.success) {
          handleClose();
          getRoomType();
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleAddForm = (event) => {
    const { name, value } = event.target;
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleClassMapEdit = (id, data) => {
    setEditRoomType(id);
    setAddForm({
      name: data.name,
      specification: data.specification,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.roomType.delRoom}`,
        id,
        {},
        { roleFunction: "roomType" }
      );
      console.log(res);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Hostel Room Type" />
      <Box sx={{ margin: "20px 0" }}>
        {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Grid item xs="auto" mb={2}>
            <TextField
              fullWidth
              size="small"
              // sx={{minWidth:227}}
              value={search}
              onChange={handleSearch}
              label="Search by Room name"
              variant="outlined"
              placeholder="Enter Room name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    {editRoomType ? "Update Room Type" : "Add Room"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    id="filled-required"
                    label="Name"
                    name="name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                    sx={{
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    size="small"
                    required
                    id="filled-required"
                    label="Specification"
                    name="specification"
                    value={addForm.specification || ""}
                    onChange={handleAddForm}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                        gap: "1%",
                      },
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      size="small"
                      color="error"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                        // m: 1,
                      }}
                    >
                      {editRoomType ? "update" : "Add"}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByTitle} className="class-table-header">
                    Room Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortBySpecification}
                    className="class-table-header"
                  >
                    Specification
                    <span style={{ cursor: "pointer" }}>
                      {sort.specification === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(
                      search ? searchPage * searchRowsPerPage : 0,
                      search
                        ? (searchPage + 1) * searchRowsPerPage
                        : rowsPerPage
                    )
                    .map((room, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{room.name}</TableCell>
                          <TableCell align="center">
                            {room.specification}
                          </TableCell>

                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ShowComponent
                                roleFunction="roomType"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    sx={{
                                      color: "#1b3779",
                                    }}
                                    onClick={() =>
                                      handleClassMapEdit(room._id, room)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="roomType"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      setDeleteModal(room._id, room)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={room._id}
                                  setDeleteModal={setDeleteModal}
                                />
                              </ShowComponent>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((room, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{room.name}</TableCell>
                          <TableCell align="center">
                            {room.specification}
                          </TableCell>

                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ShowComponent
                                roleFunction="roomType"
                                action="update"
                              >
                                <Tooltip title="Update">
                                  <IconButton
                                    size="small"
                                    sx={{
                                      color: "#1b3779",
                                    }}
                                    onClick={() =>
                                      handleClassMapEdit(room._id, room)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <ShowComponent
                                roleFunction="roomType"
                                action="delete"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      setDeleteModal(room._id, room)
                                    }
                                  >
                                    <Delete fontSize="small" color="error" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                              <DeleteModal
                                deleteModal={deleteModal}
                                handleDelete={handleDeleteone}
                                id={room._id}
                                setDeleteModal={setDeleteModal}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
          {!list.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : list.length}
          rowsPerPage={search ? searchRowsPerPage : rowsPerPage}
          page={search ? searchPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />

        {/* ======Add Icon==== */}
        <Box className="add-icon">
          <ShowComponent roleFunction="roomType" action="add">
            <Tooltip title="Add Hostel Room Type">
              <Fab
                variant="contained"
                sx={{
                  color: "#ffff",

                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                onClick={handleClickOpen}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </ShowComponent>
        </Box>
        {/* ==================== */}
      </Box>
    </Wrapper>
  );
};
export default ManageRoomType;
