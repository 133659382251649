import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactQuill from "react-quill";
import { Delete, Edit, Search } from "@mui/icons-material";
import { get, post, put, del } from "../../../services/apis";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import appendStringToUrls from "../../../utils/appendString";
import ShowComponent from "../../ShowComponent";
import { LoadingButton } from "@mui/lab";
import ReactToPrint, { useReactToPrint } from "react-to-print";

// icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SearchField = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down(900)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(685)]: {
    justifyContent: "flex-start",
  },
}));

const FormikError = styled(Typography)(() => ({
  color: "#d32f2f",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  fontSize: "0.75rem",
  marginTop: "50px",
}));

const columns = [
  {
    id: "name",
    label: "Employee Name",
    align: "center",
  },
  {
    id: "joining",
    label: "Joining Date",
    align: "center",
  },
  {
    id: "created",
    label: "Created",
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    align: "center",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "0 15px 0 15px" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function OfferLetter() {
  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [value, setValue] = useState(0);
  const [offerLetter, setOfferText] = useState(
    "Following your application for a position of a physics teacher on Jan 17, 2020 to Excellent English Medium Primary & High School and subsequent interviews on Jan 20, 2020, I am glad to inform you that you passed the interview. Effective June 01, 2020, you have been appointed as a Physics teacher with an annual starting salary of Rs.25,000/-. Other allowances and benefits admissible in your pay scale are outlined in the attached letter. You are kindly requested to respond to this letter within 10 days on whether you accept this offer. This offer is permanent and all terminal benefits, including gratuity, are attached. Should you accept this offer, your probation will start immediately and will take 3 months. Your services can be terminated during your probation without any notice, based on your conduct. Also, probation period may be extended. Find attached other terms of your employment, your duties and responsibility, and our rules of engagement."
  );
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [offerLetterData, setOfferLetterData] = React.useState([]);
  const [loadingBtn, setLoadingBtn] = useState("");
  const [name, setName] = useState("");
  const [dates, setDates] = useState();
  const [notes, setNotes] = useState("");
  const [editId, setEditId] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [modalData, setModalData] = useState({
    open: false,
    containt: "",
    action: () => {},
  });

  // table pagination============
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==========================

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClickOpen = (id, element) => {
    setModalData({
      ...modalData,
      open: true,
      containt: element.offerLetter,
      action: () => handleGetDownloadSheet(id),
    });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setEditId(null);
    setName("");
    setDates();
    setNotes("");
  };

  const handleGetOffer = async () => {
    try {
      const res = await get(`${urls.offerLetter.getListOfferLetter}`, {
        headers: { roleFunction: "offerletter" },
      });
      setOfferLetterData(res.data.data);
      setSearchFilter(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetOffer();
  }, []);

  //   =========ADD OFFERLETTER HANDLER ==========
  const onHandleFormik = useFormik({
    initialValues: {
      name: "",
      joiningDate: "",
      offerLetter: `<p>Webpruce Company</p>
                  <p>Rajajingar, Banglore, Karnataka</p>
                  <p>From: Excellent English Medium Primary & High School Ittangihal road, Vijaypur, Karnataka 586103</p>
                  <p>Contact: +91 876xxxxxxxxx</p>
                  <p>E Mail: ajxxxxx@gmail.com</p>
                  <p>Date: DD, MM, YY	</p>
                  <br/>
                  <p>Dear Ashish,</p>
                  <br/>
                  <p>This is in continuation to our meeting, I am delighted to offer you the position of Project Head at xxx organization. In this role, you would be reporting to the Senior Delivery Head and would be responsible for delivering global projects for high end clients.</p>
                  <br/>
                  <p>The terms of employment have been attached with this offer letter. We hope you will enjoy your role and make significant contributions to the success of the business. Your employment will commence on DD, MM, YY. You will be based at Bangalore location but may be required to work at other locations as well, as reasonably determined by the needs of the business.</p>
                  <br/>
                  <p>Regards</p>
                  <p>HR, Manager</p>
                  `,
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Employee Name"),
      joiningDate: Yup.string().required().label("Joining Date"),
      offerLetter: Yup.string().required().label("Offer Letter"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          name: values.name,
          joiningDate: values.joiningDate,
          offerLetter: values.offerLetter,
        };

        const res = await post(`${urls.offerLetter.createOfferLetter}`, body, {
          headers: { roleFunction: "offerletter" },
        });
        onHandleFormik.resetForm();

        setValue(0);
        handleGetOffer();
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    enableReinitialize: true,
    validateOnBlur: false,
  });

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.offerLetter.delete}`,
        id,
        {},
        { roleFunction: "offerletter" }
      );
      if (res.data.success) {
        const filteredEvent = offerLetterData.filter((item) => item._id !== id);
        setOfferLetterData(filteredEvent);
        handleGetOffer();
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDownloadSheet = async (id) => {
    setLoadingBtn(id);
    try {
      const getDownload = await get(
        `${selectedSetting._id}/offerletter/downloadpdf/${id}`,
        {
          headers: { roleFunction: "offerletter" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getDownload.data);

      const link = document.createElement("a");
      link.href = uri;
      link.target = "_blank";
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
      setLoadingBtn("");
    }
    setLoadingBtn("");
  };

  const handleSubmit = async (e) => {
    // console.log("khkjkhjk");
    e.preventDefault();
    setAddLoading(true);
    try {
      const res = await put(
        `${urls.offerLetter.update}/${editId}`,
        "",
        {
          name: name,
          joiningDate: dates,
          offerLetter: notes,
        },
        { headers: { roleFunction: "offerletter" } }
      );
      setValue(0);
      handleGetOffer();
      setEditId(null);
      setName("");
      setDates();
      setNotes("");
    } catch (error) {
      console.error(error);
      setValue(0);
      setEditId(null);
    }
    setAddLoading(false);
  };

  const handleEdit = (id, data) => {
    setEditId(id);
    setValue(2);
    setName(data.name);
    setDates(data.joiningDate);
    setNotes(data.offerLetter);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() != "") {
      setSearchFilter(
        offerLetterData.filter((employee) =>
          employee.name.toLowerCase().includes(value.trim().toLowerCase())
        )
      );
    } else {
      setSearchFilter([...offerLetterData]);
    }
  };

  const handleNotesChange = (content) => {
    setNotes(content);
  };

  return (
    <>
      <Wrapper>
        <SectionSubHeader title="Offer Letter" />
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            // margin: "15px 0",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Offer Letter List" {...a11yProps(0)} />
            <Tab label="Add Offer Letter" {...a11yProps(1)} />
            {editId && <Tab label="Edit Offer Letter" {...a11yProps(2)} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SearchField container my={2}>
            <Grid item xs="auto">
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search Employee"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </SearchField>
          <TableContainer component={Paper} sx={{ p: 0 }}>
            <Table aria-labelledby="tableTitle" size="small">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center" style={{ color: "#fff" }}>
                    #SL
                  </TableCell>

                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: "#fff" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                              {dayjs(row.joiningDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(row.createdAt).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              <div className="edit-delete">
                                <Tooltip title="View">
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      handleClickOpen(row._id, row)
                                    }
                                  >
                                    <VisibilityIcon
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <ShowComponent
                                  roleFunction="offerletter"
                                  action="update"
                                >
                                  <Tooltip title="Update">
                                    <IconButton
                                      size="small"
                                      style={{ color: "#1b3779" }}
                                      onClick={(e) => handleEdit(row._id, row)}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <ShowComponent
                                  roleFunction="offerletter"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      color="error"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                        color="error"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>

                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={row._id}
                                  setDeleteModal={setDeleteModal}
                                />

                                {/* <Tooltip>
                                  <LoadingButton
                                    size="small"
                                    loading={loadingBtn === row._id}
                                    onClick={() =>
                                      handleGetDownloadSheet(row._id)
                                    }
                                  >
                                    <FileDownloadIcon />
                                  </LoadingButton>
                                </Tooltip> */}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : offerLetterData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell align="center">
                              {page * rowsPerPage + i + 1}
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                              {dayjs(row.joiningDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(row.createdAt).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              <div className="edit-delete">
                                <Tooltip title="View">
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() =>
                                      handleClickOpen(row._id, row)
                                    }
                                  >
                                    <VisibilityIcon
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <ShowComponent
                                  roleFunction="offerletter"
                                  action="update"
                                >
                                  <Tooltip title="Update">
                                    <IconButton
                                      size="small"
                                      style={{ color: "#1b3779" }}
                                      onClick={(e) => handleEdit(row._id, row)}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <ShowComponent
                                  roleFunction="offerletter"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      color="error"
                                      onClick={() => setDeleteModal(row._id)}
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                        color="error"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>

                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={row._id}
                                  setDeleteModal={setDeleteModal}
                                />

                                {/* <Tooltip>
                                  <LoadingButton
                                    size="small"
                                    loading={loadingBtn === row._id}
                                    onClick={() =>
                                      handleGetDownloadSheet(row._id)
                                    }
                                  >
                                    <FileDownloadIcon />
                                  </LoadingButton>
                                </Tooltip> */}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
              </TableBody>
            </Table>
            {searchFilter.length === 0 || offerLetterData.length == 0 ? (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            ) : (
              ""
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={search ? searchFilter.length : offerLetterData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              my: 1,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            container
            spacing={2}
            component="form"
            mt={1}
            onSubmit={onHandleFormik.handleSubmit}
          >
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "small",
                    color: "gray",
                  }}
                >
                  Employee Name*
                </FormLabel>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  name="name"
                  size="small"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "Name",
                  }}
                  value={onHandleFormik.values.name}
                  onChange={onHandleFormik.handleChange}
                  onBlur={onHandleFormik.handleBlur}
                  error={
                    onHandleFormik.touched.name && onHandleFormik.errors.name
                      ? true
                      : false
                  }
                  helperText={
                    onHandleFormik.touched.name
                      ? onHandleFormik.errors.name
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "small",
                    color: "gray",
                  }}
                >
                  Joining Date*
                </FormLabel>

                <TextField
                  size="small"
                  name="joiningDate"
                  type="date"
                  sx={{
                    minWidth: { md: 227, xs: "100%" },
                  }}
                  value={onHandleFormik.values.joiningDate}
                  onChange={onHandleFormik.handleChange}
                  onBlur={onHandleFormik.handleBlur}
                  error={
                    onHandleFormik.touched.joiningDate &&
                    onHandleFormik.errors.joiningDate
                      ? true
                      : false
                  }
                  helperText={
                    onHandleFormik.touched.joiningDate
                      ? onHandleFormik.errors.joiningDate
                      : ""
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <FormLabel
                sx={{ fontWeight: "bold", fontSize: "small", color: "gray" }}
              >
                Offer Letter*
              </FormLabel>
              <ReactQuill
                theme="snow"
                name="offerLetter"
                type="text"
                style={{
                  height: "220px",
                }}
                value={onHandleFormik.values.offerLetter}
                onChange={(v) => onHandleFormik.setFieldValue("offerLetter", v)}
                onBlur={onHandleFormik.handleBlur}
                error={
                  onHandleFormik.touched.offerLetter &&
                  onHandleFormik.errors.offerLetter
                    ? true
                    : false
                }
              />
              <FormikError variant="body">
                {onHandleFormik.touched.offerLetter
                  ? onHandleFormik.errors.offerLetter
                  : ""}
              </FormikError>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
              }}
            >
              <Button variant="contained" size="small" color="error">
                Cancel
              </Button>
              <ShowComponent roleFunction="offerletter" action="add">
                <LoadingButton
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{
                    color: "#fff",
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </ShowComponent>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} lg={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "small",
                    color: "gray",
                  }}
                >
                  Employee Name*
                </FormLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <FormControl fullWidth required>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "small",
                    color: "gray",
                  }}
                >
                  Joining Date
                </FormLabel>
                <TextField
                  type="date"
                  // label="date"
                  size="small"
                  name="dates"
                  value={dayjs(dates).format("YYYY-MM-DD")}
                  onChange={(e) => setDates(e.target.value)}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <FormLabel
                sx={{
                  fontWeight: "bold",
                  fontSize: "small",
                  color: "gray",
                }}
              >
                Offer Letter:
              </FormLabel>
              <ReactQuill
                theme="snow"
                contentType="html"
                style={{
                  height: "220px",
                }}
                value={notes}
                onChange={handleNotesChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              l={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                mt: 7,
              }}
            >
              <Button variant="contained" size="small" color="error">
                Cancel
              </Button>
              <LoadingButton
                loading={addLoading}
                size="small"
                variant="contained"
                type="submit"
                sx={{
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                onClick={handleSubmit}
              >
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </TabPanel>

        {/* =============view offer-letter========== */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={modalData.open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Offer Letter
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent ref={componentRef}>
            <Typography gutterBottom>
              <div
                dangerouslySetInnerHTML={{ __html: modalData.containt }}
              ></div>
            </Typography>
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => (
                <LoadingButton
                  variant="contained"
                  size="small"
                  loading={loadingBtn}
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  onClick={handlePrint}
                  startIcon={<PrintIcon />}
                >
                  Print
                </LoadingButton>
              )}
              content={() => componentRef.current}
            />
            {/* <LoadingButton
                size="small"
                loading={loadingBtn}
                onClick={modalData.action}
              >
                <FileDownloadIcon />
                Download
              </LoadingButton> */}
            {/* </Tooltip> */}
            {/* <Button autoFocus onClick={handleClose}>
              Download
            </Button> */}
          </DialogActions>
        </BootstrapDialog>
      </Wrapper>
    </>
  );
}

export default OfferLetter;
