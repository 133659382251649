import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { get, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Checkbox } from "@mui/material";
import { forwardRef } from "react";
import MuiAlert from "@mui/material/Alert";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Stickybar from "../../StickyBar";
import StickyBar from "../../StickyBar";
import DownloadIcon from "@mui/icons-material/Download";
import ShowComponent from "../../ShowComponent";
const styles = {
  card: {
    margin: "15px",
    padding: "10px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
const style = {
  bgcolor: "background.paper",
  p: 2,
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Reshuffle = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [reshuffleDetails, setReshuffleDetails] = useState({});
  const [studentReshuffle, setStudentReshuffle] = useState([]);
  const [open, setOpen] = useState(false);
  const [checkBox, setCheckBox] = useState([]);
  const [bulkModal, setBulkModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(false);
  const [sample, setSample] = useState(false);
  const [changeToOptions, setChangeToOptions] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [bulkReshuffleLoader, setBulkReshuffleLoader] = useState(false);
  const [sampleLoader, setSampleLoader] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const [sort, setSort] = useState({
    name: "asc",
    rollNo: "asc",
  });

  const handleFileChange = (e) => {
    setFileSelected(e.target.files[0].name);
  };
  const sortByName = () => {
    console.log("name");
    if (sort.name === "asc") {
      let sortedList = [
        ...studentReshuffle.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setStudentReshuffle(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...studentReshuffle.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setStudentReshuffle(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByRollNo = () => {
    console.log("RollNo");
    if (sort.rollNo === "asc") {
      let sortedList = [
        ...studentReshuffle.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setStudentReshuffle(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...studentReshuffle.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setStudentReshuffle(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const handleCloseBulkModal = () => {
    setBulkModal(false);
  };
  const handleOpenBulkModal = () => {
    setBulkModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setReshuffleDetails({ ...reshuffleDetails, [name]: value });
      setStudentReshuffle([]);
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "studentResuffle" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await get(urls.students.getAllStudent, {
        headers: { roleFunction: "studentResuffle" },
        params: {
          search: {
            "academicInfo.class": reshuffleDetails.className,
            "academicInfo.section": reshuffleDetails.sectionName,
            academicYear: reshuffleDetails.academicYear,
          },
        },
      });
      setStudentReshuffle(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleReshuffle = async () => {
    setBulkReshuffleLoader(true);
    try {
      const res = await put(
        urls.students.putStudentResuffle,
        "",
        {
          section: reshuffleDetails.changeTo,
          class: reshuffleDetails.className,
          currentSection: reshuffleDetails.sectionName,
          students: checkBox,
        },
        { headers: { roleFunction: "studentResuffle" } }
      );
      const reshuffleDetailsAdded = { ...reshuffleDetails };
      for (const key in reshuffleDetailsAdded) {
        reshuffleDetailsAdded[key] = "";
      }
      setReshuffleDetails({ ...reshuffleDetailsAdded });

      if (res.data.success) {
        setStudentReshuffle([]);
        setBulkReshuffleLoader(false);
      }
    } catch (error) {
      setBulkReshuffleLoader(false);
      console.log(error);
    }
    setBulkReshuffleLoader(false);
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setReshuffleDetails({ ...reshuffleDetails, [name]: value });
    setStudentReshuffle([]);
  };

  const handleReshffleCheckBox = (_id, e) => {
    if (e.target.checked) {
      setCheckBox((prev) => [...prev, _id]);
    } else {
      setCheckBox(checkBox.filter((item) => item !== _id));
    }
  };
  useEffect(() => {
    const getAcademicYear = async () => {
      const res = await get(urls.administrator.academicYearGetAll, {
        headers: { roleFunction: "studentResuffle" },
      });
      setAcademicYears(res.data.data);
    };
    getAcademicYear();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      const res = await get(urls.class.getAllClass, {
        headers: { roleFunction: "studentResuffle" },
      });
      setClasses(res.data.data);
    };
    getClasses();
  }, [selectedSetting]);
  const handleMultipleChecks = (e) => {
    if (e.target.checked) {
      const ids = studentReshuffle.map((item) => item._id);
      setCheckBox([...ids]);
    } else {
      setCheckBox([]);
    }
  };
  const handleGetSample = async () => {
    setSampleLoader(true);
    try {
      if (!reshuffleDetails.className || !reshuffleDetails.academicYear)
        return toast.error("Please select class and academic year");
      const reshuffleList = await get(
        `${urls.students.studentsResuffleListExcel}/${reshuffleDetails.className}/${reshuffleDetails.academicYear}`,
        {
          headers: { roleFunction: "studentResuffle" },
          responseType: "blob",
        }
      );
      if (reshuffleList.status === 200) {
        const uri = URL.createObjectURL(reshuffleList.data);
        const link = document.createElement("a");
        link.href = uri;
        link.setAttribute("download", "reshuffle-list.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
    setSampleLoader(false);
  };
  const handleBulkSubmit = async (e) => {
    setValue(true);
    e.preventDefault();
    try {
      if (!reshuffleDetails.className || !reshuffleDetails.academicYear)
        return toast.error("Please select class and academic year");
      if (!e.target.sheetFile.files.length)
        return toast.error("Please choose file");
      const formData = new FormData();
      formData.append("sheet", e.target.sheetFile.files[0]);
      await put(
        `${urls.students.updateStudentResuffle}/${reshuffleDetails.className}/${reshuffleDetails.academicYear}`,
        "",
        formData,
        { headers: { roleFunction: "studentResuffle" } }
      );
      e.target.sheetFile.value = null;
      handleCloseBulkModal();
      setValue(false);
    } catch (error) {
      setValue(false);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Reshuffle " />

      <Box>
        <form onSubmit={handleSubmit}>
          <Card sx={{ margin: "20px 0", padding: "10px" }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      name="academicYear"
                      value={reshuffleDetails.academicYear}
                      onChange={(event) => handleClasses(event)}>
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      name="className"
                      value={reshuffleDetails.clsasName}
                      onChange={(event) => handleClasses(event)}>
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel>Current Section</InputLabel>
                    <Select
                      label="Current Section"
                      name="sectionName"
                      value={reshuffleDetails.sectionName}
                      onChange={(event) => handleSections(event)}>
                      {/* {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))} */}
                      {sections &&
                        sections
                          .slice()
                          .sort((a, b) =>
                            a.sectionName.localeCompare(b.sectionName)
                          ) // Sort the sections array alphabetically
                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}>
                              {row.sectionName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield}>
                    <InputLabel>Change To</InputLabel>
                    <Select
                      label="Change To"
                      name="changeTo"
                      value={reshuffleDetails.changeTo}
                      onChange={(event) => handleSections(event)}>
                      {sections &&
                        sections
                          .filter(
                            (section) =>
                              section._id !== reshuffleDetails.sectionName
                          )
                          .slice()
                          .sort((a, b) =>
                            a.sectionName.localeCompare(b.sectionName)
                          ) // Sort the sections array alphabetically

                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}>
                              {row.sectionName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    },
                    alignSelf: "center",
                    gap: 2,
                  }}>
                  <LoadingButton
                    loading={loader}
                    size="small"
                    type="submit"
                    sx={{
                      color: "#fff",
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}>
                    FIND
                  </LoadingButton>

                  <Button
                    onClick={handleOpenBulkModal}
                    varient="contained"
                    size="small"
                    sx={styles.Button}>
                    Bulk Reshuffle
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>

        <div style={{ margin: "0 0 20px 0" }}>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Name</span>
                    <span
                      onClick={sortByName}
                      style={{ cursor: "pointer", color: "#fff" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Roll No</span>
                    <span
                      onClick={sortByRollNo}
                      style={{ cursor: "pointer", color: "#fff" }}>
                      {sort.rollNo === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Father Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      onChange={handleMultipleChecks}
                      {...label}
                      sx={{
                        padding: 0,
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#fff",
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentReshuffle
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}>
                        {row.basicInfo.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}>
                        {row.academicInfo.rollNo}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}>
                        {row.fatherInfo.fatherName}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}>
                        <Checkbox
                          checked={checkBox.includes(row._id)}
                          onChange={(e) => handleReshffleCheckBox(row._id, e)}
                          {...label}
                          sx={{
                            color: "#1b3779",
                            "&.Mui-checked": {
                              color: "#1b3779",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {!studentReshuffle.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
                No data found
              </Typography>
            )}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={studentReshuffle.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              my: 1,
            }}
          />

          <ShowComponent roleFunction="studentResuffle" action="add">
            {studentReshuffle.length > 0 && (
              <StickyBar
                content={
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton
                      loading={bulkReshuffleLoader}
                      variant="contained"
                      size="small"
                      type="submit"
                      onClick={handleReshuffle}
                      sx={{
                        color: "#fff",
                        background: "#1b3779",
                        ":hover": { background: "#1b3779" },
                      }}>
                      Reshuffle
                    </LoadingButton>
                  </div>
                }
              />
            )}
          </ShowComponent>
        </div>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={bulkModal}
        onClose={handleCloseBulkModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { xs: "100%", sm: 350, md: 350, lg: 350 },
            },
          },
        }}>
        <Box sx={style}>
          <form
            onSubmit={handleBulkSubmit}
            // style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold">
                  Bulk Reshuffle
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                  loading={sampleLoader}
                  onClick={handleGetSample}
                  variant="contained"
                  sx={[styles.Button, { width: "100%" }]}
                  endIcon={<DownloadIcon />}>
                  Get sample
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={"center"}>
                <Typography> Select file:</Typography>
                <input
                  type="file"
                  name="sheetFile"
                  onChange={handleFileChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "flex-end",
                  sm: "flex-end",
                  md: "flex-end",
                  lg: "flex-end",
                }}>
                <LoadingButton
                  loading={value}
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                    color: "#fff",
                  }}>
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
    </Wrapper>
  );
};

export default Reshuffle;
