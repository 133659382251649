// import {
//   Box,
//   Button,
//   Fab,
//   FormControl,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Modal,
//   Select,
//   TextField,
//   TextareaAutosize,
//   Tooltip,
//   Typography,
//   styled,
// } from "@mui/material";
// import React, { useState } from "react";
// import { Add } from "@mui/icons-material";
// import { Delete, Edit, Search } from "@mui/icons-material";

// const ContainerBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   maxWidth: "600px",
//   maxHeight: "650px",
//   borderRadius: "5px",
//   height: "auto",
//   // overflow:"scroll",
//   backgroundColor: "white",
//   padding: "20px",
//   // border:"solid red",
//   [theme.breakpoints.down("md")]: {
//     overflow: "auto",
//     width: "90%",
//   },
//   [theme.breakpoints.between(819, 821)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(767, 769)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(911, 913)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1023, 1025)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1279, 1281)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//     overflow: "auto",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

// const defaultPopupData = {
//   title: "",
//   link: "",
//   document: "",
//   image: "",
//   text: "",
//   contentType: "",
// };

// const SplashNewsDisplay = ({
//   sharedData = defaultPopupData,
//   open = false,
//   handleClose = () => {},
// }) => {
//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//     >
//       <ContainerBox>
//         {/* <Typography
//           id="modal-modal-title"
//           variant="h6"
//           component="h2"
//           textAlign="center"
//           marginBottom="20px">
//           {sharedData.title}
//         </Typography> */}

//         {/* {sharedData.text ? <Typography>{sharedData.text}</Typography> : null}

//         {sharedData.document ? (
//           <Typography>Document : {sharedData.document}</Typography>
//         ) : null}
//         {sharedData.link ? (
//           <Typography>Link : {sharedData.link}</Typography>
//         ) : null}
//         {sharedData.contentType === "Image" ? (
//           <img src={sharedData.image} height={"100px"} width={"100px"} />
//         ) : null} */}
//       </ContainerBox>
//     </Modal>
//   );
// };

// export default SplashNewsDisplay;

//=========previous code=============//

import { Box, Typography, styled, IconButton } from "@mui/material";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const style = {
  bgcolor: "background.paper",
  padding: "80px 30px 30px 30px",
};

const CloseButton = styled(Box)(() => ({
  background: "#1eaaf1",
  position: "absolute",
  top: "0px",
  right: "0px",
  height: "80px",
  width: "80px",
  borderBottomLeftRadius: 100,
}));

const CloseIconButton = styled(IconButton)(() => ({
  position: "absolute",
  top: "10px",
  right: "10px",
}));

const defaultPopupData = {
  title: "",
  link: "",
  document: "",
  image: "",
  text: "",
  contentType: "",
};

const SplashNewsDisplay = ({
  sharedData = defaultPopupData,
  open = false,
  handleClose = () => {},
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDownloadClick = (link) => {
    window.open(link, "_blank");
  };

  if (!open || window.location.pathname !== "/") return null;

  return (
    <>
      {sharedData.contentType === "Link" && (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: { xs: "100%", sm: "450px", md: "450px", lg: "450px" },

                borderRadius: {
                  xs: "0px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                },
              },
            },
          }}
        >
          <CloseButton>
            <CloseIconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "#fff" }} />
            </CloseIconButton>
          </CloseButton>
          <Box sx={style}>
            <Link style={{ alignSelf: "center" }} to={sharedData.link}>
              Link : {sharedData.link}
            </Link>
          </Box>
        </Dialog>
      )}

      {sharedData.contentType === "Image" && (
        <>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: {
                    xs: "100%",
                    sm: "450px",
                    md: "450px",
                    lg: "450px",
                  },

                  borderRadius: {
                    xs: "0px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                  },
                },
              },
            }}
          >
            <CloseButton>
              <CloseIconButton onClick={handleClose}>
                <CloseIcon sx={{ color: "#fff" }} />
              </CloseIconButton>
            </CloseButton>
            <Box sx={style}>
              <img
                src={sharedData.image}
                height={"auto"}
                width={"100%"}
                style={{
                  borderRadius: "10px",
                }}
              />
            </Box>
          </Dialog>
        </>
      )}

      {sharedData.contentType === "Text" && (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: { xs: "100%", sm: "450px", md: "450px", lg: "450px" },

                borderRadius: {
                  xs: "0px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                },
              },
            },
          }}
        >
          <CloseButton>
            <CloseIconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "#fff" }} />
            </CloseIconButton>
          </CloseButton>
          <Box sx={style}>
            <Typography
              sx={{ textAlign: "center", width: "100%" }}
              variant="body1"
            >
              {sharedData.text}
            </Typography>
          </Box>
        </Dialog>
      )}

      {sharedData.contentType === "Document" && (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: { xs: "100%", sm: "450px", md: "450px", lg: "450px" },

                borderRadius: {
                  xs: "0px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                },
              },
            },
          }}
        >
          <CloseButton>
            <CloseIconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "#fff" }} />
            </CloseIconButton>
          </CloseButton>
          <Box sx={{ p: 4 }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                size="small"
                sx={{
                  alignSelf: "center",
                  width: "200px",
                  height: "45px",

                  ":hover": { backgroundColor: "white" },
                }}
                onClick={() => handleDownloadClick(sharedData.document)}
              >
                Document
                <FileDownloadRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default SplashNewsDisplay;
