import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
  Tooltip,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Stack,
  styled,
  IconButton,
  InputAdornment,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete, Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import AddModel from "../Layout/Academic/AddModel";
import ShowComponent from "../ShowComponent";
import Autocomplete from "@mui/material/Autocomplete";

const BookDetailed = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
}));

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "750px",
  maxHeight: "750px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#1b3779",
    color: "white",
  },
  button: {
    backgroundColor: "orange",
  },
  textfield: {
    marginTop: "25px",
    // width: "70%",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
  tab: {
    width: "100%",
    borderCollapse: "collapse",
  },
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

const StudentIssue = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState();
  const [date, setDate] = useState(dayjs(Date.now()));
  const [fromDate, setFromDate] = useState(dayjs(Date.now()));
  const [toDate, setToDate] = useState(dayjs(Date.now()));
  const [edate, setEDate] = useState(dayjs(Date.now()));

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [dueSearchFilter, setDueSearchFilter] = useState([]);
  const [dueSearch, setDueSearch] = useState("");
  const [hisSearchFilter, setHisSearchFilter] = useState([]);
  const [hisSearch, setHisSearch] = useState("");

  const [issue, setIssue] = useState([]);
  const [dueIssue, setDueIssue] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [issueTotalCount, setIssueTotalCount] = useState();
  const [dueTotalCount, setDueTotalCount] = useState();
  const [book, setBook] = useState([]);
  const [students, setStudents] = useState([]);
  const [history, setHistory] = useState([]);
  const [addForm, setAddForm] = useState({});
  const [editIssue, setEditBook] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [findLoader, setFindLoader] = useState(false);
  const [printLoadingBtn, setPrintLoadingBtn] = useState(false);
  const [popOpen, sePopOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addBtnLoader, setAddBtnLoader] = useState(false);
  const [modalData, setModalData] = useState({
    open: false,
    title: "",
    description: "",
    action: () => {},
  });
  const [sort, setSort] = useState({
    student: "",
    libraryId: "",
    title: "",
    bookId: "",
    issueDate: "",
    SubmittedDate: "",
    returnDate: "",
  });
  const sortByIssueName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...issue.sort((a, b) =>
          b.issuedTo.basicInfo.name.localeCompare(a.issuedTo.basicInfo.name)
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) =>
          a.issuedTo.basicInfo.name.localeCompare(b.issuedTo.basicInfo.name)
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByLibraryId = () => {
    if (sort.libraryId == "asc") {
      let sortedList = [
        ...issue.sort(
          (a, b) =>
            b.issuedTo.academicInfo.rollNo - a.issuedTo.academicInfo.rollNo
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, libraryId: "des" });
    } else {
      let sortedList = [
        ...issue.sort(
          (a, b) =>
            a.issuedTo.academicInfo.rollNo - b.issuedTo.academicInfo.rollNo
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, libraryId: "asc" });
    }
  };
  const sortByTitle = () => {
    if (sort.title == "asc") {
      let sortedList = [
        ...issue.sort((a, b) =>
          b.book.booktitle.localeCompare(a.book.booktitle)
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) =>
          a.book.booktitle.localeCompare(b.book.booktitle)
        ),
      ];
      setIssue(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };

  const sortByBookID = () => {
    if (sort.bookId == "asc") {
      let sortedList = [
        ...issue.sort((a, b) => b.book.booksId.localeCompare(a.book.booksId)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, bookId: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) => a.book.booksId.localeCompare(b.book.booksId)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, bookId: "asc" });
    }
  };
  const sortByIssueDate = () => {
    if (sort.issueDate == "asc") {
      let sortedList = [
        ...issue.sort((a, b) => b.issueDate.localeCompare(a.issueDate)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, issueDate: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) => a.issueDate.localeCompare(b.issueDate)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, issueDate: "asc" });
    }
  };
  const sortByReturn = () => {
    if (sort.returnDate == "asc") {
      let sortedList = [
        ...issue.sort((a, b) => b.returnDate.localeCompare(a.returnDate)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, returnDate: "des" });
    } else {
      let sortedList = [
        ...issue.sort((a, b) => a.returnDate.localeCompare(b.returnDate)),
      ];
      setIssue(sortedList);
      setSort({ ...sort, returnDate: "asc" });
    }
  };
  const sortByNonIssueName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          b.issuedTo.basicInfo.name.localeCompare(a.issuedTo.basicInfo.name)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          a.issuedTo.basicInfo.name.localeCompare(b.issuedTo.basicInfo.name)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByNonLibraryId = () => {
    if (sort.libraryId == "asc") {
      let sortedList = [
        ...dueIssue.sort(
          (a, b) =>
            b.issuedTo.academicInfo.rollNo - a.issuedTo.academicInfo.rollNo
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, libraryId: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort(
          (a, b) =>
            a.issuedTo.academicInfo.rollNo - b.issuedTo.academicInfo.rollNo
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, libraryId: "asc" });
    }
  };
  const sortByNonTitle = () => {
    if (sort.title == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          b.book.booktitle.localeCompare(a.book.booktitle)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          a.book.booktitle.localeCompare(b.book.booktitle)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };
  const sortByNonBookID = () => {
    if (sort.bookId == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          b.book.booksId.localeCompare(a.book.booksId)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, bookId: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) =>
          a.book.booksId.localeCompare(b.book.booksId)
        ),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, bookId: "asc" });
    }
  };
  const sortByNonIssueDate = () => {
    if (sort.issueDate == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) => b.issueDate.localeCompare(a.issueDate)),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, issueDate: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) => a.issueDate.localeCompare(b.issueDate)),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, issueDate: "asc" });
    }
  };
  const sortByNonReturn = () => {
    if (sort.returnDate == "asc") {
      let sortedList = [
        ...dueIssue.sort((a, b) => b.returnDate.localeCompare(a.returnDate)),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, returnDate: "des" });
    } else {
      let sortedList = [
        ...dueIssue.sort((a, b) => a.returnDate.localeCompare(b.returnDate)),
      ];
      setDueIssue(sortedList);
      setSort({ ...sort, returnDate: "asc" });
    }
  };
  const sortByHisIssueName = () => {
    if (sort.name == "asc") {
      let sortedList = [
        ...history.sort((a, b) =>
          b.issuedTo.basicInfo.name.localeCompare(a.issuedTo.basicInfo.name)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) =>
          a.issuedTo.basicInfo.name.localeCompare(b.issuedTo.basicInfo.name)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByHisLibraryId = () => {
    if (sort.libraryId == "asc") {
      let sortedList = [
        ...history.sort(
          (a, b) =>
            b.issuedTo.academicInfo.rollNo - a.issuedTo.academicInfo.rollNo
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, libraryId: "des" });
    } else {
      let sortedList = [
        ...history.sort(
          (a, b) =>
            a.issuedTo.academicInfo.rollNo - b.issuedTo.academicInfo.rollNo
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, libraryId: "asc" });
    }
  };
  const sortByHisTitle = () => {
    if (sort.title == "asc") {
      let sortedList = [
        ...history.sort((a, b) =>
          b.book.booktitle.localeCompare(a.book.booktitle)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) =>
          a.book.booktitle.localeCompare(b.book.booktitle)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };

  const sortByHisBookID = () => {
    if (sort.bookId == "asc") {
      let sortedList = [
        ...history.sort((a, b) => b.book.booksId.localeCompare(a.book.booksId)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, bookId: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) => a.book.booksId.localeCompare(b.book.booksId)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, bookId: "asc" });
    }
  };
  const sortByHisIssueDate = () => {
    if (sort.issueDate == "asc") {
      let sortedList = [
        ...history.sort((a, b) => b.issueDate.localeCompare(a.issueDate)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, issueDate: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) => a.issueDate.localeCompare(b.issueDate)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, issueDate: "asc" });
    }
  };
  const sortByHisReturn = () => {
    if (sort.returnDate == "asc") {
      let sortedList = [
        ...history.sort((a, b) => b.returnDate.localeCompare(a.returnDate)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, returnDate: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) => a.returnDate.localeCompare(b.returnDate)),
      ];
      setHistory(sortedList);
      setSort({ ...sort, returnDate: "asc" });
    }
  };
  const sortByHisSubmittedDate = () => {
    if (sort.SubmittedDate == "asc") {
      let sortedList = [
        ...history.sort((a, b) =>
          b.SubmittedDate?.localeCompare(a.SubmittedDate)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, SubmittedDate: "des" });
    } else {
      let sortedList = [
        ...history.sort((a, b) =>
          a.SubmittedDate?.localeCompare(b.SubmittedDate)
        ),
      ];
      setHistory(sortedList);
      setSort({ ...sort, SubmittedDate: "asc" });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      issue.length > 0 &&
        setSearchFilter(
          issue.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleDueSearch = (e) => {
    const { value } = e.target;
    setDueSearch(value.trim());
    if (value.trim() !== "") {
      dueIssue.length > 0 &&
        setDueSearchFilter(
          dueIssue.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setDueSearchFilter([]);
    }
  };
  const handleHisSearch = (e) => {
    const { value } = e.target;
    setHisSearch(value.trim());
    if (value.trim() !== "") {
      history.length > 0 &&
        setHisSearchFilter(
          history.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setHisSearchFilter([]);
    }
  };
  useEffect(() => {
    getIssueList();
    getDueIssueList();
    getBookList();
    getLibraryStudent();
  }, [selectedSetting]);

  const getIssueList = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.issueList.get, {
        headers: { roleFunction: "issuereturn" },
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filterIssue = response.data.data.filter(
        (issues) => issues.submitted === "no"
      );
      console.log(filterIssue, "issuelist");
      setIssue(filterIssue);
      setIssueTotalCount(filterIssue.length);
    } catch (error) {
      console.log(error);
    }
  };
  const getDueIssueList = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.issueList.getdue, {
        headers: { roleFunction: "issuereturn" },
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filteredDue = response.data.data.filter(
        (dues) => dues.submitted === "no"
      );
      console.log(filteredDue, "duList");
      setDueIssue(filteredDue);
      setDueTotalCount(filteredDue.length);
    } catch (error) {
      console.log(error);
    }
  };
  const getBookList = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.book.getList, {
        headers: { roleFunction: "issuereturn" },
        params: {
          limit: 10,
          page: 1,
        },
      });
      setBook(response.data.data);
      console.log("setBook", response.data.data);
      setTotalCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const getLibraryStudent = async () => {
    try {
      const searchStudents = await get(urls.students.getAllStudent, {
        headers: { roleFunction: "issuereturn" },
        params: {
          search: {
            "otherInfo.libraryMember": "yes",
          },
        },
      });
      setStudents(searchStudents.data.data);

      setTotalCount(searchStudents.length);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickPopOpen = (id) => {
    // sePopOpen(true);
    setModalData({
      open: true,
      title: "Return Book",
      description:
        "Are you sure you want to change the issue status to return?",
      action: () => handleSubmitList(id),
    });
  };

  const handleSubmitList = async (id) => {
    // console.log(id, "jhjhjhjhj");
    setLoader(true);
    try {
      const res = await put(
        `${urls.issueList.submitBook}/`,
        id,
        {},
        {
          headers: { roleFunction: "issuereturn" },
        }
      );
      if (res.data.success) {
        getDueIssueList();
        getIssueList();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handlePopClose = () => {
    sePopOpen(false);
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      // if (!editIssue) {
      const res = await post(
        `${urls.issueList.create}`,
        {
          issueDate: date,
          book: addForm.book,
          isbnNo: addForm.isbnNo,
          edison: addForm.edison,
          author: addForm.author,
          language: addForm.language,
          price: addForm.price,
          quantity: addForm.quantity,
          almiraNo: addForm.almiraNo,
          returnDate: edate,
          issuedTo: addForm.issuedTo,
        },
        {
          headers: { roleFunction: "issuereturn" },
        }
      );
      if (res.data.success) {
        getIssueList();
        getDueIssueList();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAddForm({});
    setModalData({ ...modalData, open: false });
  };
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const handleSearchHistory = async (e) => {
    setFindLoader(true);
    e.preventDefault();
    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.issueList.getHistory, {
        headers: { roleFunction: "issuereturn" },
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setHistory(searchHistory.data.data);
      console.log(searchHistory.data.data, "searchHistory");
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  const handleGetDownloadSheet = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/issuereturn/downloadexcel/${fromDate}/${toDate}`,
        {
          headers: { roleFunction: "issuereturn" },
        },
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `library-history${fromDate}-${toDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    setPrintLoadingBtn(true);
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/issuereturn/downloadpdf/${fromDate}/${toDate}`,
        {
          headers: { roleFunction: "issuereturn" },
        },
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setPrintLoadingBtn(false);
  };
  return (
    <Wrapper>
      <SectionsSubHeader title="Student Issue & Return" />
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          borderColor: "divider",
          margin: "15px 0",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: {
            md: "space-between",
            xs: "center",
            sm: "center",
          },
          alignItems: {
            md: "center",
            xs: "flex-start",
            sm: "center",
          },
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Issue List" {...a11yProps(0)} />
          <Tab label="Due List" {...a11yProps(1)} />
          <Tab label="History List" {...a11yProps(2)} />
        </Tabs>
        <ShowComponent roleFunction="issuereturn" action="add">
          <Button
            variant="contained"
            sx={{
              mx: 2,
              background: "#1b3779",
              "&:hover": {
                background: "#1b3779",
              },
            }}
            onClick={handleClickOpen}
          >
            {/* <AddIcon /> */}
            Issue Book
          </Button>
        </ShowComponent>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search Book"
              variant="outlined"
              placeholder="Search By Title"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <BookDetailed>
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              fontSize={16}
              my={1}
            >
              Total Books: {issueTotalCount + dueTotalCount}
            </Typography>
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              fontSize={16}
              my={1}
            >
              Issued: {issueTotalCount}
            </Typography>
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              fontSize={16}
              my={1}
            >
              Due: {dueTotalCount}
            </Typography>
          </BookDetailed>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByIssueName}
                    className="class-table-header"
                  >
                    Student
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByLibraryId}
                    className="class-table-header"
                  >
                    Library ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.libraryId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByTitle} className="class-table-header">
                    Title
                    <span style={{ cursor: "pointer" }}>
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByBookID} className="class-table-header">
                    Book ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.bookId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByIssueDate}
                    className="class-table-header"
                  >
                    Issue Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.issueDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByReturn} className="class-table-header">
                    Due Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.returnDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                {/* <TableCell sx={{ color: "white" }} align="center">
                  Return Date
                </TableCell> */}
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        {" "}
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        {/* <TableCell align="center">--</TableCell> */}
                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="issuereturn"
                            action="update"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleClickPopOpen(data._id)}
                            >
                              Return
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))
                : issue
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        {/* <TableCell align="center">--</TableCell> */}

                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="issuereturn"
                            action="update"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleClickPopOpen(data._id)}
                            >
                              Return
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!issue.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={search ? searchFilter.length : issue.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <TextField
              size="small"
              fullWidth
              value={dueSearch}
              onChange={handleDueSearch}
              label="Search Book"
              placeholder="Search By Title"
              variant="outlined"
              sx={{ mt: "20px", pb: "10px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonIssueName}
                    className="class-table-header"
                  >
                    Student
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonLibraryId}
                    className="class-table-header"
                  >
                    Library ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.libraryId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByNonTitle} className="class-table-header">
                    Title
                    <span style={{ cursor: "pointer" }}>
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonBookID}
                    className="class-table-header"
                  >
                    Book ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.bookId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonIssueDate}
                    className="class-table-header"
                  >
                    Issue Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.issueDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByNonReturn}
                    className="class-table-header"
                  >
                    Due Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.returnDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                {/* <TableCell sx={{ color: "white" }} align="center">
              Return Date
            </TableCell> */}
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dueSearch
                ? dueSearchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        {/* <TableCell align="center">--</TableCell> */}

                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="issuereturn"
                            action="update"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleClickPopOpen(data._id)}
                            >
                              Return
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))
                : dueIssue
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        {/* <TableCell align="center">--</TableCell> */}

                        <TableCell align="center">
                          <ShowComponent
                            roleFunction="issuereturn"
                            action="update"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleClickPopOpen(data._id)}
                            >
                              Return
                            </Button>
                          </ShowComponent>
                        </TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!dueIssue.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {dueSearch.trim() && !dueSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={dueSearch ? dueSearchFilter.length : dueIssue.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "5px",
          }}
        >
          <form onSubmit={handleSearchHistory}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={4}
                sx={{ alignSelf: "center", mt: 2 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    variant="standard"
                    label="From Date"
                    inputFormat="DD-MM-YYYY"
                    value={fromDate}
                    onChange={(newValue) => {
                      setFromDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={4}
                sx={{ alignSelf: "center", mt: 2 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    variant="standard"
                    label="To Date"
                    inputFormat="DD-MM-YYYY"
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                display="flex"
                alignSelf="center"
                justifyContent="flex-start"
              >
                <LoadingButton
                  loading={findLoader}
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                    mt: 2,
                  }}
                  size="small"
                  type="submit"
                >
                  Find
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ alignSelf: "center" }}>
                <Box style={{ paddingBottom: "10px" }}>
                  <Stack spacing={2} direction="row">
                    <LoadingButton
                      loading={loadingBtn}
                      onClick={handleGetDownloadSheet}
                      sx={{
                        color: "#ffff",
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Download
                    </LoadingButton>
                    <LoadingButton
                      loading={printLoadingBtn}
                      sx={{
                        color: "#ffff",
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                      onClick={handleGetPrintPdf}
                    >
                      Print
                    </LoadingButton>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Card>

        <Grid container spacing={2} justifyContent="flex-end" my={2}>
          <Grid xs="auto">
            <TextField
              size="small"
              fullWidth
              value={hisSearch}
              onChange={handleHisSearch}
              label="Search Book"
              variant="outlined"
              placeholder="Search By Title"
              sx={{ mt: "20px", pb: "10px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByHisIssueName}
                    className="class-table-header"
                  >
                    Student
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByHisLibraryId}
                    className="class-table-header"
                  >
                    Library ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.libraryId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByHisTitle} className="class-table-header">
                    Title
                    <span style={{ cursor: "pointer" }}>
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByHisBookID}
                    className="class-table-header"
                  >
                    Book ID
                    <span style={{ cursor: "pointer" }}>
                      {sort.bookId === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByHisIssueDate}
                    className="class-table-header"
                  >
                    Issue Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.issueDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByHisReturn}
                    className="class-table-header"
                  >
                    Due Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.returnDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span
                    onClick={sortByHisSubmittedDate}
                    className="class-table-header"
                  >
                    Return Date
                    <span style={{ cursor: "pointer" }}>
                      {sort.SubmittedDate === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                {/* <TableCell sx={{ color: "white" }} align="center">
              Return Date
            </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {hisSearch
                ? hisSearchFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {data.submittedDate
                            ? dayjs(data.submittedDate).format("DD-MM-YYYY")
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    ))
                : history
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      (data, index) => (
                        console.log(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ),
                        (
                          <TableRow>
                            <TableCell align="center">
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              {data.issuedTo.basicInfo.name}
                            </TableCell>
                            <TableCell align="center">
                              {data.issuedTo.academicInfo.rollNo}
                            </TableCell>
                            <TableCell align="center">
                              {data.book.booktitle}
                            </TableCell>
                            <TableCell align="center">
                              {data.book.booksId}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(data.issueDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(data.returnDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              {data.submittedDate
                                ? dayjs(data.submittedDate).format("DD-MM-YYYY")
                                : "NA"}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
            </TableBody>
          </Table>
          {!history.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {hisSearch.trim() && !hisSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={hisSearch ? hisSearchFilter.length : history.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& .MuiTablePagination-spacer": {
              overflow: "none",
            },
          }}
        />
      </TabPanel>

      {/* ========== Return Book Model ================= */}
      <AddModel
        open={modalData.open}
        handleClose={handleClose}
        handleSubmitModal={modalData.action}
        actionTitle={modalData.title}
        descriptions={modalData.description}
        loading={loader}
      />

      {/* ========== Isuue Book Model ================= */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <ContainerBox>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
              size="small"
              fontWeight={600}
              // onChange={handleAddForm}
            >
              Add Issue
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth size="small">
                  <InputLabel sx={{ fontSize: 8 }}>Select Book</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="book"
                    label="Select Book"
                    value={addForm.book || ""}
                    onChange={handleAddForm}
                  >
                    {book.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.booktitle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  label="Quantity"
                  name="quantity"
                  size="small"
                  type="number"
                  value={addForm.quantity || ""}
                  onChange={handleAddForm}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                {/* <FormControl fullWidth size="small">
                  <InputLabel sx={{ fontSize: 8 }}>Select Member</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="issuedTo"
                    label="Select Member"
                    value={addForm.issuedTo || ""}
                    onChange={handleAddForm}
                  >
                    {students.map((data) => (
                      <MenuItem value={data._id}>
                        {data.basicInfo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <FormControl fullWidth size="small">
                  <Autocomplete
                    options={students}
                    getOptionLabel={(option) => option.basicInfo?.name}
                    value={
                      students.find(
                        (student) => student._id === addForm.issuedTo
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleAddForm({
                        target: {
                          name: "issuedTo",
                          value: newValue ? newValue._id : "", // Pass the selected student's ID directly
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Search student with name"
                        label="Select Member"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Issue Date"
                    // openTo="day"
                    // views={["day", "month", "year"]}
                    inputFormat="DD-MM-YYYY"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Due Date"
                    inputFormat="DD-MM-YYYY"
                    value={edate}
                    onChange={(newValue) => {
                      setEDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: { md: "right", sm: "right", xs: "center" },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    type="button"
                    color="error"
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={loader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Add
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </ContainerBox>
        </form>
      </Modal>
    </Wrapper>
  );
};

export default StudentIssue;
