import {
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";
import ShowComponent from "../../ShowComponent";
import FileUploadIcon from "@mui/icons-material/FileUpload";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const styles = {
  cardStyle: {
    margin: "15px 0",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginTop: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },

  button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const VehicleMaintenanceView = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [date, setDate] = useState(dayjs(Date.now()));

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [addForm, setAddForm] = useState({});
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());

  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [firmName, setFirmName] = React.useState([]);
  const [editFuel, setEditFuel] = useState();
  const [list, setList] = React.useState([]);
  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editVehicleFuel, setEditVehicleFuel] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [findForm, setFindForm] = useState({});
  const [inputFile, setInputFile] = React.useState(null);

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ====================================

  let [loader, setLoader] = useState(false);
  let [loaderFind, setLoaderFind] = useState(false);
  let [addLoader, setAddLoader] = useState(false);
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const [sort, setSort] = useState({
    date: "asc",
    firmName: "asc",
    billNo: "asc",
    amount: "asc",
    kiloMeter: "asc",
  });

  const sortByDate = () => {
    if (sort.date === "asc") {
      const sortedList = [...list.sort((a, b) => b.date.localeCompare(a.date))];
      setList(sortedList);
      setSort({ ...sort, date: "des" });
    } else {
      const sortedList = [...list.sort((a, b) => a.date.localeCompare(b.date))];
      setList(sortedList);
      setSort({ ...sort, date: "asc" });
    }
  };

  const sortByBillNo = () => {
    if (sort.billNo === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.billNo.localeCompare(a.billNo)),
      ];
      setList(sortedList);
      setSort({ ...sort, billNo: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.billNo.localeCompare(b.billNo)),
      ];
      setList(sortedList);
      setSort({ ...sort, billNo: "asc" });
    }
  };

  const sortByAmount = () => {
    if (sort.amount === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.amount.localeCompare(a.amount)),
      ];
      setList(sortedList);
      setSort({ ...sort, amount: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.amount.localeCompare(b.amount)),
      ];
      setList(sortedList);
      setSort({ ...sort, amount: "asc" });
    }
  };

  const sortByKiloMeter = () => {
    if (sort.kiloMeter === "asc") {
      const sortedList = [
        ...list.sort((a, b) => b.kiloMeter.localeCompare(a.kiloMeter)),
      ];
      setList(sortedList);
      setSort({ ...sort, kiloMeter: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) => a.kiloMeter.localeCompare(b.kiloMeter)),
      ];
      setList(sortedList);
      setSort({ ...sort, kiloMeter: "asc" });
    }
  };

  const sortByFirmName = () => {
    if (sort.firmName === "asc") {
      const sortedList = [
        ...list.sort((a, b) =>
          b.firmName.firmName.localeCompare(a.firmName.firmName)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, firmName: "des" });
    } else {
      const sortedList = [
        ...list.sort((a, b) =>
          a.firmName.firmName.localeCompare(b.firmName.firmName)
        ),
      ];
      setList(sortedList);
      setSort({ ...sort, firmName: "asc" });
    }
  };

  // useEffect(() => {
  //   getFuel();
  // }, [selectedSetting]);

  const getFuel = async () => {
    setList([]);
    setLoaderFind(true);
    try {
      const fromDateObj = new Date(fromDate);

      fromDateObj.setDate(fromDateObj.getDate() - 1);

      const { data, status } = await get(urls.vehicleFuel.getList, {
        headers: { roleFunction: "maintainance" },
        params: {
          vehicleNumber: findForm.vehicleNumber,
          firmName: findForm.firmName,
          fromDate: fromDateObj.toISOString(),
          toDate: toDate,
        },
      });
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
    setLoaderFind(false);
  };

  useEffect(() => {
    setVehicleNumber([]);
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`, {
          headers: { roleFunction: "maintainance" },
        });
        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
          console.log(data.data, "dattttttttttt");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, []);

  const handleFindForm = (e) => {
    const { name, value } = e.target;
    setFindForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name == "vehicleNumber") {
      let vehicle = vehicleNumber.filter((v) => v._id == value)[0];
      setSelectedVehicleNumber(vehicle.vehicleNumber);
    }
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "fuelQuantity" || name === "rate") {
      const quantity =
        name === "fuelQuantity" ? value : addForm.fuelQuantity || 0;
      const rate = name === "rate" ? value : addForm.rate || 0;
      const amount = quantity * rate;
      setCalculatedAmount(amount);
    }
    if (name == "vehicleNumber") {
      let vehicle = vehicleNumber.filter((v) => v._id == value)[0];
      setSelectedVehicleNumber(vehicle.vehicleNumber);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.vehicleFuel.delete}`,
        id,
        {},
        { roleFunction: "maintainance" }
      );
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    if (!findForm.vehicleNumber)
      return alert("Please select the vehicle number");
    setOpen(true);
  };

  const handleClose = () => {
    setEditVehicleFuel(null);
    setOpen(false);
    setAddForm({});
    setCalculatedAmount(0);
    setFileName("");
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    setFirmName([]);
    const getFirmName = async () => {
      try {
        const { data, status } = await get(`${urls.firm.getList}`, {
          headers: { roleFunction: "maintainance" },
        });
        if (status > 199 && status < 299) {
          setFirmName(data.data);
          // console.log(data.data, "firmnammmmmmmeeee");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFirmName();
  }, [selectedSetting]);

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setAddLoader(true);

    try {
      const amount = addForm.fuelQuantity * addForm.rate;

      setAddForm((prevForm) => ({
        ...prevForm,
        amount: amount,
      }));

      let photo = null;
      if (fileName) {
        const fileForm = new FormData();
        fileForm.append("files", file);
        let response = await post(urls.fileUpload.postFileUpload, fileForm, {
          headers: { roleFunction: "maintainance" },
        });
        if (response.data.success) {
          photo = response.data.result[0]._id;
        }
      } else {
        return alert("Please select an file");
      }

      const body = {
        vehicleNumber: findForm.vehicleNumber,
        firmName: addForm.firmName,
        date: date,
        billNo: addForm.billNo,
        fuelQuantity: addForm.fuelQuantity,
        rate: addForm.rate,
        amount: amount,
        kiloMeter: addForm.kiloMeter,
        mileage: addForm.mileage,
        note: addForm.note,
      };

      if (!editVehicleFuel) {
        const res = await post(
          `${urls.vehicleFuel.create}`,
          { ...body, photo },
          {
            headers: { roleFunction: "maintainance" },
          }
        );
        if (res.data.success) {
          getFuel();
          handleClose();
          setAddLoader(false);
        }
      } else {
        let photo = null;
        if (fileName) {
          const fileForm = new FormData();
          fileForm.append("files", file);
          let response = await post(urls.fileUpload.postFileUpload, fileForm, {
            headers: { roleFunction: "maintainance" },
          });

          if (response.data.success) {
            photo = response.data.result[0]._id;
          }
        } else {
          return alert("Please select an file");
        }

        const body = {
          vehicleNumber: findForm.vehicleNumber,
          firmName: addForm.firmName,
          date: date,
          billNo: addForm.billNo,
          fuelQuantity: addForm.fuelQuantity,
          rate: addForm.rate,
          amount: amount,
          kiloMeter: addForm.kiloMeter,
          mileage: addForm.mileage,
          note: addForm.note,
        };
        const res = await put(
          `${urls.vehicleFuel.update}/${editVehicleFuel}`,
          "",
          {
            ...body,
            photo,
          },
          { headers: { roleFunction: "maintainance" } }
        );
        if (res.data.success) {
          getFuel();
          handleClose();
          setAddLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setAddLoader(false);
    }
  };

  const handleFuelEdit = (id, data) => {
    setEditVehicleFuel(id);
    console.log(data, "ooooooooooooooooooooooooo");

    setAddForm({
      vehicleNumber: data.vehicleNumber,
      firmName: data.firmName._id,
      date: date,
      billNo: data.billNo,
      fuelQuantity: data.fuelQuantity,
      rate: data.rate,
      amount: data.amount,
      kiloMeter: data.kiloMeter,
      mileage: data.mileage,
      note: data.note,
    });
    setFile(data.photo);
    const amount = data.fuelQuantity * data.rate;
    setCalculatedAmount(amount);

    handleClickOpen();
  };

  const handleGetPrintPdf = async (e) => {
    setLoader(true);
    e.preventDefault();

    try {
      const getPrint = await get(
        `/${selectedSetting._id}/vehiclefuel/downloadpdf/${findForm.vehicleNumber}/${findForm.firmName}/${fromDate}/${toDate}`,
        {
          headers: { roleFunction: "maintainance" },
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleDownload = () => {
    const downloadUrl = list.find((l) => l._id == editVehicleFuel)?.photo?.link;
    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    }
  };
  return (
    <>
      <Grid container spacing={1} justifyContent="flex-end" mb={1}>
        <ShowComponent roleFunction="maintainance" action="add">
          <Button
            variant="contained"
            style={styles.addbutton}
            onClick={handleClickOpen}>
            <AddIcon />
            Add
          </Button>
        </ShowComponent>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          mt: 1,
          mb: 2,
        }}>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl
            fullWidth
            size="small"
            // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
          >
            <InputLabel required sx={{ fontSize: 12 }}>
              Vehicle Number
            </InputLabel>
            <Select
              labelId="demo-simpless-select-filled-label"
              id="demo-simple-select-filled-l"
              label="vehicle number"
              name="vehicleNumber"
              onChange={handleFindForm}
              value={findForm.vehicleNumber || ""}>
              {vehicleNumber.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  {name.vehicleNumber}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl
            fullWidth
            size="small"
            // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
          >
            <InputLabel required sx={{ fontSize: 12 }}>
              Firm Name
            </InputLabel>
            <Select
              labelId="demo-simpless-select-filled-label"
              id="demo-simple-select-filled-l"
              label="Firm Name"
              name="firmName"
              onChange={handleFindForm}
              value={findForm.firmName || ""}>
              {firmName.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  {name.firmName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
              <DatePicker
                label="From Date"
                value={fromDate || ""}
                onChange={setFromDate}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...params}
                    error={fromDate !== null && fromDate === null}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
              <DatePicker
                label="To Date"
                value={toDate || ""}
                onChange={setToDate}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...params}
                    error={toDate !== null && toDate === null}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          justifyContent={{
            xs: "center",
            sm: "center",
            md: "flex-end",
            lg: "flex-end",
          }}
          gap={1}>
          <LoadingButton
            size="small"
            loading={loaderFind}
            variant="contained"
            onClick={getFuel}
            sx={{
              background: "#1b3779",

              ":hover": { background: "#1b3779" },
            }}>
            Find
          </LoadingButton>
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              background: "#1b3779",
              color: "#fff",
              ":hover": { background: "#1b3779" },
            }}
            onClick={handleGetPrintPdf}
            loading={loader}>
            Print{" "}
          </LoadingButton>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByDate} className="class-table-header">
                  Date
                  <span style={{ cursor: "pointer" }}>
                    {sort.date === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByFirmName} className="class-table-header">
                  Firm
                  <span style={{ cursor: "pointer" }}>
                    {sort.firmName === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByBillNo} className="class-table-header">
                  BIll No
                  <span style={{ cursor: "pointer" }}>
                    {sort.billNo === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByAmount} className="class-table-header">
                  Amount
                  <span style={{ cursor: "pointer" }}>
                    {sort.amount === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                <span onClick={sortByKiloMeter} className="class-table-header">
                  KM
                  <span style={{ cursor: "pointer" }}>
                    {sort.kiloMeter === "asc" ? "▲" : "▼"}
                  </span>
                </span>
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((fuel, index) => (
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>

                <TableCell align="center">
                  {dayjs(fuel.date).format("DD-MM-YYYY")}
                </TableCell>

                <TableCell align="center">{fuel.firmName.firmName}</TableCell>
                <TableCell align="center">{fuel.billNo}</TableCell>

                <TableCell align="center">{fuel.amount}</TableCell>
                <TableCell align="center">{fuel.kiloMeter}</TableCell>
                <TableCell align="center">
                  <div className="edit-delete">
                    <ShowComponent roleFunction="maintainance" action="update">
                      <Tooltip title="Update">
                        <IconButton
                          size="small"
                          style={{ color: "#1b3779" }}
                          onClick={() => handleFuelEdit(fuel._id, fuel)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ShowComponent>
                    <ShowComponent roleFunction="maintainance" action="delete">
                      <Tooltip title="Delete">
                        <IconButton
                          color="small"
                          size="small"
                          onClick={() => setDeleteModal(fuel._id, fuel)}>
                          <Delete fontSize="small" color="error" />
                        </IconButton>
                      </Tooltip>
                    </ShowComponent>
                    <DeleteModal
                      deleteModal={deleteModal}
                      handleDelete={handleDeleteone}
                      id={fuel._id}
                      setDeleteModal={setDeleteModal}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!list.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start,",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* =========== Add vehicle log ================ */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold">
                  {editVehicleFuel ? "Update Fuel" : "Add Fuel"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="h4">{selectedVehicleNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel required>Firm Name</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Firm Name"
                    name="firmName"
                    onChange={handleAddForm}
                    value={addForm.firmName || ""}>
                    {firmName.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.firmName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ m: 1.3, minWidth: { md: 227, xs: "100%" } }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  label="Bill No"
                  name="billNo"
                  onChange={handleAddForm}
                  value={addForm.billNo || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  label="Fuel Qnty"
                  type="number"
                  name="fuelQuantity"
                  onChange={handleAddForm}
                  value={addForm.fuelQuantity || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Rate"
                  type="number"
                  name="rate"
                  value={addForm.rate || ""}
                  onChange={handleAddForm}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Amount"
                  type="number"
                  name="amount"
                  value={calculatedAmount}
                  onChange={handleAddForm}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  type="number"
                  label="KM"
                  name="kiloMeter"
                  onChange={handleAddForm}
                  value={addForm.kiloMeter || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Tooltip title="Upload Material">
                  <Button
                    component="label"
                    variant="outlined"
                    fullWidth
                    sx={{
                      padding: "7px",
                      maxWidth: 200,
                    }}
                    startIcon={<FileUploadIcon fontSize="small" />}
                    size="small">
                    upload Reciept
                    <input
                      hidden
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "block" }}
                    />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}>
                {fileName ? <Typography>{fileName}</Typography> : ""}
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                {list.find((l) => l._id == editVehicleFuel)?.photo &&
                list.find((l) => l._id == editVehicleFuel)?.photo?.link ? (
                  <Button variant="outlined" onClick={handleDownload}>
                    DownLoad File
                  </Button>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      md: "right",
                      sm: "right",
                      xs: "center",
                      gap: "1%",
                    },
                  }}>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    size="small"
                    type="button"
                    sx={{ m: 1, color: "#fff" }}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={addLoader}
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}>
                    Add
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      {/* ================================= */}
    </>
  );
};

export default VehicleMaintenanceView;
