import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Delete, Edit, Search } from "@mui/icons-material";
import {
  Grid,
  Button,
  Card,
  Divider,
  TextField,
  Tooltip,
  Typography,
  TablePagination,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Wrapper from "../../Wrapper";
import SectionSubHeader from "../../SectionSubHeader";
import ShowComponent from "../../ShowComponent";

const styles = {
  button: {
    backgroundColor: "orange",
  },
  textfield: {
    margin: "20px",
    // width: "70%",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
  tab: {
    width: "100%",
    borderCollapse: "collapse",
  },
};

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function createData(name, leaveType, numberOfDays, startDate, endDate, status) {
  return { name, leaveType, numberOfDays, startDate, endDate, status };
}

const rows = [
  // createData("aditya", "student", 5, "10/5/2023", "15/5/2023",)
];

function StudentsLeave() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [calculatedDates, setCalculatedDates] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Student Leave" />
      <Box style={{ margin: "15px 0px" }}>
        <Grid container justifyContent="flex-end">
          <Grid xs="auto">
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box style={{ margin: "15px 0" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell>
                  <span style={{ color: "white" }}>#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Name</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Leave Type</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Number of days</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Start date</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>End date</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Status</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ color: "white" }}>Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow style={{ border: 0 }}>
                  <TableCell
                    key={row.name}
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.leavetype}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.noOfDays}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.startDate}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.endDate}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.status}
                  </TableCell>
                  <div className="edit-delete">
                    <TableCell>
                      <ShowComponent
                        roleFunction="studentLeave"
                        action="update"
                      >
                        <Tooltip title="Update" disableInteractive>
                          <IconButton size="small" style={{ color: "#1b3779" }}>
                            <Edit fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ShowComponent>
                      <ShowComponent
                        roleFunction="studentLeave"
                        action="delete"
                      >
                        <Tooltip title="Delete" disableInteractive>
                          <IconButton IconButton size="small" color="error">
                            <Delete fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                      </ShowComponent>
                    </TableCell>
                  </div>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!rows.length && (
            <Typography
              variant="h6"
              style={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </Box>
    </Wrapper>
  );
}
export default StudentsLeave;
