import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { LoadingButton } from "@mui/lab";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    marginLeft: "1rem",
    marginRight: "1rem",
  },

  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const QuickAdmit = () => {
  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [academicYears, setAcademicYears] = useState([]);
  const [formData, setFormData] = useState([]);
  const [admissionDate, setAdmissionDate] = useState(dayjs());
  const [dob, setDob] = useState(dayjs());
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "quickadmit" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]?._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "quickadmit" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "quickadmit" },
          params: {
            search: {
              class: res.data.data[0]?._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "quickadmit" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "quickadmit" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const data = {
        basicInfo: {
          name: formData.name,
          admissionDate: dayjs(admissionDate).toDate(),
          dob: dayjs(dob).toDate(),
          gender: formData.gender,
          caste: formData.caste,
        },
        academicInfo: {
          class: formData.className,
          section: formData.section,
          rollNo: formData.rollNo,
          admissionNo: formData.admissionNo,
        },
        fatherInfo: {
          fatherName: formData.fatherName,
          fatherPhone: formData.fatherPhoneNo,
        },
        motherInfo: {
          motherName: formData.motherName,
          motherPhone: formData.motherPhoneNo,
        },
        academicYear: formData.academicYear,
        contactNumber: formData.contactNumber,
        status: formData.status,
      };

      const res = await post(urls.students.postStudent, data, {
        headers: { roleFunction: "quickadmit" },
      });
      console.log(res);
      if (res.data.success) {
        navigate("/admitstudent");
      } else {
        console.log("error ", res.data);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  return (
    <>
      <Card style={{ margin: 15, padding: "22px 0px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <Typography
              style={{
                color: "red",

                display: "inline-block",
              }}
            >
              Quick Admit:
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <form onSubmit={handleSubmit}>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h6 style={{ color: "#1b3779" }}>
                  <span style={{ color: "red" }}> Note:</span> Student will be
                  admited to session
                </h6>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl size="small" fullWidth required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    // size="small"
                    fullWidth
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "flex-start",
                    lg: "flex-start",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Basic Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name || ""}
                  onChange={handleAddForm}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Admission Date"
                    value={admissionDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setAdmissionDate(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField size="small" fullWidth required {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    inputFormat="DD-MM-YYYY"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField required size="small" fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl size="small" fullWidth required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Gender
                  </InputLabel>
                  <Select
                    label="Gender"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="gender"
                    value={formData.gender || ""}
                    onChange={handleAddForm}
                  >
                    <MenuItem
                      value={"male"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Male
                    </MenuItem>
                    <MenuItem
                      value={"female"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Female
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label="Caste"
                  name="caste"
                  value={formData.caste}
                  onChange={handleAddForm}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label="Contact Number"
                  name="number"
                  value={formData.contactNumber}
                  onChange={handleAddForm}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "flex-start",
                    lg: "flex-start",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Parent Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label="Father Name"
                  name="fatherName"
                  value={formData.fatherName}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label="Father Phone No"
                  name="fatherPhoneNo"
                  value={formData.fatherPhoneNo}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label="Mother Name"
                  name="motherName"
                  value={formData.motherName}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label="Mother Phone No"
                  name="motherPhoneNo"
                  value={formData.motherPhoneNo}
                  onChange={handleAddForm}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "flex-start",
                    lg: "flex-start",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Academic Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl size="small" fullWidth required>
                  <InputLabel>Class</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="className"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                    fontSize="small"
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel sx={{ fontSize: 12, fontWeight: 400 }}>
                    Section
                  </InputLabel>
                  <Select
                    label="Section"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                    fontSize="small"
                  >
                    {formData.className && <MenuItem value="all">All</MenuItem>}
                    {sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label={
                    selectedSetting?.rollNumberType == "manual"
                      ? "Roll No."
                      : "Roll No(Auto generated)"
                  }
                  name="rollNo"
                  value={formData.rollno}
                  onChange={handleAddForm}
                  disabled={selectedSetting?.rollNumberType != "manual"}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  id="filled-"
                  size="small"
                  fullWidth
                  label={
                    selectedSetting?.admissionNo == "manual"
                      ? "Admission No."
                      : "Admission No(Auto generated)"
                  }
                  name="admissionNo"
                  value={formData.admissionNo}
                  onChange={handleAddForm}
                  disabled={selectedSetting?.admissionNo != "manual"}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl size="small" fullWidth required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    label="Status"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="status"
                    value={formData.status || ""}
                    onChange={handleAddForm}
                  >
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      value={"inActive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      InActive
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent={{
            xs: "center",
            sm: "center",
            md: "flex-end",
            lg: "flex-end",
          }}
        >
          <LoadingButton
            loading={loader}
            variant="contained"
            size="small"
            type="submit"
            sx={{
              marginRight: "1rem",
              marginBottom: "20px",
              background: "rgb(27, 55, 121)",
              ":hover": { background: "rgb(27, 55, 121)" },
            }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </form>
    </>
  );
};

export default QuickAdmit;
